import React, {Component} from "react";
import MenuGroup from "../MenuItems/MenuGroup";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";

import MenuItemButton3 from "../MenuItems/MenuItemButton3";
import LayerInfo from "../MenuItems/LayerInfo";
import MenuItemButton from "../MenuItems/MenuItemButton";
import MenuItemTextBox from "../MenuItems/MenuItemTextBox";
import MenuItemColorPicker from "../MenuItems/MenuItemColorPicker";
import MenuItemInputRange from "../MenuItems/MenuItemInputRange";
import MenuItemCheckbox from "../MenuItems/MenuItemCheckbox";
import RenameMaterial from "../Modals/RenameMaterial";
import MenuItemFullSizeDropdown from "../MenuItems/MenuItemFullSizeDropdown";
import MenuItemFullSizeDropdown2 from "../MenuItems/MenuItemFullSizeDropdown2";
import SaveAsPresetModal from "../Modals/SaveAsPresetModal";
import PresetsLibrary from "../PresetsLibrary/PresetsLibrary";
import CreatePresetFolderModal from "../PresetsLibrary/CreatePresetFolderModal";
import AddCombinationModal from "../../Combinations/AddCombinationModal";
import AddCombinationSceneInfoAlertModal from "../../Combinations/AddCombinationSceneInfoAlertModal";
import ShowCombinationsModal from "../../Combinations/ShowCombinationsModal";
import {mapWrapUV, normalMapType, colorMapEncoding} from "../../../../helpers/dropDownOptions";
import {sideOptions} from "../../../../helpers/sideOptions";
import MenuItemDropdown from "../MenuItems/MenuItemDropdown";
import WarpUVDropdown from "../MenuItems/WarpUVDropdown";
import {FileLoader} from "three";
import axios from '../../../../axios';
import jwt_decode from "jwt-decode";


import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';

let lang = {};
class MaterialSettings extends Component {
    state = {
        materialGroup: false,
        showObjects: false,
        map: false,
        lightMap: false,
        aoMap: false,
        emissiveMap: false,
        bumpMap: false,
        normalMap: false,
        roughnessMap: false,
        metalnessMap: false,
        alphaMap: false,
        wireframe: false,
        materialsList: false,
        presetLibrary: false,
        materialLibrary: false,
        tooltip: null,
        layersPrice: {},
        layersTag: {},
        layersLabel: {},
        layersDisplayName: {}
    }


    toggleMenuVisibility = (type) => {
        let newState = {}
        Object.keys({...this.state}).forEach(key => {
            if(key != 'tooltip' && key != 'layersPrice')
            {
                newState = {
                    ...newState,
                    [key]: false
                }
            }
        })

        this.setState({
            ...newState,
            [type]: !this.state[type]
        })
    }

    click = (type) => {
    }


    componentDidMount() {
        let decoded_jwt = jwt_decode(localStorage.getItem('user'));
        const userId = decoded_jwt.id;
        let uuids = [];
        new FileLoader().load('https://cadgl.net/tooltip_cadgl/tooltip.json', (response) => {
            this.setState({
                tooltip: JSON.parse(response)
            })
        })
        let instances = {}
        this.props.materialsLibrary.map((group, i) => {
            instances = {
                ...instances,
                ['Instance' + i]: false
            }
            uuids.push(group.params.id);
        })

        this.setState({
            ...instances
        })
        console.log("componentDidMount");

        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }

        axios.post('layer/getList', {
            uuids: uuids,
            userId: userId
        }).then(response => {
            let layersPrice = {};
            let layersTag = {};
            let layersLabel = {};
            let layersDisplayName = {};
            const layers = response.data.data.layers;
            for(let layer of layers){
                layersPrice[layer.layerId] = layer.price;
                layersTag[layer.layerId] = layer.tag;
                layersLabel[layer.layerId] = layer.label;
                layersDisplayName[layer.layerId] = layer.displayName;
                // this.props.applyMaterialFromDBToLayer(layer.layerId, layer.materialId, layer.materialFolderId);
            }
            this.setState({
                layersPrice: layersPrice,
                layersTag: layersTag,
                layersLabel: layersLabel,
                layersDisplayName: layersDisplayName
            })
            document.getElementById("layers_price").value = JSON.stringify(layersPrice);
            document.getElementById("layers_tag").value = JSON.stringify(layersTag);
            document.getElementById("layers_label").value = JSON.stringify(layersLabel);
            document.getElementById("layers_display_name").value = JSON.stringify(layersDisplayName);
        });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                </Row>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.material_layers : ''}
                    name={lang.material_layers}
                    onClick={() => this.toggleMenuVisibility('materialGroup')}
                    collapsed={this.state.materialGroup ? 1 : 0}>
                    {
                        this.state.materialGroup && this.props.materialsLibrary.length > 0 ?
                        this.props.materialsLibrary
                        .sort((a,b)=> (a.material.name < b.material.name ? 1 : -1))
                        .map((group, i) => {
                            return (
                                <LayerInfo name={group.material.name.replace(/_/g, ' ').replace(/-/g, ' ')}
                                key={group.material.id}
                                uuid={group.params.id}
                                value={group.params.id}
                                type="selectMeshes"
                                visible={group.material.visible}
                                price={this.state.layersPrice[group.params.id]}
                                orgName={group.mesh[0].uuid}
                                pickedObject={this.props.selectedObjects.pickedObject != null ? this.props.selectedObjects.pickedObject.uuid : ''}
                                presetName={this.props.preset_layer != null ? this.props.preset_layer[group.params.id] : null}
                                presetFolderName={this.props.preset_folder_layer != null ? this.props.preset_folder_layer[group.params.id] : null}
                                />
                            )
                        })
                        :''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.picked_layers : ''}
                    name={lang.picked_layer}
                    onClick={() => this.toggleMenuVisibility('showObjects')}
                    collapsed={this.state.showObjects ? 1 : 0}>
                    {
                        this.state.showObjects && this.props.selectedObjects.selected ?
                            <React.Fragment>
                                {/*<MenuItemTextBox name="Group Name" readonly={true}
                                                    value={this.props.selectedObjects.pickedObject.name}/>
                                */}
                                
                                {
                                    this.props.materialsLibrary.map((group, i) => {

                                        for(let meshCount = 0; meshCount < group.mesh.length ; meshCount ++)
                                        {
                                            if(group.mesh[meshCount].uuid == this.props.selectedObjects.pickedObject.uuid)
                                            {
                                                return (
                                                    <MenuItemCheckbox name={lang.visible}
                                                                        key={group.mesh[meshCount].uuid}
                                                                      value={group.material.visible}
                                                                        type="refractionMaterialToggleVisibility"/>
                                                            )
                                                break;
                                            }
                                        }
                                        // if(group.mesh[0].uuid == this.props.selectedObjects.pickedObject.uuid)
                                        // {
                                            // return (
                                            //     <MenuItemCheckbox name="Visible"
                                            //                         key={group.mesh[0].uuid}
                                            //                       value={group.material.visible}
                                            //                         type="refractionMaterialToggleVisibility"/>
                                            //             )
                                        // }
                                    })
                                }
                               
                                {
                                    this.props.selectedObjects.material.isDiamond ?
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_standard : ''}
                                                            name={lang.make_standard}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeStandard"/>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_refraction : ''}
                                                            name={lang.make_refraction}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeRefraction"/>
                                        </React.Fragment>
                                        : this.props.selectedObjects.material.isRefraction ?
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_standard : ''}
                                                            name={lang.make_standard}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeStandard"/>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_diamond : ''}
                                                            name={lang.make_diamond}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeDiamond"/>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_refraction : ''}
                                                            name={lang.make_refraction}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeRefraction"/>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.make_diamond : ''}
                                                            name={lang.make_diamond}
                                                            value={this.props.selectedObjects.pickedObject}
                                                            type="makeDiamond"/>
                                        </React.Fragment>
                                }


                        {
                            (this.props.userPermission != undefined && this.props.userPermission.isShowMaterialAudio) ? 
                                <span>
                                    <MenuItemButton
                                    value={this.props.selectedObjects.pickedObject}
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.add_audio} type="addAudio"/>
                                    <MenuItemInputRange name={lang.volume} min={0} max={1} step={0.1} 
                                    key={this.props.selectedObjects.pickedObject.uuid}
                                    obj={this.props.selectedObjects.pickedObject}
                                    value={this.props.api.getAudio(this.props.selectedObjects.pickedObject) ? this.props.api.getAudio(this.props.selectedObjects.pickedObject).getVolume() : 1} 
                                    type="changeAudioMaterialVolume" />
                                    <MenuItemCheckbox 
                                    key={this.props.selectedObjects.pickedObject.name}
                                    obj={this.props.selectedObjects.pickedObject}
                                    name={lang.loop} value={this.props.api.getAudio(this.props.selectedObjects.pickedObject) ? this.props.api.getAudio(this.props.selectedObjects.pickedObject).getLoop() :true} type="toggleAudioMaterialLoop"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.remove} type="removeAudioMaterial"
                                    key={this.props.selectedObjects.pickedObject.position.x}
                                    obj={this.props.selectedObjects.pickedObject}
                                    />
                                </span> : ''
                        }
                            </React.Fragment>
                            : ''
                    }
                </MenuGroup>


                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.material_library : ''}
                    name={lang.material_library}
                    onClick={() => this.toggleMenuVisibility('presetLibrary')}
                    collapsed={this.state.presetLibrary ? 1 : 0}>
                    {
                        this.state.presetLibrary ?
                            <React.Fragment>
                                <PresetsLibrary/>
                                <CreatePresetFolderModal name={lang.new_folder}/>
                            </React.Fragment>
                            : ''
                    }
                </MenuGroup>

                <hr />

                <React.Fragment>

                {/*<MenuGroup
                    name="MATERIALS LIST"
                    onClick={() => this.toggleMenuVisibility('materialsList')}
                    collapsed={this.state.materialsList ? 1 : 0}>
                    {
                        this.state.materialsList && this.props.selectedObjects.selected ?
                            <span>
                                <MenuItemFullSizeDropdown name="Materials List"
                                                        options={this.props.materialsList}
                                                        value={this.props.selectedObjects.materialId}
                                                        type="selectMaterial"/>
                            </span>
                            : ''
                    }
                </MenuGroup>*/}

                <Row>
                    <Col md={12} className="settings-tab-title">
                        <span id="materialType">{this.props.selectedObjects.material != null ? this.props.selectedObjects.material.isDiamond ? lang.diamond_material : this.props.selectedObjects.material.isRefraction ? lang.refraction_material : lang.standard_material: ''}</span>
                    </Col>
                </Row>


                {
                    this.props.selectedObjects.selected && this.props.selectedObjects.material && this.props.selectedObjects.material.isDiamond ?
                        <span key={this.props.selectedObjects.materialId}>
                            <MenuItemButton3 title={this.state.tooltip != null ? this.state.tooltip.load_material : ''} name={lang.load_material} type="uploadMaterial" onClick={() => this.toggleMenuVisibility('presetLibrary')}
                                            value={this.props.selectedObjects.pickedObject}/>
                            <SaveAsPresetModal title={this.state.tooltip != null ? this.state.tooltip.save_as_new_material : ''} name={lang.save_as_new_material}/>
                            {/*
                            <MenuItemButton name="Save Material" type="downloadMaterial"/>
                            <RenameMaterial name="Rename Material"
                                            value={this.props.selectedObjects.materialId}/>
                            */}
                            
                            {
                                this.props.selectedObjects.material.side ?
                                <MenuItemDropdown name={lang.side} options={sideOptions} value={this.props.selectedObjects.pickedObject.material.side} type="sideChange" />   
                                : ''
                            }
                            <MenuItemColorPicker name={lang.color}
                                                value={this.props.selectedObjects.material.color}
                                                type="materialColor"/>
                            <MenuItemInputRange name={lang.boost_x} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.boostFactors.x}
                                                type="boostFactorX"/>
                            <MenuItemInputRange name={lang.boost_y} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.boostFactors.y}
                                                type="boostFactorY"/>
                            <MenuItemInputRange name={lang.boost_z} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.boostFactors.z}
                                                type="boostFactorZ"/>
                            <MenuItemInputRange name={lang.environment_intensity} step={0.1} min={0} max={10}
                                                value={this.props.selectedObjects.material.envMapIntensity}
                                                type="envMapIntensityDiamond"/>
                            <MenuItemInputRange name={lang.reflectivity} step={0.001} min={0} max={10}
                                                value={this.props.selectedObjects.material.reflectivity}
                                                type="reflectivityDiamond"/>
                            <MenuItemInputRange name={lang.index_of_refraction} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.refractiveIndex}
                                                type="refractiveIndexDiamond"/>
                            <MenuItemInputRange name={lang.geometry} min={0} max={5}
                                                value={this.props.selectedObjects.material.geometryFactor}
                                                type="geometryFactorDiamond"/>
                            <MenuItemInputRange name={lang.squash} min={0} max={5}
                                                value={this.props.selectedObjects.material.squashFactor}
                                                type="squashFactorDiamond"/>
                            <MenuItemInputRange name={lang.dispersion} step={0.001} min={0} max={5}
                                                value={this.props.selectedObjects.material.dispersion}
                                                type="dispersionDiamond"/>
                            <MenuItemInputRange name={lang.absorption} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.absorptionFactor}
                                                type="absorptionFactorDiamond"/>
                            <MenuItemInputRange name={lang.gamma} step={0.1} min={0} max={5}
                                                value={this.props.selectedObjects.material.gammaFactor}
                                                type="gammaFactorDiamond"/>
                        </span> : ''
                }

                {
                    this.props.selectedObjects.selected && this.props.selectedObjects.material && !this.props.selectedObjects.material.isDiamond && !this.props.selectedObjects.material.isRefraction ?
                        <span key={this.props.selectedObjects.materialId}>
                            <MenuItemButton3 title={this.state.tooltip != null ? this.state.tooltip.load_material : ''} name={lang.load_material} type="uploadMaterial" onClick={() => this.toggleMenuVisibility('presetLibrary')}
                                        value={this.props.selectedObjects.pickedObject}/>
                            <SaveAsPresetModal title={this.state.tooltip != null ? this.state.tooltip.save_as_new_material : ''} name={lang.save_as_new_material}/>
                            {/*
                            <MenuItemButton name="Save Material" type="downloadMaterial"/>
                            <RenameMaterial name="Rename Material"
                                            value={this.props.selectedObjects.materialId}/>
                            <MenuItemColorPicker name="Color"
                                                value={this.props.selectedObjects.material.color}
                                                type="standardMaterialColor"/>
                            */}
                            
                            {
                                this.props.selectedObjects.pickedObject.material.side != null ?
                                <MenuItemDropdown name={lang.side} options={sideOptions} value={this.props.selectedObjects.pickedObject.material.side} type="sideChange" />   
                                : ''
                            }
                            <MenuItemColorPicker name={lang.color}
                                                value={this.props.selectedObjects.material.color}
                                                type="materialColor"/>
                            {/* <MenuItemCheckbox name={lang.enable_bevel}
                                            value={this.props.config.enableBevel}
                                            type="enableBevel"/>
                        <MenuItemInputRange name={lang.bevel_radius} step={0.1} min={0} max={10}
                                            value={this.props.selectedObjects.material.bevelRadius}
                                            type="bevelRadius"/> */}
                            <MenuItemInputRange name={lang.opacity} step={0.01} min={0} max={1}
                                                value={this.props.selectedObjects.material.opacity}
                                                type="standardMaterialOpacity"/>
                            <MenuItemInputRange name={lang.roughness} min={0} max={1}
                                                value={this.props.selectedObjects.material.roughness < 0 ? 0 : this.props.selectedObjects.material.roughness}
                                                type="standardMaterialRoughness"/>
                            <MenuItemInputRange name={lang.metalness} step={0.01} min={0} max={1}
                                                value={this.props.selectedObjects.material.metalness}
                                                type="standardMaterialMetalness"/>
                            <MenuItemInputRange name={lang.environment_intensity} step={0.1} min={0} max={10}
                                                value={this.props.selectedObjects.material.envMapIntensity}
                                                type="standardMaterialEnvMapIntensity"/>
                            <MenuGroup
                                title={this.state.tooltip != null ? this.state.tooltip.map : ''}
                                name={lang.color_map}
                                onClick={() => this.toggleMenuVisibility('map')}
                                collapsed={this.state.map ? 1 : 0}>
                                {
                                    this.state.map ?
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                        name={this.props.selectedObjects.mapType == 'map' ? lang.replace_file : lang.set_file }
                                        type="uploadMapFile"
                                        value="map"/> : ''
                                }
                                {
                                    this.state.map && 
                                    ((this.props.selectedObjects.mapType && 
                                    this.props.selectedObjects.mapType == 'map') ||
                                    (this.props.selectedObjects.pickedObject.material.map)) 
                                    ? 
                                    
                                    <React.Fragment>
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''}
                                                        name={lang.open_file} type="openMapFile"
                                                        value="map"/>     
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''}
                                                        name={lang.download_file} type="downloadMapFile"
                                                        value="map"/>   
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''}
                                                        name={lang.remove_file} type="removeMapFile"
                                                        value="map"/>  
                                        <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.map ? this.props.selectedObjects.pickedObject.material.map.wrapS : 0} type="colorMapWrapU" />      
                                        <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.map? this.props.selectedObjects.pickedObject.material.map.wrapT : 0} type="colorMapWrapV" />
                                        <MenuItemDropdown name={lang.encoding} options={colorMapEncoding} value={this.props.selectedObjects.pickedObject.material.map? this.props.selectedObjects.pickedObject.material.map.encoding : 3001} type="colorMapEncoding" />
                                        <MenuItemInputRange name={lang.repeat_u} step={0.1} min={0} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.map ? this.props.selectedObjects.pickedObject.material.map.repeat.x : 1}
                                                        type="mapRepeatU"/>    
                                        <MenuItemInputRange name={lang.repeat_v} step={0.1} min={0} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.map ? this.props.selectedObjects.pickedObject.material.map.repeat.y : 1}
                                                        type="mapRepeatV"/> 
                                    </React.Fragment>
                                    : ''
                                }
                            </MenuGroup>

                            {
                                (this.props.userPermission != undefined && this.props.userPermission.isShowLightMap) ? 
                                <MenuGroup
                                    title={this.state.tooltip != null ? this.state.tooltip.light_map : ''}
                                    name={lang.light_map}
                                    onClick={() => this.toggleMenuVisibility('lightMap')}
                                    collapsed={this.state.lightMap ? 1 : 0}>
                                    {
                                        this.state.lightMap ?
                                            <React.Fragment>
                                                <MenuItemButton 
                                                title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                                name={this.props.selectedObjects.mapType == 'lightMap' ? lang.replace_file : lang.set_file }
                                                type="uploadLightMapFile"
                                                value="lightMap"/>
                                            </React.Fragment>
                                            : ''
                                    }
                                    {
                                        this.state.lightMap && 
                                        ((this.props.selectedObjects.mapType && 
                                        this.props.selectedObjects.mapType == 'lightMap') ||
                                        (this.props.selectedObjects.pickedObject.material.lightMap)) 
                                        ? 
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                            value="lightMap"/>     
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                            value="lightMap"/>   
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                            value="lightMap"/>  
                                            <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.lightMap ? this.props.selectedObjects.pickedObject.material.lightMap.wrapS : 0} type="lightMapWrapU" />      
                                            <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.lightMap? this.props.selectedObjects.pickedObject.material.lightMap.wrapT : 0} type="lightMapWrapV" />
                                            <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.lightMap ? this.props.selectedObjects.pickedObject.material.lightMap.repeat.x : 1}
                                                            type="lightMapRepeatU"/>    
                                            <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.lightMap ? this.props.selectedObjects.pickedObject.material.lightMap.repeat.y : 1}
                                                            type="lightMapRepeatV"/> 
                                            <MenuItemInputRange name={lang.intensity}
                                                            value={this.props.selectedObjects.material ? this.props.selectedObjects.material.lightMapIntensity : 0}
                                                            type="standardMaterialLightMapIntensity"/>
                                        </React.Fragment>
                                        : ''
                                    }
                                </MenuGroup>
                                : ''
                            }

                            {
                                (this.props.userPermission != undefined && this.props.userPermission.isShowAOMap) ? 
                                <MenuGroup
                                    title={this.state.tooltip != null ? this.state.tooltip.ao_map : ''}
                                    name={lang.ao_map}
                                    onClick={() => this.toggleMenuVisibility('aoMap')}
                                    collapsed={this.state.aoMap ? 1 : 0}>
                                    {
                                        this.state.aoMap ?
                                            <React.Fragment>
                                                <MenuItemButton 
                                                title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                                name={this.props.selectedObjects.mapType == 'aoMap' ? lang.replace_file : lang.set_file }
                                                type="uploadAoMapFile"
                                                value="aoMap"/>
                                            </React.Fragment>
                                            : ''
                                    }
                                    {
                                        this.state.aoMap && 
                                        ((this.props.selectedObjects.mapType && 
                                        this.props.selectedObjects.mapType == 'aoMap') ||
                                        (this.props.selectedObjects.pickedObject.material.aoMap)) 
                                        ? 
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                            value="aoMap"/>     
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                            value="aoMap"/>   
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                            value="aoMap"/>  

                                            <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.aoMap ? this.props.selectedObjects.pickedObject.material.aoMap.wrapS : 0} type="aoMapWrapU" />      
                                            <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.aoMap? this.props.selectedObjects.pickedObject.material.aoMap.wrapT : 0} type="aoMapWrapV" />
                                            <MenuItemInputRange name={lang.rpeat_u} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.aoMap ? this.props.selectedObjects.pickedObject.material.aoMap.repeat.x : 1}
                                                            type="aoMapRepeatU"/>    
                                            <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.aoMap ? this.props.selectedObjects.pickedObject.material.aoMap.repeat.y : 1}
                                                            type="aoMapRepeatV"/> 

                                            <MenuItemInputRange name={lang.intensity}
                                                            value={this.props.selectedObjects.pickedObject.material.aoMap ? this.props.selectedObjects.pickedObject.material.aoMapIntensity : 0}
                                                            type="standardMaterialAoMapIntensity"/>
                                        </React.Fragment>
                                        : ''
                                    }
                                </MenuGroup>
                                : ''
                            }

                            {
                                (this.props.userPermission != undefined && this.props.userPermission.isShowEmissiveMap) ? 
                                <MenuGroup
                                    title={this.state.tooltip != null ? this.state.tooltip.emissive_map : ''}
                                    name={lang.emissive_map}
                                    onClick={() => this.toggleMenuVisibility('emissiveMap')}
                                    collapsed={this.state.emissiveMap ? 1 : 0}>
                                    {
                                        this.state.emissiveMap ?
                                            <React.Fragment>
                                                <MenuItemButton 
                                                title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                                name={this.props.selectedObjects.mapType == 'emissiveMap' ? lang.replace_file : lang.set_file }
                                                type="uploadEmissiveMapFile"
                                                value="emissiveMap"/>
                                            </React.Fragment>
                                            : ''
                                    }
                                    {
                                        this.state.emissiveMap && 
                                        ((this.props.selectedObjects.mapType && 
                                        this.props.selectedObjects.mapType == 'emissiveMap') ||
                                        (this.props.selectedObjects.pickedObject.material.emissiveMap)) 
                                        ? 
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                            value="emissiveMap"/>     
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                            value="emissiveMap"/>   
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                            value="emissiveMap"/>  
                                            <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.emissiveMap ? this.props.selectedObjects.pickedObject.material.emissiveMap.wrapS : 0} type="emissiveMapWrapU" />      
                                            <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.emissiveMap? this.props.selectedObjects.pickedObject.material.emissiveMap.wrapT : 0} type="emissiveMapWrapV" />
                                            <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.emissiveMap ? this.props.selectedObjects.pickedObject.material.emissiveMap.repeat.x : 1}
                                                            type="emissiveMapRepeatU"/>    
                                            <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.emissiveMap ? this.props.selectedObjects.pickedObject.material.emissiveMap.repeat.y : 1}
                                                            type="emissiveMapRepeatV"/> 
                                            <MenuItemInputRange name={lang.intensity}
                                                            value={this.props.selectedObjects.material ? this.props.selectedObjects.material.emissiveIntensity : 0}
                                                            type="standardMaterialEmissiveIntensity"/>
                                            <MenuItemColorPicker name={lang.color}
                                                                value={this.props.selectedObjects.material ? this.props.selectedObjects.material.emissive : 0}
                                                                type="standardMaterialEmissive"/>
                                        </React.Fragment>
                                        : ''
                                    }
                                </MenuGroup>
                                : ''
                            }

                            <MenuGroup
                                title={this.state.tooltip != null ? this.state.tooltip.bump_map : ''}
                                name={lang.bump_map}
                                onClick={() => this.toggleMenuVisibility('bumpMap')}
                                collapsed={this.state.bumpMap ? 1 : 0}>
                                {
                                    this.state.bumpMap ?
                                        <React.Fragment>
                                            <MenuItemButton 
                                            title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                            name={this.props.selectedObjects.mapType == 'bumpMap' ? lang.replace_file : lang.set_file }
                                            type="uploadBumpMapFile"
                                            value="bumpMap"/>
                                        </React.Fragment>
                                        : ''
                                }
                                {
                                    this.state.bumpMap && 
                                    ((this.props.selectedObjects.mapType && 
                                    this.props.selectedObjects.mapType == 'bumpMap') ||
                                    (this.props.selectedObjects.pickedObject.material.bumpMap)) 
                                    ? 
                                    <React.Fragment>
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                        value="bumpMap"/>     
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                        value="bumpMap"/>   
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                        value="bumpMap"/>  
                                        <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.bumpMap ? this.props.selectedObjects.pickedObject.material.bumpMap.wrapS : 0} type="bumpMapWrapU" />      
                                        <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.bumpMap? this.props.selectedObjects.pickedObject.material.bumpMap.wrapT : 0} type="bumpMapWrapV" />
                                        <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.bumpMap ? this.props.selectedObjects.pickedObject.material.bumpMap.repeat.x : 1}
                                                        type="bumpMapRepeatU"/>    
                                        <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.bumpMap ? this.props.selectedObjects.pickedObject.material.bumpMap.repeat.y : 1}
                                                        type="bumpMapRepeatV"/> 
                                        <MenuItemInputRange name={lang.bump_scale} step={0.01} min={0.01} max={10}
                                                        value={this.props.selectedObjects.material ? this.props.selectedObjects.material.bumpScale : 0.01}
                                                        type="standardMaterialBumpScale"/> 
                                        {/* <MenuItemTextBox name="Bump Scale"
                                                        value={this.props.selectedObjects.material ? this.props.selectedObjects.material.bumpScale : 0}
                                                        type="standardMaterialBumpScale"/> */}
                                    </React.Fragment>
                                    : ''
                                }
                            </MenuGroup>

                            <MenuGroup
                                title={this.state.tooltip != null ? this.state.tooltip.normal_map : ''}
                                name={lang.normal_map}
                                onClick={() => this.toggleMenuVisibility('normalMap')}
                                collapsed={this.state.normalMap ? 1 : 0}>
                                {
                                    this.state.normalMap ?
                                        <React.Fragment>
                                            <MenuItemButton 
                                            title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''}
                                            name={this.props.selectedObjects.mapType == 'normalMap' ? lang.replace_file : lang.set_file }
                                            type="uploadNormalMapFile"
                                            value="normalMap"/>
                                        </React.Fragment>
                                        : ''
                                }
                                {
                                    this.state.normalMap && 
                                    ((this.props.selectedObjects.mapType && 
                                    this.props.selectedObjects.mapType == 'normalMap') ||
                                    (this.props.selectedObjects.pickedObject.material.normalMap)) 
                                    ? 
                                    <React.Fragment>
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                        value="normalMap"/>     
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                        value="normalMap"/>   
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                        value="normalMap"/>  
                                        <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.normalMap ? this.props.selectedObjects.pickedObject.material.normalMap.wrapS : 0} type="normalMapWrapU" />      
                                        <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.normalMap ? this.props.selectedObjects.pickedObject.material.normalMap.wrapT : 0} type="normalMapWrapV" />
                                        <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.normalMap ? this.props.selectedObjects.pickedObject.material.normalMap.repeat.x : 1}
                                                        type="normalMapRepeatU"/>    
                                        <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.normalMap ? this.props.selectedObjects.pickedObject.material.normalMap.repeat.y : 1}
                                                        type="normalMapRepeatV"/> 
                                        <MenuItemInputRange name={lang.scale_x} step={0.01} min={0.01} max={10}
                                                        value={this.props.selectedObjects.material ? this.props.selectedObjects.material.normalScale[0] : 0.01}
                                                        type="changeNormalScaleX"/> 
                                        <MenuItemInputRange name={lang.scale_y} step={0.01} min={0.01} max={10}
                                                        value={this.props.selectedObjects.material ? this.props.selectedObjects.material.normalScale[1] : 0.01}
                                                        type="changeNormalScaleY"/> 
                                        <MenuItemDropdown name={lang.normal_map_type} options={normalMapType} value={this.props.selectedObjects.material.normalMapType ? this.props.selectedObjects.pickedObject.material.normalMapType : 0} type="changeNormalMapType" />     
                                    </React.Fragment>
                                    : ''
                                }
                            </MenuGroup>

                            <MenuGroup
                                title={this.state.tooltip != null ? this.state.tooltip.roughness_map : ''}
                                name={lang.roughness_map}
                                onClick={() => this.toggleMenuVisibility('roughnessMap')}
                                collapsed={this.state.roughnessMap ? 1 : 0}>
                                {
                                    this.state.roughnessMap ?
                                        <React.Fragment>
                                            <MenuItemButton 
                                            title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''} name={this.props.selectedObjects.mapType == 'roughnessMap' ? lang.replace_file : lang.set_file } 
                                                            type="uploadRoughnessMapFile"
                                                            value="roughnessMap"/>
                                        </React.Fragment>
                                        : ''
                                }
                                {
                                    this.state.roughnessMap && 
                                    ((this.props.selectedObjects.mapType && 
                                    this.props.selectedObjects.mapType == 'roughnessMap') ||
                                    (this.props.selectedObjects.pickedObject.material.roughnessMap)) 
                                    ? 
                                    <React.Fragment>
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                        value="roughnessMap"/>     
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                        value="roughnessMap"/>   
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                        value="roughnessMap"/>  
                                        <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.roughnessMap ? this.props.selectedObjects.pickedObject.material.roughnessMap.wrapS : 0} type="roughnessMapWrapU" />      
                                        <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.roughnessMap? this.props.selectedObjects.pickedObject.material.roughnessMap.wrapT : 0} type="roughnessMapWrapV" />
                                        <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.roughnessMap ? this.props.selectedObjects.pickedObject.material.roughnessMap.repeat.x : 1}
                                                        type="roughnessMapRepeatU"/>    
                                        <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.roughnessMap ? this.props.selectedObjects.pickedObject.material.roughnessMap.repeat.y : 1}
                                                        type="roughnessMapRepeatV"/> 
                                    </React.Fragment>
                                    : ''
                                }
                            </MenuGroup>

                            <MenuGroup
                                title={this.state.tooltip != null ? this.state.tooltip.metalness_map : ''}
                                name={lang.metalness_map}
                                onClick={() => this.toggleMenuVisibility('metalnessMap')}
                                collapsed={this.state.metalnessMap ? 1 : 0}>
                                {
                                    this.state.metalnessMap ?
                                        <React.Fragment>
                                            <MenuItemButton 
                                            title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''} name={this.props.selectedObjects.mapType == 'metalnessMap' ? lang.replace_file : lang.set_file }
                                                            type="uploadMetalnessMapFile"
                                                            value="metalnessMap"/>
                                        </React.Fragment>
                                        : ''
                                }
                                {
                                    this.state.metalnessMap && 
                                    ((this.props.selectedObjects.mapType && 
                                    this.props.selectedObjects.mapType == 'metalnessMap') ||
                                    (this.props.selectedObjects.pickedObject.material.metalnessMap)) 
                                    ? 
                                    <React.Fragment>
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_file} type="openMapFile"
                                                        value="metalnessMap"/>     
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                        value="metalnessMap"/>   
                                        <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                        value="metalnessMap"/>  
                                                        
                                        <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.metalnessMap ? this.props.selectedObjects.pickedObject.material.metalnessMap.wrapS : 0} type="metalnessMapWrapU" />      
                                        <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.metalnessMap? this.props.selectedObjects.pickedObject.material.metalnessMap.wrapT : 0} type="metalnessMapWrapV" />
                                        <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.metalnessMap ? this.props.selectedObjects.pickedObject.material.metalnessMap.repeat.x : 1}
                                                        type="metalnessMapRepeatU"/>    
                                        <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                        value={this.props.selectedObjects.pickedObject.material.metalnessMap ? this.props.selectedObjects.pickedObject.material.metalnessMap.repeat.y : 1}
                                                        type="metalnessMapRepeatV"/> 
                                    </React.Fragment>
                                    : ''
                                }
                            </MenuGroup>

                            {
                                (this.props.userPermission != undefined && this.props.userPermission.isShowAlphaMap) ?
                                <MenuGroup
                                    title={this.state.tooltip != null ? this.state.tooltip.alpha_map : ''}
                                    name={lang.alpha_map}
                                    onClick={() => this.toggleMenuVisibility('alphaMap')}
                                    collapsed={this.state.alphaMap ? 1 : 0}>
                                    {
                                        this.state.alphaMap ?
                                            <React.Fragment>
                                                <MenuItemButton
                                                title={this.state.tooltip != null ? this.state.tooltip.set_replace_file : ''} name={this.props.selectedObjects.mapType == 'alphaMap' ? lang.replace_file : lang.set_file } type="uploadAlphaMapFile"
                                                                value="alphaMap"/>
                                            </React.Fragment>
                                            : ''
                                    }
                                    {
                                        this.state.alphaMap && 
                                        ((this.props.selectedObjects.mapType && 
                                        this.props.selectedObjects.mapType == 'alphaMap') ||
                                        (this.props.selectedObjects.pickedObject.material.alphaMap)) 
                                        ? 
                                        <React.Fragment>
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.open_file : ''} name={lang.open_files} type="openMapFile"
                                                            value="alphaMap"/>     
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.download_file : ''} name={lang.download_file} type="downloadMapFile"
                                                            value="alphaMap"/>   
                                            <MenuItemButton title={this.state.tooltip != null ? this.state.tooltip.remove_file : ''} name={lang.remove_file} type="removeMapFile"
                                                            value="alphaMap"/>  
                                                            
                                            <WarpUVDropdown name={lang.wrap_u} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.alphaMap ? this.props.selectedObjects.pickedObject.material.alphaMap.wrapS : 0} type="alphaMapWrapU" />      
                                            <WarpUVDropdown name={lang.wrap_v} options={mapWrapUV} value={this.props.selectedObjects.pickedObject.material.alphaMap? this.props.selectedObjects.pickedObject.material.alphaMap.wrapT : 0} type="alphaMapWrapV" />
                                            <MenuItemInputRange name={lang.repeat_u} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.alphaMap ? this.props.selectedObjects.pickedObject.material.alphaMap.repeat.x : 1}
                                                            type="alphaMapRepeatU"/>    
                                            <MenuItemInputRange name={lang.repeat_v} step={0.1} min={1} max={10}
                                                            value={this.props.selectedObjects.pickedObject.material.alphaMap ? this.props.selectedObjects.pickedObject.material.alphaMap.repeat.y : 1}
                                                            type="alphaMapRepeatV"/> 
                                            <MenuItemInputRange name={lang.intensity}
                                                            value={this.props.selectedObjects.material ? this.props.selectedObjects.material.envMapIntensity : 0}
                                                            type="standardMaterialAlphaMapIntensity"/>
                                        </React.Fragment>
                                        : ''
                                    }
                                </MenuGroup>
                                : ''
                            }

                            {
                                (this.props.userPermission != undefined && this.props.userPermission.isShowWireframe) ?
                                <MenuGroup
                                    title={this.state.tooltip != null ? this.state.tooltip.wireframe : ''}
                                    name={lang.wireframe}
                                    onClick={() => this.toggleMenuVisibility('wireframe')}
                                    collapsed={this.state.wireframe ? 1 : 0}>
                                    {
                                        this.state.wireframe ?
                                            <React.Fragment>
                                                <MenuItemCheckbox name={lang.enable}
                                                                value={this.props.selectedObjects.material.wireframe}
                                                                type="standardMaterialEnableWireframe"/>
                                                {/*<MenuItemTextBox name="Line Width"
                                                                value={this.props.selectedObjects.material.wireframeLinewidth}
                                                                type="standardMaterialLineWidth"/>*/}
                                            </React.Fragment>
                                            : ''
                                    }
                                </MenuGroup>
                                : ''
                            }
                        </span> : ''
                }

                {
                    this.props.selectedObjects.selected && this.props.selectedObjects.material && this.props.selectedObjects.material.isRefraction ?
                        <span key={this.props.selectedObjects.materialId}>
                            <MenuItemButton3 title={this.state.tooltip != null ? this.state.tooltip.load_material : ''} name={lang.load_material} type="uploadMaterial" onClick={() => this.toggleMenuVisibility('presetLibrary')}
                                        value={this.props.selectedObjects.pickedObject}/>
                            <SaveAsPresetModal title={this.state.tooltip != null ? this.state.tooltip.save_as_new_material : ''} name={lang.save_as_new_material}/>
                            {/*
                            <MenuItemButton name="Save Material" type="downloadMaterial"/>
                            <RenameMaterial name="Rename Material"
                                            value={this.props.selectedObjects.materialId}/>
                            
                            <MenuItemCheckbox name="Visible"
                                            value={this.props.selectedObjects.material.visible}
                                            type="refractionMaterialToggleVisibility"/>
                            */}
                            
                            {
                                this.props.selectedObjects.material.side ?
                                <MenuItemDropdown name={lang.side} options={sideOptions} value={this.props.selectedObjects.pickedObject.material.side} type="sideChange" />   
                                : ''
                            }
                            <MenuItemColorPicker name={lang.color}
                                                value={this.props.selectedObjects.material.refractionTintColor}
                                                type="refractionMaterialTintColor"/>
                            <MenuItemColorPicker name={lang.diffuse}
                                                value={this.props.selectedObjects.material.diffuse}
                                                type="refractionMaterialDiffuse"/>
                            <MenuItemInputRange name={lang.index_of_refraction} step={0.1} min={0}
                                                max={2.5}
                                                value={this.props.selectedObjects.material.refractionIndex}
                                                type="refractionMaterialRefractionIndex"/>
                            <MenuItemInputRange name={lang.refraction_roughness} min={0} max={1}
                                                value={this.props.selectedObjects.material.refractionRoughness}
                                                type="refractionMaterialRefractionRoughness"/>
                            <MenuItemInputRange name={lang.roughness} min={0} max={1}
                                                value={this.props.selectedObjects.material.roughness}
                                                type="refractionMaterialRoughness"/>
                            <MenuItemInputRange name={lang.metalness} step={0.1} min={0} max={1}
                                                value={this.props.selectedObjects.material.metalness}
                                                type="refractionMaterialMetalness"/>
                            <MenuItemInputRange name={lang.transparency} min={0} max={1}
                                                value={this.props.selectedObjects.material.transparency}
                                                type="refractionMaterialTransparency"/>
                        </span> : ''
                }


                </React.Fragment> 


                <br/>



                <Row>
                    <Col md={12}>
                        <h5 className="app-name-sidebar" style={{fontSize:11+'px'}}>{lang.create_your_combinations}</h5>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '2.5%', paddingLeft: '0'}}>
                        {/* <AddCombinationModal /> */}
                        <AddCombinationSceneInfoAlertModal/>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '0', paddingLeft: '0'}}>
                        <ShowCombinationsModal />
                    </Col>
                </Row>

                

            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(MaterialSettings)
// export default MaterialSettings