import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    editPresetFolder,
} from "../../../../actions/actionsCreators";
import trashIcon from "../../../../assets/trash@2x.png";
import brushIcon from "../../../../assets/rename.png";
import closeIcon from "../../../../assets/close-icon@2x.png";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';
let lang = {};
class EditPresetFolderModal extends Component {
    componentDidMount() {
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        folderName: this.props.folderName
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    handleChange = e => {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    /**
     * Send request to delete preset folder also remove it from state
     */
    editPresetFolder = () => {
        this.setState({loading: true})
        axios.post('preset/edit-folder', {"id": this.props.uuid, "name": this.state.folderName}).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.props.editPresetFolder(this.props.uuid)
            }

            this.setState({
                show: false
            })
        }).catch(err => {
            this.setState({
                hasError: true,
                error: 'Error occurred, please contact support!',
                loading: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <img className="trash-icon material-gallery-icon" src={brushIcon} alt="" onClick={this.handleShow}/>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>{lang.rename_material_folder}</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                                <span>{lang.enter_new_material_folder_name}</span>
                            </Col>
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <Spinner animation="border" role="status" variant="primary">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={12} className="text-center">
                                <input id="field-update-material-folder" className="name-input" name="folderName" type="text" value={this.state.folderName} onChange={this.handleChange} />
                                <Button id="btn-update-material-folder" variant="primary" className="btn-delete-preset" onClick={this.editPresetFolder} disabled={this.state.loading}>
                                    {lang.update}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    editPresetFolder: editPresetFolder,
}

export default connect(null, mapDispatchToProps)(EditPresetFolderModal)