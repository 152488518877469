import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import JoditEditor from "jodit-react";
import {
    changeAnnotationText,
    changeAnnotationTitle
} from "../../../../actions/actionsCreators";

function RichTextEditor(props) {


	const [content, setContent] = useState('')
	const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}


    const [value, setState] = useState({
        value: props.value
    })

    const updateField = (e) => {
        
        // setState({
        //     ...value,
        //     value: e
        // });
        console.log("e.target.value: " + e);
        console.log(JSON.stringify(value));
        dispatchAction(props.type, e)
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
        switch (type) {
            case 'changeAnnotationText':
                props.changeAnnotationText(value)
                break;
            case 'changeAnnotationTitle':
                props.changeAnnotationTitle(value)
                break;
            default:
                return null;
        }
    }

    return (
        <Row /*{...props}*/>
            <Col md={6} className="setting-col-input">
                <span title={props.title} className="setting-name">{props.name}</span>
            </Col>
            <Col md={12} className="setting-col-input">
                {/* <Form.Control
                    size="sm"
                    type="text"
                    className="menu-item-text-box"
                    value={value.value}
                    onChange={updateField}
                    disabled={!!props.disabled}
                    readOnly={!!props.readonly}/> */}
                <JoditEditor
                    value={value.value}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => updateField(newContent)} // preferred to use only this option to update the content for performance reasons
                    // onChange={newContent => updateField(newContent)}
                />
            </Col>

        </Row>
    )
}

const mapDispatchToProps = {
    changeAnnotationText: changeAnnotationText,
    changeAnnotationTitle: changeAnnotationTitle
}

export default connect(null, mapDispatchToProps)(RichTextEditor)