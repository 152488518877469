import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../axios';
import {
    saveCombinationToState,
    createTracker
} from "../../../actions/actionsCreators";
import closeIcon from "../../../assets/close-icon@2x.png";
import {SpinnerSmall} from "../../../helpers/spinners";
import {Link} from "react-router-dom";
import MenuItemTextArea from "../../Configurator/Sidebar/MenuItems/MenuItemTextArea";

class GetEmbeddedCombinationLinkModal extends Component {
    
    
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        chosenCombinationId: '',
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        if (!this.props.embedded) {
            this.setState({
                show: true,
                hasError: true,
                error: 'Please select combination first !'
            })
        } else {
            this.setState({
                show: true,
                hasError: false,
                error: ''
            })
            this.loadCombination()
        }
    }

    /**
     * Send request to load combination
     */
    loadCombination = () => {
        this.setState({loading: true})
        axios.post('combination/get', {
            id: this.props.combinationUuid,
            getMaterial: true,
            getConfig: true,
            getGeometry: true
        }).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.props.saveCombinationToState(data.data.combination)
                this.props.createTracker('embedded', this.props.embedded)

                this.setState({chosenCombinationId: data.data.combination.uuid})
            }

        }).catch(err => {
            this.setState({
                hasError: true,
                error: err.message,
                loading: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <Button disabled={this.props.embedded == null} className="comb-get-link-btn" variant="success" onClick={this.handleShow}>GET EMBEDDED</Button>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>View Combination</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <SpinnerSmall />
                                    </Col>
                                </Row> : ''
                        }
                        {
                            !this.state.loading && !this.state.hasError ?
                                <Row className="rename-material-modal-body">
                                    <Col md={12}>
                                        <span>Link generated !</span>
                                    </Col>
                                    <Col md={12} className="text-center">
                                        <Link
                                            to={{
                                                pathname: `/iframe/` + this.state.chosenCombinationId,
                                                state: {combination: this.props.iframe}
                                            }}
                                            className="btn btn-primary"
                                            target="_blank"
                                        >View</Link>
                                        
                                    </Col>
                                    <Col md={12}>
                                        <span>Use this embedded iframe:</span>
                                    </Col>
                                    <Col md={12}>
                                        <MenuItemTextArea style={{width: 100+'%', height: 165+'px'}}
                                        value={this.state.chosenCombinationId}
                                        type="iframe"
                                        width="750"
                                        height="480"
                                        />
                                    </Col>
                                </Row> : ''
                        }
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    saveCombinationToState: saveCombinationToState,
    createTracker: createTracker
}

const mapStateToProps = state => {
    return {
        iframe: state.iframe.combination
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetEmbeddedCombinationLinkModal)