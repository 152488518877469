export const LOAD_FILE_ACTION = 'LOAD_FILE_ACTION';
export const SAVE_SCENE_CONFIG_TO_STATE = 'SAVE_SCENE_CONFIG_TO_STATE';
export const DOWNLOAD_SCENE = 'DOWNLOAD_SCENE';
export const SAVE_API_TO_STATE = 'SAVE_API_TO_STATE'

//Load report
export const LOAD_INITIAL_REPORT_REQUEST = 'LOAD_INITIAL_REPORT_REQUEST';
export const LOAD_REPORT_REQUEST = 'LOAD_REPORT_REQUEST';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_ERROR = 'LOAD_REPORT_ERROR';
export const REQUEST_TRACKERS_FILTERS = 'REQUEST_TRACKERS_FILTERS';
export const REQUEST_TRACKERS_FILTERS_SUCCESS = 'REQUEST_TRACKERS_FILTERS_SUCCESS';
export const REQUEST_TRACKERS_FILTERS_FAILED = 'REQUEST_TRACKERS_FILTERS_FAILED';
export const REQUEST_TRACKERS_FILTERS_ERROR = 'REQUEST_TRACKERS_FILTERS_ERROR';
export const LOAD_REPORT_FAILED = 'LOAD_REPORT_FAILED';

//Scene settings
export const CHANGE_MIN_CAMERA_CLIP = 'CHANGE_MIN_CAMERA_CLIP'
export const CHANGE_FAR_NEAR_MULTIPLIER = 'CHANGE_FAR_NEAR_MULTIPLIER'
export const CHANGE_DAMPING_FACTOR = 'CHANGE_DAMPING_FACTOR'
export const CHANGE_ZOOM_SPEED = 'CHANGE_ZOOM_SPEED'
export const CHANGE_MIN_AZIMUTH_ANGLE = 'CHANGE_MIN_AZIMUTH_ANGLE'
export const CHANGE_MAX_AZIMUTH_ANGLE = 'CHANGE_MAX_AZIMUTH_ANGLE'
export const CHANGE_MIN_POLAR_ANGLE = 'CHANGE_MIN_POLAR_ANGLE'
export const CHANGE_MAX_POLAR_ANGLE = 'CHANGE_MAX_POLAR_ANGLE'
export const CHANGE_MIN_ZOOM = 'CHANGE_MIN_ZOOM'
export const CHANGE_MAX_ZOOM = 'CHANGE_MAX_ZOOM'
export const CHANGE_MAX_DISTANCE = 'CHANGE_MAX_DISTANCE'
export const CHANGE_MIN_DISTANCE = 'CHANGE_MIN_DISTANCE'


export const CHANGE_MIN_SPEED = 'CHANGE_MIN_SPEED'
export const CHANGE_MAX_SPEED = 'CHANGE_MAX_SPEED'

export const CHANGE_CAMERA_ZOOM = 'CHANGE_CAMERA_ZOOM'
export const CHANGE_FOV_SETTING = 'CHANGE_FOV_SETTING'
export const CHANGE_SCENE_VIEW = 'CHANGE_SCENE_VIEW';
export const DOWNLOAD_GLTF = 'DOWNLOAD_GLTF';
export const SNAP_CANVAS = 'SNAP_CANVAS';
export const CHANGE_CAMERA_MODE_SETTING = 'CHANGE_CAMERA_MODE_SETTING';
export const CHANGE_CAMERA_POSITION_X_SETTING = 'CHANGE_CAMERA_POSITION_X_SETTING';
export const CHANGE_CAMERA_POSITION_Y_SETTING = 'CHANGE_CAMERA_POSITION_Y_SETTING';
export const CHANGE_CAMERA_POSITION_Z_SETTING = 'CHANGE_CAMERA_POSITION_Z_SETTING';
export const CHANGE_CAMERA_POSITION_SETTING = 'CHANGE_CAMERA_POSITION_SETTING';
export const CHANGE_CAMERA_TARGET_SETTING = 'CHANGE_CAMERA_TARGET_SETTING';
export const CHANGE_CAMERA_SETTING = 'CHANGE_CAMERA_SETTING';
export const CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING = 'CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING';
export const RESET_STRAIGHTEN_MODEL = 'RESET_STRAIGHTEN_MODEL';
export const TAKE_SCREENSHOT = 'TAKE_SCREENSHOT';
export const ENABLE_GROUND_SHADOW_SETTING = 'ENABLE_GROUND_SHADOW_SETTING';
export const CHANGE_NUM_SAMPLES_SETTING = 'CHANGE_NUM_SAMPLES_SETTING';
export const CHANGE_NUM_SAMPLES_PER_FRAME_SETTING = 'CHANGE_NUM_SAMPLES_PER_FRAME_SETTING';
export const CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING = 'CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING';
export const CHANGE_BLUR_RADIUS_SETTING = 'CHANGE_BLUR_RADIUS_SETTING';
export const CHANGE_GROUND_SHARPNESS_SETTING = 'CHANGE_GROUND_SHARPNESS_SETTING';
export const CHANGE_FALLOFF_SETTING = 'CHANGE_FALLOFF_SETTING';
export const ENABLE_SMOOTH_SETTING = 'ENABLE_SMOOTH_SETTING';
export const CHANGE_SMOOTH_TRANSITION_SETTING = 'CHANGE_SMOOTH_TRANSITION_SETTING';
export const CHANGE_GROUND_SHADOW_SIDE_SETTING = 'CHANGE_GROUND_SHADOW_SIDE_SETTING';
export const ENABLE_GROUND_REFLECTION_SETTING = 'ENABLE_GROUND_REFLECTION_SETTING';
export const ENABLE_GROUND_REFLECTION_PHYSICAL_SETTING = 'CHANGE_GROUND_REFLECTION_PHYSICAL_SETTING';
export const CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING = 'CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING';
export const CHANGE_GROUND_REFLECTION_METALNESS_SETTING = 'CHANGE_GROUND_REFLECTION_METALNESS_SETTING';
export const CHANGE_GROUND_REFLECTION_SIZE_SETTING = 'CHANGE_GROUND_REFLECTION_SIZE_SETTING';
export const DOWNLOAD_REFLECTION = 'DOWNLOAD_REFLECTION';
export const VIEW_REFLECTION = 'VIEW_REFLECTION';
export const LOAD_REFLECTION = 'LOAD_REFLECTION';
export const CLEAR_ALL = 'CLEAR_ALL';
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';
export const REMOVE_BACKGROUND = 'REMOVE_BACKGROUND';
export const ENABLE_AUTO_ROTATE = 'ENABLE_AUTO_ROTATE';
export const CHANGE_AUTO_ROTATE_SPEED_SETTING = 'CHANGE_AUTO_ROTATE_SPEED_SETTING';
export const UPLOAD_SCENE = 'UPLOAD_SCENE';
export const CHANGE_TONE_MAPPING_EXPOSURE_SETTING = 'CHANGE_TONE_MAPPING_EXPOSURE_SETTING';
export const CHANGE_TONE_MAPPING_SATURATION_SETTING = 'CHANGE_TONE_MAPPING_SATURATION_SETTING';
export const CHANGE_TONE_MAPPING_CONTRAST_SETTING = 'CHANGE_TONE_MAPPING_CONTRAST_SETTING';
export const CHANGE_ENV_MAP_ROTATION_SETTING = 'CHANGE_ENV_MAP_ROTATION_SETTING';
export const CHANGE_BACKGROUND_IMAGE_FILE = 'CHANGE_BACKGROUND_IMAGE_FILE';
export const ENABLE_SCREEN_SPACE_PANNING = 'ENABLE_SCREEN_SPACE_PANNING';
export const ENABLE_ZOOM = 'ENABLE_ZOOM';
export const ENABLE_PAN = 'ENABLE_PAN';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const CHANGE_PIXEL_RATIO_SETTING = 'CHANGE_PIXEL_RATIO_SETTING';

//Outline Settings
export const CHANGE_EDGE_GLOW_SETTING = 'CHANGE_EDGE_GLOW_SETTING';
export const CHANGE_EDGE_THICKNESS_SETTING = 'CHANGE_EDGE_THICKNESS_SETTING';
export const CHANGE_EDGE_STRENGTH_SETTING = 'CHANGE_EDGE_STRENGTH_SETTING';
export const CHANGE_PULSE_PERIOD_SETTING = 'CHANGE_PULSE_PERIOD_SETTING';
export const CHANGE_VISIBLE_EDGE_COLOR_SETTING = 'CHANGE_VISIBLE_EDGE_COLOR_SETTING';
export const CHANGE_HIDDEN_EDGE_COLOR_SETTING = 'CHANGE_HIDDEN_EDGE_COLOR_SETTING';
export const CHANGE_DOWN_SAMPLE_RATIO_SETTING = 'CHANGE_DOWN_SAMPLE_RATIO_SETTING';

//Post Process Settings
export const CHANGE_BEVEL_SETTING = 'CHANGE_BEVEL_SETTING';
export const CHANGE_SHARPNESS_SETTING = 'CHANGE_SHARPNESS_SETTING';
export const ENABLE_SHARPNESS_SETTING = 'ENABLE_SHARPNESS_SETTING';
export const ENABLE_SSAO_SETTING = 'ENABLE_SSAO_SETTING';
export const ENABLE_SSAO_DEBUG_SETTING = 'ENABLE_SSAO_DEBUG_SETTING';
export const CHANGE_SSAO_INTENSITY_SETTING = 'CHANGE_SSAO_INTENSITY_SETTING';
export const CHANGE_SSAO_RADIUS_SETTING = 'CHANGE_SSAO_RADIUS_SETTING';
export const CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING = 'CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING';
export const ENABLE_BLOOM_SETTING = 'ENABLE_BLOOM_SETTING';
export const CHANGE_BLOOM_STRENGTH_SETTING = 'CHANGE_BLOOM_STRENGTH_SETTING';
export const CHANGE_BLOOM_RADIUS_SETTING = 'CHANGE_BLOOM_RADIUS_SETTING';
export const CHANGE_BLOOM_THRESHOLD_SETTING = 'CHANGE_BLOOM_THRESHOLD_SETTING';
export const ENABLE_DOF = 'ENABLE_DOF';
export const ENABLE_EDIT_DOF = 'ENABLE_EDIT_DOF';
export const CHANGE_CROSS_COLOR_SETTING = 'CHANGE_CROSS_COLOR_SETTING';
export const CHANGE_CROSS_RADIUS_SETTING = 'CHANGE_CROSS_RADIUS_SETTING';
export const CHANGE_NEAR_BLUR_SCALE_SETTING = 'CHANGE_NEAR_BLUR_SCALE_SETTING';
export const CHANGE_FAR_BLUR_SCALE_SETTING = 'CHANGE_FAR_BLUR_SCALE_SETTING';
export const CHANGE_DEPTH_RANGE_SETTING = 'CHANGE_DEPTH_RANGE_SETTING';
export const ENABLE_AR = 'ENABLE_AR';
export const CHANGE_OBJECT_SCALE_SETTING = 'CHANGE_OBJECT_SCALE_SETTING';
export const CHANGE_RETICLE_SIZE_IN_SETTING = 'CHANGE_RETICLE_SIZE_IN_SETTING';
export const CHANGE_RETICLE_SIZE_OUT_SETTING = 'CHANGE_RETICLE_SIZE_OUT_SETTING';
export const CHANGE_RETICLE_FADE_THRESH_SETTING = 'CHANGE_RETICLE_FADE_THRESH_SETTING';
export const CHANGE_RETICLE_FADE_TIME_SETTING = 'CHANGE_RETICLE_FADE_TIME_SETTING';
export const CHANGE_GROUND_SHADOWS_SETTING = 'CHANGE_GROUND_SHADOWS_SETTING';
export const CHANGE_RETICLE_COLOR_SETTING = 'CHANGE_RETICLE_COLOR_SETTING';

//Materials Settings
export const SELECT_OBJECT = 'SELECT_OBJECT';
export const CHANGE_MATERIAL_COLOR = 'CHANGE_MATERIAL_COLOR';
export const CHANGE_BOOST_FACTOR_SETTING = 'CHANGE_BOOST_FACTOR_SETTING';
export const CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING = 'CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING';
export const CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING = 'CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING';
export const CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING = 'CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING';
export const CHANGE_SQUASH_FACTOR_DIAMOND_SETTING = 'CHANGE_SQUASH_FACTOR_DIAMOND_SETTING';
export const CHANGE_DISPERSION_DIAMOND_SETTING = 'CHANGE_DISPERSION_DIAMOND_SETTING';
export const CHANGE_REFLECTIVITY_DIAMOND_SETTING = 'CHANGE_REFLECTIVITY_DIAMOND_SETTING';
export const CHANGE_ABSORPTION_DIAMOND_SETTING = 'CHANGE_ABSORPTION_DIAMOND_SETTING';
export const CHANGE_GAMMA_FACTOR_DIAMOND_SETTING = 'CHANGE_GAMMA_FACTOR_DIAMOND_SETTING';
export const MAKE_STANDARD = 'MAKE_STANDARD';
export const MAKE_DIAMOND = 'MAKE_DIAMOND';
export const MAKE_REFRACTION = 'MAKE_REFRACTION';
export const CHANGE_PICKED_GROUP_VISIBILITY = 'CHANGE_PICKED_GROUP_VISIBILITY';
export const CHANGE_STANDARD_MATERIAL_COLOR = 'CHANGE_STANDARD_MATERIAL_COLOR';
export const CHANGE_STANDARD_MATERIAL_OPACITY = 'CHANGE_STANDARD_MATERIAL_OPACITY';
export const CHANGE_STANDARD_MATERIAL_ROUGHNESS = 'CHANGE_STANDARD_MATERIAL_ROUGHNESS';
export const CHANGE_STANDARD_MATERIAL_METALNESS = 'CHANGE_STANDARD_MATERIAL_METALNESS';
export const CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY = 'CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY';
export const CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY = 'CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY';
export const CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY = 'CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY';
export const CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY = 'CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY';
export const CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY = 'CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY';
export const CHANGE_STANDARD_MATERIAL_BUMP_SCALE = 'CHANGE_STANDARD_MATERIAL_BUMP_SCALE';
export const CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH = 'CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH';
export const CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME = 'CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME';
export const CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR = 'CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR';
export const CHANGE_STANDARD_MATERIAL_MAP_FILE = 'CHANGE_STANDARD_MATERIAL_MAP_FILE';
export const CHANGE_REFRACTION_MATERIAL_VISIBILITY = 'CHANGE_REFRACTION_MATERIAL_VISIBILITY';
export const CHANGE_REFRACTION_MATERIAL_TINT_COLOR = 'CHANGE_REFRACTION_MATERIAL_TINT_COLOR';
export const CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR = 'CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR';
export const CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX = 'CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX';
export const CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS = 'CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS';
export const CHANGE_REFRACTION_MATERIAL_ROUGHNESS = 'CHANGE_REFRACTION_MATERIAL_ROUGHNESS';
export const CHANGE_REFRACTION_MATERIAL_METALNESS = 'CHANGE_REFRACTION_MATERIAL_METALNESS';
export const CHANGE_REFRACTION_MATERIAL_TRANSPARENCY = 'CHANGE_REFRACTION_MATERIAL_TRANSPARENCY';
export const UPLOAD_MATERIAL = 'UPLOAD_MATERIAL';
export const SELECT_MATERIAL = 'SELECT_MATERIAL';
export const CHANGE_MATERIAL_NAME = 'CHANGE_MATERIAL_NAME';
export const SELECT_MESH = 'SELECT_MESH';
export const UPDATE_MATERIAL_LIBRARY = 'UPDATE_MATERIAL_LIBRARY';
export const UPLOAD_MATERIALS = 'UPLOAD_MATERIALS';
export const CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS = 'CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS';

export const MAP_WRAP_U = 'MAP_WRAP_U';
export const MAP_WRAP_V = 'MAP_WRAP_V';
export const MAP_ENCODING = 'MAP_ENCODING';

export const CHANGE_MAP_REPEAT_U = 'CHANGE_MAP_REPEAT_U';
export const CHANGE_MAP_REPEAT_V = 'CHANGE_MAP_REPEAT_V';
//Presets actions
export const REQUEST_PRESETS_SUCCESS = 'REQUEST_PRESETS_SUCCESS';
export const REQUEST_PRESETS_FAILED = 'REQUEST_PRESETS_FAILED';
export const DELETED_PRESET_FOLDER = 'DELETED_PRESET_FOLDER';
export const DELETED_PRESET = 'DELETED_PRESET';
export const EDIT_PRESET = 'EDIT_PRESET';
export const SAVED_PRESET = 'SAVED_PRESET';
export const SAVED_PRESET_FOLDER = 'SAVED_PRESET_FOLDER';
export const APPLY_PRESET = 'APPLY_PRESET';
export const EDIT_PRESET_FOLDER = 'EDIT_PRESET_FOLDER'

//Combinations actions
export const REQUEST_COMBINATIONS_LIST = 'REQUEST_COMBINATIONS_LIST';
export const REQUEST_COMBINATIONS_SUCCESS = 'REQUEST_COMBINATIONS_SUCCESS';
export const REQUEST_COMBINATIONS_FAILED = 'REQUEST_COMBINATIONS_FAILED';
export const REQUEST_COMBINATIONS_ERROR = 'REQUEST_COMBINATIONS_ERROR';
export const REQUEST_CREATE_COMBINATIONS_SUCCESS = 'REQUEST_CREATE_COMBINATIONS_SUCCESS';
export const REQUEST_CREATE_COMBINATIONS_FAILED = 'REQUEST_CREATE_COMBINATIONS_FAILED';
export const REQUEST_CREATE_COMBINATIONS_ERROR = 'REQUEST_CREATE_COMBINATIONS_ERROR';
export const CLONE_COMBINATION = 'CLONE_COMBINATION';
export const DELETE_COMBINATION = 'DELETE_COMBINATION';
export const UPDATE_COMBINATION = 'UPDATE_COMBINATION';
export const RESET_PRESET = 'RESET_PRESET';
export const ADD_PRESET_TO_LIST = 'ADD_PRESET_TO_LIST';
export const LOGIN = 'LOGIN';


//iframe
export const LOAD_COMBINATION = 'LOAD_COMBINATION';
export const IFRAME_TAKE_SCREENSHOT = 'IFRAME_TAKE_SCREENSHOT';
export const ENABLE_AR_IFRAME = 'ENABLE_AR_IFRAME';
export const RESET_CAMERA_IFRAME = 'RESET_CAMERA_IFRAME';
export const ENABLE_ROTATION_IFRAME = 'ENABLE_ROTATION_IFRAME';


//LOGO
export const LOGO_TOGGLE = "LOGO_TOGGLE";
export const LOGO_STAY_DURATION = "LOGO_STAY_DURATION";
export const LOGO_FADE_IN_DURATION = "LOGO_FADE_IN_DURATION";
export const LOGO_FADE_OUT_DURATION = "LOGO_FADE_OUT_DURATION";
export const LOGO_SCALE = "LOGO_SCALE";
export const LOGO_POSITION_X = "LOGO_POSITION_X";
export const LOGO_POSITION_Y = "LOGO_POSITION_Y";

export const LOAD_PRESET_LAYER = "LOAD_PRESET_LAYER";
export const LOAD_PRESET_FOLDER_LAYER = "LOAD_PRESET_FOLDER_LAYER";

//ANNOTATIONS
export const ENABLE_ANNOTATIONS = "ENABLE_ANNOTATIONS";
export const SELECT_ANNOTATIONS = "SELECT_ANNOTATIONS";
export const TEXT_ANNOTATIONS = "TEXT_ANNOTATIONS";
export const TITLE_ANNOTATIONS = "TITLE_ANNOTATIONS";
export const REMOVE_ANNOTATIONS = "REMOVE_ANNOTATIONS";

export const FEEDBACK_MIN = "FEEDBACK_MIN";
export const FEEDBACK_MAX = "FEEDBACK_MAX";
export const ENABLE_TAA = "ENABLE_TAA";
export const ENABLE_SSR = "ENABLE_SSR";
export const INTENSITY_SSR = "INTENSITY_SSR";


export const REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS = "REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS";
export const REQUEST_TEXTURES_SIZE_SUCCESS = "REQUEST_TEXTURES_SIZE_SUCCESS";
export const REQUEST_IMAGES_SIZE_SUCCESS = "REQUEST_IMAGES_SIZE_SUCCESS";
export const REQUEST_COMBINATIONS_SIZE_SUCCESS = "REQUEST_COMBINATIONS_SIZE_SUCCESS";
export const REQUEST_ACTIVITY_SUCCESS = "REQUEST_ACTIVITY_SUCCESS";

export const CHANGE_SIDE = "CHANGE_SIDE";
export const REQUEST_TRANSACTION_SUCCESS = "REQUEST_TRANSACTION_SUCCESS";

export const REQUEST_USER_PERMISSION = "REQUEST_USER_PERMISSION";

export const ANIMATION_TIME_SCALE = "ANIMATION_TIME_SCALE";
export const ANIMATION_LOOP = "ANIMATION_LOOP";
export const ANIMATION_PLAY = "ANIMATION_PLAY";
export const ANIMATION_STOP = "ANIMATION_STOP";

export const ADD_AMBIENT = "ADD_AMBIENT";
export const AUDIO_PLAY = "AUDIO_PLAY";
export const AUDIO_STOP = "AUDIO_STOP";
export const AUDIO_LOOP = "AUDIO_LOOP";
export const AUDIO_VOLUME = "AUDIO_VOLUME";
export const AUDIO_REMOVE = "AUDIO_REMOVE";

export const ADD_AUDIO = "ADD_AUDIO";
export const AUDIO_MATERIAL_VOLUME = "AUDIO_MATERIAL_VOLUME";
export const AUDIO_MATERIAL_LOOP = "AUDIO_MATERIAL_LOOP";
export const AUDIO_MATERIAL_REMOVE = "AUDIO_MATERIAL_REMOVE";

export const GET_STORAGE_SUMMARY = "GET_STORAGE_SUMMARY";

export const ENABLE_FRUSTUM_CULLING = "ENABLE_FRUSTUM_CULLING";


export const CHANGE_NORMAL_SCALE_X = 'CHANGE_NORMAL_SCALE_X';
export const CHANGE_NORMAL_SCALE_Y = 'CHANGE_NORMAL_SCALE_Y';
export const CHANGE_NORMAL_MAP_TYPE = 'CHANGE_NORMAL_MAP_TYPE';

export const SCENE_INFO_ALERT = 'SCENE_INFO_ALERT';
export const SWITCH_OLD_NEW_CAMERA = 'SWITCH_OLD_NEW_CAMERA';
export const GIZMO_ENABLED = 'GIZMO_ENABLED';
export const GIZMO_CHANGE_MODE = 'GIZMO_CHANGE_MODE';
