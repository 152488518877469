import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Configurator from './components/Configurator/Configurator';
import * as serviceWorker from './serviceWorker';
import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import store from './store';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import TrackersContainer from "./containers/TrackersContainer";
import Confirmation from "./components/Confirmation/Confirmation";
import CombinationRenderer from "./components/CombinationRenderer/Configurator"
import IFrameRenderer from "./components/IFrameRenderer/Configurator"
import ConfiguratorRenderer from "./components/ConfiguratorRenderer/Configurator"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <Container fluid>
            <Router>
                {/*<Route exact path="/" component={App} />*/}
                <Route exact path="/" component={Configurator} />
                <Route path='/configurator' component={Configurator} />
                <Route exact path='/annotation/:id' component={CombinationRenderer} />
                <Route exact path='/iframe/:id' component={IFrameRenderer} />
                <Route exact path='/configurators/:id' component={ConfiguratorRenderer} />
                <Route path='/tracker' component={TrackersContainer} />
                <Route path='/confirmation' component={() => <Confirmation type="widget"/>} />
            </Router>
        </Container>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
