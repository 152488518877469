import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form, Button} from "react-bootstrap";
import Clipboard from 'react-clipboard.js';
import axios from '../../../../axios';

function AnnotationSendInvitation(props) {
    const [value, setState] = useState({
        value: props.value ? props.value : ''
    })

    const updateField = (e) => {
        console.log(props.value);
        setState({
            ...value,
            value: e.target.value
        });
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
    }

    const sendInvitation = () => {

        let payload = {
            uuid: props.uuid,
            emails: value.value
        }

        axios.post('combination/invite-annotation-comment', payload).then(result => {
            alert('Success');
        });
    }

    return (
        <Row /*{...props}*/>
            <Col md={12} className="" style={{marginTop: 10, marginBottom: 10}}>
                <Form.Control
                placeholder="Enter comma-separated list of emails"
                    id="textarea-field"
                    rows={6}
                    as="textarea"
                    onChange={updateField}
                    value={value.value}/>
            </Col>
            <Col md={12} className="">
                <Button onClick={()=>sendInvitation()}  className="btn btn-danger ">Send Invitation</Button>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(AnnotationSendInvitation)