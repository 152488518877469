import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    selectMaterial
} from "../../../../actions/actionsCreators";

class MenuItemFullSizeDropdown extends Component{

    state = {
        value: this.props.value ? this.props.value : '',
    }

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value) => {
        switch (type) {
            case 'selectMaterial':
                this.props.selectMaterial(value)
                break;
            default:
                return null;
        }
    }

    handleChange = (event) =>  {
        this.setState({value: event.target.value});
        this.dispatchAction(this.props.type, event.target.value)
    }

    render() {
        return (
            <Row>
                <Col md={12} className="setting-col-input">
                    <Form.Control as="select" size="sm" className="menu-item-text-box" onChange={this.handleChange}>
                        {
                            this.props.options.map((option, i) => {
                                return <option selected={this.props.value === option.value} value={option.value} key={i}>{i + 1 + "- " + option.label}</option>
                            })
                        }
                    </Form.Control>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    selectMaterial: selectMaterial,
}

export default connect(null, mapDispatchToProps)(MenuItemFullSizeDropdown)