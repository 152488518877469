import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    changeDownSampleRatio,
    changeStandardMaterialAlphaMapIntensity,
    changeStandardMaterialLightMapIntensity,
    changeStandardMaterialAoMapIntensity,
    changeStandardMaterialEmissiveIntensity,
    changeStandardMaterialBumpScale,
    changeStandardMaterialLineWidth,
    changeMinimumDistance,
    changeMaximumDistance,
    changeMaxZoom,
    changeMinZoom,
    changeMaxPolarAngle,
    changeMinPolarAngle,
    changeMaxAzimuthAngle,
    changeMinAzimuthAngle,
    changeAnnotationText,
    changeAnnotationTitle
} from "../../../../actions/actionsCreators";

function MenuItemTextBox(props) {
    const [value, setState] = useState({
        value: props.value ? props.value : ''
    })

    const updateField = (e) => {
        setState({
            ...value,
            value: e.target.value
        });

        dispatchAction(props.type, e.target.value)
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
        switch (type) {
            case 'downSampleRatio':
                props.changeDownSampleRatio(value)
                break;
            case 'standardMaterialAlphaMapIntensity':
                props.changeStandardMaterialAlphaMapIntensity(value)
                break;
            case 'standardMaterialLightMapIntensity':
                props.changeStandardMaterialLightMapIntensity(value)
                break;
            case 'standardMaterialAoMapIntensity':
                props.changeStandardMaterialAoMapIntensity(value)
                break;
            case 'standardMaterialEmissiveIntensity':
                props.changeStandardMaterialEmissiveIntensity(value)
                break;
            case 'standardMaterialBumpScale':
                props.changeStandardMaterialBumpScale(value)
                break;
            case 'standardMaterialLineWidth':
                props.changeStandardMaterialLineWidth(value)
                break;
            case 'minimumDistance':
                props.changeMinimumDistance(value)
                break;
            case 'maximumDistance':
                props.changeMaximumDistance(value)
                break;
            case 'maxZoom':
                props.changeMaxZoom(value)
                break;
                
            case 'minZoom':
                props.changeMinZoom(value)
                break;
            case 'maxPolarAngle':
                props.changeMaxPolarAngle(value)
                break;
            case 'minPolarAngle':
                props.changeMinPolarAngle(value)
                break;
            case 'maxAzimuthAngle':
                props.changeMaxAzimuthAngle(value)
                break;
            case 'minAzimuthAngle':
                props.changeMinAzimuthAngle(value)
                break;
            case 'changeAnnotationText':
                props.changeAnnotationText(value)
                break;
            case 'changeAnnotationTitle':
                props.changeAnnotationTitle(value)
                break;
            default:
                return null;
        }
    }

    return (
        <Row /*{...props}*/>
            <Col md={6} className="setting-col-input">
                <span title={props.title} className="setting-name">{props.name}</span>
            </Col>
            <Col md={6} className="setting-col-input">
                <Form.Control
                    size="sm"
                    type="text"
                    className="menu-item-text-box"
                    value={props.readonly ? props.value : value.value}
                    onChange={updateField}
                    disabled={!!props.disabled}
                    readOnly={!!props.readonly}/>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
    changeDownSampleRatio: changeDownSampleRatio,
    changeStandardMaterialLightMapIntensity: changeStandardMaterialLightMapIntensity,
    changeStandardMaterialAlphaMapIntensity: changeStandardMaterialAlphaMapIntensity,
    changeStandardMaterialAoMapIntensity: changeStandardMaterialAoMapIntensity,
    changeStandardMaterialEmissiveIntensity: changeStandardMaterialEmissiveIntensity,
    changeStandardMaterialBumpScale: changeStandardMaterialBumpScale,
    changeStandardMaterialLineWidth: changeStandardMaterialLineWidth,
    changeMinimumDistance: changeMinimumDistance,
    changeMaximumDistance: changeMaximumDistance,
    changeMaxZoom: changeMaxZoom,
    changeMinZoom: changeMinZoom,
    changeMaxPolarAngle: changeMaxPolarAngle,
    changeMinPolarAngle: changeMinPolarAngle,
    changeMaxAzimuthAngle: changeMaxAzimuthAngle,
    changeMinAzimuthAngle: changeMinAzimuthAngle,
    changeAnnotationText: changeAnnotationText,
    changeAnnotationTitle: changeAnnotationTitle
}

export default connect(null, mapDispatchToProps)(MenuItemTextBox)