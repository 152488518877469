import React, {Component} from "react";
import {connect} from "react-redux";
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form} from "react-bootstrap";
import axios from '../../../axios';

class CombinationsSizeName extends Component {
    state = {
        name: this.props.name ? this.props.name : '',
        price: this.props.price ? this.props.price : 0,
        tag: this.props.tag ? this.props.tag : '',
        description: this.props.description ? this.props.description : '',
        fileTypes: this.props.fileTypes ? this.props.fileTypes : '',
        emails: this.props.emails ? this.props.emails : '',
    }

    nameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    priceChange = (e) => {
        this.setState({
            price: e.target.value
        })
    }

    tagChange = (e) => {
        this.setState({
            tag: e.target.value
        })
    }

    descChange = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    fileTypesChange = (e) => {
        this.setState({
            fileTypes: e.target.value
        })
    }

    emailsChange = (e) => {
        this.setState({
            emails: e.target.value
        })
    }

    /**
     * Update combination with new price and description
     */
    updateCombination = () => {
        let payload = {
            id: this.props.uuid,
            name: this.state.name,
            price: this.state.price,
            tag: this.state.tag,
            description: this.state.description,
            fileTypes: this.state.fileTypes,
        }

        axios.post('combination/update-name', payload).then(result => {
            // alert('Success');
        });

    }

    render() {
        return(
            <React.Fragment>
                <Form.Group id="update-combination-name" className="update-combination-name">
                    <Form.Control type="text" onChange={this.nameChange} value={this.state.name} />
                    <Form.Control className="description-input" placeholder="Description (gallery + 3d configurator)" type="text" onChange={this.descChange} value={this.state.description} />
                    <Form.Control placeholder="Eg: diamond, metal (gallery)" type="text" onChange={this.tagChange} value={this.state.tag} />
                    <Form.Control placeholder="Price (gallery)" type="number" onChange={this.priceChange} value={this.state.price} />
                    {/* <Form.Control className="fileTypes-input" placeholder="File types (gallery)" type="text" onChange={this.fileTypesChange} value={this.state.fileTypes} /> */}
                    <Button onClick={()=>this.updateCombination()}  className="btn btn-danger ">Update</Button>
                </Form.Group>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationsSizeName);