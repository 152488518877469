import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    applyPreset,
} from "../../../../actions/actionsCreators";
import brushIcon from "../../../../assets/brush@2x.png";
import closeIcon from "../../../../assets/close-icon@2x.png";

class ApplyPresetModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: ''
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    /**
     * Apply preset to selected objects
     */
    applyPreset = () => {
        axios.post('layer/create', {layerId: this.props.layerUuid, materialId: this.props.uuidPreset}
        ).then(response => {
            
        });
        this.props.applyPreset(this.props.uuidPreset, this.props.uuidPresetFolder)
        this.handleClose()
    }

    render() {
        return (
            <React.Fragment>
                <img className="brush-icon" src={brushIcon} alt="" onClick={this.handleShow} />
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Apply Preset</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !this.props.selectedObjects ?
                                <Row className="rename-material-modal-body">
                                    <Col md={12}>
                                        <span>No objects selected in 3D model!</span>
                                    </Col>
                                </Row> :
                                <React.Fragment>
                                    <Row className="rename-material-modal-body">
                                        <Col md={12}>
                                            <span>Are you sure you want to apply this preset to all selected objects ?</span>
                                        </Col>
                                        {
                                            this.state.hasError ?
                                                <Col md={12}>
                                                    <span className="rename-material-modal-error">{this.state.error}</span>
                                                </Col> : ''
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button variant="primary" onClick={this.applyPreset}>
                                                Apply
                                            </Button>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    applyPreset: applyPreset,
}

const mapStateToProps = (state) => {
    return {
        selectedObjects: state.selectedObjects.selected
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPresetModal)