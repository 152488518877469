import { connect } from 'react-redux';
import Sidebar from "../../components/Tracker/Sidebar/Sidebar";
import {loadReportRequest, loadFiltersRequest} from "../../actions/actionsCreators";

const mapDispatchToProps = {
    loadReportRequest: loadReportRequest,
    loadFiltersRequest: loadFiltersRequest
}

const mapStateToProps = state => {
    return {
        tracker: state.tracker
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)