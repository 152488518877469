import React, {Component} from "react";
import { connect } from 'react-redux';
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form, Select, Option} from "react-bootstrap";
import Combination from "./Combination";
import PplDescriptionModal from "./PplDescriptionModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import CombinationsSizePublic from "./CombinationsSizePublic";
import CombinationsSizeAllowComment from "./CombinationsSizeAllowComment";
import CombinationVideoHelpModal from "./CombinationVideoHelpModal";
import './Combinations.css';
import './Controls.css';
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import deleteBtn from '../../../assets/delete@2x.png';
import helpIcon from '../../../assets/help_icon.png';
import {FileLoader, Vector2, TextureLoader, DefaultLoadingManager, TriangleFanDrawMode} from "three";
import {
    refreshCombinationsSizeAfterDelete,
    getCombinationsSize,
    getImagesSize,
    getTexturesSize,
    getPublicUploadedFilesSize,
    deletePreset,
    getStorageSummary
} from "../../../actions/actionsCreators";
import CombinationModal from "./CombinationModal";
import PplModal from "./PplModal";
import jwt_decode from "jwt-decode";

class CombinationsSize extends Component {
    state = {
        userInfo: '',
        deleting: false,
        defaultActiveKey: 'combinations',
        limit: 10,
        skip: 10,
        combinations: [],
        combinationIds: [],
        search: '',
        ppl: null,
        lastRecord: ''
    }

    /**
     * Will set to false all embedded and widgets checkboxes so that we can track which one is checked
     */
    componentDidMount() {
        this.props.getCombinationsSize(this.props.combinations.combinationsList.length, this.state.limit);
        
        axios.get('ppl/get-ppl').then(response => {
            this.setState({ppl: response.data.data.ppl})
        }).catch(err => {
        })
    }

    openGallery = () => {
        window.open(galleryUrl+"/gallery/"+this.props.username, "_blank");
    }

    updateSearchField = (e) => {
        this.setState({search: e.target.value})
    }

    searchCombination = (e) => {
        this.setState({defaultActiveKey: 'combinations', limit: Number(this.state.limit), combinationIds: []})
        this.props.getCombinationsSize(this.props.combinations.combinationsList.length, this.state.limit, this.state.search)
    }

    deleteSelected = () => {
        this.setState({deleting: true})
        axios.post('combination/delete-multiple', {ids: this.state.combinationIds}).then(response => {
            this.props.refreshCombinationsSizeAfterDelete();
            setTimeout(() => {
                this.props.getCombinationsSize(0, 10);
                this.setState({deleting: false, combinationIds: []})
            }, 1000);

        }).catch(err => {
        })
    }

    deleteCombination = (uuid) => {
        this.setState({deleting: true})
        console.log(uuid);
        axios.post('combination/delete', {id: uuid}).then(response => {
            this.props.refreshCombinationsSizeAfterDelete();
            setTimeout(() => {
                this.props.getCombinationsSize(0, 10);
                this.setState({deleting: false, combinationIds: []})
            }, 1000);

        }).catch(err => {
        })
    }

    deleteImage = (path, type, combinationUuid) => {
        this.setState({deleting: true})
        axios.post('combination/delete-img', {fileName: path, type: type, id: combinationUuid}).then(response => {
            
            this.setState({defaultActiveKey: 'images'})
            this.props.getImagesSize()
            this.setState({deleting: false})

        }).catch(err => {
        })
    }

    deletePublicUploadedFiles = (path) => {
        this.setState({deleting: true})
        axios.post('user/delete-public-uploaded-file', {fileName: path}).then(response => {
            
            this.setState({defaultActiveKey: 'publicUploadedFiles'})
            this.props.getPublicUploadedFilesSize()
            this.setState({deleting: false})

        }).catch(err => {
        })
    }

    deleteTexture = (presetUuid, presetFolderUuid) => {
        this.setState({deleting: true})
        axios.post('preset/delete',
        {
            "presetFolderId": presetFolderUuid,
            "presetId": presetUuid
        }).then(response => {
            
            this.setState({defaultActiveKey: 'textures'})
            this.props.getTexturesSize()
            this.setState({deleting: false})

            this.props.deletePreset(presetUuid, presetFolderUuid)
        }).catch(err => {
        })
    }

    downloadCombination  = (path) => {
        this.setState({deleting: true})
        new FileLoader().load(path, (response) => {
            let link = document.createElement("a");
            let blob = new Blob([(response)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "scene.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            setTimeout(() => {
                this.setState({deleting: false})
            }, 1000);
        })
    }

    downloadTexture  = (path) => {
        this.setState({deleting: true})
        new FileLoader().load(path, (response) => {
            let link = document.createElement("a");
            let blob = new Blob([(response)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "texture.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            setTimeout(() => {
                this.setState({deleting: false})
            }, 1000);
        })
    }

    downloadFile  = (path) => {
        this.setState({deleting: true})
        new FileLoader().load(path, (response) => {
            let link = document.createElement("a");
            let blob = new Blob([(response)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "file.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            setTimeout(() => {
                this.setState({deleting: false})
            }, 1000);
        })
    }

    loadCombinationSize = () => {
        let skip = 0;//this.state.skip + this.state.limit;
        console.log("skip", skip);
        this.setState({defaultActiveKey: 'combinations', limit: Number(this.state.limit), combinationIds: [], skip: Number(skip)})
        this.props.getCombinationsSize(skip, this.state.limit)
        
    }

    loadImageSize = () => {
        this.setState({defaultActiveKey: 'images'})
        this.props.getImagesSize()
    }
    
    loadTextureSize = () => {
        this.setState({defaultActiveKey: 'textures'})
        this.props.getTexturesSize()
    }
    
    loadPublicUploadedFiles = () => {
        this.setState({defaultActiveKey: 'publicUploadedFiles'})
        this.props.getPublicUploadedFilesSize()
    }

    loadStorageSummary = () => {
        this.setState({defaultActiveKey: 'summary'})
        this.props.getStorageSummary()
    }

    updateCombinationName = (uuid) => {
        // let parent = e.target.parentNode;
        console.log(uuid);
        console.log(document.getElementById(uuid).value);
    }

    chooseCombination = (uuid) => {
        let combUUids = this.state.combinationIds;
        if(!combUUids.includes(uuid)){
            combUUids.push(uuid);
        }else{
            const index = combUUids.indexOf(uuid);
            if (index > -1) {
                combUUids.splice(index, 1);
            }
        }
        this.setState({combinationIds: combUUids})
        console.log(this.state.combinationIds);
    }
    
    staticImgModeChange = (uuid, mode) => {
        let payload = {
            id: uuid,
            mode: mode
        };

        console.log(payload);
        axios.post('combination/update-static-img-mode', payload).then(result => {
        });
    }

    

    render() {
        let combinations = ''
        let loading = this.props.combinations.loading

        if (!loading && !this.props.combinations.combinationsList.isEmpty) {
            combinations = (
                <Tabs activeKey={this.state.defaultActiveKey}
                onSelect={(k) => this.setState({defaultActiveKey: k})}
                id="uncontrolled-tab-example">
                    <Tab eventKey="combinations" title="Combinations">
                        <Row className="view-storage-combination" >
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0', textTransform: 'uppercase'}} className="btn btn-primary" onClick={this.loadCombinationSize}>Click here to view Combination Storage</button>
                            </Col>
                            {
                                this.props.userPermission != undefined ? 
                                <Col md={{ span: 6 }} style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 5 }}>
                                    {/* <div style={{ display: this.props.userPermission.isShowGallery ? 'inline-block' : 'none' }}><button style={{ marginBottom: 5 }} className="btn btn-primary" onClick={this.openGallery}>View Gallery</button></div> */}
                                    
                                    <button style={{marginBottom: '0', float: 'left'}} className="btn btn-danger" 
                                                onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.deleteSelected()};}}>
                                                    Delete Selected</button>
                                </Col>
                                : ''
                            }
                            <Col md={{ span: 6}} style={{textAlign: 'right', paddingTop: 10, paddingBottom: 5}}>
                                <div style={{display: 'inline-block'}}><input className="form-control" placeholder="Input Name or UUID" type="text" onChange={this.updateSearchField} 
                                    onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        this.searchCombination();
                                    }
                                }} /></div>
                                <div style={{display: 'inline-block', marginLeft: 5}}><button style={{marginBottom: 5}} className="btn btn-primary" onClick={this.searchCombination}>Search</button></div>
                            </Col>
                            <Col md={{ span: 12}} style={{maxHeight: 600}}>
                                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th style={{width: 50}}></th>
                                        <th colSpan={2}></th>
                                        {/* <th style={{maxWidth: 100}}></th> */}
                                        <th id="gallery-group-th" colSpan={6}>GALLERY / MARKETPLACE</th>
                                        <th></th>
                                        <th colSpan={2}></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th style={{width: 50}}>Select</th>
                                        <th colSpan={2}>Thumbnail</th>
                                        {/* <th style={{maxWidth: 100}}>Uuid</th> */}
                                        <th colSpan={2}>Name Description Tags Price</th>
                                        <th colSpan={2}>Product Upload</th>
                                        <th>Publish</th>
                                        <th>Comments</th>
                                        <th>Cover Image</th>
                                        <th colSpan={2}>Viewer</th>
                                        {this.state.ppl ? <th>Pay Per Link</th> : ''}
                                        <th>Download</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.combinationsList.map((combination, i) => {
                                                return    <tr key={i} id={combination.uuid}>
                                                        <td style={{width: 50}}>
                                                            <input type="checkbox"
                                                            onChange={()=>this.chooseCombination(combination.uuid)} />
                                                        </td>
                                                        <td className="combination-report-info" colSpan={2} style={{verticalAlign: 'top'}}>
                                                            <img loading="lazy" style={{width: 100+'%'}} src={combination.staticImg} />
                                                            <p style={{marginTop: 10}} >Total Views: {combination.totalView ?? 0}</p>
                                                            <p>Size: {
                                                                combination.size ? (combination.size / 1000000).toFixed(2) + " MB" : ""
                                                            }
                                                            </p>
                                                            <p> Created: {
                                                                new Date(combination.createdDate).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'})
                                                            }
                                                            </p>
                                                            <p>Uuid: {
                                                                combination.uuid
                                                            }</p>
                                                        </td>
                                                        {/* <td style={{width: 100}}>
                                                            {
                                                                combination.uuid
                                                            }
                                                        </td> */}
                                                        <td colSpan={2} style={{verticalAlign: 'top'}}>
                                                            <CombinationsSizeName fileTypes={combination.fileTypes} description={combination.description} tag={combination.tag} price={combination.price} name={combination.name} uuid={combination.uuid} />
                                                        </td>
                                                        <td colSpan={2} style={{verticalAlign: 'top'}} className="combination-file-download">
                                                            <CombinationsSizeFileDownload downloadFiles={combination.downloadFiles} downloadFile={combination.downloadFile} combination={combination}/>
                                                        </td>
                                                        <td>
                                                            <CombinationsSizePublic isPublic={combination.isPublic} uuid={combination.uuid}/>
                                                        </td>
                                                        <td>
                                                            <CombinationsSizeAllowComment allowComment={combination.allowComment} uuid={combination.uuid}/>
                                                        </td>
                                                        <td style={{textAlign: 'left', paddingLeft: 18}}>
                                                            <input onChange={()=>this.staticImgModeChange(combination.uuid, 'default')} value={'default'} defaultChecked={combination.staticImgMode == 'default'} name={"staticImgMode-"+combination.uuid} type="radio" />
                                                            <label style={{marginRight: 10}}> &nbsp; Default</label>
                                                            <br/>
                                                            {/* <input value={'manual'} defaultChecked={combination.staticImgMode == 'manual'} name={"staticImgMode-"+combination.uuid} type="radio" />
                                                            <label style={{marginRight: 10}}>Manual</label> */}
                                                            <input onChange={()=>this.staticImgModeChange(combination.uuid, 'none')} value={'none'} defaultChecked={combination.staticImgMode == 'none'} name={"staticImgMode-"+combination.uuid} type="radio" />
                                                            <label> &nbsp; None</label>
                                                        </td>
                                                        <td colSpan={2}>
                                                            {/* <Link
                                                                to={{
                                                                    pathname: `/annotation/` + combination.uuid
                                                                }}
                                                                className="btn btn-primary"
                                                                style={{margin: 1+'%'}}
                                                                target="_blank"
                                                            >Annotations</Link> */}
                                                            {
                                                                this.props.userPermission != undefined && this.props.userPermission.isAllowIFrame? 
                                                                // <Link
                                                                //     to={{
                                                                //         pathname: `/iframe/` + combination.uuid
                                                                //     }}
                                                                //     className="btn btn-primary"
                                                                //     style={{margin: 1+'%', width: 80+'%'}}
                                                                //     target="_blank"
                                                                // >Embedded</Link>
                                                                <CombinationModal isAnnotation={false} type={'iframe'} text={'3D Viewer'} uuid={combination.uuid}></CombinationModal>
                                                                : ''
                                                            }
                                                            {
                                                                this.props.userPermission != undefined && this.props.userPermission.isAllowConfigurator? 
                                                                // <Link
                                                                //     to={{
                                                                //         pathname: `/configurators/` + combination.uuid
                                                                //     }}
                                                                //     className="btn btn-primary"
                                                                //     style={{margin: 1+'%', width: 80+'%'}}
                                                                //     target="_blank"
                                                                // >3D Configurator</Link>
                                                                <CombinationModal userPermission={this.props.userPermission}  isAnnotation={false} type={'configurators'} text={'3D Configurator'} uuid={combination.uuid}></CombinationModal>
                                                                : ''
                                                            }
                                                            {
                                                                this.props.userPermission != undefined && this.props.userPermission.isAllowAnnotation? 
                                                                <CombinationModal referenceUuid={combination.referenceUuid} annotationCommentsEmails={combination.annotationCommentsEmails} isAnnotation={true} type={'annotation'} text={'La Fiche'} uuid={combination.uuid}></CombinationModal>
                                                                : ''
                                                            }
                                                            <Row>
                                                                <Col md={{ span: 10}}>
                                                                    <Link
                                                                        to={{
                                                                            pathname: galleryUrl+"/product/"+ combination.uuid
                                                                        }}
                                                                        className="btn btn-primary"
                                                                        style={{margin: 1+'%', width: 100+'%'}}
                                                                        target="_blank"
                                                                    >View Gallery</Link>
                                                                </Col>
                                                                <Col md={{ span: 2}}>
                                                                    <img className="helpIconCombinationModal"  id="" src={helpIcon} onClick={()=> {window.open("https://www.cadgl.com/help/marketplace", "_blank");}} />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        {
                                                            this.state.ppl ? 
                                                            <td>
                                                                {
                                                                    combination.size / 1000000 < this.state.ppl?.limitedFileSize 
                                                                    ?
                                                                    combination.pplExpired ?
                                                                    <>
                                                                        <div>Expired Date: {
                                                                        new Date(combination.pplExpired).toLocaleString('en-GB', {
                                                                            year: 'numeric', month: 'numeric', day: 'numeric', 
                                                                            hour: 'numeric', minute: 'numeric', second: 'numeric'
                                                                        })
                                                                        }</div>
                                                                        <div>Views countdown: {combination.pplViewClickCountdown}</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <PplDescriptionModal ppl={this.state.ppl}/>
                                                                    <PplModal 
                                                                    ppl={this.state.ppl}
                                                                    combinationSize={combination.size ? combination.size / 1000000 : 0} 
                                                                    sizeLimit={this.state.ppl?.limitedFileSize} 
                                                                    uuid={combination.uuid}  />
                                                                    </>
                                                                    : 'File size exceed'
                                                                }
                                                            </td>
                                                            : ''
                                                        }
                                                        <td>
                                                            <a className="btn btn-primary" onClick={()=>this.downloadCombination(combination.path)} style={{cursor: 'pointer', padding: 6}}>Download</a>
                                                        </td>
                                                        <td style={{position: 'relative'}}>
                                                            {/* <CombinationVideoHelpModal></CombinationVideoHelpModal> */}
                                                            <img className="helpIconVideo"  id="" src={helpIcon} onClick={()=>{window.open("https://www.cadgl.com/combination-setup", "_blank");}} />
                                                            <img className="btn-delete-combination" src={deleteBtn} value={combination.uuid} 
                                                            onClick={()=>
                                                                { if (window.confirm('Are you sure you wish to delete this Combination?')) this.deleteCombination(combination.uuid) }
                                                                } 
                                                            />
                                                            {/* <Button value={combination.uuid} onClick={()=>this.deleteCombination(combination.uuid)}  className="btn btn-danger">Delete</Button> */}
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                        {/* <tr>
                                            <td colSpan={12} style={{padding: 0}}>
                                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadCombinationSize}>Load All</button>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td colSpan={2}>
                                                <b>Total Size: </b>
                                            </td>
                                            <td colSpan={7} style={{textAlign: 'left'}}>
                                                {
                                                    (this.props.combinations.totalSize / 1000000).toFixed(2) + " MB out of " + this.props.combinations.storage + " MB"
                                                }
                                            </td>
                                            <td colSpan={3} style={{textAlign: 'left'}}>
                                                <button style={{marginBottom: '0', float: 'right'}} className="btn btn-danger" 
                                                onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.deleteSelected()};}}>
                                                    Delete Selected</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0', textTransform: 'uppercase'}} className="btn btn-primary" onClick={this.loadCombinationSize}>Load More</button>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="images" title="Images">
                        <Row>
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadImageSize}>Click here to view Image Storage</button>
                            </Col>
                            <Col md={{ span: 12}}>
                                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th>Combination</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.imageList.map((image, i) => {
                                                return    <tr>
                                                        <td>
                                                            {
                                                                image.type
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                (image.size / 1000000).toFixed(2) + " MB"
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                image.name
                                                            }
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={{
                                                                    pathname: image.path
                                                                }}
                                                                className="btn btn-primary"
                                                                style={{margin: 1+'%'}}
                                                                target="_blank"
                                                            >View</Link>
                                                        </td>
                                                        <td>
                                                        <Button value={image.path} onClick={()=>this.deleteImage(image.path, image.imgType, image.uuid)}  className="btn btn-danger">Delete</Button>
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                        <tr>
                                            <td>
                                                <b>Total Size: </b>
                                            </td>
                                            <td colSpan={6} style={{textAlign: 'left'}}>
                                                {
                                                    (this.props.combinations.imageTotalSize / 1000000).toFixed(2) + " MB out of " + this.props.combinations.storage + " MB"
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="textures" title="Textures">
                        <Row>
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadTextureSize}>Click here to view Texture Storage</button>
                            </Col>
                            <Col md={{ span: 12}}>
                                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Size</th>
                                        <th>Download</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.textureList.map((texture, i) => {
                                            return  <tr>
                                                        <td>
                                                            {
                                                                texture.name
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                (texture.size / 1000000).toFixed(2) + " MB"
                                                            }
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-primary" onClick={()=>this.downloadTexture(texture.path)} style={{cursor: 'pointer'}}>Download</a>
                                                        </td>
                                                        <td>
                                                            <Button onClick={()=>this.deleteTexture(texture.presetUuid, texture.presetFolderUuid)}  className="btn btn-danger">Delete</Button>
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                        <tr>
                                            <td>
                                                <b>Total Size: </b>
                                            </td>
                                            <td colSpan={6} style={{textAlign: 'left'}}>
                                                {
                                                    (this.props.combinations.textureTotalSize / 1000000).toFixed(2) + " MB out of " + this.props.combinations.storage + " MB"
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="publicUploadedFiles" title="Comments Attachments">
                        <Row>
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadPublicUploadedFiles}>Click here to view Comments Attachments Storage</button>
                            </Col>
                            <Col md={{ span: 12}}>
                                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Size</th>
                                        <th>Download</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.publicUploadedFilesList.map((file, i) => {
                                            return  <tr>
                                                        <td>
                                                            {
                                                                file.path
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                (file.size / 1000000).toFixed(2) + " MB"
                                                            }
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-primary" onClick={()=>this.downloadFile(file.path)} style={{cursor: 'pointer', margin: 1+'%', width: 80+'%'}}>Download</a>
                                                        </td>
                                                        <td>
                                                            <Button onClick={()=>this.deletePublicUploadedFiles(file.path)} style={{margin: 1+'%', width: 80+'%'}} className="btn btn-danger">Delete</Button>
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                        <tr>
                                            <td>
                                                <b>Total Size: </b>
                                            </td>
                                            <td colSpan={6} style={{textAlign: 'left'}}>
                                                {
                                                    (this.props.combinations.publicUploadedFilesTotalSize / 1000000).toFixed(2) + " MB out of " + this.props.combinations.storage + " MB"
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="summary" title="Summary">
                        <Row>
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadStorageSummary}>Click here to view Storage Summary</button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10, color: 'white'}}>
                            <Col md={{ span: 12}}>
                                <Row>
                                    <Col md={{ span: 6}}>
                                        Storage Limit:
                                    </Col>
                                    <Col md={{ span: 6}}>
                                       {this.props.combinations.summary.limit}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10, color: 'white'}}>
                            <Col md={{ span: 12}}>
                                <Row>
                                    <Col md={{ span: 6}}>
                                        Total Usage: 
                                    </Col>
                                    <Col md={{ span: 6}}>
                                        {this.props.combinations.summary.used}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10, color: 'white'}}>
                            <Col md={{ span: 12}}>
                                <Row>
                                    <Col md={{ span: 6}}>
                                        Storage Remaining: 
                                    </Col>
                                    <Col md={{ span: 6}}>
                                        {this.props.combinations.summary.remain}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            )
        }

        if (this.state.deleting || loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }


        if (this.props.combinations.combinationsList.isEmpty) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <span className="rename-material-modal-error">No combinations found !</span>
                    </Col>
                </Row>
            )
        }

        return (
            combinations
        )
    }
}

const mapStateToProps = state => {
    console.log(state.combinations);
    return {
        combinations: state.combinations,
    }
}
const mapDispatchToProps = {
    getCombinationsSize: getCombinationsSize,
    getImagesSize: getImagesSize,
    getTexturesSize: getTexturesSize,
    getPublicUploadedFilesSize: getPublicUploadedFilesSize,
    deletePreset: deletePreset,
    refreshCombinationsSizeAfterDelete: refreshCombinationsSizeAfterDelete,
    getStorageSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(CombinationsSize);