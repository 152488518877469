import {connect} from "react-redux";
import MaterialSettings from "../../components/Configurator/Sidebar/MaterialSettings/MaterialSettings";

const mapStateToProps = (state) => {
    return {
        api: state.sceneConfig.api,
        config: state.sceneConfig.config,
        geometry: state.sceneConfig.geometry,
        materials: state.sceneConfig.materials,
        materialsList: state.sceneConfig.materialsList,
        selectedObjects: state.selectedObjects,
        materialsLibrary: state.sceneConfig.materialsLibrary,
        preset_layer: state.sceneConfig.preset_layer,
        preset_folder_layer: state.sceneConfig.preset_folder_layer,
        userPermission: state.user.userPermission,
        presets: state.presetLibrary.presets,
        sceneConfig: state.sceneConfig
    }
}

export default connect(mapStateToProps)(MaterialSettings)