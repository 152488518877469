import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

let helperPC = 'https://cadgl.net/icons_cadgl/pc-mouse.jpg';
let helperMobile = 'https://cadgl.net/icons_cadgl/mobile-touch.jpg';
let showInterval;

class HelperModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        helperIcon: helperPC
    };

    /**
     * Close modal
     */
    handleCloseHelper = () => {
        this.setState({
            show: false
        })
        // if(document.getElementById("helper-dont-show").checked == true)
        // {
        //     var d=new Date();
        //     d.setTime(d.getTime()+(1*24*60*60*1000));
        //     var expires = "; expires="+d.toGMTString();
        //     var name = "helper-dont-show";
        //     var value  = "true";
        //     document.cookie = name+"="+value+expires+"; path=/";
        // }
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
        showInterval = setInterval(() => {
            if(window.innerWidth > window.innerHeight){
                if(document.getElementById("helperPopup")){
                    let isInIFrame = window.self !== window.top;
                    if(isInIFrame){
                        if (
                            document.fullscreenElement || /* Standard syntax */
                            document.webkitFullscreenElement || /* Safari and Opera syntax */
                            document.msFullscreenElement /* IE11 syntax */
                          ) {
                            document.getElementById("helperPopup").childNodes[0].classList.add("popupWidth60");
                            document.getElementById("helperPopup").childNodes[0].classList.remove("popupWidth50");
                          }else{
                            document.getElementById("helperPopup").childNodes[0].classList.add("popupWidth50");
                            document.getElementById("helperPopup").childNodes[0].classList.remove("popupWidth60");
                          }
                    }else{
                        document.getElementById("helperPopup").childNodes[0].classList.add("popupWidth60");
                    }
                    clearInterval(showInterval);
                }
            }
        }, 1);
    }

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount() {
        //Only check Zooming Level in Mobile Portrait
        if(!this.readCookie("helper-dont-show"))
        {
            if(window.innerWidth < window.innerHeight)
            {
                this.setState({
                    helperIcon: helperMobile,
                });
            }
            else
            {
                this.setState({
                    helperIcon: helperPC,
                });
            }
            //this.handleShow();
        }
    }

    render() {
        return (
            <React.Fragment>
                <img src={this.props.iconSrc} style={{width: 20}} onClick={this.handleShow}/>
                <Modal id="helperPopup" size="xl" show={this.state.show} 
                onHide={this.handleCloseHelper} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title></Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseHelper}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>
                            <img src={this.state.helperIcon} alt="" style={{'width': 100+'%'}} />
                    </Modal.Body>
                    {/* <Modal.Footer style={{color: 'white'}}>
                                Don't show <input type="checkbox" id="helper-dont-show" />
                                <button style={{backgroundColor: 'grey', marginBottom: 0 + '!important'}}  className=" btn btn-primary" onClick={this.handleCloseHelper}>Close</button>
                    </Modal.Footer> */}
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(HelperModal)