import {
    LOAD_COMBINATION,
    IFRAME_TAKE_SCREENSHOT,
    ENABLE_AR_IFRAME,
    RESET_CAMERA_IFRAME,
    ENABLE_ROTATION_IFRAME
} from "../actions/actionTypes";

const initialState = {
    combination: {},
    error: '',
    hasError: false,
    loading: false,
    arSettings: {
        enabled: true,
        objectScale: 0.1,
        groundShadows: true,
        reticleColor: '#ffffff',
        reticleSizeIn: 0.15,
        reticleSizeOut: 0.2,
        reticleFadeThresh: 0.25,
        reticleFadeTime: 1.5
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_COMBINATION:
            return {
                ...state,
                combination: action.combination
            }
        case IFRAME_TAKE_SCREENSHOT:
            return {
                ...state,
                type: 'takeScreenshot'
            }
        case ENABLE_AR_IFRAME:
            return {
                ...state,
                arSettings: action.arSettings,
                type: 'enableArIframe'
            }
        case RESET_CAMERA_IFRAME:
            return {
                ...state,
                type: 'resetCameraIframe'
            }
        case ENABLE_ROTATION_IFRAME:
            return {
                ...state,
                combination: action.combination,
                type: 'enableRotationIframe'
            }
        default:
            return state;
    }
}

export default reducer;