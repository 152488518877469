import React, {Component} from "react";
import {connect} from "react-redux";
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form} from "react-bootstrap";
import axios from '../../../axios';

class CombinationsSizePublic extends Component {
    state = {
        isPublic: this.props.isPublic ? this.props.isPublic : false,
    }

    publicChange = (e) => {
        let payload = {
            id: this.props.uuid,
            status: !this.state.isPublic
        };
        this.setState({
            isPublic: !this.state.isPublic
        })

        console.log(payload);
        axios.post('combination/update-public', payload).then(result => {
        });
    }


    render() {
        return(
            <React.Fragment>
                <Form.Group className="update-combination-name">
                    <input className="form-control chk-publish" type="checkbox" defaultChecked={this.state.isPublic}
                    onChange={()=>this.publicChange()} />
                </Form.Group>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationsSizePublic);