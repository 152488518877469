import React, {Component} from "react";
import {connect} from "react-redux";
import 'react-input-range/lib/css/index.css';

import HelperModal from "./HelperModal";
let play = 'https://cadgl.net/icons_cadgl/play.png';
let stop = 'https://cadgl.net/icons_cadgl/stop.png';
let sound_on = 'https://cadgl.net/icons_cadgl/sound_on.png';
let sound_off = 'https://cadgl.net/icons_cadgl/sound_off.png';
let help = 'https://cadgl.net/icons_cadgl/help.png';
let fullscreen = 'https://cadgl.net/icons_cadgl/fullscreen.png';
let auto_rotate = 'https://cadgl.net/icons_cadgl/auto_rotate.png';
let measure = 'https://cadgl.net/icons_cadgl/measure.png';
let auto_rotate_stop = 'https://cadgl.net/icons_cadgl/auto_rotate_stop.png';
let annotation_on = 'https://cadgl.net/icons_cadgl/annotation_on.png';
let annotation_off = 'https://cadgl.net/icons_cadgl/annotation_off.png';

let change_background = 'https://cadgl.net/icons_cadgl/background.png';
let screenshot = 'https://cadgl.net/icons_cadgl/screenshot.png';
let configurator3D = 'https://cadgl.net/icons_cadgl/3d_config.png';

class CombinationIcons extends Component {
    
    state = {
        page: '',
    }
    go3DConfigurator = (e) => {
        const url = window.location.href;
        const id = url.split("/")[url.split("/").length - 1];
        window.open('/configurators/' + id,'_blank');
    }

    componentDidMount() {
        const url = window.location.href;
        const page = url.split("/")[url.split("/").length - 2];
        this.setState({
            page: page
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="col-md-4 tooltip-left" id="fullScreenIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Fullscreen">
                    <img src={fullscreen} style={{width: 20, marginLeft: 3}} />
                </div>
                <div className="col-md-4 tooltip-left" id="helperIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Info">
                    <HelperModal iconSrc={help}></HelperModal>
                </div>
                <div className="col-md-4 tooltip-left" id="annotationIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Annotation">
                    <img src={annotation_on} style={{width: 20}} />
                </div>
                <div className="col-md-4 tooltip-left" id="autoRotateIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Stop Auto Rotate">
                    <img src={auto_rotate} style={{width: 20}} />
                </div>
                <div className="col-md-4 tooltip-left" id="animationPlay" style={{display: 'none', marginBottom: 5}} data-tooltip="Stop Animation">
                    <img src={stop}  style={{width: 20}} />
                </div>
                <div className="col-md-4 tooltip-left" id="audioPlay" style={{display: 'none', marginBottom: 5}} data-tooltip="Mute">
                    <img src={sound_on}  style={{width: 20}} />
                </div>
                <div className="col-md-4 tooltip-left" id="changeBackgroundIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Change Background">
                    <img src={change_background}  style={{width: 20}} />
                </div>
                <div className="col-md-4 tooltip-left" id="screenshotIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="Screenshot">
                    <img src={screenshot}  style={{width: 20}} />
                </div>
                {
                    this.state.page == 'iframe' ?
                        <div className="col-md-4 tooltip-left" id="3DConfiguratorIcon" style={{display: 'none', marginBottom: 5, cursor: 'pointer'}} data-tooltip="3D Configurator">
                            <img onClick={this.go3DConfigurator} src={configurator3D}  style={{width: 20}} />
                        </div>
                    : 
                    <div className="col-md-4 tooltip-left" id="3DConfiguratorIcon" style={{display: 'none', marginBottom: 5}} data-tooltip="3D Configurator">
                    </div>
                }
                <div className="col-md-4 tooltip-left" id="measurementIcon" style={{display: 'none', marginBottom: 5, cursor: 'not-allowed'}} data-tooltip="Measure">
                    <img src={measure}  style={{width: 20}} />
                </div>

                
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationIcons)