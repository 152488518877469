import React, {Component} from 'react';
import Navbar from "../Navbar/Navbar";
import ProductRenderer from "./ProductRenderer";
import {Table, Col, Row, Spinner} from "react-bootstrap";
import Controls from "./Controls";
import './Configurator.css';

class Configurator extends Component {
    state = {
        lightStyle: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: '#f1f1f1',
            color: '#151515',
            overflowX: 'hidden',
            overflowY: 'scroll'
        },
        darkStyle: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            overflowX: 'hidden',
            overflowY: 'scroll'
        },
        style: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: 'transparent',
            color: '#ffffff',
            overflow: 'hidden',
            //overflowY: 'scroll'
        },
        theme: 'dark'
    }

    changeTheme = () => {
        if (this.state.theme === 'dark') {
            this.setState({
                style: this.state.lightStyle,
                theme: 'light'
            })
        } else {
            this.setState({
                style: this.state.darkStyle,
                theme: 'dark'
            })
        }
    }

    render() {
        return (
            <div className="iframe-view" id="360-iframe" style={this.state.style}>
                <ProductRenderer />
                <Controls theme={this.state.theme} changeTheme={this.changeTheme}/>
            </div>
        )
    }
}

export default Configurator;
