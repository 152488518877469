import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

class ZoomAlertModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
        if(document.getElementById("zoom-alert-dont-show").checked == true)
        {
            var d=new Date();
            d.setTime(d.getTime()+(1*24*60*60*1000));
            var expires = "; expires="+d.toGMTString();
            var name = "zoom-alert-dont-show";
            var value  = "true";
            document.cookie = name+"="+value+expires+"; path=/";
        }
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
    }

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount() {
        //Only check Zooming Level in Mobile Portrait
        if(!this.readCookie("zoom-alert-dont-show"))
        {
            if(window.innerWidth < window.innerHeight)
            {
                if(window.devicePixelRatio > 3)
                {
                    this.handleShow();
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="xl" show={this.state.show} 
                onHide={this.handleClose} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{color: 'white'}}>
                        The zoom value in your display settings is over 150%. This page may not display correctly.
                    </Modal.Body>
                    <Modal.Footer style={{color: 'white'}}>
                                Don't show <input type="checkbox" id="zoom-alert-dont-show" />
                                <button style={{backgroundColor: 'grey', marginBottom: 0 + '!important'}}  className=" btn btn-primary" onClick={this.handleClose}>Close</button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(ZoomAlertModal)