import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Form, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    savePresetFolder,
} from "../../../../actions/actionsCreators";
import axios from "../../../../axios";
import closeIcon from '../../../../assets/close-icon@2x.png';

class CreatePresetFolderModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        folderName: '',
        loading: false
    };

    /**
     * Update materials array on state and close modal
     */
    dispatchAction = () => {
        if (!this.state.folderName) {
            this.setState({
                hasError: true,
                error: 'Preset name is required!'
            })
        }

        if (!this.state.hasError && this.state.folderName) {
            this.setState({loading: true})
            axios.post('preset/create-folder', {name: this.state.folderName}).then(response => {
                this.setState({loading: false})
                let {data} = response
                if (!data.success) {
                    this.setState({
                        hasError: true,
                        error: data.error.message
                    })
                }

                if (data.success) {
                    this.props.savePresetFolder(this.state.folderName, data.data.presetFolder)
                    this.handleClose()
                }
            }).catch(err => {
                console.log(err)
                this.setState({
                    hasError: true,
                    error: 'Error occurred, please contact support!',
                    loading: false
                })
            })
        }
    }

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false,
            hasError: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true,
            hasError: false
        })
    }

    /**
     * Updates field value on change
     * @param e
     */
    updateField = (e) => {
        this.setState({
            folderName: e.target.value,
            hasError: !e.target.value,
            error: 'Name can not be empty'
        });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md={12} className="btn-save-as-preset">
                        <Button onClick={this.handleShow}>{this.props.name}</Button>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="save-preset-modal-title">
                        <Modal.Title>CREATE MATERIAL FOLDER</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                            <Row className="save-preset-modal-body">
                                <Col md={4}>
                                    <span>Name</span>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={this.state.folderName}
                                        className="save-preset-text-box"
                                        onChange={this.updateField}/>
                                </Col>
                                {
                                    this.state.hasError ?
                                        <React.Fragment>
                                            <Col md={4} />
                                            <Col md={8}>
                                                <span className="save-preset-modal-error">{this.state.error}</span>
                                            </Col>
                                        </React.Fragment> : ''
                                }
                            </Row>
                            {
                                this.state.loading ?
                                    <Row>
                                        <Col md={12} className="spinner">
                                            <Spinner animation="border" role="status" variant="primary">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </Col>
                                    </Row> : ''
                            }
                            <Row>
                                <Col md={4}/>
                                <Col md={8}>
                                    <Button variant="primary" onClick={this.dispatchAction} disabled={this.state.loading}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    savePresetFolder: savePresetFolder,

}

const mapStateToProps = (state) => {
    return {
        presets: state.presetLibrary.presets
    }
}

export default connect(null, mapDispatchToProps)(CreatePresetFolderModal)