import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

import lang_it from '../../../lang/lang_it.json';
import lang_en from '../../../lang/lang_en.json';
import lang_fr from '../../../lang/lang_fr.json';
import lang_vn from '../../../lang/lang_vn.json';

let lang = {};
class LoginModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        email: '',
        password: '',
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    componentDidMount() {
        //Check browser disable cookie
        var cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){ 
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
        }
        
        let token = null;
        if(cookieEnabled){
            token = localStorage.getItem('user');
        }
        if(!token)
        {
            this.handleShow();
        }
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }

    login = () => {
        this.setState({
            loading: true,
            hasError: false,
            error: ''
        })

        let payload = {
            email: this.state.email,
            password: this.state.password,
        }

        axios.post('auth/app-login', payload).then(result => {
            if (result.data.success) {
                // alert('Success');
                this.setState({
                    loading: false,
                    hasError: false,
                    error: ''
                })
                localStorage.setItem('user', JSON.stringify(result.data.data.token));
                var today = new Date();
                localStorage.setItem('user-active', today.setHours(today.getHours() + 4));
                // localStorage.setItem('user-active', today.setMinutes(today.getMinutes() + 2));
                document.location.reload();
                this.handleClose();
            }
        }).catch((err, result) => {
            alert(err.response.data.error.message);
            this.setState({
                loading: false,
                hasError: true,
                error: err.response.data.error.message
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <button style={{backgroundColor: 'grey', display: 'none'}} className="add-combination-btn btn btn-primary" onClick={this.handleShow}>LOGIN</button>
                <Modal size="xl" show={this.state.show} 
                //onHide={this.handleClose} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                        {/* <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div> */}
                    </Modal.Header>
                    <Modal.Body style={{paddingBottom: 0}}>
                        <Table className="combinations-table table-borderless" size="lg">
                            <tr>
                                <td style={{paddingRight: 9}}>
                                    <input placeholder="email" 
                                    style={{margin: 0, width: 100+'%'}}
                                    className="name-input" 
                                    name="email" type="text" value={this.state.email} onChange={this.handleChange} /> </td>
                            </tr>
                            <tr>
                                <td style={{paddingRight: 9}}>
                                    <input placeholder="password" 
                                    style={{margin: 0, width: 100+'%'}}
                                    className="name-input" name="password" type="password" value={this.state.password} onChange={this.handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingRight: 9}}>
                                    {
                                        this.state.loading ?
                                            <SpinnerSmall /> :
                                            <button style={{marginBottom: '0'}} className="login-btn btn btn-primary" onClick={this.login} disabled={this.state.loading} >{lang.login}</button>
                                    }
                                </td>
                            </tr>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="login-footer" style={{color: 'white', paddingTop: 0}}>
                    <a href="https://www.cadgl.com/subscription-packages">{lang.create_your_cadgl_account}</a>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    login: login,
}

export default connect(null, mapDispatchToProps)(LoginModal)