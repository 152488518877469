import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

const initialState = {};
const enhancers = [];
const middleware = [
    thunk
];

// In case of development mode add redux-logger middleware
if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

export default createStore(
    rootReducer,
    initialState,
    composedEnhancers,
);

