import React, {Component, } from "react";
import {connect} from "react-redux";
import MenuItemInputRange2 from "../MenuItems/MenuItemInputRange2";
import MenuItemInputRangeMaxAzimuth from "../MenuItems/MenuItemInputRangeMaxAzimuth";
import MenuItemInputRangeMinAzimuth from "../MenuItems/MenuItemInputRangeMinAzimuth";
import MenuItemCheckbox from "../MenuItems/MenuItemCheckbox";
import {Modal, Form, Button} from "react-bootstrap";

import closeIcon from "../../../../assets/close-icon@2x.png";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';
let lang = {};

let originalMinAzimuth = 0;
let originalMaxAzimuth = 0;
class RestrictCameraModal extends Component {
    state = {
        show: false,
    }

    showRestrictCamera = () => {
        this.setState({
            show: true
        });
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }
    

    componentDidMount() {
        originalMinAzimuth = this.props.originalMinAzimuth;
        originalMaxAzimuth = this.props.originalMaxAzimuth;
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }

    restrictCameraClickInternal = () =>{
        this.props.restrictCameraClick('asd')
    }
    /**
     * Update combination with new price and description
     */

    render() {

        return(
            <React.Fragment>
                <button id="btnShowRestrictCamera" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.showRestrictCamera} disabled={this.state.loading} >Open Settings</button>
                <Modal id="restrictCameraModal" size="xl" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                            {/* <MenuItemInputRange2 isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} step={0.1} min={0.1} max={4} disableInput={true} name="Zoom Speed" value={this.props.config.camera.orbitParams.zoomSpeed ?  this.props.config.camera.orbitParams.zoomSpeed : '0'} type="zoomSpeed" /> */}
                            <MenuItemInputRange2 isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} step={0.01} min={0} max={50} disableInput={true} name={lang.zoom_in} value={this.props.config.camera.orbitParams.minDistance ?  this.props.config.camera.orbitParams.minDistance : '0'} type="minimumDistance" />
                            <MenuItemInputRange2 isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} step={0.1} min={0} max={50} disableInput={true} name={lang.zoom_out} value={this.props.config.camera.orbitParams.maxDistance ?  this.props.config.camera.orbitParams.maxDistance : 50} type="maximumDistance" />
                            <MenuItemInputRange2 isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} step={0.1} min={0} max={3.14} disableInput={true} name={lang.restrict_down} value={this.props.config.camera.orbitParams.maxPolarAngle ? (this.props.config.camera.orbitParams.maxPolarAngle > 3.14 ? 3.14 : this.props.config.camera.orbitParams.maxPolarAngle) : 0} type="maxPolarAngle" />
                            <MenuItemInputRange2 isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} step={0.1} min={0} max={3.14} disableInput={true} name={lang.restrict_up} value={this.props.config.camera.orbitParams.minPolarAngle ? (this.props.config.camera.orbitParams.minPolarAngle > 3.14 ? 3.14 : this.props.config.camera.orbitParams.minPolarAngle) : 0} type="minPolarAngle" />
                            <MenuItemInputRangeMaxAzimuth isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} originalValue={originalMaxAzimuth} step={0.1} max={10} disableInput={true} name={lang.restrict_left} value={this.props.config.camera.orbitParams.maxAzimuthAngle} type="maxAzimuthAngle" />
                            <MenuItemInputRangeMinAzimuth isNeedValueChange={true} valueChange={this.restrictCameraClickInternal} originalValue={originalMinAzimuth} min={-10} step={0.1} max={10} disableInput={true} name={lang.restrict_right} value={this.props.config.camera.orbitParams.minAzimuthAngle} type="minAzimuthAngle" />
                        </span>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(RestrictCameraModal);