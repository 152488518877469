import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    deletePresetFolder,
    loadCombinations
} from "../../../actions/actionsCreators";
import closeIcon from "../../../assets/close-icon@2x.png";
import Combinations from "./Combinations";

class ShowCombinationsModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
        this.props.loadCombinations()
    }

    render() {
        return (
            <React.Fragment>
                <button style={{backgroundColor: 'grey'}} className="add-combination-btn btn btn-primary" onClick={this.handleShow}>VIEW</button>
                <Modal id="view-storage-modal" size="xl" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Combinations />
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    deletePresetFolder: deletePresetFolder,
    loadCombinations: loadCombinations
}

export default connect(null, mapDispatchToProps)(ShowCombinationsModal)