import React, {Component, } from "react";
import {connect} from "react-redux";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";
import {login} from '../../../actions/actionsCreators';
import {Modal, Form, Button, Tabs, Tab, Accordion} from "react-bootstrap";
import MenuItemInputRange from "../Sidebar/MenuItems/MenuItemInputRange";
import MenuItemColorPicker from "../Sidebar/MenuItems/MenuItemColorPicker";

import CombinationsCreations from "./CombinationsCreations";
import CombinationsSize from "./CombinationsSize";
import Activity from "./Activity";
import Transactions from "./Transactions";

import closeIcon from "../../../assets/close-icon@2x.png";
import {Table, Col, Row, Spinner} from "react-bootstrap";
import {
    getCombinationsSize,
    getActivityInfo,
    getImagesSize,
    getTransactions
} from "../../../actions/actionsCreators";

import lang_it from '../../../lang/lang_it.json';
import lang_en from '../../../lang/lang_en.json';
import lang_fr from '../../../lang/lang_fr.json';
import lang_vn from '../../../lang/lang_vn.json';
import galleryUrl from '../../../galleryUrl';
import jwt_decode from "jwt-decode";
let lang = {};
class LogoutForm extends Component {
    state = {
        email: '',
        showCreations: false,
        show: false,
        showActivity: false,
        hasError: false,
        error: '',
        loading: false,

        showChangePassword: false,
        password: '',
        confirmPassword: '',
        submittingChangePassword: false,
        passwordError: '',

        showUpdateNotificationSettings: false,
        disableViewsClicksNotification: false,
        disableStorageNotification: false,
        submittingUpdateNotificationSettings: false,

        submittingUpdateProfile: false,

        languageShow: false,
        defaultEnvShow: false,

        defaultDiamondEnv: {},
        defaultSceneEnv: {},
        defaultBackground: {},
        defaultBackgroundType: 'file',

        paypalShow: false,
        paypal: {
            paypalSandboxId: '',
            paypalProductionId: ''
        },
        currency: 'USD',

        profileImage: '',
        coverImage: '',
        bio: '',
        username: '',
        uuid: '',
        country: '',
        city: '',

        defaultAnnotationIconShow: false,
        annotationUrl: '',
        loadingUrl: '',
        swipeUrl: '',
        logoUrl: '',
        logoStayDuration: '',
        logoFadeInDuration: '',
        logoFadeOutDuration: '',
        logoScale: '',
        logoPositionX: '',
        logoPositionY: '',

        selectedLogoUrl: '',
        selectedLoadingUrl: '',
        selectedSwipeUrl: ''
    }

    
    getRoundedValue = (id) => {
        const value = document.getElementById(id).value;
        let defaultValue = 0;
        switch(id){
            case "logo_duration":
                defaultValue = this.state.logoStayDuration;
            break;
            case "logo_fade_in":
                defaultValue = this.state.logoFadeInDuration;
            break;
            case "logo_fade_out":
                defaultValue = this.state.logoFadeOutDuration;
            break;
            case "logo_scale":
                defaultValue = this.state.logoScale;
            break;
            case "logo_position_x":
                defaultValue = this.state.logoPositionX;
            break;
            case "logo_position_y":
                defaultValue = this.state.logoPositionY;
            break;
        }
        return value ? Math.round(parseFloat(value) * 10) / 10 : defaultValue;
    };

    componentDidMount() {

        axios.get('user/get-user-info').then(response => {
            let {data} = response;
            if(data.success)
            {
                let user = data.data;
                this.setState({
                    disableViewsClicksNotification: user.disableViewsClicksNotification
                })
                this.setState({
                    disableStorageNotification: user.disableStorageNotification
                })
                if(user.lang){
                    localStorage.setItem("lang", user.lang);
                }
                if(user.defaultSceneEnv){
                    this.setState({
                        defaultSceneEnv: user.defaultSceneEnv
                    })
                }else{
                    this.setState({
                        defaultSceneEnv: null
                    })
                }
                if(user.annotationUrl){
                    this.setState({
                        annotationUrl: user.annotationUrl
                    })
                }else{
                    this.setState({
                        annotationUrl: null
                    })
                }
                if(user.loadingUrl){
                    this.setState({
                        loadingUrl: user.loadingUrl
                    })
                }else{
                    this.setState({
                        loadingUrl: null
                    })
                }
                if(user.swipeUrl){
                    this.setState({
                        swipeUrl: user.swipeUrl
                    })
                }else{
                    this.setState({
                        swipeUrl: null
                    })
                }



                if(user.logoUrl){
                    this.setState({
                        logoUrl: user.logoUrl,
                        logoStayDuration: user.logoStayDuration,
                        logoFadeInDuration: user.logoFadeInDuration,
                        logoFadeOutDuration: user.logoFadeOutDuration,
                        logoScale: user.logoScale,
                        logoPositionX: user.logoPositionX,
                        logoPositionY: user.logoPositionY,
                    })
                    document.getElementById("logo_duration").value = user.logoStayDuration;
                    document.getElementById("logo_fade_in").value = user.logoFadeInDuration;
                    document.getElementById("logo_fade_out").value = user.logoFadeOutDuration;
                    document.getElementById("logo_scale").value = user.logoScale;
                    document.getElementById("logo_position_x").value = user.logoPositionX;
                    document.getElementById("logo_position_y").value = user.logoPositionY;

                }else{
                    this.setState({
                        logoUrl: null,
                        logoStayDuration: null,
                        logoFadeInDuration: null,
                        logoFadeOutDuration: null,
                        logoScale: null,
                        logoPositionX: null,
                        logoPositionY: null,
                    })
                    document.getElementById("logo_duration").value = 0;
                    document.getElementById("logo_fade_in").value = 0;
                    document.getElementById("logo_fade_out").value = 0;
                    document.getElementById("logo_scale").value = 0;
                    document.getElementById("logo_position_x").value = 0;
                    document.getElementById("logo_position_y").value = 0;
                }






                if(user.defaultDiamondEnv){
                    this.setState({
                        defaultDiamondEnv: user.defaultDiamondEnv
                    })
                }else{
                    this.setState({
                        defaultDiamondEnv: null
                    })
                }

                if(user.defaultBackground){
                    this.setState({
                        defaultBackground: user.defaultBackground
                    })
                    this.setState({
                        defaultBackgroundType: user.defaultBackground.type
                    })
                }else{
                    this.setState({
                        defaultBackground: null
                    })
                    this.setState({
                        defaultBackgroundType: 'file'
                    })
                }
                if(user.currency){
                    this.setState({
                        currency: user.currency
                    })
                }else{
                    this.setState({
                        currency: 'USD'
                    })
                }

                let paypalProps = {};
                if(user.paypalSandboxId){
                    paypalProps.paypalSandboxId = user.paypalSandboxId;
                }
                if(user.paypalProductionId){
                    paypalProps.paypalProductionId = user.paypalProductionId;
                }
                this.setState({
                    paypal: paypalProps
                })

                
                this.setState({
                    country: user.country
                })
                this.setState({
                    city: user.city
                })
                this.setState({
                    username: user.username
                })
                this.setState({
                    uuid: user.uuid
                })
                this.setState({
                    bio: user.bio
                })
                this.setState({
                    profileImage: user.profileImage
                })
                this.setState({
                    coverImage: user.coverImage
                })

            }
        });
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }

    /**
     * Update combination with new price and description
     */

     openGallery = () => {
        window.open(galleryUrl+"/gallery/"+this.state.username, "_blank");
    }

    openViewProfile = () => {
        axios.get('user/impersonate-gallery').then(response => {
            setTimeout(() => {
                console.log(response.data.data.uuid);
                window.open(galleryUrl + "?impersonate=" + response.data.data.uuid);
            }, 1000);

        }).catch(err => {
        })
        // window.open(galleryUrl+"/gallery/"+this.state.username, "_blank");
    }

    logout = () => {
        localStorage.removeItem("user");
        window.location.href = "/";
    }
    viewCreations = () => {
        this.setState({
            showCreations: true
        });
    }
    viewStorage = () => {
        this.setState({
            show: true
        });
    }
    viewActivity = () => {
        this.setState({
            showActivity: true
        });
        this.props.getActivityInfo()
    }
    viewTransaction = () => {
        this.setState({
            showTransaction: true
        });
        this.props.getTransactions()
    }
    
    handleCloseCreations = () => {
        this.setState({
            showCreations: false
        })
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }
    handleCloseTransaction = () => {
        this.setState({
            showTransaction: false
        })
    }
    handleCloseActivity = () => {
        this.setState({
            showActivity: false
        })
    }
    openChangePassword = () => {
        this.setState({
            showChangePassword: true
        })
    }
    handleCloseChangePassword = () => {
        this.setState({
            showChangePassword: false
        })
    }

    uploadProfileImage = () => {
        if(document.getElementById("profile_image_file").files[0] != undefined){
            this.setState({
                submittingUpdateProfile: true
            })
            let materialLabelData = new FormData();
            materialLabelData.append('label', document.getElementById("profile_image_file").files[0]);
            axios.post('preset/upload-material-label-img', materialLabelData
            ).then(response => {
                let label = response.data.data;
                setTimeout(() => {
                    this.setState({
                        profileImage: label
                    })
                    this.setState({
                        submittingUpdateProfile: false
                    })
                }, 2000);
            });
        }
    }

    uploadCoverImage = () => {
        if(document.getElementById("cover_image_file").files[0] != undefined){
            this.setState({
                submittingUpdateProfile: true
            })
            let materialLabelData = new FormData();
            materialLabelData.append('label', document.getElementById("cover_image_file").files[0]);
            axios.post('preset/upload-material-label-img', materialLabelData
            ).then(response => {
                let label = response.data.data;
                setTimeout(() => {
                    this.setState({
                        coverImage: label
                    })
                    this.setState({
                        submittingUpdateProfile: false
                    })
                }, 2000);
            });
        }
    }

    inputBio = (e) => {
        this.setState({
            bio: e.target.value
        })
    }

    inputUsername = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    inputCountry = (e) => {
        this.setState({
            country: e.target.value
        })
    }

    inputCity = (e) => {
        this.setState({
            city: e.target.value
        })
    }

    inputAnnotationUrl = (e) => {
        this.setState({
            annotationUrl: e.target.value
        })
    }

    inputLoadingUrl = (e) => {
        this.setState({
            loadingUrl: e.target.value
        })
    }

    inputLogoUrl = (e) => {
        this.setState({
            logoUrl: e.target.value
        })
    }

    handleFileSwipeChange = (event) => {
        // Get the selected file
        const file = event.target.files[0];
        
        if (file) {
            // Check file size (in bytes)
            const maxSize = 1 * 1024 * 1024; // 1MB in bytes
            if (file.size > maxSize) {
            alert('File size exceeds 1MB limit.');
            event.target.value = null; // Clear the file input
            return;
            }
            
            // Check file type
            const allowedTypes = ['image/gif'];
            if (!allowedTypes.includes(file.type)) {
            alert('Only GIF files are allowed.');
            event.target.value = null; // Clear the file input
            return;
            }
    
            // Now you can use the file
            // For example, you can upload it or store it in state
        }
        console.log("file: ", file);
        // Update state with the selected file
        this.setState({
            selectedSwipeUrl: file
        })
    };

    handleFileLoadingChange = (event) => {
        // Get the selected file
        const file = event.target.files[0];
        
        if (file) {
            // Check file size (in bytes)
            const maxSize = 1 * 1024 * 1024; // 1MB in bytes
            if (file.size > maxSize) {
            alert('File size exceeds 1MB limit.');
            event.target.value = null; // Clear the file input
            return;
            }
            
            // Check file type
            const allowedTypes = ['image/gif'];
            if (!allowedTypes.includes(file.type)) {
            alert('Only GIF files are allowed.');
            event.target.value = null; // Clear the file input
            return;
            }
    
            // Now you can use the file
            // For example, you can upload it or store it in state
        }
        console.log("file: ", file);
        // Update state with the selected file
        this.setState({
            selectedLoadingUrl: file
        })
    };

    handleFileLogoChange = (event) => {
        // Get the selected file
        const file = event.target.files[0];
        
        if (file) {
            // Check file size (in bytes)
            const maxSize = 1 * 1024 * 1024; // 1MB in bytes
            if (file.size > maxSize) {
            alert('File size exceeds 1MB limit.');
            event.target.value = null; // Clear the file input
            return;
            }
            
            // Check file type
            const allowedTypes = ['image/png'];
            if (!allowedTypes.includes(file.type)) {
            alert('Only PNG files are allowed.');
            event.target.value = null; // Clear the file input
            return;
            }
    
            // Now you can use the file
            // For example, you can upload it or store it in state
        }
        console.log("file: ", file);
        // Update state with the selected file
        this.setState({
            selectedLogoUrl: file
        })
    };

    
    submitUpdateProfile = () => {
        this.setState({
            submittingUpdateProfile: true
        })
        
        let profileImage = this.state.profileImage;
        let coverImage = this.state.coverImage;
        
        if (profileImage == '' || this.state.bio == '') {
            alert('Please update your profile image and bio');
            this.setState({
                submittingUpdateProfile: false
            })
            return false;
        }
        
        axios.post('user/update-profile', {
            city: this.state.city,
            country: this.state.country,
            username: this.state.username,
            bio: this.state.bio,
            profileImage: profileImage,
            coverImage: coverImage
        }).then(response => {
            alert('Update successfully!');
            this.setState({
                submittingUpdateProfile: false
            })
        }).catch(error => {
            alert(error.response.data.error.message);
            this.setState({
                submittingUpdateProfile: false
            })
        });
    }

    submitChangePassword = () => {
        this.setState({
            submittingChangePassword: true
        })
        if(this.state.password != this.state.confirmPassword)
        {
            alert('New Password and Password Confirmation is not the same');
            this.setState({
                submittingChangePassword: false
            })
        }
        else
        {

            axios.post('user/changePassword', {
                password: this.state.password,
            }).then(response => {
                alert('Update Password successfully!');
                this.setState({
                    submittingChangePassword: false,
                    showChangePassword: false
                })
            });
        }
    }
    
    inputNewPassword = e => {
        this.setState({
            password: e.target.value
        })
    }
    
    inputConfirmPassword = e => {
        this.setState({
            confirmPassword: e.target.value
        })
    }


    openUpdateNotificationSettings = () => {
        this.setState({
            showUpdateNotificationSettings: true
        })
    }
    handleCloseUpdateNotificationSettings = () => {
        this.setState({
            showUpdateNotificationSettings: false
        })
    }
    submitUpdateNotificationSettings = () => {
        this.setState({
            submittingUpdateNotificationSettings: true
        })

        axios.post('user/update-notification-settings', {
            disableViewsClicksNotification: this.state.disableViewsClicksNotification,
            disableStorageNotification: this.state.disableStorageNotification,
        }).then(response => {
            alert('Update successfully!');
            this.setState({
                submittingUpdateNotificationSettings: false,
                showUpdateNotificationSettings: false
            })
        });
    }
    
    toggleDisableViewsClicksNotification = e => {
        this.setState({
            disableViewsClicksNotification: !this.state.disableViewsClicksNotification
        })
    }
    
    toggleDisableStorageNotification = e => {
        this.setState({
            disableStorageNotification: !this.state.disableStorageNotification
        })
    }

    setLanguage = () => {
        this.setState({
            languageShow : true
        })
    }
    handleLanguageClose = () => {
        this.setState({
            languageShow : false
        })
    }


    setLang(lang){
        
        axios.post('user/updateLang', {
            lang: lang,
        }).then(response => {
            alert('Update successfully!');
            window.location.reload();
        });
    }

    
    setLangEng = e => {
        localStorage.setItem("lang", "en");
        this.setLang("en");
    }

    setLangVn = e => {
        localStorage.setItem("lang", "vn");
        this.setLang("vn");
    }

    setLangFr = e => {
        localStorage.setItem("lang", "fr");
        this.setLang("fr");
    }

    setLangIt = e => {
        localStorage.setItem("lang", "it");
        this.setLang("it");
    }


    setPaypal = () => {
        this.setState({
            paypalShow : true
        })
    }
    paypalClose = () => {
        this.setState({
            paypalShow : false
        })
    }

    savePaypal = () => {
        axios.post('user/update-paypal', {
            paypalSandboxId: this.state.paypal.paypalSandboxId,
            paypalProductionId: this.state.paypal.paypalProductionId,
            currency: this.state.currency
        }).then(response => {
            alert('Update successfully!');
        });
    }

    setPaypalSandbox = (e) => {
        let pp = this.state.paypal;
        pp.paypalSandboxId = e.target.value;
        this.setState({
            paypal : pp
        })
    }
    setPaypalProduction = (e) => {
        let pp = this.state.paypal;
        pp.paypalProductionId = e.target.value;
        this.setState({
            paypal : pp
        })
    }
    setPaypalCurrency = (e) => {
        this.setState({
            currency : e.target.value
        })
    }


    
    
    setDefaultAnnotationIcons = () => {
        this.setState({
            defaultAnnotationIconShow: true
        })
    }
    handleDefaultAnnotationIconsClose = () => {
        this.setState({
            defaultAnnotationIconShow: false
        })
    }

    
    setDefaultSwipeIcons = () => {
        this.setState({
            defaultSwipeIconShow: true
        })
    }
    handleDefaultSwipeIconsClose = () => {
        this.setState({
            defaultSwipeIconShow: false
        })
    }
    
    setDefaultLoadingIcons = () => {
        this.setState({
            defaultLoadingIconShow: true
        })
    }
    handleDefaultLoadingIconsClose = () => {
        this.setState({
            defaultLoadingIconShow: false
        })
    }

    
    setDefaultLogoIcons = () => {
        this.setState({
            defaultLogoIconShow: true
        })
    }
    handleDefaultLogoIconsClose = () => {
        this.setState({
            defaultLogoIconShow: false
        })
    }
    
    setDefaultEnv = () => {
        this.setState({
            defaultEnvShow: true
        })
    }
    handleDefaultEnvClose = () => {
        this.setState({
            defaultEnvShow: false
        })
    }

    triggerDefaultEnvFile = () => {
        document.getElementById("default_env_file").click();
    }

    triggerDefaultDiamondFile = () => {
        document.getElementById("default_diamond_file").click();
    }

    triggerDefaultBackground = () => {
        document.getElementById("default_background_file").click();
    }


    saveDefaultDiamondEnv = () => {
        
        if(document.getElementById("default_diamond_file").files[0] != undefined){
            let data = new FormData();
            data.append('default_diamond_file', document.getElementById("default_diamond_file").files[0]);
            axios.post('user/upload-default-diamond-env', data
            ).then(response => {
                let file = response.data.data;
                let payload = {
                    "defaultDiamondEnv":{
                        "path": file
                    }
                };
        
                axios.post('user/update-default-diamond-env', payload).then(response => {
                    this.setState({loading: false})
                    let {data} = response
                    if (!data.success) {
                        this.setState({
                            hasError: true,
                            error: data.error.message
                        })
                    }
        
                    if (data.success) {
                        alert("Update success!");
                    }
                })
            });
        }else{
            alert("Please choose HDR file");
        }
    }

    saveDefaultAnnotationUrl = () => {
        let payload = {
            "annotationUrl": this.state.annotationUrl
        };
        axios.post('user/update-annotation-url', payload).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                alert("Update success!");
            }
        })
    }

    saveDefaultSwipeUrl = () => {
        let payload = {
            "swipeUrl": this.state.swipeUrl
        };
        axios.post('user/update-swipe-url', payload).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                alert("Update success!");
            }

            this.setState({
                defaultSwipeIconShow: false
            })
        })
    }

    saveDefaultLoadingUrl = () => {
        let payload = {
            "loadingUrl": this.state.loadingUrl
        };
        axios.post('user/update-loading-url', payload).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                alert("Update success!");
            }

            this.setState({
                defaultLoadingIconShow: false
            })
        })
    }

    saveDefaultLogoUrl = () => {

        let payload = {
            "logoUrl": this.state.logoUrl,
            "logoStayDuration": document.getElementById("logo_duration").value,
            "logoFadeInDuration": document.getElementById("logo_fade_in").value,
            "logoFadeOutDuration": document.getElementById("logo_fade_out").value,
            // "logoScale": document.getElementById("logo_scale").value,
            "logoPositionX": document.getElementById("logo_position_x").value,
            "logoPositionY": document.getElementById("logo_position_y").value,
        };
        axios.post('user/update-logo-url', payload).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                alert("Update success! Please reload the page.");
            }
            

            this.setState({
                defaultLogoIconShow: false
            })
        })
    }

    uploadDefaultSwipeUrl = () => {
        let formData = new FormData();
        formData.append('file', this.state.selectedSwipeUrl);
        axios.post('user/upload-swipe', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.setState({
                    swipeUrl: data.data
                })
                alert("Upload success!");
            }
        })
    }

    uploadDefaultLoadingUrl = () => {
        let formData = new FormData();
        formData.append('file', this.state.selectedLoadingUrl);
        axios.post('user/upload-loading', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.setState({
                    loadingUrl: data.data
                })
                alert("Upload success!");
            }
        })
    }

    uploadDefaultLogoUrl = () => {
        let formData = new FormData();
        formData.append('file', this.state.selectedLogoUrl);
        axios.post('user/upload-logo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.setState({
                    logoUrl: data.data
                })
                alert("Upload success!");
            }
        })
    }

    saveDefaultSceneEnv = () => {
        
        if(document.getElementById("default_env_file").files[0] != undefined){
            let data = new FormData();
            data.append('default_env_file', document.getElementById("default_env_file").files[0]);
            axios.post('user/upload-default-scene-env', data
            ).then(response => {
                let file = response.data.data;
                let payload = {
                    "defaultSceneEnv":{
                        "path": file,
                        "default_env_exposure": document.getElementById("default_env_exposure").value,
                        "default_env_saturation": document.getElementById("default_env_saturation").value,
                        "default_env_rotation": document.getElementById("default_env_rotation").value,
                        "default_env_contrast": document.getElementById("default_env_contrast").value
                    }
                };
        
                axios.post('user/update-default-scene-env', payload).then(response => {
                    this.setState({loading: false})
                    let {data} = response
                    if (!data.success) {
                        this.setState({
                            hasError: true,
                            error: data.error.message
                        })
                    }
        
                    if (data.success) {
                        alert("Update success!");
                    }
                })
            });
        }else{
            alert("Please choose HDR file");
        }
    }

    saveDefaultBackground = () => {
        if(this.state.defaultBackgroundType == "file"){
            if(document.getElementById("default_background_file").files[0] != undefined){
                let data = new FormData();
                data.append('default_background_file', document.getElementById("default_background_file").files[0]);
                axios.post('user/upload-default-background', data
                ).then(response => {
                    let file = response.data.data;
                    let payload = {
                        "defaultBackground":{
                            "path": file,
                            "color": document.getElementById("colorValue").value,
                            "type": "file",
                        }
                    };
            
                    axios.post('user/update-default-background', payload).then(response => {
                        this.setState({loading: false})
                        let {data} = response
                        if (!data.success) {
                            this.setState({
                                hasError: true,
                                error: data.error.message
                            })
                        }
            
                        if (data.success) {
                            alert("Update success!");
                        }
                    })
                });
            }else{
                alert("Please choose image file");
            }
        }else{
            let payload = {
                "defaultBackground":{
                    "color": document.getElementById("colorValue").value,
                    "type": "color",
                }
            };
    
            axios.post('user/update-default-background', payload).then(response => {
                this.setState({loading: false})
                let {data} = response
                if (!data.success) {
                    this.setState({
                        hasError: true,
                        error: data.error.message
                    })
                }
    
                if (data.success) {
                    alert("Update success!");
                }
            })
        }
    }

    changeDefaultBackgroundType = (value) =>{
        console.log(value.target.value);
        this.setState({
            defaultBackgroundType: value.target.value
        })
    }

    resetDefaultSetup = (value) =>{
        axios.post('user/reset-default-env').then(response => {
            alert('Update successfully!');
            window.location.reload();
        });
    }

    render() {

        return(
            <React.Fragment>
                <Table className="combinations-table table-borderless" size="lg">
                    <thead>

                    </thead>
                    <tbody>
                        <tr>
                            <td> {lang.my_account} </td>
                        </tr>
                        <tr>
                            <td className="my-account-tab">
                                <input style={{marginLeft: 0, marginRight: 0}} placeholder="" className="form-control" name="email" type="text" value={this.props.email} readOnly={true} />
                            </td>
                        </tr>
                        <tr>
                            <td className="my-account-tab">
                                    



                                    {/* Set Env Default */}
                                    <React.Fragment>
                                        
                                        <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setDefaultEnv} >{lang.default_scene}</button>
                                        <Modal className="modalSize50" size="xl" show={this.state.defaultEnvShow} onHide={this.handleDefaultEnvClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleDefaultEnvClose}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                    
                                                    <Row id="defaultSceneEnv_title" >
                                                        <Col style={{textAlign: 'center', color: 'white'}} md={{ span: 12}}>
                                                            <h5>Refresh your app to see the change</h5>
                                                        </Col>
                                                    </Row>

                                                    <Row id="defaultSceneEnv_title"  style={{marginTop: 50}}>
                                                        <Col md={{ span: 12}} style={{color: 'white'}}>
                                                            <h3 style={{textAlign: 'center'}}>{lang.set_default_scene_env}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row id="defaultSceneEnv">
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <Row  /*{...this.props}*/>
                                                                <Col md={{ span: 12}}>
                                                                    <span style={{cursor: 'pointer'}} onClick={this.triggerDefaultEnvFile} title="Load hdr image" className="setting-name">Load Environment</span>
                                                                    <Form.Control
                                                                        style={{color: 'white', display: 'none'}}
                                                                        size="sm"
                                                                        type="file"
                                                                        id="default_env_file"/>
                                                                    <Form.Control
                                                                        style={{color: 'black', marginTop: 20}}
                                                                        size="sm"
                                                                        type="text"
                                                                        value={this.state.defaultSceneEnv ? this.state.defaultSceneEnv.path : ''}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <MenuItemInputRange
                                                            id="default_env_exposure"
                                                            title={this.state.tooltip != null ? this.state.tooltip.exposure : ''}
                                                            name={lang.exposure} step={0.1} min={0} max={2} value={this.state.defaultSceneEnv ? this.state.defaultSceneEnv.default_env_exposure : 1}  />
                                                            <MenuItemInputRange
                                                            id="default_env_saturation"
                                                            title={this.state.tooltip != null ? this.state.tooltip.saturation : ''}
                                                            name={lang.saturation} step={0.1} min={0} max={2} value={this.state.defaultSceneEnv ? this.state.defaultSceneEnv.default_env_saturation : 1}  />
                                                            <MenuItemInputRange
                                                            id="default_env_rotation"
                                                            title={this.state.tooltip != null ? this.state.tooltip.rotation : ''}
                                                            name={lang.rotation} step={0.1} min={0} max={6} value={this.state.defaultSceneEnv ? this.state.defaultSceneEnv.default_env_rotation : 0}  />
                                                            <MenuItemInputRange
                                                            id="default_env_contrast"
                                                            title={this.state.tooltip != null ? this.state.tooltip.contrast : ''}
                                                            name={lang.contrast} step={0.1} min={0} max={2} value={this.state.defaultSceneEnv ? this.state.defaultSceneEnv.default_env_contrast : 1} />
                                                        </Col>
                                                        <Col className="defaultSceneButton" md={{ span: 12}}>
                                                            <Button variant="primary" onClick={this.saveDefaultSceneEnv}>
                                                                Save
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <Row id="defaultDiamondEnv_title"  style={{marginTop: 50}}>
                                                        <Col md={{ span: 12}} style={{color: 'white'}}>
                                                            <h3 style={{textAlign: 'center'}}>{lang.set_default_diamond_env}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row id="defaultDiamondEnv">
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <Row  /*{...this.props}*/>
                                                                <Col md={{ span: 12}}>
                                                                    <span style={{cursor: 'pointer'}} onClick={this.triggerDefaultDiamondFile} title="Load hdr image" className="setting-name">Load Environment</span>
                                                                    <Form.Control
                                                                        style={{color: 'white', display: 'none'}}
                                                                        size="sm"
                                                                        type="file"
                                                                        id="default_diamond_file"/>
                                                                    <Form.Control
                                                                        style={{color: 'black', marginTop: 20}}
                                                                        size="sm"
                                                                        type="text"
                                                                        value={this.state.defaultDiamondEnv ? this.state.defaultDiamondEnv.path : ''}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="defaultSceneButton" md={{ span: 12}}>
                                                            <Button variant="primary" onClick={this.saveDefaultDiamondEnv}>
                                                                Save
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    <Row id="defaultBackground_title" style={{marginTop: 50}}>
                                                        <Col md={{ span: 12}} style={{color: 'white'}}>
                                                            <h3 style={{textAlign: 'center'}}>{lang.set_default_background}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row id="defaultBackground">
                                                        <Col className="background343c42 row" md={{ span: 12}} style={{color: 'white', textAlign: 'center'}}>
                                                            
                                                            <Col md={{ span: 6}} style={{color: 'white'}}>
                                                                <Form.Check defaultChecked={this.state.defaultBackgroundType == 'file'} value='file' onChange={this.changeDefaultBackgroundType}  inline label="File" name="choose_default_background" id="choose_default_background" type='radio'  />
                                                            </Col>
                                                            <Col md={{ span: 6}} style={{color: 'white'}}>
                                                                <Form.Check defaultChecked={this.state.defaultBackgroundType == 'color'} value='color' onChange={this.changeDefaultBackgroundType}  inline label="Color" name="choose_default_background" id="choose_default_background" type='radio'  />
                                                            </Col>
                                                            
                                                            
                                                            
                                                        </Col>
                                                        {/* <Col md={{ span: 12}}>
                                                            
                                                            <Row>
                                                                <Col md={12} className="setting-col-input">
                                                                    <span title={this.props.title} className="setting-name">Default Background file</span>
                                                                </Col>
                                                                
                                                            </Row>
                                                        </Col> */}
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <MenuItemColorPicker
                                                            title={this.state.tooltip != null ? this.state.tooltip.color : ''}
                                                            name={lang.color} value={this.state.defaultBackground ? this.state.defaultBackground.color : ''}  />
                                                        </Col>
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <span style={{cursor: 'pointer'}} onClick={this.triggerDefaultBackground} title="Load hdr image" className="setting-name">Load Image</span>
                                                                <Form.Control
                                                                    style={{color: 'white', display: 'none'}}
                                                                    size="sm"
                                                                    type="file"
                                                                    id="default_background_file"/>
                                                                {/* <Form.Control
                                                                    style={{color: 'black', marginTop: 20}}
                                                                    size="sm"
                                                                    type="text"
                                                                    value={this.state.defaultBackground ? this.state.defaultBackground.path : ''}/> */}
                                                        </Col>

                                                        <Col className="defaultSceneButton" md={{ span: 12}}>
                                                            <Button variant="primary" onClick={this.saveDefaultBackground}>
                                                                Save
                                                            </Button>
                                                        </Col>

                                                    </Row>
                                                    <Row style={{marginTop: 10}} id="defaultSceneButton_reset">
                                                        <Col className="defaultSceneButton" md={{ span: 12}} style={{color: 'white'}}>
                                                            <Button variant="primary" onClick={this.resetDefaultSetup}>
                                                                Reset
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>

                                    {/* View Storage */}
                                    <React.Fragment>
                                        <button id="btnViewStorage" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.viewStorage} disabled={this.state.loading} >{lang.view_storage}</button>
                                        <Modal id="view-storage-modal" size="xl" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <CombinationsSize username={this.state.username} userPermission={this.props.userPermission} />
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>
                                    

                                    {/* View Activity */}
                                    <React.Fragment>
                                        <button id="btnViewActivity" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.viewActivity} disabled={this.state.loading} >{lang.view_activity}</button>
                                        <Modal size="xl" show={this.state.showActivity} onHide={this.handleCloseActivity} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseActivity}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body style={{color: 'white'}}>
                                                <Activity />
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>
                                    

                                    

                                    {/* Set Default Annotation Icons */}
                                    <React.Fragment>
                                        
                                        <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setDefaultAnnotationIcons} >{lang.default_annotation_icons}</button>
                                        <Modal className="modalSize50" size="xl" show={this.state.defaultAnnotationIconShow} onHide={this.handleDefaultAnnotationIconsClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleDefaultAnnotationIconsClose}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                    
                                                    <Row id="defaultSceneEnv_title" >
                                                        <Col style={{textAlign: 'center', color: 'white'}} md={{ span: 12}}>
                                                            <h5>Re-login your app to see the change</h5>
                                                        </Col>
                                                    </Row>

                                                    <Row id="defaultSceneEnv_title"  style={{marginTop: 50}}>
                                                        <Col md={{ span: 12}} style={{color: 'white'}}>
                                                            <h3 style={{textAlign: 'center'}}>{lang.custom_annotation_icons_url}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row id="defaultSceneEnv">
                                                        <Col className="background343c42" md={{ span: 12}}>
                                                            <Row  /*{...this.props}*/>
                                                                <Col md={{ span: 12}} style={{padding: 0}}>
                                                                    <Form.Control onChange={this.inputAnnotationUrl} id="annotaion-url"
                                                                        style={{color: 'black', marginTop: 20, marginBottom: 0 + 'px !important'}}
                                                                        size="sm"
                                                                        type="text"
                                                                        value={this.state.annotationUrl ? this.state.annotationUrl : ''}/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="defaultSceneButton" md={{ span: 12}}>
                                                            <Button variant="primary" onClick={this.saveDefaultAnnotationUrl}>
                                                                Save
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>

                                    {
                                        this.props.userPermission != undefined && this.props.userPermission.isAllowCustomSwipeIconSetting
                                        ?
                                        <>
                                        {/* Set Default Swipe Icons */}
                                        <React.Fragment>
                                            
                                            <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setDefaultSwipeIcons} >{lang.custom_swipe_icons_url}</button>
                                            <Modal className="modalSize50" size="xl" show={this.state.defaultSwipeIconShow} onHide={this.handleDefaultSwipeIconsClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                <Modal.Header closeButton className="rename-material-modal-title">
                                                    <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                    <div>
                                                        <img className="close-icon" src={closeIcon} alt="" onClick={this.handleDefaultSwipeIconsClose}/>
                                                    </div>
                                                </Modal.Header>
                                                <Modal.Body>
                                                        <Row id="defaultSceneEnv_title"  style={{marginTop: 0}}>
                                                            <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                <h3 style={{textAlign: 'center'}}>{lang.custom_swipe_icons_url}</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row id="defaultSceneEnv">
                                                            <Col className="background343c42" md={{ span: 12}}>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 9}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.handleFileSwipeChange} 
                                                                            style={{color: 'black', paddingTop: 6}}
                                                                            size="sm"
                                                                            type="file" />
                                                                    </Col>
                                                                    <Col md={{ span: 3}} style={{padding: 0}}>
                                                                        <Button variant="primary" onClick={this.uploadDefaultSwipeUrl}>
                                                                            Upload
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 12}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.inputSwipeUrl} id="annotaion-url"
                                                                            style={{color: 'black', marginTop: 20, marginBottom: 0 + 'px !important'}}
                                                                            size="sm"
                                                                            type="text"
                                                                            value={this.state.swipeUrl ? this.state.swipeUrl : ''}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="defaultSceneButton" md={{ span: 12}}>
                                                                <Button variant="primary" onClick={this.saveDefaultSwipeUrl}>
                                                                    Save
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        
                                                </Modal.Body>
                                            </Modal>
                                        </React.Fragment>
    
                                        </>
                                        :
                                        <></>
                                    }

                                    {
                                        this.props.userPermission != undefined && this.props.userPermission.isAllowCustomLoadingSetting
                                        ?
                                        <>
                                        {/* Set Default Loading Icons */}
                                        <React.Fragment>
                                            
                                            <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setDefaultLoadingIcons} >{lang.custom_loading_icons_url}</button>
                                            <Modal className="modalSize50" size="xl" show={this.state.defaultLoadingIconShow} onHide={this.handleDefaultLoadingIconsClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                <Modal.Header closeButton className="rename-material-modal-title">
                                                    <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                    <div>
                                                        <img className="close-icon" src={closeIcon} alt="" onClick={this.handleDefaultLoadingIconsClose}/>
                                                    </div>
                                                </Modal.Header>
                                                <Modal.Body>
                                                        <Row id="defaultSceneEnv_title"  style={{marginTop: 0}}>
                                                            <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                <h3 style={{textAlign: 'center'}}>{lang.custom_loading_icons_url}</h3>
                                                                <p>Recommended size 100 x 100px</p>
                                                            </Col>
                                                        </Row>
                                                        <Row id="defaultSceneEnv">
                                                            <Col className="background343c42" md={{ span: 12}}>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 9}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.handleFileLoadingChange} 
                                                                            style={{color: 'black', paddingTop: 6}}
                                                                            size="sm"
                                                                            type="file" />
                                                                    </Col>
                                                                    <Col md={{ span: 3}} style={{padding: 0}}>
                                                                        <Button variant="primary" onClick={this.uploadDefaultLoadingUrl}>
                                                                            Upload
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 12}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.inputLoadingUrl} id="annotaion-url"
                                                                            style={{color: 'black', marginTop: 20, marginBottom: 0 + 'px !important'}}
                                                                            size="sm"
                                                                            type="text"
                                                                            value={this.state.loadingUrl ? this.state.loadingUrl : ''}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className="defaultSceneButton" md={{ span: 12}}>
                                                                <Button variant="primary" onClick={this.saveDefaultLoadingUrl}>
                                                                    Save
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        
                                                </Modal.Body>
                                            </Modal>
                                        </React.Fragment>
    
                                        </>
                                        :
                                        <></>
                                    }

                                    {
                                        this.props.userPermission != undefined && this.props.userPermission.isAllowCustomLogoSetting
                                        ?
                                        <>
                                        {/* Set Default Logo Icons */}
                                        <React.Fragment>
                                            
                                            <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setDefaultLogoIcons} >{lang.custom_logo_icons_url}</button>
                                            <Modal className="modalSize50" size="xl" show={this.state.defaultLogoIconShow} onHide={this.handleDefaultLogoIconsClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                <Modal.Header closeButton className="rename-material-modal-title">
                                                    <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                    <div>
                                                        <img className="close-icon" src={closeIcon} alt="" onClick={this.handleDefaultLogoIconsClose}/>
                                                    </div>
                                                </Modal.Header>
                                                <Modal.Body>
                                                        <Row id="defaultSceneEnv_title"  style={{marginTop: 0}}>
                                                            <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                <h3 style={{textAlign: 'center'}}>{lang.custom_logo_icons_url}</h3>
                                                            </Col>
                                                        </Row>
                                                        <Row id="defaultSceneEnv">
                                                            <Col className="background343c42" md={{ span: 12}}>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 9}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.handleFileLogoChange} 
                                                                            style={{color: 'black', paddingTop: 6}}
                                                                            size="sm"
                                                                            type="file" />
                                                                    </Col>
                                                                    <Col md={{ span: 3}} style={{padding: 0}}>
                                                                        <Button variant="primary" onClick={this.uploadDefaultLogoUrl}>
                                                                            Upload
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row  /*{...this.props}*/>
                                                                    <Col md={{ span: 12}} style={{padding: 0}}>
                                                                        <Form.Control onChange={this.inputLogoUrl} 
                                                                            style={{color: 'black', marginTop: 20, marginBottom: 0 + 'px !important'}}
                                                                            size="sm"
                                                                            type="text"
                                                                            value={this.state.logoUrl ? this.state.logoUrl : ''}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            
                                                            <Col className="defaultSceneButton" md={{ span: 12}}>
                                                            <MenuItemInputRange name={lang.stay_duration} step={0.1} min={0} max={60} value={this.getRoundedValue("logo_duration")} type="logoStayDuration" />
                                                                <MenuItemInputRange name={lang.fade_in_duration} step={0.1} min={0} max={3} value={this.getRoundedValue("logo_fade_in")} type="logoFadeInDuration" />
                                                                <MenuItemInputRange name={lang.fade_out_duration} step={0.1} min={0} max={3} value={this.getRoundedValue("logo_fade_out")} type="logoFadeOutDuration" />
                                                                {/* <MenuItemInputRange name={lang.scale} step={0.1} min={0} max={4} value={this.getRoundedValue("logo_scale")} type="logoScale" /> */}
                                                                <MenuItemInputRange name={lang.position_x} step={0.1} min={0} max={1} value={this.getRoundedValue("logo_position_x")} type="logoPositionX" />
                                                                <MenuItemInputRange name={lang.position_y} step={0.1} min={0} max={1} value={this.getRoundedValue("logo_position_y")} type="logoPositionY" />
                                                            </Col>
    
    
                                                            <Col className="defaultSceneButton" md={{ span: 12}}>
                                                                <Button variant="primary" onClick={this.saveDefaultLogoUrl}>
                                                                    Save
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        
                                                </Modal.Body>
                                            </Modal>
                                        </React.Fragment>
    
                                        </>
                                        :
                                        <></>
                                    }

                                    {/* Paypal */}
                                    {
                                        this.props.userPermission != undefined && this.props.userPermission.isShowBuyNowButton
                                        ?
                                            <React.Fragment>
                                                <>
                                                    <button id="" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.setPaypal} >{lang.set_paypal}</button>
                                                    <Modal className="modalSize50" size="xl" show={this.state.paypalShow} onHide={this.paypalClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                        <Modal.Header closeButton className="rename-material-modal-title">
                                                            <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                            <div>
                                                                <img className="close-icon" src={closeIcon} alt="" onClick={this.paypalClose}/>
                                                            </div>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Row id="paypalSandbox_title">
                                                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                    <h3 style={{textAlign: 'center'}}>{lang.set_paypal_sandbox}</h3>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row id="paypalSandbox">
                                                                <Col style={{padding: 0+'px'}} className="background343c42" md={{ span: 12}}>
                                                                    <Row>
                                                                        <Col md={{ span: 12}}>
                                                                            <Form.Control
                                                                                value={this.state.paypal ? this.state.paypal.paypalSandboxId : ''}
                                                                                size="sm"
                                                                                type="text"
                                                                                onChange={this.setPaypalSandbox}
                                                                                id="paypalSandboxId"/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row> */}
                                                            <Row id="paypalProduction_title" style={{marginTop: 20+'px'}}>
                                                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                    <h3 style={{textAlign: 'center'}}>{lang.set_paypal_production}</h3>
                                                                </Col>
                                                            </Row>
                                                            <Row id="paypalProduction">
                                                                <Col style={{padding: 0+'px'}} className="background343c42" md={{ span: 12}}>
                                                                    <Row  /*{...this.props}*/>
                                                                        <Col md={{ span: 12}}>
                                                                            <Form.Control
                                                                                value={this.state.paypal ? this.state.paypal.paypalProductionId : ''}
                                                                                size="sm"
                                                                                onChange={this.setPaypalProduction}
                                                                                type="text"
                                                                                id="paypalProductionId"/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row id="paypalProduction_title" style={{marginTop: 20+'px'}}>
                                                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                                                    <h3 style={{textAlign: 'center'}}>{lang.currency}</h3>
                                                                </Col>
                                                            </Row>
                                                            <Row id="paypalProduction">
                                                                <Col style={{padding: 0+'px'}} className="background343c42" md={{ span: 12}}>
                                                                    <Row  /*{...this.props}*/>
                                                                        <Col md={{ span: 12}}>
                                                                            <Col md={{ span: 6}} style={{color: 'white'}}>
                                                                                <Form.Check defaultChecked={this.state.currency == 'USD'} value='USD' onChange={this.setPaypalCurrency}  inline label="USD" name="choose_currency" id="choose_currency" type='radio'  />
                                                                            </Col>
                                                                            <Col md={{ span: 6}} style={{color: 'white'}}>
                                                                                <Form.Check defaultChecked={this.state.currency == 'EUR'} value='EUR' onChange={this.setPaypalCurrency}  inline label="EUR" name="choose_currency" id="choose_currency" type='radio'  />
                                                                            </Col>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row id="paypalProduction">
                                                                <Col className="defaultSceneButton" md={{ span: 12}}>
                                                                    <Button variant="primary" onClick={this.savePaypal}>
                                                                        Save
                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                    </Modal.Body>
                                                    </Modal>
                                                </>
                                            </React.Fragment>
                                
                                        : ''
                                    }
                                    
                                    
                            
                                    
                                    {/* View Orders */}
                                    <React.Fragment>
                                        <button id="btnViewOrders" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.viewTransaction} disabled={this.state.loading} >{lang.view_orders}</button>
                                        <Modal size="xl" show={this.state.showTransaction} onHide={this.handleCloseTransaction} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseTransaction}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body style={{color: 'white'}}>
                                                <Transactions />
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>


                                    {/* Notification Settings */}
                                    <React.Fragment>
                                        <button id="btnUpdateNotificationSettings" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.openUpdateNotificationSettings} disabled={this.state.loading} >{lang.notification_settings}</button>
                                        <Modal size="xl" show={this.state.showUpdateNotificationSettings} onHide={this.handleCloseUpdateNotificationSettings} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseUpdateNotificationSettings}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body style={{color: 'white'}}>
                                                <Form.Group>
                                                    <Form.Label style={{color: 'white'}}>{lang.disable_views_clicks_notification}</Form.Label>
                                                    <Form.Check
                                                        checked={this.state.disableViewsClicksNotification}
                                                        onChange={this.toggleDisableViewsClicksNotification}
                                                        type="checkbox"
                                                        id="disableViewsClicksNotification"
                                                        className="float-right"/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{color: 'white'}}>{lang.disable_storage_notification}</Form.Label>
                                                    <Form.Check
                                                        checked={this.state.disableStorageNotification}
                                                        onChange={this.toggleDisableStorageNotification}
                                                        type="checkbox"
                                                        id="disableStorageNotification"
                                                        className="float-right"/>
                                                </Form.Group>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button disabled={this.state.submittingUpdateNotificationSettings} style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.submitUpdateNotificationSettings}  >{lang.submit}</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </React.Fragment>





                                    {/* Reset Password */}
                                    <React.Fragment>
                                        <button id="btnChangePassword" style={{marginBottom: '0', display: 'none'}} className="add-combination-btn btn btn-primary" onClick={this.openChangePassword} disabled={this.state.loading} >{lang.edit_profile}</button>
                                        <Modal size="xl" show={this.state.showChangePassword} onHide={this.handleCloseChangePassword} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseChangePassword}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body style={{color: 'white'}}>
                                                {/* Bio */}
                                                <Form.Group controlId="Profile Image">
                                                    <Form.Label style={{color: 'white', display: 'block'}}>Profile Image <span style={{fontStyle: 'italic', fontWeight: 100}}>(recommended size 195x195 px, 400kb)</span></Form.Label>
                                                    <img style={{display: 'block'}}  id="profile_image" src={this.state.profileImage} width="50px" />
                                                    <Form.Control
                                                    style={{color: 'white', width: 40+'%', display: 'inline-block'}}
                                                    size="sm"
                                                    type="file"
                                                    id="profile_image_file"/>
                                                    <button disabled={this.state.submittingUpdateProfile} id="btn_upload_profile_image" style={{width: 40+'%', display: 'inline-block', float:'right'}} class="btn btn-primary" onClick={this.uploadProfileImage}>Upload</button>
                                                </Form.Group>
                                                <Form.Group controlId="Cover Image">
                                                    <Form.Label style={{color: 'white', display: 'block'}}>Cover Image <span style={{fontStyle: 'italic', fontWeight: 100}}>(recommended size 1600x150 px, 400kb)</span></Form.Label>
                                                    <img style={{display: 'block'}}  id="cover_image" src={this.state.coverImage} width="100px" />
                                                    <Form.Control
                                                    style={{color: 'white', width: 40+'%', display: 'inline-block'}}
                                                    size="sm"
                                                    type="file"
                                                    id="cover_image_file"/>
                                                    <button disabled={this.state.submittingUpdateProfile} id="btn_upload_cover_image" style={{width: 40+'%', display: 'inline-block', float:'right'}} class="btn btn-primary" onClick={this.uploadCoverImage}>Upload</button>
                                                </Form.Group>
                                                <Form.Group controlId="username">
                                                    <Form.Label style={{color: 'white'}}>Username</Form.Label>
                                                    <Form.Control onChange={this.inputUsername}  value={this.state.username}  />
                                                </Form.Group>
                                                <Form.Group controlId="country">
                                                    <Form.Label style={{color: 'white'}}>Country</Form.Label>
                                                    <Form.Control onChange={this.inputCountry}  value={this.state.country}  />
                                                </Form.Group>
                                                <Form.Group controlId="city">
                                                    <Form.Label style={{color: 'white'}}>City</Form.Label>
                                                    <Form.Control onChange={this.inputCity}  value={this.state.city}  />
                                                </Form.Group>
                                                <Form.Group controlId="bio">
                                                    <Form.Label style={{color: 'white'}}>Bio</Form.Label>
                                                    <Form.Control onChange={this.inputBio}  value={this.state.bio} as="textarea" rows={3} />
                                                </Form.Group>
                                                <Button disabled={this.state.submittingUpdateProfile} style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.submitUpdateProfile}  >{lang.submit_all}</Button>
                                                {/* Password */}
                                                <Form.Group controlId="password">
                                                    <Form.Label style={{color: 'white'}}>New Password</Form.Label>
                                                    <Form.Control onChange={this.inputNewPassword} type="password" value={this.state.password} />
                                                </Form.Group>
                                                <Form.Group controlId="checkoutVariationPrice">
                                                    <Form.Label style={{color: 'white'}}>Confirm Password</Form.Label>
                                                    <Form.Control onChange={this.inputConfirmPassword} type="password"value={this.state.confirmPassword} />
                                                </Form.Group>
                                                {
                                                    this.state.passwordError ?
                                                        <Form.Group>
                                                            <Form.Label style={{color: 'white'}}>{this.state.passwordError}</Form.Label>
                                                        </Form.Group>
                                                    : ''
                                                }
                                                <Button disabled={this.state.submittingChangePassword} style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.submitChangePassword}  >{lang.submit}</Button>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            </Modal.Footer>
                                        </Modal>
                                    </React.Fragment>
                                    


                                    {/* Open Gallery */}
                                    {
                                    this.props.userPermission != undefined && this.props.userPermission.isShowGallery
                                    ?
                                    <button id="btnOpenGallery" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.openGallery} disabled={this.state.loading} >{lang.open_gallery}</button>
                                    : ''
                                    }

                                    {/* Open View Profile in Gallery */}
                                    <React.Fragment>
                                        <button id="btnViewProfile" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.openViewProfile} disabled={this.state.loading} >{lang.view_profile}</button>
                                    </React.Fragment>

                                    
                                    
                                    {/* Set Lang */}
                                    <React.Fragment>
                                        
                                        <button id="" style={{marginBottom: '0', display: 'none'}} className="add-combination-btn btn btn-primary" onClick={this.setLanguage} >{lang.set_language}</button>
                                        <Modal size="xl" show={this.state.languageShow} onHide={this.handleLanguageClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                <div>
                                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.handleLanguageClose}/>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <button id="btnEngLang" 
                                                style={{marginBottom: '0'}} 
                                                className="add-combination-btn btn btn-primary" 
                                                onClick={this.setLangEng} 
                                                >English</button>
                                                <button id="btnEngLang" 
                                                style={{marginBottom: '0'}} 
                                                className="add-combination-btn btn btn-primary" 
                                                onClick={this.setLangVn} 
                                                >Vietnamese</button>
                                                <button id="btnEngLang" 
                                                style={{marginBottom: '0'}} 
                                                className="add-combination-btn btn btn-primary" 
                                                onClick={this.setLangFr} 
                                                >French</button>
                                                <button id="btnEngLang" 
                                                style={{marginBottom: '0'}} 
                                                className="add-combination-btn btn btn-primary" 
                                                onClick={this.setLangIt} 
                                                >Italian</button>
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>
                                    
                                    
                                    
                            </td>
                        </tr>
                        <tr>
                            <td className="my-account-tab">
                                {
                                    <button id="btnLogOut" style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.logout} disabled={this.state.loading} >Logout</button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </React.Fragment>
        )
        
    }
}

const mapDispatchToProps = {
    getCombinationsSize: getCombinationsSize,
    getActivityInfo: getActivityInfo,
    getImagesSize: getImagesSize,
    getTransactions: getTransactions
}

export default connect(null, mapDispatchToProps)(LogoutForm);