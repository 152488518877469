import {RepeatWrapping, Color} from "three";
// import TextureExporter from '../../../cadcentre/dist/cadcenter';
// const {TextureExporter} = require("../../../cadcentre/dist/cadcenter");
const {TextureExporter} = window.CADEDITOR.icad;

export const switchCases = (props, api) => {
    switch (props.sceneConfig.type) {
        case 'minAzimuthAngle':
            api.setCameraParams({orbitParams: {minAzimuthAngle: props.sceneConfig.config.camera.orbitParams.minAzimuthAngle}})
            break;
        case 'maxAzimuthAngle':
            api.setCameraParams({orbitParams: {maxAzimuthAngle: props.sceneConfig.config.camera.orbitParams.maxAzimuthAngle}})
            break;
        case 'minPolarAngle':
            api.setCameraParams({orbitParams: {minPolarAngle: props.sceneConfig.config.camera.orbitParams.minPolarAngle}})
            break;
        case 'maxPolarAngle':
            api.setCameraParams({orbitParams: {maxPolarAngle: props.sceneConfig.config.camera.orbitParams.maxPolarAngle}})
            break;

        case 'minSpeed':
            console.log("minSpeed: " + props.sceneConfig.config.camera.orbitParams.minSpeed);
            api.setCameraParams({orbitParams: {minSpeed: props.sceneConfig.config.camera.orbitParams.minSpeed}})
            break;
        case 'maxSpeed':
            console.log("maxSpeed: " + props.sceneConfig.config.camera.orbitParams.maxSpeed);
            api.setCameraParams({orbitParams: {maxSpeed: props.sceneConfig.config.camera.orbitParams.maxSpeed}})
            break;

        case 'minZoom':
            api.setCameraParams({orbitParams: {minZoom: props.sceneConfig.config.camera.orbitParams.minZoom}})
            break;
        case 'maxZoom':
            api.setCameraParams({orbitParams: {maxZoom: props.sceneConfig.config.camera.orbitParams.maxZoom}})
            break;
        case 'maxDistance':
            api.setCameraParams({orbitParams: {maxDistance: props.sceneConfig.config.camera.orbitParams.maxDistance}})
            break;
        case 'minDistance':
            api.setCameraParams({orbitParams: {minDistance: props.sceneConfig.config.camera.orbitParams.minDistance}})
            break;
        case 'dampingFactor':
            api.setCameraParams({orbitParams: {dampingFactor: props.sceneConfig.config.camera.orbitParams.dampingFactor}})
            break;
        case 'zoomSpeed':
            api.setCameraParams({orbitParams: {zoomSpeed: props.sceneConfig.config.camera.orbitParams.zoomSpeed}})
            break;
        case 'camera':
            api.setCameraParams({zoom: props.sceneConfig.config.camera.zoom})
            break;
        case 'fov':
            api.setCameraParams({fov: props.sceneConfig.config.camera.fov})
            break;
        case 'cameraMode':
            api.setCameraParams({mode: props.sceneConfig.config.camera.mode})
            break;
        case 'enableScreenSpacePanning':
            api.setCameraParams({orbitParams: {screenSpacePanning: props.sceneConfig.config.camera.orbitParams.screenSpacePanning}})
            break;
        case 'enablePan':
            api.setCameraParams({orbitParams: {enablePan: props.sceneConfig.config.camera.orbitParams.enablePan}})
            break;
        case 'enableZoom':
            api.setCameraParams({orbitParams: {enableZoom: props.sceneConfig.config.camera.orbitParams.enableZoom}})
            break;
        case 'enableFrustumCulling':
            api.frustumCulling = props.sceneConfig.config.frustumCulling
            break;
        case 'sceneView':
            switch (props.sceneConfig.value) {
                case 'x+c':
                    api.resetCameraView("x")
                    break;
                case 'x-c':
                    api.resetCameraView("x", false)
                    break;
                case 'y+c':
                    api.resetCameraView("y")
                    break;
                case 'y-c':
                    api.resetCameraView("y", false)
                    break;
                case 'z+c':
                    api.resetCameraView("z")
                    break;
                case 'z-c':
                    api.resetCameraView("z", false)
                    break;
                default:
                    break;
            }
            break;
        case 'downloadGltf':
            dowloadGltf(api)
            break;
        case 'snapCanvas':
            snapCanvas(api)
            break;
        case 'edgeGlow':
        case 'edgeThickness':
        case 'edgeStrength':
        case 'pulsePeriod':
        case 'visibleEdgeColor':
        case 'hiddenEdgeColor':
        case 'downSampleRatio':
            api.setOutlineParams(props.sceneConfig.config.outline)
            /*props.saveSceneConfigToState(
                {
                    config: props.sceneConfig.config,
                    geometry: props.sceneConfig.geometry,
                    materials: props.sceneConfig.materials,
                },
                api
            )*/
            break;
        case 'cameraPosition':

            let p = api.getCameraParams();
            const position = {x: props.sceneConfig.config.camera.position[0], y: props.sceneConfig.config.camera.position[1], z: props.sceneConfig.config.camera.position[2]};
            
            p.position = [position.x, position.y, position.z];
            api.setCameraParams(p)
            break;
        case 'resetCameraTarget':
            api.resetCameraTarget()
            break;
        case 'resetCamera':
            console.log('resetCamera');
            api.resetCamera()
            break;
        case 'changeBevelSetting':
            api.enableBevel = props.sceneConfig.config.enableBevel
            break;
        case 'sharpness':
            if (props.sceneConfig.sharpnessStatus) {
                api.setSharpenIntensity(props.sceneConfig.config.sharpness)
            }
            break
        case 'enableSharpness':
            if (!props.sceneConfig.sharpnessStatus) {
                api.setSharpenIntensity(0)
            }
            else
            {
                api.setSharpenIntensity(props.sceneConfig.config.sharpness)
            }
            break;
        case 'enableSsao':
            api.setSSAOParams({enabled: props.sceneConfig.config.ssao.enabled})
            break;
        case 'enableSsaoDebug':
            api.setSSAOParams({enableDebug: props.sceneConfig.config.ssao.enableDebug})
            break;
        case 'ssaoIntensity':
            api.setSSAOParams({intensity: props.sceneConfig.config.ssao.intensity})
            break;
        case 'ssaoRadius':
            api.setSSAOParams({radius: props.sceneConfig.config.ssao.radius})
            break;
        case 'ssaoDownscaleLevel':
            api.setSSAOParams({downscaleLevel: props.sceneConfig.config.ssao.downscaleLevel})
            break;
        case 'enableBloom':
            api.setBloomParams({enabled: props.sceneConfig.config.unrealBloom.enabled})
            break;
        case 'bloomStrength':
            api.setBloomParams({strength: props.sceneConfig.config.unrealBloom.strength})
            break;
        case 'bloomRadius':
            api.setBloomParams({radius: props.sceneConfig.config.unrealBloom.radius})
            break;
        case 'bloomThreshold':
            api.setBloomParams({threshold: props.sceneConfig.config.unrealBloom.threshold})
            break;
        case 'changeStraightenModelRotateSetting':
            console.log('switch case changeStraightenModelRotateSetting');
            let axis = props.sceneConfig.straightenRotationAxis.split('')
            api.straighten(axis[0], axis[1])
            break;
        case 'resetStraightenModel':
            api.resetRotation()
            break;
        case 'takeScreenshot':
            takeScreenshot(api)
            break;
        case 'enableGroundShadow':
        case 'numSamplesSetting':
        case 'numSamplesPerFrameSetting':
        case 'groundShadowBrightnessSetting':
        case 'blurRadiusSetting':
        case 'groundShadowSharpnessSetting':
        case 'falloffSetting':
        case 'enableSmooth':
        case 'changeSmoothTransitionSetting':
        case 'changeGroundShadowSideSetting':
            api.setGroundShadowParams(props.sceneConfig.config.groundShadow)
            break;
        case 'enableGroundReflectionSetting':
        case 'changeGroundReflectionRoughnessSetting':
        case 'changeGroundReflectionMetalnessSetting':
        case 'changeGroundReflectionSizeSetting':
            api.setGroundReflectionParams(props.sceneConfig.config.groundReflection)
            break;
        case 'enableGroundReflectionPhysicalSetting':
            setReflection(api, props)
            break;
        case 'viewReflection':
            viewReflection(props)
            break;
        case 'downloadReflection':
            downloadReflection(props)
            break;
        case 'loadReflection':
            loadReflection(api, props)
            break;
        case 'clearAll':
            api.clearAll()
            break;
        case 'setBackgroundColor':
            let value = convertHexToRgb(props.sceneConfig.value)
            console.log(value);
            document.getElementById("previousBackgroundColor").value = props.sceneConfig.value;
            // api.setBackground(new Color(value[0] / 255.0, value[1] / 255.0, value[2] / 255.0))
            api.setBackground(props.sceneConfig.value)
            break;
        case 'removeBackground':
            api.setBackground(null)
            break;
        case 'enableDof':
            api.setDofParams({enabled: props.sceneConfig.config.dof.enabled})
            break;
        case 'enableEditDof':
            api.setDofParams({enableEdit: props.sceneConfig.config.dof.enableEdit})
            break;
        case 'changeCrossColorSetting':
            api.setDofParams({crossColor: props.sceneConfig.config.dof.crossColor})
            break;
        case 'changeCrossRadiusSetting':
            api.setDofParams({crossRadius: props.sceneConfig.config.dof.crossRadius})
            break;
        case 'changeNearBlurScaleSetting':
            api.setDofParams({nearBlurScale: props.sceneConfig.config.dof.nearBlurScale})
            break;
        case 'changeFarBlurScaleSetting':
            api.setDofParams({farBlurScale: props.sceneConfig.config.dof.farBlurScale})
            break;
        case 'changeDepthRangeSetting':
            api.setDofParams({depthRange: props.sceneConfig.config.dof.depthRange})
            break;
        case 'enableAutoRotate':
            api.setCameraParams({orbitParams: {autoRotateSpeed: props.sceneConfig.config.camera.orbitParams.autoRotateSpeed, autoRotate: props.sceneConfig.config.camera.orbitParams.autoRotate}})
            break;
        case 'changeAutoRotateSpeedSetting':
            api.setCameraParams({orbitParams: {autoRotateSpeed: props.sceneConfig.config.camera.orbitParams.autoRotateSpeed}})
            break;
        case 'changeMaterialSettings':
            api.importMaterialParams(props.selectedObjects.material).then(() => {
                api.setMaterialParams(props.selectedObjects.pickedObject, props.selectedObjects.material)
            })
            /*api.setMaterialParams(props.selectedObjects.pickedObject, props.selectedObjects.material)*/
            break;
        case 'selectMaterial':
            props.selectedObjects.objects.forEach(object => {
                api.importMaterialParams(props.selectedObjects.material).then((m) => {
                    //m.visible = object.bVisible;
                    m.visible = props.selectedObjects.pickedObject.material.visible;
                    
                    api.setMaterialParams(object, m)
                })
            })
            break;
        case 'changeArSettings':
            api.setXRParams(props.sceneConfig.arSettings)
            break;
        case 'toneMappingExposure':
            api.exposure = props.sceneConfig.config.toneMappingExposure
            break;
        case 'toneMappingSaturation':
            api.toneMappingSaturation = props.sceneConfig.config.toneMappingSaturation
            break;
        case 'toneMappingContrast':
            api.toneMappingContrast = props.sceneConfig.config.toneMappingContrast
            break;
        case 'envMapRotation':
            api.setEnvMapRotation(props.sceneConfig.config.envMapRotation)
            break;
        case 'changePixelRatioSetting':
            api.setDisplayPixelRatio(props.sceneConfig.pixelRatio)
            break
        case 'enableAnnotations':
            api.annotationsEnabled = props.sceneConfig.enableAnnotations;
            break
        case 'enableTAA':
            api.enableTAA = props.sceneConfig.config.enableTAA;
            break
        case 'enableSSR':
            api.setSSRParams({enabled: props.sceneConfig.config.enableSSR});
            break
        case 'intensitySSR':
            api.setSSRParams({intensity: props.sceneConfig.config.intensitySSR});
            break
        default:
            break;
    }
    props.sceneConfig.type = '';
}

/**
 * Download as gltf
 */
const dowloadGltf = api => {
    api.sceneManager.exportGeometry().then(value => {
        let link = document.createElement("a");
        let blob = new Blob([JSON.stringify(value)], {type: 'application/json'});
        link.href = URL.createObjectURL(blob);
        link.download = "object.gltf";
        const body = document.getElementsByTagName("body")[0];
        body.appendChild(link);
        link.click();
        body.removeChild(link);
    });
}

/**
 * Snap Canvas
 */
const snapCanvas = api => {
    let canvas = document.createElement('canvas');
    canvas.id = "Temp";
    canvas.width = window.innerWidth / 3;
    canvas.height = window.innerHeight / 3;
    canvas.style.zIndex = "";
    canvas.style.position = "absolute";
    canvas.style.border = "1px solid";
    canvas.style.borderColor = "#ff0000";
    canvas.style.top = "0px";
    canvas.style.left = "0px";
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(canvas);
    api.getSnapshot({canvas: canvas}).then(value => {
        setTimeout(() => {
            body.removeChild(canvas)
        }, 5000)
    });
}

/**
 * Take screenshot of canvas
 * @param api
 */
const takeScreenshot = api => {
    console.log("takeScreenshot");
    let img = api.getSnapshotData("image/jpg")
    let link = document.createElement("a");
    link.download = "screenshot.jpg";
    link.href = img;
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(link);
    link.click();
    body.removeChild(link);
}

/**
 * View reflection used in new window
 * @param props
 */
const viewReflection = props => {
    TextureExporter.openTexture(props.sceneConfig.config.groundReflection.diffuseMap)
}

/**
 * Download reflecion used
 * @param props
 */
const downloadReflection = props => {
    TextureExporter.downloadTexture(props.sceneConfig.config.groundReflection.diffuseMap, 'diffuseMap')
}

/**
 * Sets default reflection
 * @param api
 * @param props
 */
export const setReflection = (api, props) => {
    api.loadTexture("./models/reflection/diffuseMap.jpeg").then((texture) => {
        props.sceneConfig.config.groundReflection.diffuseMap = texture;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.repeat.set(10, 10);
        api.setGroundReflectionParams(props.sceneConfig.config.groundReflection)
    })
}

/**
 * Upload new reflection
 * @param api
 * @param props
 */
const loadReflection = (api, props) => {
    const inputFile = document.getElementById("fileInput");
    inputFile.onchange = e => {
        const files = Array.from(e.target.files)
        let file = Array.isArray(files) ? files[0] : files;
        if (file) {
            api.importTexture(file, texture => {
                props.sceneConfig.config.groundReflection.diffuseMap = texture;
                texture.wrapS = RepeatWrapping;
                texture.wrapT = RepeatWrapping;
                texture.repeat.set(10, 10);
                api.setGroundReflectionParams(props.sceneConfig.config.groundReflection)
            })
        }
    }
    inputFile.click();
}

/**
 * Converts Hex color code to Rgb
 * @param hex
 * @returns {boolean|[number, number, number]}
 */
const convertHexToRgb = hex => {
    if (hex.charAt(0) === '#') {
        hex = hex.substr(1);
    }
    if ((hex.length < 2) || (hex.length > 6)) {
        return false;
    }
    let values = hex.split(''),
        r,
        g,
        b;

    if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = r;
        b = r;
    } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16);
        g = parseInt(values[1].toString() + values[1].toString(), 16);
        b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = parseInt(values[2].toString() + values[3].toString(), 16);
        b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
        return false;
    }
    return [r, g, b];
}

