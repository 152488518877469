import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Form, Button, Tabs, Tab, Accordion, Table, Col, Row} from "react-bootstrap";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import {SpinnerSmall} from "../../../helpers/spinners";
import closeIcon from "../../../assets/close-icon@2x.png";
import helpIcon from '../../../assets/help_icon.png';
import MenuItemTextArea from "../../Configurator/Sidebar/MenuItems/MenuItemTextArea";

import {updateCombination, loadCombinations} from '../../../actions/actionsCreators';

class PplDescriptionModal extends Component {
    state = {
        isShow: false
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    setShow = () => {
        this.setState({
            isShow : true
        })
    }
    setHide = () => {
        this.setState({
            isShow : false
        })
    }

    render() {
        return(
            <React.Fragment>
                <>
                    <label style={{fontSize: 16, cursor: 'pointer'}} onClick={this.setShow}>PPL 
                    </label>
                    <img className="helpIconCombinationModalPpl"  id="" src={helpIcon} onClick={this.setShow} />
                    <Modal className="modalSize50" size="xl" show={this.state.isShow} onHide={this.setHide} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton className="rename-material-modal-title">
                            <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                            <div>
                                <img className="close-icon" src={closeIcon} alt="" onClick={this.setHide}/>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="ppl-description-modal">
                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                    <h3>Pay Per Link</h3>
                                </Col>
                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                    <p>Show your product in an unbranded viewer for a limited period of time.</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Pay for 1 month: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.priceOneMonth} USD</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Pay for 3 months: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.priceThreeMonth} USD</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Pay for 6 months: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.priceSixMonth} USD</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Pay for 1 year: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.priceOneYear} USD</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Limited File Size: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.limitedFileSize} MB</p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'right'}}>
                                    <p style={{color: 'white', textAlign: 'right'}}>Clicks Qty: </p>
                                </Col>
                                <Col md={{ span: 6}} style={{color: 'white', textAlign: 'left'}}>
                                    <p style={{color: 'white', textAlign: 'left'}}>{this.props.ppl.clickViewPerMonth.toLocaleString()} </p>
                                </Col>
                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                    <p>You can purchase extra clicks if needed.</p>
                                </Col>
                            </Row>
                    </Modal.Body>
                    </Modal>
                </>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(PplDescriptionModal);