import React from "react";

function Tracker(props) {
    return(
        props.trackers.map(item => {
            return (
                <tr key={item.userId}>
                    <td>{item.name}</td>
                    <td>{item.url}</td>
                    {item.data.map((item, i) => <td key={i}>{item.value}</td>)}
                </tr>
            )
        })
    )
}

export default Tracker;