import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    addNewCombination,
} from "../../../actions/actionsCreators";
import closeIcon from "../../../assets/close-icon@2x.png";
import Combinations from "./Combinations";

class AddCombinationModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
        this.props.closeParentPopup()
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    addNewCombination = () => {
        this.handleShow()
        this.props.addNewCombination()
        this.closeParentPopup()
    }

    closeParentPopup = () => {
        var element = document.getElementById("combination-scene-info-alert");
        if(element){
            element.parentElement.classList.remove("show");
        }
    }

    render() {
        let combinations = ''
        let loading = this.props.combinations.loading
        let hasError = this.props.combinations.hasError

        if (!loading && !hasError) {
            combinations = <Combinations />
        }

        if (loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <p>Be patient while we prepare your scene</p>
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }

        if (hasError) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <span className="rename-material-modal-error">{this.props.combinations.error}</span>
                    </Col>
                </Row>
            )
        }

        return (
            <React.Fragment>
            <button style={{backgroundColor: '#007bff'}}  className="add-combination-btn btn btn-primary" onClick={this.addNewCombination} disabled={this.props.combinations.loading}>Publish Scene</button>
            <button style={{backgroundColor: 'grey'}}  className="add-combination-btn btn btn-primary" onClick={this.handleClose} disabled={this.props.combinations.loading}>Cancel</button>
                <Modal size="xl" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {combinations}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    addNewCombination: addNewCombination
}

const mapStateToProps = state => {
    return {
        combinations: state.combinations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCombinationModal)