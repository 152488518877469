import {
    LOAD_REPORT_REQUEST,
    LOAD_REPORT_SUCCESS,
    REQUEST_TRACKERS_FILTERS,
    REQUEST_TRACKERS_FILTERS_ERROR,
    REQUEST_TRACKERS_FILTERS_FAILED,
    REQUEST_TRACKERS_FILTERS_SUCCESS,
    LOAD_REPORT_FAILED,
    LOAD_REPORT_ERROR
} from "../actions/actionTypes";

const initialState = {
    trackers: [],
    listColumn: [],
    availableFilters: {},
    filters: {},
    loading: false,
    hasError: false,
    error: '',
    loadingReport: false,
    hasErrorReport: false,
    errorReport: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_REPORT_SUCCESS:
            return {
                ...state,
                trackers: action.trackers,
                listColumn: action.listColumn,
                loadingReport: action.loading
            }
        case REQUEST_TRACKERS_FILTERS:
            return {
                ...state,
                loading: action.loading
            }
            case LOAD_REPORT_REQUEST:
                return {
                    ...state,
                    loadingReport: action.loading,
                    hasErrorReport: false,
                    errorReport: ''
                }
        case REQUEST_TRACKERS_FILTERS_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                availableFilters: action.availableFilters
            }
        case REQUEST_TRACKERS_FILTERS_FAILED:
        case REQUEST_TRACKERS_FILTERS_ERROR:
            return {
                ...state,
                loading: action.loading,
                hasError: action.hasError,
                error: action.error
            }
        case LOAD_REPORT_FAILED:
        case LOAD_REPORT_ERROR:
            return {
                ...state,
                loadingReport: action.loading,
                hasErrorReport: action.hasError,
                errorReport: action.error
            }
        default:
            return state;
    }
}

export default reducer;