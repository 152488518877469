import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Form, Button, Tabs, Tab, Accordion, Table, Col, Row} from "react-bootstrap";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import {SpinnerSmall} from "../../../helpers/spinners";
import closeIcon from "../../../assets/close-icon@2x.png";
import helpIcon from '../../../assets/help_icon.png';
import {updateCombination, loadCombinations} from '../../../actions/actionsCreators';
import CombinationModal from "./CombinationModal";

class Combination extends Component {
    state = {
        price: this.props.combination.price ? this.props.combination.price : 0,
        description: this.props.combination.description ? this.props.combination.description : '',
        tag: this.props.combination.tag ? this.props.combination.tag : '',
        isPublic: this.props.combination.isPublic ? this.props.combination.isPublic : '',
        allowComment: this.props.combination.allowComment ? this.props.combination.allowComment : '',
        fileTypes: this.props.combination.fileTypes ? this.props.combination.fileTypes : '',
        
        name: this.props.combination.name ? this.props.combination.name : '',
        loading: false,
        error: '',
        hasError: false,
        checkedWidget: this.props.checkedWidget,
        checkedEmbedded: this.props.checkedEmbedded,
        checkedConfigurator: this.props.checkedConfigurator,
        userPermission: this.props.userPermission
    }

    /**
     * Handles price and description change
     * @param e
     */
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    handleConfiguratorCheckboxChange = () => {
        this.setState({
            checkedConfigurator: !this.state.checkedConfigurator
        })
        this.props.handleConfiguratorCheckboxChange(this.props.combination.configurator, this.props.combination.uuid)
    }
    /**
     * Change selected status of widget
     */
    handleWidgetCheckboxChange = () => {
        this.setState({
            checkedWidget: !this.state.checkedWidget
        })
        this.props.handleWidgetCheckboxChange(this.props.combination.widget, this.props.combination.uuid)
    }

    /**
     * Change selected status of embedded
     */
    handleEmbeddedCheckboxChange = () => {
        this.setState({
            checkedEmbedded: !this.state.checkedEmbedded
        })
        this.props.handleEmbeddedCheckboxChange(this.props.combination.embedded, this.props.combination.uuid)
    }

    /**
     * Update combination with new price and description
     */
    updateCombination = () => {
        this.setState({
            loading: true,
            hasError: false,
            error: ''
        })

        let staticImgMode = document.querySelector('input[name="staticImgMode-'+this.props.combination.uuid+'"]:checked').value;

        let payload = {
            id: this.props.combination.uuid,
            name: this.state.name,
            price: this.state.price,
            description: this.state.description,
            tag: this.state.tag,
            fileTypes: this.state.fileTypes,
            staticImgMode: staticImgMode
        }

        axios.post('combination/update', payload).then(result => {
            if (result.data.success) {

                var files = document.getElementById('staticImg-'+this.props.combination.uuid).files;
                if(files.length > 4)
                {
                    this.setState({
                        loading: false,
                        hasError: true,
                        error: 'Please choose only 4 images with prefix: copc, como, ifpc, ifmo'
                    })
                }

                let staticImgNameCheck= true;
                for(let i = 0 ; i < files.length ; i ++)
                {
                    console.log(files[i].name);
                    if(
                        !files[i].name.includes("copc") &&
                        !files[i].name.includes("como") &&
                        !files[i].name.includes("ifpc") &&
                        !files[i].name.includes("ifmo")
                      )
                      {
                        staticImgNameCheck = false;
                        break;
                      }
                }
                if(!staticImgNameCheck)
                {
                    alert('Please choose only 4 images with prefix: copc, como, ifpc, ifmo');
                }
                else
                {
                    for(let i = 0 ; i < files.length ; i ++)
                    {
                        let data = new FormData()
                        data.append("id", this.props.combination.uuid)
                        data.append("files", files[i])
                        axios.post('combination/update-static-img', data,
                            {
                                headers: {'Content-Type': 'multipart/form-data' }
                            }
                        ).then(response => {
                        });
                    }
                }


                // 1 second for showing the loading
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        hasError: false,
                        error: ''
                    })
                    //update state in reducer
                    this.props.updateCombination(payload, this.props.position)
                    setTimeout(() => {
                        this.props.loadCombinations(true)
                    }, 1000);
                }, 1000);
            }
        }).catch((err, result) => {
            this.setState({
                loading: false,
                hasError: true,
                error: err.response.data.error.message
            })
        })
    }

    publicChange = (e) => {
        let payload = {
            id: this.props.combination.uuid,
            status: !this.state.isPublic
        };
        this.setState({
            isPublic: !this.state.isPublic
        })

        console.log(payload);
        axios.post('combination/update-public', payload).then(result => {
        });
    }

    staticImgModeChange = (e) => {
        let payload = {
            id: this.props.combination.uuid,
            mode: e.target.value
        };

        console.log(payload);
        axios.post('combination/update-static-img-mode', payload).then(result => {
        });
    }

    

    allowCommentChange = (e) => {
        let payload = {
            id: this.props.combination.uuid,
            status: !this.state.allowComment
        };
        this.setState({
            allowComment: !this.state.allowComment
        })

        console.log(payload);
        axios.post('combination/update-allow-comment', payload).then(result => {
        });
    }

    render() {
        let errorRow = (
            <tr className="error-row">
                <td colSpan={this.props.layers.length + 8}>
                    Failed {this.state.error}
                </td>
            </tr>
        )
        return(
            <React.Fragment>
                <tr>
                    <td colSpan="2" className="combination-report-info" style={{verticalAlign: 'top'}}>
                        <img style={{width: 100+'%'}} src={this.props.combination.staticImg} />
                        <p style={{marginTop: 10}} >Total Views: {this.props.combination.totalView ?? 0}</p>
                        <p>Size: {
                            this.props.combination.size ? (this.props.combination.size / 1000000).toFixed(2) + " MB" : ""
                        }
                        </p>
                        <p>Created: {
                            new Date(this.props.combination.createDate).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'})
                        }
                        </p>
                        <p>Uuid: {
                            this.props.combination.uuid
                        }</p>
                    </td>
                    <td colSpan={2} className="combination-dashboard-info" style={{verticalAlign: 'top'}}>
                        <CombinationsSizeName fileTypes={this.state.fileTypes} description={this.state.description} tag={this.state.tag} price={this.state.price} name={this.state.name} uuid={this.props.combination.uuid} />
                        {/* <input style={{display: 'block'}} placeholder="Name" className="name-input" name="name" type="text" value={this.state.name} onChange={this.handleChange} />
                        <input style={{display: 'block'}} placeholder="Description (gallery + 3d configurator)" className="description-input" name="description" type="text" value={this.state.description} onChange={this.handleChange} />
                        <input style={{display: 'block'}} placeholder="Price (gallery)" className="price-input" name="price" type="number" value={this.state.price} onChange={this.handleChange} />
                        <input style={{display: 'block'}} placeholder="Eg: diamonds,metal (gallery)" className="tag-input price-input" name="tag" type="text" value={this.state.tag} onChange={this.handleChange} /> */}
                        {/* <input style={{display: 'block'}} placeholder="File types (gallery)" className="tag-input price-input" name="fileTypes" type="text" value={this.state.fileTypes} onChange={this.handleChange} /> */}
                    </td>
                    <td colSpan={2} style={{verticalAlign: 'top'}} className="combination-file-download">
                        <CombinationsSizeFileDownload downloadFiles={this.props.combination.downloadFiles} downloadFile={this.props.combination.downloadFile} combination={this.props.combination}/>
                    </td>
                    <td>
                        <input className="form-control chk-publish" type="checkbox" defaultChecked={this.state.isPublic}
                            onChange={()=>this.publicChange()} />
                    </td>
                    <td>
                        <input className="form-control chk-allow-comment" type="checkbox" defaultChecked={this.state.allowComment == true || this.state.allowComment == "true" ? true : false}
                            onChange={()=>this.allowCommentChange()} />
                    </td>
                    {/* <td>
                        <input id={"staticImg-"+this.props.combination.uuid} className="staticImg-input" name="staticImg" type="file" multiple />
                    </td> */}
                    <td style={{textAlign: 'left', paddingLeft: 32}}>
                        <input onChange={this.staticImgModeChange} value={'default'} defaultChecked={this.props.combination.staticImgMode == 'default'} name={"staticImgMode-"+this.props.combination.uuid} type="radio" />
                        <label style={{marginRight: 10}}>&nbsp; Default</label>
                        <br/>
                        {/* <input value={'manual'} defaultChecked={this.props.combination.staticImgMode == 'manual'} name={"staticImgMode-"+this.props.combination.uuid} type="radio" />
                        <label style={{marginRight: 10}}>Manual</label> */}
                        <input onChange={this.staticImgModeChange} value={'none'} defaultChecked={this.props.combination.staticImgMode == 'none'} name={"staticImgMode-"+this.props.combination.uuid} type="radio" />
                        <label>&nbsp; None</label>
                    </td>
                    {/* <td>
                        {
                            this.state.loading ?
                                <SpinnerSmall /> :
                                <button  className="btn btn-danger btn btn-primary" onClick={this.updateCombination} disabled={this.state.loading} >Update</button>
                        }
                    </td> */}
                    <td colSpan="2">
                        
                        {/* <input className="checkbox-square" type="checkbox" checked={this.props.checkedConfigurator} onClick={this.handleConfiguratorCheckboxChange}/> */}
                        {
                            this.state.userPermission != undefined && this.state.userPermission.isAllowIFrame? 
                            // <React.Fragment>
                            //     <>
                            //         <button id="" style={{marginBottom: '0'}} className="btn btn-primary"  >Embedded</button>
                            //         <Modal className="modalSize50" size="xl"  aria-labelledby="contained-modal-title-vcenter" centered>
                            //             <Modal.Header closeButton className="rename-material-modal-title">
                            //                 <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                            //                 <div>
                            //                     <img className="close-icon" src={closeIcon} alt="" onClick={this.paypalClose}/>
                            //                 </div>
                            //             </Modal.Header>
                            //             <Modal.Body>
                            //                 <Row>
                            //                     <Col md={{ span: 12}} style={{color: 'white'}}>
                            //                         <Link
                            //                             to={{
                            //                                 pathname: `/iframe/` + this.props.combination.uuid
                            //                             }}
                            //                             className="btn btn-primary"
                            //                             style={{margin: 1+'%'}}
                            //                             target="_blank"
                            //                         >Embedded</Link>
                            //                     </Col>
                            //                 </Row>
                            //                 <Row>
                            //                     <Col md={{ span: 12}} style={{color: 'white'}}>
                            //                         <textarea></textarea>
                            //                     </Col>
                            //                 </Row>
                            //         </Modal.Body>
                            //         </Modal>
                            //     </>
                            // </React.Fragment>
                            <CombinationModal isAnnotation={false} type={'iframe'} text={'3D Viewer'} uuid={this.props.combination.uuid}></CombinationModal>
                            : ''
                        }
                        {
                            this.state.userPermission != undefined && this.state.userPermission.isAllowConfigurator? 
                            // <Link
                            //     to={{
                            //         pathname: `/configurators/` + this.props.combination.uuid
                            //     }}
                            //     className="btn btn-primary"
                            //     style={{margin: 1+'%'}}
                            //     target="_blank"
                            // >3D Configurator</Link>
                            <CombinationModal userPermission={this.state.userPermission} isAnnotation={false} type={'configurators'} text={'3D Configurator'} uuid={this.props.combination.uuid}></CombinationModal>
                            : ''
                        }
                        {
                            this.state.userPermission != undefined && this.state.userPermission.isAllowAnnotation? 
                            <CombinationModal referenceUuid={this.props.combination.referenceUuid} annotationCommentsEmails={this.props.combination.annotationCommentsEmails} isAnnotation={true} type={'annotation'} text={'La Fiche'} uuid={this.props.combination.uuid}></CombinationModal>
                            : ''
                        }
                        <Row>
                            <Col md={{ span: 10}}>
                                <Link
                                    to={{
                                        pathname: galleryUrl+"/product/"+ this.props.combination.uuid
                                    }}
                                    className="btn btn-primary"
                                    style={{margin: 1+'%'}}
                                    target="_blank"
                                >View Gallery</Link>
                            </Col>
                            <Col md={{ span: 2}}>
                                <img className="helpIconCombinationModal"  id="" src={helpIcon} onClick={()=> {window.open("https://www.cadgl.com/help/marketplace", "_blank");}} />
                            </Col>
                        </Row>
                    </td>
                    <td style={{position: 'relative'}}>
                        {/* <img className="helpIconVideo"  id="" src={helpIcon} onClick={this.handleShow} /> */}
                        <img className="helpIconVideo"  id="" src={helpIcon} onClick={()=>{window.open("https://www.cadgl.com/combination-setup", "_blank");}} />
                        <CloneCombinationModal uuid={this.props.combination.uuid} position={this.props.position}/>&nbsp; &nbsp; 
                        <DeleteCombinationsModal uuid={this.props.combination.uuid}/>
                    </td>
                    
                    {/* {
                        this.props.combination.presets.split(',').map((preset, i) => {
                            return <td key={i}>{preset}</td>
                        }) 
                    } */}
                </tr>

                { this.state.error ? errorRow : '' }
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    updateCombination: updateCombination,
    loadCombinations: loadCombinations
}

export default connect(null, mapDispatchToProps)(Combination);