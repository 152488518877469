import React, {Component} from "react";
import {Col, Row, Button} from "react-bootstrap";
import ColorLabel from "./ColorLabel";
import rotateIcon from '../../assets/rotationIconWhite@2x.png';
import rotateIconBlack from '../../assets/rotateIconBlack@2x.png';
import cameraIcon from '../../assets/cameraIconWhite@2x.png';
import cameraIconBlack from '../../assets/cameraIconBlack@2x.png';
import resetIcon from '../../assets/reset@2x.png';
import resetIconBlack from '../../assets/resetIconBlack@2x.png';
import enableIcon from '../../assets/on-toggle.png'
import disableIcon from '../../assets/off-toggle.png'

import { connect } from 'react-redux';
import {takeScreenshotIframe, enableArIframe, resetCameraIframe, enableRotationIframe} from "../../actions/actionsCreators";

class Controls extends Component{
    state = {
        description: '',
        price: '',
        materialColors: []
    }

    componentDidMount() {
        let materialColors = []
        if (this.props.sceneConfig.materials) {
            for (const [key, value] of Object.entries(this.props.sceneConfig.materials)) {
                materialColors.push(value.material.color)
            }
        }

        this.setState({
            materialColors: materialColors
        })
    }

    render() {
        let scene = this.props.sceneConfig
        let controls = ''
        if (scene.config) {
            controls = (
                <Row className="main-content">
                    <Col md={2}>
                        <Row>
                            <Col md={12} className="comb-header">
                                <span>Description</span>
                            </Col>
                        </Row>
                        <div className="comb-border-bottom"></div>
                        <Row>
                            <Col md={12}>
                                <p className="combination-description">
                                    {scene.description ? scene.description : ''}
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={3}>
                        <Row>
                            <Col md={12} className="comb-header">
                                <span>Material Type</span>
                            </Col>
                        </Row>
                        <div className="comb-border-bottom"></div>
                        <Row>
                            {
                                this.state.materialColors.map((color, i) => {
                                    return <ColorLabel key={i} colorCode={color}/>
                                })
                            }
                        </Row>
                    </Col>

                    <Col md={3}>
                        <Row>
                            <Col md={12} className="comb-header">
                                <span>Stone Type</span>
                            </Col>
                        </Row>
                        <div className="comb-border-bottom"></div>
                        <Row>
                            <ColorLabel />
                            <ColorLabel />
                            <ColorLabel />
                        </Row>
                    </Col>

                    <Col md={2} className="ctrl-btn-col">
                        <Row>
                            <Col md={12}>
                                <img className="comb-icons camera-if" src={this.props.theme === 'dark' ? cameraIcon : cameraIconBlack} onClick={this.props.takeScreenshotIframe} alt="" />
                                <img className="comb-icons rotate-if" src={this.props.theme === 'dark' ? rotateIcon : rotateIconBlack} onClick={this.props.enableArIframe} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <img className="comb-icons reset-camera-if" src={this.props.theme === 'dark' ? resetIcon : resetIconBlack} onClick={this.props.resetCameraIframe} alt="" /> Reset
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {
                                    this.props.sceneConfig.config.camera.orbitParams.autoRotate ?
                                        <span>
                                            <img className="rotation-if" src={disableIcon} onClick={() => this.props.enableRotationIframe(false)} alt="" /> Disable Rotation
                                        </span>
                                        :
                                        <span>
                                            <img className="rotation-if" src={enableIcon} onClick={() => this.props.enableRotationIframe(true)} alt="" /> Enable Rotation
                                        </span>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} onClick={this.props.changeTheme} style={{cursor: 'pointer'}}>
                                {
                                    this.props.theme === 'dark' ? 'Theme: Light': 'Theme: Dark'
                                }
                            </Col>
                        </Row>
                    </Col>

                    <Col md={2} className="ctrl-btn-col-cart">
                        <Row>
                            <Col md={12}>
                                <Button className="comb-btn price">$ {scene.price ? scene.price : ''}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Button className="comb-btn add-to-cart">ADD TO CART</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        return (controls)
    }
}

const mapStateToProps = (state) => {
    return {
        sceneConfig: state.iframe.combination,
    }
}

const mapDispatchToProps = {
    takeScreenshotIframe: takeScreenshotIframe,
    enableArIframe: enableArIframe,
    resetCameraIframe: resetCameraIframe,
    enableRotationIframe: enableRotationIframe
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)