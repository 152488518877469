import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    changeCameraMode,
    changeSsaoDownscaleLevel,
    changeGroundShadowSide,
    changeSide,
    mapWrapU,
    mapWrapV,
    changeNormalMapType,
    colorMapEncoding,
    gizmoChangeMode
} from "../../../../actions/actionsCreators";

class MenuItemDropdown extends Component{
    state = {
        value: this.props.value ? this.props.value : '',
    }

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value) => {
        switch (type) {
            case 'cameraMode':
                this.props.changeCameraMode(value)
                break;
            case 'ssaoDownscaleLevel':
                this.props.changeSsaoDownscaleLevel(value)
                break;
            case 'groundShadowSide':
                this.props.changeGroundShadowSide(value)
                break;
            case 'sideChange':
                this.props.changeSide(value)
                break;
            case 'normalMapWrapU':
                this.props.mapWrapU('normalMap', value)
                break;
            case 'normalMapWrapV':
                this.props.mapWrapV('normalMap', value)
                break;
            case 'colorMapWrapU':
                this.props.mapWrapU('map', value)
                break;
            case 'colorMapWrapV':
                this.props.mapWrapV('map', value)
                break;

            case 'lightMapWrapU':
                this.props.mapWrapU('lightMap', value)
                break;
            case 'lightMapWrapV':
                this.props.mapWrapV('lightMap', value)
                break;
            case 'emissiveMapWrapU':
                this.props.mapWrapU('emissiveMap', value)
                break;
            case 'emissiveMapWrapV':
                this.props.mapWrapV('emissiveMap', value)
                break;
            case 'bumpMapWrapU':
                this.props.mapWrapU('bumpMap', value)
                break;
            case 'bumpMapWrapV':
                this.props.mapWrapV('bumpMap', value)
                break;
            case 'roughnessMapWrapU':
                this.props.mapWrapU('roughnessMap', value)
                break;
            case 'roughnessMapWrapV':
                this.props.mapWrapV('roughnessMap', value)
                break;
            case 'metalnessMapWrapU':
                this.props.mapWrapU('metalnessMap', value)
                break;
            case 'metalnessMapWrapV':
                this.props.mapWrapV('metalnessMap', value)
                break;
            case 'alphaMapWrapU':
                this.props.mapWrapU('alphaMap', value)
                break;
            case 'alphaMapWrapV':
                this.props.mapWrapV('alphaMap', value)
                break;
            case 'changeNormalMapType':
                this.props.changeNormalMapType(value)
                break;
            case 'colorMapEncoding':
                this.props.colorMapEncoding('map', value)
                break;
            case 'gizmoChangeMode':
                this.props.gizmoChangeMode(value)
                break;
                
                

            default:
                return null;
        }
    }

    handleChange = (event) =>  {
        this.setState({value: event.target.value});
        this.dispatchAction(this.props.type, event.target.value)
    }

    render() {
        return (
            <Row /*{...this.props}*/>
                <Col md={7} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={5} className="setting-col-input">
                    <Form.Control as="select" size="sm" className="menu-item-text-box" onChange={this.handleChange}>
                        {
                            this.props.options.map((option, i) => {
                                return <option data-val={this.props.value} selected={this.props.value === option.value} value={option.value} key={i}>{option.label}</option>
                            })
                        }
                    </Form.Control>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    changeCameraMode: changeCameraMode,
    changeSsaoDownscaleLevel: changeSsaoDownscaleLevel,
    changeGroundShadowSide: changeGroundShadowSide,
    changeSide: changeSide,
    mapWrapU: mapWrapU,
    mapWrapV: mapWrapV,
    changeNormalMapType: changeNormalMapType,
    colorMapEncoding: colorMapEncoding,
    gizmoChangeMode: gizmoChangeMode
}

export default connect(null, mapDispatchToProps)(MenuItemDropdown)