import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    selectMaterial,
    selectMeshes,
} from "../../../../actions/actionsCreators";

class MenuItemFullSizeDropdown2 extends Component{

    state = {
        name: this.props.name ? this.props.name : '',
    }

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value) => {
        switch (type) {
            case 'selectMeshes':
                this.props.selectMeshes(value)
                break;
            default:
                return null;
        }
    }

    handleChange = (event) =>  {
        this.setState({value: event.target.value});
        this.dispatchAction(this.props.type, event.target.value)
    }

    render() {
        return (
            <Row>
                <Col md={12} className="setting-col-input">
                    <Form.Control as="select" size="sm" className="menu-item-text-box" onChange={this.handleChange}>
                        {
                            this.props.options.map((option, i) => {
                                return <option  value={option.material.id} key={i}>{i + 1 + "- " + option.material.name}</option>
                            })
                        }
                    </Form.Control>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    selectMaterial: selectMaterial,
    selectMeshes: selectMeshes,
}

export default connect(null, mapDispatchToProps)(MenuItemFullSizeDropdown2)