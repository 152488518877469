import React from "react";
import {Col, Row} from "react-bootstrap";

//EXTERNAL ON CLICK BUTTON
function MenuItemButton3(props) {
    return (
        <Row {...props}>{
            <Col md={12} className="setting-col" >
                    <span title={props.title} className="setting-name">{props.name}</span>
            </Col>
            }
        </Row>
    )
}

export default MenuItemButton3