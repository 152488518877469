import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import { ChromePicker  } from 'react-color';
import {
    changeVisibleEdgeColor,
    changeHiddenEdgeColor,
    setBackgroundColor,
    changeCrossColor,
    changeMaterialColor,
    changeStandardMaterialColor,
    changeStandardMaterialEmissiveColor,
    refractionMaterialTintColor,
    refractionMaterialDiffuse,
    changeReticleColorSetting
} from "../../../../actions/actionsCreators";

class MenuItemColorPicker extends Component{
    state = {
        showColorPicker: false,
        colorValue: this.props.value ? this.props.value : ''
    }

    /**
     * Filter all color types and fire its specific action
     */
    dispatchAction = (type, value) => {
        switch (type) {
            case 'visibleEdgeColor':
                this.props.changeVisibleEdgeColor(value)
                break;
            case 'hiddenEdgeColor':
                this.props.changeHiddenEdgeColor(value)
                break;
            case 'setBackgroundColor':
                this.props.setBackgroundColor(value)
                break;
            case 'crossColor':
                this.props.changeCrossColor(value)
                break;
            case 'materialColor':
                this.props.changeMaterialColor(value)
                break;
            case 'standardMaterialColor':
                this.props.changeStandardMaterialColor(value)
                break;
            case 'standardMaterialEmissive':
                this.props.changeStandardMaterialEmissiveColor(value)
                break;
            case 'refractionMaterialTintColor':
                this.props.refractionMaterialTintColor(value)
                break;
            case 'refractionMaterialDiffuse':
                this.props.refractionMaterialDiffuse(value)
                break;
            case 'changeReticleColorSetting':
                this.props.changeReticleColorSetting(value)
                break;
            default:
                return null;
        }
    }

    showColorPicker = () => {
        this.setState({
            showColorPicker: true
        })
    }

    hideColorPicker = () => {
        this.setState({
            showColorPicker: false
        })
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        this.dispatchAction(this.props.type, event.target.value)
    }

    handleColorChangeComplete = (color) => {
        this.setState({ colorValue: color.hex });
        this.dispatchAction(this.props.type, color.hex)
    }

    render() {
        return (
            <Row /*{...this.props}*/ onMouseLeave={this.hideColorPicker}>
                <Col md={6} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={6} className="setting-col-input">
                    <Form.Control
                        size="sm"
                        type="text"
                        id="colorValue"
                        name="colorValue"
                        className="menu-item-text-box"
                        value={this.state.colorValue}
                        onMouseOver={this.showColorPicker}
                        onChange={this.handleChange}
                    />
                </Col>
                {
                    // this.state.showColorPicker ?
                        <ChromePicker
                            disableAlpha={true}
                            color={this.state.colorValue}
                            onChange={this.handleColorChangeComplete} />
                            // : ''
                }
            </Row>
        )
    }
}

const mapDispatchToProps = {
    changeVisibleEdgeColor: changeVisibleEdgeColor,
    changeHiddenEdgeColor: changeHiddenEdgeColor,
    setBackgroundColor: setBackgroundColor,
    changeCrossColor: changeCrossColor,
    changeMaterialColor: changeMaterialColor,
    changeStandardMaterialColor: changeStandardMaterialColor,
    changeStandardMaterialEmissiveColor: changeStandardMaterialEmissiveColor,
    refractionMaterialTintColor: refractionMaterialTintColor,
    refractionMaterialDiffuse: refractionMaterialDiffuse,
    changeReticleColorSetting: changeReticleColorSetting
}

export default connect(null, mapDispatchToProps)(MenuItemColorPicker)