import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    applyPresetFolder,
} from "../../../../actions/actionsCreators";
import trashIcon from "../../../../assets/trash@2x.png";
import applyIcon from "../../../../assets/gear@2x.png";
import brushIcon from "../../../../assets/assign-to-layer.png";
import closeIcon from "../../../../assets/close-icon@2x.png";
import {
    applyPreset,
} from "../../../../actions/actionsCreators";

class ApplyPresetFolderModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    /**
     * Send request to delete preset folder also remove it from state
     */
    applyPresetFolder = () => {
        this.setState({loading: true})
        // axios.post('preset/apply-preset-folder-layer', {
        //     "presetFolderUuid": this.props.uuid,
        //     "layerUuid": this.props.layerUuid}).then(response => {
        //     let {data} = response
        //     this.setState({loading: false})
        //     if (!data.success) {
        //         this.setState({
        //             hasError: true,
        //             error: data.error.message
        //         })
        //     }

        //     this.props.applyPresetFolder()
        //     this.setState({
        //         show: false
        //     })
        // }).catch(err => {
        //     this.setState({
        //         hasError: true,
        //         error: 'Error occurred, please contact support!',
        //         loading: false
        //     })
        // })
        
        axios.post('layer/create', {layerId: this.props.layerUuid, materialFolderId: this.props.uuid}
        ).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            this.props.applyPresetFolder()
            this.setState({
                show: false
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <img className="trash-icon material-gallery-icon" src={brushIcon} alt="" onClick={this.handleShow}/>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Apply Material Library to Layer</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                                <span>Do you want to apply this Library to current Layer?</span>
                            </Col>
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <Spinner animation="border" role="status" variant="primary">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={12} className="text-center">
                                <Button variant="primary" className="btn-delete-preset" onClick={this.applyPresetFolder} disabled={this.state.loading}>
                                    Apply
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    applyPresetFolder: applyPresetFolder,
}


export default connect(null, mapDispatchToProps)(ApplyPresetFolderModal)