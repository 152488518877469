import React, {Component} from "react";
import { connect } from 'react-redux';
import {Table, Col, Row, Spinner} from "react-bootstrap";
import Combination from "./Combination";
import './Combinations.css';
import './Controls.css';
import jwt_decode from "jwt-decode";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import GetEmbeddedCombinationLinkModal from "./GetEmbeddedCombinationLinkModal";
import GetWidgetCombinationLinkModal from "./GetWidgetCombinationLinkModal";
import GetConfiguratorCombinationLinkModal from "./GetConfiguratorCombinationLinkModal";

class Combinations extends Component {
    state = {
        embedded: {},
        widget: {},
        configurator: {},
        embeddedUuid: null,
        widgetUuid: null,
        configuratorUuid: null,
        embeddedCombinationUuid: null,
        widgetCombinationUuid: null,
        configuratorCombinationUuid: null,
        userInfo: '',
        userPermission: {},
        username: '',
    }

    /**
     * Will set to false all embedded and widgets checkboxes so that we can track which one is checked
     */
    componentDidMount() {
        let token = localStorage.getItem('user');
        if(token)
        {
            console.log(jwt_decode(localStorage.getItem('user')));
            this.setState({
                loggedIn: true,
                userInfo: jwt_decode(localStorage.getItem('user'))
            })
            axios.get('user/get-user-permission').then(response => {
                let {data} = response
                if (data.success) {
                    this.setState({
                        loggedIn: true,
                        userPermission: data.data
                    }) 
                }
            });
        }
        if (!this.props.combinations.combinationsList.isEmpty) {
            let embedded = {}
            let widget = {}

            setTimeout(() => {
                this.props.combinations.combinationsList.map((combination, i) => {
                    if(i == 0)
                    {
    
                        let embeddeds = {...this.state.embedded}
    
                        this.setState({
                            embedded: {
                                ...embeddeds,
                                [embedded]: true
                            },
                            embeddedUuid: combination.embedded,
                            embeddedCombinationUuid: combination.uuid
                        })
                    }
    
                })

                if(this.props.combinations.createdCombination != undefined)
                {
                    let widgets = {...this.state.widget}
    
                    this.setState({
                        widget: {
                            ...widgets,
                            [this.props.combinations.createdCombination.widget]: true
                        },
                        widgetUuid: this.props.combinations.createdCombination.widget,
                        widgetCombinationUuid: this.props.combinations.createdCombination.uuid
                    })
                }

                if(this.props.combinations.createdCombination != undefined)
                {
                    let configurators = {...this.state.configurator}
    
                    this.setState({
                        configurator: {
                            ...configurators,
                            [this.props.combinations.createdCombination.configurator]: true
                        },
                        configuratorUuid: this.props.combinations.createdCombination.configurator,
                        configuratorCombinationUuid: this.props.combinations.createdCombination.uuid
                    })
                }
            }, 3000);

        }
    }

    handleConfiguratorCheckboxChange = (configurator, combinationUuid) => {
        let configurators = {...this.state.configurator}
        this.props.combinations.combinationsList.map(combination => {
            configurators[combination.configurator] = false
        })

        this.setState({
            configurator: {
                ...configurators,
                [configurator]: true
            },
            configuratorUuid: configurator,
            configuratorCombinationUuid: combinationUuid
        })
    }
    /**
     * Only one widget can be selected at a time
     * @param widget
     * @param combinationUuid
     */
    handleWidgetCheckboxChange = (widget, combinationUuid) => {
        let widgets = {...this.state.widget}
        this.props.combinations.combinationsList.map(combination => {
            widgets[combination.widget] = false
        })

        this.setState({
            widget: {
                ...widgets,
                [widget]: true
            },
            widgetUuid: widget,
            widgetCombinationUuid: combinationUuid
        })
    }

    /**
     * Only one embedded can be selected at a time
     * @param embedded
     * @param combinationUuid
     */
    handleEmbeddedCheckboxChange = (embedded, combinationUuid) => {
        let embeddeds = {...this.state.embedded}
        this.props.combinations.combinationsList.map(combination => {
            embeddeds[combination.embedded] = false
        })

        this.setState({
            embedded: {
                ...embeddeds,
                [embedded]: true
            },
            embeddedUuid: embedded,
            embeddedCombinationUuid: combinationUuid
        })
    }
    openGallery = () => {
        window.open(galleryUrl+"/gallery/"+this.state.userPermission.username, "_blank");
    }

    render() {
        let combinations = ''
        let loading = this.props.combinations.loading
        let hasError = this.props.combinations.hasError

        if (!loading && !hasError && !this.props.combinations.combinationsList.isEmpty && this.state.userPermission.username) {
            let layers = this.props.combinations.combinationsList[0] ? JSON.parse(this.props.combinations.combinationsList[0].layers) : []
            combinations = (
                <Row className="view-storage-combination" >
                    <Col md={{ span: 12}}>
                        <Table className="combinations-table table-borderless" size="lg">
                            <thead>
                            <tr>
                                <th colSpan="2"></th>
                                <th id="gallery-group-th" colSpan={6}>GALLERY / MARKETPLACE</th>
                                <th></th>
                                <th colSpan="2"></th>
                                <th ></th>
                            </tr>
                            <tr>
                                <th colSpan="2">Default Image</th>
                                <th colSpan={2}>Name Description Tags Price</th>
                                <th colSpan={2}>Product Upload</th>
                                <th>Publish</th>
                                <th>Comments</th>
                                {/* <th>Cover Images</th> */}
                                <th>Cover Image</th>
                                <th colSpan="2">Viewer</th>
                                <th >Action</th>
                                {/* {
                                    layers.map((layer, i) => {
                                        return <th key={i}>{layer}</th>
                                    })
                                } */}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.combinations.combinationsList.map((combination, i) => {
                                    return <Combination
                                        key={combination.uuid}
                                        combination={combination}
                                        layers={layers}
                                        position={i}
                                        checkedConfigurator={this.state.configurator[combination.configurator]}
                                        checkedEmbedded={this.state.embedded[combination.embedded]}
                                        checkedWidget={this.state.widget[combination.widget]}
                                        handleWidgetCheckboxChange={this.handleWidgetCheckboxChange}
                                        handleEmbeddedCheckboxChange={this.handleEmbeddedCheckboxChange}
                                        handleConfiguratorCheckboxChange={this.handleConfiguratorCheckboxChange}
                                        userPermission={this.state.userPermission}
                                    />
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={{ span: 12}}>
                        <div className="comb-get-btn">
                            {/* {
                                this.state.userPermission.isAllowIFrame ? 
                                <GetEmbeddedCombinationLinkModal embedded={this.state.embeddedUuid} combinationUuid={this.state.embeddedCombinationUuid}/>
                                : ''
                            } */}
                            {/* {
                                this.state.userPermission.isAllowCombination ? 
                                <GetWidgetCombinationLinkModal widget={this.state.widgetUuid} combinationUuid={this.state.widgetCombinationUuid}/>
                                : ''
                            } */}
                            {/* {
                                this.state.userPermission.isAllowConfigurator ? 
                                <GetConfiguratorCombinationLinkModal configurator={this.state.configuratorUuid} combinationUuid={this.state.configuratorCombinationUuid}/>
                                : ''
                            } */}
                            {/* <div style={{ display: this.state.userPermission.isShowGallery ? 'inline-block' : 'none' }}><button style={{ marginBottom: 5 }} className="btn btn-primary" onClick={this.openGallery}>View Gallery</button></div> */}
                        </div>
                    </Col>
                </Row>
            )
        }

        if (loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }

        if (hasError) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <span className="rename-material-modal-error">{this.props.combinations.error}</span>
                    </Col>
                </Row>
            )
        }

        if (this.props.combinations.combinationsList.isEmpty) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <span className="rename-material-modal-error">No combinations found !</span>
                    </Col>
                </Row>
            )
        }

        return (
            combinations
        )
    }
}

const mapStateToProps = state => {
    return {
        combinations: state.combinations
    }
}

export default connect(mapStateToProps, null)(Combinations);