import { getGPUTier } from 'detect-gpu';
import {ARButton} from "three/examples/jsm/webxr/ARButton";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {Pass} from "three/examples/jsm/postprocessing/Pass";
import {ClearPass} from "three/examples/jsm/postprocessing/ClearPass";
import {sRGBEncoding, Uncharted2ToneMapping,  WebGLRenderer} from "three";

import React, {useEffect, useState} from "react";
import {Spinner, Col, Row, DropdownButton, Dropdown, Navbar, Form, Button} from "react-bootstrap";
import {FileLoader, Vector2, ObjectLoader} from "three";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {switchCases} from "./switchCases";
import {SpinnerSmall} from "../../helpers/spinners";
import viewerUrl from '../../3dViewerUrl';
import axios from '../../axios';
// import Navbar from "../Navbar/Navbar";

import HelperModal from "../../components/Configurator/Combinations/HelperModal";
import CombinationIcons from "../../components/Configurator/Combinations/CombinationIcons";
import * as commonFunction from '../CommonFunction/CommonFunction.js';

import HardwareAccelerationModal from "../Configurator/Combinations/HardwareAccelerationModal";
import logo from '../../assets/logo_site.png';


import arOn from '../../assets/button-AR-On.png';
import cameraOn from '../../assets/button-Camera-On.png';
import switchOn from '../../assets/button-On.png';
import rotateOn from '../../assets/button-Rotate-On.png';
import annotationUrl from '../../assets/annotations.png';
import swiper360 from '../../assets/swipe_360.gif';

let isLoadedMoreThanNineTy = false;
// const Viewer = require("../../cadcentre/dist/cadcenter");
const Viewer = window.CADEDITOR.icad;
let mousePosition = new Vector2(0, 0)
let mouseDownTime = 0;
let lastMouseDownTime = 0;
let _logoVisibility = 1;
let isFirstClick = false;
let draggingAnnotation = null;

let isFullScreen = false;
// Duration is in seconds
let logoFadeInDuration = 1.0;
let logoFadeOutDuration = 1.0;
let logoStayDuration = 1.0;
let logoMinActionTime = 0.5; // 500 ms. Min time to check.
let _logoTweener;
let _logoState =  'startVisible';
let outlineFadeTime = 1;
let outlineVisibleTime = 4;
let outlineEdgeStrength = 1.0;
let mouseWheelTime = 0;
let currentCombinationName = '';
let currentCombinationUrl = window.location;
let isIgnoreAnimation = false;
let enableCameraDoubleClick = true;
// var context = new ( window.AudioContext || window.webkitAudioContext )();

let play = 'https://cadgl.net/icons_cadgl/play.png';
let stop = 'https://cadgl.net/icons_cadgl/stop.png';
let sound_on = 'https://cadgl.net/icons_cadgl/sound_on.png';
let sound_off = 'https://cadgl.net/icons_cadgl/sound_off.png';
let help = 'https://cadgl.net/icons_cadgl/help.png';
let fullscreen = 'https://cadgl.net/icons_cadgl/fullscreen.png';
let auto_rotate = 'https://cadgl.net/icons_cadgl/auto_rotate.png';
let auto_rotate_stop = 'https://cadgl.net/icons_cadgl/auto_rotate_stop.png';
let annotation_on = 'https://cadgl.net/icons_cadgl/annotation_on.png';
let annotation_off = 'https://cadgl.net/icons_cadgl/annotation_off.png';
const canvas = React.createRef()
function ProductRenderer(props, state) {

    let arStatus = true;
    let autoRotate = false;
    let combinations;
    let userInfo = {};
    const [gpu, setGPU] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [useCustomLoading, setUseCustomLoading] = useState(false);
    const [customLoading, setCustomLoading] = useState('');

    
    const [useCustomSwipe, setUseCustomSwipe] = useState(false);
    const [customSwipe, setCustomSwipe] = useState('');

    const [showHardwareModal, setShowHardwareModal] = useState(false);
    async function testGPU(){
        const gpuTier = await getGPUTier();
        console.log("gpu", gpuTier);
        setGPU(gpuTier);
    }

    let logoParams = {
        opacity: 1,
        enabled: true,
        image: "https://cadgl.net/editor/images/logo_editor.png",
        height: 0,
        width: 0,
        positionY: 0,
        positionX: 0,
        scale: 3,
        logoStayDuration: 60,
        logoFadeInDuration: 0,
        logoFadeOutDuration: 0
    };

    useEffect(() => {
        // let isMobile = false;
        // let isAppleGPU = false;
        // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform) ) {
        //     isMobile = true;
        // }
        // const iPad = (navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ || 
        //     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);
        // if(iPad) isMobile = true;
        // if(firstLoad){
        //     testGPU();
        //     if(gpu && firstLoad){
        //         setFirstLoad(false);
        //         if(gpu.gpu == "apple gpu (Apple GPU)") isAppleGPU = true;
        //         if(gpu.tier >= 2 || isMobile || isAppleGPU){
        //             coreFeature();
        //         }else{
        //             setShowHardwareModal(true);
        //         }
        //     }
        // }
        coreFeature();
        
    }, [gpu])

    function coreFeature(){
        let isInIFrame = window.self !== window.top;
        axios.post('combination/get-iframe', {
            id: props.match.params.id,
            getMaterial: true,
            getConfig: true,
            getGeometry: true,
            isInIFrame: isInIFrame
        }).then(response => {
            let comp = response.data.data.combination
            let user = response.data.data.user;
            
            
            var element = document.getElementById("360-iframe");
            // Add the class to the element
            element.classList.add(user.username.replace(/\s+/g, '') + "_iframe");

            if(user.isAllowCustomLogoSetting){
                if(user.logoUrl){
                    logoParams.image = user.logoUrl;
                }
                if(user.logoStayDuration){
                    logoParams.logoStayDuration = user.logoStayDuration;
                }
                if(user.logoFadeInDuration){
                    logoParams.logoFadeInDuration = user.logoFadeInDuration;
                }
                if(user.logoFadeOutDuration){
                    logoParams.logoFadeOutDuration = user.logoFadeOutDuration;
                }
                if(user.logoPositionX){
                    logoParams.positionX = user.logoPositionX;
                }
                if(user.logoPositionY){
                    logoParams.positionY = user.logoPositionY;
                }
                if(user.logoScale){
                    logoParams.scale = user.logoScale;
                }
            }
            if(user.loadingUrl && user.isAllowCustomLoadingSetting){
                setUseCustomLoading(true);
                setCustomLoading(user.loadingUrl);
            }
            
            if(user.swipeUrl && user.isAllowCustomSwipeIconSetting){
                setUseCustomSwipe(true);
                setCustomSwipe(user.swipeUrl);
            }

            let timeTimeout = 0;
            if(window.self !== window.top){
                timeTimeout = 1000;
            }
            // document.getElementById("static-img").style.setProperty('height', window.innerHeight+'px', 'important');
            if(document.getElementById("canvas").clientWidth != undefined && document.getElementById("canvas").clientWidth > 0){
                setTimeout(() => {
                        //Create API instance
                        const api = new Viewer.API({
                            canvas: canvas.current,
                            xrOverlay: document.getElementById("xrOverlay"),
                            gemConfig: {
                                quality: 'high',
                                diamondClass: [{
                                    name: "diamond",
                                    color: 0xaaaaaa
                                }]
                            },
                            annotationIconParams: {
                                maxAnnotations: 10,
                                texture: user.annotationUrl && user.annotationUrl != '' ?  user.annotationUrl : annotationUrl,
                                columns: 10,
                                size: new Vector2(48, 48),
                                padding: 2,
                                annotationWidth: 40 * 1920 / window.innerWidth,
                                annotationHiddenAlpha: 0.3,
                                annotationHighlightTint: "gold",
                                annotationTint: "#ffffff"
                                // annotationTint: "red"
                            },
                            //logoParams: logoParams,
                            animationLoopCallback : ()=> animationLoop()
                        });

                        function _tweenLogo(x, finalState, duration = 0.5){
                            if(_logoTweener) _logoTweener.stop();
                            const fState = finalState;
                            _logoTweener = api.getTweener({l: _logoVisibility}).to({l: x}, duration*1000).onUpdate((o)=>{
                                if (Math.abs(_logoVisibility-o.l) < 0.0001) return;
                                _logoVisibility = o.l;
                                let p = api.getLogoParams();
                                p.opacity = _logoVisibility * _logoVisibility;
                                api.setLogoParams(p);
                            }).onComplete(()=>{
                                _logoVisibility = x;
                                _logoTweener = undefined;
                                _logoState = fState;
                            })
                                //.easing(TWEEN.Easing.Quadratic.InOut)
                                .start();
                            return _logoTweener;
                        }
                    
                        function animationLoop(){

                            let logoParams = api.getLogoParams();
                            const time = Date.now();
                            const mmax = logoMinActionTime * 1000;
                    
                            if(( mouseDownTime > 1 && time-mouseDownTime > mmax ) || ( time-mouseWheelTime < 500 ) ){
                                // show if hidden. stop if hiding and show. do nothing if showing or visible
                                if(_logoState === 'hidden' || _logoState === 'hiding'){
                                    _logoVisibility = Math.max(0.002, _logoVisibility);
                                    _tweenLogo(1, 'startVisible', (1-_logoVisibility) *logoParams.logoFadeInDuration);
                                    _logoState = 'showing';
                                }
                            }else {
                                // hide if visible. stop if showing and hide. do nothing if hiding or hidden
                                if(_logoState === 'startVisible'){
                                    // this._logoVisibility = this._logoVisibility;
                                    _tweenLogo(_logoVisibility, 'startHiding',logoParams.logoStayDuration-0.002);
                                    _logoState = 'visible';
                                }
                                if(_logoState === 'startHiding'){
                                    _logoVisibility = Math.min(0.998, _logoVisibility);
                                    _tweenLogo(0, 'hidden',(_logoVisibility) * logoParams.logoFadeOutDuration);
                                    _logoState = 'hiding';
                                }
                            }
                        }


                        let gRefl = api.getGroundReflectionParams();
                        gRefl.enable = false;
                        api.setGroundReflectionParams(gRefl);
                        
                        let xrParams = api.getXRParams();
                        xrParams.enabled = false;
                        api.setXRParams(xrParams);
                        
                        api.play() //Render 3D model on canvas
                        api.annotationsEnabled = true;

                        commonFunction.setDefaultRatio(api);




                        //if (!props.iframe.combination.id) {
                        if (!props.match.params.id) {
                            new FileLoader().load("./scene.json", (response) => {
                                api.importScene(JSON.parse(response))
                            })
                        } else {
                            const search = window.location.search;
                            console.log(search);
                            const isGalleryBanner=search.includes("isGalleryBanner");
                            if(isGalleryBanner){
                                new FileLoader().load("https://app.cadgl.com/bar.json", (response) => {
                                    commonFunction.hideLayersAfterLoad();
                                    api.importScene(JSON.parse(response))
                                })
                            }else{
                                setTimeout(() => {
                                    // if (window.getComputedStyle(document.getElementById("static-img")).display !== "none")
                                    // {
                                        commonFunction.renderFullscreenIconHelperIcon(comp, "iframe");
                                        document.getElementById("myLoader2").style.display = "block";
                                    // }
                                }, 1000);
                                document.getElementById("canvas").style.display = "none";
        


                                // axios.post('combination/get-iframe', {
                                //     id: props.match.params.id,
                                //     getMaterial: true,
                                //     getConfig: true,
                                //     getGeometry: true,
                                //     isInIFrame: isInIFrame
                                // }).then(response => {

                                    
                                // }).catch(err => {
                                // })


                                /* Inside axios.post('combination/get-iframe') then - Start */
                                currentCombinationName = comp.name;
                                // commonFunction.currentCombinationName = currentCombinationName;
                                isIgnoreAnimation = comp.isIgnoreAnimation;
                                enableCameraDoubleClick = comp.isCameraDoubleClick != undefined ? comp.isCameraDoubleClick : true;
                                if(isIgnoreAnimation == "true"){
                                    api.stopAllAnimations();
                                }
                                let user = response.data.data.user;
                                
                                commonFunction.renderStaticImg(comp);
    
                                let path = comp.path;
                                // if(user.cdnUrl){
                                //     let arrPath = path.split("/");
                                //     path = "https://" + user.cdnUrl + "/" + arrPath[arrPath.length - 1];
                                // }
                                if(comp.isUseCDN){
                                    path = "https://assets.cadgl.com/" + comp.path.substring(comp.path.lastIndexOf('/') + 1);
                                }
                                new FileLoader().load(path, async (response) => {
                                    setTimeout(async () => {
                                        await api.importScene(JSON.parse(response))
                                        api.stopAllSounds();
                                        // commonFunction.waitAudioContext(api);
                                        // api.play();
                                        if(window.innerWidth < window.innerHeight){
                                            api.setCameraParams({orbitParams: {zoomSpeed: 1}});
                                        }
        

                                        // let logoParams1 = api.getLogoParams();
                                        // console.log("logoParams1: ", logoParams1);
                                        // api.setLogoParams(logoParams1);
                                        
                                        setTimeout(() => {
                                            let animationSet = api.getModelAnimation();
                                            if(animationSet.size > 0 && isIgnoreAnimation == "false"){
                                                //SHOW PLAY ANIMATION BUTTON
                                                document.getElementById("animationPlay").style.display = "block";
                                            }
                                        }, 3000);
                                        if(api.getAmbientAudio() != undefined){
                                            document.getElementById("audioPlay").style.display = "block";
                                        }
        
                                        //Auto Rotate
                                        let cameraParams = api.getCameraParams();
                                        let orbitP = cameraParams.orbitParams;
                                        if(orbitP.autoRotate == true){
                                            document.getElementById("autoRotateIcon").style.display = "block";
                                        }
        
                                        //Annotation
                                        commonFunction.renderAnnotationIcon(api);
                                        if(new Date(comp.createDate).getFullYear() <= new Date(2021,10,26).getFullYear()
                                        && 
                                        new Date(comp.createDate).getMonth() <= new Date(2021,10,26).getMonth()
                                        ){
                                            api.setCameraParams({orbitParams: {zoomSpeed: 1, minDistance: 0.2}})
                                        }


                                        // api.straighten('y', '-');
                                        var run = false;
                                        setTimeout(() => {
                                            let per = 90;
                                            var percentageInterval = setInterval(() => {
                                                per++;
                                                document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                                                document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                                                if(per >= 100){
                                                    console.log(cameraParams);
                                                    commonFunction.hideLayersAfterLoad();
                                                    // api.setCameraParams({orbitParams: {zoomSpeed: 1, minDistance: 0.005}})
                                                    // console.log(api.getCameraParams());
                                                    // api.setCameraParams({zoom: cameraParams.zoom/200});
                                                    // let totalZoom = 0.001;
                                                    // let totalZoomInt = setInterval(() => {
                                                    //     console.log("cameraParams.zoom: " + cameraParams.zoom);
                                                    //     if(totalZoom < cameraParams.zoom){
                                                    //         totalZoom = totalZoom + 0.001;
                                                    //         api.setCameraParams({zoom: totalZoom});
                                                    //         if(cameraParams.zoom - totalZoom <= 0.003 && !run){
                                                    //             run = true;
                                                    //             api.straighten('y', '+');
                                                    //         }
                                                    //     }else{
                                                    //         clearInterval(totalZoomInt);
                                                    //     }
                                                    // }, 0.5);
                                                    clearInterval(percentageInterval);
                                                }
                                            }, 100);
                                        }, 1);
        
        
                                        if(isInIFrame){
                                            xrParams = api.getXRParams();
                                            xrParams.enabled = false;
                                            
                                            if(navigator.platform == 'iPhone' || navigator.platform == 'iPad' || navigator.platform == 'Win32'){
                                                api.setXRParams(xrParams);
                                            }
                                        }else{
                                            var xrInterval = setInterval(() => {
                                                    xrParams = api.getXRParams();
                                                    
                                                    if (navigator.platform == 'iPhone' || navigator.platform == 'iPad') { // <-- Use the one here above
                                                            
                                                        if ('xr' in navigator)
                                                        {
                                                            if(!user.isAllowAR)
                                                            {
                                                                xrParams.enabled = false;
                                                            }
                                                            api.setXRParams(xrParams);
                                                        }
                                                        else
                                                        {
                                                            var mySpans = document.getElementsByTagName('a');
                                                            var arBut;
                                                            for(var i=0;i<mySpans.length;i++){
                                        
                                                                if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                        
                                                                arBut = mySpans[i];
                                                                break;
                                        
                                                            }
                                        
                                                            }
                                                            if(arBut)
                                                            {
                                                                //Hide Button for iOS for now
                                                                arBut.style.display = "none";
                                                                if(user.isAllowAR)
                                                                {
                                                                    if(!xrParams.enabled)
                                                                    {
                                                                        arBut.style.display = "none";
                                                                    }
                                                                }
                                                                else{
                                                                    arBut.style.display = "none";
                                                                }
                                                                arBut.innerText = 'START AR';
                                                                arBut.removeAttribute("href");
                                            
                                                                // Get the modal
                                                                var modal = document.getElementById("myModal");
                                            
                                                                // Get the button that opens the modal
                                                                var btn = arBut;
                                            
                                                                // Get the <span> element that closes the modal
                                                                var span = document.getElementsByClassName("closeAR")[0];
                                            
                                                                // When the user clicks on the button, open the modal
                                                                var p = document.getElementsByClassName("modal-bodyAR-p")[0];
                                                                //p.innerHTML = "Please download or open <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> to view " + "<a href='"+document.location+"'>"+document.location+"</a> and experience the AR ";
                                                                p.innerHTML = "To view in AR <br/> <a id='urlClicking' href='#'>"+currentCombinationName+"</a> (Copy link) <br/> Use or install <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> <br/> <i>* AR will be fully available on IOS very soon</i> ";
                                                                
                                                                document.getElementById('urlClicking').addEventListener('click', function(){
                                                                    const textField = document.createElement('textarea');
                                                                    textField.innerText = currentCombinationUrl;
                                                                    document.body.appendChild(textField);
                                                                    textField.focus();
                                                                    textField.select();
                                                                    textField.setSelectionRange(0, 99999); /* For mobile devices */
                                                                    try {
                                                                    // Now that we've selected the anchor text, execute the copy command
                                                                        const successful = document.execCommand('copy');
                                                                    } catch(err) {
                                                                        alert(err);
                                                                    }
                                                                    document.body.removeChild(textField);
                                                                    alert("Copied to the clipboard");
                                                                });
                                                                btn.onclick = function() {
                                                                modal.style.display = "block";
                                                                }
                                            
                                                                // When the user clicks on <span> (x), close the modal
                                                                span.onclick = function() {
                                                                modal.style.display = "none";
                                                                }
                                            
                                                                // When the user clicks anywhere outside of the modal, close it
                                                                window.onclick = function(event) {
                                                                    if (event.target == modal) {
                                                                        modal.style.display = "none";
                                                                    }
                                                                }
                                                            }
                                        
                                                        }
                                                    }
                                                    else
                                                    {
                                                        var element =  document.getElementById('ARButton');
                                                        if(element != null)
                                                        {
                                                            var txt = element.textContent || element.innerText;
                                                            if(txt == 'AR NOT SUPPORTED')
                                                            {
                                                                element.style.display = "none";
                                                            }
                                                        }
                                                        var mySpans = document.getElementsByTagName('a');
                                                        var arBut;
                                                        for(var i=0;i<mySpans.length;i++){
                                    
                                                            if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                                                arBut = mySpans[i];
                                                                break;
                                                            }
                                                        }
                                                        if(arBut){
                                                            arBut.style.display = "none";
                                                        }
                                                    }
                                                    clearInterval(xrInterval);
                                            }, 1);
                                        }
        
        
                                        let materialsLibrary = api.getMaterialLibrary()
                                        for(const layer of materialsLibrary)
                                        {
                                            if(layer.material.visible)
                                            {
                                                for(let mesh of layer.mesh){
                                                    if(api.getAudio(mesh)){
                                                        document.getElementById("audioPlay").style.display = "block";
                                                        
                                                    }
                                                }
                                            }
                                        }
        
                                        commonFunction.uploadGLBToLeo(api, viewerUrl, 'iframe', props.match.params.id);
                                    }, 1);
                                    
    
                            
    
                                },function ( xhr ) {
                                    if(xhr.loaded >= xhr.total)
                                    {
                                        // document.getElementById("static-img").style.display = "none";
                                        // document.getElementById("myLoader2").style.display = "none";
                                        // document.getElementById("canvas").style.display = "block";
                                    }
                                    let xhrTotal = 0;
                                    if(comp.originalFileSize){
                                        xhrTotal = comp.originalFileSize;
                                    }else{
                                        xhrTotal = xhr.total;
                                    }

                                    var per = xhr.loaded / xhrTotal * 100;
                                    if(per >= 90){
                                        per = 90;
                                        isLoadedMoreThanNineTy = true;
                                    }
                                    else{
                                        document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                                        document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                                        console.log( (xhr.loaded / xhrTotal * 100) + '% loaded' );
                                        
                                        // var fadeTarget = document.getElementById("static-img-overlay");
                                        var fadeTarget = document.getElementById("static-img");
                                        var opa = (100 - xhr.loaded / xhrTotal * 100)/10;
                                        console.log("opa: " + opa);
                                        // fadeTarget.style.opacity = opa;
                                        // fadeTarget.style.filter  = 'blur('+opa+'px)';
                                        // fadeTarget.style.filter  = 'blur(40px)';
                                    }

                                },
                            
                                // onError callback
                                function ( err ) {
                                    console.error( 'An error happened' );
                                } )
    
                                let combinationList = document.getElementById("combinationList");
                                axios.get('combination/get-list?meshes=' + comp.meshes).then(response => {
                                    combinations = response.data.data.combination
                                    combinations.map(function(combination, index) {
                                        if(combination.uuid == comp.uuid)
                                        {
                                            document.getElementById('combinationPrice').value = combination.price != undefined ? '$' + parseFloat(combination.price, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() : 0;
                                            document.getElementById('combinationDesc').value = combination.description != undefined ? combination.description : '';
                                            let opt = new Option(combination.name, combination.path); 
                                            opt.selected = true;
                                            combinationList[index] = opt;
                                        }
                                        else
                                        {
                                            combinationList[index] = new Option(combination.name, combination.path);
                                        }
                                    });
    
                                }).catch(error => {
                                })
                                
                    
                                logoParams.enabled = user.isAllowWatermarkLogo;
                                if(user.logoUrl && user.isAllowCustomLogoSetting){
                                    logoParams.enabled = true;
                                }
                                else if(user.logoUrl && !user.isAllowCustomLogoSetting){
                                    logoParams.enabled = user.isAllowWatermarkLogo;
                                }
                                else if((!user.logoUrl || user.logoUrl == "") && user.isAllowCustomLogoSetting){
                                    logoParams.enabled = false;
                                }

                                userInfo.isShowLinkToolTip = user.isShowLinkToolTip;
                                api.setLogoParams(logoParams);

                                let p = api.getLogoParams();
                                console.log("p: ", p);

                                /* Inside axios.post('combination/get-iframe') then - End */

        
                            }

                        }

                        //Setting mouse and touch events on canvas
                        

                        function exitHandler()
                        {
                            var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
                            if(fullscreenElement == null){
                                isFullScreen = false;
                            }
                        }

                        document.addEventListener('fullscreenchange', exitHandler, false);
                        document.addEventListener('mozfullscreenchange', exitHandler, false);
                        document.addEventListener('MSFullscreenChange', exitHandler, false);
                        document.addEventListener('webkitfullscreenchange', exitHandler, false);
                        document.getElementById('fullScreenIcon').addEventListener('click', function(e) {
                            if(!isFullScreen){
                                var elem = document.documentElement;
                                if (elem.requestFullscreen) {
                                elem.requestFullscreen();
                                } else if (elem.webkitRequestFullscreen) { /* Safari */
                                elem.webkitRequestFullscreen();
                                } else if (elem.msRequestFullscreen) { /* IE11 */
                                elem.msRequestFullscreen();
                                }else if (elem.webkitEnterFullscreen) { /* IE11 */
                                    elem.webkitEnterFullscreen();
                                }
                                isFullScreen = true;
                            }else{
                                if (document.exitFullscreen) {
                                    document.exitFullscreen();
                                } else if (document.webkitExitFullscreen) { /* Safari */
                                    document.webkitExitFullscreen();
                                } else if (document.msExitFullscreen) { /* IE11 */
                                    document.msExitFullscreen();
                                }
                                isFullScreen = false;
                            }
                        });


                        document.getElementById('animationPlay').addEventListener('click', function(e) {
                            if(document.getElementById('animationPlay').childNodes[0].src == play){
                                api.playAllAnimations();
                                api.playAllSounds();
                                document.getElementById('audioPlay').childNodes[0].src = sound_on;
                                document.getElementById('animationPlay').childNodes[0].src = stop;
                                document.getElementById('animationPlay').title = "Stop Animation";
                                document.getElementById('animationPlay').setAttribute('data-tooltip', 'Stop Animation');
                            }
                            else{
                                api.stopAllAnimations();
                                api.stopAllSounds();
                                document.getElementById('audioPlay').childNodes[0].src = sound_off;
                                document.getElementById('animationPlay').childNodes[0].src = play;
                                document.getElementById('animationPlay').title = "Play Animation";
                                document.getElementById('animationPlay').setAttribute('data-tooltip', 'Play Animation');
                            }
                        });

                        document.getElementById('audioPlay').addEventListener('click', function(e) {
                            if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
                                api.stopAllSounds();
                                document.getElementById('audioPlay').childNodes[0].src = sound_off;
                                document.getElementById('audioPlay').title = "UnMute";
                                document.getElementById('audioPlay').setAttribute('data-tooltip', 'UnMute');
                            }
                            else{
                                api.playAllSounds();
                                document.getElementById('audioPlay').childNodes[0].src = sound_on;
                                document.getElementById('audioPlay').title = "Mute";
                                document.getElementById('audioPlay').setAttribute('data-tooltip', 'Mute');
                            }
                        });

                        document.getElementById('autoRotateIcon').addEventListener('click', function(e) {
                            let cameraParams = api.getCameraParams();
                            let orbitP = cameraParams.orbitParams;
                            api.setCameraParams({orbitParams: {autoRotate: !orbitP.autoRotate}})

                            if(document.getElementById('autoRotateIcon').childNodes[0].src == auto_rotate){
                                document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate_stop;
                                document.getElementById('autoRotateIcon').setAttribute('data-tooltip', 'Auto Rotate');
                            }else{
                                document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate;
                                document.getElementById('autoRotateIcon').setAttribute('data-tooltip', 'Stop Auto Rotate');
                            }
                        });

                        document.getElementById('annotationIcon').addEventListener('click', function(e) {
                            if(document.getElementById('annotationIcon').childNodes[0].src == annotation_on){
                                document.getElementById('annotationIcon').childNodes[0].src = annotation_off;
                                api.annotationsEnabled = false;
                            }else{
                                document.getElementById('annotationIcon').childNodes[0].src = annotation_on;
                                api.annotationsEnabled = true;
                            }
                        });


                        document.getElementById('close3DViewer').addEventListener('click', function(e) {
                            var modal3DViewer = document.getElementById("modal3DViewer");
                            modal3DViewer.style.display = "none";
                            document.getElementById('iframe-main-content').style.opacity = "100%";
                        });

                        document.getElementById('measurementIcon').addEventListener('click', function(e) {
                            document.getElementById('modal3DViewer').style.display = "block";
                            document.getElementById('iframe-main-content').style.opacity = "20%";
                        })
                        
                        document.getElementById('canvas').addEventListener('keydown', (e) => onMoveCameraByArrowKey(e, api, false, userInfo));


                        document.getElementById('canvas').addEventListener('mousemove', (e) => onPointerMove(e, api, false, userInfo));
                        document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerMove(e, api, true, userInfo));
                        document.getElementById('tooltip-span').addEventListener('mouseup', (e) => onPointerUp2(e, api, props, false, userInfo));
                        document.getElementById('canvas').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('canvas').addEventListener('touchend', (e) => onPointerUp(e, api, props, true, userInfo));
                        document.getElementById('canvas').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('canvas').addEventListener('touchstart', (e) => onPointerDown(e, api, props));
                        document.getElementById('canvas').addEventListener('wheel', (e) => onPointerWheel(e, api, props));
                        document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerWheel(e, api, true, userInfo));

                        
                        document.getElementById('autoRotateIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('autoRotateIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('helperIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('helperIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('fullScreenIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('fullScreenIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('animationPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('animationPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('audioPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('audioPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                        document.getElementById('annotationIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                        document.getElementById('annotationIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));

                        document.getElementById('canvas').addEventListener('dblclick', (e) => onPointerDownDouble(e, api, props));

                        window.addEventListener("resize", ev => {
                            // api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
                            
                            commonFunction.setDefaultRatio(api);
                        }, false);

                    
                }, timeTimeout);
            }

        });
        

    }

    useEffect(() => {
        console.log("Produc Renderer");
        // switchCases(props, API)
    })


    return (
        <>
            <HardwareAccelerationModal show={showHardwareModal}></HardwareAccelerationModal>
            <div style={{height: 100+'%'}} id="test">
                <div id="tooltip-span" style={{width: 100, height: 100}} className="tooltip" title="CadGL"></div>
                <Row id="iframe-main-content" className="main-content iframe-main-content" style={{height: 100+'%'}}>
                    <Col sm={{ span: 12}} className="canvas-area" style={{height: 100+'%'}}>
                        <div style={{display: 'none'}} className="animate-bottom" id="myLoader">
                        </div>
                        {
                            useCustomLoading ? 
                            <>
                            <div style={{display: 'none', position: 'absolute', width: 'auto', height: 'auto'}}  id="myLoader2">
                                <div id="myBar" className="c100 p0 big" style={{display: 'none'}}>
                                    <span id="myBar-text">0%</span>
                                    <div className="slice">
                                        <div className="bar"></div>
                                        <div className="fill"></div>
                                    </div>
                                </div>
                                <img style={{width: 100+'%'}} src={customLoading} />
                            </div>
                            </>
                            :
                            <>
                            <div style={{display: 'none', position: 'absolute'}}  id="myLoader2">
                                <div id="myBar" className="c100 p0 big">
                                    <span id="myBar-text">0%</span>
                                    <div className="slice">
                                        <div className="bar"></div>
                                        <div className="fill"></div>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        <div id="static-img">
                            <div id="static-img-overlay">
                            </div>
                        </div>
                        {/* <img id="static-img" style={{width: 100+'%'}} /> */}


                        <canvas id="canvas" className="canvasIframe" width={500} height={500} ref={canvas}/>
                        <img id="swipeIcon" src={useCustomSwipe ? customSwipe : swiper360} style={{display: 'none', position: 'absolute', bottom: 20}} />
                        
                        <div id="iframe-canvas-buttons" className="" style={{position: 'absolute', top: 10, right: 70, height: 20}}>
                            
                            {/* <div className="col-md-4" id="helperIcon" style={{display: 'block'}}>
                                <HelperModal iconSrc={help}></HelperModal>
                            </div>
                            <div className="col-md-4" id="animationPlay" style={{display: 'none'}}>
                                <img src={stop}  style={{width: 20}} />
                            </div>
                            <div className="col-md-4" id="audioPlay" style={{display: 'none'}}>
                                <img src={sound_on}  style={{width: 20}} />
                            </div>
                            <div className="col-md-4" id="fullScreenIcon" style={{display: 'none'}}>
                                <img src={fullscreen} style={{width: 20, marginLeft: 3}} />
                            </div> */}

                            <CombinationIcons id={props.match.params.id}></CombinationIcons>
                        </div>
                    </Col>
                </Row>
                <div id="myModal" className="modalAR">
                    <div className="modal-contentAR">
                        <span className="closeAR">&times;</span>
                        <div className="modal-bodyAR">
                            <p className="modal-bodyAR-p" style={{color: 'black'}}></p>
                        </div>
                    </div>
                </div>
                <div id="modalAnnotation" className="modalAnnotation">
                    <div className="modal-contentAnnotation">
                        <span className="closeAnnotation">&times;</span>
                        <div className="modal-bodyAnnotation" id="modal-body-annotation">
                            
                        </div>
                    </div>
                </div>
                <div id="modal3DViewer" className="modal3DViewer"  style={{width: 90 + '%', margin: 'auto', display: 'none'}}>
                    <div className="" style={{width: 100 + '%', height: 95 + '%', margin: 'auto'}}>
                        <span id="close3DViewer" style={{fontSize: 30, float: 'left', cursor: 'pointer'}}>&times;</span>
                        <div className="" id="modal-3d-viewer" style={{height: 95 + '%'}}>
                            <iframe width={100+'%'} height={100+'%'}/>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

const calculateMousePosition = (e, isMobile) =>
{
    let x, y;
    if(isMobile){
        if(e.changedTouches != undefined){
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        }
    }else{
        x = e.clientX;
        y = e.clientY;
    }
    mousePosition.x = (x / document.getElementById('canvas').clientWidth) * 2 - 1;
    mousePosition.y = -(y / document.getElementById('canvas').clientHeight) * 2 + 1;
}

const onPointerWheel = (e, api, props) => {
    mouseWheelTime = Date.now();
    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            // api.stopAllSounds();
            api.playAllSounds();
        }
    }
}

const onMoveCameraByArrowKey  = (e, api, isMobile, userInfo) => {
    let p = api.getCameraParams();
    const position = {x: p.position[0], y: p.position[1], z: p.position[2]};
    switch (e.key) {
        case "ArrowLeft":
            // Left pressed
            position.z += 0.01;
            break;
        case "ArrowRight":
            // Right pressed
            position.z -= 0.01;
            break;
        case "ArrowUp":
            // Up pressed
            position.z += 0.01;
            break;
        case "ArrowDown":
            // Down pressed
            position.z -= 0.01;
            break;
    }
    p.position = [position.x, position.y, position.z];
    // api.setCameraParams(p);
}

/**
 * Set mouse position
 * @param e
 */
const onPointerMove = (e, api, isMobile, userInfo) => {
    calculateMousePosition(e, isMobile);
    const hitLogo = api.hitLogo(mousePosition);
    if(hitLogo) 
    {
        document.body.style.cursor = "pointer";
        
        if(userInfo.isShowLinkToolTip)
        {
            var tooltipSpan = document.getElementById('tooltip-span');
            if(tooltipSpan)
            {
                tooltipSpan.style.display = "block";
                tooltipSpan.style.top = (mousePosition.y + 20) + 'px';
                tooltipSpan.style.left = (mousePosition.x + 20) + 'px';
            }
        }
    }
    else
    {
        document.body.style.cursor = "default";
        var tooltipSpan = document.getElementById('tooltip-span');
        if(tooltipSpan)
        {
            tooltipSpan.style.display = "none";
            tooltipSpan.style.top = 0 + 'px';
            tooltipSpan.style.left = 0 + 'px';
        }
    }

}

/**
 * On mouse up event, will highlight on UI objects that use the material of the clicked object
 * @param e
 * @param api
 * @param props
 */
const onPointerUp = (e, api, props, isMobile, userInfo) => {
    document.getElementById("annotationIcon").classList.remove("canvasActive");
    document.getElementById("autoRotateIcon").classList.remove("canvasActive");
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("canvas").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    calculateMousePosition(e, isMobile);
    mouseWheelTime = Date.now();
    lastMouseDownTime = mouseDownTime;
    let delta = Date.now() - mouseDownTime;
    mouseDownTime = 0;
    if (delta > 200) return;
    
    if(userInfo.isShowLinkToolTip)
    {
        const hitLogo = api.hitLogo(mousePosition);
        if(hitLogo) window.open('https://www.cadgl.com/subscription-packages ', '_blank');
        console.log(e.target.id);
    }

    
    setTimeout(() => {
        var modal3DViewer = document.getElementById("modal3DViewer");
        modal3DViewer.style.display = "none";
        document.getElementById('iframe-main-content').style.opacity = "100%";
    }, 1);

    
    //ANNOTATIONS
    let annotation = api.hitAnnotations(mousePosition, true);
    if(annotation == draggingAnnotation) {
        draggingAnnotation = null;
        api.setCameraParams({mode: "orbit"});
    }
    if(annotation)
    {

        // api.animateCamera(annotation.cameraState);
        // // let a = new Vector2();
        // // a.x = annotation.screenPosition.x;
        // // a.y = annotation.screenPosition.y;
        // // api.hitAnnotations(a, true);

        // var modalAnnotation = document.getElementById("modalAnnotation");
        // modalAnnotation.style.display = "block";
        // document.getElementById("modal-body-annotation").innerHTML = annotation.text;

        // // Get the <span> element that closes the modal
        // var closeAnnotation = document.getElementsByClassName("closeAnnotation")[0];
        // // When the user clicks on <span> (x), close the modal
        // closeAnnotation.onclick = function() {
        //     modalAnnotation.style.display = "none";
        //     // api.resetCamera();
        // }

        // // When the user clicks anywhere outside of the modal, close it
        // window.onclick = function(event) {
        //     if (event.target == modalAnnotation) {
        //         modalAnnotation.style.display = "none";
        //         // api.resetCamera();
        //     }
        // }

        
        commonFunction.renderAnnotationModalEvent(api, annotation);
    }else{
    }
}

const onPointerUp2 = (e, api, props, isMobile, userInfo) => {
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("canvas").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    
    if(userInfo.isShowLinkToolTip)
    {
        window.open('https://www.cadgl.com/subscription-packages', '_blank');
        console.log(e.target.id);
    }

}

const onPointerDown = (e, api, props) => {

    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            // api.stopAllSounds();
            api.playAllSounds();
        }
    }
    document.getElementById("annotationIcon").classList.add("canvasActive");
    document.getElementById("autoRotateIcon").classList.add("canvasActive");
    document.getElementById("helperIcon").classList.add("canvasActive");
    document.getElementById("audioPlay").classList.add("canvasActive");
    document.getElementById("animationPlay").classList.add("canvasActive");
    document.getElementById("canvas").classList.add("canvasActive");
    document.getElementById("fullScreenIcon").classList.add("canvasActive");
    document.getElementById("swipeIcon").style.display = "none";
    mouseDownTime = Date.now();
    const now = Date.now();
    if(now - lastMouseDownTime < 300){
        lastMouseDownTime = 0;
        //double click
        if(enableCameraDoubleClick === true || enableCameraDoubleClick === "true"){
            api.animateCameraTarget(mousePosition, 1.2, 6, 1.7);
        }
        return
    }

    let annotation = api.hitAnnotations(mousePosition, true);
    if(!annotation){
        commonFunction.setNullIframeSrc();
    }
}


const onPointerDownDouble = (e, api, props) => {
    // api.animateCameraTarget(mousePosition, 1.2, 6, 1.1);
}

const mapStateToProps = (state) => {
    return {
        iframe: state.iframe,
        combinations: state.combinations,
    }
}

export default withRouter(connect(mapStateToProps, null)(ProductRenderer))