import React, {Component} from "react";
import Navbar from "../../components/Navbar/Navbar";
import "../Tracker/Tracker.css";
import {Col, Row} from "react-bootstrap";
import SidebarContainer from "../../containers/Tracker/SidebarContainer";
import Trackers from "../Tracker/Trackers/Trackers";
import { CSVLink } from "react-csv";
import {SpinnerSmall} from '../../helpers/spinners';

class Tracker extends Component {
    state = {
        csvData: [{data: null}]
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let csvData = [{data: null}]
        if (this.props.tracker.trackers.length !== 0) {
            csvData = this.props.tracker.trackers.map((item) => {
                let data = {}
                item.data.forEach(item => {
                    data[item.name] = item.value
                })
                const name = {"Account Name": item.name}
                const url = {URL: item.url}

                return(
                    {...name, ...url, ...data}
                )
            })
        }

        if (prevProps.tracker.trackers !== this.props.tracker.trackers && this.props.tracker.trackers.length !== 0) {
            this.setState({
                csvData: csvData
            })
        }
    }

    render() {
        return (
            <div className="Tracker">
                <Navbar />
                <Row>
                    <Col className="sidebar" md={{ span: 2 }}>
                        <SidebarContainer availableFilters={this.props.tracker.availableFilters}/>
                    </Col>
                    <Col className="trackers" md={{ span: 10 }}>
                        {
                            this.props.tracker.trackers.length !== 0 ?
                                <Row className="trackers-header">
                                    <Col md={{ span: 1}}>
                                        <p>View Trackers</p>
                                    </Col>
                                    <Col md={{ span: 2, offset: 9}}>
                                        <CSVLink data={this.state.csvData} headers={this.props.listColumn} className="btn btn-success export-button" >Export CSV</CSVLink>
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={{ span: 12}} style={{overflowX: 'auto'}}>
                                {
                                    this.props.tracker.loadingReport ? <SpinnerSmall /> : ''
                                }
                                {
                                    this.props.tracker.hasErrorReport ? this.props.tracker.errorReport : ''
                                }
                                {
                                    this.props.tracker.trackers.length !== 0 ?  <Trackers trackers={this.props.tracker.trackers} listColumn={this.props.tracker.listColumn}/> : ''
                                }
                                {
                                    this.props.tracker.trackers.length === 0 && !this.props.hasErrorReport ? ' No records found !' : ''
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Tracker;