import React, {Component} from "react";
import { connect } from 'react-redux';
import {Table, Col, Row, Spinner, Button} from "react-bootstrap";
import Combination from "./Combination";
import './Combinations.css';
import './Controls.css';
import {Link} from "react-router-dom";
import axios from '../../../axios';
import {FileLoader, Vector2, TextureLoader, DefaultLoadingManager} from "three";
import {
    getTransactions
} from "../../../actions/actionsCreators";
import jwt_decode from "jwt-decode";

class Transactions extends Component {
    state = {
        userInfo: '',
        deleting: false
    }

    /**
     * Will set to false all embedded and widgets checkboxes so that we can track which one is checked
     */
    componentDidMount() {
        
    }


    render() {
        let combinations = ''
        let loading = this.props.combinations.loading

        if (!loading) {
            combinations = (
                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th>Payment ID</th>
                                        <th>Price</th>
                                        <th>Combination Name</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.transactions.map((transaction, i) => {
                                                return    <tr>
                                                        <td>
                                                            {
                                                                transaction.paymentID
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                transaction.price + '$'
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                transaction.combinationName
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                transaction.transactionDate
                                                            }
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                    </tbody>
                </Table>
            )
        }

        if (loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }



        return (
            combinations
        )
    }
}

const mapStateToProps = state => {
    console.log(state.combinations);
    return {
        combinations: state.combinations
    }
}
const mapDispatchToProps = {
    getTransactions: getTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);