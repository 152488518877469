import React from "react";
import {Col, Row} from "react-bootstrap";
import arrowUp from '../../../../assets/arrow-up@2x.png';
import arrowDown from '../../../../assets/down-arrow@2x.png';

function MenuGroup(props) {
    let className = "settings-tab-title";
    if(props.collapsed == 1)
    {
        className = "settings-tab-title settings-selected";
    }
    return (
        <React.Fragment>
            <Row {...props}>
                <Col md={12} className={className}>
                    <span title={props.title}>{props.name}</span>
                    {
                        props.collapsed
                            ?
                            <img src={arrowDown} alt=""/>
                            :
                            <img src={arrowUp} alt=""/>
                    }
                </Col>
            </Row>
            {props.children}
        </React.Fragment>
    )
}

export default MenuGroup