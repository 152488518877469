import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    sceneInfoAlert,
} from "../../../actions/actionsCreators";
import closeIcon from "../../../assets/close-icon@2x.png";
import Combinations from "./Combinations";
import AddCombinationModal from "./AddCombinationModal";

class AddCombinationSceneInfoAlertModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    sceneInfoAlert = () => {
        this.handleShow()
        this.props.sceneInfoAlert()
    }

    renderText = (text) => {
        switch(text){
            case "High":
                return (
                    <p style={{color: 'red', fontSize: 16, fontWeight: "bold", marginBottom: 0}}>{text}</p>
                )
            case "Low":
                return (
                    <p style={{color: 'Green', fontSize: 16, fontWeight: "bold", marginBottom: 0}}>{text}</p>
                )
            case "Medium":
                return (
                    <p style={{color: 'Yellow', fontSize: 16, fontWeight: "bold", marginBottom: 0}}>{text}</p>
                )
        }
    }

    renderValue = (text) => {
        switch(text){
            case "High":
                return 'red';
            case "Low":
                return 'green';
            case "Medium":
                return 'yellow';
        }
    }

    renderClass = (text) => {
        switch(text){
            case "High":
                return 'scene-info-item scene-info-item-high';
            case "Low":
                return 'scene-info-item scene-info-item-low';
            case "Medium":
                return 'scene-info-item scene-info-item-medium';
        }
    }

    closeParentPopup = () => {
        this.handleClose();
    }

    convertToMb = (value, isByteValue) => {
        if(isByteValue)
            return (value/1000000).toFixed(1);
        return value;
    }

    render() {
        let combinations = ''
        let loading = this.props.combinations.loading
        let hasError = this.props.combinations.hasError
        let infos = this.props.combinations.infos
        let sceneInfos = this.props.combinations.sceneInfos

        if (!loading && !hasError && sceneInfos) {
            combinations = (
                <>
                    {
                        sceneInfos.map((sceneInfo, i) => {
                            return (
                                <Row className=
                                    {
                                        this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) >= sceneInfo.high ? this.renderClass('High') : 
                                        (this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) <= sceneInfo.low ? this.renderClass('Low') : this.renderClass('Medium'))
                                    }
                                 style={{color: 'white', backgroundColor: 'grey', padding: 20, margin: 'auto', borderRadius: 5, marginBottom: 10}}>
                                    <Col md={2} style={{margin: 'auto', paddingRight: 35}}>
                                        {
                                            this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) >= sceneInfo.high ? this.renderText('High') : 
                                            (this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) <= sceneInfo.low ? this.renderText('Low') : this.renderText('Medium'))
                                        }
                                    </Col>
                                    <Col md={1} style={{maxWidth: 1, width: 1, backgroundColor: 'black', padding: 1}}></Col>
                                    <Col md={8} style={{margin: 'auto'}}>
                                        <p style={{textAlign: 'left'}}>{sceneInfo.text}: <span style={{color: 
                                            this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) >= sceneInfo.high ? this.renderValue('High') : 
                                            (this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) <= sceneInfo.low ? this.renderValue('Low') : this.renderValue('Medium'))
                                            
                                            }}>{sceneInfo.isByteValue ? this.convertToMb(infos[sceneInfo.param], sceneInfo.isByteValue) : infos[sceneInfo.param].toLocaleString()} {sceneInfo.isByteValue ? 'MB' : ''}</span></p>
                                        <p style={{textAlign: 'left'}}>{sceneInfo.desc}</p>
                                        <p style={{textAlign: 'left'}}><a href={sceneInfo.link} target="_blank">Learn More</a></p>
                                    </Col> 
                                </Row>
                            )
                        })
                    }
                </>
            )

        }

        return (
            <React.Fragment>
                <button style={{backgroundColor: 'grey'}}  className="add-combination-btn btn btn-primary" onClick={this.sceneInfoAlert} disabled={this.props.combinations.loading}>CREATE</button>
                <Modal id="combination-scene-info-alert" className="combination-scene-info-alert" size="xl" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>CadGL - Performance Check</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {combinations}
                    </Modal.Body>
                    <Modal.Footer>
                        <AddCombinationModal closeParentPopup= {this.closeParentPopup} />
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    sceneInfoAlert: sceneInfoAlert
}

const mapStateToProps = state => {
    return {
        combinations: state.combinations
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCombinationSceneInfoAlertModal)