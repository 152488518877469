import {
    REQUEST_PRESETS_FAILED,
    REQUEST_PRESETS_SUCCESS,
    DELETED_PRESET_FOLDER,
    DELETED_PRESET,
    EDIT_PRESET,
    SAVED_PRESET,
    SAVED_PRESET_FOLDER,
    SELECT_OBJECT,
    SELECT_MESH
} from "../actions/actionTypes";

const initialState = {
    presets: [],
    error: '',
    hasError: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PRESETS_SUCCESS:
            return {
                ...state,
                ...action.newState
            }
        case REQUEST_PRESETS_FAILED:
            return {
                ...state,
                ...action.newState
            }
        case DELETED_PRESET_FOLDER:
            return {
                ...state,
                presets: action.presets
            }
        case DELETED_PRESET:
        case EDIT_PRESET:
        case SAVED_PRESET:
        case SAVED_PRESET_FOLDER:
            return {
                ...state,
                presets: action.presets
            }
        case SELECT_OBJECT:
            return {
                ...state,
                pickedObject: action.material
            }
        case SELECT_MESH:
            return {
                ...state,
                pickedObject: action.material
            }
        default:
            return state;
    }
}

export default reducer;