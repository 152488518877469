import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Spinner, Form} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    editPreset,
} from "../../../../actions/actionsCreators";
import trashIcon from "../../../../assets/trash@2x.png";
import brushIcon from "../../../../assets/rename.png";
import closeIcon from "../../../../assets/close-icon@2x.png";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';
let lang = {};
class EditPresetModal extends Component {

    componentDidMount() {
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }

    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        folderName: this.props.folderName,
        price: this.props.price
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    handleChange = e => {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    /**
     * Send request to delete preset folder also remove it from state
     */

    savingPreset(label = null){

        axios.post('preset/edit-preset', {
            "presetFolderId": this.props.uuidPresetFolder,
            "presetId": this.props.uuidPreset,
            "name": this.state.folderName,
            "label": label,
            "price": this.state.price}).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.props.editPreset(this.props.uuid)
            }

            this.setState({
                show: false
            })
        }).catch(err => {
            this.setState({
                hasError: true,
                error: 'Error occurred, please contact support!',
                loading: false
            })
        })
    }

    editPreset = () => {
        this.setState({loading: true})

        if(document.getElementById("label").files[0] == undefined){
            this.savingPreset();
        }else{
            let materialLabelData = new FormData();
            materialLabelData.append('label', document.getElementById("label").files[0]);
            
            axios.post('preset/upload-material-label-img', materialLabelData
            ).then(response => {
                let label = response.data.data;
                console.log(label);
                this.savingPreset(label);
            });
        }

    }

    render() {
        return (
            <React.Fragment>
                <img className="trash-icon material-gallery-icon" src={brushIcon} alt="" onClick={this.handleShow}/>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>{lang.rename_material}</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body id="rename-material-modal-body">
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                                <span>{lang.enter_new_material_name}</span>
                            </Col>
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <Spinner animation="border" role="status" variant="primary">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={5} style={{padding: 0, color: 'white', textAlign: 'right'}}>
                                <label>Name: </label>
                            </Col>
                            <Col md={7} >
                                <input className="name-input" name="folderName" type="text" value={this.state.folderName} onChange={this.handleChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} style={{padding: 0, color: 'white', textAlign: 'right'}}>
                                <label>Price Multiplier: </label>
                            </Col>
                            <Col md={7} >
                                <input className="name-input" name="price" type="text" value={this.state.price} onChange={this.handleChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} style={{padding: 0, color: 'white', textAlign: 'right'}}>
                                <label>Upload Label: </label>
                            </Col>
                            <Col md={7} style={{color: 'white'}}>
                                <input id="label" style={{paddingLeft: 6}} type="file"  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} style={{padding: 0, color: 'white', textAlign: 'right'}}>
                            </Col>
                            <Col md={7} >
                                <Button style={{marginLeft: 5, width: 160}} variant="primary"  onClick={this.editPreset} disabled={this.state.loading}>
                                    {lang.update}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    editPreset: editPreset,
}

export default connect(null, mapDispatchToProps)(EditPresetModal)