import React, {useEffect} from "react";
import {Row, Col} from "react-bootstrap";
import {FileLoader, Vector2, TextureLoader, RepeatWrapping, Color, MathUtils, Vector3} from "three";
import { connect } from 'react-redux';
import {saveSceneConfigToState, selectObject, loadPresets, loadCombinations, selectAnnotations, changeCameraPositionSetting} from "../../../actions/actionsCreators";
import {switchCases} from "./switchCases";
import jwt_decode from "jwt-decode";
import swipe from '../../../assets/swipe360.png';
import HelperModal from "../Combinations/HelperModal";
import First3DMModal from "../Combinations/First3DMModal";
import FirstGlbModal from "../Combinations/FirstGlbModal";
import WelcomeModal from "../Combinations/WelcomeModal";
import fullscreen from '../../../assets/fullscreen.png';
import axios from '../../../axios';
import { getGPUTier } from 'detect-gpu';
// const Viewer = require("../../../cadcentre/dist/cadcenter");
const Viewer = window.CADEDITOR.icad;

let mousePosition = new Vector2(0, 0)
let mouseDownTime = 0
let lastMouseDownTime = 0;
let userActive = 0

let draggingAnnotation = null;
let _logoVisibility = 1;

// Duration is in seconds
let logoFadeInDuration = 1.0;
let logoFadeOutDuration = 1.0;
let logoStayDuration = 1.0;
let logoMinActionTime = 0.5; // 500 ms. Min time to check.
let _logoTweener;
let _logoState =  'startVisible';
let outlineFadeTime = 1;
let outlineVisibleTime = 4;
let outlineEdgeStrength = 1.0;
let mouseWheelTime = 0;
let userInfo = null;
let userObj = null;


function ProductRenderer(props) {
    const canvas = React.createRef()
    

    useEffect(() => {
        let uploadedFiles = props.files ? props.files : null //Will hold filed coming from upload, if there aren't any then will load default scene.json
        
        var impersonate = window.location.search.split('impersonate=')[1];
        if(impersonate){
            axios.get('user/get-impersonate?uuid='+impersonate).then(response => {
                let {data} = response;
                if(data.success)
                {
                    let userTmp = data.data;
                    let token = userTmp.token;
                    localStorage.setItem('user', JSON.stringify(token));
                    var today = new Date();
                    localStorage.setItem('user-active', today.setHours(today.getHours() + 4));
                    document.location.href="/";
                }
            });
        }

        //Check browser disable cookie
        var cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){ 
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
        }
        
        let token = null;
        if(cookieEnabled){
            token = localStorage.getItem('user');
        }
        if(token)
        {
            setInterval(() => {
                let mustLogOut = false;
                if(localStorage.getItem('user-active')){
                    let dateNeedChecked = localStorage.getItem('user-active');
                    if(dateNeedChecked <= new Date().setHours(new Date().getHours() + 0)){
                        mustLogOut = true;
                    }
                }else{
                    mustLogOut = true;
                }
                if(mustLogOut){
                    localStorage.removeItem("user");
                    localStorage.removeItem("user-active");
                    window.location.replace("/");
                }
            }, 2000);setInterval(() => {
                axios.get('user/get-user-info').then(response => {
                    const user = response.data.data;
                    if(
                        (user.role && (user.role == "subscriber" || user.role == "admin"))
                    ){

                    }else{
                        localStorage.removeItem("user");
                        localStorage.removeItem("user-active");
                        window.location.replace("/");
                    }
                }).catch(error => {
                    if(error.response.status == 401){
                        localStorage.removeItem("user");
                        localStorage.removeItem("user-active");
                        window.location.replace("/");
                    }
                });
            }, 10000);
            axios.get('user/get-user-info').then(response => {
                let {data} = response;
                if(data.success)
                {
                    let userTmp = data.data;
                    userObj = data.data;
                    if(userTmp.isActive == false)
                    {
                        localStorage.removeItem("user");
                        document.location.reload();
                    }
                    if(userTmp.lang){
                        localStorage.setItem("lang", userTmp.lang);
                    }
                }
            });
        }
        axios.get('user/get-user-permission').then(response => {
            let {data} = response
            if (data.success) {
                userInfo = data.data;
                if(userInfo.isActive == false)
                {
                    //Clear LocalStorage

                }
                let canvas1 = canvas.current;
                //Create API instance
                const api = new Viewer.API({
                    canvas: canvas1,
                    gemConfig: {
                        quality: 'high',
                        diamondClass: [{
                            name: "diamond",
                            color: 0xaaaaaa
                        }]
                    },
                    annotationIconParams: {
                        maxAnnotations: 10,
                        texture: userInfo.annotationUrl ? userInfo.annotationUrl : "https://cadgl.net/icons_cadgl/annotations.png",
                        columns: 10,
                        size: new Vector2(48, 48),
                        padding: 2,
                        // annotationHiddenAlpha: 0.3,
                        // annotationHighlightTint: "#aaaaff",
                        // annotationTint: "#ffffff"
                    },
                    logoParams: {
                        opacity: 1,
                        enabled: userInfo ? userInfo.isAllowWatermarkLogo : true,
                        image: userInfo ? (userInfo.logoUrl ?? "https://cadgl.net/editor/images/logo_editor.png") : "https://cadgl.net/editor/images/logo_editor.png",
                        height: 0,
                        width: 0,
                        positionY: userInfo ? (userInfo.logoPositionY ?? 0) : 0,
                        positionX: userInfo ? (userInfo.logoPositionX ?? 0) : 0,
                        scale: 1,
                        logoStayDuration: userInfo ? (userInfo.logoStayDuration ?? 5) : 5,
                        logoFadeInDuration: userInfo ? (userInfo.logoFadeInDuration ?? 0) : 0,
                        logoFadeOutDuration: userInfo ? (userInfo.logoFadeOutDuration ?? 0) : 0
                    },
                    animationLoopCallback : ()=> animationLoop()
                });
        
                //api.setViewerSize(canvas1.clientWidth, canvas1.clientHeight);
        
                let xrParams = api.getXRParams();
                xrParams.enabled = false;
                api.setXRParams(xrParams);
                api.play() //Render 3D model on canvas
                
        
                let checkAR = setInterval(() => {
        
                    let xrParams = api.getXRParams();
                    if (navigator.platform == 'iPhone') { // <-- Use the one here above
                        
                        if ('xr' in navigator)
                        {
                            // if(!user.isAllowAR)
                            // {
                            //     xrParams.enabled = false;
                            // }
                            // api.setXRParams(xrParams);
                        }
                        else
                        {
                            var mySpans = document.getElementsByTagName('a');
                            var arBut;
                            for(var i=0;i<mySpans.length;i++){
        
                                if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
        
                                arBut = mySpans[i];
                                break;
        
                            }
        
                            }
                            arBut.innerText = 'START AR';
                            arBut.removeAttribute("href");
                            clearInterval(checkAR);
        
                            // Get the modal
                            var modal = document.getElementById("myModal");
        
                            // Get the button that opens the modal
                            var btn = arBut;
        
                            // Get the <span> element that closes the modal
                            var span = document.getElementsByClassName("closeAR")[0];
        
                            // When the user clicks on the button, open the modal
                            btn.onclick = function() {
                            modal.style.display = "block";
                            }
        
                            // When the user clicks on <span> (x), close the modal
                            span.onclick = function() {
                            modal.style.display = "none";
                            }
        
                            // When the user clicks anywhere outside of the modal, close it
                            window.onclick = function(event) {
                            if (event.target == modal) {
                                modal.style.display = "none";
                            }
                            }
        
                        }
                    }
                    else
                    {
                        var element =  document.getElementById('ARButton');
                        if(element != null)
                        {
                            var txt = element.textContent || element.innerText;
                            if(txt == 'AR NOT SUPPORTED')
                            {
                                element.style.display = "none";
                            }
                        }
                        var mySpans = document.getElementsByTagName('a');
                        var arBut;
                        for(var i=0;i<mySpans.length;i++){
        
                            if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                arBut = mySpans[i];
                                break;
                            }
                        }
                        if(arBut){
                            arBut.style.display = "none";
                        }
                    }
                    clearInterval(checkAR);
                },  500);
        
                function setDefaultRatio()
                {
                    console.log("window.devicePixelRatio)", window.devicePixelRatio);
                    api.setDisplayPixelRatio(window.devicePixelRatio);
                    api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
                    api.setAnnotationWidth(60 * 1920 / Math.max(window.innerWidth, window.innerHeight));
                    //api.setSSRParams({enabled: false});
                }
                setDefaultRatio();
            
                function _tweenLogo(x, finalState, duration = 0.5){
                    if(_logoTweener) _logoTweener.stop();
                    const fState = finalState;
                    _logoTweener = api.getTweener({l: _logoVisibility}).to({l: x}, duration*1000).onUpdate((o)=>{
                        if (Math.abs(_logoVisibility-o.l) < 0.0001) return;
                        _logoVisibility = o.l;
                        let p = api.getLogoParams();
                        p.opacity = _logoVisibility * _logoVisibility;
                        api.setLogoParams(p);
                    }).onComplete(()=>{
                        _logoVisibility = x;
                        _logoTweener = undefined;
                        _logoState = fState;
                    })
                        //.easing(TWEEN.Easing.Quadratic.InOut)
                        .start();
                    return _logoTweener;
                }
            
                function animationLoop(){
        
                    let logoParams = api.getLogoParams();
                    const time = Date.now();
                    const mmax = logoMinActionTime * 1000;
            
                    if(( mouseDownTime > 1 && time-mouseDownTime > mmax ) || ( time-mouseWheelTime < 500 ) ){
                        // show if hidden. stop if hiding and show. do nothing if showing or visible
                        if(_logoState === 'hidden' || _logoState === 'hiding'){
                            _logoVisibility = Math.max(0.002, _logoVisibility);
                            _tweenLogo(1, 'startVisible', (1-_logoVisibility) *logoParams.logoFadeInDuration);
                            _logoState = 'showing';
                        }
                    }else {
                        // hide if visible. stop if showing and hide. do nothing if hiding or hidden
                        if(_logoState === 'startVisible'){
                            // this._logoVisibility = this._logoVisibility;
                            _tweenLogo(_logoVisibility, 'startHiding',logoParams.logoStayDuration-0.002);
                            _logoState = 'visible';
                        }
                        if(_logoState === 'startHiding'){
                            _logoVisibility = Math.min(0.998, _logoVisibility);
                            _tweenLogo(0, 'hidden',(_logoVisibility) * logoParams.logoFadeOutDuration);
                            _logoState = 'hiding';
                        }
                    }
                }
        
                props.changeSidebarHeight(canvas.current.clientHeight) //Adjust sidebar height to match rendered canvas
        
                if (uploadedFiles) {

                    let logoParams = api.getLogoParams();
                    logoParams.logoStayDuration = 60;
                    api.setLogoParams(logoParams);

                    setInterval(function(){
                        let logoParams = api.getLogoParams();
                        console.log("logoParams: ", logoParams);
                    }, 3000);
                    
                    //Import model
                    document.getElementById("myLoader").style.display = "block";
                    document.getElementById("canvas").style.display = "none";
                        
                    setTimeout(() => {
                        if(!userObj.defaultSceneEnv || userObj.defaultSceneEnv == undefined){
                            api.loadTexture("sk_blurry_640.hdr", event => console.log(event), {}).then(value => {
                                    api.sceneManager.setSceneEnvironment(value, {imageBasedLighting: true, setBackground: false, setDiamondEnv: true});
                            })
                        }else{
                            api.loadTexture(userObj.defaultSceneEnv.path, 
                                event => console.log(event), {}).then(value => {
                                    console.log(value);
                                    api.sceneManager.setSceneEnvironment
                                    (value, {imageBasedLighting: true, setBackground: false, setDiamondEnv: true, setEnvironment: true});
                            })
                            api.exposure = Number(userObj.defaultSceneEnv.default_env_exposure);
                            api.toneMappingSaturation = Number(userObj.defaultSceneEnv.default_env_saturation);
                            api.toneMappingContrast = Number(userObj.defaultSceneEnv.default_env_contrast);
                            api.setEnvMapRotation(Number(userObj.defaultSceneEnv.default_env_rotation));
                        }
                        if(!userObj.defaultBackground || userObj.defaultBackground == undefined){
                            api.setBackground("#C0C8CC")
                        }else{
                            if(userObj.defaultBackground.type == "color"){
                                var hex    = userObj.defaultBackground.color.replace(/^\#/, '');
                                var length = hex.length;
                                api.setBackground(userObj.defaultBackground.color);
                            }else{
                                getFileObject(userObj.defaultBackground.path, function (fileObject) {
                                    api.setBackgroundFile(fileObject, {setEnvironment: false, setBackground: true})
                                })
                            }
                        }
                        if(userObj.defaultDiamondEnv){
                            api.loadTexture(userObj.defaultDiamondEnv.path, 
                                event => console.log(event), {}).then(value => {
                                     api.sceneManager.setDiamondEnvironment
                                     (value, 
                                        {
                                            imageBasedLighting: true, 
                                            setBackground: false, 
                                            setDiamondEnv: true, 
                                            setEnvironment: false
                                        });
                            })
                        }

                        api.importModelFiles(uploadedFiles.files).then(result => {
                            setTimeout(() => {
                                document.getElementById("myLoader").style.display = "none";
                                document.getElementById("canvas").style.display = "block";
                                api.exportScene().then(response => {
                                    props.saveSceneConfigToState(response, api, 'files') //save json to state and also api object so that we can call later all api's on component update
                
                                    let groundShadow = response.config.groundShadow;
                                    groundShadow.falloff = 2.5;
                                    api.setGroundShadowParams(groundShadow);

                                    document.getElementById("fullScreenIcon").style.display = "block";
                                    document.getElementById("helperIcon").style.display = "block";
                                    
                                });
                                //Set background default image
                                // getFileObject('./bg_new.jpg', function (fileObject) {
                                //     api.setBackgroundFile(fileObject, {setEnvironment: false, setBackground: true})
                                // })
                                
                
                                //Set default background color to white
                
                                api.setSSRParams({enabled: false});
                                // api.enableTAA = false;
                                let animationSet = api.getModelAnimation();
                                console.log(animationSet);

                                //disable TAA
                                let defaultTAA = api.getTAAParams();
                                defaultTAA.enabled = false;
                                api.setTAAParams(defaultTAA);
                                //disable SSAO
                                api.setSSAOParams({enabled: false})
                                
                                let xrParams = api.getXRParams();
                                xrParams.enabled = false;
                                api.setXRParams(xrParams);


                                api.setCameraParams({orbitParams: {zoomSpeed: 0.5, minDistance: 0.0001, dampingFactor: 0.15}})
                                // api.setCameraNearFarMultiplier(500);
                                api.setMinCameraNearClip(0.1);
                                api.setCameraParams({zoom: 1, fov: 20});
                                
                                api.toneMappingContrast = 1.04;
                                api.setSharpenIntensity(0.1);


                                // let logoParams = api.getLogoParams();
                                // console.log("logoParams: ", logoParams);
                                // logoParams.logoStayDuration = 60;
                                // api.setLogoParams(logoParams);

                            }, 1000);
                        });
                        //Load and set default texture, if not set the model will be all black
                        /*new TextureLoader().load('./defaultTexture.png', response => {
                            api.setSceneEnvironment(response, {
                                setEnvironment: true,
                                setDiamondEnv: false,
                                setBackground: false,
                                imageBasedLighting: true
                            })
                        });*/


                        //
                    }, 1000);
                    
                    setTimeout(() => {
                        let materialsLibrary = api.getMaterialLibrary();
                        console.log(typeof materialsLibrary);
                        for (const [key, value] of Object.entries(materialsLibrary)) {
                            for(let mesh of value.mesh){
                                let pickedObject = mesh ?? null;
                                if(pickedObject){
                                    if(!value.params.isDiamond && !value.params.isRefraction){
                                        api.setMaterialParams(pickedObject, {side: 'double'})
                                    }
                                }
                            }
                        }
                    }, 4000);
        
                } else {
                    document.getElementById("myLoader").style.display = "block";
                    document.getElementById("canvas").style.display = "none";
                    // getFileObject('./background_app_1800x1200_light.jpg', function (fileObject) {
                    //     api.setBackgroundFile(fileObject, {setEnvironment: false, setBackground: true})
                    // })
                    new FileLoader().load("./startup.json", (response) => {
                        api.importScene(JSON.parse(response)).then(() => {
                            document.getElementById("myLoader").style.display = "none";
                            document.getElementById("canvas").style.display = "block";
                            props.saveSceneConfigToState(JSON.parse(response), api, 'scene') 
                            //Save json to state and also api object so that we can call later all api's on component update
                        });
                    })
                }

                
                //disable Gizmo by default
                let gizmoParams = api.getTransformControlParams();
                gizmoParams.enabled = false;
                api.setTransformControlParams(gizmoParams);




        
                //Load presets from server
                props.loadPresets()
                //load combinations
                //props.loadCombinations()
        
                //Setting mouse and touch events on canvas
                document.getElementById('canvas').addEventListener('mousemove', (e) => onPointerMove(e, api, userInfo, props));
                document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerMove(e, api, userInfo, props));
                document.getElementById('tooltip-span').addEventListener('mouseup', (e) => onPointerUp2(e, api, props, false, userInfo));
                document.getElementById('canvas').addEventListener('mouseup', (e) => onPointerUp(e, api, props, userInfo));
                document.getElementById('canvas').addEventListener('touchend', (e) => onPointerUp(e, api, props, userInfo));
                document.getElementById('canvas').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                document.getElementById('canvas').addEventListener('touchstart', (e) => onPointerDown(e, api, props));
                document.getElementById('canvas').addEventListener('wheel', (e) => onPointerWheel(e, api, props));
                document.getElementById('canvas').addEventListener('keyup', (e) => onKeyUp(e, api, props));

                window.addEventListener("resize", ev => {
                    // api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
                    setDefaultRatio();
                }, false);
                document.getElementById('canvas').addEventListener('dblclick', (e) => onPointerDownDouble(e, api, props));


                document.getElementById('fullScreenIcon').addEventListener('click', function(e) {
                    var elem = document.documentElement;
                    if (elem.requestFullscreen) {
                      elem.requestFullscreen();
                    } else if (elem.webkitRequestFullscreen) { /* Safari */
                      elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) { /* IE11 */
                      elem.msRequestFullscreen();
                    }
                    document.getElementById("configurator-sidebar").style.height = "900px";
                    document.getElementById("configurator-sidebar").style.backgroundColor  = "#343c42 !important";
                    // document.getElementById("configurator-sidebar").style.height = document.body.clientHeight + 500;
                });
                let originalCanvasHeight = document.getElementById("configurator-sidebar").offsetHeight ;
                function exitHandler()
                {
                    console.log("originalCanvasHeight: " + originalCanvasHeight);
                    var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
                    if(fullscreenElement == null){
                        let a = originalCanvasHeight + "px";
                        document.getElementById("configurator-sidebar").style.height = a;
                    }
                }
                document.addEventListener('fullscreenchange', exitHandler, false);
                document.addEventListener('mozfullscreenchange', exitHandler, false);
                document.addEventListener('MSFullscreenChange', exitHandler, false);
                document.addEventListener('webkitfullscreenchange', exitHandler, false);


                //SCREEN RECORDING
                    let 
                        mediaRecorder;
                    document.addEventListener("click", async function(e){
                        const target = e.target.closest("#startRecording"); // Or any other selector.
                        
                        if(target){
                            console.log("api.sceneManager._objectContainer", api.sceneManager._objectContainer);
                            // const gpuTier = await getGPUTier();
                            // console.log("gpu", gpuTier);
                            //let speed = api.getCameraParams().orbitParams.autoRotateSpeed;
                            let speed = document.getElementById("recordingRotateSpeed").value;
                            let axis = document.getElementById("recordingRotateAxis").value;
                            let direction = document.getElementById("recordingRotateDirection").value;
                            let diff = document.getElementById("recordingRotateDiffMilSecond").value;
                            let startRecording = false;
                            let startRotateTime, startRecordTime = null, diffTime;

                            // const canvasRec = document.getElementById("canvas");
                            let stream = 
                            //canvasRec.captureStream(); 
                            await recordScreen();
                            let mimeType = 'video/webm;codecs=h264';
                            mediaRecorder = createRecorder(stream, mimeType);

                            let timeOut = 60/speed;
                            let timeOutMilSecs = timeOut * 1000;
                            let timeOutMilSecsStop = 60/speed*1000+1;

                            console.log("getRotation", api.getRotation());
                            console.log("timeOut", timeOut);
                            console.log("timeOutMilSecs", timeOutMilSecs);
                            console.log("timeOutMilSecsStop", timeOutMilSecsStop);


                            // let obj = api.sceneManager._objectContainer;
                            // console.log("obj", obj);
                            // var startRotation = { x: 0, y: 0, z: 0 };
                            // var endRotation = { x: Math.PI * 2, y: Math.PI * 2, z: Math.PI * 2 };
                            // let tween = api.getTweener(startRotation)
                            // .to(endRotation, 1000)
                            // .onUpdate(function() {
                            //     console.log('xxxx');
                            //     obj.rotation.x = startRotation.x;
                            //     obj.rotation.y = startRotation.y;
                            //     obj.rotation.z = startRotation.z;
                            // });
                            // tween.start();
                            // tween.onComplete(() => {
                            //     //mediaRecorder.stop();
                            // });
                            
                            
                            let bb = api.sceneManager.rotateAround(new Vector3(+(axis == "x"), +(axis == "y"), +(axis == "z")), (direction == "+" ? 1 : -1) * Math.PI * 2, timeOut);
                            setTimeout(() => {
                                // bb.stop();
                                // mediaRecorder.stop();
                            }, timeOut*1000);
                            bb
                            .onStart(()=> {
                                const date = new Date();
                                startRotateTime = date;
                                const dateString = date.toISOString();
                                console.log("dateString", dateString);
                                mediaRecorder.start(1);
                            })
                            .onComplete(() => {
                                const date2 = new Date();
                                const dateString2 = date2.toISOString();
                                console.log("dateString2", dateString2);
                                setTimeout(() => {
                                    mediaRecorder.stop();
                                    console.log("diffTime", diffTime);
                                }, diffTime);
                              });
                            // let xxx =  setInterval(() => {
                            //     if(document.getElementById("isFinish").value != "100"){
                            //         // alert('zzzz');
                            //         mediaRecorder.stop();
                            //         document.getElementById("isFinish").value  = "100";
                            //         clearInterval(xxx);
                            //     }
                            // });
                            // setTimeout(() => {
                            //     api.straighten(axis, direction, timeOut);
                            //     setTimeout(() => {
                            //         api.straighten(axis, direction, timeOut);
                            //         setTimeout(() => {
                            //             api.straighten(axis, direction, timeOut);
                            //             setTimeout(() => {
                            //                 api.straighten(axis, direction, timeOut);
                            //                 setTimeout(() => {
                            //                     console.log("stop", Math.floor(Date.now() / 1000));
                            //                     startRecording = true;
                            //                     mediaRecorder.stop();
                            //                 }, timeOutMilSecs + parseInt(diff)); //speed 2 -> 90, speed 10 -> 80
                            //                 // }, timeOutMilSecs);
                            //             }, timeOutMilSecs);
                            //         }, timeOutMilSecs);
                            //     }, timeOutMilSecs);
                            // }, 0);

                            async function recordScreen() {
                                console.log("window.screen.width ", window.screen.width);
                                console.log("window.devicePixelRatio", window.devicePixelRatio);
                                console.log("window.screen.width * window.devicePixelRatio", window.screen.width * window.devicePixelRatio);
                                console.log("window.screen.height ", window.screen.height);
                                console.log("window.devicePixelRatio", window.devicePixelRatio);
                                console.log("window.screen.height * window.devicePixelRatio", window.screen.height * window.devicePixelRatio);
                                return await navigator.mediaDevices.getDisplayMedia({
                                    audio: true, 
                                    video: { 
                                        mediaSource: "screen", 
                                        width: { ideal: window.screen.width * window.devicePixelRatio },
                                        // height: { ideal: 2160 } 
                                    }
                                });
                            }
            
                            function createRecorder (stream, mimeType) {
                            // the stream data is stored in this array
                                let recordedChunks = []; 
                                let quality = document.getElementById("recordingQuality").value;
                                const mediaRecorder = new MediaRecorder(stream, {
                                    videoBitsPerSecond: parseInt(quality), // Double the default quality from 2.5Mbps to 5Mbps
                                  }, mimeType);
                
                                console.log("MediaRecorder.videoBitsPerSecond", mediaRecorder.videoBitsPerSecond);
                                mediaRecorder.ondataavailable = function (e) {
                                    const date = new Date();
                                    const dateString = date.toISOString();
                                    console.log("ondataavailable", dateString);
                                    if(startRecordTime == null){
                                        startRecordTime = date;
                                        diffTime = (startRecordTime.getTime() - startRotateTime.getTime());
                                        console.log("diffTime Calculated", diffTime);
                                    }
                                    // if (e.data.size > 0) {
                                    recordedChunks.push(e.data);
                                    // }  
                                };
                                mediaRecorder.onstop = function () {
                                    const date = new Date();
                                    const dateString = date.toISOString();
                                    console.log("onstop", dateString);
                                    const tracks = stream.getTracks();
                                    saveFile(recordedChunks);
                                    recordedChunks = [];
                                    tracks.forEach(track => track.stop());
                                    // setTimeout(() => {
                                    // }, 1);
                                };
                                // mediaRecorder.start(); // For every 200ms the stream data will be stored in a separate chunk.
                                return mediaRecorder;
                            }
            
                            function saveFile(recordedChunks){
            
                                const blob = new Blob(recordedChunks, {
                                type: 'video/webm; codecs=h264'
                                });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                document.body.appendChild(a);
                                a.style = "display: none";
                                a.href = url;
                                a.download = "tween-rotation.webm";
                                a.click();
                                window.URL.revokeObjectURL(url);
                            }
                        }
                    });
                    
    
                    



                function fpsMeter() {
                    let prevTime = Date.now(),
                        frames = 0;
                
                    requestAnimationFrame(function loop() {
                      const time = Date.now();
                      frames++;
                      if (time > prevTime + 1000) {
                        // let fps = Math.round( ( frames * 1000 ) / ( time - prevTime ) );
                        let fps = ( ( frames * 1000 ) / ( time - prevTime ) );
                        prevTime = time;
                        frames = 0;
                
                        console.info('FPS: ', fps);
                      }
                
                      requestAnimationFrame(loop);
                    });
                  }
                  
                //SCREEN RECORDING








            }
        }).catch(error => {
            console.log(error.message)
        })

    }, [])

    /**
     * Check the type of the setting that is currently changing and calls cadcentre api
     */
    useEffect(() => {
        function isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return isNaN(str);
        }
        if(props.userPermission)
        {
            userInfo = props.userPermission;
        }
        if (props.sceneConfig.api) {
            const api = props.sceneConfig.api
            //Import model if exists from upload file
            if (props.files && props.sceneConfig.type === 'uploadFiles') {
                // document.getElementById("myLoader").style.display = "block";
                // document.getElementById("canvas").style.display = "none";
                // api.importModelFiles(props.files.files).then(result => {
                //     document.getElementById("myLoader").style.display = "none";
                //     document.getElementById("canvas").style.display = "block";
                //     api.exportScene().then(response => {
                //         props.saveSceneConfigToState(response, api, 'files') //save json to state and also api object so that we can call later all api's on component update
                //     });
                //     //Set background default image
                //     getFileObject('./bg_new.jpg', function (fileObject) {
                //         api.setBackgroundFile(fileObject, {setEnvironment: false})
                //     })
                // });
                // //Load and set default texture, if not set the model will be all black
                // new TextureLoader().load('./defaultTexture.png', response => {
                //     api.setSceneEnvironment(response, {
                //         setEnvironment: true,
                //         setDiamondEnv: false,
                //         setBackground: false,
                //         imageBasedLighting: true
                //     })
                // });
            }
            // api.setCameraParams({zoom: 0.4})
            switchCases(props, api)
        }

        if(props.sceneConfig.config){
            const config = props.sceneConfig.config;
            if(config.environment && isJson(config.environment)){
                const json = JSON.parse(config.environment);
                if(json.image){
                    const bg = json.image.data;
                    document.getElementById("selectedSceneEnv").value = bg;
                }
            }
            if(config.diamondEnvironment && isJson(config.diamondEnvironment)){
                const json = JSON.parse(config.diamondEnvironment);
                if(json.image){
                    const diamond = json.image.data;
                    document.getElementById("selectedDiamondEnv").value = diamond;
                }
            }
        }
    })

    /**
     * Width and height of canvas must be fixed and set to value 500, otherwise clicking on materials won't work.
     * This is cadcentre limitation
     */
    return (
        <Row>
            <WelcomeModal></WelcomeModal>
            <div id="tooltip-span" style={{width: 100, height: 100}} className="tooltip" title="CadGL"></div>
            <div style={{display: 'none'}} className="animate-bottom" id="myLoader"></div>
            <Col md={{ span: 12}} className="canvas-area">
                <canvas id="canvas" width="500" height="500" ref={canvas}/>
                {/* <img id="swipeIcon" src={swipe} style={{position: 'absolute', bottom: 20}} /> */}
                <input id="fileInput" style={{visibility: 'hidden', display: 'none'}} type="file"/>
                <img id="fullScreenIcon" src={fullscreen}  style={{display: 'block', position: 'absolute', top: 10, right: 100000000, height: 20}} />
                <div id="helperIcon" style={{ display: 'block' }} />
                <div id="3DConfiguratorIcon" style={{display: 'none'}} />
                <div id="CameraDoubleClickIcon" style={{display: 'block'}} />
                <div id="screenshotIcon" style={{display: 'block'}} />
                <div id="changeBackgroundIcon" style={{display: 'block'}} />
                <div id="measurementIcon" style={{display: 'none'}} />
                <div id="ignoreAnimation" style={{display: 'none'}} />

                <input type="hidden" id="logo_duration" />
                <input type="hidden" id="logo_fade_in" />
                <input type="hidden" id="logo_fade_out" />
                <input type="hidden" id="logo_scale" />
                <input type="hidden" id="logo_position_x" />
                <input type="hidden" id="logo_position_y" />

                <input type="hidden" id="layers_label" />
                <input type="hidden" id="layers_tag" />
                <input type="hidden" id="layers_price" />
                <input type="hidden" id="layers_display_name" />
                <input type="hidden" id="annotation_url" />
                <input type="hidden" id="selectedSceneEnv" />
                <input type="hidden" id="selectedDiamondEnv" />

                <input type="hidden" id="recordingQuality" value="16000000" />
                <input type="hidden" id="recordingRotateSpeed" value="2" />
                <input type="hidden" id="recordingRotateAxis" value="y" />
                <input type="hidden" id="recordingRotateDirection" value="+" />
                <input type="hidden" id="recordingRotateDiffMilSecond" value="90" />
                <input type="hidden" id="isFinish" value="100" />
                <input type="hidden" id="previousBackgroundColor" value="" />
                
                <First3DMModal file={props.files}></First3DMModal>
                <FirstGlbModal file={props.files}></FirstGlbModal>
            </Col>

            <div id="myModal" className="modalAR">
                <div className="modal-contentAR">
                    <span className="closeAR">&times;</span>
                    <div className="modal-bodyAR">
                        <p style={{color: 'black'}}>Please access this link to download an App to experience the AR</p>
                        <a target="_blank" href="https://apps.apple.com/us/app/webxr-viewer/id1295998056">XR Viewer</a>
                    </div>
                </div>
            </div>
        </Row>
    )
}


const onKeyUp = (e, api, props) => {
    const intersection = api.rayCast(mousePosition);
    let pickedObject = intersection?.object ?? null;
    if(pickedObject && (e.key === 'Backspace' || e.key === 'Delete')) {
        let root = pickRoot(pickedObject);
        if(root) {
            api.removeObject(root);
            let controls = api.getTransformControlParams()
            api.setTransformControlParams({visible: false, enabled: controls.enabled})
            api.setDirty();
        }
    }
}

const onPointerWheel = (e, api, props) => {
    mouseWheelTime = Date.now();
    var today = new Date();
    localStorage.setItem('user-active', today.setHours(today.getHours() + 4));
    // localStorage.setItem('user-active', today.setMinutes(today.getMinutes() + 2));
    console.log("api.annotationsEnabled: " + api.annotationsEnabled);
}

/**
 * Set mouse position
 * @param e
 */
const onPointerMove = (e, api, userInfo, props) => {
    let x = e.clientX;
    let y = e.clientY;
    mousePosition.x = (x / document.getElementById('canvas').clientWidth) * 2 - 1;
    mousePosition.y = -(y / document.getElementById('canvas').clientHeight) * 2 + 1;

    if(draggingAnnotation) {
        if (mouseDownTime > 0) {
            let i = api.rayCast(mousePosition);
            if (i) draggingAnnotation.setIntersection(i);
        }
        else {
            draggingAnnotation = null;
            api.setCameraParams({mode: "orbit"});
        }
    }

    const hitLogo = api.hitLogo(mousePosition);
    if(hitLogo) 
    {
        document.body.style.cursor = "pointer";
        if(userInfo.isShowLinkToolTip)
        {
            var tooltipSpan = document.getElementById('tooltip-span');
            if(tooltipSpan)
            {
                tooltipSpan.style.display = "block";
                tooltipSpan.style.top = (mousePosition.y + 10) + 'px';
                tooltipSpan.style.left = (mousePosition.x + 10) + 'px';
            }
        }
    }
    else
    {
        document.body.style.cursor = "default";
        var tooltipSpan = document.getElementById('tooltip-span');
        if(tooltipSpan)
        {
            tooltipSpan.style.display = "none";
            tooltipSpan.style.top = 0 + 'px';
            tooltipSpan.style.left = 0 + 'px';
        }
    }
}

/**
 * On mouse up event, will highlight on UI objects that use the material of the clicked object
 * @param e
 * @param api
 * @param props
 */
const onPointerUp = (e, api, props, userInfo) => {
    document.getElementById("canvas").classList.remove("canvasActive");
    mouseWheelTime = Date.now();
    let delta = Date.now() - mouseDownTime;
    lastMouseDownTime = mouseDownTime;
    mouseDownTime = 0;
    if (delta > 200) return;

    const intersection = api.rayCast(mousePosition);
    let pickedObject = intersection?.object ?? null;
    
    // if(pickedObject == null) return false;
    // if(pickedObject.uuid == localStorage.getItem('current-part')) return false;
    // localStorage.setItem('current-part', pickedObject.uuid);
    let materialLibrary = api.getMaterialLibrary();
    let objs = materialLibrary.find(value => !!value.mesh.find(value1 => value1 === pickedObject))?.mesh ?? [pickedObject];
    let selectedObject = new Map()

    //ANNOTATIONS
    let annotation = api.hitAnnotations(mousePosition, true);

    if (e.ctrlKey) {
        if(!annotation && !draggingAnnotation && intersection)
            annotation = api.addAnnotation(intersection);
    }
    if(annotation == draggingAnnotation) {
        draggingAnnotation = null;

        //this.gui.annotations.selected(annotation);
        api.setCameraParams({mode: "orbit"});
    }
    if(annotation)
    {
        props.selectAnnotations(annotation);
        // api.animateCamera(annotation.cameraState);
    }


    if (!e.ctrlKey && pickedObject) {
        api.highlightObjects();
        selectedObject.clear();

        let p = {edgeStrength: 10};
        api.getTweener(p)
                .to({edgeStrength: 0.0}, 3*1000)
                .onUpdate(()=> api.setOutlineParams(p))
                .start();
    }

    objs.forEach(value => {
        if (value) {
            selectedObject.set(value.uuid, value);
            api.highlightObjects.apply(api, Array.from(selectedObject.values()));
        } else {
            api.highlightObjects();
            selectedObject.clear();
        }
    });

    props.selectObject(selectedObject, pickedObject, objs) //Fire action to display material and object properties in sidebar

    if(userInfo.isShowLinkToolTip)
    {
        const hitLogo = api.hitLogo(mousePosition);
        if(hitLogo) window.open('https://gallery.cadgl.com/subscription', '_blank');
    }


    //gizmo
    let _pickedObject = intersection?.object ?? null;
    let transformControlParams = api.getTransformControlParams();

    if(_pickedObject) {
        let pickedRoot = pickRoot(_pickedObject)

        api.setTransformControlParams({visible: true, enabled: transformControlParams.enabled,  object: pickedRoot, mode: transformControlParams.mode})
    } else {
        api.setTransformControlParams({visible: false, enabled: transformControlParams.enabled, mode: transformControlParams.mode})
    }
}


const onPointerUp2 = (e, api, props, isMobile, userInfo) => {
    document.getElementById("canvas").classList.remove("canvasActive");
    if(userInfo.isShowLinkToolTip)
    {
        window.open('https://gallery.cadgl.com/subscription', '_blank');
        console.log(e.target.id);
    }
}

const onPointerDown = (e, api, props) => {
    var today = new Date();
    localStorage.setItem('user-active', today.setHours(today.getHours() + 4));
    // localStorage.setItem('user-active', today.setMinutes(today.getMinutes() + 2));

    document.getElementById("canvas").classList.add("canvasActive");
    //document.getElementById("swipeIcon").style.display = "none";
    mouseDownTime = Date.now();
    draggingAnnotation = api.hitAnnotations(mousePosition, true);
    if (draggingAnnotation)
        api.setCameraParams({mode: "fixed"});

    
    const now = Date.now();
    if(now - lastMouseDownTime < 300){
        lastMouseDownTime = 0;
        //double click
        api.animateCameraTarget(mousePosition, 1.2, 6, 1.1);
        return
    }
}

const onPointerDownDouble = (e, api, props) => {
    // api.animateCameraTarget(mousePosition, 1.2, 6, 1.1);
}

const getFileBlob = (url, cb) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
        cb(xhr.response);
    });
    xhr.send();
};

const blobToFile = (blob, name) => {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};

const getFileObject = (filePathOrUrl, cb) => {
    getFileBlob(filePathOrUrl, function (blob) {
        cb(blobToFile(blob, 'test.jpg'));
    });
};

const pickRoot = (object) => {
    let _object = object
    while(_object) {
        if(_object.userData.isRootObject) {
            return _object
        }
        _object = _object.parent
    }
    return _object
}

const getPickedObject = () => {
    return this._pickedObject;
}


const mapStateToProps = (state) => {
    return {
        sceneConfig: state.sceneConfig,
        selectedObjects: state.selectedObjects
    }
}

const mapDispatchToProps = {
    saveSceneConfigToState: saveSceneConfigToState,
    selectObject: selectObject,
    loadPresets: loadPresets,
    loadCombinations: loadCombinations,
    selectAnnotations: selectAnnotations,
    changeCameraPositionSetting: changeCameraPositionSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductRenderer)