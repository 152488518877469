import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    changeBevelSetting,
    enableSharpnessSetting,
    enableSsaoSetting,
    enableDebugSsaoSetting,
    enableBloomSetting,
    enableGroundShadow,
    enableSmoothTransition,
    enableSmooth,
    enableGroundReflection,
    enableGroundReflectionPhysical,
    enableDof,
    enableEditDof,
    enableAutoRotate,
    standardMaterialEnableWireframe,
    refractionMaterialToggleVisibility,
    pickedGroupToggleVisibility,
    enableAr,
    enableArGroundShadows,
    screenSpacePanning,
    enableZoom,
    enablePan,
    logoToggle,
    enableAnnotations,
    enableTAA,
    enableSSR,
    toggleAnimationLoop,
    toggleAudioLoop,
    enableSsaoBlur,

    toggleAudioMaterialLoop,
    enableFrustumCulling,
    switchOldNewCamera,
    gizmoEnabled
} from "../../../../actions/actionsCreators";

class MenuItemCheckbox extends Component {
    state = {
        checked: this.props.value ? this.props.value : false
    }

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value, obj) => {
        switch (type) {
            case 'toggleAudioMaterialLoop':
                this.props.toggleAudioMaterialLoop(obj, value);
                break;
            case 'toggleAudioLoop':
                this.props.toggleAudioLoop(value);
                break;
            case 'toggleAnimationLoop':
                this.props.toggleAnimationLoop(value);
                break;
            case 'enableAnnotations':
                this.props.enableAnnotations(value);
                break;
            case 'logoToggle':
                this.props.logoToggle(value);
                break;
            case 'enableBevel':
                this.props.changeBevelSetting(value)
                break;
            case 'enableSharpness':
                this.props.enableSharpnessSetting(value)
                break;
            case 'enableSsao':
                this.props.enableSsaoSetting(value)
                break;
            case 'enableSsaoBlur':
                this.props.enableSsaoBlur(value)
                break;
            case 'enableDebugSsao':
                this.props.enableDebugSsaoSetting(value)
                break;
            case 'enableBloom':
                this.props.enableBloomSetting(value)
                break;
            case 'groundShadow':
                this.props.enableGroundShadow(value)
                break;
            case 'smoothTransition':
                this.props.enableSmoothTransition(value)
                break;
            case 'enableSmooth':
                this.props.enableSmooth(value)
                break;
            case 'enableGroundReflection':
                this.props.enableGroundReflection(value)
                break;
            case 'enableGroundReflectionPhysical':
                this.props.enableGroundReflectionPhysical(value)
                break;
            case 'enableEditDof':
                this.props.enableEditDof(value)
                break;
            case 'enableDof':
                this.props.enableDof(value)
                break;
            case 'enableAutoRotate':
                this.props.enableAutoRotate(value)
                break;
            case 'standardMaterialEnableWireframe':
                this.props.standardMaterialEnableWireframe(value)
                break;
            case 'refractionMaterialToggleVisibility':
                this.props.refractionMaterialToggleVisibility(value)
                break;
            case 'pickedGroupToggleVisibility':
                console.log("Menu Item Checkbox");
                this.props.pickedGroupToggleVisibility(value)
                break;
            case 'enableAr':
                this.props.enableAr(value)
                break;
            case 'enableArGroundShadows':
                this.props.enableArGroundShadows(value)
                break;
            case 'screenSpacePanning':
                this.props.screenSpacePanning(value)
                break;
            case 'enablePan':
                this.props.enablePan(value)
                break;
            case 'enableZoom':
                this.props.enableZoom(value)
                break;
            case 'enableTAA':
                this.props.enableTAA(value)
                break;
            case 'enableSSR':
                this.props.enableSSR(value)
                break;
            case 'enableFrustumCulling':
                this.props.enableFrustumCulling(value)
                break;
            case 'switchOldNewCamera':
                this.props.switchOldNewCamera(value)
                break;
            case 'gizmoEnabled':
                this.props.gizmoEnabled(value)
                break;
            default:
                return null;
        }
    }

    handleChange = (event) => {
        this.setState({checked: !this.state.checked});
        this.dispatchAction(this.props.type, !this.state.checked, this.props.obj)
    }

    render() {
        return (
            <Row /*{...this.props}*/>
                <Col md={7} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={5} className="setting-col-input">
                    <Form.Check
                        type="checkbox"
                        className="float-right"
                        checked={this.state.checked}
                        value={this.state.checked}
                        onChange={this.handleChange}/>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    changeBevelSetting: changeBevelSetting,
    enableSharpnessSetting: enableSharpnessSetting,
    enableSsaoSetting: enableSsaoSetting,
    enableDebugSsaoSetting: enableDebugSsaoSetting,
    enableBloomSetting: enableBloomSetting,
    enableGroundShadow: enableGroundShadow,
    enableSmoothTransition: enableSmoothTransition,
    enableSmooth: enableSmooth,
    enableGroundReflection: enableGroundReflection,
    enableGroundReflectionPhysical: enableGroundReflectionPhysical,
    enableDof: enableDof,
    enableEditDof: enableEditDof,
    enableAutoRotate: enableAutoRotate,
    standardMaterialEnableWireframe: standardMaterialEnableWireframe,
    refractionMaterialToggleVisibility: refractionMaterialToggleVisibility,
    pickedGroupToggleVisibility: pickedGroupToggleVisibility,
    enableAr: enableAr,
    enableArGroundShadows:enableArGroundShadows,
    screenSpacePanning: screenSpacePanning,
    enablePan: enablePan,
    enableZoom: enableZoom,
    logoToggle: logoToggle,
    enableAnnotations: enableAnnotations,
    enableTAA: enableTAA,
    enableSSR: enableSSR,
    toggleAnimationLoop: toggleAnimationLoop,
    toggleAudioLoop: toggleAudioLoop,
    toggleAudioMaterialLoop: toggleAudioMaterialLoop,
    enableFrustumCulling: enableFrustumCulling,
    enableSsaoBlur: enableSsaoBlur,
    switchOldNewCamera: switchOldNewCamera,
    gizmoEnabled: gizmoEnabled
}

export default connect(null, mapDispatchToProps)(MenuItemCheckbox)