import React, {Component, useEffect} from "react";

import DiamondEnvLoadButton from "../MenuItems/DiamondEnvLoadButton";
import SceneEnvLoadButton from "../MenuItems/SceneEnvLoadButton";
import MenuItemButton from "../MenuItems/MenuItemButton";
import MenuGroup from "../MenuItems/MenuGroup";
import MenuItemTextBox from "../MenuItems/MenuItemTextBox";
import MenuItemColorPicker from "../MenuItems/MenuItemColorPicker";
import MenuItemInputRange from "../MenuItems/MenuItemInputRange";
import MenuItemInputRange2 from "../MenuItems/MenuItemInputRange2";
import MenuItemInputRangeMaxAzimuth from "../MenuItems/MenuItemInputRangeMaxAzimuth";
import MenuItemInputRangeMinAzimuth from "../MenuItems/MenuItemInputRangeMinAzimuth";
import MenuItemDropdown from "../MenuItems/MenuItemDropdown";
import {groundShadowSides} from "../../../../helpers/dropDownOptions";
import MenuItemCheckbox from "../MenuItems/MenuItemCheckbox";
import AddCombinationModal from "../../Combinations/AddCombinationModal";
import AddCombinationSceneInfoAlertModal from "../../Combinations/AddCombinationSceneInfoAlertModal";

import ShowCombinationsModal from "../../Combinations/ShowCombinationsModal";
import SaveScene from "../../Combinations/SaveScene";
import {Col, Row, Button, Form} from "react-bootstrap";
import LoginModal from "../../Combinations/LoginModal";
import RestrictCameraModal from "../Modals/RestrictCameraModal";
import jwt_decode from "jwt-decode";
import { connect } from 'react-redux';
import axios from '../../../../axios';

import {FileLoader} from "three";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';
import {gizmoOptions} from "../../../../helpers/gizmoOptions";

let lang = {};
let originalMinAzimuth = 0;
let originalMaxAzimuth = 0;
let originalCanvasHeight = 0;


class SceneSettings extends Component{
    state = {
        apiLoaded: false,
        sceneSetup: true,
        sceneView: false,
        outline: false,
        straightenModel: false,
        cameraMode: false,
        camera: false,
        restrictCamera: false,
        sceneEnvironment: false,
        diamondEnvironment: false,
        groundShadows: false,
        groundReflection: false,
        background: false,
        gizmo: false,
        autoRotate: false,
        screenshot: false,
        screenrecording: false,
        icons: false,
        tooltip: null,
        enableFullscreen: false,
        enableHelper: false,

        enableScreenshot: false,
        enableChangeBackground: false,
        enableMeasurement: false,
        enable3DConfigurator: false,
        enableCameraDoubleClick: false,

        userInfo: '',
        defaultSceneEnv: {},
        defaultBackground: {},

        selectedSceneEnv: '',
        selectedDiamondEnv: '',

        recordingQuality: 16000000,
        recordingRotateSpeed: 2,
        recordingRotateAxis: 'y',
        recordingRotateDirection: '+',
        recordingRotateDiffMilSecond: 90
    }


    componentDidMount() {
        if(this.props.api == undefined){
            setTimeout(() => {
                    this.setState({
                        apiLoaded: true
                    })
            }, 1000);
        }else{
            this.setState({
                apiLoaded: true
            })
        }

        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
            axios.get('user/get-user-info').then(response => {
                let {data} = response;
                if(data.success)
                {
                    let userTmp = data.data;
                    if(userTmp.isActive == false)
                    {
                        localStorage.removeItem("user");
                        document.location.reload();
                    }
                    if(userTmp.lang){
                        localStorage.setItem("lang", userTmp.lang);
                        switch(localStorage.getItem('lang')){
                            case "en":
                                lang = lang_en;
                            break;
                            case "vn":
                                lang = lang_vn;
                            break;
                            case "fr":
                                lang = lang_fr;
                            break;
                            case "it":
                                lang = lang_it;
                            break;
                            default:
                                lang = lang_en;
                            break;
                        }
                    }
                    
                }
            });
        }

        new FileLoader().load('https://cadgl.net/tooltip_cadgl/tooltip.json', (response) => {
            this.setState({
                tooltip: JSON.parse(response)
            })
        })
        //Check browser disable cookie
        var cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){ 
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
        }
        
        let token = null;
        if(cookieEnabled){
            token = localStorage.getItem('user');
        }
        if(token)
        {
            this.setState({
                loggedIn: true,
                userInfo: jwt_decode(localStorage.getItem('user'))
            })
        }

        originalCanvasHeight = document.getElementById("configurator-sidebar").offsetHeight ;
        function exitHandler()
        {
            console.log("originalCanvasHeight: " + originalCanvasHeight);
            var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
            if(fullscreenElement == null){
                let a = originalCanvasHeight + "px";
                document.getElementById("configurator-sidebar").style.height = a;
            }
        }
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
        document.addEventListener('webkitfullscreenchange', exitHandler, false);

        if (window.getComputedStyle(document.getElementById("fullScreenIcon")).display === "none") {
            
            this.setState({enableFullscreen: false})
        }else{
            this.setState({enableFullscreen: true})
        }
        if (window.getComputedStyle(document.getElementById("helperIcon")).display === "none") {
            this.setState({enableHelper: false})
        }else{
            this.setState({enableHelper: true})
        }
        if (window.getComputedStyle(document.getElementById("screenshotIcon")).display === "none") {
            this.setState({enableScreenshot: false})
        }else{
            this.setState({enableScreenshot: true})
        }
        if (window.getComputedStyle(document.getElementById("changeBackgroundIcon")).display === "none") {
            this.setState({enableChangeBackground: false})
        }else{
            this.setState({enableChangeBackground: true})
        }
        if (window.getComputedStyle(document.getElementById("measurementIcon")).display === "none") {
            this.setState({enableMeasurement: false})
        }else{
            this.setState({enableMeasurement: true})
        }
        
        if (window.getComputedStyle(document.getElementById("3DConfiguratorIcon")).display === "none") {
            this.setState({enable3DConfigurator: false})
        }else{
            this.setState({enable3DConfigurator: true})
        }

        if (window.getComputedStyle(document.getElementById("CameraDoubleClickIcon")).display === "none") {
            this.setState({enableCameraDoubleClick: false})
        }else{
            this.setState({enableCameraDoubleClick: true})
        }

        //SCREEN RECORDING
        
        this.setState({recordingQuality:  document.getElementById("recordingQuality").value != "" ? document.getElementById("recordingQuality").value : this.state.recordingQuality});
        this.setState({recordingRotateSpeed:  document.getElementById("recordingRotateSpeed").value != "" ? document.getElementById("recordingRotateSpeed").value : this.state.recordingRotateSpeed});
        this.setState({recordingRotateAxis:  document.getElementById("recordingRotateAxis").value != "" ? document.getElementById("recordingRotateAxis").value :  this.state.recordingRotateAxis});
        this.setState({recordingRotateDirection:  document.getElementById("recordingRotateDirection").value != "" ? document.getElementById("recordingRotateDirection").value :  this.state.recordingRotateDirection});
        this.setState({recordingRotateDiffMilSecond:  document.getElementById("recordingRotateDiffMilSecond").value != "" ? document.getElementById("recordingRotateDiffMilSecond").value :  this.state.recordingRotateDiffMilSecond});
        
    }

    restrictCameraChange = (type) => {
        this.forceUpdate();
    }

    toggleFullScreen = () =>{

        if(document.getElementById("btnEnableFullScreen").checked == true){
            document.getElementById("fullScreenIcon").style.display = "block";
        }else{
            document.getElementById("fullScreenIcon").style.display = "none";
        }

        this.setState({
            ...this.state,
            enableFullscreen: !this.state.enableFullscreen
        })
    }

    toggleHelper = () =>{

        if(document.getElementById("btnEnableHelper").checked == true){
            document.getElementById("helperIcon").style.display = "block";
        }else{
            document.getElementById("helperIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enableHelper: !this.state.enableHelper
        })
    }

    toggleScreenshot = () =>{

        if(document.getElementById("btnEnableScreenshot").checked == true){
            document.getElementById("screenshotIcon").style.display = "block";
        }else{
            document.getElementById("screenshotIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enableScreenshot: !this.state.enableScreenshot
        })
    }

    toggleChangeBackground = () =>{

        if(document.getElementById("btnEnableChangeBackground").checked == true){
            document.getElementById("changeBackgroundIcon").style.display = "block";
        }else{
            document.getElementById("changeBackgroundIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enableChangeBackground: !this.state.enableChangeBackground
        })
    }

    toggleMeasurement = () =>{

        if(document.getElementById("btnEnableMeasurement").checked == true){
            document.getElementById("measurementIcon").style.display = "block";
        }else{
            document.getElementById("measurementIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enableMeasurement: !this.state.enableMeasurement
        })
    }

    toggle3DConfigurator = () =>{

        if(document.getElementById("btn3DConfigurator").checked == true){
            document.getElementById("3DConfiguratorIcon").style.display = "block";
        }else{
            document.getElementById("3DConfiguratorIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enable3DConfigurator: !this.state.enable3DConfigurator
        })
    }

    toggleCameraDoubleClick = () =>{

        if(document.getElementById("btnCameraDoubleClick").checked == true){
            document.getElementById("CameraDoubleClickIcon").style.display = "block";
        }else{
            document.getElementById("CameraDoubleClickIcon").style.display = "none";
        }
        this.setState({
            ...this.state,
            enableCameraDoubleClick: !this.state.enableCameraDoubleClick
        })
    }

    toggleMenuVisibility = (type) => {

        if(type =="icons"){
            if (window.getComputedStyle(document.getElementById("fullScreenIcon")).display === "none") {
                this.setState({enableFullscreen: false})
            }else{
                this.setState({enableFullscreen: true})
            }
            if (window.getComputedStyle(document.getElementById("helperIcon")).display === "none") {
                this.setState({enableHelper: false})
            }else{
                this.setState({enableHelper: true})
            }
            if (window.getComputedStyle(document.getElementById("screenshotIcon")).display === "none") {
                this.setState({enableScreenshot: false})
            }else{
                this.setState({enableScreenshot: true})
            }
            if (window.getComputedStyle(document.getElementById("changeBackgroundIcon")).display === "none") {
                this.setState({enableChangeBackground: false})
            }else{
                this.setState({enableChangeBackground: true})
            }
            if (window.getComputedStyle(document.getElementById("measurementIcon")).display === "none") {
                this.setState({enableMeasurement: false})
            }else{
                this.setState({enableMeasurement: true})
            }
            if (window.getComputedStyle(document.getElementById("3DConfiguratorIcon")).display === "none") {
                this.setState({enable3DConfigurator: false})
            }else{
                this.setState({enable3DConfigurator: true})
            }
            if (window.getComputedStyle(document.getElementById("CameraDoubleClickIcon")).display === "none") {
                this.setState({enableCameraDoubleClick: false})
            }else{
                this.setState({enableCameraDoubleClick: true})
            }


        }


        let newState = {}
        console.log(this.state);
        Object.keys({...this.state}).forEach(key => {
            if(key != 'tooltipTab' && key != 'tooltip' 
            && key != 'userInfo' 
            && key != 'enableFullscreen' 
            && key != 'enableHelper'
            && key != 'enableScreenshot'
            && key != 'enableChangeBackground'
            && key != 'enable3DConfigurator'
            && key != 'enableMeasurement'
            && key != 'enableCameraDoubleClick'
            && key != 'apiLoaded'
            && key != 'selectedSceneEnv'
            && key != 'selectedDiamondEnv'

            && key != 'recordingQuality'
            && key != 'recordingRotateSpeed'
            && key != 'recordingRotateAxis'
            && key != 'recordingRotateDirection'
            && key != 'recordingRotateDiffMilSecond'
            )
            {
                newState = {
                    ...newState,
                    [key]: false
                }
            }
        })


        this.setState({
            ...newState,
            [type]: !this.state[type]
        })

        if(this.props.config != undefined){
            originalMinAzimuth = this.props.config.camera.orbitParams.minAzimuthAngle == null ? '-Infinity' : this.props.config.camera.orbitParams.minAzimuthAngle;
            originalMaxAzimuth = this.props.config.camera.orbitParams.originalMaxAzimuth == undefined ? 'Infinity' : this.props.config.camera.orbitParams.originalMaxAzimuth;
        }


        document.getElementById("recordingQuality").value = this.state.recordingQuality;
        document.getElementById("recordingRotateSpeed").value = this.state.recordingRotateSpeed;
        document.getElementById("recordingRotateAxis").value = this.state.recordingRotateAxis;
        document.getElementById("recordingRotateDirection").value = this.state.recordingRotateDirection;
        document.getElementById("recordingRotateDiffMilSecond").value = this.state.recordingRotateDiffMilSecond;
    }

    sceneEnvClick = (value) => {
        this.setState({selectedSceneEnv: value})
        document.getElementById("selectedSceneEnv").value = value;
    }

    diamondEnvClick = (value) => {
        this.setState({selectedDiamondEnv: value})
        document.getElementById("selectedDiamondEnv").value = value;
    }


    recordingQualityChange = (value) => {
        this.setState({recordingQuality: value.target.value})
        document.getElementById("recordingQuality").value = value.target.value;
    }
    recordingRotateSpeedChange = (value) => {
        this.setState({recordingRotateSpeed: value.target.value})
        document.getElementById("recordingRotateSpeed").value = value.target.value;
    }
    recordingRotateAxisChange = (value) => {
        this.setState({recordingRotateAxis: value.target.value})
        document.getElementById("recordingRotateAxis").value = value.target.value;
    }
    recordingRotateDirectionChange = (value) => {
        this.setState({recordingRotateDirection: value.target.value})
        document.getElementById("recordingRotateDirection").value = value.target.value;
    }
    recordingRotateDiffMilSecondChange = (value) => {
        this.setState({recordingRotateDiffMilSecond: value.target.value})
        document.getElementById("recordingRotateDiffMilSecond").value = value.target.value;
    }

    

    render() {
        return (
            <React.Fragment>
                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.scene_setup : ''}
                    name={lang.scene_setup}
                    onClick={() => this.toggleMenuVisibility('sceneSetup')}
                    collapsed={this.state.sceneSetup ? 1 : 0}>
                    {
                        this.state.sceneSetup && this.state.apiLoaded ?
                            <span>
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                name={lang.open_files} type="uploadFiles"/>
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                name={'Insert file'} type="insertFile"/>
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.open_scene : ''}
                                name={lang.open_scene} type="uploadScene"/>
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.export_glb : ''}
                                name={lang.export_glb} type="exportSceneGltf"/>
                                {/* <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.save_scene : ''}
                                name="Save Scene" type="downloadScene"/> */}
                                <SaveScene 
                                title={this.state.tooltip != null ? this.state.tooltip.save_scene : ''}
                                name={lang.save_scene}/>
                                {/* <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.clear_all : ''}
                                name="Clear All" type="clearAll"/> */}
                                {/*<MenuItemButton name="Snap Canvas" type="snapCanvas"/>*/}
                            </span> : ''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.scene_environment : ''}
                    name={lang.scene_environment}
                    onClick={() => this.toggleMenuVisibility('sceneEnvironment')}
                    collapsed={this.state.sceneEnvironment ? 1 : 0}>
                    {
                        this.state.sceneEnvironment ?
                            <span>
                                        <MenuItemButton
                                        title={this.state.tooltip != null ? this.state.tooltip.load_environment : ''}
                                        name={lang.load_environment} type="uploadEnvironment"/>

                                        <div id="scene_env_default" style={{height: 180, overflowX: 'hidden', overflowY: 'auto'}}>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/carp_blurry_640.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blurry 01" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/sk_blurry_640.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blurry 02" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/carp_bw_640.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sharp 01" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/sk_bw_640.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sharp 02" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/office desk_640.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bright 01" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/LA_Downtown.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bright 02" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/cloudy_cliffside2.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bright 03" type="loadDefaultSceneEnv"/>

                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/default01.jpg"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Light 01" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/default02.jpg"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Light 02" type="loadDefaultSceneEnv"/>

                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Indoor_1.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indoor 1" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Indoor_2.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indoor 2" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Indoor_3.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indoor 3" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Indoor_4.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indoor 4" type="loadDefaultSceneEnv"/>

                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_1.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 1" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_2.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 2" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_3.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 3" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_4.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 4" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_5.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 5" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_6.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 6" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_7.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 7" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Outdoor_8.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outdoor 8" type="loadDefaultSceneEnv"/>

                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_1.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 1" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_2.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 2" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_3.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 3" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_4.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 4" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_5.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 5" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_6.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 6" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_7.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 7" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_8.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 8" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_9.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 9" type="loadDefaultSceneEnv"/>
                                            <SceneEnvLoadButton
                                            envClick={this.sceneEnvClick}
                                            selectedSceneEnv={this.state.selectedSceneEnv}
                                            value="preset environments/scene_environment/Studio_10.hdr"
                                            name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio 10" type="loadDefaultSceneEnv"/>
                                        </div>

                                        <MenuItemInputRange
                                        title={this.state.tooltip != null ? this.state.tooltip.exposure : ''}
                                        name={lang.exposure} step={0.1} min={0} max={10} value={this.props.config.toneMappingExposure} type="changeToneMappingExposureSetting" />
                                        <MenuItemInputRange
                                        title={this.state.tooltip != null ? this.state.tooltip.saturation : ''}
                                        name={lang.saturation} step={0.1} min={0} max={2} value={this.props.config.toneMappingSaturation} type="changeToneMappingSaturationSetting" />
                                        <MenuItemInputRange
                                        title={this.state.tooltip != null ? this.state.tooltip.rotation : ''}
                                        name={lang.rotation} step={0.1} min={0} max={6} value={this.props.config.envMapRotation} type="changeEnvMapRotationSetting" />
                                        <MenuItemInputRange
                                        title={this.state.tooltip != null ? this.state.tooltip.contrast : ''}
                                        name={lang.contrast} step={0.01} min={0} max={2} value={this.props.config.toneMappingContrast} type="changeToneMappingContrastSetting" />
                                    </span> : ''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.diamond_environment : ''}
                    name={lang.diamond_environment}
                    onClick={() => this.toggleMenuVisibility('diamondEnvironment')}
                    collapsed={this.state.diamondEnvironment ? 1 : 0}>
                    {
                        this.state.diamondEnvironment ?
                            <span>
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.load_environment : ''}
                                name={lang.load_environment} type="uploadEquirec"/>
                                {/*<MenuItemButton name="Select Cubemap" type="uploadCubeMap" />*/}
                                <DiamondEnvLoadButton
                                envClick={this.diamondEnvClick}
                                selectedDiamondEnv={this.state.selectedDiamondEnv}
                                value="preset environments/diamond_environment/sh_blurry_640.hdr"
                                name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diamond 01" type="loadDefaultDiamondEnv"/>
                                <DiamondEnvLoadButton
                                envClick={this.diamondEnvClick}
                                selectedDiamondEnv={this.state.selectedDiamondEnv}
                                value="preset environments/diamond_environment/sh_bw_640.hdr"
                                name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diamond 02" type="loadDefaultDiamondEnv"/>
                                <DiamondEnvLoadButton
                                envClick={this.diamondEnvClick}
                                selectedDiamondEnv={this.state.selectedDiamondEnv}
                                value="preset environments/diamond_environment/sk_blurry_640.hdr"
                                name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diamond 03" type="loadDefaultDiamondEnv"/>
                                <DiamondEnvLoadButton
                                envClick={this.diamondEnvClick}
                                selectedDiamondEnv={this.state.selectedDiamondEnv}
                                value="preset environments/diamond_environment/sk_bw_640.hdr"
                                name="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diamond 04" type="loadDefaultDiamondEnv"/>
                            </span> : ''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.background : ''}
                    name={lang.background}
                    onClick={() => this.toggleMenuVisibility('background')}
                    collapsed={this.state.background ? 1 : 0}>
                    {
                        this.state.background ?
                            <span>
                                <MenuItemColorPicker
                                title={this.state.tooltip != null ? this.state.tooltip.color : ''}
                                name={lang.color} value={this.props.config.outline.visibleEdgeColor} type="setBackgroundColor" />
                                <MenuItemButton
                                title={this.state.tooltip != null ? this.state.tooltip.load_image : ''} 
                                name={lang.load_image} type="backgroundLoadImage"/>
                                {/* <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.remove : ''}
                                name={lang.remove} type="removeBackground"/> */}
                            </span> : ''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.background : ''}
                    name={'TRANSFORM'}
                    onClick={() => this.toggleMenuVisibility('gizmo')}
                    collapsed={this.state.gizmo ? 1 : 0}>
                    {
                        this.state.gizmo ?
                            <span>
                                <MenuItemDropdown name={'Mode'} options={gizmoOptions} value={this.props.api.getTransformControlParams().mode} type="gizmoChangeMode" /> 
                                <MenuItemCheckbox name={'Enabled'} value={this.props.api.getTransformControlParams().enabled} type="gizmoEnabled" />
                            </span> : ''
                    }
                </MenuGroup>

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.rotate_model : ''}
                    name={lang.rotate_model}
                    onClick={() => this.toggleMenuVisibility('straightenModel')}
                    collapsed={this.state.straightenModel ? 1 : 0}>
                    {
                        this.state.straightenModel ?
                            <span>
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.reset : ''}
                                name={lang.reset} type="resetStraightenModel" />
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.rotate_x : ''}
                                name={lang.rotate_x_1} type="straightenModelRotate" value="x+"/>
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.rotate_x : ''}
                                name={lang.rotate_x_2} type="straightenModelRotate" value="x-"/>
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.rotate_y : ''}
                                name={lang.rotate_y} type="straightenModelRotate" value="y+"/>
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.rotate_z : ''}
                                name={lang.rotate_z} type="straightenModelRotate" value="z+"/>
                            </span> : ''
                    }
                </MenuGroup>

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowSceneView) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.scene_view : ''}
                            name={lang.scene_view}
                            onClick={() => this.toggleMenuVisibility('sceneView')}
                            collapsed={this.state.sceneView ? 1 : 0}>
                            {
                                this.state.sceneView ?
                                    <span>
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.right_view : ''}
                                        name={lang.right_view} type="sceneView" value="x+c" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.left_view : ''}
                                        name={lang.left_view} type="sceneView" value="x-c" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.top_view : ''}
                                        name={lang.top_view} type="sceneView" value="y+c" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.down_view : ''}
                                        name={lang.down_view} type="sceneView" value="y-c" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.front_view : ''}
                                        name={lang.front_view} type="sceneView" value="z+c" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.back_view : ''}
                                        name={lang.back_view} type="sceneView" value="z-c" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }


                {
                    (this.props.userPermission != undefined && this.props.userPermission.isAllowOutline) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.outline : ''}
                            name={lang.outline}
                            onClick={() => this.toggleMenuVisibility('outline')}
                            collapsed={this.state.outline ? 1 : 0}>
                            {
                                this.state.outline ?
                                    <span>
                                        <MenuItemColorPicker 
                                        title={this.state.tooltip != null ? this.state.tooltip.visible_edge_color : ''}
                                        name={lang.visible_edge_color} value={this.props.config.outline.visibleEdgeColor} type="visibleEdgeColor" />
                                        <MenuItemColorPicker 
                                        title={this.state.tooltip != null ? this.state.tooltip.hidden_edge_color : ''}
                                        name={lang.hidden_edge_color} value={this.props.config.outline.hiddenEdgeColor} type="hiddenEdgeColor"/>
                                        <MenuItemTextBox 
                                        title={this.state.tooltip != null ? this.state.tooltip.down_sample_ratio : ''}
                                        name={lang.down_sample_ratio} value={this.props.config.outline.downSampleRatio} type="downSampleRatio" />
                                        <MenuItemInputRange 
                                        title={this.state.tooltip != null ? this.state.tooltip.edge_glow : ''}
                                        name={lang.edge_glow} min={0.0} max={1} value={this.props.config.outline.edgeGlow} type="edgeGlow" />
                                        <MenuItemInputRange 
                                        title={this.state.tooltip != null ? this.state.tooltip.edge_thickness : ''}
                                        name={lang.edge_thickness} min={1} max={4} value={this.props.config.outline.edgeThickness} type="edgeThickness" />
                                        <MenuItemInputRange 
                                        title={this.state.tooltip != null ? this.state.tooltip.edge_strength : ''}
                                        name={lang.edge_strength} min={0.01} max={10} value={this.props.config.outline.edgeStrength} type="edgeStrength" />
                                        <MenuItemInputRange 
                                        title={this.state.tooltip != null ? this.state.tooltip.pulse_period : ''}
                                        name={lang.pulse_period} min={0.0} max={5} value={this.props.config.outline.pulsePeriod} type="pulsePeriod" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }
                {/*<MenuGroup
                    name="CAMERA MODE"
                    onClick={() => this.toggleMenuVisibility('cameraMode')}
                    collapsed={this.state.cameraMode ? 1 : 0}>
                    {
                        this.state.cameraMode ?
                            <span>
                                <MenuItemDropdown name="Mode" options={modeOptions} value={this.props.config.camera.mode} type="cameraMode" />
                            </span> : ''
                    }
                </MenuGroup>*/}

                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.camera : ''}
                    name={lang.camera}
                    onClick={() => this.toggleMenuVisibility('camera')}
                    collapsed={this.state.camera ? 1 : 0}>
                    {
                        this.state.camera ?
                            <span>
                                <Row /*{...this.props}*/>
                                    <Col md={7} className="setting-col-input">
                                        <span title="Enable Camera Double Click" className="setting-name">{lang.camera_double_click}</span>
                                    </Col>
                                    <Col md={5} className="setting-col-input">
                                        <Form.Check
                                            checked={this.state.enableCameraDoubleClick}
                                            onChange={this.toggleCameraDoubleClick}
                                            id="btnCameraDoubleClick"
                                            type="checkbox"
                                            className="float-right"/>
                                    </Col>
                                </Row>
                                
                                <MenuItemCheckbox name={'Panoramic Camera'} value={this.props.config.camera.orbitParams.autoPushTarget} type="switchOldNewCamera" />
                                <MenuItemCheckbox name={lang.enable_frustum_culling} value={this.props.config.frustumCulling} type="enableFrustumCulling" />
                                <MenuItemCheckbox name={lang.screen_panning} value={this.props.config.camera.orbitParams.screenSpacePanning} type="screenSpacePanning" />
                                {/* <MenuItemInputRange name={lang.zoom} min={0} max={10} value={this.props.config.camera.zoom} type="cameraZoom" /> */}
                                <MenuItemInputRange name={lang.fov} min={1} max={100} value={this.props.config.camera.fov} type="fov" />
                                <MenuItemInputRange name={lang.min_camera_near_clip}  min={0} max={0.6} step={0.1} value={this.props.api.getMinCameraNearClip() ?? 0} type="minCameraNearClip" />
                                <MenuItemInputRange name="Damping Factor" min={0} max={1} value={this.props.config.camera.orbitParams.dampingFactor} type="dampingFactor" />
                                        <MenuItemInputRange2 key={Math.floor(Math.random() * 999)} step={0.1} min={0.1} max={4} disableInput={false} name={lang.zoom_speed} value={this.props.config.camera.orbitParams.zoomSpeed ?  this.props.config.camera.orbitParams.zoomSpeed : 0} type="zoomSpeed" />
                                {/* <MenuItemInputRange2 step={0.0001} min={0} max={50} disableInput={false} name={lang.min_distance} value={this.props.config.camera.orbitParams.minDistance ?  this.props.config.camera.orbitParams.minDistance : 0} type="minimumDistance" /> */}
                                {/* <MenuItemInputRange2 step={0.001} min={0} max={50} disableInput={false} name={lang.max_distance} value={this.props.config.camera.orbitParams.maxDistance ?  this.props.config.camera.orbitParams.maxDistance : 50} type="maximumDistance" />
                                
                                <MenuItemInputRange2 step={0.001} min={0} max={50} disableInput={false} name={lang.min_speed} value={this.props.config.camera.orbitParams.minSpeed ?  this.props.config.camera.orbitParams.minSpeed : 0} type="minimumSpeed" />
                                <MenuItemInputRange2 step={0.001} min={0} max={50} disableInput={false} name={lang.max_speed} value={this.props.config.camera.orbitParams.maxSpeed ?  this.props.config.camera.orbitParams.maxSpeed : 50} type="maximumSpeed" /> */}

                                {/* <MenuItemInputRange2 key={this.props.config.camera.position[0]+0.000002} step={0.1} name="X" min={-10} max={10} value={this.props.config.camera.position[0]} type="cameraX" />
                                <MenuItemInputRange2 key={this.props.config.camera.position[1]+0.000005} step={0.1} name="Y" min={-10} max={10} value={this.props.config.camera.position[1]} type="cameraY" />
                                <MenuItemInputRange2 key={this.props.config.camera.position[2]+0.000008} step={0.1} name="Z" min={-10} max={10} value={this.props.config.camera.position[2]} type="cameraZ" />  */}

                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.reset_camera_target : ''}
                                name={lang.recenter} type="resetCameraTarget" value="" />
                                <MenuItemButton 
                                title={this.state.tooltip != null ? this.state.tooltip.reset_camera : ''}
                                name={lang.reset_camera} type="resetCamera" value=""/>
                                

                                
                            </span> : ''
                    }
                </MenuGroup>


                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowRestrictCamera) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.restrict_camera : ''}
                            name={lang.restrict_camera}
                            onClick={() => this.toggleMenuVisibility('restrictCamera')}
                            collapsed={this.state.restrictCamera ? 1 : 0}>
                            {
                                this.state.restrictCamera ?
                                    <span>
                                        <MenuItemCheckbox name={lang.enable_zoom} value={this.props.config.camera.orbitParams.enableZoom} type="enableZoom" />
                                        <MenuItemCheckbox name={lang.enable_pan} value={this.props.config.camera.orbitParams.enablePan} type="enablePan" />
                                        {/* <MenuItemTextBox name="Maximum Zoom" value={this.props.config.camera.orbitParams.maxZoom} type="maxZoom" />
                                        <MenuItemTextBox name="Minimum Zoom" value={this.props.config.camera.orbitParams.minZoom} type="minZoom" /> */}
                                        
                                        <MenuItemInputRange2 key={Math.floor(Math.random() * 998)} step={0.1} min={0} max={50} disableInput={false} name={lang.zoom_in} value={this.props.config.camera.orbitParams.minDistance ?  this.props.config.camera.orbitParams.minDistance : 0} type="minimumDistance" />
                                        <MenuItemInputRange2 key={Math.floor(Math.random() * 997)}  step={0.1} min={0} max={50} disableInput={false} name={lang.zoom_out} value={this.props.config.camera.orbitParams.maxDistance ?  this.props.config.camera.orbitParams.maxDistance : 50} type="maximumDistance" />

                                        <MenuItemInputRange2 key={Math.floor(Math.random() * 996)}  step={0.1} min={0} max={3.14} disableInput={false} name={lang.restrict_down} value={this.props.config.camera.orbitParams.maxPolarAngle ? (this.props.config.camera.orbitParams.maxPolarAngle > 3.14 ? 3.14 : this.props.config.camera.orbitParams.maxPolarAngle) : 0} type="maxPolarAngle" />
                                        <MenuItemInputRange2 key={Math.floor(Math.random() * 995)} step={0.1} min={0} max={3.14} disableInput={false} name={lang.restrict_up} value={this.props.config.camera.orbitParams.minPolarAngle ? (this.props.config.camera.orbitParams.minPolarAngle > 3.14 ? 3.14 : this.props.config.camera.orbitParams.minPolarAngle) : 0} type="minPolarAngle" />
                                        
                                        <MenuItemInputRangeMaxAzimuth key={Math.floor(Math.random() * 994)}  originalValue={originalMaxAzimuth} step={0.1} max={10} disableInput={false} name={lang.restrict_left} value={this.props.config.camera.orbitParams.maxAzimuthAngle} type="maxAzimuthAngle" />
                                        <MenuItemInputRangeMinAzimuth key={Math.floor(Math.random() * 993)}  originalValue={originalMinAzimuth} min={-10} step={0.1} max={10} disableInput={false} name={lang.restrict_right} value={this.props.config.camera.orbitParams.minAzimuthAngle} type="minAzimuthAngle" />
                                        
                                        

                                        {/* <RestrictCameraModal originalMinAzimuth={originalMinAzimuth} originalMaxAzimuth={originalMaxAzimuth} restrictCameraClick={this.restrictCameraChange} config={this.props.config} /> */}
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }
                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.ground_shadows : ''}
                    name={lang.ground_shadows_tab}
                    onClick={() => this.toggleMenuVisibility('groundShadows')}
                    collapsed={this.state.groundShadows ? 1 : 0}>
                    {
                        this.state.groundShadows ?
                            <span>
                                <MenuItemCheckbox name={lang.enable} value={this.props.config.groundShadow.enabled} type="groundShadow" />
                                <MenuItemInputRange name={lang.samples} step={1} min={1} max={1000} value={this.props.config.groundShadow.numSamples} type="numSamples" />
                                <MenuItemInputRange name={lang.samples_per_frame} step={0.1} min={1} max={10} value={this.props.config.groundShadow.numSamplesPerFrame} type="numSamplesPerFrame" />
                                <MenuItemInputRange name={lang.brightness}  min={0} max={1} value={this.props.config.groundShadow.brightness} type="groundBrightness" />
                                <MenuItemInputRange name={lang.blur_radius} min={0} max={10} value={this.props.config.groundShadow.blurRadius} type="blurRadius" />
                                <MenuItemInputRange name={lang.sharpness} step={1} min={0} max={90} value={this.props.config.groundShadow.sharpness} type="groundSharpness" />
                                <MenuItemInputRange name={lang.falloff} step={0.1} min={1} max={5} value={this.props.config.groundShadow.falloff} type="falloff" />
                                <MenuItemCheckbox name={lang.smooth_transition} value={this.props.config.groundShadow.smoothTransition} type="smoothTransition" />
                                <MenuItemCheckbox name={lang.enable_smooth} value={this.props.config.groundShadow.enableSmooth} type="enableSmooth" />
                                <MenuItemDropdown name={lang.side} options={groundShadowSides} value={this.props.config.groundShadow.side} type="groundShadowSide" />
                            </span> : ''
                    }
                </MenuGroup>

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowGroundReflection) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.ground_reflection : ''}
                            name={lang.ground_reflection}
                            onClick={() => this.toggleMenuVisibility('groundReflection')}
                            collapsed={this.state.groundReflection ? 1 : 0}>
                            {
                                this.state.groundReflection ?
                                    <span>
                                        <MenuItemCheckbox name={lang.enable} value={this.props.config.groundReflection.enable} type="enableGroundReflection" />
                                        <MenuItemCheckbox name={lang.backplate} value={this.props.config.groundReflection.physical} type="enableGroundReflectionPhysical" />
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.load_file : ''}
                                        name={lang.load_image} type="loadReflection" />
                                        <MenuItemInputRange name={lang.roughness} min={0} max={1} value={this.props.config.groundReflection.roughness} type="reflectionRoughness" />
                                        <MenuItemInputRange name={lang.metalness} step={0.1} min={0} max={1} value={this.props.config.groundReflection.metalness} type="reflectionMetalness" />
                                        <MenuItemInputRange name={lang.size} step={0.5} min={0.1} max={10} value={this.props.config.groundReflection.size} type="reflectionSize" />
                                        
                                        
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.view : ''}
                                        name={lang.view} type="viewReflection"/>
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.download : ''}
                                        name={lang.download} type="downloadReflection"/>

                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowAutoRotate) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.auto_rotate : ''}
                            name={lang.auto_rotate}
                            onClick={() => this.toggleMenuVisibility('autoRotate')}
                            collapsed={this.state.autoRotate ? 1 : 0}>
                            {
                                this.state.autoRotate ?
                                    <span>
                                        <MenuItemCheckbox name={lang.enable} value={this.props.config.camera.orbitParams.autoRotate} type="enableAutoRotate" />
                                        <MenuItemInputRange name={lang.auto_rotate_speed} step={0.1} min={0.1} max={10} value={this.props.config.camera.orbitParams.autoRotateSpeed} type="autoRotateSpeed" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowScreenshot) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.screenshot : ''}
                            name={lang.screenshot}
                            onClick={() => this.toggleMenuVisibility('screenshot')}
                            collapsed={this.state.screenshot ? 1 : 0}>
                            {
                                this.state.screenshot ?
                                    <span>
                                        <MenuItemButton 
                                        title={this.state.tooltip != null ? this.state.tooltip.take_screenshot : ''}
                                        name={lang.take_screenshot} type="takeScreenshot" value=""/>
                                        <MenuItemInputRange name={lang.pixel_ratio} step={0.1} min={1} max={2.5} value={this.props.pixelRatio} type="pixelRatio" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowScreenRecording) ? 
                        <MenuGroup
                            name={'SCREEN RECORDING'}
                            onClick={() => this.toggleMenuVisibility('screenrecording')}
                            collapsed={this.state.screenrecording ? 1 : 0}>
                            {
                                this.state.screenrecording ?
                                    <span>
                                        <div className="row">
                                            <div className="setting-col col-md-12">
                                                    <span id="startRecording" className="setting-name">Record</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div style={{paddingTop: 15}} className="setting-col-input col-md-6">
                                                Video Quality
                                            </div>
                                            <div className="setting-col-input col-md-6">
                                                {/* <input type="text" className="menu-item-text-box form-control form-control-sm" ></input> */}
                                                <select style={{textAlignLast: 'center'}} onChange={this.recordingQualityChange} value={this.state.recordingQuality} className="menu-item-text-box form-control form-control-sm">
                                                    <option value="40000000">Best</option>
                                                    <option value="16000000">High</option>
                                                    <option value="8000000">Medium</option>
                                                    <option value="5000000">Low</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div style={{paddingTop: 15}} className="setting-col-input col-md-6">
                                                Rotate Speed
                                            </div>
                                            <div className="setting-col-input col-md-6">
                                                <input onChange={this.recordingRotateSpeedChange} type="number" className="menu-item-text-box form-control form-control-sm" value={this.state.recordingRotateSpeed}></input>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div style={{paddingTop: 15}} className="setting-col-input col-md-6">
                                                Rotate By Axis
                                            </div>
                                            <div className="setting-col-input col-md-6">
                                                {/* <input type="text" className="menu-item-text-box form-control form-control-sm" ></input> */}
                                                <select style={{textAlignLast: 'center'}} onChange={this.recordingRotateAxisChange} value={this.state.recordingRotateAxis} className="menu-item-text-box form-control form-control-sm">
                                                    <option value="x">X</option>
                                                    <option value="y">Y</option>
                                                    <option value="z">Z</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div style={{paddingTop: 15}} className="setting-col-input col-md-6">
                                                Direction
                                            </div>
                                            <div className="setting-col-input col-md-6">
                                                {/* <input onChange={this.recordingRotateDirectionChange}  type="text" className="menu-item-text-box form-control form-control-sm" value={this.state.recordingRotateDirection}></input> */}
                                                <select style={{textAlignLast: 'center', fontSize: 13+'pt', maxHeight: 30, paddingTop: 0}} onChange={this.recordingRotateDirectionChange} value={this.state.recordingRotateDirection} className="menu-item-text-box form-control form-control-sm">
                                                        <option value="+">+</option>
                                                        <option value="-">-</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div style={{paddingTop: 15}} className="setting-col-input col-md-6">
                                                Fine-tune
                                            </div>
                                            <div className="setting-col-input col-md-6">
                                                <input onChange={this.recordingRotateDiffMilSecondChange}  type="number" className="menu-item-text-box form-control form-control-sm" value={this.state.recordingRotateDiffMilSecond}></input>
                                            </div>
                                        </div> */}

                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }


                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowIcons) ? 
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.fullscreen : ''}
                            name={lang.show_icons}
                            onClick={() => this.toggleMenuVisibility('icons')}
                            collapsed={this.state.icons ? 1 : 0}>
                            {
                                this.state.icons ?
                                    <span>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Show Fullscreen" className="setting-name">{lang.show_fullscreen}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enableFullscreen}
                                                    onChange={this.toggleFullScreen}
                                                    id="btnEnableFullScreen"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Show Info" className="setting-name">{lang.show_info}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enableHelper}
                                                    onChange={this.toggleHelper}
                                                    id="btnEnableHelper"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Show Screenshot" className="setting-name">{lang.show_screenshot}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enableScreenshot}
                                                    onChange={this.toggleScreenshot}
                                                    id="btnEnableScreenshot"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Show Change Background" className="setting-name">{lang.show_change_background}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enableChangeBackground}
                                                    onChange={this.toggleChangeBackground}
                                                    id="btnEnableChangeBackground"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Show 3D Configurator" className="setting-name">{lang.show_3d_configurator}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enable3DConfigurator}
                                                    onChange={this.toggle3DConfigurator}
                                                    id="btn3DConfigurator"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                        <Row /*{...this.props}*/>
                                            <Col md={7} className="setting-col-input">
                                                <span title="Measurement" className="setting-name">{lang.measurement}</span>
                                            </Col>
                                            <Col md={5} className="setting-col-input">
                                                <Form.Check
                                                    checked={this.state.enableMeasurement}
                                                    onChange={this.toggleMeasurement}
                                                    id="btnEnableMeasurement"
                                                    type="checkbox"
                                                    className="float-right"/>
                                            </Col>
                                        </Row>
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }

                <br/>

                <Row>
                    <Col md={12}>
                        <h5 className="app-name-sidebar" style={{fontSize:11+'px'}}>{lang.create_your_combinations}</h5>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '2.5%', paddingLeft: '0'}}>
                        {/* <AddCombinationModal /> */}
                        <AddCombinationSceneInfoAlertModal/>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '0', paddingLeft: '0'}}>
                        <ShowCombinationsModal />
                        <LoginModal style={{display: 'none'}} />
                    </Col>
                </Row>
                <div id="myImg" style={{display: 'none'}}>

                </div>
            </React.Fragment>
        )
    }
}
export default SceneSettings