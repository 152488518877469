import React from "react";
import Navbar from "../Navbar/Navbar";
import '../../App.css';
import './Confirm.css';
import {Container, Row, Col} from "react-bootstrap";
import confirmIconWidget from '../../assets/confirmation-icon-widget-1@x.png';
import confirmIconLink from '../../assets/confirmation-icon-link-1@x.png';
import arrowLeft from '../../assets/arrow-left-1@2x.png';
import { useHistory } from "react-router-dom";

function Confirmation(props) {
    const history = useHistory();

    const redirectToConfigurator = () => {
        history.push("/configurator");
    }

    return(
        <div className="App">
            <Navbar />
            <Container fluid>
                <div className="confirm-area">
                    <Row>
                        <Col className="confirm-box" md={{ span: 4, offset: 4 }}>
                            <Row>
                                <Col>
                                    <img src={props.type === 'widget' ? confirmIconWidget : confirmIconLink} className="confirm-icon" />
                                </Col>
                            </Row>
                            <Row style={{padding: "5%"}}>
                                <Col>
                                    {
                                        props.type === 'widget' ?  <span className="confirm-message">Configurator Widget is successfully generated! Your design is ready to be shared.</span> :
                                            <span className="confirm-message">Embedded Link is successfully generated! Your design is ready to be shared.</span>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className="back-button" onClick={redirectToConfigurator}>
                                        <span className="back-button-text">
                                            <img src={arrowLeft} alt=""/>
                                            Back to editor
                                        </span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Confirmation