import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import warningIcon from "../../../assets/warning.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

let helperPC = 'https://cadgl.net/icons_cadgl/pc-mouse.jpg';
let helperMobile = 'https://cadgl.net/icons_cadgl/mobile-touch.jpg';
let showInterval;

class HardwareAccelerationModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        helperIcon: helperPC
    };

    /**
     * Close modal
     */
    handleCloseHelper = () => {
        this.setState({
            show: false
        })
        if(document.getElementById("welcome-dont-show").checked == true)
        {
            var d=new Date();
            d.setTime(d.getTime()+(100*24*60*60*1000));
            var expires = "; expires="+d.toGMTString();
            var name = "welcome-dont-show";
            var value  = "true";
            document.cookie = name+"="+value+expires+"; path=/";
        }
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
        showInterval = setInterval(() => {
            if(document.getElementById("helperPopup")){
                // document.getElementById("helperPopup").childNodes[0].classList.add("popupWidth60");
                clearInterval(showInterval);
            }
        }, 1);
    }

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount() {
        if(localStorage.getItem('user'))
        {
            if(!this.readCookie("welcome-dont-show") && window.location.pathname == "/"){
                this.handleShow();
            }
        }else{
            this.setState({
                show: false
            })
        }
    }
    componentWillReceiveProps = (nextProps) => {
        console.log("nextProps", nextProps);
        this.setState({
            show: nextProps.show
        })
      }
    

    render() {
        return (
            <React.Fragment>
                {
                    this.state.show ?
                    <img src={this.props.iconSrc} style={{width: 20}} onClick={this.handleShow}/>
                    : <></>
                }
                <Modal id="welcomeModal" size="xl" show={this.state.show} 
                onHide={this.handleCloseHelper} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title><img src={warningIcon} width={20}/> Warning</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseHelper}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{color: 'black'}}>
                        <br/>
                        {/* <p className="p1" style={{fontSize: 16}}>
                            Your browser is not configured properly. 
                        </p> */}
                        <p className="p2" style={{fontSize: 16}}>
                            Please activate "Hardware Acceleration" option in your settings. 
                        </p>
                        <p className="p2" style={{fontSize: 16}}>
                            Being switched off prevents you from loading WebGl pages correctly. 
                        </p>
                        {/* <p className="p2" style={{fontSize: 16}}>
                            This option is enabled by default but could have been switched off unintentionally. 
                        </p> */}
                        
                    </Modal.Body>
                    <Modal.Footer style={{color: 'white'}}>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(HardwareAccelerationModal)