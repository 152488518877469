import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Form, Button, Tabs, Tab, Accordion, Table, Col, Row} from "react-bootstrap";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import {SpinnerSmall} from "../../../helpers/spinners";
import helpIcon from '../../../assets/help_icon.png';
import closeIcon from "../../../assets/close-icon@2x.png";
import MenuItemTextArea from "../../Configurator/Sidebar/MenuItems/MenuItemTextArea";
import AnnotationSendInvitation from "../../Configurator/Sidebar/MenuItems/AnnotationSendInvitation";
import AnnotationReferenceCombination from "../../Configurator/Sidebar/MenuItems/AnnotationReferenceCombination";

import {updateCombination, loadCombinations} from '../../../actions/actionsCreators';

class CombinationModal extends Component {
    state = {
        isShow: false
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    setShow = () => {
        this.setState({
            isShow : true
        })
    }
    setHide = () => {
        this.setState({
            isShow : false
        })
    }

    openUrl = () => {
        switch(this.props.type){
            case "iframe":
                window.open("https://www.cadgl.com/help/3d-viewer", "_blank");
                break;
            case "configurators":
                window.open("https://www.cadgl.com/help/3d-configurator", "_blank");
                break;
            case "annotation":
                window.open("https://www.cadgl.com/help/annotations", "_blank");
                break;
        }
    }

    render() {
        return(
            <React.Fragment>
                <>
                    <Row>
                        <Col md={{ span: 10}}>
                            <button id="" style={{marginBottom: '0', margin: 1+'%'}} onClick={this.setShow} className="btn btn-primary">{this.props.text}</button>
                        </Col>
                        <Col md={{ span: 2}}>
                            <img className="helpIconCombinationModal"  id="" src={helpIcon} onClick={this.openUrl} />
                        </Col>
                    </Row>
                    <Modal className="modalSize50" size="xl" show={this.state.isShow} onHide={this.setHide} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton className="rename-material-modal-title">
                            <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                            <div>
                                <img className="close-icon" src={closeIcon} alt="" onClick={this.setHide}/>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                    <Link
                                        to={{
                                            pathname: `/${this.props.type}/` + this.props.uuid
                                        }}
                                        className="btn btn-primary"
                                        target="_blank"
                                    >Open {this.props.text}</Link>
                                    {
                                        (this.props.userPermission && this.props.userPermission.configuratorsTemplates) ?
                                        this.props.userPermission.configuratorsTemplates.map((template, i) => {
                                            return <Link
                                                to={{
                                                    pathname: `/${this.props.type}/${this.props.uuid}?template=${template}` 
                                                }}
                                                className="btn btn-primary"
                                                target="_blank"
                                                style={{marginTop: 5}}
                                            >Open with {template}</Link>
                                        })
                                        : ''
                                    }
                                </Col>
                                <Col md={{ span: 12}} style={{color: 'white'}}>
                                    {
                                        !this.props.isAnnotation && 
                                        <MenuItemTextArea style={{width: 100+'%', height: 165+'px'}}
                                            value={this.props.uuid}
                                            type={this.props.type}
                                            width="750"
                                            height="480"
                                        />
                                    }
                                    {
                                        this.props.isAnnotation && 
                                        <AnnotationSendInvitation style={{width: 100+'%', height: 165+'px'}}
                                            value={this.props.annotationCommentsEmails}
                                            uuid={this.props.uuid}
                                            type={this.props.type}
                                            width="750"
                                            height="480"
                                        />
                                    }
                                    {
                                        this.props.isAnnotation && 
                                        <AnnotationReferenceCombination style={{width: 100+'%', height: 165+'px'}}
                                            value={this.props.referenceUuid}
                                            uuid={this.props.uuid}
                                            type={this.props.type}
                                            width="750"
                                            height="480"
                                        />
                                    }
                                </Col>
                            </Row>
                    </Modal.Body>
                    </Modal>
                </>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationModal);