import React, {Component} from "react";
import { connect } from 'react-redux';
import {Table, Col, Row, Spinner, Button} from "react-bootstrap";
import Combination from "./Combination";
import './Combinations.css';
import './Controls.css';
import {Link} from "react-router-dom";
import axios from '../../../axios';
import {FileLoader, Vector2, TextureLoader, DefaultLoadingManager} from "three";
import {
    getActivityInfo
} from "../../../actions/actionsCreators";
import jwt_decode from "jwt-decode";

class Activity extends Component {
    state = {
        userInfo: '',
        deleting: false
    }

    /**
     * Will set to false all embedded and widgets checkboxes so that we can track which one is checked
     */
    componentDidMount() {
        
    }


    render() {
        let combinations = ''
        let loading = this.props.combinations.loading

        if (!loading) {
            combinations = (
                <Row>
                    <Col md={{ span: 12}}>
                        Used: <b>{this.props.combinations.remain}</b> clicks out of <b>{this.props.combinations.default}</b> (will reset on {this.props.combinations.expired})
                    </Col>
                    <Col md={{ span: 12}}>
                        Extra clicks: <b>{this.props.combinations.extra}</b> (don't expire)
                    </Col>
                </Row>
            )
        }

        if (loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }



        return (
            combinations
        )
    }
}

const mapStateToProps = state => {
    console.log(state.combinations);
    return {
        combinations: state.combinations
    }
}
const mapDispatchToProps = {
    getActivityInfo: getActivityInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity);