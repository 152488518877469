import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {
    
    changeMaxAzimuthAngle,
    changeMinAzimuthAngle
} from "../../../../actions/actionsCreators";

class MenuItemInputRangeMinAzimuth extends Component {
    state = {
        value: this.props.value ? this.props.value : '-Infinity'
    };

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value, additional) => {
        switch (type) {
            
            case 'maxAzimuthAngle':
                this.props.changeMaxAzimuthAngle(value)
                break;
            case 'minAzimuthAngle':
                this.props.changeMinAzimuthAngle(value)
                break;
            default:
                return null;
        }
    }

    /**
     * Checks if Value entered is a number
     * Then updates state when input text box is used for changing this field
     */
    handleTextBoxChange = event => {
        // let inputVal = event.target.value
        // if (!isNaN(inputVal) && inputVal <= this.props.max && inputVal >= this.props.min) {
        //     this.setState({[event.target.name]: event.target.value});
        //     this.dispatchAction(this.props.type, event.target.value)
        // }
        this.setState({[event.target.name]: event.target.value});
        this.dispatchAction(this.props.type, Number(event.target.value))
    }

    /**
     * Update state when slider is used for changing this field
     */
    handleSliderChange = (value) => {
        this.setState({
            value: parseFloat(value.toFixed(2))
        })
        this.dispatchAction(this.props.type, value)
        if(this.props.isNeedValueChange){
            this.props.valueChange('asd')
        }
    }

    resetValue = (value) => {
        this.setState({
            value: this.props.originalValue
        })
        this.dispatchAction(this.props.type, this.props.originalValue)
        if(this.props.isNeedValueChange){
            this.props.valueChange('asd')
        }
    }

    render() {
        return (
            <Row /*{...this.props}*/>
                <Col md={12} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={12} className="input-range-slider">
                    <InputRange
                        step={this.props.step ? this.props.step : 0.01}
                        maxValue={this.props.max}
                        minValue={this.props.min}
                        value={this.state.value}
                        onChange={this.handleSliderChange}/>
                </Col>
                <Col md={6} className="setting-col-input">
                    <Form.Control
                        autoComplete="off"
                        size="sm"
                        type="text"
                        className="menu-item-text-box"
                        name="value"
                        value={this.state.value}
                        onChange={this.handleTextBoxChange}
                        disabled={this.props.disableInput ? this.props.disableInput : false}
                        />
                </Col>
                <Col md={6} className="setting-col-input">
                        <button id="resetAzimuth"
                        value={this.state.value} style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.resetValue} >Reset</button>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    
    changeMaxAzimuthAngle:changeMaxAzimuthAngle,
    changeMinAzimuthAngle:changeMinAzimuthAngle
}

export default connect(null, mapDispatchToProps)(MenuItemInputRangeMinAzimuth)