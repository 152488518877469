import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import Clipboard from 'react-clipboard.js';

function MenuItemTextArea(props) {
    const [value, setState] = useState({
        value: props.value ? props.value : ''
    })

    const updateField = (e) => {
        setState({
            ...value,
            value: e.target.value
        });

        dispatchAction(props.type, e.target.value)
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
    }

    return (
        <Row /*{...props}*/>
            <Col md={12} className="" style={{marginTop: 10, marginBottom: 10}}>
                <Form.Control
                    id="textarea-field"
                    rows={6}
                    as="textarea"
                    value={'<html><head><meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1"></head><body><iframe style="border: 1px solid #c5c5c5;" title="CadGL 3D Configurator"  src="'+window.location.protocol+'//'+window.location.hostname +'/' + `` + props.type + `/` + props.value +'" width="'+props.width+'" height="'+props.height+'" frameborder="0" allowfullscreen="allowfullscreen"></iframe></body></html>'}
                    disabled={true}
                    readOnly={true}/>
                <Clipboard style={{marginBottom: 10}} className="btn btn-primary" data-clipboard-text={'<html><head><meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1"></head><body><iframe style="border: 1px solid #c5c5c5;" title="CadGL 3D Configurator"  src="'+window.location.protocol+'//'+window.location.hostname +'/' + `` + props.type + `/` + props.value +'" width="'+props.width+'" height="'+props.height+'" frameborder="0" allowfullscreen="allowfullscreen"></iframe></body></html>'}>
                    Copy Iframe
                </Clipboard>
                <Clipboard className="btn btn-primary" data-clipboard-text={window.location.protocol+'//'+window.location.hostname +'/' + `` + props.type + `/` + props.value}>
                    Copy Link
                </Clipboard>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(MenuItemTextArea)