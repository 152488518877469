import {connect} from "react-redux";
import MyAccount from "../../components/Configurator/Sidebar/MyAccount/MyAccount";

const mapStateToProps = (state) => {
    console.log((state.sceneConfig));
    return {
        api: state.sceneConfig.api,
        config: state.sceneConfig.config,
        geometry: state.sceneConfig.geometry,
        materials: state.sceneConfig.materials,
        arSettings: state.sceneConfig.arSettings,
        annotation: state.sceneConfig.annotation,
        enableAnnotations: state.sceneConfig.enableAnnotations,
        userPermission: state.user.userPermission,
        sharpnessStatus: state.sceneConfig.sharpnessStatus
    }
}

export default connect(mapStateToProps, null)(MyAccount)