import React from "react";
import {Col, Row} from "react-bootstrap";

function ColorLabel(props) {
    return (
        <Col md={3} className="combination-color-label">
            <Row>
                <Col md={12} className="comb-color-circle-col">
                    <span style={{ backgroundColor: props.colorCode }} className="combination-circle"></span>
                    <span>Color name</span>
                </Col>
            </Row>
        </Col>
    )
}

export default ColorLabel