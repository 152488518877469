import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Form, Button, Tabs, Tab, Accordion, Table, Col, Row} from "react-bootstrap";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import {SpinnerSmall} from "../../../helpers/spinners";
import closeIcon from "../../../assets/close-icon@2x.png";
import uploadIcon from '../../../assets/upload.png';
import MenuItemTextArea from "../../Configurator/Sidebar/MenuItems/MenuItemTextArea";
import Clipboard from 'react-clipboard.js';

import {updateCombination, loadCombinations} from '../../../actions/actionsCreators';

class UploadFileModal extends Component {
    state = {
        isShow: false,
        file: null,
        path: ''
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    setShow = () => {
        this.setState({
            isShow : true
        })
    }
    setHide = () => {
        this.setState({
            isShow : false
        })
    }

    chooseUploadFile = (e) => {
        const files = Array.from(e.target.files);
        this.setState({
            file : files[0]
        })
    }

    upload = () => {
        
        let data = new FormData();
        data.append('label', this.state.file);
        data.append('uuid', this.props.uuid);
        if(this.props.email){
            data.append('email', this.props.email);
        }
        
        axios.post('user/upload-public-file', data
        ).then(response => {
            let path = response.data.data.path;
            this.setState({
                path : path
            })
        });
    }

    render() {
        return(
            <React.Fragment>
                <>
                    <div style={{fontSize: 10 + 'pt', cursor: 'pointer', paddingTop: 10+'px', paddingBottom: 15+'px', marginTop: 5}} id="combination-comment-upload" className="panel-combination-info">
                        <div onClick={this.setShow} ><img src={uploadIcon} style={{width: 25}} /> Upload a file</div>
                        <Modal className="modalSize50 modal-upload-file" size="xl" show={this.state.isShow} onHide={this.setHide} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton className="rename-material-modal-title">
                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                <div>
                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.setHide}/>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={{span: 7}} md={{ span: 8}} style={{color: 'white'}}>
                                        <input onChange={this.chooseUploadFile} type="file"  />
                                    </Col>
                                    <Col xs={{ span: 5}} md={{ span: 4}} style={{color: 'white', maxWidth: 33.33+'%'}}>
                                        <button id="" onClick={this.upload} className="btn btn-primary" style={{width: 100+'%'}}>Upload</button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 7}} md={{ span: 8}} style={{color: 'white', marginTop: 10}}>
                                        <input value={this.state.path} readOnly={true} className="form-control"  />
                                    </Col>
                                    <Col xs={{ span: 5}} md={{ span: 4}} style={{color: 'white', marginTop: 10, maxWidth: 33.33+'%'}}>  
                                        <Clipboard style={{width: 100+'%'}} className="btn btn-primary" data-clipboard-text={this.state.path}>
                                            Copy 
                                        </Clipboard>
                                    </Col>

                                                      
                                </Row>
                        </Modal.Body>
                        </Modal>
                    </div>
                    
                </>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(UploadFileModal);