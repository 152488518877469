import { combineReducers } from "redux";
import sceneConfig from './sceneConfigReducer';
import tracker from './trackerReducer';
import selectedObjects from './selectedObjectReducer';
import presetLibrary from './presetsLibraryReducer';
import combinations from './combinationsReducer';
import iframe from './iframeReducer';
import user from './userReducer'

export default combineReducers({
    sceneConfig,
    selectedObjects,
    tracker,
    presetLibrary,
    combinations,
    iframe,
    user
})