import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form, Button} from "react-bootstrap";
import Clipboard from 'react-clipboard.js';
import axios from '../../../../axios';

function AnnotationReferenceCombination(props) {
    const [value, setState] = useState({
        value: props.value ? props.value : ''
    })

    const updateField = (e) => {
        console.log(props.value);
        setState({
            ...value,
            value: e.target.value
        });
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
    }

    const sendInvitation = () => {

        let payload = {
            originalUuid: props.uuid,
            referenceUuid: value.value
        }

        axios.post('combination/refer-to-combination', payload).then(result => {
            alert('Success');
        });
    }

    return (
        <Row /*{...props}*/>
            <Col md={7} className="" style={{marginTop: 10, marginBottom: 10}}>
                <Form.Control
                placeholder="Enter Original Combination Uuid"
                    onChange={updateField}
                    value={value.value}/>
            </Col>
            <Col md={5} className="" style={{marginTop: 10, marginBottom: 10}}>
                <Button onClick={()=>sendInvitation()}  className="btn btn-danger ">Show History</Button>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(AnnotationReferenceCombination)