import React from "react";
import {Col, Row} from "react-bootstrap";
import DeletePresetModal from "./DeletePresetModal";
import EditPresetModal from "./EditPresetModal";
import ApplyPresetModal from "./ApplyPresetModal";
import PresetName from "./PresetName";

const Preset = (props) => {
    const displayData = props.user.userPermission.role == 'admin' ? true : false;

    return (
        <Row>
            <Col md={12} className="preset-col">
                {/* <span>{props.name}</span> */}
                <PresetName layerUuid={props.layerUuid} name={props.name}  uuidPreset={props.uuidPreset} uuidPresetFolder={props.uuidPresetFolder}/>
                <DeletePresetModal uuidPreset={props.uuidPreset} uuidPresetFolder={props.uuidPresetFolder}/>
                <EditPresetModal uuidPreset={props.uuidPreset} uuidPresetFolder={props.uuidPresetFolder} folderName={props.name} price={props.price} />
                {/* <ApplyPresetModal uuidPreset={props.uuidPreset} uuidPresetFolder={props.uuidPresetFolder}/> */}
            </Col>
            {
            displayData  ?
                <Col md={12} className="preset-col" style={{borderTop: 0}}>
                    <input className="form-control" type="text" value={typeof props.data === 'object' ? JSON.stringify(props.data) : props.data} />
                </Col>
            : ''
            }
        </Row>
    )
}

export default Preset