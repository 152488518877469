
const {TextureExporter} = window.CADEDITOR.icad;

export const modeOptions = [
    {value: 'fixed', label: 'Fixed'},
    {value: 'orbit', label: 'Orbit'},
]

export const downscaleLevels = [
    {value: 1, label: '1'},
    {value: 2, label: '2'}
]

export const groundShadowSides = [
    {value: 1, label: 'UP'},
    {value: 2, label: 'DOWN'},
    {value: 3, label: 'LEFT'},
    {value: 4, label: 'RIGHT'},
    {value: 5, label: 'FRONT'},
    {value: 6, label: 'BACK'},
]

export const mapWrapUV = [
    {value: 33071, label: 'Clamp to Edge', subVal: 1001},
    {value: 33648, label: 'Mirrored Repeat', subVal: 1002},
    {value: 10497, label: 'Repeat', subVal: 1000},
    
    // {value: 1001, label: 'CLAMP_TO_EDGE'},
    // {value: 1002, label: 'MIRRORED_REPEAT'},
    // {value: 1000, label: 'REPEAT'},
]

export const colorMapEncoding = [
    {value: TextureExporter.WEBGL_CONSTANTS.LINEAR_ENCODING, label: 'Linear'},
    {value: TextureExporter.WEBGL_CONSTANTS.SRGB_ENCODING, label: 'SRGB'},
]

export const normalMapType = [
    {value: 'object_space', label: 'object_space'},
    {value: 'tangent_space', label: 'tangent_space'},
]
