import React, {Component} from "react";
import {connect} from "react-redux";
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form} from "react-bootstrap";
import axios from '../../../axios';

class CombinationsSizeAllowComment extends Component {
    state = {
        allowComment: this.props.allowComment ? this.props.allowComment : false,
    }

    publicChange = (e) => {
        let payload = {
            id: this.props.uuid,
            status: !this.state.allowComment
        };
        this.setState({
            allowComment: !this.state.allowComment
        })

        console.log(payload);
        axios.post('combination/update-allow-comment', payload).then(result => {
        });
    }


    render() {
        return(
            <React.Fragment>
                <Form.Group className="update-combination-name">
                    <input className="form-control chk-allow-comment" type="checkbox" defaultChecked={this.state.allowComment == true || this.state.allowComment == "true" ? true : false}
                    onChange={()=>this.publicChange()} />
                </Form.Group>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationsSizeAllowComment);