import {
    REQUEST_USER_PERMISSION
} from "../actions/actionTypes";

const initialState = {
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_USER_PERMISSION:
            return {
                ...state,
                userPermission: action.userPermission,
            }
        default:
            return state;
    }
}

export default reducer;