import {
    CHANGE_MATERIAL_COLOR,
    SELECT_OBJECT,
    CHANGE_BOOST_FACTOR_SETTING,
    CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING,
    CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING,
    CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING,
    CHANGE_SQUASH_FACTOR_DIAMOND_SETTING,
    CHANGE_DISPERSION_DIAMOND_SETTING,
    CHANGE_ABSORPTION_DIAMOND_SETTING,
    CHANGE_GAMMA_FACTOR_DIAMOND_SETTING,
    MAKE_STANDARD,
    MAKE_DIAMOND,
    MAKE_REFRACTION,
    CHANGE_PICKED_GROUP_VISIBILITY,
    CHANGE_STANDARD_MATERIAL_COLOR,
    CHANGE_STANDARD_MATERIAL_OPACITY,
    CHANGE_STANDARD_MATERIAL_ROUGHNESS,
    CHANGE_STANDARD_MATERIAL_METALNESS,
    CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY,
    CHANGE_STANDARD_MATERIAL_BUMP_SCALE,
    CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH,
    CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR,
    CHANGE_STANDARD_MATERIAL_MAP_FILE,
    CHANGE_REFRACTION_MATERIAL_VISIBILITY,
    CHANGE_REFRACTION_MATERIAL_TINT_COLOR,
    CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_METALNESS,
    CHANGE_REFRACTION_MATERIAL_TRANSPARENCY,
    UPLOAD_MATERIAL,
    SELECT_MATERIAL,
    APPLY_PRESET,
    SELECT_MESH,
    UPLOAD_MATERIALS,
    CHANGE_SIDE,

    CHANGE_NORMAL_SCALE_X,
    CHANGE_NORMAL_SCALE_Y,
    CHANGE_NORMAL_MAP_TYPE
} from "../actions/actionTypes";

const initialState = {
    object: {},
    material: {},
    pickedObject: {},
    materialId: null,
    selected: false,
    objects: [],
    materialStandardDefaultSettings: {
        "visible": true,
        "color": "#ffffff",
        "opacity": 1,
        "roughness": 1,
        "metalness": 1,
        "map": null,
        "lightMap": null,
        "lightMapIntensity": 1,
        "aoMap": null,
        "aoMapIntensity": 1,
        "emissive": "#000000",
        "emissiveIntensity": 1,
        "emissiveMap": null,
        "bumpMap": null,
        "bumpScale": 1,
        "normalMap": null,
        "normalScale": [1, 1],
        "displacementMap": null,
        "displacementScale": 1,
        "displacementBias": 0,
        "roughnessMap": null,
        "metalnessMap": null,
        "alphaMap": null,
        "envMapIntensity": 2,
        "refractionRatio": 0.98,
        "wireframe": false,
        "wireframeLinewidth": 1,
        "skinning": false,
        "vertexTangents": false,
        "morphTargets": false,
        "morphNormals": false,
        "bevelRadius": 3,
        "normalScaleX": 1,
        "normalScaleY": 1
    },
    materialDiamondDefaultSettings: {
        "visible": true,
        "color": "#ffffff",
        "boostFactors": {"x": 0.892, "y": 0.892, "z": 0.98595025},
        "envMapIntensity": 1,
        "refractiveIndex": 2.4,
        "geometryFactor": 0.28,
        "gammaFactor": 1,
        "squashFactor": 0.98,
        "absorptionFactor": 1,
        "dispersion": 0.012,
        "isDiamond": true
    },
    materialRefractionDefaultSettings: {
        "visible": true,
        "diffuse": "#eeeeee",
        "refractionTintColor": "#ffffff",
        "refractionIndex": 1.1,
        "roughness": 0.01,
        "metalness": 0,
        "transparency": 0.95,
        "refractionRoughness": 0.15,
        "isRefraction": true
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_OBJECT:
            return {
                ...state,
                object: action.object,
                selected: action.selected,
                material: action.material,
                pickedObject: action.pickedObject,
                materialId: action.materialId,
                objects: action.objects,
                mapType: null
            }
        case CHANGE_MATERIAL_COLOR:
        case CHANGE_BOOST_FACTOR_SETTING:
        case CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING:
        case CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING:
        case CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING:
        case CHANGE_SQUASH_FACTOR_DIAMOND_SETTING:
        case CHANGE_DISPERSION_DIAMOND_SETTING:
        case CHANGE_ABSORPTION_DIAMOND_SETTING:
        case CHANGE_GAMMA_FACTOR_DIAMOND_SETTING:
        case CHANGE_STANDARD_MATERIAL_COLOR:
        case CHANGE_STANDARD_MATERIAL_OPACITY:
        case CHANGE_STANDARD_MATERIAL_ROUGHNESS:
        case CHANGE_STANDARD_MATERIAL_METALNESS:
        case CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_BUMP_SCALE:
        
        case CHANGE_NORMAL_SCALE_X:
        case CHANGE_NORMAL_SCALE_Y:
        case CHANGE_NORMAL_MAP_TYPE:

        case CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH:
        case CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME:
        case CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR:
        case CHANGE_STANDARD_MATERIAL_MAP_FILE:
            return {
                ...state,
                material: action.selectedMaterial,
                mapType: action.mapType
            }
        case CHANGE_REFRACTION_MATERIAL_VISIBILITY:
        case CHANGE_REFRACTION_MATERIAL_TINT_COLOR:
        case CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR:
        case CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX:
        case CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS:
        case CHANGE_REFRACTION_MATERIAL_ROUGHNESS:
        case CHANGE_REFRACTION_MATERIAL_METALNESS:
        case CHANGE_REFRACTION_MATERIAL_TRANSPARENCY:
            return {
                ...state,
                material: action.selectedMaterial
            }
        case MAKE_STANDARD:
        case MAKE_DIAMOND:
        case MAKE_REFRACTION:
        case UPLOAD_MATERIAL:
            return {
                ...state,
                object: action.object,
                material: action.material,
                materialId: action.materialId,
                selected: action.selected,
                objects: action.objects
            }
        case SELECT_MATERIAL:
        case APPLY_PRESET:
            return {
                ...state,
                object: action.object,
                material: action.material,
                materialId: action.materialId,
                selected: action.selected,
                objects: action.objects
            }
        case SELECT_MESH:
            return {
                ...state,
                material: action.material,
                materialId: action.materialId,
                object: action.object,
                objects: action.objects,
                pickedObject: action.pickedObject,
                selected: action.selected,
                mapType: null
            }
        case UPLOAD_MATERIALS:
            return {
                ...state,
                object: action.object,
                material: action.material,
                pickedObject: action.pickedObject,
                materialId: action.materialId,
                selected: action.selected,
                objects: action.objects,
            }
        case CHANGE_SIDE:
            return{
                ...state,
                material: action.selectedMaterial
            }
        case CHANGE_PICKED_GROUP_VISIBILITY:
            
        default:
            return state;
    }
}

export default reducer