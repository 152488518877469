
import jwt_decode from "jwt-decode";
import * as commonFunction from "../components/CommonFunction/CommonFunction.js";
import {
    CHANGE_ABSORPTION_DIAMOND_SETTING,
    CHANGE_AUTO_ROTATE_SPEED_SETTING,
    CHANGE_BEVEL_SETTING,
    CHANGE_BLOOM_RADIUS_SETTING,
    CHANGE_BLOOM_STRENGTH_SETTING,
    CHANGE_BLOOM_THRESHOLD_SETTING,
    CHANGE_BLUR_RADIUS_SETTING,
    CHANGE_BOOST_FACTOR_SETTING,
    CHANGE_CAMERA_MODE_SETTING,
    CHANGE_CAMERA_POSITION_X_SETTING,
    CHANGE_CAMERA_POSITION_Y_SETTING,
    CHANGE_CAMERA_POSITION_Z_SETTING,
    CHANGE_CAMERA_SETTING,
    CHANGE_CAMERA_TARGET_SETTING,
    CHANGE_CAMERA_ZOOM,
    CHANGE_CROSS_COLOR_SETTING,
    CHANGE_CROSS_RADIUS_SETTING,
    CHANGE_DISPERSION_DIAMOND_SETTING,
    CHANGE_REFLECTIVITY_DIAMOND_SETTING,
    CHANGE_DOWN_SAMPLE_RATIO_SETTING,
    CHANGE_EDGE_GLOW_SETTING,
    CHANGE_EDGE_STRENGTH_SETTING,
    CHANGE_EDGE_THICKNESS_SETTING,
    CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING,
    CHANGE_ENV_MAP_ROTATION_SETTING,
    CHANGE_FALLOFF_SETTING,
    CHANGE_FAR_BLUR_SCALE_SETTING,
    CHANGE_DEPTH_RANGE_SETTING,
    CHANGE_FOV_SETTING,
    CHANGE_GAMMA_FACTOR_DIAMOND_SETTING,
    CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING,
    CHANGE_GROUND_REFLECTION_METALNESS_SETTING,
    CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING,
    CHANGE_GROUND_REFLECTION_SIZE_SETTING,
    CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING,
    CHANGE_GROUND_SHADOW_SIDE_SETTING,
    CHANGE_GROUND_SHADOWS_SETTING,
    CHANGE_GROUND_SHARPNESS_SETTING,
    CHANGE_HIDDEN_EDGE_COLOR_SETTING,
    CHANGE_MATERIAL_COLOR,
    CHANGE_NEAR_BLUR_SCALE_SETTING,
    CHANGE_NUM_SAMPLES_PER_FRAME_SETTING,
    CHANGE_NUM_SAMPLES_SETTING,
    CHANGE_OBJECT_SCALE_SETTING,
    CHANGE_PULSE_PERIOD_SETTING,
    CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR,
    CHANGE_REFRACTION_MATERIAL_METALNESS,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_TINT_COLOR,
    CHANGE_REFRACTION_MATERIAL_TRANSPARENCY,
    CHANGE_REFRACTION_MATERIAL_VISIBILITY,
    CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING,
    CHANGE_RETICLE_COLOR_SETTING,
    CHANGE_RETICLE_FADE_THRESH_SETTING,
    CHANGE_RETICLE_FADE_TIME_SETTING,
    CHANGE_RETICLE_SIZE_IN_SETTING,
    CHANGE_RETICLE_SIZE_OUT_SETTING,
    CHANGE_SCENE_VIEW,
    CHANGE_SHARPNESS_SETTING,
    CHANGE_SMOOTH_TRANSITION_SETTING,
    CHANGE_SQUASH_FACTOR_DIAMOND_SETTING,
    CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING,
    CHANGE_SSAO_INTENSITY_SETTING,
    CHANGE_SSAO_RADIUS_SETTING,
    CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_BUMP_SCALE,
    CHANGE_STANDARD_MATERIAL_COLOR,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY,
    CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME,
    CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_MAP_FILE,
    CHANGE_STANDARD_MATERIAL_METALNESS,
    CHANGE_STANDARD_MATERIAL_OPACITY,
    CHANGE_STANDARD_MATERIAL_ROUGHNESS,
    CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH,
    CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING,
    CHANGE_TONE_MAPPING_EXPOSURE_SETTING,
    CHANGE_TONE_MAPPING_SATURATION_SETTING,
    CHANGE_VISIBLE_EDGE_COLOR_SETTING,
    CLEAR_ALL,
    DOWNLOAD_GLTF,
    DOWNLOAD_REFLECTION,
    ENABLE_AR,
    ENABLE_AUTO_ROTATE,
    ENABLE_BLOOM_SETTING,
    ENABLE_DOF,
    ENABLE_EDIT_DOF,
    ENABLE_GROUND_REFLECTION_PHYSICAL_SETTING,
    ENABLE_GROUND_REFLECTION_SETTING,
    ENABLE_GROUND_SHADOW_SETTING,
    ENABLE_SHARPNESS_SETTING,
    ENABLE_SMOOTH_SETTING,
    ENABLE_SSAO_DEBUG_SETTING,
    ENABLE_SSAO_SETTING,
    LOAD_FILE_ACTION,
    LOAD_INITIAL_REPORT_REQUEST,
    LOAD_REFLECTION,
    LOAD_REPORT_REQUEST,
    LOAD_REPORT_SUCCESS,
    MAKE_DIAMOND,
    MAKE_REFRACTION,
    MAKE_STANDARD,
    CHANGE_PICKED_GROUP_VISIBILITY,
    REMOVE_BACKGROUND,
    RESET_STRAIGHTEN_MODEL,
    SAVE_API_TO_STATE,
    SAVE_SCENE_CONFIG_TO_STATE,
    SELECT_MATERIAL,
    SELECT_OBJECT,
    SET_BACKGROUND_COLOR,
    SNAP_CANVAS,
    TAKE_SCREENSHOT,
    UPLOAD_MATERIAL,
    UPLOAD_SCENE,
    VIEW_REFLECTION,
    CHANGE_MATERIAL_NAME,
    REQUEST_PRESETS_FAILED,
    REQUEST_PRESETS_SUCCESS,
    DELETED_PRESET_FOLDER,
    EDIT_PRESET_FOLDER,
    DELETED_PRESET,
    EDIT_PRESET,
    SAVED_PRESET,
    SAVED_PRESET_FOLDER,
    APPLY_PRESET,
    REQUEST_COMBINATIONS_LIST,
    REQUEST_COMBINATIONS_SUCCESS,
    REQUEST_COMBINATIONS_FAILED,
    REQUEST_COMBINATIONS_ERROR,
    REQUEST_CREATE_COMBINATIONS_SUCCESS,
    REQUEST_CREATE_COMBINATIONS_FAILED,
    REQUEST_CREATE_COMBINATIONS_ERROR,
    CLONE_COMBINATION,
    DELETE_COMBINATION,
    UPDATE_COMBINATION,
    SELECT_MESH,
    UPLOAD_MATERIALS,
    LOAD_COMBINATION,
    IFRAME_TAKE_SCREENSHOT,
    ENABLE_AR_IFRAME,
    RESET_CAMERA_IFRAME,
    REQUEST_TRACKERS_FILTERS,
    REQUEST_TRACKERS_FILTERS_SUCCESS,
    REQUEST_TRACKERS_FILTERS_FAILED,
    REQUEST_TRACKERS_FILTERS_ERROR,
    LOAD_REPORT_ERROR,
    LOAD_REPORT_FAILED,
    ENABLE_ROTATION_IFRAME,
    CHANGE_BACKGROUND_IMAGE_FILE,
    CHANGE_TONE_MAPPING_CONTRAST_SETTING,
    ENABLE_SCREEN_SPACE_PANNING,
    ENABLE_ZOOM,
    ENABLE_PAN,
    UPLOAD_FILES,
    CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS, 

    
    CHANGE_MIN_SPEED,
    CHANGE_MAX_SPEED,

    CHANGE_MIN_DISTANCE,
    CHANGE_MAX_DISTANCE,
    CHANGE_MAX_ZOOM,
    CHANGE_MIN_ZOOM,
    CHANGE_MAX_POLAR_ANGLE,
    CHANGE_MIN_POLAR_ANGLE,
    CHANGE_MAX_AZIMUTH_ANGLE,
    CHANGE_MIN_AZIMUTH_ANGLE,
    CHANGE_PIXEL_RATIO_SETTING,
    RESET_PRESET,
    LOGIN,
    ADD_PRESET_TO_LIST,
    CHANGE_MAP_REPEAT_U,
    CHANGE_MAP_REPEAT_V,
    LOGO_TOGGLE,
    LOGO_STAY_DURATION,
    LOGO_FADE_IN_DURATION,
    LOGO_FADE_OUT_DURATION,
    LOGO_SCALE,
    LOGO_POSITION_X,
    LOGO_POSITION_Y,
    LOAD_PRESET_LAYER,
    LOAD_PRESET_FOLDER_LAYER,
    ENABLE_ANNOTATIONS,
    SELECT_ANNOTATIONS,
    TEXT_ANNOTATIONS,
    TITLE_ANNOTATIONS,
    REMOVE_ANNOTATIONS,
    ENABLE_TAA,
    ENABLE_SSR,
    INTENSITY_SSR,
    REQUEST_COMBINATIONS_SIZE_SUCCESS,
    REQUEST_IMAGES_SIZE_SUCCESS,
    REQUEST_ACTIVITY_SUCCESS,
    CHANGE_SIDE,
    REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS,
    REQUEST_TEXTURES_SIZE_SUCCESS,
    REQUEST_TRANSACTION_SUCCESS,
    REQUEST_USER_PERMISSION,
    CHANGE_CAMERA_POSITION_SETTING,
    CHANGE_DAMPING_FACTOR,
    CHANGE_ZOOM_SPEED,
    ANIMATION_TIME_SCALE,
    ANIMATION_LOOP,
    ANIMATION_PLAY,
    ANIMATION_STOP,

    ADD_AMBIENT,
    AUDIO_PLAY,
    AUDIO_STOP,
    AUDIO_LOOP,
    AUDIO_VOLUME,
    AUDIO_REMOVE,

    ADD_AUDIO,
    AUDIO_MATERIAL_VOLUME,
    AUDIO_MATERIAL_LOOP,
    AUDIO_MATERIAL_REMOVE,
    FEEDBACK_MIN,
    FEEDBACK_MAX,

    GET_STORAGE_SUMMARY,
    CHANGE_FAR_NEAR_MULTIPLIER,
    CHANGE_MIN_CAMERA_CLIP,

    ENABLE_FRUSTUM_CULLING,

    MAP_WRAP_U,
    MAP_WRAP_V,
    MAP_ENCODING,
    CHANGE_NORMAL_SCALE_X,
    CHANGE_NORMAL_SCALE_Y,
    CHANGE_NORMAL_MAP_TYPE,
    SCENE_INFO_ALERT,
    SWITCH_OLD_NEW_CAMERA,

    GIZMO_ENABLED,
    GIZMO_CHANGE_MODE
} from './actionTypes';
import axios from '../axios';
import {RepeatWrapping, MathUtils, Color, LinearEncoding, sRGBEncoding} from "three";
// import {TextureExporter} from '../cadcentre/dist/cadcenter';
// const {TextureExporter} = require("../cadcentre/dist/cadcenter");
const {TextureExporter} = window.CADEDITOR.icad;

export const changeMinCameraNearClip = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.setMinCameraNearClip(value);
        dispatchEvent({
            type: CHANGE_MIN_CAMERA_CLIP,
        })
    }
}

export const changeNearFarMultiplier = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.setCameraNearFarMultiplier(value);
        dispatchEvent({
            type: CHANGE_FAR_NEAR_MULTIPLIER,
        })
    }
}

export const feedBackMax = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let taaParams = sceneConfig.config.taa != undefined ? sceneConfig.config.taa : api.getTAAParams();
        taaParams.feedBackMax = value;
        api.setTAAParams(taaParams);
        taaParams = api.getTAAParams();
        console.log(taaParams);
        
        sceneConfig.config.taa = taaParams;
        dispatchEvent({
            type: FEEDBACK_MAX,
            sceneConfig: sceneConfig,
        })
    }
}

export const feedBackMin = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let taaParams = sceneConfig.config.taa != undefined ? sceneConfig.config.taa : api.getTAAParams();
        taaParams.feedBackMin = value;
        api.setTAAParams(taaParams);
        taaParams = api.getTAAParams();
        console.log(taaParams);
        
        sceneConfig.config.taa = taaParams;
        dispatchEvent({
            type: FEEDBACK_MIN,
            sceneConfig: sceneConfig,
        })
    }
}

export const removeAudioMaterial = (obj) =>{
    console.log('removeAudioMaterial');
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.removeAudio(obj);
        dispatchEvent({
            type: AUDIO_MATERIAL_REMOVE,
            api: api,
        })
    }
}

export const changeAudioMaterialVolume = (obj, value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        console.log(obj);
        if(api.getAudio(obj)){
            api.getAudio(obj).setVolume(value);
            dispatchEvent({
                type: AUDIO_MATERIAL_VOLUME,
                api: api,
            })
        }
    }
}

export const toggleAudioMaterialLoop = (obj, value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        if(api.getAudio(obj)){
            api.getAudio(obj).setLoop(value);
            dispatchEvent({
                type: AUDIO_MATERIAL_LOOP,
                api: api,
            })
        }
    }
}

export const addAudio = (obj) => {
    console.log(obj);
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.value = null;
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                let url = URL.createObjectURL(file)
                api.addAudio(obj, url)
            }
        }
        inputFile.click();
        dispatchEvent({
            type: ADD_AUDIO,
            api: api,
        })
    }
}


export const changeAudioVolume = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        if(api.getAmbientAudio()){
            api.getAmbientAudio().setVolume(value);
            dispatchEvent({
                type: AUDIO_VOLUME,
                api: api,
            })
        }
    }
}

export const toggleAudioLoop = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        if(api.getAmbientAudio()){
            api.getAmbientAudio().setLoop(value);
            dispatchEvent({
                type: AUDIO_LOOP,
                api: api,
            })
        }
    }
}

export const toggleAudioPlay = () =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.playAllSounds();
        dispatchEvent({
            type: AUDIO_PLAY,
            api: api,
        })
    }
}

export const toggleAudioStop = () =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.stopAllSounds();
        dispatchEvent({
            type: AUDIO_STOP,
            api: api,
        })
    }
}

export const removeAudio = () =>{
    console.log('removeAudio');
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.removeAmbientAudio();
        dispatchEvent({
            type: AUDIO_REMOVE,
            api: api,
        })
    }
}

export const addAmbient = () => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.value = null;
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                let url = URL.createObjectURL(file)
                api.addAmbientAudio(url)
            }
        }
        inputFile.click();
        dispatchEvent({
            type: ADD_AMBIENT,
            api: api,
        })
    }
}

export const changeAnimationTimeScale = (value) =>{
    return (dispatchEvent, getState) => {
        value = value;
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        if(sceneConfig.config.animationParams != undefined){
            sceneConfig.config.animationParams.timescale = value;
            api.setAnimationParams(sceneConfig.config.animationParams);
            dispatchEvent({
                type: ANIMATION_TIME_SCALE,
                sceneConfig: sceneConfig,
                api: api,
            })
        } 
    }
}

export const toggleAnimationLoop = (value) =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        if(sceneConfig.config.animationParams != undefined){
            sceneConfig.config.animationParams.loop = value;
            api.setAnimationParams(sceneConfig.config.animationParams);
            dispatchEvent({
                type: ANIMATION_LOOP,
                sceneConfig: sceneConfig,
                api: api,
            })
        }
    }
}

export const toggleAnimationPlay = () =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        api.playAllAnimations();
        dispatchEvent({
            type: ANIMATION_PLAY,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}

export const toggleAnimationStop = () =>{
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        api.stopAllAnimations();
        dispatchEvent({
            type: ANIMATION_STOP,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}

/*
Enable Logo
*/
export const logoToggle = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.enabled = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        dispatchEvent({
            type: LOGO_TOGGLE,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}
export const logoFadeInDuration = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.logoFadeInDuration = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        document.getElementById("logo_fade_in").value = value;
        dispatchEvent({
            type: LOGO_FADE_IN_DURATION,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}
export const logoFadeOutDuration = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.logoFadeOutDuration = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        document.getElementById("logo_fade_out").value = value;
        dispatchEvent({
            type: LOGO_FADE_OUT_DURATION,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}
export const logoScale = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.scale = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        document.getElementById("logo_scale").value = value;
        dispatchEvent({
            type: LOGO_SCALE,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}
export const logoPositionX = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.positionX = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        document.getElementById("logo_position_x").value = value;
        dispatchEvent({
            type: LOGO_POSITION_X,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}
export const logoPositionY = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.positionY = value;

        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);
        document.getElementById("logo_position_y").value = value;
        dispatchEvent({
            type: LOGO_POSITION_Y,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}

export const logoStayDuration = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let logoParams = api.getLogoParams();

        logoParams.logoStayDuration = value;
        sceneConfig.config.logoParams = logoParams;

        api.setLogoParams(logoParams);

        document.getElementById("logo_duration").value = value;

        dispatchEvent({
            type: LOGO_STAY_DURATION,
            sceneConfig: sceneConfig,
            api: api,
        })
    }
}

/**
 * Set file data to state
 */
export const loadFileAction = (api) => {
    return dispatchEvent => {
        dispatchEvent({
            type: LOAD_FILE_ACTION,
            api: api
        })
    }
}

/**
 * Load tracker report
 */
export const loadInitialReportRequest = () => {
    return dispatchEvent => {
        dispatchEvent({
            type: LOAD_INITIAL_REPORT_REQUEST,
        })
    }
}

/**
 * Save scene config in state, basically all the info that the uploaded json file has
 */
export const saveSceneConfigToState = (sceneConfig, api, type) => {
    return (dispatchEvent, getState) => {
        //Prepare materials list array
        let materialsList = []
        let materialsLibrary = api.getMaterialLibrary()

        for (const [key, value] of Object.entries(sceneConfig.materials)) {
            materialsList.push({label: key, value: key}); //Prepare materialsList array to be used in materials dropdown
        }

        //Disable ground reflection on each model upload by default, by client request
        if (type === 'files') {
            sceneConfig.config.groundReflection.enable = false;
            // api.setGroundReflectionParams(sceneConfig.config.groundReflection)
        }
        api.setGroundReflectionParams(sceneConfig.config.groundReflection)
        var count = 0;
        var getMaterialLibrary = setInterval(() => {
            count ++;
            api.getMaterialLibrary();
            if(count == 5)
            {
                let meshes = ''
                let materials = getState().sceneConfig.api.getMaterialLibrary()
                
                materials.forEach(material => {
                    meshes = meshes == '' ?  material.params.id : (meshes + ';' + material.params.id)
                })

                localStorage.setItem('current-meshes', meshes);

                clearInterval(getMaterialLibrary);
            }
        }, 1000);

        let logoParams = api.getLogoParams();
        sceneConfig.config.logoParams = logoParams;
        api.setLogoParams(sceneConfig.config.logoParams);

        dispatchEvent({
            type: SAVE_SCENE_CONFIG_TO_STATE,
            sceneConfig: sceneConfig,
            api: api,
            materialsList: materialsList,
            materialsLibrary: materialsLibrary
        })
        
        if (type === 'files') {
            sceneConfig.config.camera.orbitParams.zoomSpeed = 0.5;
            dispatchEvent({
                type: CHANGE_ZOOM_SPEED,
                sceneConfig: sceneConfig
            })
        }
        

        let arSettings = api.getXRParams();
        dispatchEvent({
            type: ENABLE_AR,
            arSettings: arSettings
        })


        //Check if exist Local Storage applied Preset for the scene
        setTimeout(() => {
            
            //privateApplyExistingLocalStorageMaterial(api, dispatchEvent, getState)

            //let sceneConfig = {...getState().sceneConfig}
            //sceneConfig.config.camera.zoom = 0.4
            // dispatchEvent({
            //     type: CHANGE_CAMERA_ZOOM,
            //     sceneConfig: sceneConfig
            // })

        }, 3000);

        
        if(localStorage.getItem('user'))
        {
            axios.get('user/get-user-permission').then(response => {
                let {data} = response
                if (data.success) {

                    dispatchEvent({
                        type: REQUEST_USER_PERMISSION,
                        userPermission: data.data,
                    })
                }
            }).catch(error => {
                console.log(error.message)
            })
        }
    }
}

/**
 * Saves an instance of api from cadcentre to state
 * Will be used on ProductRendered.js to call api's
 */
export const saveApiToState = (api) => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: SAVE_API_TO_STATE,
            api: api
        })
    }
}

/**
 * Downloads Scene as json format
 */
export const downloadScene = () => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.exportScene().then(value => {
            let link = document.createElement("a");
            let blob = new Blob([JSON.stringify(value)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            // link.download = "scene.json";
            link.download = document.getElementById("sceneName").value + ".json" ;
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
        });
    }
}

/**
 * Download scene as gltf format
 */
export const downloadGltf = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: DOWNLOAD_GLTF,
            sceneConfig: sceneConfig,
        })
    }
}

/**
 * Snap canvas
 */
export const snapCanvas = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: SNAP_CANVAS,
            sceneConfig: sceneConfig,
        })
    }
}

/**
 * Change Camera Zoom setting
 */
export const changeCameraZoom = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.zoom = value
        dispatchEvent({
            type: CHANGE_CAMERA_ZOOM,
            sceneConfig: sceneConfig
        })
    }
}

/**
 * Change Camera Fov setting
 */
export const changeFovSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.fov = value
        dispatchEvent({
            type: CHANGE_FOV_SETTING,
            sceneConfig: sceneConfig
        })
    }
}

/**
 * Change Scene View
 */
export const changeSceneView = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: CHANGE_SCENE_VIEW,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Edge Glow Setting
 */
export const changeEdgeGlowSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.edgeGlow = value
        dispatchEvent({
            type: CHANGE_EDGE_GLOW_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Edge Thickness Setting
 */
export const changeEdgeThicknessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.edgeThickness = value
        dispatchEvent({
            type: CHANGE_EDGE_THICKNESS_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Edge Strength Setting
 */
export const changeEdgeStrengthSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.edgeStrength = value
        dispatchEvent({
            type: CHANGE_EDGE_STRENGTH_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Pulse Period Setting
 */
export const changePulsePeriodSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.pulsePeriod = value
        dispatchEvent({
            type: CHANGE_PULSE_PERIOD_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Visible Edge Setting
 */
export const changeVisibleEdgeColor = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.visibleEdgeColor = value
        dispatchEvent({
            type: CHANGE_VISIBLE_EDGE_COLOR_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Hidden Edge Color Setting
 */
export const changeHiddenEdgeColor = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.hiddenEdgeColor = value
        dispatchEvent({
            type: CHANGE_HIDDEN_EDGE_COLOR_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Down Sample Ratio Setting
 */
export const changeDownSampleRatio = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.outline.downSampleRatio = value
        dispatchEvent({
            type: CHANGE_DOWN_SAMPLE_RATIO_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Camera Mode Setting
 */
export const changeCameraMode = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.mode = value
        dispatchEvent({
            type: CHANGE_CAMERA_MODE_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

export const changeCameraPositionSetting = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        const {api} = getState().sceneConfig
        if(api)
        {
            let p = api.getCameraParams();
            sceneConfig.config.camera.position = p.position
            dispatchEvent({
                type: CHANGE_CAMERA_POSITION_SETTING,
                sceneConfig: sceneConfig
            })
    
        }
    }
}

/**
 * Change Camera Position X Axis Setting
 */
export const changeCameraPositionXSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.position[0] = value
        dispatchEvent({
            type: CHANGE_CAMERA_POSITION_X_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Camera Position Y Axis Setting
 */
export const changeCameraPositionYSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.position[1] = value
        dispatchEvent({
            type: CHANGE_CAMERA_POSITION_Y_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Camera Position Z Axis Setting
 */
export const changeCameraPositionZSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.position[2] = value
        dispatchEvent({
            type: CHANGE_CAMERA_POSITION_Z_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Camera Target Setting
 */
export const changeCameraTargetSetting = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: CHANGE_CAMERA_TARGET_SETTING,
            sceneConfig: sceneConfig,
        })
    }
}

/**
 * Change Camera Setting
 */
export const changeCameraSetting = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        const {api} = getState().sceneConfig
        
        let p = api.getCameraParams();
        api.resetCamera()
         p = api.getCameraParams();
        sceneConfig.config.camera.position = p.position;
        console.log("action Creator");
        dispatchEvent({
            type: CHANGE_CAMERA_SETTING,
            sceneConfig: sceneConfig,
        })
    }
}

/**
 * Change Bevel Setting
 */
export const changeBevelSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.enableBevel = value
        dispatchEvent({
            type: CHANGE_BEVEL_SETTING,
            sceneConfig: sceneConfig
        })
    }
}

/**
 * Change Sharpness Setting
 */
export const changeSharpnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.sharpness = value
        dispatchEvent({
            type: CHANGE_SHARPNESS_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Toggle Sharpness Setting
 */
export const enableSharpnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: ENABLE_SHARPNESS_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Toggle Ssao Setting
 */
export const enableSsaoSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.ssao.enabled = value
        dispatchEvent({
            type: ENABLE_SSAO_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

export const enableSsaoBlur = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.ssao.blur = value
        sceneConfig.api.setSSAOParams({blur: value})
        dispatchEvent({
            type: ENABLE_SSAO_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Toggle Debug Ssao Setting
 */
export const enableDebugSsaoSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.ssao.enabled) {
            sceneConfig.config.ssao.enableDebug = value
            dispatchEvent({
                type: ENABLE_SSAO_DEBUG_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Ssao Intensity Setting
 */
export const changeSsaoIntensitySetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.ssao.enabled) {
            sceneConfig.config.ssao.intensity = value
            dispatchEvent({
                type: CHANGE_SSAO_INTENSITY_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Ssao Radius Setting
 */
export const changeSsaoRadiusSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.ssao.enabled) {
            sceneConfig.config.ssao.radius = value
            dispatchEvent({
                type: CHANGE_SSAO_RADIUS_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Ssao Downscale Setting
 */
export const changeSsaoDownscaleLevel = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.ssao.enabled) {
            sceneConfig.config.ssao.downscaleLevel = value
            dispatchEvent({
                type: CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Toggle Bloom Setting
 */
export const enableBloomSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.unrealBloom.enabled = value
        dispatchEvent({
            type: ENABLE_BLOOM_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Bloom Strength Setting
 */
export const changeBloomStrengthSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.unrealBloom.enabled) {
            sceneConfig.config.unrealBloom.strength = value
            dispatchEvent({
                type: CHANGE_BLOOM_STRENGTH_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Bloom Radius Setting
 */
export const changeBloomRadiuSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.unrealBloom.enabled) {
            sceneConfig.config.unrealBloom.radius = value
            dispatchEvent({
                type: CHANGE_BLOOM_RADIUS_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Bloom Threshold Setting
 */
export const changeBloomThresholdSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.unrealBloom.enabled) {
            sceneConfig.config.unrealBloom.threshold = value
            dispatchEvent({
                type: CHANGE_BLOOM_THRESHOLD_SETTING,
                sceneConfig: sceneConfig,
                value: value
            })
        }
    }
}

/**
 * Change Straighten Model Rotate Setting
 */
export const changeStraightenModelRotateSetting = value => {
    return (dispatchEvent, getState) => {
        let api = getState().sceneConfig.api;
        console.log("getRotation", api.getRotation());
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

/**
 * Change Straighten Model Setting
 */
export const resetStraightenModel = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: RESET_STRAIGHTEN_MODEL,
            sceneConfig: sceneConfig
        })
    }
}

/**
 * Take screenshot of current state of editor
 */
export const takeScreenshot = () => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: TAKE_SCREENSHOT
        })
    }
}

export const enableGroundShadow = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.groundShadow.enabled = value
        dispatchEvent({
            type: ENABLE_GROUND_SHADOW_SETTING,
            sceneConfig: sceneConfig
        })
    }
}

export const changeNumSamplesSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.numSamples = value
            dispatchEvent({
                type: CHANGE_NUM_SAMPLES_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeNumSamplesPerFrameSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.numSamplesPerFrame = value
            dispatchEvent({
                type: CHANGE_NUM_SAMPLES_PER_FRAME_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeGroundBrightnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.brightness = value
            dispatchEvent({
                type: CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeBlurRadiusSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.blurRadius = value
            dispatchEvent({
                type: CHANGE_BLUR_RADIUS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeGroundSharpnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.sharpness = value
            dispatchEvent({
                type: CHANGE_GROUND_SHARPNESS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeFalloffSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.falloff = value
            dispatchEvent({
                type: CHANGE_FALLOFF_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const enableSmooth = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.enableSmooth = value
            dispatchEvent({
                type: ENABLE_SMOOTH_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const enableSmoothTransition = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.smoothTransition = value
            dispatchEvent({
                type: CHANGE_SMOOTH_TRANSITION_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeGroundShadowSide = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundShadow.enabled) {
            sceneConfig.config.groundShadow.side = value
            dispatchEvent({
                type: CHANGE_GROUND_SHADOW_SIDE_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const enableGroundReflection = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.groundReflection.enable = value
        dispatchEvent({
            type: ENABLE_GROUND_REFLECTION_SETTING,
            sceneConfig: sceneConfig
        })
    }
}

export const enableGroundReflectionPhysical = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable) {
            sceneConfig.config.groundReflection.physical = value
            if (!value) {
                sceneConfig.config.groundReflection.diffuseMap = ''
            }
            dispatchEvent({
                type: ENABLE_GROUND_REFLECTION_PHYSICAL_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeReflectionRoughnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable) {
            sceneConfig.config.groundReflection.roughness = value
            dispatchEvent({
                type: CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeReflectionMetalnessSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable) {
            sceneConfig.config.groundReflection.metalness = value
            dispatchEvent({
                type: CHANGE_GROUND_REFLECTION_METALNESS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeReflectionSizeSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable) {
            sceneConfig.config.groundReflection.size = value
            dispatchEvent({
                type: CHANGE_GROUND_REFLECTION_SIZE_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const viewReflection = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable && sceneConfig.config.groundReflection.physical && sceneConfig.config.groundReflection.diffuseMap !== '') {
            dispatchEvent({
                type: VIEW_REFLECTION,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const downloadReflection = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable && sceneConfig.config.groundReflection.physical && sceneConfig.config.groundReflection.diffuseMap !== '') {
            dispatchEvent({
                type: DOWNLOAD_REFLECTION,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const loadReflection = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.groundReflection.enable && sceneConfig.config.groundReflection.physical && sceneConfig.config.groundReflection.diffuseMap !== '') {
            dispatchEvent({
                type: LOAD_REFLECTION,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const clearAll = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: CLEAR_ALL,
            sceneConfig: sceneConfig
        })
    }
}

export const setBackgroundColor = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: SET_BACKGROUND_COLOR,
            sceneConfig: sceneConfig,
            value: value
        })
    }
}

export const removeBackground = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        dispatchEvent({
            type: REMOVE_BACKGROUND,
            sceneConfig: sceneConfig,
        })
    }
}

export const enableDof = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.dof.enabled = value
        dispatchEvent({
            type: ENABLE_DOF,
            sceneConfig: sceneConfig
        })
    }
}

export const enableEditDof = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.dof.enableEdit = value
        dispatchEvent({
            type: ENABLE_EDIT_DOF,
            sceneConfig: sceneConfig
        })
    }
}

export const changeCrossColor = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.dof.enabled) {
            sceneConfig.config.dof.crossColor = value
            dispatchEvent({
                type: CHANGE_CROSS_COLOR_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeCrossRadiusSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.dof.enabled) {
            sceneConfig.config.dof.crossRadius = value
            dispatchEvent({
                type: CHANGE_CROSS_RADIUS_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeNearBlurScaleSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.dof.enabled) {
            sceneConfig.config.dof.nearBlurScale = value
            dispatchEvent({
                type: CHANGE_NEAR_BLUR_SCALE_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeFarBlurScaleSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.dof.enabled) {
            sceneConfig.config.dof.farBlurScale = value
            dispatchEvent({
                type: CHANGE_FAR_BLUR_SCALE_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}

export const changeDepthRangeSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        if (sceneConfig.config.dof.enabled) {
            sceneConfig.config.dof.depthRange = value
            dispatchEvent({
                type: CHANGE_DEPTH_RANGE_SETTING,
                sceneConfig: sceneConfig
            })
        }
    }
}



export const enableAutoRotate = value => {

    return (dispatchEvent, getState) => {

        if(value){
        }

        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.autoRotate = value
        dispatchEvent({
            type: ENABLE_AUTO_ROTATE,
            sceneConfig: sceneConfig
        })
    }
}

export const screenSpacePanning = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.screenSpacePanning = value
        dispatchEvent({
            type: ENABLE_SCREEN_SPACE_PANNING,
            sceneConfig: sceneConfig
        })
    }
}

export const enableZoom = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.enableZoom = value
        dispatchEvent({
            type: ENABLE_ZOOM,
            sceneConfig: sceneConfig
        })
    }
}

export const enablePan = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.enablePan = value
        dispatchEvent({
            type: ENABLE_PAN,
            sceneConfig: sceneConfig
        })
    }
}

export const changeAutoRotateSpeedSetting = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        // if (sceneConfig.config.camera.orbitParams.autoRotate) {
            sceneConfig.config.camera.orbitParams.autoRotateSpeed = value
            dispatchEvent({
                type: CHANGE_AUTO_ROTATE_SPEED_SETTING,
                sceneConfig: sceneConfig
            })
        // }
    }
}

/**
 * Is used to show on sidebar the material of the selected object
 * @param selectedObjects
 * @param pickedObject
 * @returns {function(...[*]=)}
 */
export const selectObject = (selectedObjects, pickedObject, objs) => {
    return (dispatchEvent, getState) => {
        let selectedObjectsInState = {...getState().selectedObjects}
        let sceneConfig = {...getState().sceneConfig}
        let objects = []
        let materialId = null
        let materialsList = sceneConfig.materialsList

        if (selectedObjects.size > 0) {
            /**
             * Set objects array
             */
            selectedObjects.forEach(object => {
               objects.push({uuid: object.uuid, name: object.name});
            })
            selectedObjectsInState.objects = objects

            /**
             * All the objects that enter this function have same material
             */
            for (const [key, value] of Object.entries(sceneConfig.materials)) {
                value.mesh.map(uuid => {
                    if (uuid === objects[0].uuid) {
                        materialId = key
                    }
                })
            }
        } else {
            selectedObjectsInState.objects = []
        }
        //TODO will remove map property for now because api throws error when changing material properties
        let material = null
        if (materialId) {
            let {map, ...rest} = sceneConfig.materials[materialId.toString()].material
            material = rest
        }

        dispatchEvent({
            type: SELECT_OBJECT,
            object: objects.length !== 0 ? {uuid: pickedObject.uuid, name: pickedObject.name} : {},
            selected: selectedObjects.size > 0,
            materialId: materialId,
            material: material,
            pickedObject: pickedObject,
            materialsList: materialsList,
            objects: objs,
            mapType: null
        })
    }
}

export const changeMaterialColor = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.color = value
        materials[selectedObjects.materialId].material.color = value
        dispatchEvent({
            type: CHANGE_MATERIAL_COLOR,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeBoostFactorXSetting = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.boostFactors.x = value
        materials[selectedObjects.materialId].material.boostFactors.x = value
        dispatchEvent({
            type: CHANGE_BOOST_FACTOR_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeBoostFactorYSetting = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.boostFactors.y = value
        materials[selectedObjects.materialId].material.boostFactors.y = value
        dispatchEvent({
            type: CHANGE_BOOST_FACTOR_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeBoostFactorZSetting = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.boostFactors.z = value
        materials[selectedObjects.materialId].material.boostFactors.z = value
        dispatchEvent({
            type: CHANGE_BOOST_FACTOR_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeEnvMapIntensityDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.envMapIntensity = value
        materials[selectedObjects.materialId].material.envMapIntensity = value
        dispatchEvent({
            type: CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeRefractiveIndexDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.refractiveIndex = value
        materials[selectedObjects.materialId].material.refractiveIndex = value
        dispatchEvent({
            type: CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeGeometryFactorDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.geometryFactor = value
        materials[selectedObjects.materialId].material.geometryFactor = value
        dispatchEvent({
            type: CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeSquashFactorDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.squashFactor = value
        materials[selectedObjects.materialId].material.squashFactor = value
        dispatchEvent({
            type: CHANGE_SQUASH_FACTOR_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeDispersionDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.dispersion = value
        materials[selectedObjects.materialId].material.dispersion = value
        dispatchEvent({
            type: CHANGE_DISPERSION_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeReflectivityDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.reflectivity = value
        materials[selectedObjects.materialId].material.reflectivity = value
        dispatchEvent({
            type: CHANGE_REFLECTIVITY_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
        dispatchEvent({
            type: CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeAbsorptionFactorDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        // selectedObjects.material.absorption = value
        // materials[selectedObjects.materialId].material.absorption = value
        selectedObjects.material.absorptionFactor = value
        materials[selectedObjects.materialId].material.absorptionFactor = value
        dispatchEvent({
            type: CHANGE_ABSORPTION_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeGammaFactorDiamond = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.gammaFactor = value
        materials[selectedObjects.materialId].material.gammaFactor = value
        dispatchEvent({
            type: CHANGE_GAMMA_FACTOR_DIAMOND_SETTING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

/**
 * Show properties for material type diamond
 * Check if the material of the selected object is used by other objects
 * Id yes, then removes this object from material mesh array
 * If not then material is removed completely
 * As defaults, for standard material, are used materialStandardDefaultSettings object in state
 * Note that timestamp is used as new key for adding new material to materials object.
 * If this new key is added as id property in material object itself, the controls will not work. So id should be undefined
 * @param object
 * @returns {function(...[*]=)}
 */
export const makeDiamond = object => {
    return (dispatchEvent, getState) => {
        //first find material that is used by this object
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let currentMaterialId = null // will hold the material that is being used by the picked object
        let objectsWithSameMaterial= null; // will count how many objects use this material
        let sceneConfig = {...getState().sceneConfig}

        for (const [key, data] of Object.entries(materials)) {
            data.mesh.map(uuid => {
                if (uuid === object.uuid) {
                    objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
                    currentMaterialId = key // material id of this object
                }
            })
        }

        //First remove mesh from current material
        materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== selectedObjects.pickedObject.uuid)

        //will create new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
        let newMaterialKey = 'M' + Date.now()
        materials = {
            ...materials,
            [newMaterialKey]: {
                material: {
                    id: newMaterialKey.toString(),
                    ...getState().selectedObjects.materialDiamondDefaultSettings
                },
                mesh: [object.uuid]
            }
        }

        //Add new material to materials list
        let materialsList = sceneConfig.materialsList
        materialsList.push({value: newMaterialKey, label: newMaterialKey})

        //Change object material and dispatch action
        selectedObjects.objects.forEach(obj => {
            sceneConfig.api.importMaterialParams(materials[newMaterialKey].material).then(() => {
                sceneConfig.api.setMaterialParams(obj, {isDiamond: true})
            })
        });
        //Update materials library
        let materialsLibrary = []
        materialsLibrary = sceneConfig.api.getMaterialLibrary()
        //Change selected object state
        dispatchEvent({
            type: MAKE_DIAMOND,
            materials: materials,
            object: {uuid: object.uuid, name: object.name},
            material: materials[newMaterialKey].material,
            materialId: newMaterialKey,
            materialsList: materialsList,
            materialsLibrary: materialsLibrary,
            selected: true,
            objects: [object]
        })
    }
}

/**
 * Show properties for material type standard
 * Check if the material of the selected object is used by other objects
 * Id yes, then removes this object from material mesh array
 * If not then material is removed completely
 * As defaults, for standard material, are used materialStandardDefaultSettings object in state
 * Note that timestamp is used as new key for adding new material to materials object.
 * If this new key is added as id property in material object itself, the controls will not work. So id should be undefined
 * @param object
 * @returns {function(...[*]=)}
 */
export const makeStandard = object => {
    return (dispatchEvent, getState) => {
        //first find material that is used by this object
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let currentMaterialId = null // will hold the material that is being used by the picked object
        let objectsWithSameMaterial= null; // will count how many objects use this material
        let sceneConfig = {...getState().sceneConfig}

        for (const [key, data] of Object.entries(materials)) {
            data.mesh.map(uuid => {
                if (uuid === object.uuid) {
                    objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
                    currentMaterialId = key // material id of this object
                }
            })
        }

        //First remove mesh from current material
        materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== selectedObjects.pickedObject.uuid)

        //will create new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
        let newMaterialKey = 'M' + Date.now()
        materials = {
            ...materials,
            [newMaterialKey]: {
                material: {
                    id: newMaterialKey.toString(),
                    ...getState().selectedObjects.materialStandardDefaultSettings
                },
                mesh: [object.uuid]
            }
        }

        //Add new material to materials list
        let materialsList = sceneConfig.materialsList
        materialsList.push({value: newMaterialKey, label: newMaterialKey})

        //Change object material and dispatch action
        selectedObjects.objects.forEach(obj => {
            sceneConfig.api.importMaterialParams(materials[newMaterialKey].material).then(() => {
                sceneConfig.api.setMaterialParams(obj, {})
            })
        });
    
        //Update materials library
        let materialsLibrary = []
        materialsLibrary = sceneConfig.api.getMaterialLibrary()

        //Change selected object state
        dispatchEvent({
            type: MAKE_STANDARD,
            materials: materials,
            object: {uuid: object.uuid, name: object.name},
            material: materials[newMaterialKey].material,
            materialId: newMaterialKey,
            materialsList: materialsList,
            materialsLibrary: materialsLibrary,
            selected: true,
            objects: [object]
        })
    }
}

/**
 * Show properties for material type refraction
 * Check if the material of the selected object is used by other objects
 * Id yes, then removes this object from material mesh array
 * If not then material is removed completely
 * As defaults, for refraction material, are used materialRefractionDefaultSettings object in state
 * Note that timestamp is used as new key for adding new material to materials object.
 * If this new key is added as id property in material object itself, the controls will not work. So id should be undefined
 * @param object
 * @returns {function(...[*]=)}
 */
export const makeRefraction = object => {
    return (dispatchEvent, getState) => {
        //first find material that is used by this object
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let currentMaterialId = null // will hold the material that is being used by the picked object
        let objectsWithSameMaterial= null; // will count how many objects use this material
        let sceneConfig = {...getState().sceneConfig}

        for (const [key, data] of Object.entries(materials)) {
            data.mesh.map(uuid => {
                if (uuid === object.uuid) {
                    objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
                    currentMaterialId = key // material id of this object
                }
            })
        }

        //First remove mesh from current material
        materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== selectedObjects.pickedObject.uuid)

        //will create new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
        let newMaterialKey = 'M' + Date.now()
        materials = {
            ...materials,
            [newMaterialKey]: {
                material: {
                    id: newMaterialKey.toString(),
                    ...getState().selectedObjects.materialRefractionDefaultSettings
                },
                mesh: [object.uuid]
            }
        }

        //Add new material to materials list
        let materialsList = sceneConfig.materialsList
        materialsList.push({value: newMaterialKey, label: newMaterialKey})

        //Change object material and dispatch action
        selectedObjects.objects.forEach(obj => {
            sceneConfig.api.importMaterialParams(materials[newMaterialKey].material).then(() => {
                sceneConfig.api.setMaterialParams(obj, {isRefraction: true})
            })
        });
    
        //Update materials library
        let materialsLibrary = []
        materialsLibrary = sceneConfig.api.getMaterialLibrary()

        //Change selected object state
        dispatchEvent({
            type: MAKE_REFRACTION,
            materials: materials,
            object: {uuid: object.uuid, name: object.name},
            material: materials[newMaterialKey].material,
            materialId: newMaterialKey,
            materialsList: materialsList,
            materialsLibrary: materialsLibrary,
            selected: true,
            objects: [object]
        })
    }
}


export const pickedGroupToggleVisibility = object => {
    console.log('pickedGroupToggleVisibility');
    return (dispatchEvent, getState) => {
        //first find material that is used by this object
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let currentMaterialId = null // will hold the material that is being used by the picked object
        let objectsWithSameMaterial= null; // will count how many objects use this material
        let sceneConfig = {...getState().sceneConfig}

        for (const [key, data] of Object.entries(materials)) {
            data.mesh.map(uuid => {
                if (uuid === object.uuid) {
                    objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
                    currentMaterialId = key // material id of this object
                }
            })
        }

        //First remove mesh from current material
        materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== selectedObjects.pickedObject.uuid)

        //will create new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
        let newMaterialKey = 'M' + Date.now()
        materials = {
            ...materials,
            [newMaterialKey]: {
                material: {
                    id: newMaterialKey.toString(),
                    ...getState().selectedObjects.materialRefractionDefaultSettings
                },
                mesh: [object.uuid]
            }
        }

        //Add new material to materials list
        let materialsList = sceneConfig.materialsList
        materialsList.push({value: newMaterialKey, label: newMaterialKey})

        //Change object material and dispatch action
        sceneConfig.api.importMaterialParams(materials[newMaterialKey].material).then(() => {
            sceneConfig.api.setMaterialParams(selectedObjects.pickedObject, {visible: !selectedObjects.pickedObject.visible})

            //Update materials library
            let materialsLibrary = []
            materialsLibrary = sceneConfig.api.getMaterialLibrary()

            //Change selected object state
            dispatchEvent({
                type: CHANGE_PICKED_GROUP_VISIBILITY,
                materials: materials,
                object: {uuid: object.uuid, name: object.name},
                material: materials[newMaterialKey].material,
                materialId: newMaterialKey,
                materialsList: materialsList,
                materialsLibrary: materialsLibrary,
                selected: true,
                objects: [object]
            })
        })
    }
}

/**
 * Show properties for uploaded
 * Check if the current material of the selected object is used by other objects
 * Id yes, then removes this object from material mesh array
 * Note that timestamp is used as new key for adding new material to materials object.
 * If this new key is added as id property in material object itself, the controls will not work. So id should be undefined
 * @param object
 * @returns {function(...[*]=)}
 */
export const uploadMaterial = object => {
    return (dispatchEvent, getState) => {
        let sceneConfig = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let currentMaterialId = null // will hold the material that is being used by the picked object
        let objectsWithSameMaterial= null; // will count how many objects use this material

        //first find material that is used by this object
        for (const [key, data] of Object.entries(materials)) {
            data.mesh.map(uuid => {
                if (uuid === object.uuid) {
                    objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
                    currentMaterialId = key // material id of this object
                }
            })
        }

        //First remove mesh from current material
        materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== selectedObjects.pickedObject.uuid)

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                let reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function () {
                    let {id, ...uploadedMaterial} = JSON.parse(reader.result)
                    //will add new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
                    if (JSON.parse(reader.result)) {
                        //will create new material with random ID for this object (Inorder for controls to work on this new material the ID must be undefined)
                        let newMaterialKey = 'M' + Date.now()
                        materials = {
                            ...materials,
                            [newMaterialKey]: {
                                material: {
                                    id: newMaterialKey.toString(),
                                    ...uploadedMaterial
                                },
                                mesh: [object.uuid]
                            }
                        }

                        //Add new material to materials list
                        let materialsList = sceneConfig.materialsList
                        materialsList.push({value: newMaterialKey, label: newMaterialKey})

                        //Change object material and dispatch action
                        sceneConfig.api.importMaterialParams(materials[newMaterialKey].material).then(() => {
                            sceneConfig.api.setMaterialParams(selectedObjects.pickedObject, materials[newMaterialKey].material)

                            //Update materials library
                            let materialsLibrary = []
                            materialsLibrary = sceneConfig.api.getMaterialLibrary()

                            //Change selected object state
                            dispatchEvent({
                                type: UPLOAD_MATERIAL,
                                materials: materials,
                                object: {uuid: object.uuid, name: object.name},
                                material: materials[newMaterialKey].material,
                                materialId: newMaterialKey,
                                materialsLibrary: materialsLibrary,
                                selected: true,
                                materialsList: materialsList
                            })
                        })
                    }
                }
            }
        }
        inputFile.click();
    }
}

export const changeStandardMaterialColor = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.color = value
        materials[selectedObjects.materialId].material.color = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_COLOR,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeStandardMaterialOpacity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.opacity = value
        materials[selectedObjects.materialId].material.opacity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_OPACITY,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeBevelRadius = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.bevelRadius = value
        materials[selectedObjects.materialId].material.bevelRadius = value
        if (getState().sceneConfig.config.enableBevel) {
            dispatchEvent({
                type: CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS,
                materials: materials,
                selectedMaterial: selectedObjects.material
            })
        }
    }
}

export const changeStandardMaterialRoughness = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.roughness = value
        materials[selectedObjects.materialId].material.roughness = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_ROUGHNESS,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeStandardMaterialMetalness = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.metalness = value
        materials[selectedObjects.materialId].material.metalness = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_METALNESS,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeStandardMaterialEnvMapIntensity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.envMapIntensity = value
        materials[selectedObjects.materialId].material.envMapIntensity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeStandardMaterialAlphaMapIntensity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.envMapIntensity = value
        materials[selectedObjects.materialId].material.envMapIntensity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'alphaMap'
        })
    }
}

export const changeStandardMaterialLightMapIntensity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.lightMapIntensity = value
        materials[selectedObjects.materialId].material.lightMapIntensity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'lightMap'
        })
    }
}

export const changeStandardMaterialAoMapIntensity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.aoMapIntensity = value
        materials[selectedObjects.materialId].material.aoMapIntensity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'aoMap'
        })
    }
}

export const changeStandardMaterialEmissiveIntensity = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.emissiveIntensity = value
        materials[selectedObjects.materialId].material.emissiveIntensity = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'emissiveMap'
        })
    }
}

export const changeStandardMaterialBumpScale = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.bumpScale = value
        materials[selectedObjects.materialId].material.bumpScale = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_BUMP_SCALE,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'bumpMap'
        })
    }
}

export const changeStandardMaterialLineWidth = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        if (selectedObjects.material.wireframe) {
            selectedObjects.material.wireframeLinewidth = value
            materials[selectedObjects.materialId].material.wireframeLinewidth = value
            dispatchEvent({
                type: CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH,
                materials: materials,
                selectedMaterial: selectedObjects.material
            })
        }
    }
}

export const changeDampingFactor = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.dampingFactor = value
        dispatchEvent({
            type: CHANGE_DAMPING_FACTOR,
            sceneConfig: sceneConfig
        })
    }
}

export const changeZoomSpeed = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.zoomSpeed = Number(value)
        dispatchEvent({
            type: CHANGE_ZOOM_SPEED,
            sceneConfig: sceneConfig
        })
    }
}


export const changeMinimumSpeed = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.minSpeed = value
        let api = getState().sceneConfig.api;
        api.setCameraParams({orbitParams: {minSpeed: value}})
        dispatchEvent({
            type: CHANGE_MIN_SPEED,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMaximumSpeed = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.maxSpeed = value
        let api = getState().sceneConfig.api;
        api.setCameraParams({orbitParams: {maxSpeed: value}})
        dispatchEvent({
            type: CHANGE_MAX_SPEED,
            sceneConfig: sceneConfig
        })
    }
}



export const changeMinimumDistance = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.minDistance = value
        dispatchEvent({
            type: CHANGE_MIN_DISTANCE,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMaximumDistance = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.maxDistance = value
        dispatchEvent({
            type: CHANGE_MAX_DISTANCE,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMaxZoom = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.maxZoom = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MAX_ZOOM,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMinZoom = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.minZoom = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MIN_ZOOM,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMaxPolarAngle = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.maxPolarAngle = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MAX_POLAR_ANGLE,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMinPolarAngle = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.minPolarAngle = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MIN_POLAR_ANGLE,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMaxAzimuthAngle = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.maxAzimuthAngle = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MAX_AZIMUTH_ANGLE,
            sceneConfig: sceneConfig
        })
    }
}

export const changeMinAzimuthAngle = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.minAzimuthAngle = parseFloat(value)
        dispatchEvent({
            type: CHANGE_MIN_AZIMUTH_ANGLE,
            sceneConfig: sceneConfig
        })
    }
}

export const standardMaterialEnableWireframe = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.wireframe = value
        materials[selectedObjects.materialId].material.wireframe = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeStandardMaterialEmissiveColor = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.emissive = value
        materials[selectedObjects.materialId].material.emissive = value
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'emissiveMap'
        })
    }
}

/**
 * Downloads material as json format
 */
export const downloadMaterial = () => {
    return (dispatchEvent, getState) => {
        let api = getState().sceneConfig.api
        let material = api.getMaterialParams(getState().selectedObjects.pickedObject)
        api.exportMaterialParams(material).then(value => {
            delete value['id']
            let link = document.createElement("a");
            let blob = new Blob([JSON.stringify(value)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "material.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
        });
    }
}

/**
 * Upload map file for material type standard
 * @returns {function(...[*]=)}
 */
export const uploadMapFile = (propName) => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            console.log(files);
            if (file) {
                api.importTexture(file, texture => {
                    selectedObjects.material[propName] = texture
                    materials[selectedObjects.materialId].material[propName] = texture
                    // texture.wrapS = RepeatWrapping;
                    // texture.wrapT = RepeatWrapping;
                    // texture.repeat.set(10, 10);
                    if(propName == "normalMap")
                    {
                        selectedObjects.material.normalScale.x = 1;
                        selectedObjects.material.normalScale.y = 1;
                    }
                    if(propName == "alphaMap")
                    {
                        selectedObjects.material.envMapIntensity = 1;
                    }
                    console.log('propname: ' + propName);
                    if(propName == "map"){
                        //Set Encoding = SRGB as default
                        let ccc = 0;
                        let encodingInt = setInterval(() => {
                            console.log("ccc: " + ccc);
                            if(selectedObjects.pickedObject.material[propName]){
                                colorMapEncodingPrivate(propName, TextureExporter.WEBGL_CONSTANTS.SRGB_ENCODING, dispatchEvent, getState);
                                clearInterval(encodingInt);
                            }
                            ccc++;
                        }, 1);

                        
                    }
                    dispatchEvent({
                        type: CHANGE_STANDARD_MATERIAL_MAP_FILE,
                        materials: materials,
                        selectedMaterial: selectedObjects.material,
                        mapType: propName
                    })
                })
            }
            inputFile.value = '';
        }
        inputFile.click();
    }
}

function colorMapEncodingPrivate(propName, value, dispatchEvent, getState){
    const {api} = getState().sceneConfig
    let materials = {...getState().sceneConfig.materials}
    let selectedObjects = {...getState().selectedObjects}
    selectedObjects.pickedObject.material[propName].encoding = value;//TextureExporter.WEBGL_TO_THREE.get(+value)
    materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

    let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
    TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
    api.setDirty();
    api.setMaterialParams(selectedObjects.pickedObject, selectedObjects.material);
    dispatchEvent({
        type: MAP_ENCODING,
        materials: materials,
        selectedMaterial: selectedObjects.material
    })
}

export const mapWrapU = (propName, value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.pickedObject.material[propName].wrapS = TextureExporter.WEBGL_TO_THREE.get(+value)
        materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

        let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
        console.log(sampler);
        TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
        api.setDirty();

        dispatchEvent({
            type: MAP_WRAP_U,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const mapWrapV = (propName, value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.pickedObject.material[propName].wrapT = TextureExporter.WEBGL_TO_THREE.get(+value)
        materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

        let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
        console.log(sampler);
        TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
        api.setDirty();

        dispatchEvent({
            type: MAP_WRAP_V,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}


export const colorMapEncoding = (propName, value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.pickedObject.material[propName].encoding = value;//TextureExporter.WEBGL_TO_THREE.get(+value)
        materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

        let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
        TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
        api.setDirty();
        api.setMaterialParams(selectedObjects.pickedObject, selectedObjects.material);
        dispatchEvent({
            type: MAP_ENCODING,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeMapRepeatU = (propName, value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.pickedObject.material[propName].repeat.x = value
        materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

        let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
        TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
        api.setDirty();

        dispatchEvent({
            type: CHANGE_MAP_REPEAT_U,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const changeMapRepeatV = (propName, value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.pickedObject.material[propName].repeat.y = value
        console.log("selectedObjects.pickedObject");
        console.log(selectedObjects.pickedObject);
        console.log("materials");
        console.log(materials);
        console.log("selectedObjects.materialId");
        console.log(selectedObjects.materialId);
        console.log("materials[selectedObjects.materialId]");
        console.log(materials[selectedObjects.materialId]);
        materials[selectedObjects.materialId].material[propName] = selectedObjects.pickedObject.material[propName]

        let sampler = TextureExporter.getSampler(selectedObjects.pickedObject.material[propName]);
        TextureExporter.setSampler(sampler, selectedObjects.pickedObject.material[propName]);
        api.setDirty();

        dispatchEvent({
            type: CHANGE_MAP_REPEAT_V,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const openMapFile = (propName) => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig
        TextureExporter.openTexture(selectedObjects.pickedObject.material[propName])
    }
}

export const downloadMapFile = (propName) => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig
        TextureExporter.downloadTexture(selectedObjects.pickedObject.material[propName], selectedObjects.material[propName].name.split('.')[0])
    }
}

export const removeMapFile = (propName) => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig
        selectedObjects.pickedObject.material[propName] = null;
        api.setMaterialParams(selectedObjects.pickedObject, {});
        
        selectedObjects.material[propName] = null
        materials[selectedObjects.materialId].material[propName] = null
        dispatchEvent({
            type: CHANGE_STANDARD_MATERIAL_MAP_FILE,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: propName
        })
    }

}

function  applyMaterialFromDBToLayer(dispatchEvent, getState, layerId, uuidPreset, uuidPresetFolder) {
        const {api} = getState().sceneConfig
        let material = null;
        let materialId = null;
        let materials = getState().sceneConfig.materialsLibrary
        for(let mat of materials)
        {
            if(mat.params.id == layerId){
                let group = mat;
                api.highlightObjects.apply(api, group.mesh) //Highlight objects
                material = group.params;
                let p = {edgeStrength: 10};
                api.getTweener(p)
                        .to({edgeStrength: 0.0}, 4*1000)
                        // .delay(4*1000)
                        .onUpdate(()=> api.setOutlineParams(p))
                        .start();
        
        
                for (const [key, value] of Object.entries(getState().sceneConfig.materials)) {
                    if (value.material.id === material.id) {
                        materialId = key
                    }
                }
                dispatchEvent({
                    type: SELECT_MESH,
                    material: material,
                    materialId: materialId,
                    object: {uuid: group.mesh[0].uuid, name: group.mesh[0].name},
                    objects: group.mesh,
                    pickedObject: group.mesh[0],
                    selected: true
                })
                privateApplyingPreset(dispatchEvent, getState, uuidPreset, uuidPresetFolder);
            }
        }
}

function privateApplyExistingLocalStorageMaterial(api, dispatchEvent, getState)
{
    
    //Select Material GROUP
    let material = null;
    let materialId = null;
    let materials = getState().sceneConfig.materialsLibrary
    for(let mat of materials)
    {
            let group = mat;
            material = group.params;
            api.highlightObjects.apply(api, group.mesh) //Highlight objects


            let p = {edgeStrength: 10};
            api.getTweener(p)
                    .to({edgeStrength: 0.0}, 4*1000)
                    // .delay(4*1000)
                    .onUpdate(()=> api.setOutlineParams(p))
                    .start();


            for (const [key, value] of Object.entries(getState().sceneConfig.materials)) {
                if (value.material.id === material.id) {
                    materialId = key
                }
            }
            dispatchEvent({
                type: SELECT_MESH,
                material: material,
                materialId: materialId,
                object: {uuid: group.mesh[0].uuid, name: group.mesh[0].name},
                objects: group.mesh,
                pickedObject: group.mesh[0],
                selected: true
            })
            let presetObj = localStorage.getItem(mat.params.id);
            if(presetObj)
            {
                let uuidPreset = presetObj.split(";")[0];
                let uuidPresetFolder = presetObj.split(";")[1];

                //privateApplyingPreset(dispatchEvent, getState, uuidPreset, uuidPresetFolder);

            }
    }
}

/**
 * Upload json scene config. The file that it is exported from sidebar controls
 * @returns {function(...[*]=)}
 */
export const uploadScene = () => {
    return (dispatchEvent, getState) => {
        let so = getState().selectedObjects;
        let comb = getState().combinations;
        const {api} = getState().sceneConfig
        const inputFile = document.getElementById("fileInput");
        inputFile.value = null;
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                let reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function () {
                    let sceneConfig = JSON.parse(reader.result)
                    so.object = {};
                    so.objects = [];
                    comb.presetList = {};
                    document.getElementById("myLoader").style.display = "block";
                    document.getElementById("canvas").style.display = "none";
                    api.importScene(sceneConfig).then(() => {
    
                        setTimeout(() => {
                            let obj = sceneConfig;
                            let p = api.getCameraParams();
                            p.position = [obj.config.camera.position[0], obj.config.camera.position[1], obj.config.camera.position[2]];
                            api.setCameraParams(p);
        
                            document.getElementById("myLoader").style.display = "none";
                            document.getElementById("canvas").style.display = "block";


                        }, 1000);

                        var count = 0 ;
                        var getMaterialLibrary = setInterval(() => {
                            count ++;
                            api.getMaterialLibrary();
                            if(count == 5)
                            {
                                let meshes = ''
                                let materials = getState().sceneConfig.api.getMaterialLibrary()
                                
                                materials.forEach(material => {
                                    meshes = meshes == '' ?  material.params.id : (meshes + ';' + material.params.id)
                                })
    
                                localStorage.setItem('current-meshes', meshes);
    
                                
                                clearInterval(getMaterialLibrary);
                            }
                        }, 1000);
                        
                        let taaParams = api.getTAAParams();
                        api.setTAAParams(taaParams);
    
    
                        sceneConfig.config.groundShadow.falloff = 2.5
                        api.setGroundShadowParams(sceneConfig.config.groundShadow)
    
                        //Default Logo watermark
                        if(!sceneConfig.config.logoParams)
                        {
                            let logoParams = api.getLogoParams();
                            sceneConfig.config.logoParams = logoParams;
                        }
    
                        //Load Annotation Enable
                        if(api.annotationsEnabled)
                        {
                            dispatchEvent({
                                enableAnnotations: api.annotationsEnabled,
                                type: ENABLE_ANNOTATIONS,
                            })
                        }
    
                        dispatchEvent({
                            type: UPLOAD_SCENE,
                            sceneConfig: sceneConfig,
                            api: api
                        })
                        
                        dispatchEvent({
                            type: REQUEST_COMBINATIONS_SUCCESS,
                            combinations: [],
                            loading: false
                        })
    
                        let arSettings = api.getXRParams();
                        dispatchEvent({
                            type: ENABLE_AR,
                            arSettings: arSettings
                        })
    



                            //Check if exist Local Storage applied Preset for the scene
                            // privateApplyExistingLocalStorageMaterial(api, dispatchEvent, getState)
                            let decoded_jwt = jwt_decode(localStorage.getItem('user'));
                            const userId = decoded_jwt.id;
                            let uuids = [];
                            
                            let layerInter = setInterval(() => {
                                let materials = getState().sceneConfig.api.getMaterialLibrary();
                                if(materials.length > 0){
                                    clearInterval(layerInter);
                                    console.log('zxczx');
                                    materials = getState().sceneConfig.api.getMaterialLibrary();
                                    materials.map((group, i) => {
                                        uuids.push(group.params.id);
                                    })
                                    axios.post('layer/getList', {
                                        uuids: uuids,
                                        userId: userId
                                    }).then(response => {
                                        const layers = response.data.data.layers;
                                        for(let layer of layers){
                                            if(layer.materialId || layer.materialFolderId){
                                                console.log(layer);
                                                applyMaterialFromDBToLayer(dispatchEvent, getState, layer.layerId, layer.materialId, layer.materialFolderId);
                                            }
                                        }
                                    });
                                }
                            }, 1000);


                    });

                }
            }
        }
        inputFile.click();
    }
}

export const uploadCubeMap = () => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                api.setBackgroundFile(file, {
                    setEnvironment: false,
                    setDiamondEnv: true,
                    setBackground: false,
                    imageBasedLighting: true
                })

                //TODO Where to save this in state ??
                /*dispatchEvent({
                    type: CHANGE_STANDARD_MATERIAL_MAP_FILE,
                    materials: materials,
                    selectedMaterial: selectedObjects.material
                })*/
            }
        }
        inputFile.click();
    }
}

export const uploadEquirec = () => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                api.setBackgroundFile(file, {
                    setEnvironment: false,
                    setDiamondEnv: true,
                    setBackground: false,
                    imageBasedLighting: true
                })

                //TODO Where to save this in state ??
                /*dispatchEvent({
                    type: CHANGE_STANDARD_MATERIAL_MAP_FILE,
                    materials: materials,
                    selectedMaterial: selectedObjects.material
                })*/
            }
        }
        inputFile.click();
    }
}


export const refractionMaterialToggleVisibilityWithLayerId = (uuid, value) => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.visible = value
        materials[uuid].material.visible = value
        console.log("uuid: " + uuid);
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_VISIBILITY,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialToggleVisibility = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.visible = value

        console.log("selectedObjects.materialId: " + selectedObjects.materialId);
        materials[selectedObjects.materialId].material.visible = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_VISIBILITY,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialTintColor = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.refractionTintColor = value
        materials[selectedObjects.materialId].material.refractionTintColor = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_TINT_COLOR,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialDiffuse = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.diffuse = value
        materials[selectedObjects.materialId].material.diffuse = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialRefractionIndex = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.refractionIndex = value
        materials[selectedObjects.materialId].material.refractionIndex = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialRefractionRoughness = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.refractionRoughness = value
        materials[selectedObjects.materialId].material.refractionRoughness = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialRoughness = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.roughness = value
        materials[selectedObjects.materialId].material.roughness = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_ROUGHNESS,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialMetalness = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.metalness = value
        materials[selectedObjects.materialId].material.metalness = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_METALNESS,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const refractionMaterialTransparency = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.transparency = value
        materials[selectedObjects.materialId].material.transparency = value
        dispatchEvent({
            type: CHANGE_REFRACTION_MATERIAL_TRANSPARENCY,
            materials: materials,
            selectedMaterial: selectedObjects.material
        })
    }
}

export const enableAr = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        arSettings.enabled = value
        dispatchEvent({
            type: ENABLE_AR,
            arSettings: arSettings
        })
    }
}

export const changeObjectScaleSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.objectScale = value
            dispatchEvent({
                type: CHANGE_OBJECT_SCALE_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const changeReticleSizeInSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.reticleSizeIn = value
            dispatchEvent({
                type: CHANGE_RETICLE_SIZE_IN_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const changeReticleSizeOutSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.reticleSizeOut = value
            dispatchEvent({
                type: CHANGE_RETICLE_SIZE_OUT_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const changeReticleFadeThreshSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.reticleFadeThresh = value
            dispatchEvent({
                type: CHANGE_RETICLE_FADE_THRESH_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const changeReticleFadeTimeSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.reticleFadeTime = value
            dispatchEvent({
                type: CHANGE_RETICLE_FADE_TIME_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const enableArGroundShadows = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.groundShadows = value
            dispatchEvent({
                type: CHANGE_GROUND_SHADOWS_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const changeReticleColorSetting = value => {
    return (dispatchEvent, getState) => {
        let arSettings = {...getState().sceneConfig.arSettings}
        if (arSettings.enabled) {
            arSettings.reticleColor = value
            dispatchEvent({
                type: CHANGE_RETICLE_COLOR_SETTING,
                arSettings: arSettings
            })
        }
    }
}

export const uploadEnvironment = () => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                console.log(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result

                    api.loadTexture(base64String,
                        event => console.log(event), {filename: file.name}).then(value => {
                             api.sceneManager.setSceneEnvironment
                             (value, {imageBasedLighting: true, setBackground: false, setDiamondEnv: true, setEnvironment: true});
                    })

                };
                reader.readAsDataURL(file);
                console.log("File");
                console.log(file);
                api.setBackgroundFile(file, {
                    setEnvironment: true,
                    setDiamondEnv: true,
                    setBackground: true,
                    imageBasedLighting: true
                })

                //TODO Where to save this in state ??
                /*dispatchEvent({
                    type: CHANGE_STANDARD_MATERIAL_MAP_FILE,
                    materials: materials,
                    selectedMaterial: selectedObjects.material
                })*/
            }
            inputFile.value = null;
        }
        inputFile.click();
        inputFile.value = null;
    }
}

export const backgroundLoadImage = () => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig}

        const inputFile = document.getElementById("fileInput");
        inputFile.value = null;
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                api.setBackgroundFile(file, {
                    setEnvironment: false
                })

                api.importTexture(file, texture => {
                    sceneConfig.config.background = texture
                })

                dispatchEvent({
                    type: CHANGE_BACKGROUND_IMAGE_FILE,
                    sceneConfig: sceneConfig
                })
            }
        }
        inputFile.click();
    }
}

export const changeToneMappingExposureSetting = (value) => {
    return (dispatchEvent, getState) => {
        let config = {...getState().sceneConfig.config}
        config.toneMappingExposure = value
        dispatchEvent({
            type: CHANGE_TONE_MAPPING_EXPOSURE_SETTING,
            config: config,
        })
    }
}

export const changeToneMappingSaturationSetting = (value) => {
    return (dispatchEvent, getState) => {
        let config = {...getState().sceneConfig.config}
        config.toneMappingSaturation = value
        dispatchEvent({
            type: CHANGE_TONE_MAPPING_SATURATION_SETTING,
            config: config,
        })
    }
}

export const changeToneMappingContrastSetting = (value) => {
    return (dispatchEvent, getState) => {
        let config = {...getState().sceneConfig.config}
        config.toneMappingContrast = value
        dispatchEvent({
            type: CHANGE_TONE_MAPPING_CONTRAST_SETTING,
            config: config,
        })
    }
}

export const changeEnvMapRotationSetting = (value) => {
    return (dispatchEvent, getState) => {
        let config = {...getState().sceneConfig.config}
        config.envMapRotation = value
        dispatchEvent({
            type: CHANGE_ENV_MAP_ROTATION_SETTING,
            config: config,
        })
    }
}

/**
 * When selecting material from dropdown will change the material on all objects that use the old material
 * @param materialKey
 * @returns {function(...[*]=)}
 */
export const selectMaterial = materialKey => {
    return (dispatchEvent, getState) => {
        //first find material that is used by this object
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        let objects = []
        let newMaterial = materials[materialKey].material
        let objectsWithSameMaterial = null; // will count how many objects use this material

        for (const [key, data] of Object.entries(materials)) {
            if (key === materialKey) {
                objectsWithSameMaterial = data.mesh.length //check if this material is used by other objects too, if not, delete this material
            }
        }

        //First remove mesh from current material
        selectedObjects.objects.forEach(object => {
            materials[selectedObjects.materialId].mesh = materials[selectedObjects.materialId].mesh.filter(el => el !== object.uuid)
        })

        //Add mesh to new material
        selectedObjects.objects.forEach(object => {
            materials[materialKey].mesh.push(object.uuid)
            objects.push(object)
        })

        //change selected object state
        dispatchEvent({
            type: SELECT_MATERIAL,
            materials: materials,
            material: newMaterial,
            materialId: materialKey,
            objects: objects,
            object: {uuid: selectedObjects.pickedObject.uuid, name: selectedObjects.pickedObject.name},
            selected: true
        })
    }
}

/**
 * Change material name. Changes lasts as long as the app instance lasts
 * @param materials
 * @returns {function(...[*]=)}
 */
export const changeMaterialName = materials => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: CHANGE_MATERIAL_NAME,
            materialsList: materials,
        })
    }
}

/**
 * Will load presets from server on component ProductRenderer mount
 * @returns {function(...[*]=)}
 */
export const loadPresets = () => {
    return (dispatchEvent, getState) => {
        axios.get('preset/get-list').then(response => {
            let {data} = response
            if (data.success) {

                //Save presets in state
                let newState = {
                    presets: data.data.presets,
                    hasError: false,
                    error: ''
                }

                dispatchEvent({
                    type: REQUEST_PRESETS_SUCCESS,
                    newState: newState,
                })
            }

            if (!data.success) {
                dispatchEvent({
                    type: REQUEST_PRESETS_FAILED,
                    newState: {
                        hasError: true,
                        error: data.data.message
                    },
                })
            }
        }).catch(error => {
            console.log(error.message)
        })
    }
}

/**
 * Remove specific preset folder from state
 * @param uuid
 * @returns {function(...[*]=)}
 */
export const deletePresetFolder = uuid => {
    return (dispatchEvent, getState) => {
        let {presets} = {...getState().presetLibrary}
        const presetsUpdated = presets.filter(preset => preset.uuid !== uuid)
        dispatchEvent({
            type: DELETED_PRESET_FOLDER,
            presets: presetsUpdated
        })
    }
}

export const editPresetFolder = uuid => {
    return (dispatchEvent, getState) => {
        let {presets} = {...getState().presetLibrary}
        const presetsUpdated = presets.filter(preset => preset.uuid !== uuid)
        dispatchEvent({
            type: EDIT_PRESET_FOLDER,
            presets: presetsUpdated
        })

        axios.get('preset/get-list').then(response => {
            let {data} = response
            if (data.success) {

                //Save presets in state
                let newState = {
                    presets: data.data.presets,
                    hasError: false,
                    error: ''
                }

                dispatchEvent({
                    type: REQUEST_PRESETS_SUCCESS,
                    newState: newState,
                })
            }

            if (!data.success) {
                dispatchEvent({
                    type: REQUEST_PRESETS_FAILED,
                    newState: {
                        hasError: true,
                        error: data.data.message
                    },
                })
            }
        }).catch(error => {
            console.log(error.message)
        })
    }
}

/**
 * Remove specific preset from state
 * @param uuidPreset
 * @param uuidPresetFolder
 * @returns {function(...[*]=)}
 */
export const deletePreset = (uuidPreset, uuidPresetFolder) => {
    return (dispatchEvent, getState) => {
        let presets = [...getState().presetLibrary.presets]
        let presetsUpdated = presets.map(presetFolder => {
            if (presetFolder.uuid === uuidPresetFolder){
                let {data, ...folderInfo} = presetFolder
                let filteredPresets = data.filter(preset => preset.uuid !== uuidPreset)
                return {
                    data: filteredPresets,
                    ...folderInfo
                }
            } else {
                return presetFolder
            }
        })

        dispatchEvent({
            type: DELETED_PRESET,
            presets: presetsUpdated
        })
    }
}

export const editPreset = (uuidPreset, uuidPresetFolder) => {
    return (dispatchEvent, getState) => {
        let presets = [...getState().presetLibrary.presets]
        let presetsUpdated = presets.map(presetFolder => {
            if (presetFolder.uuid === uuidPresetFolder){
                let {data, ...folderInfo} = presetFolder
                let filteredPresets = data.filter(preset => preset.uuid !== uuidPreset)
                return {
                    data: filteredPresets,
                    ...folderInfo
                }
            } else {
                return presetFolder
            }
        })

        dispatchEvent({
            type: EDIT_PRESET,
            presets: presetsUpdated
        })

        axios.get('preset/get-list').then(response => {
            let {data} = response
            if (data.success) {

                //Save presets in state
                let newState = {
                    presets: data.data.presets,
                    hasError: false,
                    error: ''
                }

                dispatchEvent({
                    type: REQUEST_PRESETS_SUCCESS,
                    newState: newState,
                })
            }

            if (!data.success) {
                dispatchEvent({
                    type: REQUEST_PRESETS_FAILED,
                    newState: {
                        hasError: true,
                        error: data.data.message
                    },
                })
            }
        }).catch(error => {
            console.log(error.message)
        })
    }
}

/**
 * Save new preset in state
 * @param presetName
 * @param folderUuid
 * @param presetUuid
 * @returns {function(...[*]=)}
 */
export const savePreset = (presetName, folderUuid, presetUuid, presetPrice) => {
    return (dispatchEvent, getState) => {
        let presets = [...getState().presetLibrary.presets]
        let material = {...getState().selectedObjects.material}
        let presetsUpdated = presets.map(presetFolder => {
            if (presetFolder.uuid === folderUuid){
                let {data, ...folderInfo} = presetFolder
                if (!data) {
                    data = [{
                        data: {...material},
                        uuid: presetUuid,
                        name: presetName,
                        price: presetPrice
                    }]
                } else {
                    data.push({
                        data: {...material},
                        uuid: presetUuid,
                        name: presetName,
                        price: presetPrice
                    })
                }
                return {
                    data: data,
                    ...folderInfo
                }
            } else {
                return presetFolder
            }
        })

        dispatchEvent({
            type: SAVED_PRESET,
            presets: presetsUpdated
        })
    }
}

/**
 * Save new preset folder
 * @param folderName
 * @param folderUuid
 * @returns {function(...[*]=)}
 */
export const savePresetFolder = (folderName, folderUuid) => {
    return (dispatchEvent, getState) => {
        let presets = [...getState().presetLibrary.presets]
        presets.push({
            uuid: folderUuid,
            name: folderName
        })

        dispatchEvent({
            type: SAVED_PRESET_FOLDER,
            presets: presets
        })
    }
}

export const applyPresetFolder = () => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let preset_folder_layer = {};
        let listLayerIds = [];
        for (const [key, value] of Object.entries(materials)) {
            listLayerIds.push(value.material.id);
        }
        
        let presetFolderList = {...getState().presetLibrary.presets};
        privateApplyPresetAndPresetFolderToLayer(listLayerIds, presetFolderList, dispatchEvent);

    }
}

function privateApplyPresetAndPresetFolderToLayer(uuids, presetFolderList, dispatchEvent){
    setTimeout(() => {
        let preset_layer = {};
        let preset_folder_layer = {};
        let decoded_jwt = jwt_decode(localStorage.getItem('user'));
        const userId = decoded_jwt.id;
        axios.post('layer/getList', {
            uuids: uuids,
            userId: userId
        }).then(response => {
            const layers = response.data.data.layers;
            for(let layer of layers){
                for (const [key, value] of Object.entries(presetFolderList)) {
                    if(value.uuid == layer.materialFolderId){
                        preset_folder_layer[layer.layerId] = value.name;
                    }
                    
                    if(value.data != undefined){
                        for(let preset of value.data){
                            if(layer.materialId == preset.uuid){
                                preset_layer[layer.layerId] = preset.name
                            }
                        }
                    }
                }
            }

            dispatchEvent({
                type: LOAD_PRESET_LAYER,
                preset_layer: preset_layer,
            })
            dispatchEvent({
                type: LOAD_PRESET_FOLDER_LAYER,
                preset_folder_layer: preset_folder_layer,
            })
        });
    }, 1000);
}

function privateApplyingPreset(dispatchEvent, getState, uuidPreset, uuidPresetFolder)
{
    let presets = [...getState().presetLibrary.presets]
    let newMaterial = {}
    //first find material that is used by this object
    let materials = {...getState().sceneConfig.materials}
    let materialsList = [...getState().sceneConfig.materialsList]
    let selectedObjects = {...getState().selectedObjects}
    let objects = []
    let chosenPresetUuid = '';

    if(selectedObjects.material == null || selectedObjects.material.id == undefined)
    {
        return;
    }


    let listLayerIds = [];
    for (const [key, value] of Object.entries(materials)) {
        listLayerIds.push(value.material.id);
    }

    let presetFolderList = {...getState().presetLibrary.presets};
    privateApplyPresetAndPresetFolderToLayer(listLayerIds, presetFolderList, dispatchEvent);
    

    /* if preset doesn't on Google Bucket*/
    //Get material info from preset
    presets.map(presetFolder => {
        if (presetFolder.uuid === uuidPresetFolder){
            presetFolder.data.map(preset => {
                if (preset.uuid === uuidPreset) {
                    if(!JSON.stringify(preset.data).includes("googleapis"))
                    {
                        if(typeof preset.data !== 'object'){
                            preset.data = JSON.parse(preset.data);
                        }
                        
                        newMaterial = {...preset.data}
                        chosenPresetUuid = preset.uuid;


                        let responseMaterial = {};
                        let newMaterialKey = '';
                        for (const [key, value] of Object.entries(materials)) {
                            if(value.material.id == selectedObjects.material.id)
                            {
                                newMaterial.id = value.material.id;
                                newMaterial.name = value.material.name;
                    
                                value.material = newMaterial;
                                responseMaterial = value.material;
                                newMaterialKey = key;
                                localStorage.setItem(value.material.id, uuidPreset + ";" + uuidPresetFolder);
                            }
                        }
                    
                        // //Add mesh to new material
                        selectedObjects.objects.forEach(object => {
                            //materials[newMaterialKey].mesh.push(object.uuid)
                            objects.push(object)
                        })
                    
                        // //Add new material to materials list array
                        // materialsList.push({label: newMaterialKey, value: newMaterialKey})
                    
                        //change selected object state
                        dispatchEvent({
                            type: APPLY_PRESET,
                            materials: materials,
                            material: responseMaterial,
                            materialId: newMaterialKey,
                            materialsList: materialsList,
                            objects: objects,
                            object: {uuid: selectedObjects.pickedObject.uuid, name: selectedObjects.pickedObject.name},
                            selected: true,
                            loading: false
                        })
                    
                        //add Preset to Preset List
                        let presetList = {...getState().combinations.presetList};
                        presetList[newMaterialKey] = chosenPresetUuid;
                        dispatchEvent({
                            type: ADD_PRESET_TO_LIST,
                            presetList: presetList,
                        })


                    }
                    else
                    {
    
                        document.getElementById("myLoader").style.display = "block";
                        document.getElementById("canvas").style.display = "none";
                    
                        //TODO: Call API to get Preset Data
                        const result = axios.post('preset/get', {id: uuidPreset}).then(response => {
                            document.getElementById("myLoader").style.display = "none";
                            document.getElementById("canvas").style.display = "block";
                            let {data} = response
                            if (!data.success) {
                                console.log(data)
                            }
                            if (data.success) {
                                let preset = data.data.preset;
                                //Get material info from preset
                                // presets.map(presetFolder => {
                                //     if (presetFolder.uuid === uuidPresetFolder){
                                //         presetFolder.data.map(preset => {
                                //             if (preset.uuid === uuidPreset) {
                                //                 newMaterial = {...preset.data}
                                //                 chosenPresetUuid = preset.uuid;
                                //             }
                                //         })
                                //     }
                                // })
                                newMaterial = (preset.data);
                                chosenPresetUuid = preset.uuid;
                    
                                let responseMaterial = {};
                                let newMaterialKey = '';
                                for (const [key, value] of Object.entries(materials)) {
                                    if(value.material.id == selectedObjects.material.id)
                                    {
                                        newMaterial.id = value.material.id;
                                        newMaterial.name = value.material.name;
                            
                                        value.material = newMaterial;
                                        responseMaterial = value.material;
                                        newMaterialKey = key;
                                        localStorage.setItem(value.material.id, uuidPreset + ";" + uuidPresetFolder);
                                    }
                                }
                            
                                // //Add mesh to new material
                                selectedObjects.objects.forEach(object => {
                                    //materials[newMaterialKey].mesh.push(object.uuid)
                                    objects.push(object)
                                })
                            
                                // //Add new material to materials list array
                                // materialsList.push({label: newMaterialKey, value: newMaterialKey})
                            
                                //change selected object state
                                dispatchEvent({
                                    type: APPLY_PRESET,
                                    materials: materials,
                                    material: responseMaterial,
                                    materialId: newMaterialKey,
                                    materialsList: materialsList,
                                    objects: objects,
                                    object: {uuid: selectedObjects.pickedObject.uuid, name: selectedObjects.pickedObject.name},
                                    selected: true,
                                    loading: false
                                })
                            
                                //add Preset to Preset List
                                let presetList = {...getState().combinations.presetList};
                                presetList[newMaterialKey] = chosenPresetUuid;
                                dispatchEvent({
                                    type: ADD_PRESET_TO_LIST,
                                    presetList: presetList,
                                })
                            }
                        })
                        

                        .catch(function (error) {
                            if (error.response) {
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                            }
                          });

                        ;

                        console.log(result);
                    }
                }
            })
        }
    })


    

}

/**
 * Applies material to selected objects
 * @param uuidPreset
 * @param uuidPresetFolder
 * @returns {function(...[*]=)}
 */
export const applyPreset = (uuidPreset, uuidPresetFolder) => {
    return (dispatchEvent, getState) => {
        
        privateApplyingPreset(dispatchEvent, getState, uuidPreset, uuidPresetFolder);
        
    }
}

/**
 * Send request to get combinations from server
 * @returns {function(...[*]=)}
 */
export const loadCombinations = (isUpdate = false) => {
    return (dispatchEvent, getState) => {
        
        if(!isUpdate)
        {
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
        }

        setTimeout(() => {
            
            let meshes = '';
            let materials = getState().sceneConfig.api.getMaterialLibrary()
            materials.forEach(material => {
                meshes = meshes == '' ?  material.params.id : (meshes + ';' + material.params.id)
            })

            meshes = localStorage.getItem("current-meshes");


            axios.get('combination/get-list?meshes=' + meshes).then(response => {
                let {data} = response
                if (data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_SUCCESS,
                        combinations: data.data.combination,
                        loading: false
                    })
                }

                if (!data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_FAILED,
                        hasError: true,
                        error: data.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Combinations could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
    }
}

export const getTransactions = () => {
    return (dispatchEvent, getState) => {
        
        dispatchEvent({
            type: REQUEST_COMBINATIONS_LIST,
            loading: true
        })
        setTimeout(() => {
            axios.get('transaction/get-user-transactions').then(response => {
                let {data} = response
                let transactions = data.data.transactions
                if (data.success) {
                    dispatchEvent({
                        type: REQUEST_TRANSACTION_SUCCESS,
                        transactions: transactions,
                        loading: false
                    })
                }
            }).catch(error => {
                console.log(error);
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Transaction could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
}
}

export const getActivityInfo = () => {
    return (dispatchEvent, getState) => {
        
        dispatchEvent({
            type: REQUEST_COMBINATIONS_LIST,
            loading: true
        })
        setTimeout(() => {
            axios.get('view/get-activity-info').then(response => {
                let {data} = response
                let user = data.data.user
                if (data.success) {
                    dispatchEvent({
                        type: REQUEST_ACTIVITY_SUCCESS,
                        remain: user.remain,
                        default: user.default,
                        extra: user.extra,
                        expired: user.expired,
                        loading: false
                    })
                }
            }).catch(error => {
                console.log(error);
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Activity could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
}
}

export const getStorageSummary = () => {
    return (dispatchEvent, getState) => {
        
        setTimeout(() => {
            axios.get('combination/get-summary').then(response => {
                let {data} = response
                console.log(data);
                let summary = data.data
                if (data.success) {
                    dispatchEvent({
                        type: GET_STORAGE_SUMMARY,
                        summary: summary
                    })
                }
            }).catch(error => {
                console.log(error);
                dispatchEvent({
                    type: GET_STORAGE_SUMMARY,
                    hasError: true,
                    error: 'Activity could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
}
}

export const refreshCombinationsSizeAfterDelete = () => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: REQUEST_COMBINATIONS_SIZE_SUCCESS,
            combinations: [],
            totalSize: 0
        })
    };
}

export const getCombinationsSize = (skip, limit, search, isUpdate = false) => {
    skip = 0;
    return (dispatchEvent, getState) => {
        
        if(!isUpdate)
        {
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
        }
        limit = 1000;
        setTimeout(() => {

            axios.get('combination/get-list-size?limit='+limit+'&skip='+skip+'&s='+search).then(response => {
                let {data} = response
                if (data.success) {
                    let totalSize = 0;
                    for(let combination of data.data)
                    {
                        totalSize += Number(combination.size);
                    }
                    let combs = data.data;
                    let combinationsList = getState().combinations.combinationsList;
                    axios.get('user/get-storage-info').then(response2 => {
                        let {data} = response2;

                        // setTimeout(() => {
                        //     let lastRecord = combs[0].uuid;
                        //     var elm = document.getElementById(lastRecord);
                        //     elm.scrollIntoView({ block: 'end',  behavior: 'smooth' });   
                        // }, 500);


                        dispatchEvent({
                            type: REQUEST_COMBINATIONS_SIZE_SUCCESS,
                            //combinations: [...combinationsList,...combs],
                            combinations: combs,
                            totalSize: Number(totalSize),// + Number(getState().combinations.totalSize),
                            // totalSize: Number(totalSize) + Number(getState().combinations.totalSize),
                            storage: data.data.storageLimit,
                            storageExpired: new Date(data.data.storageExpiredDate),
                            loading: false
                        })
                    }).catch(error => {
                        dispatchEvent({
                            type: REQUEST_COMBINATIONS_ERROR,
                            hasError: true,
                            error: 'Combinations could not be loaded !',
                            loading: false
                        })
                    });
                }

                if (!data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_FAILED,
                        hasError: true,
                        error: data.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Combinations could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
    }
}
/**
 * Add new combination
 * @returns {function(...[*]=)}
 */
export const addNewCombination = () => {
    return (dispatchEvent, getState) => {

        //Prepare layers
        let layers = []
        let meshes = ''
        let materials = getState().sceneConfig.api.getMaterialLibrary()
        let uuids = [];
        let decoded_jwt = jwt_decode(localStorage.getItem('user'));
        const userId = decoded_jwt.id;

        materials = materials.sort(commonFunction.sortLayerName);
        materials.forEach(material => {
            console.log(material);
            layers.push(material.material.name.replace(/_/g, ' ').replace(/-/g, ' '))
            meshes = meshes == '' ?  material.params.id : (meshes + ';' + material.params.id)
            uuids.push(material.params.id);
        })
        
        meshes = meshes; //localStorage.getItem("current-meshes");

        axios.post('layer/getList', {
            uuids: uuids,
            userId: userId
        }).then(response => {

            const layersResponse = response.data.data.layers;
            console.log("meshes: "+ meshes);
            //Validate Preset before adding combination
            let presetList = {...getState().combinations.presetList};
            let checkMaterialApplied = true;
            for(let layer of layersResponse){
                if(!layer.materialId || !layer.materialFolderId){
                    checkMaterialApplied = false;
                }
            }

            //This one inside the else statement below, move here to bypass the check applying Material to Layer
            //Dispatch loading true
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
    
            //Prepare scene file
            let sceneConfig = {
                config: {},
                geometry: {},
                materials: {}
            }
            let presets = [];
            let presetIds = [];
            
            const presetFolderList = {...getState().presetLibrary.presets};
            materials.forEach(material => {
                for(let layer of layersResponse){
                    if(material.params.id == layer.layerId){
                        for (const [key, value] of Object.entries(presetFolderList)) {
                            if(value.data){
                                for(let preset of value.data){
                                    if(layer.materialId == preset.uuid){
                                        presets.push(preset.name);
                                        presetIds.push(preset.uuid);
                                    }
                                }
                            }
                        }
                    }
                }
            })
            
            getState().sceneConfig.api.exportScene().then(result => {
                sceneConfig = result
                //Prepare file and layers to sent
                let blob = new Blob([JSON.stringify(sceneConfig)], {type: 'application/json'});
                // let staticImg = getState().sceneConfig.api.getSnapshotData("image/jpg", 600, 338)
                // let staticImg = getState().sceneConfig.api.getSnapshotData("image/jpg", 2560, 1440)
                let staticImg = getState().sceneConfig.api.getSnapshotData("image/jpg");
                let data = new FormData()
                data.append("meshes", meshes)
                data.append("presets", presets)
                data.append("presetIds", presetIds)
                data.append("layers", JSON.stringify(layers))

                data.append("logoDuration", document.getElementById("logo_duration").value != "" ? document.getElementById("logo_duration").value : 5)

                data.append("layersLabel", 
                document.getElementById("layers_label") && document.getElementById("layers_label").value != "" ? document.getElementById("layers_label").value : "{}")
                data.append("layersTag", 
                document.getElementById("layers_tag") && document.getElementById("layers_tag").value != "" ? document.getElementById("layers_tag").value : "{}")
                data.append("layersPrice", 
                document.getElementById("layers_price") && document.getElementById("layers_price").value != "" ? document.getElementById("layers_price").value : "{}")
                
                data.append("files", blob, 'scene.json' )
                
                //Additional Data
                var x = document.getElementById("fullScreenIcon");
                if (window.getComputedStyle(x).display === "block") {
                    data.append("isShowFullScreen", true)
                }else{
                    data.append("isShowFullScreen", false)
                }
                var y = document.getElementById("helperIcon");
                if (window.getComputedStyle(y).display === "block") {
                    data.append("isShowHelper", true)
                }else{
                    data.append("isShowHelper", false)
                }
                var z = document.getElementById("3DConfiguratorIcon");
                if (window.getComputedStyle(z).display === "block") {
                    data.append("isShow3DConfigurator", true)
                }else{
                    data.append("isShow3DConfigurator", false)
                }
                var b = document.getElementById("CameraDoubleClickIcon");
                if (window.getComputedStyle(b).display === "block") {
                    data.append("isCameraDoubleClick", true)
                }else{
                    data.append("isCameraDoubleClick", false)
                }
                
                var z = document.getElementById("screenshotIcon");
                if (window.getComputedStyle(z).display === "block") {
                    data.append("isShowScreenshot", true)
                }else{
                    data.append("isShowScreenshot", false)
                }
                
                var v = document.getElementById("changeBackgroundIcon");
                if (window.getComputedStyle(v).display === "block") {
                    data.append("isShowChangeBackground", true)
                }else{
                    data.append("isShowChangeBackground", false)
                }
                
                var v = document.getElementById("measurementIcon");
                if (window.getComputedStyle(v).display === "block") {
                    data.append("isShowMeasurement", true)
                }else{
                    data.append("isShowMeasurement", false)
                }

                // var annotationUrl = document.getElementById("annotation_url").value;
                // if(annotationUrl != ''){
                //     data.append("annotationUrl", annotationUrl)
                // }
                
                if (window.getComputedStyle(document.getElementById("ignoreAnimation")).display === "none") {
                    data.append("isIgnoreAnimation", false)
                }else{
                    data.append("isIgnoreAnimation", true)
                }

                let info = getState().sceneConfig.api.sceneInfo;
                data.append("sceneInfo", JSON.stringify(info))
                //data.append("staticImg", staticImg)

                var res = Array.from(data.entries(), ([key, prop]) => (
                            {[key]: {
                            "ContentLength": 
                            typeof prop === "string" 
                            ? prop.length 
                            : prop.size
                            }
                        }));
                
                console.log(res);
    
                axios.post('combination/create', data,
                    {
                        headers: {'Content-Type': 'multipart/form-data' }
                    }
                ).then(response => {
                    let {data} = response
                    let createdCombination = data.data.combination;
                    if (data.success) {

                        
                        let payload = {
                            "id": createdCombination.id,
                            "staticImg": staticImg
                        }
                        //Update Static Img
                        axios.post('combination/update-default-static-img', payload
                        ).then(response => {
                            axios.get('combination/get-list?meshes=' + meshes).then(response => {
                                let {data} = response
                                if (data.success) {
                                    dispatchEvent({
                                        type: REQUEST_COMBINATIONS_SUCCESS,
                                        combinations: data.data.combination,
                                        loading: false,
                                        createdCombination: createdCombination,
                                        hasError: false
                                    })
                                }
                
                                if (!data.success) {
                                    dispatchEvent({
                                        type: REQUEST_COMBINATIONS_FAILED,
                                        hasError: true,
                                        error: data.data.message,
                                        loading: false
                                    })
                                }
                            }).catch(error => {
                                dispatchEvent({
                                    type: REQUEST_COMBINATIONS_ERROR,
                                    hasError: true,
                                    error: 'Combinations could not be loaded !',
                                    loading: false
                                })
                            })
                        });

                    }
    
                    if (!data.success) {
                        dispatchEvent({
                            type: REQUEST_CREATE_COMBINATIONS_FAILED,
                            hasError: true,
                            error: data.data.message,
                            loading: false
                        })
                    }
                }).catch(error => {
                    // console.log(JSON.stringify(error))
                    // if (error.response) {
                    //     console.log(error.response.data);
                    //     console.log(error.response.status);
                    //     console.log(error.response.headers);
                    // }
                    dispatchEvent({
                        type: REQUEST_CREATE_COMBINATIONS_ERROR,
                        hasError: true,
                        error: 'Combinations could not be created ! ' + error.response.data.message,
                        loading: false
                    })
                    // setTimeout(() => {
                    //     dispatchEvent({
                    //         type: REQUEST_CREATE_COMBINATIONS_ERROR,
                    //         hasError: false,
                    //         error: 'Combinations could not be created ! ' + error.response.data.message,
                    //         loading: false
                    //     })
                    // }, 135000);
                })
            })


            // if( layersResponse.length != uuids.length || !checkMaterialApplied)
            // {
            //     dispatchEvent({
            //         type: REQUEST_CREATE_COMBINATIONS_FAILED,
            //         hasError: true,
            //         error: "You must create or apply a Preset for each Material Group before adding Combination.",
            //         loading: false
            //     })
            //     setTimeout(() => {
            //         dispatchEvent({
            //             type: REQUEST_CREATE_COMBINATIONS_FAILED,
            //             hasError: false,
            //             error: "You must create or apply a Preset for each Material Group before adding Combination.",
            //             loading: false
            //         })
            //     }, 5000);
            //     return false;
            // }
            // else
            // {
                
            // }
        });

    }
}

export const sceneInfoAlert = () => {
    
    return (dispatchEvent, getState) => {
        let api = getState().sceneConfig.api
        let infos = api.sceneInfo;
        console.log(infos);
        axios.get('scene-info/list').then(response => {
            let {data} = response
            const sceneInfos = data.data.sceneInfos;
            console.log(sceneInfos);
            dispatchEvent({
                type: SCENE_INFO_ALERT,
                infos: infos,
                sceneInfos: sceneInfos,
                loading: false,
                hasError: false
            })
        }).catch(error => {
        })
    }
}

/**
 * Clone Combination
 * @param oldUuid
 * @param combination
 * @param position
 * @returns {function(...[*]=)}
 */
export const cloneCombination = (oldUuid, combination, position) => {
    return (dispatchEvent, getState) => {
        let [...combinations] = getState().combinations.combinationsList
        let [combinationToClone] = combinations.filter(combination => combination.uuid === oldUuid)
        combinations.splice(position, 0, {
            ...combinationToClone,
            uuid: combination.uuid,
            id: null
        })

        dispatchEvent({
            type: CLONE_COMBINATION,
            combinations: combinations,
            loading: false
        })
    }
}

/**
 * Delete combination
 * @param uuid
 * @returns {function(...[*]=)}
 */
export const deleteCombination = uuid => {
    return (dispatchEvent, getState) => {
            let combinations = [...getState().combinations.combinationsList]
        let combinationsUpdated = combinations.filter(combination => combination.uuid !== uuid)

        dispatchEvent({
            type: DELETE_COMBINATION,
            combinations: combinationsUpdated,
            loading: false
        })
    }
}

/**
 * Update combination in reducer
 * @param payload
 * @param position
 * @returns {function(...[*]=)}
 */
export const updateCombination = (payload, position) => {
    return (dispatchEvent, getState) => {
        let combinations = [...getState().combinations.combinationsList]
        let remainingCombinations = combinations.filter(combination => combination.uuid !== payload.id)
        let [combinationUpdated] = combinations.filter(combination => combination.uuid === payload.id)
        remainingCombinations.splice(position, 0, {
            ...combinationUpdated,
            price: payload.price,
            description: payload.description
        })

        dispatchEvent({
            type: UPDATE_COMBINATION,
            combinations: remainingCombinations,
            loading: false
        })
    }
}

export const login = (payload) => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: LOGIN,
            loading: false
        })
    }
}


/**
 * Highlight objects from material library dropdown
 * @param group
 * @returns {function(...[*]=)}
 */
export const selectMeshes = group => {
    return (dispatchEvent, getState) => {
        let api = getState().sceneConfig.api
        api.highlightObjects.apply(api, group.mesh) //Highlight objects


        let p = {edgeStrength: 10};
        api.getTweener(p)
                .to({edgeStrength: 0.0}, 3*1000)
                // .delay(4*1000)
                .onUpdate(()=> api.setOutlineParams(p))
                .start();


        let materialId = null
        let material = group.params
        if(material == null)
        {
            //Select Material GROUP
            let materialsLibrary = getState().sceneConfig.materialsLibrary
            let materials = getState().sceneConfig.materials
            
            for(let mat of materialsLibrary)
            {
                if(mat.params.id == group)
                {
                    let matchedMaterial = null;
                    let map = null;
                    let visible = true;
                    for (const [key, value] of Object.entries(materials)) {
                        if (key == group) {
                            map = value.material.map;
                            visible = value.material.visible;

                            matchedMaterial= value.material;
                        }
                    }
                    group = mat;
                    group.params = matchedMaterial;
                    group.params.map = map;
                    //group.material.visible = visible;
                    //group.params.visible = visible;
                    material = group.params;
                    api.highlightObjects.apply(api, group.mesh) //Highlight objects
                }
            }
        }
        for (const [key, value] of Object.entries(getState().sceneConfig.materials)) {
            if (value.material.id === material.id) {
                materialId = key
            }
        }
        dispatchEvent({
            type: SELECT_MESH,
            material: material,
            materialId: materialId,
            object: {uuid: group.mesh[0].uuid, name: group.mesh[0].name},
            objects: group.mesh,
            pickedObject: group.mesh[0],
            selected: true,
            mapType: null,
        })
    }
}

/**
 * Download json file with materials in model
 * @returns {function(...[*]=)}
 */
export const downloadMaterials = () => {
    return (dispatchEvent, getState) => {
        getState().sceneConfig.api.sceneManager.exportMaterialConfig().then(value => {
            let link = document.createElement("a");
            let blob = new Blob([JSON.stringify(value)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "materials.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
        });
    }
}

/**
 * Upload json with materials into model
 * @returns {function(...[*]=)}
 */
export const loadMaterials = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            if (file) {
                let reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function () {
                    let materials = JSON.parse(reader.result)
                    if (JSON.parse(reader.result)) {
                        // Import materials in cadcentre api
                        sceneConfig.api.importMaterialConfig(materials)

                        //Update materials library
                        let materialsLibrary = []
                        materialsLibrary = sceneConfig.api.getMaterialLibrary()

                        //Prepare materials list array
                        let materialsList = []
                        for (const [key, value] of Object.entries(materials)) {
                            materialsList.push({label: key, value: key});
                        }

                        //Remove previous highlighted objects if any
                        sceneConfig.api.highlightObjects.apply(sceneConfig.api, [])

                        //Change selected object state
                        dispatchEvent({
                            type: UPLOAD_MATERIALS,
                            materials: materials,
                            materialsList: materialsList,
                            materialsLibrary: materialsLibrary,
                            object: {},
                            material: {},
                            pickedObject: {},
                            materialId: null,
                            selected: false,
                            objects: [],
                        })
                    }
                }
            }
        }
        inputFile.click();
    }
}

export const saveCombinationToState = combination => {
    return (dispatchEvent, getState) =>{
        dispatchEvent({
            type: LOAD_COMBINATION,
            combination: combination
        })
    }
}

export const takeScreenshotIframe = () => {
    return (dispatchEvent, getState) =>{
        dispatchEvent({
            type: IFRAME_TAKE_SCREENSHOT
        })
    }
}

export const enableArIframe = () => {
    return (dispatchEvent, getState) =>{
        let arSettings = {...getState().iframe.arSettings}
        arSettings.enabled = !getState().iframe.arSettings.enabled

        dispatchEvent({
            type: ENABLE_AR_IFRAME,
            arSettings: arSettings
        })
    }
}

export const resetCameraIframe = () => {
    return (dispatchEvent, getState) =>{
        dispatchEvent({
            type: RESET_CAMERA_IFRAME
        })
    }
}

/**
 * Load available filters from backend
 * @returns {function(...[*]=)}
 */
export const loadFiltersRequest = () => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: REQUEST_TRACKERS_FILTERS,
            loading: true
        })
        axios.get('view/get-filter').then(response => {
            let {data} = response
            if (data.success) {
                dispatchEvent({
                    type: REQUEST_TRACKERS_FILTERS_SUCCESS,
                    loading: false,
                    availableFilters: data.data.filter
                })
            }

            if (!data.success) {
                dispatchEvent({
                    type: REQUEST_TRACKERS_FILTERS_FAILED,
                    loading: false,
                    hasError: true,
                    error: data.message
                })
            }
        }).catch(error => {
            dispatchEvent({
                type: REQUEST_TRACKERS_FILTERS_ERROR,
                loading: false,
                hasError: true,
                error: error.message
            })
        })
    }
}

/**
 * Send request to filter trackers
 */
export const loadReportRequest = (filter) => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: LOAD_REPORT_REQUEST,
            loading: true
        })
        axios.post('view/report', filter)
            .then(response => {
                let {data} = response
                if (data.success) {
                    dispatchEvent({
                        type: LOAD_REPORT_SUCCESS,
                        trackers: data.data.report,
                        listColumn: data.data.listColum,
                        loading: false
                    })
                } else {
                    dispatchEvent({
                        type: LOAD_REPORT_FAILED,
                        error: data.message,
                        hasError: true,
                        loading: false
                    })
                }
            })
            .catch(err => {
                dispatchEvent({
                    type: LOAD_REPORT_ERROR,
                    error: 'Please select all filters !',
                    hasError: true,
                    loading: false
                })
            })
    }
}

/**
 * TODO change after is implemented login
 * For now will create tracker on each combination view
 * @param type
 * @param token
 * @returns {function(...[*]=)}
 */
export const createTracker = (type, token) => {
    return (dispatchEvent, getState) => {
        axios.post(
            'view/create',
            {
                "url": token,
                "type": type
            }
        ).then(response => {
                console.log(response)
            }).catch(err => {
                console.log(err.response)
            })
    }
}

export const enableRotationIframe = value => {
    return (dispatchEvent, getState) => {
        let combination = {...getState().iframe.combination}
        combination.config.camera.orbitParams.autoRotate = value
        dispatchEvent({
            type: ENABLE_ROTATION_IFRAME,
            combination: combination
        })
    }
}

export const uploadFiles = files => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: UPLOAD_FILES,
        })
    }
}

export const changePixelRatio = value => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            type: CHANGE_PIXEL_RATIO_SETTING,
            pixelRatio: value
        })
    }
}

export const enableAnnotations = (value) => {
    // console.log("enableAnnotations");
    return (dispatchEvent, getState) => {
        dispatchEvent({
            enableAnnotations: value,
            type: ENABLE_ANNOTATIONS,
        })
    }
}

export const selectAnnotations = (value) => {
    return (dispatchEvent, getState) => {
        dispatchEvent({
            annotation: value,
            type: SELECT_ANNOTATIONS,
        })
        dispatchEvent({
            enableAnnotations: false,
            type: ENABLE_ANNOTATIONS,
        })
        dispatchEvent({
            enableAnnotations: true,
            type: ENABLE_ANNOTATIONS,
        })
    }
}

export const changeAnnotationText = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.annotation.text = value
        dispatchEvent({
            type: TEXT_ANNOTATIONS,
            sceneConfig: sceneConfig
        })
    }
}

export const changeAnnotationTitle = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.annotation.title = value
        dispatchEvent({
            type: TITLE_ANNOTATIONS,
            sceneConfig: sceneConfig
        })
    }
}

export const removeAnnotation = () => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.annotation.remove()
        dispatchEvent({
            type: TITLE_ANNOTATIONS,
            sceneConfig: sceneConfig
        })
    }
}

export const enableTAA = (value) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        let sceneConfig = {...getState().sceneConfig};
        let taaParams = sceneConfig.config.taa != undefined ? sceneConfig.config.taa : api.getTAAParams();
        taaParams.enabled = value;
        api.setTAAParams(taaParams);
        
        let enableTAAInterval = setInterval(() => {
            if(api.getTAAParams().enabled == undefined){
                api.setTAAParams(sceneConfig.config.taa);
            }else{
                clearInterval(enableTAAInterval);
            }
        }, 1000);
        sceneConfig.config.taa = taaParams;
        dispatchEvent({
            type: ENABLE_TAA,
            sceneConfig: sceneConfig
        })
    }
}

export const enableSSR = (value) => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.enableSSR = value
        dispatchEvent({
            type: ENABLE_SSR,
            sceneConfig: sceneConfig
        })
    }
}

export const intensitySSR = (value) => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.intensitySSR = value
        dispatchEvent({
            type: INTENSITY_SSR,
            sceneConfig: sceneConfig
        })
    }
}

export const changeSide = value => {
    return (dispatchEvent, getState) => {
        let selectedObjects = {...getState().selectedObjects}
        let sceneConfig = {...getState().sceneConfig}
        let valueText = '';
        console.log(value);
        switch(value)
        {
            case '0':
                valueText = 'front';
                break;
            case '1':
                valueText = 'back';
                break;
            case '2':
                valueText = 'double';
                break;
        }
        
        selectedObjects.material.side = valueText
        sceneConfig.api.setMaterialParams(selectedObjects.pickedObject, {side: valueText})
        dispatchEvent({
            type: CHANGE_SIDE,
            selectedMaterial: selectedObjects.material
        })
    }
}


export const getImagesSize = (isUpdate = false) => {
    return (dispatchEvent, getState) => {
        
        if(!isUpdate)
        {
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
        }

        setTimeout(() => {

            axios.get('combination/get-image-list-size').then(response => {
                let {data} = response
                if (data.success) {

                    let totalSize = 0;
                    for(let combination of data.data)
                    {
                        totalSize += Number(combination.size);
                    }
                    let combs = data.data;

                    axios.get('user/get-storage-info').then(response2 => {
                        let {data} = response2;
                        dispatchEvent({
                            type: REQUEST_IMAGES_SIZE_SUCCESS,
                            imageList: combs,
                            imageTotalSize: totalSize,
                            storage: data.data.storageLimit,
                            storageExpired: new Date(data.data.storageExpiredDate),
                            loading: false
                        })
                    }).catch(error => {
                        dispatchEvent({
                            type: REQUEST_COMBINATIONS_ERROR,
                            hasError: true,
                            error: 'Combinations could not be loaded !',
                            loading: false
                        })
                    });
                }

                if (!data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_FAILED,
                        hasError: true,
                        error: data.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Combinations could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
    }
}

export const getTexturesSize = (isUpdate = false) => {
    return (dispatchEvent, getState) => {
        
        if(!isUpdate)
        {
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
        }

        setTimeout(() => {

            axios.get('combination/get-texture-list-size').then(response => {
                let {data} = response
                if (data.success) {

                    let totalSize = 0;
                    for(let combination of data.data)
                    {
                        totalSize += Number(combination.size);
                    }
                    let combs = data.data;

                    axios.get('user/get-storage-info').then(response2 => {
                        let {data} = response2;
                        dispatchEvent({
                            type: REQUEST_TEXTURES_SIZE_SUCCESS,
                            textureList: combs,
                            textureTotalSize: totalSize,
                            storage: data.data.storageLimit,
                            storageExpired: new Date(data.data.storageExpiredDate),
                            loading: false
                        })
                    }).catch(error => {
                        dispatchEvent({
                            type: REQUEST_COMBINATIONS_ERROR,
                            hasError: true,
                            error: 'Combinations could not be loaded !',
                            loading: false
                        })
                    });
                }

                if (!data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_FAILED,
                        hasError: true,
                        error: data.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Combinations could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
    }
}


export const getPublicUploadedFilesSize = (isUpdate = false) => {
    return (dispatchEvent, getState) => {
        
        if(!isUpdate)
        {
            dispatchEvent({
                type: REQUEST_COMBINATIONS_LIST,
                loading: true
            })
        }

        setTimeout(() => {

            axios.get('user/get-public-uploaded-file').then(response => {
                let {data} = response
                if (data.success) {
                    if(data.data){
                        console.log(data.data);
                        let totalSize = 0;
                        for(let file of data.data)
                        {
                            totalSize += Number(file.size);
                        }
                        let publicUploadedFiles = data.data;
                        console.log('zzzzzzzzzzzzzzzzz');
                        console.log(data.data);
    
                        axios.get('user/get-storage-info').then(response2 => {
                            let {data} = response2;
                            dispatchEvent({
                                type: REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS,
                                publicUploadedFilesList: publicUploadedFiles,
                                publicUploadedFilesTotalSize: totalSize,
                                storage: data.data.storageLimit,
                                storageExpired: new Date(data.data.storageExpiredDate),
                                loading: false
                            })
                        }).catch(error => {
                            dispatchEvent({
                                type: REQUEST_COMBINATIONS_ERROR,
                                hasError: true,
                                error: 'Combinations could not be loaded !',
                                loading: false
                            })
                        });
                    }
                }

                if (!data.success) {
                    dispatchEvent({
                        type: REQUEST_COMBINATIONS_FAILED,
                        hasError: true,
                        error: data.data.message,
                        loading: false
                    })
                }
            }).catch(error => {
                dispatchEvent({
                    type: REQUEST_COMBINATIONS_ERROR,
                    hasError: true,
                    error: 'Combinations could not be loaded !',
                    loading: false
                })
            })
        }, 1000);
    }
}


export const loadDefaultSceneEnv = (file) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        console.log(file);
        api.loadTexture(file, 
            event => console.log(event), {}).then(value => {
                value.encoding = (!value.encoding || value.encoding === LinearEncoding) ? sRGBEncoding: value.encoding;
                 api.sceneManager.setSceneEnvironment
                 (value, {imageBasedLighting: true, setBackground: true, setDiamondEnv: true, setEnvironment: true});
                //  api.setBackground(value);
        })

    }
}

export const loadDefaultDiamondEnv = (file) => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig

        api.loadTexture(file, 
            event => console.log(event), {}).then(value => {

                 api.sceneManager.setDiamondEnvironment
                 (value, 
                    {
                        imageBasedLighting: true, 
                        setBackground: false, 
                        setDiamondEnv: true, 
                        setEnvironment: false
                    });
        })

    }
}

export const enableFrustumCulling = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.frustumCulling = value
        dispatchEvent({
            type: ENABLE_FRUSTUM_CULLING,
            sceneConfig: sceneConfig
        })
    }
}

export const exportSceneGltf = () => {
    return (dispatchEvent, getState) => {
        const {api} = getState().sceneConfig
        api.sceneManager.exportSceneGltf(true, true).then(value => {
            let link = document.createElement("a");
            let blob = new Blob([value]);
            link.href = URL.createObjectURL(blob);
            link.download = "object.glb";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
        });
    }
}



export const changeNormalScaleX = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.normalScale[0] = parseFloat(value)
        materials[selectedObjects.materialId].material = selectedObjects.material.normalScaleX
        dispatchEvent({
            type: CHANGE_NORMAL_SCALE_X,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'normalMap'
        })
    }
}

export const changeNormalScaleY = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.normalScale[1] = parseFloat(value)
        materials[selectedObjects.materialId].material = selectedObjects.material
        dispatchEvent({
            type: CHANGE_NORMAL_SCALE_Y,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'normalMap'
        })
    }
}

export const changeNormalMapType = value => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        selectedObjects.material.normalMapType = value
        materials[selectedObjects.materialId].material = selectedObjects.material
        dispatchEvent({
            type: CHANGE_NORMAL_MAP_TYPE,
            materials: materials,
            selectedMaterial: selectedObjects.material,
            mapType: 'normalMap'
        })
    }
}

export const switchOldNewCamera = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        sceneConfig.config.camera.orbitParams.autoPushTarget = value
        let api = getState().sceneConfig.api;
        api.setCameraParams({orbitParams: {autoPushTarget: value}})
        dispatchEvent({
            type: SWITCH_OLD_NEW_CAMERA,
            sceneConfig: sceneConfig
        })
    }
}

export const insertFile = () => {
    return (dispatchEvent, getState) => {
        let materials = {...getState().sceneConfig.materials}
        let selectedObjects = {...getState().selectedObjects}
        const {api} = getState().sceneConfig

        const inputFile = document.getElementById("fileInput");
        inputFile.onchange = e => {
            console.log(e.target.files);
            const files = Array.from(e.target.files)
            let file = Array.isArray(files) ? files[0] : files;
            let selectedFiles = new Map()
            files.forEach(file => {
                selectedFiles.set(file.name, file);
            });
            if (file) {
                console.log("selectedFiles", selectedFiles);
                api.importModelFiles(selectedFiles, false).then(() => {
                    // api.resetCamera();
                    console.log("importModelFiles");
                    api.exportScene().then(response => {
                        console.log("response", response);
                        //saveSceneConfigToState(response, api, 'files') //save json to state and also api object so that we can call later all api's on component update
                        let sceneConfig = response;

                        //Prepare materials list array
                        let materialsList = []
                        let materialsLibrary = api.getMaterialLibrary()
                
                        for (const [key, value] of Object.entries(sceneConfig.materials)) {
                            materialsList.push({label: key, value: key}); //Prepare materialsList array to be used in materials dropdown
                        }

                        var count = 0;
                        var getMaterialLibrary = setInterval(() => {
                            count ++;
                            api.getMaterialLibrary();
                            if(count == 5)
                            {
                                let meshes = ''
                                let materials = getState().sceneConfig.api.getMaterialLibrary()
                                
                                materials.forEach(material => {
                                    meshes = meshes == '' ?  material.params.id : (meshes + ';' + material.params.id)
                                })
                
                                localStorage.setItem('current-meshes', meshes);
                
                                clearInterval(getMaterialLibrary);
                            }
                        }, 1000);
                
                
                        dispatchEvent({
                            type: SAVE_SCENE_CONFIG_TO_STATE,
                            sceneConfig: sceneConfig,
                            api: api,
                            materialsList: materialsList,
                            materialsLibrary: materialsLibrary
                        })



                    });
                });

            }
            inputFile.value = null;
        }
        inputFile.click();
        inputFile.value = null;
    }
}

export const gizmoEnabled = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        let api = getState().sceneConfig.api;   

        if(value){
            let json = JSON.parse(sceneConfig.config.background);
            console.log("JSON", json);
            let previousBG = json.value;
            console.log("previousBG", previousBG);
            if(document.getElementById("previousBackgroundColor").value == ""){
                document.getElementById("previousBackgroundColor").value = previousBG;
            }
            api.setBackground(new Color(value[0] / 255.0, value[1] / 255.0, value[2] / 255.0));
        }else{
            let previousBG = document.getElementById("previousBackgroundColor").value;
            console.log("previousBG", previousBG);
            let value = convertHexToRgb(previousBG);
            console.log("value", value);
            api.setBackground(previousBG);
        }
     
        let gizmoParams = api.getTransformControlParams();
        console.log("gizmoParams", gizmoParams);
        gizmoParams.enabled = value;
        api.setTransformControlParams(gizmoParams);
    }
}

export const gizmoChangeMode = value => {
    return (dispatchEvent, getState) => {
        let sceneConfig = {...getState().sceneConfig}
        let api = getState().sceneConfig.api;        
        let gizmoParams = api.getTransformControlParams();
        gizmoParams.mode = value;
        api.setTransformControlParams(gizmoParams);
    }
}


const convertHexToRgb = hex => {
    if (hex.charAt(0) === '#') {
        hex = hex.substr(1);
    }
    if ((hex.length < 2) || (hex.length > 6)) {
        return false;
    }
    let values = hex.split(''),
        r,
        g,
        b;

    if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = r;
        b = r;
    } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16);
        g = parseInt(values[1].toString() + values[1].toString(), 16);
        b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = parseInt(values[2].toString() + values[3].toString(), 16);
        b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
        return false;
    }
    return [r, g, b];
}