import React from "react";
import {Table} from "react-bootstrap";
import Tracker from "../Trackers/Tracker";

function Trackers(props) {
    let hasData = true;

    if (!props.listColumn || !props.trackers) {
        hasData = false
    }

    return (
        <Table borderless className="trackers-table" >
            <thead>
            <tr>
                {hasData ? props.listColumn.map((item, i) => <th key={i}>{item}</th>) : 'No Data'}
            </tr>
            </thead>
            <tbody>
                {hasData ? <Tracker trackers={props.trackers}/> : ''}
            </tbody>
        </Table>
    )
}

export default Trackers;