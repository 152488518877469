import React, {createRef} from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import {
    exportSceneGltf,
    changeSceneView,
    downloadScene,
    downloadGltf,
    snapCanvas,
    changeCameraTargetSetting,
    changeCameraSetting,
    changeStraightenModelRotateSetting,
    resetStraightenModel,
    viewReflection,
    downloadReflection,
    loadReflection,
    clearAll,
    removeBackground,
    makeDiamond,
    makeStandard,
    makeRefraction,
    downloadMaterial,
    uploadMapFile,
    uploadScene,
    uploadEquirec,
    uploadCubeMap,
    uploadEnvironment,
    uploadMaterial,
    selectMeshes,
    downloadMaterials,
    loadMaterials,
    backgroundLoadImage,
    uploadFiles, takeScreenshot,
    loadCombinations,
    openMapFile,
    downloadMapFile,
    removeMapFile,
    removeAnnotation,

    loadDefaultSceneEnv,
    loadDefaultDiamondEnv,

    toggleAnimationPlay,
    toggleAnimationStop,

    addAmbient,
    toggleAudioPlay,
    toggleAudioStop,
    removeAudio,

    addAudio,
    removeAudioMaterial

} from "../../../../actions/actionsCreators";

function SceneEnvLoadButton(props) {
    /**
     * Filter all button types and fire its specific action
     */



    const dispatchAction = (type, value, obj) => {
        props.envClick(value);
        switch (type) {
            case "exportSceneGltf":
                props.exportSceneGltf()
                break;
            case "removeAudioMaterial":
                props.removeAudioMaterial(obj)
                break;

            case "removeAudio":
                props.removeAudio()
                break;

            case "addAudio":
                props.addAudio(value)
                break;

            case 'toggleAudioPlay':
                props.toggleAudioPlay()
                break;
            case 'toggleAudioStop':
                props.toggleAudioStop()
                break;

            case "addAmbient":
                props.addAmbient()
                break;

            case 'toggleAnimationPlay':
                props.toggleAnimationPlay()
                break;
            case 'toggleAnimationStop':
                props.toggleAnimationStop()
                break;

            case 'loadDefaultSceneEnv':
                props.loadDefaultSceneEnv(value)
                break;
            case 'loadDefaultDiamondEnv':
                props.loadDefaultDiamondEnv(value)
                break;
            case 'sceneView':
                props.changeSceneView(value)
                break;
            case 'downloadScene':
                props.downloadScene()
                break;
            case 'downloadGltf':
                props.downloadGltf()
                break;
            case 'snapCanvas':
                props.snapCanvas()
                break;
            case 'resetCameraTarget':
                props.changeCameraTargetSetting()
                break;
            case 'resetCamera':
                props.changeCameraSetting()
                break;
            case 'straightenModelRotate':
                props.changeStraightenModelRotateSetting(value)
                break;
            case 'resetStraightenModel':
                props.resetStraightenModel()
                break;
            case 'loadReflection':
                props.loadReflection()
                break;
            case 'viewReflection':
                props.viewReflection()
                break;
            case 'downloadReflection':
                props.downloadReflection()
                break;
            case 'clearAll':
                props.clearAll()
                break;
            case 'removeBackground':
                props.removeBackground()
                break;
            case 'makeDiamond':
                props.makeDiamond(value)
                break;
            case 'makeStandard':
                props.makeStandard(value)
                break;
            case 'makeRefraction':
                props.makeRefraction(value)
                break;
            case 'downloadMaterial':
                props.downloadMaterial()
                break;
            case 'uploadScene':
                props.uploadScene()
                break;
            case 'uploadMapFile':
            case 'uploadLightMapFile':
            case 'uploadAoMapFile':
            case 'uploadEmissiveMapFile':
            case 'uploadBumpMapFile':
            case 'uploadNormalMapFile':
            case 'uploadRoughnessMapFile':
            case 'uploadMetalnessMapFile':
            case 'uploadAlphaMapFile':
                props.uploadMapFile(value)
                break;
            case 'uploadEquirec':
                props.uploadEquirec()
                break;
            case 'uploadCubeMap':
                props.uploadCubeMap()
                break;
            case 'uploadEnvironment':
                props.uploadEnvironment()
                break;
            case 'uploadMaterial':
                props.uploadMaterial(value)
                break;
            case 'selectMeshes':
                props.selectMeshes(value)
                break;
            case 'downloadMaterials':
                props.downloadMaterials()
                break;
            case 'loadMaterials':
                props.loadMaterials()
                break;
            case 'backgroundLoadImage':
                props.backgroundLoadImage()
                break;
            case 'uploadFiles':
                openDialog()
                break;
            case 'openMapFile':
                props.openMapFile(value)
                break;
            case 'downloadMapFile':
                props.downloadMapFile(value)
                break;
            case 'removeMapFile':
                props.removeMapFile(value)
                break;
            case 'takeScreenshot':
                props.takeScreenshot()
                break;
            case 'removeAnnotation':
                props.removeAnnotation()
                break;
            default:
                return null;
        }
    }

    //Files upload handling only for menu Upload Files
    const dropzoneRef = createRef();
    const history = useHistory();
    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };
    const acceptedFiles = ".dds, .glb, .fbx, .bin, .png, .obj, .gltf, .mtl, .jpg, .3dm";

    const onDrop = files => {
        let selectedFiles = new Map()
        files.forEach(file => {
            selectedFiles.set(file.name, file);
        });

        history.push({
            pathname: "/configurator",
            // pathname: "/",
            state: { files: selectedFiles }
        });

        props.uploadFiles(selectedFiles)
        window.location.reload(false);
    }

    return (
        <Row>
            <Dropzone ref={dropzoneRef} noClick noKeyboard accept={acceptedFiles} onDrop={onDrop}>
                {({getRootProps, getInputProps, acceptedFiles}) => {
                    return (
                        <div className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                            </div>
                        </div>
                    );
                }}
            </Dropzone>
            <Col md={12} className={(props.selectedSceneEnv == props.value || props.value == document.getElementById("selectedSceneEnv").value) ? 'scene-env-highlighted setting-col' : 'setting-col'} onClick={() => dispatchAction(props.type, props.value, props.obj)}>
                <span title={props.title} className="setting-name">{props.name}</span>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
    changeSceneView: changeSceneView,
    downloadScene: downloadScene,
    downloadGltf: downloadGltf,
    snapCanvas: snapCanvas,
    changeCameraTargetSetting: changeCameraTargetSetting,
    changeCameraSetting: changeCameraSetting,
    changeStraightenModelRotateSetting: changeStraightenModelRotateSetting,
    resetStraightenModel: resetStraightenModel,
    downloadReflection: downloadReflection,
    viewReflection: viewReflection,
    loadReflection: loadReflection,
    clearAll: clearAll,
    removeBackground: removeBackground,
    makeDiamond: makeDiamond,
    makeStandard: makeStandard,
    makeRefraction: makeRefraction,
    downloadMaterial: downloadMaterial,
    uploadMapFile: uploadMapFile,
    uploadScene: uploadScene,
    uploadEquirec: uploadEquirec,
    uploadCubeMap: uploadCubeMap,
    uploadEnvironment: uploadEnvironment,
    uploadMaterial: uploadMaterial,
    selectMeshes: selectMeshes,
    downloadMaterials: downloadMaterials,
    loadMaterials: loadMaterials,
    backgroundLoadImage: backgroundLoadImage,
    uploadFiles: uploadFiles,
    takeScreenshot: takeScreenshot,
    loadCombinations: loadCombinations,
    openMapFile: openMapFile,
    downloadMapFile: downloadMapFile,
    removeMapFile: removeMapFile,
    removeAnnotation: removeAnnotation,
    
    loadDefaultSceneEnv: loadDefaultSceneEnv,
    loadDefaultDiamondEnv: loadDefaultDiamondEnv,

    toggleAnimationPlay: toggleAnimationPlay,
    toggleAnimationStop: toggleAnimationStop,

    addAmbient: addAmbient,
    toggleAudioPlay: toggleAudioPlay,
    toggleAudioStop: toggleAudioStop,
    removeAudio: removeAudio,

    addAudio: addAudio,
    removeAudioMaterial: removeAudioMaterial,
    exportSceneGltf: exportSceneGltf
}

export default connect(null, mapDispatchToProps)(SceneEnvLoadButton)