import React, {Component} from 'react'
import PresetFolder from "./PresetFolder";
import './Preset.css';
import { connect } from 'react-redux';

class PresetsLibrary extends Component {
    state = {
        menusCollapsedState: {}
    }

    /**
     * Add presets folder ids in state with false value.
     * To be used to track which folder group is collapsed
     */
    componentDidMount() {
        let menusCollapsedState = {}
        console.log('Preset Library componentDidMount');
        console.log(this.props.presetLibrary);
        this.props.presetLibrary.presets.forEach(folder => {
            menusCollapsedState = {
                ...menusCollapsedState,
                [folder.uuid]: false
            }
        })

        //Changes folders collapsed state
        this.setState({
            menusCollapsedState: {...menusCollapsedState}
        })
    }

    /**
     * Toggles folders collapsed status, only one folder can stay open at a time
     * @param uuid
     */
    collapseMenu = (uuid) => {

        let newState = {}
        Object.keys({...this.state.menusCollapsedState}).forEach(key => {
            newState = {
                ...newState,
                [key]: false
            }
        })

        this.setState({
            menusCollapsedState: {
                ...newState,
                [uuid]: !this.state.menusCollapsedState[uuid]
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.presetLibrary.presets.map(folder => {
                        return <PresetFolder
                            key={folder.uuid}
                            uuid={folder.uuid}
                            name={folder.name}
                            user={this.props.user}
                            pickedObject={this.props.presetLibrary.pickedObject}
                            presets={folder.data ? folder.data : null}
                            collapsed={this.state.menusCollapsedState[folder.uuid]}
                            collapse={() => this.collapseMenu(folder.uuid)}
                        />
                    })
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        presetLibrary: state.presetLibrary,
        user: state.user
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PresetsLibrary)