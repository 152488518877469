import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    applyPreset,
} from "../../../../actions/actionsCreators";

class ApplyPresetModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: ''
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    /**
     * Apply preset to selected objects
     */
    applyPreset = () => {
        axios.post('layer/create', {layerId: this.props.layerUuid, materialId: this.props.uuidPreset}
        ).then(response => {
            
        });
        this.props.applyPreset(this.props.uuidPreset, this.props.uuidPresetFolder)
        this.handleClose()
    }

    render() {
        return (
                <Col md={5} className="preset-name" onClick={() => this.applyPreset()}>
                        <span className="">{this.props.name}</span>
                </Col>
                
        )
    }
}

const mapDispatchToProps = {
    applyPreset: applyPreset,
}

const mapStateToProps = (state) => {
    return {
        selectedObjects: state.selectedObjects.selected
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPresetModal)