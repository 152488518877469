import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Form, Button, Tabs, Tab, Accordion, Table, Col, Row} from "react-bootstrap";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import {SpinnerSmall} from "../../../helpers/spinners";
import closeIcon from "../../../assets/close-icon@2x.png";
import MenuItemTextArea from "../Sidebar/MenuItems/MenuItemTextArea";

import {updateCombination, loadCombinations,getCombinationsSize,
    refreshCombinationsSizeAfterDelete} from '../../../actions/actionsCreators';
import PaypalExpressBtn from 'react-paypal-express-checkout';

class PplModal extends Component {
    state = {
        isShow: false,
        
        client:{
            production: 'AV-_MrT_x8R02HiancqNIR2s1_rYxh16IJyOZ0JKmqG-JNHSHCckbuWn8GRqBq7lMGTrKXkbMafVJybT',
            sandbox: 'AdHa-dLFk6Vr9AmMrAJlZAykF0hKIpnxPlms_0o8Drlw5GCGs-_c6E3OSI7XG7V0KCB3v1UzqZx9Wf5K'
        },
        
        chosenPpl:{
            period: 1,
            text: 'Pay For 1 Month'
        },

        style:{
            color: "gold",
            shape: "rect",
            layout: "vertical"
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    setShow = () => {
        this.setState({
            isShow : true
        })
    }
    setHide = () => {
        this.setState({
            isShow : false
        })
    }
    
    onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        alert("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }
    onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        console.log(payment);
        axios.post('combination/create-ppl-combination', {
            uuid: this.props.uuid, period: this.state.chosenPpl.period
            , price: this.state.chosenPpl.period == 1 ? this.props.ppl?.priceOneMonth
            :
            (
                this.state.chosenPpl.period == 3 ? this.props.ppl?.priceThreeMonth : 
                (
                    this.state.chosenPpl.period == 6 ? this.props.ppl?.priceSixMonth : 
                    this.props.ppl?.priceOneYear
                )
            )
            , paymentID: payment.paymentID
        }).then(response => {
            this.props.getCombinationsSize(0, 10);
        }).catch(err => {
        })
                // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }

    pplChange = (value) => {
        switch(value.target.value){
            case "1":
                this.setState({chosenPpl: {
                    period: 1,
                    text: 'Pay For 1 Month'
                }})
                break;
            case "3":
                this.setState({chosenPpl: {
                    period: 3,
                    text: 'Pay For 3 Months'
                }})
                break;
            case "6":
                this.setState({chosenPpl: {
                    period: 6,
                    text: 'Pay For 6 Months'
                }})
                break;
            case "12":
                this.setState({chosenPpl: {
                    period: 12,
                    text: 'Pay For 1 Year'
                }})
                break;

        }
    }

    render() {
        return(
            <React.Fragment>
                    <>
                        <select 
                            style={{fontSize: 15}}
                            className="form-control"
                            onChange={this.pplChange}>
                            <option value="1">1 month</option>
                            <option value="3">3 months</option>
                            <option value="6">6 months</option>
                            <option value="12">1 year</option>
                        </select>
                        <button id="" style={{marginBottom: '0', margin: 1+'%'}} onClick={this.setShow} className="btn btn-primary">
                            {this.state.chosenPpl.text}
                        </button>
                        <Modal className="modalSize50" size="xl" show={this.state.isShow} onHide={this.setHide} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton className="rename-material-modal-title">
                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                <div>
                                    <img className="close-icon" src={closeIcon} alt="" onClick={this.setHide}/>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={{ span: 12}} style={{color: 'white'}}>
                                        <PaypalExpressBtn env={'production'}
                                            onError={this.onError}
                                            onSuccess={this.onSuccess}
                                            client={this.state.client}
                                            currency={'USD'}
                                            total={this.state.chosenPpl.period == 1 ? this.props.ppl?.priceOneMonth
                                                :
                                                (
                                                    this.state.chosenPpl.period == 3 ? this.props.ppl?.priceThreeMonth : 
                                                    (
                                                        this.state.chosenPpl.period == 6 ? this.props.ppl?.priceSixMonth : 
                                                        this.props.ppl?.priceOneYear
                                                    )
                                                )}
                                                style= {
                                                    this.state.style
                                                  }   
                                                />
                                    </Col>
                                </Row>
                        </Modal.Body>
                        </Modal>
                    </>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    refreshCombinationsSizeAfterDelete: refreshCombinationsSizeAfterDelete,
    getCombinationsSize: getCombinationsSize
}

export default connect(null, mapDispatchToProps)(PplModal);