import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import MenuGroup from "../MenuItems/MenuGroup";
import MenuItemTextBox from "../MenuItems/MenuItemTextBox";
import MenuItemTextBox2 from "../MenuItems/MenuItemTextBox2";
import MenuItemButton from "../MenuItems/MenuItemButton";
import MenuItemCheckbox from "../MenuItems/MenuItemCheckbox";
import MenuItemInputRange from "../MenuItems/MenuItemInputRange";
import MenuItemDropdown from "../MenuItems/MenuItemDropdown";
import {downscaleLevels} from "../../../../helpers/dropDownOptions";
import MenuItemColorPicker from "../MenuItems/MenuItemColorPicker";
import AddCombinationModal from "../../Combinations/AddCombinationModal";
import ShowCombinationsModal from "../../Combinations/ShowCombinationsModal";
import LoginModal from "../../Combinations/LoginModal";
import LogoutForm from "../../Combinations/LogoutForm";
import {FileLoader} from "three";
import jwt_decode from "jwt-decode";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';

let lang = {};
class MyAccount extends Component{
    state = {
    }

    componentDidMount() {
        new FileLoader().load('https://cadgl.net/tooltip_cadgl/tooltip.json', (response) => {
            this.setState({
                tooltip: JSON.parse(response)
            })
        });
        let token = localStorage.getItem('user');
        if(token)
        {
            this.setState({
                loggedIn: true,
                userInfo: jwt_decode(localStorage.getItem('user'))
            })
        }
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }

    toggleMenuVisibility = (type) => {
        let newState = {}
        Object.keys({...this.state}).forEach(key => {
            if(key != 'tooltip' && key != 'loggedIn' && key != 'userInfo')
            {
                newState = {
                    ...newState,
                    [key]: false
                }
            }
        })

        this.setState({
            ...newState,
            [type]: !this.state[type]
        })
        
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col id="login-modal" md={12} className="btn-save-as-preset" style={{marginTop: '50'}}>
                        {
                        !this.state.loggedIn ? 
                            <LoginModal /> : <LogoutForm userPermission={this.props.userPermission} email={this.state.userInfo.email} />
                        }
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default MyAccount