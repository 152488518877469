import React, {Component} from 'react';
import Navbar from "../Navbar/Navbar";
import ProductRenderer from "./ProductRenderer";
import LeoNguyenTemplate01 from "./Templates/LeoNguyenTemplate01";
import LeoNguyenTemplate02 from "./Templates/LeoNguyenTemplate02";
import DoorTemplate from "./Templates/DoorTemplate";
import CadGLTemplate from "./Templates/CadGLTemplate";

import {Table, Col, Row, Spinner} from "react-bootstrap";
import Controls from "./Controls";
import './Configurator.css';
import './Template_DoorTemplate.css';
import './Template_CadGLTemplate.css';

class Configurator extends Component {
    
    state = {
        lightStyle: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: '#f1f1f1',
            color: '#151515',
            overflowX: 'hidden',
            overflowY: 'scroll'
        },
        darkStyle: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            overflowX: 'hidden',
            overflowY: 'scroll'
        },
        style: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            backgroundColor: 'transparent',
            color: '#ffffff',
            overflow: 'hidden',
            //overflowY: 'scroll'
        },
        theme: 'dark'
    }

    changeTheme = () => {
        if (this.state.theme === 'dark') {
            this.setState({
                style: this.state.lightStyle,
                theme: 'light'
            })
        } else {
            this.setState({
                style: this.state.darkStyle,
                theme: 'dark'
            })
        }
    }

    whichTemplate = () => {
        let search = window.location.search;
        if(search.includes("template")){
            let templateName = search.split("template=")[1];
            switch(templateName){
                case "LeoNguyenTemplate01":
                    return <LeoNguyenTemplate01 />;
                    break;
                case "LeoNguyenTemplate02":
                    return <LeoNguyenTemplate02 />;
                    break;
                case "DoorTemplate":
                    return <DoorTemplate />;
                    break;
                case "CadGLTemplate":
                    return <CadGLTemplate />;
                    break;
            }
        }else{
            return <ProductRenderer />;
        }
    }

    render() {
        return (
            <div className="configurator-view" id="360-configurator" style={this.state.style}>
                
                <this.whichTemplate />
                
                <Controls theme={this.state.theme} changeTheme={this.changeTheme}/>
            </div>
        )
    }
}

export default Configurator;
