import axios from '../../axios';

var currentCombinationUrl, currentCombinationName = "";

export function renderAnnotationIcon(api) {
    
    let allAnnotation = api.getAllAnnotations();
    for(let annotation of allAnnotation)
    {
        if(annotation.index == 0){
            console.log("annotation.hidden: " + annotation.hidden);
            if(!annotation.hidden){
                document.getElementById("annotationIcon").style.display = "block";
            }else{
                document.getElementById("annotationIcon").style.display = "none";
            }
        }
    }
}

export function setDefaultRatio(api){
    api.setDisplayPixelRatio(window.devicePixelRatio);
    api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
    if(window.innerWidth < window.innerHeight){
        api.setAnnotationWidth(30 * 1920 / Math.max(window.innerWidth, window.innerHeight));
    }else{
        let isInIFrame = window.self !== window.top;
        if(isInIFrame){
            var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
            if(fullscreenElement == null){
                api.setAnnotationWidth(80 * 1920 / Math.max(window.innerWidth, window.innerHeight));
            }else{
                api.setAnnotationWidth(80 * 1920 / Math.max(window.innerWidth, window.innerHeight));
            }
        }else{
            api.setAnnotationWidth(60 * 1920 / Math.max(window.innerWidth, window.innerHeight));
        }
    }
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        api.setAnnotationWidth(30 * 1920 / Math.max(window.innerWidth, window.innerHeight));
    }
}

export function renderStaticImg(comp){
    switch(comp.staticImgMode)
    {
        // case 'manual':
        //     if (window.innerWidth < window.innerHeight) {
        //         document.getElementById("static-img").src= comp.ifMoImg;
        //     }
        //     else
        //     {
        //         document.getElementById("static-img").src= comp.ifPcImg;
        //     }
        //     document.getElementById("static-img").width = "600";
        // break;
        case 'default':
            // document.getElementById("static-img").src= comp.staticImg;
            document.getElementById("static-img").style.backgroundImage = "url('"+comp.staticImg+"')";
        break;
        default:
        break;
    } 
}

export function renderFullscreenIconHelperIcon(comp, type){
    if(window.innerWidth < window.innerHeight){
        document.getElementById('fullScreenIcon').style.display = 'none';
    }else{
        document.getElementById('fullScreenIcon').style.display = comp.isShowFullScreen == "true" ? 'block' : 'none';
    }

    
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        document.getElementById('fullScreenIcon').style.display = 'none';
    }

    document.getElementById('helperIcon').style.display = comp.isShowHelper == "true" ? 'block' : 'none';
    document.getElementById('3DConfiguratorIcon').style.display = (comp.isShow3DConfigurator == "true" || comp.isShow3DConfigurator == true) ? 'block' : 'none';
    if(type != "iframe"){
        document.getElementById('changeBackgroundIcon').style.display = comp.isShowChangeBackground == "true" ? 'block' : 'none';
        document.getElementById('screenshotIcon').style.display = comp.isShowScreenshot == "true" ? 'block' : 'none';
    }
    document.getElementById('measurementIcon').style.display = comp.isShowMeasurement == "true" ? 'block' : 'none';
}

export function waitAudioContext(api){
    let stopResuming = false
    let waitTime = 2000; // millisecond
    let resumer = () => {
        if(stopResuming) return;
        api.getAudioContext().resume().then(()=>{
            stopResuming = true;
        })
        setTimeout(resumer, waitTime)
        console.log("resumer");
    };
    resumer();
}

export function hideLayersAfterLoad() {
    if (document.getElementById("myLoader")) {
        document.getElementById("myLoader").style.display = "none";
    }
    if (document.getElementById("static-img")) {
        document.getElementById("static-img").style.display = "none";
    }
    if (document.getElementById("myLoader2")) {
        document.getElementById("myLoader2").style.display = "none";
    }
    if (document.getElementById("canvas")) {
        document.getElementById("canvas").style.display = "block";
    }
    if (document.getElementById("swipeIcon")) {
        document.getElementById("swipeIcon").style.cssText = "position: absolute !important; left: 48% !important; display: inline !important;";
    }
}


function ValidURL(str) {
    if(str.substring(0,4) == "http"){
        return true;
    }
    return false;
    // var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    // if(!regex .test(str)) {
    //   return false;
    // } else {
    //   return true;
    // }
  }


export function renderAnnotationModalEvent(api, annotation){
    if(ValidURL(annotation.text)){
        window.open(annotation.text, '_blank');
    }else{
        //props.selectAnnotations(annotation);
        api.animateCamera(annotation.cameraState);
        let annotationSelect = document.getElementById("annotationList");
        if(annotationSelect){
            annotationSelect.value = annotation.index;
            annotationSelect.dispatchEvent(new Event('change'));
        }
    
        var modalAnnotation = document.getElementById("modalAnnotation");
    
        
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            if(window.innerWidth > window.innerHeight){
                modalAnnotation.style.left = "50%";
                modalAnnotation.style.top = "70%";
                modalAnnotation.style.transform = "translate(-50%, -50%)";
            }
        }
    
    
    
        modalAnnotation.style.display = "block";
        document.getElementById("modal-body-annotation").innerHTML = annotation.text;
    
        // Get the <span> element that closes the modal
        var closeAnnotation = document.getElementsByClassName("closeAnnotation")[0];
        // When the user clicks on <span> (x), close the modal
        closeAnnotation.onclick = function() {
            modalAnnotation.style.display = "none";
            // api.resetCamera();
        }
    
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
            if (event.target == modalAnnotation) {
                modalAnnotation.style.display = "none";
                // api.resetCamera();
            }else{
                
            }
        }
    }
}

export function setNullIframeSrc(){
    let modalAnnotation = document.getElementById("modalAnnotation");
    modalAnnotation.style.display = "none";
    if(modalAnnotation.getElementsByTagName("iframe")[0]){
        modalAnnotation.getElementsByTagName("iframe")[0].src = "";
    }
}

export function truncate(source, size) {
    return source;
    //return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

export function renderAR(api, user){
    let xrParams = api.getXRParams();
    let isInIFrame = window.self !== window.top;
    if(isInIFrame){
        xrParams = api.getXRParams();
        xrParams.enabled = false;
        
        if(navigator.platform == 'iPhone' || navigator.platform == 'iPad' || navigator.platform == 'Win32'){
            api.setXRParams(xrParams);
        }
    }else{
        var xrInterval = setInterval(() => {
            xrParams = api.getXRParams();
            
            if (navigator.platform == 'iPhone' || navigator.platform == 'iPad') { // <-- Use the one here above
                    
                if ('xr' in navigator)
                {
                    if(!user.isAllowAR)
                    {
                        xrParams.enabled = false;
                    }
                    api.setXRParams(xrParams);
                }
                else
                {
                    var mySpans = document.getElementsByTagName('a');
                    var arBut;
                    for(var i=0;i<mySpans.length;i++){

                        if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){

                        arBut = mySpans[i];
                        break;

                    }

                    }
                    if(arBut)
                    {
                        //Hide Button for iOS for now
                        arBut.style.display = "none";
                        if(user.isAllowAR)
                        {
                            if(!xrParams.enabled)
                            {
                                arBut.style.display = "none";
                            }
                        }
                        else{
                            arBut.style.display = "none";
                        }
                        arBut.innerText = 'START AR';
                        arBut.removeAttribute("href");
    
                        // Get the modal
                        var modal = document.getElementById("myModal");
    
                        // Get the button that opens the modal
                        var btn = arBut;
    
                        // Get the <span> element that closes the modal
                        var span = document.getElementsByClassName("closeAR")[0];
    
                        // When the user clicks on the button, open the modal
                        var p = document.getElementsByClassName("modal-bodyAR-p")[0];
                        //p.innerHTML = "Please download or open <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> to view " + "<a href='"+document.location+"'>"+document.location+"</a> and experience the AR ";
                        p.innerHTML = "To view in AR <br/> <a id='urlClicking' href='#'>"+currentCombinationName+"</a> (Copy link) <br/> Use or install <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> <br/> <i>* AR will be fully available on IOS very soon</i> ";
                        
                        document.getElementById('urlClicking').addEventListener('click', function(){
                            const textField = document.createElement('textarea');
                            textField.innerText = currentCombinationUrl;
                            document.body.appendChild(textField);
                            textField.focus();
                            textField.select();
                            textField.setSelectionRange(0, 99999); /* For mobile devices */
                            try {
                            // Now that we've selected the anchor text, execute the copy command
                                const successful = document.execCommand('copy');
                            } catch(err) {
                                alert(err);
                            }
                            document.body.removeChild(textField);
                            alert("Copied to the clipboard");
                        });
                        btn.onclick = function() {
                        modal.style.display = "block";
                        }
    
                        // When the user clicks on <span> (x), close the modal
                        span.onclick = function() {
                        modal.style.display = "none";
                        }
    
                        // When the user clicks anywhere outside of the modal, close it
                        window.onclick = function(event) {
                            if (event.target == modal) {
                                modal.style.display = "none";
                            }
                        }
                    }

                }
            }
            else
            {
                var element =  document.getElementById('ARButton');
                if(element != null)
                {
                    var txt = element.textContent || element.innerText;
                    if(txt == 'AR NOT SUPPORTED')
                    {
                        element.style.display = "none";
                    }
                }
                var mySpans = document.getElementsByTagName('a');
                var arBut;
                for(var i=0;i<mySpans.length;i++){

                    if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                        arBut = mySpans[i];
                        break;
                    }
                }
                if(arBut){
                    arBut.style.display = "none";
                }
            }
            clearInterval(xrInterval);
        }, 1);
}
}

export function sortLayerName( a, b ) {
    if ( a.material.name < b.material.name ){
      return -1;
    }
    if ( a.material.name > b.material.name ){
      return 1;
    }
    return 0;
}

export function convertPriceToString(price, currency){
    let symbol = "€";
    if(currency == "USD"){
        symbol = "$";
    }
    return symbol + parseFloat(price, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
}
  
export function uploadGLBToLeo(api, viewerUrl, type, id = null){

    // Test Upload GLB To Leo
    api.sceneManager.exportSceneGltf(true, true).then(value => {
        let blob = new Blob([value]);
        let file = URL.createObjectURL(blob);
        let data = new FormData();
        data.append("files", blob, 'xxx.glb' );
        data.append("id", id );
        axios.post('combination/upload-object-to-leo', data,
            {
                headers: {'Content-Type': 'multipart/form-data' }
            }
        ).then(response => {
            let {data} = response;
            console.log(response);
            
            let modal3DViewer = document.getElementById("modal3DViewer");
            if(modal3DViewer.getElementsByTagName("iframe")[0]){
                modal3DViewer.getElementsByTagName("iframe")[0].src = viewerUrl + "/website/#model="+data.data;
                if(window.innerWidth < window.innerHeight){
                    if(window.visualViewport.scale <= 0.75){
                        //85%
                        document.getElementById("modal3DViewer").classList.add("scale-75");
                    }
                    else if(window.visualViewport.scale > 0.75 && window.visualViewport.scale < 1){
                        //85%
                        document.getElementById("modal3DViewer").classList.add("scale-85");
                    }else if(window.visualViewport.scale == 1){
                        //100%
                        document.getElementById("modal3DViewer").classList.add("scale-100");
                    }else if(window.visualViewport.scale > 1 && window.visualViewport.scale < 1.25){
                        //115%
                        document.getElementById("modal3DViewer").classList.add("scale-115");
                    }else if(window.visualViewport.scale >= 1.25){
                        //115%
                        document.getElementById("modal3DViewer").classList.add("scale-125");
                    }
                }
                // alert("window.visualViewport.scale: " + window.visualViewport.scale);
                // alert("Math.round(window.devicePixelRatio * 100): " + Math.round(window.devicePixelRatio * 100));
                if(window.innerHeight < 900){
                    modal3DViewer.style.transform = "translate(0%, -110%)";
                    modal3DViewer.style.height = "700px";
                }else{
                    modal3DViewer.style.transform = "translate(0%, -107%)";
                    modal3DViewer.style.height = "900px";
                }

                switch(type){
                    case "annotation":
                        document.getElementById("open-3d-button").removeAttribute("disabled");
                        document.getElementById("open-3d-button").style.cursor = "pointer";
                        document.getElementById("open-3d-button-spinner").style.display = "none";
                    break;
                    default:
                        document.getElementById("measurementIcon").style.cursor = "pointer";
                    break;
                }
                
            }
    
        });
    });
}