import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

import {
    downloadScene,
} from "../../../actions/actionsCreators";
import lang_it from '../../../lang/lang_it.json';
import lang_en from '../../../lang/lang_en.json';
import lang_fr from '../../../lang/lang_fr.json';
import lang_vn from '../../../lang/lang_vn.json';
let lang = {};
class SaveScene extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        sceneName: '',
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
    }

    handleChange = e => {
        this.setState({
            sceneName: e.target.value,
            error: '',
            hasError: false
        })
    }

    saveScene = e =>{
        this.props.downloadScene()
    }

    componentDidMount() {
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md={12} className="setting-col"  onClick={this.handleShow}>
                        <span title={this.props.title} className="setting-name">{this.props.name}</span>
                    </Col>
                    <Modal size="xl" show={this.state.show}   onHide={this.handleClose}
                    //onHide={this.handleClose} 
                    aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton className="rename-material-modal-title">
                            <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                            <div>
                                <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className="combinations-table table-borderless" size="lg">
                                <tr>
                                    <td><input placeholder="name" className="name-input" name="sceneName" id="sceneName" type="text" value={this.state.sceneName} onChange={this.handleChange} /> </td>
                                    <td>
                                        <button id="sceneName" className="add-combination-btn btn btn-primary" onClick={this.saveScene}>{lang.save}</button>
                                    </td>
                                </tr>
                            </Table>
                        </Modal.Body>
                    </Modal>
                </Row>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    downloadScene: downloadScene,
}

export default connect(null, mapDispatchToProps)(SaveScene)