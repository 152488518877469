import React, {useState} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import {
    changeAnnotationText,
    changeAnnotationTitle
} from "../../../../actions/actionsCreators";

function MenuItemTextBox2(props) {
    const [value, setState] = useState({
        value: props.value
    })

    const updateField = (e) => {
        
        setState({
            ...value,
            value: e.target.value 
        });
        console.log("e.target.value: " + e.target.value);
        console.log(JSON.stringify(value));
        dispatchAction(props.type, e.target.value)
    }

    /**
     * Filter all input text box types and fire its specific action
     */
    const dispatchAction = (type, value) => {
        switch (type) {
            case 'changeAnnotationText':
                props.changeAnnotationText(value)
                break;
            case 'changeAnnotationTitle':
                props.changeAnnotationTitle(value)
                break;
            default:
                return null;
        }
    }

    return (
        <Row /*{...props}*/>
            <Col md={6} className="setting-col-input">
                <span title={props.title} className="setting-name">{props.name}</span>
            </Col>
            <Col md={6} className="setting-col-input">
                <Form.Control
                    size="sm"
                    type="text"
                    className="menu-item-text-box"
                    value={value.value}
                    onChange={updateField}
                    disabled={!!props.disabled}
                    readOnly={!!props.readonly}/>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = {
    changeAnnotationText: changeAnnotationText,
    changeAnnotationTitle: changeAnnotationTitle
}

export default connect(null, mapDispatchToProps)(MenuItemTextBox2)