import React, {useState} from 'react';
import {Row, Col} from "react-bootstrap";

import Navbar from "../../components/Navbar/Navbar";
import ProductRenderer from "./ProductRenderer/ProductRenderer";
import Sidebar from "./Sidebar/Sidebar";
import LoginModal from "./Combinations/LoginModal";

import './Configurator.css';
import { $CombinedState } from 'redux';

function Configurator(props) {
    const productContent = React.createRef()

    const [value, setState] = useState({
        style: {}
    })

    const changeSidebarHeight = height => {
        setState({
            ...value,
            style: {
                height: height
            }
        });
    }

    const closeAcceptCookie = () => {
        var d=new Date();
        d.setTime(d.getTime()+(100*24*60*60*1000));
        var expires = "; expires="+d.toGMTString();
        var name = "accept-cookie-dont-show";
        var value  = "true";
        document.cookie = name+"="+value+expires+"; path=/";

        document.getElementById("accept-cookie-div").style.display = "none";
    }

    const readCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    return (
        <>
            <div id="360-app" className="configurator">
                {/*<Navbar />*/}
                <Row className="main-content app-main-content">
                    <Col md={{ span: 10 }} ref={productContent}>
                        <ProductRenderer changeSidebarHeight={changeSidebarHeight} files={props.location.state ? props.location.state : undefined} />
                        {/*<Controls />*/}
                    </Col>
                    <Col md={{ span: 2 }} id="configurator-sidebar" className="configurator-sidebar" style={value.style}>
                        <div className="sidebar-controls-container">
                            <Sidebar />
                        </div>
                    </Col> 
                </Row>
            </div>
            {
                (!readCookie("accept-cookie-dont-show") && window.location.pathname == "/") ? 
                <div id="accept-cookie-div" style={{bottom: 0, position: 'absolute', width: 100+'%', height: 60, backgroundColor: 'white', padding: 18}}>
                    <Row>
                        <Col md={{ span: 10 }}>
                        This website uses cookies to offer you better user experience. See the details at the <a target="_blank" href="https://www.cadgl.com/cookies-policy">Cookies Policy</a> page.
                        </Col>
                        <Col md={{ span: 2 }} style={{textAlign: 'right'}}>
                            <button onClick={closeAcceptCookie} style={{ marginTop: 0}} className='add-combination-btn btn btn-primary'>Accept</button>
                        </Col> 
                    </Row>
                </div>
                : ''
            }
        </>
    );
}

export default Configurator;
