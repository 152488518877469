import React, {useEffect, useState} from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import {FileLoader} from "three";
import PostProcessSettingsContainer from "../../../containers/Configurator/PostProcessSettingsContainer";
import SceneSettingsContainer from "../../../containers/Configurator/SceneSettingsContainer";
import MaterialSettingsContainer from "../../../containers/Configurator/MaterialSettingsContainer";
import MyAccountContainer from "../../../containers/Configurator/MyAccountContainer";



let gearIcon = 'https://cadgl.net/icons_cadgl/gear@2x.png';
let materialIcon = 'https://cadgl.net/icons_cadgl/materials@2x.png';
let processIcon = 'https://cadgl.net/icons_cadgl/process@2x.png';
let accountIcon = 'https://cadgl.net/icons_cadgl/profile.png';
let tooltip = null;


function Sidebar() {
    const [state, setState] = useState({
        settings: true,
        materials: false,
        process: false,
        account: false,
        currentTab: 'settings',
        tooltipTab: null,
    })

    useEffect(() => {
        new FileLoader().load('https://cadgl.net/tooltip_cadgl/tooltip.json', (response) => {
            tooltip = JSON.parse(response);
        });
    });

    const toggleTabVisibility = (type) => {
        if(type != state.currentTab)
        {
            let newState = {}
            Object.keys({...state}).forEach(key => {
                newState = {
                    ...newState,
                    [key]: false
                }
            })
    
            setState({
                ...newState,
                [type]: !state[type],
                currentTab: type
            })
        }
    }

    return (
    <Tab.Container>
        <Row style={{position: 'fixed', top: '0', width: 16.4+'%', right: 23+'px', zIndex: 99}}>
            <Col sm={12}>
                <Nav className="configurator-nav">
                    <Row>
                        <Col 
                        title={tooltip != null ? tooltip.scene_settings : ''}
                        md={3} className={`current-tab-${state.currentTab == 'settings'}`} onClick={() => toggleTabVisibility('settings')}>
                            <Nav.Item>
                                <Nav.Link>
                                    <img src={gearIcon} alt=""/>
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col 
                        title={tooltip != null ? tooltip.material_setup : ''}
                        md={3} className={`current-tab-${state.currentTab == 'materials'}`} onClick={() => toggleTabVisibility('materials')}>
                            <Nav.Item>
                                <Nav.Link>
                                    <img src={materialIcon} alt=""/>
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col 
                        title={tooltip != null ? tooltip.plugins : ''}
                        md={3} className={`current-tab-${state.currentTab == 'process'}`} onClick={() => toggleTabVisibility('process')}>
                            <Nav.Item>
                                <Nav.Link>
                                    <img src={processIcon} alt=""/>
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col 
                        title={tooltip != null ? tooltip.account : ''}
                        md={3} className={`current-tab-${state.currentTab == 'account'}`} onClick={() => toggleTabVisibility('account')}>
                            <Nav.Item>
                                <Nav.Link>
                                    <img src={accountIcon} alt=""/>
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                    </Row>
                </Nav>
            </Col>
        </Row>
        <Row className="settings" style={{marginTop: 38+'px'}}>
            <Col md={12} className="text-left">
                <Tab.Content>
                    {
                        state.settings ?
                            <SceneSettingsContainer />
                            : ''
                    }
                    {
                        state.materials ?
                            <MaterialSettingsContainer />
                            : ''
                    }
                    {
                        state.process ?
                            <PostProcessSettingsContainer />
                            : ''
                    }
                    {
                        state.account ?
                            <MyAccountContainer />
                            : ''
                    }
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container>
    )
}

export default Sidebar;