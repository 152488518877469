import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Form} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    changeMaterialName,
} from "../../../../actions/actionsCreators";
import closeIcon from "../../../../assets/close-icon@2x.png";

class RenameMaterial extends Component {
    state = {
        show: false,
        material: {},
        hasError: false,
        error: ''
    };

    /**
     * On component mount set material object so that current label can be displayed on modal
     */
    componentDidMount() {
        this.props.materialsList.map(material => {
            if (material.value === this.props.value) {
                this.setState({
                    material: {...material}
                })
            }
        })
    }

    /**
     * Update materials array on state and close modal
     */
    dispatchAction = () => {
        if (!this.state.hasError) {
            let materials = this.props.materialsList.map(element => {
                let {...material} = element
                if (material.value === this.state.material.value) {
                    material.label = this.state.material.label
                }
                return material
            })
            this.props.changeMaterialName(materials)
            this.handleClose()
        }
    }

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    /**
     * Updates field value on change
     * @param e
     */
    updateField = (e) => {
        this.setState({
            material: {
                value: this.state.material.value,
                label: e.target.value,
            },
            hasError: !e.target.value,
            error: 'Name can not be empty'
        });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md={12} className="setting-col" onClick={this.handleShow}>
                        <span className="setting-name">{this.props.name}</span>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Rename Material</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={4}>
                                <span>New Name</span>
                            </Col>
                            <Col md={8}>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={this.state.material.label}
                                    className="rename-material-text-box"
                                    onChange={this.updateField}/>
                            </Col>
                            {
                                this.state.hasError ?
                                    <React.Fragment>
                                        <Col md={4} />
                                        <Col md={8}>
                                            <span className="rename-material-modal-error">{this.state.error}</span>
                                        </Col>
                                    </React.Fragment> : ''
                            }
                        </Row>
                        <Row>
                            <Col md={4}/>
                            <Col md={8}>
                                <Button variant="primary" onClick={this.dispatchAction}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    changeMaterialName: changeMaterialName,

}

const mapStateToProps = (state) => {
    return {
        materialsList: state.sceneConfig.materialsList,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenameMaterial)