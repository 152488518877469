import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Table, Col, Row, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import closeIcon from "../../../assets/close-icon@2x.png";
import LoginForm from "./LoginForm";
import {login} from '../../../actions/actionsCreators';
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";

let helperPC = 'https://cadgl.net/icons_cadgl/pc-mouse.jpg';
let helperMobile = 'https://cadgl.net/icons_cadgl/mobile-touch.jpg';
let showInterval;

class First3DMModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false,
        helperIcon: helperPC
    };

    /**
     * Close modal
     */
    handleCloseHelper = () => {
        this.setState({
            show: false
        })
        if(document.getElementById("3dm-dont-show").checked == true)
        {
            var d=new Date();
            d.setTime(d.getTime()+(100*24*60*60*1000));
            var expires = "; expires="+d.toGMTString();
            var name = "3dm-dont-show";
            var value  = "true";
            document.cookie = name+"="+value+expires+"; path=/";
        }
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        });
        showInterval = setInterval(() => {
            if(document.getElementById("helperPopup")){
                // document.getElementById("helperPopup").childNodes[0].classList.add("popupWidth60");
                clearInterval(showInterval);
            }
        }, 1);
    }

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount() {
        if(!this.readCookie("3dm-dont-show"))
        {
            let uploadedFiles = this.props.file;
            if(uploadedFiles){
                uploadedFiles.files.forEach((file, path) => {
                    if (file.name.match(/\.(3dm)$/)) {
                        setTimeout(() => {
                            this.handleShow();
                        }, 1000);
                    }
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <img src={this.props.iconSrc} style={{width: 20}} onClick={this.handleShow}/>
                <Modal id="first3DMPopup" size="xl" show={this.state.show} 
                onHide={this.handleCloseHelper} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Important</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleCloseHelper}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{color: 'black'}}>
                        <p style={{fontSize: 16}}>
                            Gemstones in 3dm files must be prepared before uploading to CadGL.
                        </p>
                        <p style={{fontSize: 16, marginTop: 30}}>
                            <a href="https://www.cadgl.com/help/gemstones-3dm" target="_blank">Read tutorial</a> 
                        </p>
                    </Modal.Body>
                    <Modal.Footer style={{color: 'white'}}>
                        <div>
                            Hide this message <input type="checkbox" id="3dm-dont-show" />
                        </div>
                        <div>
                            <button style={{backgroundColor: 'grey', marginBottom: 0 + '!important'}}  className=" btn btn-primary" onClick={this.handleCloseHelper}>Close</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(First3DMModal)