import { getGPUTier } from 'detect-gpu';
import React, {useEffect, useState} from "react";
import {Spinner, Col, Row, DropdownButton, Dropdown, Navbar, Form, Button} from "react-bootstrap";
import {FileLoader, Vector2, TextureLoader, DefaultLoadingManager} from "three";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {switchCases} from "./switchCases";
import {SpinnerSmall} from "../../helpers/spinners";
import hamburger from '../../assets/hamburger-icon.png';
import axios from '../../axios';
import viewerUrl from '../../3dViewerUrl';
// import Navbar from "../Navbar/Navbar";

import logo from '../../assets/logo_site.png';


import deleteBtn from '../../assets/delete@2x.png';
import arOn from '../../assets/button-AR-On.png';
import cameraOn from '../../assets/button-Camera-On.png';
import switchOn from '../../assets/button-On.png';
import rotateOn from '../../assets/button-Rotate-On.png';

import arOff from '../../assets/button-AR-Off.png';
import cameraOff from '../../assets/button-Camera-Off.png';
import switchOff from '../../assets/button-Off.png';
import rotateOff from '../../assets/button-Rotate-Off.png';
import Switch from "react-bootstrap/esm/Switch";
import CombinationIcons from "../../components/Configurator/Combinations/CombinationIcons";
import UploadFileModal from "../../components/Configurator/Combinations/UploadFileModal";
import {enableAnnotations, saveSceneConfigToState} from "../../actions/actionsCreators";
import HardwareAccelerationModal from "../Configurator/Combinations/HardwareAccelerationModal";

import * as commonFunction from '../CommonFunction/CommonFunction.js';

import lang_it from '../../lang/lang_it.json';
import lang_en from '../../lang/lang_en.json';
import lang_fr from '../../lang/lang_fr.json';
import lang_vn from '../../lang/lang_vn.json';
import jwt_decode from "jwt-decode";
let lang = {};
if(localStorage.getItem('lang')){
    switch(localStorage.getItem('lang')){
        case "en":
            lang = lang_en;
        break;
        case "vn":
            lang = lang_vn;
        break;
        case "fr":
            lang = lang_fr;
        break;
        case "it":
            lang = lang_it;
        break;
    }
}else{
    lang = lang_en;
}

// const Viewer = require("../../cadcentre/dist/cadcenter");
const Viewer = window.CADEDITOR.icad;
let mousePosition = new Vector2(0, 0)
let mouseDownTime = 0
let API

let _logoVisibility = 1;
let draggingAnnotation = null;
let isFirstClick = false;

// Duration is in seconds
let isFullScreen = false;
let logoFadeInDuration = 1.0;
let logoFadeOutDuration = 1.0;
let logoStayDuration = 1.0;
let logoMinActionTime = 0.5; // 500 ms. Min time to check.
let _logoTweener;
let _logoState =  'startVisible';
let outlineFadeTime = 1;
let outlineVisibleTime = 4;
let outlineEdgeStrength = 1.0;
let mouseWheelTime = 0;
let lastMouseDownTime = 0;
let userInfo = {};
let currentCombinationName = '';
let currentCombinationUrl = window.location;
let originalCanvasHeight = 0;
let isIgnoreAnimation = false;
let play = 'https://cadgl.net/icons_cadgl/play.png';
let stop = 'https://cadgl.net/icons_cadgl/stop.png';
let sound_on = 'https://cadgl.net/icons_cadgl/sound_on.png';
let sound_off = 'https://cadgl.net/icons_cadgl/sound_off.png';
let help = 'https://cadgl.net/icons_cadgl/help.png';
let fullscreen = 'https://cadgl.net/icons_cadgl/fullscreen.png';
let auto_rotate = 'https://cadgl.net/icons_cadgl/auto_rotate.png';
let auto_rotate_stop = 'https://cadgl.net/icons_cadgl/auto_rotate_stop.png';
let annotation_on = 'https://cadgl.net/icons_cadgl/annotation_on.png';
let annotation_off = 'https://cadgl.net/icons_cadgl/annotation_off.png';
let annotationComments = [];
let isOnwer = false;
let isOwnerEmail = '';
const canvas = React.createRef()

function ProductRenderer(props, state) {

    const [value, setState] = useState({
        isShow3D: true,
        canvasHeight: 1000,
        userInfo: {},
    })

    const open3D = () => {
        alert('zxcz');
    }

    let arStatus = true;
    let autoRotate = false;
    let combinations;
    let annotationsLocal = [];
    let materialsLibraryLocal = [];
    const [gpu, setGPU] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [useCustomLoading, setUseCustomLoading] = useState(false);
    const [customLoading, setCustomLoading] = useState('');
    const [showHardwareModal, setShowHardwareModal] = useState(false);
    async function testGPU(){
        const gpuTier = await getGPUTier();
        setGPU(gpuTier);
    }

    let logoParams = {
        opacity: 1,
        enabled: true,
        image: "https://cadgl.net/editor/images/logo_editor.png",
        height: 0,
        width: 0,
        positionY: 0,
        positionX: 0,
        scale: 1,
        logoStayDuration: 60,
        logoFadeInDuration: 0,
        logoFadeOutDuration: 0
    };

    useEffect(() => {
        // let isMobile = false;
        // let isAppleGPU = false;
        // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform) ) {
        //     isMobile = true;
        // }
        // const iPad = (navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ || 
        //     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);
        // if(iPad) isMobile = true;
        // if(firstLoad){
        //     testGPU();
        //     if(gpu && firstLoad){
        //         setFirstLoad(false);
        //         if(gpu.gpu == "apple gpu (Apple GPU)") isAppleGPU = true;
        //         if(gpu.tier >= 2 || isMobile || isAppleGPU){
        //             coreFeature();
        //         }else{
        //             setShowHardwareModal(true);
        //         }
        //     }
        // }
        coreFeature();
        
    }, [gpu])

    function coreFeature(){
        
        let timeTimeout = 0;
        if(window.self !== window.top){
            timeTimeout = 5000;
        }
        document.getElementById("static-img").style.setProperty('height', window.innerHeight+'px', 'important');
        if(document.getElementById("canvas").clientWidth != undefined && document.getElementById("canvas").clientWidth > 0){
            setTimeout(() => {

                console.log("canvas annotation: " + document.getElementById("canvas").clientWidth);
                    let annoWidth = 48;
                    let annoHeight = 48;
                    //Create API instance
                    const api = new Viewer.API({
                        canvas: canvas.current,
                        gemConfig: {
                            quality: 'high',
                            diamondClass: [{
                                name: "diamond",
                                color: 0xaaaaaa
                            }]
                        },
                        annotationIconParams: {
                            maxAnnotations: 10,
                            // texture: "../../annotations.png",
                            texture: "https://cadgl.net/icons_cadgl/annotations.png",
                            columns: 10,
                            size: new Vector2(annoWidth, annoHeight),
                            padding: 2,
                            annotationHiddenAlpha: 0.3,
                            annotationHighlightTint: "gold",
                            // annotationTint: "red"
                        },
                        //logoParams: logoParams,
                        animationLoopCallback : ()=> animationLoop()
                    });

                    API = api

                    let gRefl = api.getGroundReflectionParams();
                    gRefl.enable = false;
                    api.setGroundReflectionParams(gRefl);
                    //api.setViewerSize(canvas.current.clientWidth, canvas.current.clientHeight);

                    
                    let xrParams = api.getXRParams();
                    xrParams.enabled = false;
                    api.setXRParams(xrParams);


                    commonFunction.setDefaultRatio(api);

                    //if (!props.iframe.combination.id) {
                    if (!props.match.params.id) {
                        new FileLoader().load("./scene.json", (response) => {
                            api.importScene(JSON.parse(response))
                        })
                    } else {

                        let combinationUuid =  props.match.params.id.split('&')[0];
                        axios.post('combination/get-combination', {
                            id: combinationUuid,
                            getMaterial: true,
                            getConfig: true,
                            getGeometry: true
                        }).then(response => {
                            document.getElementById("canvas").style.display = "none";
                            let comp = response.data.data.combination
                            currentCombinationName = comp.name;
                            annotationComments = comp.annotationComments;
                            // commonFunction.currentCombinationName = currentCombinationName;
                            isIgnoreAnimation = comp.isIgnoreAnimation;
                            if(isIgnoreAnimation == "true"){
                                api.stopAllAnimations();
                            }
                            let user = response.data.data.user;
                            setState({
                                ...value,
                                userInfo: user
                            });
                            

                            var element = document.getElementById("360-combination");
                            // Add the class to the element
                            element.classList.add(user.username.replace(/\s+/g, '') + "_annotation");

                            //Custom Logo
                            if(user.isAllowCustomLogoSetting){
                                if(user.logoUrl){
                                    logoParams.image = user.logoUrl;
                                }
                                if(user.logoStayDuration){
                                    logoParams.logoStayDuration = user.logoStayDuration;
                                }
                                if(user.logoFadeInDuration){
                                    logoParams.logoFadeInDuration = user.logoFadeInDuration;
                                }
                                if(user.logoFadeOutDuration){
                                    logoParams.logoFadeOutDuration = user.logoFadeOutDuration;
                                }
                                if(user.logoPositionX){
                                    logoParams.positionX = user.logoPositionX;
                                }
                                if(user.logoPositionY){
                                    logoParams.positionY = user.logoPositionY;
                                }
                                if(user.logoScale){
                                    logoParams.scale = user.logoScale;
                                }
                            }
                            if(user.loadingUrl && user.isAllowCustomLoadingSetting){
                                setUseCustomLoading(true);
                                setCustomLoading(user.loadingUrl);
                            }

                            if (window.getComputedStyle(document.getElementById("static-img")).display !== "none")
                            {
                                document.getElementById("myLoader2").style.display = "block";
                            }



                            
                            //commonFunction.renderFullscreenIconHelperIcon(comp, "annotation");

                            commonFunction.renderStaticImg(comp);
                
                            let token = localStorage.getItem('user');
                            if(token){
                                let decoded_jwt = jwt_decode(localStorage.getItem('user'));
                                if(decoded_jwt.email == user.email){
                                    isOnwer = true;
                                    isOwnerEmail = user.email;
                                }
                                // alert(JSON.stringify(decoded_jwt));
                            }
                            
                            initial(comp.path, user, comp);

                    

                            let combinationList = document.getElementById("combinationList");
                            axios.get('combination/get-list?meshes=' + comp.meshes+'&userId=' + user.userId).then(response => {

                                combinations = response.data.data.combination;
                                let index = 0 ;

                                for(let combination of combinations)
                                {
                                    let opt = new Option(combination.name, combination.path); 
                                    if(combination.uuid == comp.uuid)
                                    {
                                        document.getElementById('combinationPrice').value = combination.price != undefined ? '$' + parseFloat(combination.price, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() : 0;
                                        document.getElementById('combinationDesc').value = combination.description != undefined ? combination.description : '';
                                        document.getElementById('combinationTitle').value = combination.name != undefined ? combination.name : '';
                                        opt.selected = true;
                                    }
                                    combinationList[index] = opt;
                                    index ++;
                                }
                                combinations.map(function(combination, index) {
                                });

                            }).catch(error => {
                            })


                
                            logoParams.enabled = user.isAllowWatermarkLogo;
                            if(user.logoUrl && user.isAllowCustomLogoSetting){
                                logoParams.enabled = true;
                            }
                            else if(user.logoUrl && !user.isAllowCustomLogoSetting){
                                logoParams.enabled = user.isAllowWatermarkLogo;
                            }
                            else if((!user.logoUrl || user.logoUrl == "") && user.isAllowCustomLogoSetting){
                                logoParams.enabled = false;
                            }
                            userInfo.isShowLinkToolTip = user.isShowLinkToolTip;
                            api.setLogoParams(logoParams);
                            api.play() //Render 3D model on canvas

                            // api.setDofParams({enabled: props.sceneConfig.config.dof.enabled})
                            

                            //Render Annotation Comments
                            //annotation-comments-list
                            console.log(annotationComments);
                            renderAnnotationComments(annotationComments)

                        }).catch(err => {
                        })
                    }

                    function renderAnnotationComments(comments){
                        let commentRenderers = "";
                        comments = comments.reverse();
                        let emailAllowed =  props.match.params.id.split('&email=')[1];
                        for(let comment of comments){
                            let renderComment = '';
                            let contentArr = comment.content.split(' ');
                            let tmpContent = [];
                            for(let content of contentArr){
                                if (content.indexOf("http://") == 0 || content.indexOf("https://") == 0) {
                                    tmpContent.push('<a target="_blank" href="'+content+'">link</a>');
                                }else{
                                    tmpContent.push(content);
                                }
                            }
                            let contentFinal = tmpContent.join(" ");
                            if( (emailAllowed && emailAllowed == comment.email) || isOnwer){
                                renderComment = `
                                <div class="row annotation-comments-row">
                                    <div class="col-12 col-md-6" title="${comment.email}">
                                        <p style="font-weight: bold" >${comment.email.substring(0, 13)}...</p>
                                    </div>
                                    <div 
                                    data-date=${comment.date}
                                    data-email=${comment.email}
                                    class="delete-annotation-comment col-12  col-md-6" title="${new Date(comment.date).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric',
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit"})}">
                                        <p style="display: inline-block; text-align: right; font-weight: bold">${new Date(comment.date).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'})}</p>
                                        <img style="cursor: pointer; float: right" className="btn-delete-combination delete-annotation-comment"  
                                        src="../delete.png" alt="" />
                                    </div>
                                    <div class="col-md-12">
                                        <p class="comment-content" style="font-style: italic">${contentFinal}</p>
                                        <hr style="display: block"/>
                                    </div>
                                </div>
                                `;
                            }else{
                                renderComment = `
                                <div class="row annotation-comments-row">
                                    <div class="col-12 col-md-6" title="${comment.email}">
                                        <p style="font-weight: bold" >${comment.email.substring(0, 13)}...</p>
                                    </div>
                                    <div class="col-12 col-md-6" title="${new Date(comment.date).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric',
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit"})}">
                                        <p style="display: inline-block; text-align: right; font-weight: bold">${new Date(comment.date).toLocaleString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'})}</p>
                                    </div>
                                    <div class="col-md-12">
                                        <p class="comment-content" style="font-style: italic">${contentFinal}</p>
                                        <hr style="display: block"/>
                                    </div>
                                </div>
                                `;
                            }

                            
                            commentRenderers += renderComment;
                        }
                        
                        setTimeout(() => {
                            document.getElementById("annotation-comments-list").innerHTML = commentRenderers;
                        }, 500);
                        setTimeout(() => {
                            var annotationCommentsDeletes = document.querySelectorAll('.delete-annotation-comment');
                            var myFunction = function() {
                                if(window.confirm("Are you sure?")){
                                    let payload = {
                                        uuid: props.match.params.id.split('&email=')[0],
                                        email: this.getAttribute("data-email"),
                                        date: this.getAttribute("data-date")
                                    };
                                    axios.post('combination/delete-annotation-comment', payload).then(response => {
                                        alert('Success');
                                        renderAnnotationComments(response.data.data.annotationComments);
                                    }).catch(err => {
                                        console.log(err);
                                        alert("Can\'t submit comment with this email address");
                                    });
                                }
                            };
                            for (var i = 0; i < annotationCommentsDeletes.length; i++) {
                                annotationCommentsDeletes[i].addEventListener('click', myFunction, false);
                            }
                        }, 2000);
                        
                    }

                    function initial(path, user, comp)
                    {
                        console.log(user);
                        new FileLoader().load(path, async (response) => {
                            await api.importScene(JSON.parse(response)).then(() => {
                                setTimeout(() => {
                                    // api.stopAllSounds();
                                    commonFunction.waitAudioContext(api);
                                    // api.play();
                                    if(window.innerWidth < window.innerHeight){
                                        api.setCameraParams({orbitParams: {zoomSpeed: 1}});
                                    }
                                    
                                    setTimeout(() => {
                                        // commonFunction.hideLayersAfterLoad();
                                        let per = 90;
                                        var percentageInterval = setInterval(() => {
                                            per++;
                                            console.log('percentageInterval: ' + per);
                                            document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                                            document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                                            if(per >= 100){
                                                console.log(cameraParams);
                                                commonFunction.hideLayersAfterLoad();

                                                setState({
                                                    ...value,
                                                    canvasHeight: canvas.current.clientHeight,
                                                });
                                                originalCanvasHeight = canvas.current.clientHeight;
                                                clearInterval(percentageInterval);
                                            }
                                        }, 100);
                                    }, 0);
                                    //
                                    //props.enableAnnotations(true);

                                    let obj = JSON.parse(response);
                                    let p = api.getCameraParams();
                                    p.position = [obj.config.camera.position[0], obj.config.camera.position[1], obj.config.camera.position[2]];
                                    api.setCameraParams(p);
                                    let animationSet = api.getModelAnimation();
                                    if(animationSet.size > 0 && isIgnoreAnimation == "false"){
                                        //SHOW PLAY ANIMATION BUTTON
                                        document.getElementById("animationPlay").style.display = "block";
                                    }

                                    
                                    if(api.getAmbientAudio()  != undefined){
                                        document.getElementById("audioPlay").style.display = "block";
                                        // api.playAllSounds();
                                    }

                                    //Auto Rotate
                                    let cameraParams = api.getCameraParams();
                                    let orbitP = cameraParams.orbitParams;
                                    if(orbitP.autoRotate == true){
                                        document.getElementById("autoRotateIcon").style.display = "block";
                                    }

                                    //Annotation
                                    commonFunction.renderAnnotationIcon(api);

                                    let isInIFrame = window.self !== window.top;
                                    if(isInIFrame){
                                        xrParams = api.getXRParams();
                                        xrParams.enabled = false;
                                        
                                        if(navigator.platform == 'iPhone' || navigator.platform == 'iPad' || navigator.platform == 'Win32'){
                                            api.setXRParams(xrParams);
                                        }
                                    }else{
                                        var xrInterval = setInterval(() => {
                                            xrParams = api.getXRParams();
                                            
                                            if (navigator.platform == 'iPhone' || navigator.platform == 'iPad') { // <-- Use the one here above
                                                    
                                                if ('xr' in navigator)
                                                {
                                                    if(!user.isAllowAR)
                                                    {
                                                        xrParams.enabled = false;
                                                    }
                                                    api.setXRParams(xrParams);
                                                }
                                                else
                                                {
                                                    var mySpans = document.getElementsByTagName('a');
                                                    var arBut;
                                                    for(var i=0;i<mySpans.length;i++){

                                                        if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){

                                                        arBut = mySpans[i];
                                                        break;

                                                    }

                                                    }
                                                    if(arBut)
                                                    {
                                                        //Hide Button for iOS for now
                                                        arBut.style.display = "none";
                                                        if(user.isAllowAR)
                                                        {
                                                            if(!xrParams.enabled)
                                                            {
                                                                arBut.style.display = "none";
                                                            }
                                                        }
                                                        else{
                                                            arBut.style.display = "none";
                                                        }
                                                        arBut.innerText = 'START AR';
                                                        arBut.removeAttribute("href");
                                    
                                                        // Get the modal
                                                        var modal = document.getElementById("myModal");
                                    
                                                        // Get the button that opens the modal
                                                        var btn = arBut;
                                    
                                                        // Get the <span> element that closes the modal
                                                        var span = document.getElementsByClassName("closeAR")[0];
                                    
                                                        // When the user clicks on the button, open the modal
                                                        var p = document.getElementsByClassName("modal-bodyAR-p")[0];
                                                        //p.innerHTML = "Please download or open <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> to view " + "<a href='"+document.location+"'>"+document.location+"</a> and experience the AR ";
                                                        p.innerHTML = "To view in AR <br/> <a id='urlClicking' href='#'>"+currentCombinationName+"</a> (Copy link) <br/> Use or install <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> <br/> <i>* AR will be fully available on IOS very soon</i> ";
                                                        
                                                        document.getElementById('urlClicking').addEventListener('click', function(){
                                                            const textField = document.createElement('textarea');
                                                            textField.innerText = currentCombinationUrl;
                                                            document.body.appendChild(textField);
                                                            textField.focus();
                                                            textField.select();
                                                            textField.setSelectionRange(0, 99999); /* For mobile devices */
                                                            try {
                                                            // Now that we've selected the anchor text, execute the copy command
                                                                const successful = document.execCommand('copy');
                                                            } catch(err) {
                                                                alert(err);
                                                            }
                                                            document.body.removeChild(textField);
                                                            alert("Copied to the clipboard");
                                                        });
                                                        btn.onclick = function() {
                                                        modal.style.display = "block";
                                                        }
                                    
                                                        // When the user clicks on <span> (x), close the modal
                                                        span.onclick = function() {
                                                        modal.style.display = "none";
                                                        }
                                    
                                                        // When the user clicks anywhere outside of the modal, close it
                                                        window.onclick = function(event) {
                                                            if (event.target == modal) {
                                                                modal.style.display = "none";
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                            else
                                            {
                                                var element =  document.getElementById('ARButton');
                                                if(element != null)
                                                {
                                                    var txt = element.textContent || element.innerText;
                                                    if(txt == 'AR NOT SUPPORTED')
                                                    {
                                                        element.style.display = "none";
                                                    }
                                                }
                                                var mySpans = document.getElementsByTagName('a');
                                                var arBut;
                                                for(var i=0;i<mySpans.length;i++){

                                                    if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                                        arBut = mySpans[i];
                                                        break;
                                                    }
                                                }
                                                if(arBut){
                                                    arBut.style.display = "none";
                                                }
                                            }
                                            clearInterval(xrInterval);
                                        }, 1);
                                    }

                                    let materialsLibrary = api.getMaterialLibrary();
                                    let uuids = [];

                                    for(const layer of materialsLibrary)
                                    {
                                        if(layer.material.visible)
                                        {
                                            for(let mesh of layer.mesh){
                                                if(api.getAudio(mesh)){
                                                    document.getElementById("audioPlay").style.display = "block";
                                                    // api.playAllSounds();
                                                }
                                            }
                                            materialsLibraryLocal.push({
                                                'visible': true,
                                                'data': layer,
                                                'preset': null,
                                                'layer': layer.params.id
                                            });
                                            
                                        }
                                        uuids.push(layer.params.id);
                                    }

                                    //Get Layers Info
                                    axios.post('layer/getList', {
                                        uuids: uuids,
                                        userId: user.userId
                                    }).then(response => {
                                        setTimeout(() => {
                                            const layers = response.data.data.layers;
                                            let countLayer = 0;
                                            let layersList = document.getElementById("layersList");
                                            for(let layer of materialsLibraryLocal)
                                            {
                                                if(countLayer == 0){
                                                    document.getElementById("chosenLayer").value = layer.data.params.id;
                                                }
                                                let name = layer.data.params.name;
                                                for(let lay of layers){
                                                    if(lay.layerId == layer.data.params.id)
                                                    {
                                                        name = lay.displayName ?? layer.data.params.name;
                                                    }
                                                }
                                                let opt = new Option(name, layer.data.params.id); 
                                                
                                                layersList[countLayer] = opt;
                                                countLayer++;
                                            }
                                        }, 2000);
                                    });

                                    var count = 0;
                                    let getMaterialLibrary = setInterval(() => {
                                        count ++;
                                        if(count == 4)
                                        {
                                        //     for(const layer of materialsLibrary)
                                        //     {
                                        //         if(layer.material.visible)
                                        //         {
                                        //             for(let mesh of layer.mesh){
                                        //                 if(api.getAudio(mesh)){
                                        //                     document.getElementById("audioPlay").style.display = "block";
                                        //                     api.playAllSounds();
                                        //                 }
                                        //             }
                                        //             materialsLibraryLocal.push({
                                        //                 'visible': true,
                                        //                 'data': layer,
                                        //                 'preset': null,
                                        //                 'layer': layer.params.id
                                        //             });
                                        //         }
                                            // }
                                            clearInterval(getMaterialLibrary);
                                        }
                                    }, 1000);

                                    setTimeout(() => {
                                        let annotationList = document.getElementById("annotationList");
                                        let allAnnotation = api.getAllAnnotations();
                                        console.log(allAnnotation);
                                        for(let annotation of allAnnotation)
                                        {
                                            console.log(annotation);
                                            let opt = new Option(annotation.title, annotation.index); 
                                            
                                            if(annotation.index == 0)
                                            {
                                                opt.selected = true;
                                                document.getElementById("annotationTitle").value = annotation.title;
                                                document.getElementById("annotationText").value = annotation.text;

                                                let chk = document.getElementById('annotationVisible');
                                                chk.checked = annotation.hidden;
                                            }
                                            annotationList[annotation.index] = opt;
                                        }
                                    }, 2000);



                                    // Test Upload GLB To Leo
                                    commonFunction.uploadGLBToLeo(api, viewerUrl, 'annotation');


                                }, 1);
                            });
                            


                        },function ( xhr ) {
                            if(xhr.loaded >= xhr.total)
                            {
                                // document.getElementById("static-img").style.display = "none";
                                // document.getElementById("myLoader2").style.display = "none";
                                // document.getElementById("canvas").style.display = "block";
                                
                            }
                            let xhrTotal = 0;
                            if(comp.originalFileSize){
                                xhrTotal = comp.originalFileSize;
                            }else{
                                xhrTotal = xhr.total;
                            }
                            var per = xhr.loaded / xhrTotal * 100;
                            if(per >= 90){
                                per = 90;
                            }else{
                                console.log( (xhr.loaded / xhrTotal * 100) + '% loaded' );
                                document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                                document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                                //console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
                                var fadeTarget = document.getElementById("static-img");
                                var opa = (100 - xhr.loaded / xhrTotal * 100)/10;
                                // fadeTarget.style.filter  = 'blur(40px)';
                            }
                        },
                    
                        // onError callback
                        function ( err ) {
                            console.error( 'An error happened' );
                        } )
                    }
                
                    function _tweenLogo(x, finalState, duration = 0.5){
                        if(_logoTweener) _logoTweener.stop();
                        const fState = finalState;
                        _logoTweener = api.getTweener({l: _logoVisibility}).to({l: x}, duration*1000).onUpdate((o)=>{
                            if (Math.abs(_logoVisibility-o.l) < 0.0001) return;
                            _logoVisibility = o.l;
                            let p = api.getLogoParams();
                            p.opacity = _logoVisibility * _logoVisibility;
                            api.setLogoParams(p);
                        }).onComplete(()=>{
                            _logoVisibility = x;
                            _logoTweener = undefined;
                            _logoState = fState;
                        })
                            //.easing(TWEEN.Easing.Quadratic.InOut)
                            .start();
                        return _logoTweener;
                    }

                
                    function animationLoop(){

                        let logoParams = api.getLogoParams();
                        const time = Date.now();
                        const mmax = logoMinActionTime * 1000;
                
                        if(( mouseDownTime > 1 && time-mouseDownTime > mmax ) || ( time-mouseWheelTime < 500 ) ){
                            // show if hidden. stop if hiding and show. do nothing if showing or visible
                            if(_logoState === 'hidden' || _logoState === 'hiding'){
                                _logoVisibility = Math.max(0.002, _logoVisibility);
                                _tweenLogo(1, 'startVisible', (1-_logoVisibility) *logoParams.logoFadeInDuration);
                                _logoState = 'showing';
                            }
                        }else {
                            // hide if visible. stop if showing and hide. do nothing if hiding or hidden
                            if(_logoState === 'startVisible'){
                                // this._logoVisibility = this._logoVisibility;
                                _tweenLogo(_logoVisibility, 'startHiding',logoParams.logoStayDuration-0.002);
                                _logoState = 'visible';
                            }
                            if(_logoState === 'startHiding'){
                                _logoVisibility = Math.min(0.998, _logoVisibility);
                                _tweenLogo(0, 'hidden',(_logoVisibility) * logoParams.logoFadeOutDuration);
                                _logoState = 'hiding';
                            }
                        }
                    }

                    if(window.innerWidth < window.innerHeight){
                        document.getElementById('fakeLoadBackground').innerText = "Background";
                        document.getElementById('btnScreenshot').innerText = "Screenshot";
                    }
                    
                    document.getElementById('fakeLoadBackground').addEventListener('click', () => {
                        document.getElementById('loadBackground').click()
                    })

                    document.getElementById('loadBackground').addEventListener('change', function(e) {
                        const files = Array.from(e.target.files)
                        let file = Array.isArray(files) ? files[0] : files;
                        if (file) {
                            api.setBackgroundFile(file, {
                                setEnvironment: false
                            })
                        }
                    })

                    document.getElementById('hambuger-annotation').addEventListener('click', () => {
                        document.getElementById('panel-close-mobile').style.display = "block";
                        document.getElementById('annotation-panel-area').style.display = "block";
                        document.getElementById('annotation-panel-area').style.position = "absolute";
                        document.getElementById('annotation-panel-area').style.top = 0;
                        
                    })


                    function exitHandler()
                    {
                        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
                        if(fullscreenElement == null){
                            let a = originalCanvasHeight + "px";
                            document.getElementById("annotation-panel-area").style.height = a;
                            isFullScreen = false;
                        }
                    }
                    document.addEventListener('fullscreenchange', exitHandler, false);
                    document.addEventListener('mozfullscreenchange', exitHandler, false);
                    document.addEventListener('MSFullscreenChange', exitHandler, false);
                    document.addEventListener('webkitfullscreenchange', exitHandler, false);
                    document.getElementById('fullScreenIcon').addEventListener('click', function(e) {
                        if(!isFullScreen){
                            var elem = document.documentElement;
                            if (elem.requestFullscreen) {
                            elem.requestFullscreen();
                            } else if (elem.webkitRequestFullscreen) { /* Safari */
                            elem.webkitRequestFullscreen();
                            } else if (elem.msRequestFullscreen) { /* IE11 */
                            elem.msRequestFullscreen();
                            }
                            document.getElementById("annotation-panel-area").style.height = "2000px";
                            isFullScreen = true;
                        }else{
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.webkitExitFullscreen) { /* Safari */
                                document.webkitExitFullscreen();
                            } else if (document.msExitFullscreen) { /* IE11 */
                                document.msExitFullscreen();
                            }
                            isFullScreen = false;
                        }

                    });

                    document.getElementById('animationPlay').addEventListener('click', function(e) {
                        if(document.getElementById('animationPlay').childNodes[0].src == play){
                            api.playAllAnimations();
                            api.playAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_on;
                            document.getElementById('animationPlay').childNodes[0].src = stop;
                            document.getElementById('animationPlay').title = "Stop Animation";
                        }
                        else{
                            api.stopAllAnimations();
                            api.stopAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_off;
                            document.getElementById('animationPlay').childNodes[0].src = play;
                            document.getElementById('animationPlay').title = "Play Animation";
                        }
                    });

                    document.getElementById('audioPlay').addEventListener('click', function(e) {
                        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
                            try {
                                api.stopAllSounds();
                            } catch (error) {
                            }
                            document.getElementById('audioPlay').childNodes[0].src = sound_off;
                            document.getElementById('audioPlay').title = "UnMute";
                        }
                        else{
                            api.playAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_on;
                            document.getElementById('audioPlay').title = "Mute";
                        }
                    });

                    document.getElementById('autoRotateIcon').addEventListener('click', function(e) {
                        let cameraParams = api.getCameraParams();
                        let orbitP = cameraParams.orbitParams;
                        api.setCameraParams({orbitParams: {autoRotate: !orbitP.autoRotate}})
                        if(document.getElementById('autoRotateIcon').childNodes[0].src == auto_rotate){
                            document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate_stop;
                        }else{
                            document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate;
                        }
                    });

                    document.getElementById('annotationIcon').addEventListener('click', function(e) {
                        if(document.getElementById('annotationIcon').childNodes[0].src == annotation_on){
                            document.getElementById('annotationIcon').childNodes[0].src = annotation_off;
                            api.annotationsEnabled = false;
                        }else{
                            document.getElementById('annotationIcon').childNodes[0].src = annotation_on;
                            api.annotationsEnabled = true;
                        }
                    });

                    

                    //Setting mouse and touch events on canvas
                    document.getElementById('canvas').addEventListener('mousemove', (e) => onPointerMove(e, api, false, userInfo));
                    document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerMove(e, api, true, userInfo));
                    document.getElementById('tooltip-span').addEventListener('mouseup', (e) => onPointerUp2(e, api, props, false, userInfo));
                    document.getElementById('canvas').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, materialsLibraryLocal, userInfo));
                    document.getElementById('canvas').addEventListener('touchend', (e) => onPointerUp(e, api, props, true, materialsLibraryLocal, userInfo));
                    document.getElementById('canvas').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('canvas').addEventListener('touchstart', (e) => onPointerDown(e, api, props));
                    document.getElementById('canvas').addEventListener('wheel', (e) => onPointerWheel(e, api, props));
                    document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerWheel(e, api, true, userInfo));
                    window.addEventListener("resize", ev => {
                        //api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
                        
                        commonFunction.setDefaultRatio(api);
                    }, false);
                    document.getElementById('autoRotateIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('autoRotateIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('helperIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('helperIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('fullScreenIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('fullScreenIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('animationPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('animationPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('audioPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('audioPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('annotationIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('annotationIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));

                    document.getElementById('canvas').addEventListener('dblclick', (e) => onPointerDownDouble(e, api, props));


                    document.getElementById('combinationList').addEventListener('change', function(e) {
                        document.getElementById("myLoader2").style.display = "block";
                        document.getElementById("static-img").style.display = "block";
                        document.getElementById("canvas").style.display = "none";

                        initial(this.value);
                        // new FileLoader().load(this.value, (response) => {
                        //     api.importScene(JSON.parse(response))
                        //     document.getElementById("myLoader").style.display = "none";
                        //     document.getElementById("canvas").style.display = "block";
                        // })

                        combinations.map(combination => {
                            if(combination.path == this.value)
                            {
                                document.getElementById('combinationPrice').value = combination.price != undefined ? '$' + parseFloat(combination.price, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() : 0;
                                document.getElementById('combinationDesc').value = combination.description != undefined ? combination.description : '';
                                currentCombinationName = combination.name;
                                // commonFunction.currentCombinationName = currentCombinationName;
                                if(document.getElementById('urlClicking') != null){
                                    document.getElementById('urlClicking').innerHTML = currentCombinationName;
                                }
                            }
                        });
                    });
                    
                    document.getElementById('layersList').addEventListener('change', function(e) {
                        
                        document.getElementById("chosenLayer").value = this.value;
                        for(let layer of materialsLibraryLocal)
                        {
                            if(layer.layer == this.value)
                            {
                                let chk = document.getElementById('layerVisible');
                                chk.checked = !layer.visible;
                                console.log(layer.data.mesh);

                                api.highlightObjects.apply(api, layer.data.mesh)
                                let p = {edgeStrength: 10};
                                api.getTweener(p)
                                        .to({edgeStrength: 0.0}, 1*10000)
                                        .onUpdate(()=> api.setOutlineParams(p))
                                        .start();
                            }
                        }

                    });
                    
                    document.getElementById('annotationList').addEventListener('change', function(e) {
                        
                        let allAnnotation = api.getAllAnnotations();
                        for(let annotation of allAnnotation)
                        {
                            if(annotation.index == this.value)
                            {
                                document.getElementById("annotationTitle").value = annotation.title;
                                document.getElementById("annotationText").value = annotation.text;
                                
                                api.animateCamera(annotation.cameraState);
                                let a = new Vector2();
                                a.x = annotation.screenPosition.x;
                                a.y = annotation.screenPosition.y;
                                api.hitAnnotations(a, true);

                                let chk = document.getElementById('annotationVisible');
                                chk.checked = annotation.hidden;

                                commonFunction.renderAnnotationModalEvent(api, annotation);
                            }
                        }

                    });

                    document.getElementById('btnScreenshot').addEventListener('click', function(e) {
                        let img = api.getSnapshotData("image/jpg")
                        let link = document.createElement("a");
                        link.download = "screenshot.jpg";
                        link.href = img;
                        const body = document.getElementsByTagName("body")[0];
                        body.appendChild(link);
                        link.click();
                        body.removeChild(link);
                    });
                    
                    // document.getElementById('btnAR').addEventListener('click', function(e) {
                    //     let arSettings = {...props.iframe.arSettings}
                    //     arStatus = !arStatus;
                    //     arSettings.enabled = arStatus
                    //     api.setXRParams(arSettings)
                    // });

                    document.getElementById('btnResetCanvas').addEventListener('click', function(e) {
                        window.location.reload(true);
                    });
                    

                    // document.getElementById('btnRotate').addEventListener('click', function(e) {
                    //     autoRotate = !autoRotate;
                    //     api.setCameraParams({orbitParams: {autoRotate: autoRotate}})
                    // });


                    document.getElementById('btnScreenshot').addEventListener('mouseover', function(e) {
                        document.getElementById("btnScreenshot").src = cameraOn
                    });

                    document.getElementById('btnScreenshot').addEventListener('mouseout', function(e) {
                        document.getElementById("btnScreenshot").src = cameraOn
                    });

                    // document.getElementById('btnRotate').addEventListener('mouseover', function(e) {
                    //     document.getElementById("btnRotate").src = rotateOn
                    // });

                    // document.getElementById('btnRotate').addEventListener('mouseout', function(e) {
                    //     document.getElementById("btnRotate").src = rotateOn
                    // });

                    // document.getElementById('btnAR').addEventListener('mouseover', function(e) {
                    //     document.getElementById("btnAR").src = arOff
                    // });

                    // document.getElementById('btnAR').addEventListener('mouseout', function(e) {
                    //     document.getElementById("btnAR").src = arOn
                    // });

                    document.getElementById('layerVisible').addEventListener('click', function(e) {
                        let chosenLayer = document.getElementById("chosenLayer").value;
                        for(let layer of materialsLibraryLocal)
                        {
                            if(layer.data.params.id == chosenLayer)
                            {
                                layer.visible = !layer.visible;
                                layer.data.params.visible = layer.visible;
                                api.importMaterialParams(layer.data.params).then(() => {
                                    api.setMaterialParams(layer.data.mesh[0], layer.data.params)
                                })
                            }
                        }
                    });

                    document.getElementById('annotationVisible').addEventListener('click', function(e) {
                        let chosenAnnotation = document.getElementById("annotationList").value;
                        let allAnnotation = api.getAllAnnotations();
                        for(let annotation of allAnnotation)
                        {
                            annotation.hidden = !annotation.hidden;
                            // if(annotation.index == 0)
                            // {
                                api.animateCamera({});
                                let a = new Vector2();
                                a.x = annotation.screenPosition.x;
                                a.y = annotation.screenPosition.y;
                                api.hitAnnotations(a, true);
                            // }
                        }
                    });
                    
                    document.getElementById('btnSubmitAnnotationComment').addEventListener('click', function(e) {
                        let emailAllowed =  props.match.params.id.split('&email=')[1];
                        if(emailAllowed || isOnwer){
                            let content = document.getElementById("annotation-comment").value.replace(/\n/g, " ");
                            let payload = {
                                uuid: props.match.params.id.split('&email=')[0],
                                email: emailAllowed ?? isOwnerEmail,
                                content
                            };
                            axios.post('combination/submit-annotation-comment', payload).then(response => {
                                console.log(response.data.data);
                                renderAnnotationComments(response.data.data.annotationComments);
                                document.getElementById("annotation-comment").value = "";
                            }).catch(err => {
                                console.log(err);
                                alert("Can\'t submit comment with this email address");
                            });
                        }else{
                            alert("Not allowed");
                        }
                        
                    });


                    document.getElementById('panel-close-mobile-button').addEventListener('click', function(e) {
                        var x = document.getElementById("hambuger-annotation");
                        if (window.getComputedStyle(x).display === "block") {
                            document.getElementById('annotation-panel-area').style.display = "none";
                            document.getElementById('panel-close-mobile').style.display = "none";
                        }
                    })

                    document.getElementById('open-3d-button').addEventListener('click', function(e) {
                        document.getElementById('modal3DViewer').style.display = "block";
                        document.getElementById('annotation-main-content').style.opacity = "20%";
                    })

                    document.getElementById('close3DViewer').addEventListener('click', function(e) {
                        var modal3DViewer = document.getElementById("modal3DViewer");
                        modal3DViewer.style.display = "none";
                        document.getElementById('annotation-main-content').style.opacity = "100%";
                    });


            }, timeTimeout);
        }

    }

    useEffect(() => {
            const api = API
            console.log("Product");
            switchCases(props, API)
    })


    return (
        <>
            <HardwareAccelerationModal show={showHardwareModal}></HardwareAccelerationModal>
            <div>
                <div id="tooltip-span" style={{width: 100, height: 100}} className="tooltip" title="CadGL"></div>
                <Row id="annotation-main-content" className="main-content combination-main-content">
                    <Col md={{ span: 10}} className="canvas-area" style={{paddingRight: 0}}>
                        <div id="hambuger-annotation" style={{display: 'none', position: 'absolute', top: 0, right: 20}}>
                            <img src={hamburger} style={{width: 25}} />
                        </div>
                        <div style={{display: 'none'}} className="animate-bottom" id="myLoader">
                        </div>
                        <img id="static-img" />


                        {
                            useCustomLoading ?
                            <>
                                <div style={{display: 'none', width: 'auto', height: 'auto'}}  id="myLoader2" >
                                    <div id="myBar" className="c100 p0 big" style={{display: 'none'}}>
                                        <span id="myBar-text">0%</span>
                                        <div className="slice">
                                            <div className="bar"></div>
                                            <div className="fill"></div>
                                        </div>
                                    </div>
                                    <img style={{width: 100+'%'}} src={customLoading} />
                                </div>
                            </>
                            :
                            <>
                                <div style={{display: 'none'}}  id="myLoader2">
                                    <div id="myBar" className="c100 p0 big">
                                        <span id="myBar-text">0%</span>
                                        <div className="slice">
                                            <div className="bar"></div>
                                            <div className="fill"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }



                        <canvas id="canvas" width="500" height="500" ref={canvas} style={{marginTop: -3, marginLeft: 5}}/>
                        <img id="swipeIcon" src={'https://cadgl.net/editor/images/swipe_360.gif'} style={{position: 'absolute', bottom: 20}} />
                        
                        <div id="annotation-canvas-buttons" className="" style={{display: 'none', position: 'absolute', top: 10, right: 70, height: 20}}>
                            
                            <CombinationIcons></CombinationIcons>
                        </div>
                    </Col>
                    <Col  style={{overflowY: 'scroll', overflowX: 'hidden', height: value.canvasHeight}} md={{ span: 2}} id="annotation-panel-area" className="panel-area">
                        <Row className="annotation-title" style={{color: 'black', fontSize: 25, fontWeight: 'bold', marginTop: 25}}>
                            <Col md={{ span: 12}} className="panel-column">
                                {lang.design_approval}
                            </Col>
                        </Row>
                        <Row>
                            <div className="panel-logo">
                                <img
                                    style={{width: 100+'%'}}
                                    alt=""
                                    src={logo}
                                    className="d-inline-block align-top panel-logo-img"
                                />
                            </div>
                            {/* <div className="panel-combination-list">
                                VARIATIONS
                                <hr></hr>
                            </div> */}
                            <Col xs={{span: 6}} md={{span: 12}}>
                                <Row>
                                    <Col md={{span: 12}}>
                                        <div id="combination-info" style={{paddingTop: 10+'px', paddingBottom: 10+'px'}} className=" panel-combination-info">
                                            <div className="panel-combination-list-title form-group">
                                                {lang.job_description}
                                            </div>
                                            <hr></hr>
                                            <hr></hr>
                                            <Form.Group controlId="combinationList" style={{display: 'none'}}>
                                                <Form.Control  as="select" className="panel-combination-select">
                                                    
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="combinationTitle" className="panel-combination-info-desc">
                                                <Form.Control type="text" readOnly />
                                            </Form.Group>
                                            <Form.Group controlId="combinationDesc" className="panel-combination-info-desc">
                                                <Form.Control as="textarea" rows={3} readOnly value={state.description} />
                                            </Form.Group>
                                            <Form.Group style={{display: 'none'}} controlId="combinationPrice" className="panel-combination-info-price">
                                                <Form.Control type="text" readOnly value={state.price} />
                                            </Form.Group>
                                            
                                        </div>
                                    </Col>
                                    <Col md={{span: 12}}>
                                        <div id="combination-layers" style={{paddingTop: 10+'px', paddingBottom: 10+'px'}} className="panel-combination-info">
                                            <div className="panel-combination-list-title form-group">
                                                {lang.layers}
                                            </div>
                                            <hr></hr>
                                            <hr></hr>
                                            <Form.Group controlId="layersList" >
                                                <Form.Control  as="select" className="panel-combination-select">
                                                    
                                                </Form.Control>
                                            </Form.Group>
                                            <Row className="panel-combination-list-showhide">
                                                <Col xs={{span: 9}} md={{span: 9}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                    {lang.hide_layer}
                                                </Col>
                                                <Col xs={{span: 3}} md={{span: 3}} style={{textAlign: 'right'}}>
                                                    <input value="true" id="layerVisible" className="form-check-input position-static" type="checkbox" />
                                                </Col>
                                            </Row>
                                            
                                            
                                        </div>
                                    </Col>
                                    <Col md={{span: 12}}>
                                        <div  id="combination-annotations" style={{paddingTop: 10+'px', paddingBottom: 10+'px'}} className="panel-combination-info">
                                            <div className="panel-combination-list-title form-group">
                                                {lang.annotations_low}
                                            </div>
                                            <hr></hr>
                                            <Form.Group controlId="annotationList" style={{marginBottom: 0}}>
                                                <Form.Control  as="select" className="panel-combination-select">
                                                    
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group style={{marginTop: -10, marginBottom: 15, display: 'none'}} controlId="annotationTitle" className="panel-annotation-info-title">
                                                <Form.Control type="text" readOnly  />
                                            </Form.Group>
                                            <Form.Group style={{display: 'none'}} controlId="annotationText" className="panel-annotation-info-text">
                                                <Form.Control as="textarea" rows={3} readOnly  />
                                            </Form.Group>
                                            <Row className="panel-combination-list-showhide" style={{marginTop: -10}}>
                                                <Col xs={{span: 7}} md={{span: 9}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                    {lang.hide_annotation}
                                                </Col>
                                                <Col xs={{span: 5}} md={{span: 3}} style={{textAlign: 'right'}}>
                                                    <input value="true" id="annotationVisible" className="form-check-input position-static" type="checkbox" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={{span: 12}}>
                                        <div style={{display: 'none', paddingTop: 10+'px', paddingBottom: 10+'px', marginTop: 5}} id="annotation-tools" className="panel-combination-info">
                                            <div className="panel-combination-list-title form-group">
                                                {lang.tools}
                                            </div>
                                            <Row>
                                                <Col md={{span: 12}}>
                                                    <button style={{float: 'right'}} id="btnResetCanvas" className="form-control">{lang.reset}</button>
                                                </Col>
                                            </Row>
                                            <Row className="first-load-bg">
                                                <Col md={{span: 12}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                    {/* <a href="#" className="fakeLoadBackground" id="fakeLoadBackground">Add Background</a> */}
                                                    <input type="file"
                                                    style={{display: 'none'}}
                                                    id="loadBackground" name="avatar"
                                                    accept="image/png, image/jpeg"></input>
                                                </Col>
                                            </Row>
                                            <Row className="panel-button-group">
                                                
                                                {/* <Col md={{ span: 6}} className="panel-button">
                                                    <img className="iframe-circle-icon panel-button-screenshot"  id="btnScreenshot" src={cameraOn}  />
                                                </Col>
                                                <Col md={{ span: 6}} className="panel-button">
                                                    <img className="iframe-circle-icon panel-button-rotate" id="btnRotate" src={rotateOn}  />
                                                </Col> */}
                                            </Row>
                                            {/* <Row className="second-load-bg" style={{display: 'none'}}>
                                                <Col md={{span: 12}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                    <a href="#" className="fakeLoadBackground" id="fakeLoadBackground2">Add Background</a>
                                                    <input type="file"
                                                    style={{display: 'none'}}
                                                    id="loadBackground" name="avatar"
                                                    accept="image/png, image/jpeg"></input>
                                                </Col>
                                            </Row> */}
                                            <React.Fragment>
                                                <Button  id="fakeLoadBackground" className="fakeLoadBackground panel-combination-info-add-to-cart " style={{display: 'none', width: 100+'%', marginLeft: 10, marginRight: 10, marginTop: 30, marginBottom: 5}} res-type="Desk" variant="primary">{lang.change_background}</Button>{' '}
                                            </React.Fragment>
                                            <React.Fragment >
                                                <Button  id="btnScreenshot" className="fakeLoadBackground panel-combination-info-add-to-cart " style={{display: 'none', width: 100+'%', marginLeft: 10, marginRight: 10}} res-type="Desk" variant="primary">{lang.take_screenshot_label}</Button>{' '}
                                            </React.Fragment>
                                            <input type="hidden" id="chosenLayer" />
                                        </div>
                                    </Col>
                                    <Col md={{span: 12}}  id="annotation-comment-panel" style={{paddingLeft: 0, paddingRight: 0}}>
                                            <Col md={{span: 12}}>
                                                <Button style={{
                                                    fontSize: 11+'pt', float: 'left', cursor: 'pointer',fontWeight: 'bold',
                                                    color: 'white',background: '#575757',
                                                    marginTop: 10,
                                                    marginLeft: 0,
                                                    width: 100+'%',
                                                    cursor: 'not-allowed'
                                                    }} id="open-3d-button"  className="" variant="primary" disabled>
                                                    <span style={{marginRight: 10}} id="open-3d-button-spinner" className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Check Measurements</Button>
                                            </Col>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 6}}  md={{span: 12}}  id="annotation-comment-panel" style={{paddingLeft: 0, paddingRight: 0}}>
                                <div style={{paddingTop: 10+'px', paddingBottom: 10+'px', marginTop: 5, maxWidth: 100+'%'}} id="combination-comments" className="panel-combination-list panel-combination-info">
                                    <div className="panel-combination-list-title form-group">
                                        Comments
                                    </div>
                                    <Row>
                                        <Col md={{span: 12}} id="annotation-comments-list">
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span: 12}}>
                                        <Form.Control id="annotation-comment" as="textarea" rows={3}   />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span: 12}}>
                                            <button style={{backgroundColor: 'white', 
                                            textAlign: 'center',
                                            fontSize: 10 + 'pt',
                                            marginTop: 5,
                                            width: 45+'%',
                                            border: '1px solid #c9c9c9'}} id="btnSubmitAnnotationComment" className="form-control">Submit</button>
                                        </Col>
                                    </Row>
                                </div>
                                <UploadFileModal email={props.match.params.id.split('&email=')[1]} uuid={props.match.params.id.split('&')[0]}/>

                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{span: 12}} md={{span: 0}} style={{position: 'absolute', top: 40+'%'}}>
                        <div style={{padding: '5px', height: '35px', borderTop: '1px solid #c5c5c5', textAlign: 'right', marginTop: '-3px', background: '#f6f6f6 ', width: '100%', maxWidth: '100%'}} id="panel-close-mobile" className="">
                            <button id="panel-close-mobile-button" style={{width: '20%', float: 'right'}} type="button" className="panel-combination-info-add-to-cart btn btn-primary"><i id="x-close" className="fas fa-times"></i></button>
                        </div>
                    </Col>
                </Row>
                <div id="myModal" className="modalAR">
                    <div className="modal-contentAR">
                        <span className="closeAR">&times;</span>
                        <div className="modal-bodyAR">
                            <p className="modal-bodyAR-p" style={{color: 'black'}}></p>
                            
                        </div>
                    </div>
                </div>
                <div id="modalAnnotation" className="modalAnnotation">
                    <div className="modal-contentAnnotation">
                        <span className="closeAnnotation">&times;</span>
                        <div className="modal-bodyAnnotation" id="modal-body-annotation">
                            
                        </div>
                    </div>
                </div>
                <div id="modal3DViewer" className="modal3DViewer"  style={{width: 90 + '%', margin: 'auto', display: 'none'}}>
                    <div className="" style={{width: 100 + '%', height: 95 + '%', margin: 'auto'}}>
                        <span id="close3DViewer" style={{fontSize: 30, float: 'left', cursor: 'pointer'}}>&times;</span>
                        <div className="" id="modal-3d-viewer" style={{height: 95 + '%'}}>
                            <iframe width={100+'%'} height={100+'%'}/>
                        </div>
                    </div>
                </div>

                
            </div>
        </>
    )
}

const calculateMousePosition = (e, isMobile) =>
{
    
    let x, y;
    if(isMobile){
        if(e.changedTouches != undefined){
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        }
    }else{
        x = e.clientX;
        y = e.clientY;
    }
    mousePosition.x = (x / document.getElementById('canvas').clientWidth) * 2.05 - 1;
    mousePosition.y = -(y / document.getElementById('canvas').clientHeight) * 2 + 1;
}


const onPointerWheel = (e, api, props) => {
    mouseWheelTime = Date.now();
    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            // api.stopAllSounds();
            api.playAllSounds();
        }
    }
}

/**
 * Set mouse position
 * @param e
 */
const onPointerMove = (e, api, isMobile, userInfo) => {
    calculateMousePosition(e, isMobile);
    const hitLogo = api.hitLogo(mousePosition);
    if(hitLogo) 
    {
        document.body.style.cursor = "pointer";

        if(userInfo.isShowLinkToolTip)
        {
            var tooltipSpan = document.getElementById('tooltip-span');
            if(tooltipSpan)
            {
                tooltipSpan.style.display = "block";
                tooltipSpan.style.top = (mousePosition.y + 20) + 'px';
                tooltipSpan.style.left = (mousePosition.x + 20) + 'px';
            }
        }
    }
    else
    {
        document.body.style.cursor = "default";
        var tooltipSpan = document.getElementById('tooltip-span');
        if(tooltipSpan)
        {
            tooltipSpan.style.display = "none";
            tooltipSpan.style.top = 0 + 'px';
            tooltipSpan.style.left = 0 + 'px';
        }
    }

}

/**
 * On mouse up event, will highlight on UI objects that use the material of the clicked object
 * @param e
 * @param api
 * @param props
 */
const onPointerUp = (e, api, props, isMobile, materialsLibraryLocal) => {
    document.getElementById("annotationIcon").classList.remove("canvasActive");
    document.getElementById("autoRotateIcon").classList.remove("canvasActive");
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    document.getElementById("canvas").classList.remove("canvasActive");
    calculateMousePosition(e, isMobile);
    mouseWheelTime = Date.now();
    lastMouseDownTime = mouseDownTime;
    let delta = Date.now() - mouseDownTime;
    mouseDownTime = 0;
    if (delta > 200) return;

    const intersection = api.rayCast(mousePosition);
    let pickedObject = intersection?.object ?? null;
    let materialLibrary = api.getMaterialLibrary();
    let objs = materialLibrary.find(value => !!value.mesh.find(value1 => value1 === pickedObject))?.mesh ?? [pickedObject];
    let selectedObject = new Map()

    if (!e.ctrlKey && pickedObject) {
        api.highlightObjects();
        selectedObject.clear();

        let p = {edgeStrength: 10};
        api.getTweener(p)
                .to({edgeStrength: 0.0}, 1*10000)
                // .delay(4*1000)
                .onUpdate(()=> api.setOutlineParams(p))
                .start();

    }
    if(pickedObject != null)
    {
        let id = pickedObject.material.uid ? pickedObject.material.uid : pickedObject.material.uuid;
        document.getElementById("chosenLayer").value = id;
        document.getElementById("layersList").value = id;
        
        for(let layer of materialsLibraryLocal)
        {
            if(layer.layer == id)
            {
                let chk = document.getElementById('layerVisible');
                chk.checked = !layer.visible;
            }
        }
    }
    objs.forEach(value => {
        if (value) {
            selectedObject.set(value.uuid, value);
            api.highlightObjects.apply(api, Array.from(selectedObject.values()));
            console.log("Array.from(selectedObject.values())");
            console.log(Array.from(selectedObject.values()));
        } else {
            api.highlightObjects();
            selectedObject.clear();
        }
    });

    
    //ANNOTATIONS
    let annotation = api.hitAnnotations(mousePosition, true);
    if(annotation == draggingAnnotation) {
        draggingAnnotation = null;
        api.setCameraParams({mode: "orbit"});
    }
    if(annotation)
    {
        // //props.selectAnnotations(annotation);
        // //api.animateCamera(annotation.cameraState);
        // let annotationSelect = document.getElementById("annotationList");
        // annotationSelect.value = annotation.index;
        // annotationSelect.dispatchEvent(new Event('change'));

        // var modalAnnotation = document.getElementById("modalAnnotation");
        // modalAnnotation.style.display = "block";
        // document.getElementById("modal-body-annotation").innerHTML = annotation.text;

        // // Get the <span> element that closes the modal
        // var closeAnnotation = document.getElementsByClassName("closeAnnotation")[0];
        // // When the user clicks on <span> (x), close the modal
        // closeAnnotation.onclick = function() {
        //     modalAnnotation.style.display = "none";
        //     // api.resetCamera();
        // }

        // // When the user clicks anywhere outside of the modal, close it
        // window.onclick = function(event) {
        //     if (event.target == modalAnnotation) {
        //         modalAnnotation.style.display = "none";
        //         // api.resetCamera();
        //     }
        // }

        commonFunction.renderAnnotationModalEvent(api, annotation);
    }
    
    
    if(userInfo.isShowLinkToolTip)
    {
        const hitLogo = api.hitLogo(mousePosition);
        if(hitLogo) window.open('https://gallery.cadgl.com/subscription', '_blank');
    }

    setTimeout(() => {
        var modal3DViewer = document.getElementById("modal3DViewer");
        modal3DViewer.style.display = "none";
        document.getElementById('annotation-main-content').style.opacity = "100%";
    }, 1);

}


const onPointerUp2 = (e, api, props, isMobile) => {
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    document.getElementById("canvas").classList.remove("canvasActive");
    
    if(userInfo.isShowLinkToolTip)
    {
        window.open('https://gallery.cadgl.com/subscription', '_blank');
        console.log(e.target.id);
    }
    setTimeout(() => {
        var modal3DViewer = document.getElementById("modal3DViewer");
        alert(modal3DViewer.style.display);
    }, 2000);
}

const onPointerDown = (e, api, props) => {

    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            // api.stopAllSounds();
            api.playAllSounds();
        }
    }
    document.getElementById("annotationIcon").classList.add("canvasActive");
    document.getElementById("autoRotateIcon").classList.add("canvasActive");
    document.getElementById("helperIcon").classList.add("canvasActive");
    document.getElementById("audioPlay").classList.add("canvasActive");
    document.getElementById("animationPlay").classList.add("canvasActive");
    document.getElementById("fullScreenIcon").classList.add("canvasActive");
    document.getElementById("canvas").classList.add("canvasActive");
    document.getElementById("swipeIcon").style.display = "none";
    mouseDownTime = Date.now();
    draggingAnnotation = api.hitAnnotations(mousePosition, true);
    // if (draggingAnnotation){
    //     console.log("draggingAnnotation");
    //     api.setCameraParams({mode: "fixed"});
    // }

    
    // var x = document.getElementById("hambuger-annotation");
    // if (window.getComputedStyle(x).display === "block") {
    //     document.getElementById('annotation-panel-area').style.display = "none";
    //     document.getElementById('panel-close-mobile').style.display = "none";
    // }

    // let isMobile = window.innerWidth < window.innerHeight;
    // console.log(isMobile);
    // if(isMobile){
    //     if (window.getComputedStyle(x).display === "block") {
    //         console.log();
    //         document.getElementById('annotation-panel-area').style.display = "none";
    //     }
    // }

    const now = Date.now();
    if(now - lastMouseDownTime < 300){
        lastMouseDownTime = 0;
        //double click
        api.animateCameraTarget(mousePosition, 1.2, 6, 1.7);
        return
    }
    
    let annotation = api.hitAnnotations(mousePosition, true);
    if(!annotation){
        commonFunction.setNullIframeSrc();
    }
}

const onPointerDownDouble = (e, api, props) => {
    // api.animateCameraTarget(mousePosition, 1.2, 6, 1.1);
}

const getFileBlob = (url, cb) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
        cb(xhr.response);
    });
    xhr.send();
};

const blobToFile = (blob, name) => {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};

const getFileObject = (filePathOrUrl, cb) => {
    getFileBlob(filePathOrUrl, function (blob) {
        cb(blobToFile(blob, 'test.jpg'));
    });
};


const mapStateToProps = (state) => {
    return {
        iframe: state.iframe,
        combinations: state.combinations,
        enableAnnotations: enableAnnotations,
        saveSceneConfigToState: saveSceneConfigToState
    }
}

export default withRouter(connect(mapStateToProps, null)(ProductRenderer))