import React from "react";
import { Navbar } from "react-bootstrap";
import './Navbar.css';
import logo from '../../assets/logo-4-3-x.png';

function Nav() {
    return(
        <Navbar className="navbar">
            <Navbar.Brand href="#home">
                <img
                    alt=""
                    src={logo}
                    className="d-inline-block align-top"
                />
            </Navbar.Brand>
            <Navbar.Brand>
                CadGL - 3D Configurator
            </Navbar.Brand>
        </Navbar>
    )
}
export default Nav