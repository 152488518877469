import {
    CHANGE_ABSORPTION_DIAMOND_SETTING,
    CHANGE_AUTO_ROTATE_SPEED_SETTING,
    CHANGE_BEVEL_SETTING,
    CHANGE_BLOOM_RADIUS_SETTING,
    CHANGE_BLOOM_STRENGTH_SETTING,
    CHANGE_BLOOM_THRESHOLD_SETTING,
    CHANGE_BLUR_RADIUS_SETTING,
    CHANGE_BOOST_FACTOR_SETTING,
    CHANGE_CAMERA_MODE_SETTING,
    CHANGE_CAMERA_POSITION_X_SETTING,
    CHANGE_CAMERA_POSITION_Y_SETTING,
    CHANGE_CAMERA_POSITION_Z_SETTING,
    CHANGE_CAMERA_SETTING,
    CHANGE_CAMERA_TARGET_SETTING,
    CHANGE_CAMERA_ZOOM,
    CHANGE_CROSS_COLOR_SETTING,
    CHANGE_CROSS_RADIUS_SETTING,
    CHANGE_DISPERSION_DIAMOND_SETTING,
    CHANGE_DOWN_SAMPLE_RATIO_SETTING,
    CHANGE_EDGE_GLOW_SETTING,
    CHANGE_EDGE_STRENGTH_SETTING,
    CHANGE_EDGE_THICKNESS_SETTING,
    CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING,
    CHANGE_FALLOFF_SETTING,
    CHANGE_FAR_BLUR_SCALE_SETTING,
    CHANGE_DEPTH_RANGE_SETTING,
    CHANGE_FOV_SETTING,
    CHANGE_GAMMA_FACTOR_DIAMOND_SETTING,
    CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING,
    CHANGE_GROUND_REFLECTION_METALNESS_SETTING,
    CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING,
    CHANGE_GROUND_REFLECTION_SIZE_SETTING,
    CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING,
    CHANGE_GROUND_SHADOW_SIDE_SETTING,
    CHANGE_GROUND_SHARPNESS_SETTING,
    CHANGE_HIDDEN_EDGE_COLOR_SETTING,
    CHANGE_MATERIAL_COLOR,
    CHANGE_NEAR_BLUR_SCALE_SETTING,
    CHANGE_NUM_SAMPLES_PER_FRAME_SETTING,
    CHANGE_NUM_SAMPLES_SETTING,
    CHANGE_PULSE_PERIOD_SETTING,
    CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING,
    CHANGE_SCENE_VIEW,
    CHANGE_SHARPNESS_SETTING,
    CHANGE_SMOOTH_TRANSITION_SETTING,
    CHANGE_SQUASH_FACTOR_DIAMOND_SETTING,
    CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING,
    CHANGE_SSAO_INTENSITY_SETTING,
    CHANGE_SSAO_RADIUS_SETTING,
    CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_BUMP_SCALE,
    CHANGE_STANDARD_MATERIAL_COLOR,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR,
    CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY,
    CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME,
    CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY,
    CHANGE_STANDARD_MATERIAL_MAP_FILE,
    CHANGE_STANDARD_MATERIAL_METALNESS,
    CHANGE_STANDARD_MATERIAL_OPACITY,
    CHANGE_STANDARD_MATERIAL_ROUGHNESS,
    CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH,
    CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING,
    CHANGE_VISIBLE_EDGE_COLOR_SETTING,
    CLEAR_ALL,
    DOWNLOAD_GLTF,
    DOWNLOAD_REFLECTION,
    ENABLE_AUTO_ROTATE,
    ENABLE_BLOOM_SETTING,
    ENABLE_DOF,
    ENABLE_EDIT_DOF,
    ENABLE_GROUND_REFLECTION_PHYSICAL_SETTING,
    ENABLE_GROUND_REFLECTION_SETTING,
    ENABLE_GROUND_SHADOW_SETTING,
    ENABLE_SHARPNESS_SETTING,
    ENABLE_SMOOTH_SETTING,
    ENABLE_SSAO_SETTING,
    ENABLE_SSAO_DEBUG_SETTING,
    LOAD_FILE_ACTION,
    LOAD_REFLECTION,
    MAKE_DIAMOND,
    MAKE_STANDARD,
    MAKE_REFRACTION,
    CHANGE_PICKED_GROUP_VISIBILITY,
    REMOVE_BACKGROUND,
    RESET_STRAIGHTEN_MODEL,
    SAVE_SCENE_CONFIG_TO_STATE,
    SELECT_OBJECT,
    SET_BACKGROUND_COLOR,
    SNAP_CANVAS,
    TAKE_SCREENSHOT,
    UPLOAD_SCENE,
    VIEW_REFLECTION,
    CHANGE_REFRACTION_MATERIAL_VISIBILITY,
    CHANGE_REFRACTION_MATERIAL_TINT_COLOR,
    CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX,
    CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_ROUGHNESS,
    CHANGE_REFRACTION_MATERIAL_METALNESS,
    CHANGE_REFRACTION_MATERIAL_TRANSPARENCY,
    ENABLE_AR,
    CHANGE_OBJECT_SCALE_SETTING,
    CHANGE_RETICLE_SIZE_IN_SETTING,
    CHANGE_RETICLE_SIZE_OUT_SETTING,
    CHANGE_RETICLE_FADE_THRESH_SETTING,
    CHANGE_RETICLE_FADE_TIME_SETTING,
    CHANGE_GROUND_SHADOWS_SETTING,
    CHANGE_RETICLE_COLOR_SETTING,
    CHANGE_TONE_MAPPING_EXPOSURE_SETTING,
    CHANGE_TONE_MAPPING_SATURATION_SETTING,
    CHANGE_TONE_MAPPING_CONTRAST_SETTING,
    CHANGE_ENV_MAP_ROTATION_SETTING,
    UPLOAD_MATERIAL,
    SELECT_MATERIAL,
    CHANGE_MATERIAL_NAME,
    APPLY_PRESET,
    UPLOAD_MATERIALS,
    CHANGE_BACKGROUND_IMAGE_FILE,
    ENABLE_SCREEN_SPACE_PANNING,
    ENABLE_PAN,
    ENABLE_ZOOM,
    UPLOAD_FILES,
    CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS,
    CHANGE_DAMPING_FACTOR,
    CHANGE_ZOOM_SPEED,
    CHANGE_MIN_DISTANCE,
    CHANGE_MAX_DISTANCE,
    CHANGE_MAX_ZOOM,
    CHANGE_MIN_ZOOM,
    CHANGE_MAX_POLAR_ANGLE,
    CHANGE_MIN_POLAR_ANGLE,
    CHANGE_MAX_AZIMUTH_ANGLE,
    CHANGE_MIN_AZIMUTH_ANGLE,
    CHANGE_PIXEL_RATIO_SETTING,
    LOAD_PRESET_LAYER,
    LOAD_PRESET_FOLDER_LAYER,
    ENABLE_ANNOTATIONS,
    SELECT_ANNOTATIONS,
    ENABLE_TAA,
    ENABLE_SSR,
    INTENSITY_SSR,

    ENABLE_FRUSTUM_CULLING,

    CHANGE_NORMAL_SCALE_X,
    CHANGE_NORMAL_SCALE_Y,
    CHANGE_NORMAL_MAP_TYPE
} from "../actions/actionTypes";

const initialState = {
    arSettings: {
        enabled: true,
        objectScale: 0.1,
        groundShadows: true,
        reticleColor: '#ffffff',
        reticleSizeIn: 0.15,
        reticleSizeOut: 0.2,
        reticleFadeThresh: 0.25,
        reticleFadeTime: 1.5
    },
    materialsList: [],
    materialsLibrary: [],
    pixelRatio: 1
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_FILE_ACTION:
            return {
                ...state,
                api: action.api
            }
        case SAVE_SCENE_CONFIG_TO_STATE:
            return {
                ...state,
                api: action.api,
                config: action.sceneConfig.config,
                geometry: action.sceneConfig.geometry,
                materials: action.sceneConfig.materials,
                materialsList: action.materialsList,
                materialsLibrary: action.materialsLibrary,
                type: ''
            }
        case CHANGE_MIN_AZIMUTH_ANGLE:
            return {
                ...state,
                type: 'minAzimuthAngle'
            }
        case CHANGE_MAX_AZIMUTH_ANGLE:
            return {
                ...state,
                type: 'maxAzimuthAngle'
            }
        case CHANGE_MIN_POLAR_ANGLE:
            return {
                ...state,
                type: 'minPolarAngle'
            }
        case CHANGE_MAX_POLAR_ANGLE:
            return {
                ...state,
                type: 'maxPolarAngle'
            }
        case CHANGE_MIN_ZOOM:
            return {
                ...state,
                type: 'minZoom'
            }
        case CHANGE_MAX_ZOOM:
            return {
                ...state,
                type: 'maxZoom'
            }
        case CHANGE_MAX_DISTANCE:
            return {
                ...state,
                type: 'maxDistance'
            }
        case CHANGE_MIN_DISTANCE:
            return {
                ...state,
                type: 'minDistance'
            }
        case CHANGE_DAMPING_FACTOR:
            return {
                ...state,
                type: 'dampingFactor'
            }
        case CHANGE_ZOOM_SPEED:
            return {
                ...state,
                type: 'zoomSpeed'
            }
        case CHANGE_CAMERA_ZOOM:
            return {
                ...state,
                type: 'camera'
            }
        case CHANGE_FOV_SETTING:
            return {
                ...state,
                type: 'fov'
            }
        case CHANGE_SCENE_VIEW:
            return {
                ...state,
                type: 'sceneView',
                value: action.value
            }
        case CHANGE_EDGE_GLOW_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'edgeGlow',
                value: action.value
            }
        case CHANGE_EDGE_THICKNESS_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'edgeThickness',
                value: action.value
            }
        case CHANGE_EDGE_STRENGTH_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'edgeStrength',
                value: action.value
            }
        case CHANGE_PULSE_PERIOD_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'pulsePeriod',
                value: action.value
            }
        case CHANGE_VISIBLE_EDGE_COLOR_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'visibleEdgeColor',
                value: action.value
            }
        case CHANGE_HIDDEN_EDGE_COLOR_SETTING:
            return {
                ...state,
                config: action.sceneConfig.config,
                type: 'hiddenEdgeColor',
                value: action.value
            }
        case DOWNLOAD_GLTF:
            return {
                ...state,
                type: 'downloadGltf'
            }
        case SNAP_CANVAS:
            return {
                ...state,
                type: 'snapCanvas'
            }
        case CHANGE_DOWN_SAMPLE_RATIO_SETTING:
            return {
                ...state,
                type: 'downSampleRatio'
            }
        case CHANGE_CAMERA_MODE_SETTING:
            return {
                ...state,
                type: 'cameraMode'
            }
        case CHANGE_CAMERA_POSITION_X_SETTING:
        case CHANGE_CAMERA_POSITION_Y_SETTING:
        case CHANGE_CAMERA_POSITION_Z_SETTING:
            return {
                ...state,
                type: 'cameraPosition',
                config: action.sceneConfig.config,
            }
        case CHANGE_CAMERA_TARGET_SETTING:
            return {
                ...state,
                type: 'resetCameraTarget'
            }
        case CHANGE_CAMERA_SETTING:
            console.log("Reducer");
            console.log(action.sceneConfig.config);
            return {
                ...state,
                type: 'resetCamera',
                config: action.sceneConfig.config,
            }
        case CHANGE_BEVEL_SETTING:
            return {
                ...state,
                type: 'changeBevelSetting'
            }
        case CHANGE_SHARPNESS_SETTING:
            return {
                ...state,
                type: 'sharpness',
                sharpnessStatus: action.value,
            }
        case ENABLE_SHARPNESS_SETTING:
            return {
                ...state,
                sharpnessStatus: action.value,
                type: 'enableSharpness'
            }
        case ENABLE_SSAO_DEBUG_SETTING:
            return {
                ...state,
                type: 'enableSsaoDebug'
            }
        case ENABLE_SSAO_SETTING:
            return {
                ...state,
                type: 'enableSsao'
            }
        case CHANGE_SSAO_INTENSITY_SETTING:
            return {
                ...state,
                type: 'ssaoIntensity'
            }
        case CHANGE_SSAO_RADIUS_SETTING:
            return {
                ...state,
                type: 'ssaoRadius'
            }
        case CHANGE_SSAO_DOWNSCALE_LEVEL_SETTING:
            return {
                ...state,
                type: 'ssaoDownscaleLevel'
            }
        case ENABLE_BLOOM_SETTING:
            return {
                ...state,
                type: 'enableBloom'
            }
        case CHANGE_BLOOM_STRENGTH_SETTING:
            return {
                ...state,
                type: 'bloomStrength'
            }
        case CHANGE_BLOOM_RADIUS_SETTING:
            return {
                ...state,
                type: 'bloomRadius'
            }
        case CHANGE_BLOOM_THRESHOLD_SETTING:
            return {
                ...state,
                type: 'bloomThreshold'
            }
        case CHANGE_STRAIGHTEN_MODEL_ROTATE_SETTING:
            return {
                ...state,
                type: 'changeStraightenModelRotateSetting',
                straightenRotationAxis: action.value
            }
        case RESET_STRAIGHTEN_MODEL:
            return {
                ...state,
                type: 'resetStraightenModel'
            }
        case TAKE_SCREENSHOT:
            return {
                ...state,
                type: 'takeScreenshot'
            }
        case ENABLE_GROUND_SHADOW_SETTING:
            return {
                ...state,
                type: 'enableGroundShadow'
            }
        case CHANGE_NUM_SAMPLES_SETTING:
            return {
                ...state,
                type: 'numSamplesSetting'
            }
        case CHANGE_NUM_SAMPLES_PER_FRAME_SETTING:
            return {
                ...state,
                type: 'numSamplesPerFrameSetting'
            }
        case CHANGE_GROUND_SHADOW_BRIGHTNESS_SETTING:
            return {
                ...state,
                type: 'groundShadowBrightnessSetting'
            }
        case CHANGE_BLUR_RADIUS_SETTING:
            return {
                ...state,
                type: 'blurRadiusSetting'
            }
        case CHANGE_GROUND_SHARPNESS_SETTING:
            return {
                ...state,
                type: 'groundShadowSharpnessSetting'
            }
        case CHANGE_FALLOFF_SETTING:
            return {
                ...state,
                type: 'falloffSetting'
            }
        case ENABLE_SMOOTH_SETTING:
            return {
                ...state,
                type: 'enableSmooth'
            }
        case CHANGE_SMOOTH_TRANSITION_SETTING:
            return {
                ...state,
                type: 'changeSmoothTransitionSetting'
            }
        case CHANGE_GROUND_SHADOW_SIDE_SETTING:
            return {
                ...state,
                type: 'changeGroundShadowSideSetting'
            }
        case ENABLE_GROUND_REFLECTION_SETTING:
            return {
                ...state,
                type: 'enableGroundReflectionSetting'
            }
        case ENABLE_GROUND_REFLECTION_PHYSICAL_SETTING:
            return {
                ...state,
                type: 'enableGroundReflectionPhysicalSetting'
            }
        case CHANGE_GROUND_REFLECTION_ROUGHNESS_SETTING:
            return {
                ...state,
                type: 'changeGroundReflectionRoughnessSetting'
            }
        case CHANGE_GROUND_REFLECTION_METALNESS_SETTING:
            return {
                ...state,
                type: 'changeGroundReflectionMetalnessSetting'
            }
        case CHANGE_GROUND_REFLECTION_SIZE_SETTING:
            return {
                ...state,
                type: 'changeGroundReflectionSizeSetting'
            }
        case VIEW_REFLECTION:
            return {
                ...state,
                type: 'viewReflection'
            }
        case DOWNLOAD_REFLECTION:
            return {
                ...state,
                type: 'downloadReflection'
            }
        case LOAD_REFLECTION:
            return {
                ...state,
                type: 'loadReflection'
            }
        case CLEAR_ALL:
            return {
                ...state,
                type: 'clearAll'
            }
        case SET_BACKGROUND_COLOR:
            return {
                ...state,
                type: 'setBackgroundColor',
                value: action.value
            }
        case REMOVE_BACKGROUND:
            return {
                ...state,
                type: 'removeBackground'
            }
        case ENABLE_DOF:
            return {
                ...state,
                type: 'enableDof'
            }
        case ENABLE_EDIT_DOF:
            return {
                ...state,
                type: 'enableEditDof'
            }
        case CHANGE_CROSS_COLOR_SETTING:
            return {
                ...state,
                type: 'changeCrossColorSetting'
            }
        case CHANGE_CROSS_RADIUS_SETTING:
            return {
                ...state,
                type: 'changeCrossRadiusSetting'
            }
        case CHANGE_NEAR_BLUR_SCALE_SETTING:
            return {
                ...state,
                type: 'changeNearBlurScaleSetting'
            }
        case CHANGE_FAR_BLUR_SCALE_SETTING:
            return {
                ...state,
                type: 'changeFarBlurScaleSetting'
            }
        case CHANGE_DEPTH_RANGE_SETTING:
            return {
                ...state,
                type: 'changeDepthRangeSetting'
            }
        case ENABLE_AUTO_ROTATE:
            return {
                ...state,
                type: 'enableAutoRotate'
            }
        case ENABLE_SCREEN_SPACE_PANNING:
            return {
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'enableScreenSpacePanning'
            }
        case ENABLE_PAN:
            return {
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'enablePan'
            }
        case ENABLE_ZOOM:
            return {
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'enableZoom'
            }
        case CHANGE_AUTO_ROTATE_SPEED_SETTING:
            return {
                ...state,
                type: 'changeAutoRotateSpeedSetting'
            }
        case CHANGE_MATERIAL_COLOR:
        case CHANGE_BOOST_FACTOR_SETTING:
        case CHANGE_ENV_MAP_INTENSITY_DIAMOND_SETTING:
        case CHANGE_REFRACTIVE_INDEX_DIAMOND_SETTING:
        case CHANGE_GEOMETRY_FACTOR_DIAMOND_SETTING:
        case CHANGE_SQUASH_FACTOR_DIAMOND_SETTING:
        case CHANGE_DISPERSION_DIAMOND_SETTING:
        case CHANGE_ABSORPTION_DIAMOND_SETTING:
        case CHANGE_GAMMA_FACTOR_DIAMOND_SETTING:
        case CHANGE_STANDARD_MATERIAL_COLOR:
        case CHANGE_STANDARD_MATERIAL_OPACITY:
        case CHANGE_STANDARD_MATERIAL_ROUGHNESS:
        case CHANGE_STANDARD_MATERIAL_METALNESS:
        case CHANGE_STANDARD_MATERIAL_ENV_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_LIGHT_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_ALPHA_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_AO_MAP_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_EMISSIVE_INTENSITY:
        case CHANGE_STANDARD_MATERIAL_BUMP_SCALE:

        case CHANGE_NORMAL_SCALE_X:
        case CHANGE_NORMAL_SCALE_Y:
        case CHANGE_NORMAL_MAP_TYPE:

        case CHANGE_STANDARD_MATERIAL_WIREFRAME_LINE_WIDTH:
        case CHANGE_STANDARD_MATERIAL_ENABLE_WIREFRAME:
        case CHANGE_STANDARD_MATERIAL_EMISSIVE_COLOR:
        case CHANGE_STANDARD_MATERIAL_MAP_FILE:
        case CHANGE_REFRACTION_MATERIAL_VISIBILITY:
        case CHANGE_PICKED_GROUP_VISIBILITY:
        case CHANGE_REFRACTION_MATERIAL_TINT_COLOR:
        case CHANGE_REFRACTION_MATERIAL_DIFFUSE_COLOR:
        case CHANGE_REFRACTION_MATERIAL_REFRACTION_INDEX:
        case CHANGE_REFRACTION_MATERIAL_REFRACTION_ROUGHNESS:
        case CHANGE_REFRACTION_MATERIAL_ROUGHNESS:
        case CHANGE_REFRACTION_MATERIAL_METALNESS:
        case CHANGE_REFRACTION_MATERIAL_TRANSPARENCY:
        case CHANGE_STANDARD_MATERIAL_BEVEL_RADIUS:
            return {
                ...state,
                materials: action.materials,
                type: 'changeMaterialSettings'
            }
        case SELECT_OBJECT:
            return {
                ...state,
                materialsList: action.materialsList,
                type: 'selectObject'
            }
        case MAKE_STANDARD:
        case MAKE_DIAMOND:
        case MAKE_REFRACTION:
        case UPLOAD_MATERIAL:
            return {
                ...state,
                materials: action.materials,
                materialsList: action.materialsList,
                materialsLibrary: action.materialsLibrary
            }
        case UPLOAD_SCENE:
            //When export to json the materials normalScale prop is as object, but when importing it must be array or api will through error on mouse up event
            let materials = action.sceneConfig.materials
            let newMaterials = {}
            for (const [key, value] of Object.entries(materials)) {
                newMaterials = {
                    ...newMaterials,
                    [key]: fixNormalScale(value)
                }
            }
            return {
                ...state,
                api: action.api,
                config: action.sceneConfig.config,
                geometry: action.sceneConfig.geometry,
                materials: newMaterials,
            }
        case ENABLE_AR:
        case CHANGE_OBJECT_SCALE_SETTING:
        case CHANGE_RETICLE_SIZE_IN_SETTING:
        case CHANGE_RETICLE_SIZE_OUT_SETTING:
        case CHANGE_RETICLE_FADE_THRESH_SETTING:
        case CHANGE_RETICLE_FADE_TIME_SETTING:
        case CHANGE_GROUND_SHADOWS_SETTING:
        case CHANGE_RETICLE_COLOR_SETTING:
            return {
                ...state,
                arSettings: action.arSettings,
                type: 'changeArSettings'
            }
        case CHANGE_TONE_MAPPING_EXPOSURE_SETTING:
            return {
                ...state,
                config: action.config,
                type: 'toneMappingExposure'
            }
        case CHANGE_TONE_MAPPING_SATURATION_SETTING :
            return {
                ...state,
                config: action.config,
                type: 'toneMappingSaturation'
            }
        case CHANGE_TONE_MAPPING_CONTRAST_SETTING :
            return {
                ...state,
                config: action.config,
                type: 'toneMappingContrast'
            }
        case CHANGE_ENV_MAP_ROTATION_SETTING:
            return {
                ...state,
                config: action.config,
                type: 'envMapRotation'
            }
        case SELECT_MATERIAL:
            return {
                ...state,
                materials: action.materials,
                objects: action.objects,
                type: 'selectMaterial'
            }
        case  APPLY_PRESET:
            return {
                ...state,
                materials: action.materials,
                materialsList: action.materialsList,
                objects: action.objects,
                type: 'selectMaterial'
            }
        case LOAD_PRESET_LAYER:
            return {
                ...state,
                preset_layer: action.preset_layer
            }
        case LOAD_PRESET_FOLDER_LAYER:
            return {
                ...state,
                preset_folder_layer: action.preset_folder_layer
            }
        case CHANGE_MATERIAL_NAME:
            return {
                ...state,
                materialsList: action.materialsList
            }
        case UPLOAD_MATERIALS:
            return {
                ...state,
                materialsList: action.materialsList,
                materialsLibrary: action.materialsLibrary,
                materials: action.materials
            }
        case CHANGE_BACKGROUND_IMAGE_FILE:
            return {
                ...state,
                sceneConfig: action.sceneConfig
            }
        case UPLOAD_FILES:
            return {
                ...state,
                type: 'uploadFiles'
            }
        case CHANGE_PIXEL_RATIO_SETTING:
            return {
                ...state,
                pixelRatio: action.pixelRatio,
                type: 'changePixelRatioSetting'
            }
        case ENABLE_ANNOTATIONS:
            return{
                ...state,
                enableAnnotations: action.enableAnnotations,
                type: 'enableAnnotations'
            }
        case SELECT_ANNOTATIONS:
            return {
                ...state,
                annotation: action.annotation,
                type: 'selectAnnotations'
            }
        case ENABLE_TAA:
            return{
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'enableTAA'
            }
        case ENABLE_SSR:
            return{
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'enableSSR'
            }
        case INTENSITY_SSR:
            return{
                ...state,
                sceneConfig: action.sceneConfig,
                type: 'intensitySSR'
            }
        case ENABLE_FRUSTUM_CULLING:
            return {
                ...state,
                type: 'enableFrustumCulling'
            }
        default:
            return state;
    }
}

/**
 * When export to json the materials normalScale prop is as object, but when importing it must be array or api will through error on mouse up event
 * This is only when material is not diamond
 * @param materials
 * @returns {*}
 */
const fixNormalScale = (materials) => {
    if (!Array.isArray(materials.material.normalScale) && materials.material.normalScale) {
        let wrongNormalScale = materials.material.normalScale
        materials.material.normalScale = [wrongNormalScale.x, wrongNormalScale.y]
        return materials
    } else {
        return materials
    }
}

export default reducer