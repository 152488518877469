import React, {Component} from "react";
import { connect } from 'react-redux';
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form} from "react-bootstrap";
import Combination from "./Combination";
import CombinationsSizeFileDownload from "./CombinationsSizeFileDownload";
import CombinationsSizeName from "./CombinationsSizeName";
import CombinationsSizePublic from "./CombinationsSizePublic";
import CombinationsSizeAllowComment from "./CombinationsSizeAllowComment";
import './Combinations.css';
import './Controls.css';
import {Link} from "react-router-dom";
import axios from '../../../axios';
import galleryUrl from '../../../galleryUrl';
import deleteBtn from '../../../assets/delete@2x.png';
import {FileLoader, Vector2, TextureLoader, DefaultLoadingManager, TriangleFanDrawMode} from "three";
import {
    refreshCombinationsSizeAfterDelete,
    getCombinationsSize,
    getImagesSize,
    getTexturesSize,
    deletePreset,
    getStorageSummary
} from "../../../actions/actionsCreators";
import jwt_decode from "jwt-decode";

class CombinationsCreations extends Component {
    state = {
        userInfo: '',
        deleting: false,
        defaultActiveKey: 'combinations',
        limit: 10,
        combinations: [],
        combinationIds: [],
        search: ''
    }

    /**
     * Will set to false all embedded and widgets checkboxes so that we can track which one is checked
     */
    componentDidMount() {
        
    }

    openGallery = () => {
        window.open(galleryUrl+"/gallery/"+this.props.username, "_blank");
    }

    updateSearchField = (e) => {
        this.setState({search: e.target.value})
    }

    searchCombination = (e) => {
        this.setState({defaultActiveKey: 'combinations', limit: Number(this.state.limit), combinationIds: []})
        this.props.getCombinationsSize(this.props.combinations.combinationsList.length, this.state.limit, this.state.search)
    }

    deleteSelected = () => {
        this.setState({deleting: true})
        axios.post('combination/delete-multiple', {ids: this.state.combinationIds}).then(response => {
            this.props.refreshCombinationsSizeAfterDelete();
            setTimeout(() => {
                this.props.getCombinationsSize(0, 10);
                this.setState({deleting: false, combinationIds: []})
            }, 1000);

        }).catch(err => {
        })
    }

    deleteCombination = (uuid) => {
        this.setState({deleting: true})
        console.log(uuid);
        axios.post('combination/delete', {id: uuid}).then(response => {
            this.props.refreshCombinationsSizeAfterDelete();
            setTimeout(() => {
                this.props.getCombinationsSize(0, 10);
                this.setState({deleting: false, combinationIds: []})
            }, 1000);

        }).catch(err => {
        })
    }

    deleteImage = (path, type, combinationUuid) => {
        this.setState({deleting: true})
        axios.post('combination/delete-img', {fileName: path, type: type, id: combinationUuid}).then(response => {
            
            this.setState({defaultActiveKey: 'images'})
            this.props.getImagesSize()
            this.setState({deleting: false})

        }).catch(err => {
        })
    }

    deleteTexture = (presetUuid, presetFolderUuid) => {
        this.setState({deleting: true})
        axios.post('preset/delete',
        {
            "presetFolderId": presetFolderUuid,
            "presetId": presetUuid
        }).then(response => {
            
            this.setState({defaultActiveKey: 'textures'})
            this.props.getTexturesSize()
            this.setState({deleting: false})

            this.props.deletePreset(presetUuid, presetFolderUuid)
        }).catch(err => {
        })
    }

    downloadCombination  = (path) => {
        this.setState({deleting: true})
        new FileLoader().load(path, (response) => {
            let link = document.createElement("a");
            let blob = new Blob([(response)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "scene.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            setTimeout(() => {
                this.setState({deleting: false})
            }, 1000);
        })
    }

    downloadTexture  = (path) => {
        this.setState({deleting: true})
        new FileLoader().load(path, (response) => {
            let link = document.createElement("a");
            let blob = new Blob([(response)], {type: 'application/json'});
            link.href = URL.createObjectURL(blob);
            link.download = "texture.json";
            const body = document.getElementsByTagName("body")[0];
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            setTimeout(() => {
                this.setState({deleting: false})
            }, 1000);
        })
    }

    loadCombinationSize = () => {
        this.setState({defaultActiveKey: 'combinations', limit: Number(this.state.limit), combinationIds: []})
        this.props.getCombinationsSize(this.props.combinations.combinationsList.length, this.state.limit)
    }

    loadImageSize = () => {
        this.setState({defaultActiveKey: 'images'})
        this.props.getImagesSize()
    }
    
    loadTextureSize = () => {
        this.setState({defaultActiveKey: 'textures'})
        this.props.getTexturesSize()
    }

    loadStorageSummary = () => {
        this.setState({defaultActiveKey: 'summary'})
        this.props.getStorageSummary()
    }

    updateCombinationName = (uuid) => {
        // let parent = e.target.parentNode;
        console.log(uuid);
        console.log(document.getElementById(uuid).value);
    }

    chooseCombination = (uuid) => {
        let combUUids = this.state.combinationIds;
        if(!combUUids.includes(uuid)){
            combUUids.push(uuid);
        }else{
            const index = combUUids.indexOf(uuid);
            if (index > -1) {
                combUUids.splice(index, 1);
            }
        }
        this.setState({combinationIds: combUUids})
        console.log(this.state.combinationIds);
    }
    
    staticImgModeChange = (uuid, mode) => {
        let payload = {
            id: uuid,
            mode: mode
        };

        console.log(payload);
        axios.post('combination/update-static-img-mode', payload).then(result => {
        });
    }

    render() {
        let combinations = ''
        let loading = this.props.combinations.loading

        if (!loading && !this.props.combinations.combinationsList.isEmpty) {
            combinations = (
                <Tabs activeKey={this.state.defaultActiveKey}
                onSelect={(k) => this.setState({defaultActiveKey: k})}
                id="uncontrolled-tab-example">
                    <Tab eventKey="combinations" title="Combinations">
                        <Row className="view-storage-combination" >
                            <Col md={{ span: 12}}>
                                <button style={{marginBottom: '0', textTransform: 'uppercase'}} className="btn btn-primary" onClick={this.loadCombinationSize}>Click here to load your creations</button>
                            </Col>
                            {
                                this.props.userPermission != undefined ? 
                                <Col md={{ span: 6 }} style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 5 }}>
                                    {/* <div style={{ display: this.props.userPermission.isShowGallery ? 'inline-block' : 'none' }}><button style={{ marginBottom: 5 }} className="btn btn-primary" onClick={this.openGallery}>View Gallery</button></div> */}
                                    
                                    <button style={{marginBottom: '0', float: 'left'}} className="btn btn-danger" 
                                                onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.deleteSelected()};}}>
                                                    Delete Selected</button>
                                </Col>
                                : ''
                            }
                            <Col md={{ span: 6}} style={{textAlign: 'right', paddingTop: 10, paddingBottom: 5}}>
                                <div style={{display: 'inline-block'}}><input className="form-control" placeholder="Input Name or UUID" type="text" onChange={this.updateSearchField} /></div>
                                <div style={{display: 'inline-block', marginLeft: 5}}><button style={{marginBottom: 5}} className="btn btn-primary" onClick={this.searchCombination}>Search</button></div>
                            </Col>
                            <Col md={{ span: 12}} style={{maxHeight: 600}}>
                                <Table className="combinations-table table-borderless" size="lg">
                                    <thead>
                                    <tr>
                                        <th style={{width: 50}}></th>
                                        <th colSpan={2}>Thumbnail</th>
                                        <th colSpan={2}>File Upload</th>
                                        <th style={{maxWidth: 100}}>Uuid</th>
                                        <th>Publish</th>
                                        <th>Size</th>
                                        <th>Created Date</th>
                                        <th>Download</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.combinations.combinationsList.map((combination, i) => {
                                                return    <tr key={i}>
                                                        <td style={{width: 50}}>
                                                            <input type="checkbox"
                                                            onChange={()=>this.chooseCombination(combination.uuid)} />
                                                        </td>
                                                        <td  colSpan={2} style={{verticalAlign: 'top', paddingTop: 16}}>
                                                            <img style={{width: 89+'%'}} src={combination.staticImg} />
                                                            <p style={{marginTop: 10}} >Total Views: {combination.totalView ?? 0}</p>
                                                            <p>{
                                                                combination.uuid
                                                            }</p>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <CombinationsSizeFileDownload downloadFiles={combination.downloadFiles} downloadFile={combination.downloadFile} combination={combination}/>
                                                        </td>
                                                        <td style={{width: 100}}>
                                                            {
                                                                combination.uuid
                                                            }
                                                        </td>
                                                        <td>
                                                            <CombinationsSizePublic isPublic={combination.isPublic} uuid={combination.uuid}/>
                                                        </td>
                                                        <td>
                                                            {
                                                                (combination.size / 1000000).toFixed(2) + " MB"
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                combination.createdDate
                                                            }
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-primary" onClick={()=>this.downloadCombination(combination.path)} style={{cursor: 'pointer', padding: 6}}>Download</a>
                                                        </td>
                                                        <td>
                                                            <img className="btn-delete-combination" src={deleteBtn} value={combination.uuid} 
                                                            onClick={()=>
                                                                { if (window.confirm('Are you sure you wish to delete this Combination?')) this.deleteCombination(combination.uuid) }
                                                                } 
                                                            />
                                                            {/* <Button value={combination.uuid} onClick={()=>this.deleteCombination(combination.uuid)}  className="btn btn-danger">Delete</Button> */}
                                                        </td>
                                                    </tr>
                                            })
                                        }
                                        {/* <tr>
                                            <td colSpan={12} style={{padding: 0}}>
                                                <button style={{marginBottom: '0'}} className="btn btn-primary" onClick={this.loadCombinationSize}>Load All</button>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td colSpan={2}>
                                                <b>Total Size: </b>
                                            </td>
                                            <td colSpan={7} style={{textAlign: 'left'}}>
                                                {
                                                    (this.props.combinations.totalSize / 1000000).toFixed(2) + " MB out of " + this.props.combinations.storage + " MB"
                                                }
                                            </td>
                                            <td colSpan={3} style={{textAlign: 'left'}}>
                                                <button style={{marginBottom: '0', float: 'right'}} className="btn btn-danger" 
                                                onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.deleteSelected()};}}>
                                                    Delete Selected</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            )
        }

        if (this.state.deleting || loading) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )
        }


        if (this.props.combinations.combinationsList.isEmpty) {
            combinations = (
                <Row>
                    <Col md={12} className="spinner">
                        <span className="rename-material-modal-error">No combinations found !</span>
                    </Col>
                </Row>
            )
        }

        return (
            combinations
        )
    }
}

const mapStateToProps = state => {
    console.log(state.combinations);
    return {
        combinations: state.combinations,
    }
}
const mapDispatchToProps = {
    getCombinationsSize: getCombinationsSize,
    getImagesSize: getImagesSize,
    getTexturesSize: getTexturesSize,
    deletePreset: deletePreset,
    refreshCombinationsSizeAfterDelete: refreshCombinationsSizeAfterDelete,
    getStorageSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(CombinationsCreations);