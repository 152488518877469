import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {
    changeMinimumDistance,
    changeMaximumDistance,
    changeMaxPolarAngle,
    changeMinPolarAngle,
    changeMaxAzimuthAngle,
    changeMinAzimuthAngle,
    changeZoomSpeed,
    changeCameraPositionXSetting,
    changeCameraPositionYSetting,
    changeCameraPositionZSetting,

    changeMinimumSpeed,
    changeMaximumSpeed
} from "../../../../actions/actionsCreators";

class MenuItemInputRange2 extends Component {
    state = {
        value: this.props.value ? this.props.value : 0
    };

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value, additional) => {
        switch (type) {
            case 'cameraX':
                this.props.changeCameraPositionXSetting(value)
                break;
            case 'cameraY':
                this.props.changeCameraPositionYSetting(value)
                break;
            case 'cameraZ':
                this.props.changeCameraPositionZSetting(value)
                break;
            case 'zoomSpeed':
                this.props.changeZoomSpeed(value)
                break;
            case 'minimumDistance':
                this.props.changeMinimumDistance(value)
                break;
            case 'maximumDistance':
                this.props.changeMaximumDistance(value)
                break;
            case 'maxPolarAngle':
                this.props.changeMaxPolarAngle(value)
                break;
            case 'minPolarAngle':
                this.props.changeMinPolarAngle(value)
                break;
            case 'maxAzimuthAngle':
                this.props.changeMaxAzimuthAngle(value)
                break;
            case 'minAzimuthAngle':
                this.props.changeMinAzimuthAngle(value)
                break;

            case 'minimumSpeed':
                this.props.changeMinimumSpeed(value)
                break;
            case 'maximumSpeed':
                this.props.changeMaximumSpeed(value)
                break;
            default:
                return null;
        }
    }

    /**
     * Checks if Value entered is a number
     * Then updates state when input text box is used for changing this field
     */
    handleTextBoxChange = event => {
        let inputVal = event.target.value
        //if (!isNaN(inputVal) && inputVal <= this.props.max && inputVal >= this.props.min) {
        if (!isNaN(inputVal)) {
            this.setState({[event.target.name]: event.target.value});
            this.dispatchAction(this.props.type, Number(event.target.value))
        }
    }

    /**
     * Update state when slider is used for changing this field
     */
    handleSliderChange = (value) => {
        this.setState({
            value: parseFloat(value.toFixed(2))
        })
        this.dispatchAction(this.props.type, value)
        if(this.props.isNeedValueChange){
            this.props.valueChange('asd')
        }
    }

    render() {
        return (
            <Row key={this.props.value} /*{...this.props}*/>
                <Col md={12} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={12} className="input-range-slider">
                    <InputRange
                        step={this.props.step ? this.props.step : 0.01}
                        maxValue={this.props.max}
                        minValue={this.props.min}
                        value={this.state.value}
                        onChange={this.handleSliderChange}/>
                </Col>
                <Col md={4} className="setting-col-input" style={{marginTop: -5}}>
                    <Form.Control
                        autoComplete="off"
                        size="sm"
                        type="text"
                        className="menu-item-text-box"
                        name="value"
                        value={this.state.value}
                        onChange={this.handleTextBoxChange}
                        disabled={this.props.disableInput ? this.props.disableInput : false}
                        />
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {

    changeMinimumDistance:changeMinimumDistance,
    changeMaximumDistance:changeMaximumDistance,
    changeMaxPolarAngle:changeMaxPolarAngle,
    changeMinPolarAngle:changeMinPolarAngle,
    changeMaxAzimuthAngle:changeMaxAzimuthAngle,
    changeMinAzimuthAngle:changeMinAzimuthAngle,
    changeZoomSpeed:changeZoomSpeed,
    
    changeCameraPositionXSetting: changeCameraPositionXSetting,
    changeCameraPositionYSetting: changeCameraPositionYSetting,
    changeCameraPositionZSetting: changeCameraPositionZSetting,
    
    changeMinimumSpeed: changeMinimumSpeed,
    changeMaximumSpeed: changeMaximumSpeed

    
}

export default connect(null, mapDispatchToProps)(MenuItemInputRange2)