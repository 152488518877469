import axios from 'axios';
import jwt_decode from "jwt-decode";

let bearer = "Bearer ";
//Auth for Local

//Auth for staging



//Check browser disable cookie
var cookieEnabled = navigator.cookieEnabled;
if (!cookieEnabled){ 
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
}

let token = null;
if(cookieEnabled){
    token = localStorage.getItem('user');
}


let auth =  token != null ? bearer.concat(token.replace(/"/g, "")) : null;
//let decoded_jwt = jwt_decode(localStorage.getItem('user'));
let Axios = null;
const baseURL = 'https://api-staging.cadgl.com/api/v1/';
if(auth){
    Axios = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": auth
        }
    })
    
}else{
    Axios = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json"
        }
    })
}
export default Axios;
