import React, {Component} from "react";
import folderIcon from '../../../../assets/folder@2x.png';
import {Col, Row} from "react-bootstrap";
import Preset from "./Preset";
import DeletePresetFolderModal from "./DeletePresetFolderModal";
import EditPresetFolderModal from "./EditPresetFolderModal";
import ApplyPresetFolderModal from "./ApplyPresetFolderModal";
import arrowUp from '../../../../assets/arrow-up@2x.png';
import arrowDown from '../../../../assets/down-arrow@2x.png';

class PresetFolder extends Component {

    render() {
        return (
            <React.Fragment>
                <Row data-test={this.props.uuid}>
                    <Col md={12} className="settings-tab-title preset-folder-title">
                        <img className="preset-folder-icon" src={folderIcon} alt=""/>
                        <span className="preset-folder-name" onClick={this.props.collapse}>{this.props.name}</span>
                        <DeletePresetFolderModal uuid={this.props.uuid} />
                        <EditPresetFolderModal uuid={this.props.uuid} folderName={this.props.name} />
                        <ApplyPresetFolderModal uuid={this.props.uuid} layerUuid={this.props.pickedObject != null ? this.props.pickedObject.id : null} />
                        {
                            this.props.collapsed ?
                                <img className="preset-folder-arrow" src={arrowUp} alt="" onClick={this.props.collapse}/>
                                :
                                <img className="preset-folder-arrow" src={arrowDown} alt="" onClick={this.props.collapse}/>
                        }
                    </Col>
                </Row>
                {
                    this.props.presets && this.props.collapsed ?
                        this.props.presets.map(preset => {
                            return <Preset user={this.props.user} data={preset.data} layerUuid={this.props.pickedObject != null ? this.props.pickedObject.id : null} key={preset.uuid} uuidPreset={preset.uuid} uuidPresetFolder={this.props.uuid} name={preset.name}  price={preset.price}/>
                        })
                        : this.props.collapsed && !this.props.presets ?
                        <Row>
                            <Col md={12} className="no-presets-info">
                                <span>No Presets !</span>
                            </Col>
                        </Row>
                        : ''
                }
            </React.Fragment>
        )
    }
}

export default PresetFolder