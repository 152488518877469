import { connect } from 'react-redux';
import Tracker from "../components/Tracker/Tracker";
import {loadInitialReportRequest} from "../actions/actionsCreators";

const mapStateToProps = (state) => {
    return {
        tracker: state.tracker
    }
}

const mapDispatchToProps = {
    loadInitialReportRequest: loadInitialReportRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracker)