import {connect} from "react-redux";
import SceneSettings from "../../components/Configurator/Sidebar/SceneSettings/SceneSettings";

const mapStateToProps = (state) => {
    console.log("Scene Settings Container mapStateToProps");
    return {
        api: state.sceneConfig.api,
        config: state.sceneConfig.config,
        geometry: state.sceneConfig.geometry,
        materials: state.sceneConfig.materials,
        pixelRatio: state.sceneConfig.pixelRatio,
        userPermission: state.user.userPermission
    }
}

export default connect(mapStateToProps)(SceneSettings)