import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../axios';
import {
    deleteCombination,
} from "../../../actions/actionsCreators";
import deleteBtn from '../../../assets/delete@2x.png';
import closeIcon from "../../../assets/close-icon@2x.png";
import {SpinnerSmall} from "../../../helpers/spinners";

class DeleteCombinationsModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    /**
     * Send request to delete combination also remove it from state
     */
    deleteCombination = () => {
        this.setState({loading: true})
        axios.post('combination/delete', {id: this.props.uuid}).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.handleClose()
                this.props.deleteCombination(this.props.uuid)
            }

        }).catch(err => {
            this.setState({
                hasError: true,
                error: err.message,
                loading: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <img className="btn-delete-combination" src={deleteBtn} alt="" onClick={this.handleShow} />
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Delete Combination</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                                <span>Are you sure you want to delete this combination?</span>
                            </Col>
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <SpinnerSmall />
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={12} className="text-center">
                                <Button variant="primary" className="btn-delete-preset" onClick={this.deleteCombination} disabled={this.state.loading}>
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    deleteCombination: deleteCombination,
}

export default connect(null, mapDispatchToProps)(DeleteCombinationsModal)