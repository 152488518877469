export const switchCases = (props, api) => {
    switch (props.iframe.type) {
        case 'takeScreenshot':
            takeScreenshot(api)
            break;
        case 'enableArIframe':
            let arSettings = {...props.iframe.arSettings}
            arSettings.enabled = false
            api.setXRParams(arSettings)
            break;
        case 'resetCameraIframe':
            api.resetCamera()
            break;
        case 'enableRotationIframe':
            api.setCameraParams({orbitParams: {autoRotate: props.iframe.combination.config.camera.orbitParams.autoRotate}})
            break;
        default:
            break;
    }
}

/**
 * Take screenshot of canvas
 * @param api
 */
const takeScreenshot = api => {
    let img = api.getSnapshotData("image/jpg")
    let link = document.createElement("a");
    link.download = "screenshot.jpg";
    link.href = img;
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(link);
    link.click();
    body.removeChild(link);
}