import { getGPUTier } from 'detect-gpu';
import viewerUrl from '../../../3dViewerUrl';
import HardwareAccelerationModal from "../../Configurator/Combinations/HardwareAccelerationModal";

import React, {useEffect, useState} from "react";
import {Spinner, Col, Row, DropdownButton, Dropdown, Navbar, Form, Button} from "react-bootstrap";
import {FileLoader, Vector2, TextureLoader} from "three";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {switchCases} from "../switchCases";
import {saveSceneConfigToState, selectObject, loadPresets, loadCombinations} from "../../../actions/actionsCreators";
import PaypalExpressBtn from 'react-paypal-express-checkout';
import axios from '../../../axios';
// import Navbar from "../Navbar/Navbar";

import logo from '../../../assets/logo_site.png';


import arOn from '../../../assets/button-AR-On.png';
import cameraOn from '../../../assets/button-Camera-On.png';
import switchOn from '../../../assets/button-On.png';
import rotateOn from '../../../assets/button-Rotate-On.png';

import arOff from '../../../assets/button-AR-Off.png';
import cameraOff from '../../../assets/button-Camera-Off.png';
import switchOff from '../../../assets/button-Off.png';
import rotateOff from '../../../assets/button-Rotate-Off.png';
import hamburger from '../../../assets/hamburger-icon.png';
import Switch from "react-bootstrap/esm/Switch";
import ZoomAlertModal from "../../Configurator/Combinations/ZoomAlertModal";
import HelperModal from "../../Configurator/Combinations/HelperModal";
import CombinationIcons from "../../Configurator/Combinations/CombinationIcons";
import door from '../../../assets/door.svg';
import doorHandle from '../../../assets/door-handle.png';

import {Modal} from "react-bootstrap";
import closeIcon from "../../../assets/close-icon@2x.png";
import * as commonFunction from '../../CommonFunction/CommonFunction.js';

import lang_it from '../../../lang/lang_it.json';
import lang_en from '../../../lang/lang_en.json';
import lang_fr from '../../../lang/lang_fr.json';
import lang_vn from '../../../lang/lang_vn.json';
let lang = {};
if(localStorage.getItem('lang')){
    switch(localStorage.getItem('lang')){
        case "en":
            lang = lang_en;
        break;
        case "vn":
            lang = lang_vn;
        break;
        case "fr":
            lang = lang_fr;
        break;
        case "it":
            lang = lang_it;
        break;
    }
}else{
    lang = lang_en;
}

// const Viewer = require("../../cadcentre/dist/cadcenter");
const Viewer = window.CADEDITOR.icad;
let mousePosition = new Vector2(0, 0)
let mouseDownTime = 0
let API
let _logoVisibility = 1;
let isFirstClick = false;

// Duration is in seconds
let isFullScreen = false;
let logoFadeInDuration = 1.0;
let logoFadeOutDuration = 1.0;
let logoStayDuration = 1.0;
let logoMinActionTime = 0.5; // 500 ms. Min time to check.
let _logoTweener;
let _logoState =  'startVisible';
let outlineFadeTime = 1;
let outlineVisibleTime = 4;
let outlineEdgeStrength = 1.0;
let mouseWheelTime = 0;
let lastMouseDownTime = 0;
let userInfo = {};
let variationName = '';
let variationPrice = 0;
let variationPriceOriginal = 0;
let variationDesc = '';
let materialsLibrary = [];
let materialsForQuotation = [];
let materialsLibraryLocal = [];
let hiddenMaterialsLibraryLocal = [];
let layersTag = {};
let layersPrice = {};
let layersDisplayName = {};
let layersMaterial = {};
let layersVisiblePrice = {};
let presetIds = '';
let meshes = '';
let totalPrice = 0;
let layersLabel = {};
let currentTag = '';
let userOwned = {};
let currencyLocal = "USD";
let presetFolderLayer = [];
let originalXREnable = false;
let currentCombinationName = '';
let currentCombinationUrl = window.location;
let originalCanvasHeight = 0;
let isIgnoreAnimation = false;
const canvas = React.createRef()
let play = 'https://cadgl.net/icons_cadgl/play.png';
let stop = 'https://cadgl.net/icons_cadgl/stop.png';
let sound_on = 'https://cadgl.net/icons_cadgl/sound_on.png';
let sound_off = 'https://cadgl.net/icons_cadgl/sound_off.png';
let help = 'https://cadgl.net/icons_cadgl/help.png';
let fullscreen = 'https://cadgl.net/icons_cadgl/fullscreen.png';
let auto_rotate = 'https://cadgl.net/icons_cadgl/auto_rotate.png';
let auto_rotate_stop = 'https://cadgl.net/icons_cadgl/auto_rotate_stop.png';
let annotation_on = 'https://cadgl.net/icons_cadgl/annotation_on.png';
let annotation_off = 'https://cadgl.net/icons_cadgl/annotation_off.png';
let draggingAnnotation = null;
let delayForSelectHiddenLayer = false;
let layerHavePresetFolder = [];
let enableCameraDoubleClick = true;

function ProductRenderer(props, state) {
    // const client = {
    //     sandbox:    'AbwbHMXto-i2T3QaJsHvPzQTJT1MMPvSQYDO0yyLcsbumrql4iMfQMuA8A7ZJPzdkI2TWBx_68JRFav5',
    //     production: 'YOUR-PRODUCTION-APP-ID',
    // }
    
    const [activeLayerId, setActiveLayerId] = useState(null);
    const [apiReact, setApiReact] = useState(null);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [selectedLayerWithTag, setSelectedLayerWithTag] = useState([]);
    const [panelVisible, setPanelVisible] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [userReact, setUserReact] = useState(null);
    const [layersReact, setLayersReact] = useState([]);
    const [materialsReact, setMaterialsReact] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [collections, setCollections] = useState([]);
    const [layerPresetFoldersReact, setLayerPresetFoldersReact] = useState([]);




    const [currency, setCurrency] = useState({
        currency: '',
    })
    const [paypal, setPaypal] = useState({
        client: {},
    })
    const [value, setState] = useState({
        checkoutShow: false,
        quotationPCShow: false,
        quotationMobileShow: false,
        paymentID: '',
        checkoutSuccessShow: false,
        variationName: '',
        variationPrice: 0,
        variationPriceOriginal: 0,
        variationDesc: '',
        userPermission: {},
        materialsLibrary: [],
        presetFolderLayer: [],
        quotationEmail: '',
        quotationQuestion: '',
        quotationSubmitting: false,
        canvasHeight: 1000,
        client: null,
        leo: ''
    })
    let arStatus = true;
    let autoRotate = false;
    let presets = [];
    let chosenLayer;
    let combinations;
    let logoParams = {
        opacity: 1,
        enabled: true,
        image: "https://cadgl.net/editor/images/logo_editor.png",
        height: 0,
        width: 0,
        positionY: 0,
        positionX: 0,
        scale: 1,
        logoStayDuration: 5,
        logoFadeInDuration: 0,
        logoFadeOutDuration: 0
    };

    const [gpu, setGPU] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [showHardwareModal, setShowHardwareModal] = useState(false);
    async function testGPU(){
        const gpuTier = await getGPUTier();
        setGPU(gpuTier);
    }
    function coreFeature(){
        let that = this;
        console.log('useEffect');
        let timeTimeout = 0;
        if(window.self !== window.top){
            timeTimeout = 1000;
        }
        
        document.getElementById("static-img").style.setProperty('height', window.innerHeight+'px', 'important');
        if(document.getElementById("canvas").clientWidth != undefined && document.getElementById("canvas").clientWidth > 0){
            //if(window.innerWidth < window.innerHeight && window.innerWidth == 1080){
            if(window.innerWidth < window.innerHeight){
                document.getElementById("configurator-canvas-area").style.setProperty('flex', '100%');
                document.getElementById("configurator-canvas-area").style.setProperty('max-width', '100%');

                document.getElementById("configurators-panel-area").style.setProperty('flex', '100%');
                document.getElementById("configurators-panel-area").style.setProperty('max-width', '100%');
                
            }
            setTimeout(() => {
                    //Create API instance
                    const api = new Viewer.API({
                        canvas: canvas.current,
                        xrOverlay: document.getElementById("xrOverlay"),
                        gemConfig: {
                            quality: 'high',
                            diamondClass: [{
                                name: "diamond",
                                color: 0xaaaaaa
                            }]
                        },
                        annotationIconParams: {
                            maxAnnotations: 10,
                            texture: "https://cadgl.net/icons_cadgl/annotations.png",
                            columns: 10,
                            size: new Vector2(48, 48),
                            padding: 2,
                            annotationHiddenAlpha: 0.3,
                            annotationHighlightTint: "gold",
                            // annotationTint: "red"
                        },
                        logoParams: logoParams,
                        animationLoopCallback : ()=> animationLoop()
                    });
                    API = api
                    setApiReact(api);
                    // api.setViewerSize(canvas.current.clientWidth, canvas.current.clientHeight);
    
                    
                    let gRefl = api.getGroundReflectionParams();
                    gRefl.enable = false;
                    api.setGroundReflectionParams(gRefl);
                    let xrParams = api.getXRParams();
                    xrParams.enabled = false;
                    api.setXRParams(xrParams);
                    api.play() //Render 3D model on canvas
    
                    commonFunction.setDefaultRatio(api);
    
                    //if (!props.iframe.combination.id) {
                    if (!props.match.params.id) {
                        new FileLoader().load("./scene.json", (response) => {
                            api.importScene(JSON.parse(response))
                        })
                    } else {
                        document.getElementById("canvas").style.display = "none";
                        let isInIFrame = window.self !== window.top;
                        axios.post('combination/get-configurator', {
                            id: props.match.params.id,
                            getMaterial: true,
                            getConfig: true,
                            getGeometry: true,
                            isInIFrame: isInIFrame
                        }).then(response => {
                            let comp = response.data.data.combination;
                            // layersLabel = comp.layersLabel;
                            // layersTag = comp.layersTag;
                            
                            currentCombinationName = comp.name;
                            // commonFunction.currentCombinationName = currentCombinationName;
                            isIgnoreAnimation = comp.isIgnoreAnimation;
                            enableCameraDoubleClick = comp.isCameraDoubleClick != undefined ? comp.isCameraDoubleClick : true;
                            if(isIgnoreAnimation == "true"){
                                api.stopAllAnimations();
                            }
                            let user = response.data.data.user;
                            setUserReact(user);
                            //Check if user has template
                            let search = window.location.search;
                            let templateName = search.split("template=");
                            if(templateName[1] && user.configuratorsTemplates && user.configuratorsTemplates.includes(templateName[1])){

                            }else{
                                // alert("This template does not belong to this Owner.");
                                // return false;
                            }
                            userOwned = response.data.data.user;
                            let client = {};
                            client.production = user.paypalProductionId;
                            client.sandbox = user.paypalSandboxId;

                            setPaypal({
                                ...paypal,
                                client: client,
                            });
                            setCurrency({
                                currency: user.currency ? user.currency : 'USD',
                            });
                            currencyLocal = user.currency ? user.currency : 'USD';
                            
                            setTimeout(() => {
                                document.getElementById('btnBuyNow').style.display = "block";
                                document.getElementById('btnBuyNow').disabled = !user.isShowBuyNowButton;
                            }, 3000);
    
                            // commonFunction.renderFullscreenIconHelperIcon(comp, "configurators");
    
                            if (window.getComputedStyle(document.getElementById("static-img")).display !== "none")
                            {
                                commonFunction.renderFullscreenIconHelperIcon(comp, "configurators");
                                document.getElementById("myLoader2").style.display = "block";
                            }
    
                            document.getElementById('combinationDesc').value = comp.description != undefined ? comp.description : '';
                            variationPrice = comp.price != undefined ? commonFunction.convertPriceToString(comp.price, currencyLocal) : 0;
                            variationDesc = comp.description != undefined ? comp.description : '';
                            variationName = comp.name;
                            variationPriceOriginal = comp.price;
    
                            presetIds = comp.presetIds;
                            meshes = comp.meshes;
                            initial(comp.path, user, comp);
    
    
                            commonFunction.renderStaticImg(comp);
    
    
                            let combinationList = document.getElementById("combinationList");
                            combinationList.style.height = "74px";
                            combinationList.style.overflow = "auto";
                            combinationList.style.margin = "2px";
                            axios.get('combination/get-list?userId='+user.userId+'&meshes=' + comp.meshes).then(response => {
                                combinations = response.data.data.combination
                                combinations.map(function(combination, index) {

                                    let selectList = document.createElement("div");
                                    selectList.className = "configurator-select-variation";
                                    let variationName = combination.name;
                                    selectList.innerHTML  = commonFunction.truncate(variationName, 15);
                                    selectList.setAttribute("data-path", combination.path);
                                    selectList.setAttribute("uuid", combination.uuid);
                                    combinationList.appendChild(selectList);

                                    if(combination.uuid == comp.uuid)
                                    {
                                        selectList.classList.add("choosen-preset-css");
                                    }
                                });
    
                            }).catch(error => {
                            })
    
    
                            logoParams.enabled = user.isAllowWatermarkLogo;
                            userInfo.isShowLinkToolTip = user.isShowLinkToolTip;
                            api.setLogoParams(logoParams);
                            
                
                        }).catch(err => {
                        })
    
                    }
                
                    function _tweenLogo(x, finalState, duration = 0.5){
                        if(_logoTweener) _logoTweener.stop();
                        const fState = finalState;
                        _logoTweener = api.getTweener({l: _logoVisibility}).to({l: x}, duration*1000).onUpdate((o)=>{
                            if (Math.abs(_logoVisibility-o.l) < 0.0001) return;
                            _logoVisibility = o.l;
                            let p = api.getLogoParams();
                            p.opacity = _logoVisibility * _logoVisibility;
                            api.setLogoParams(p);
                        }).onComplete(()=>{
                            _logoVisibility = x;
                            _logoTweener = undefined;
                            _logoState = fState;
                        })
                            //.easing(TWEEN.Easing.Quadratic.InOut)
                            .start();
                        return _logoTweener;
                    }
                
                    function animationLoop(){
    
                        let logoParams = api.getLogoParams();
                        const time = Date.now();
                        const mmax = logoMinActionTime * 1000;
                
                        if(( mouseDownTime > 1 && time-mouseDownTime > mmax ) || ( time-mouseWheelTime < 500 ) ){
                            // show if hidden. stop if hiding and show. do nothing if showing or visible
                            if(_logoState === 'hidden' || _logoState === 'hiding'){
                                _logoVisibility = Math.max(0.002, _logoVisibility);
                                _tweenLogo(1, 'startVisible', (1-_logoVisibility) *logoParams.logoFadeInDuration);
                                _logoState = 'showing';
                            }
                        }else {
                            // hide if visible. stop if showing and hide. do nothing if hiding or hidden
                            if(_logoState === 'startVisible'){
                                // this._logoVisibility = this._logoVisibility;
                                _tweenLogo(_logoVisibility, 'startHiding',logoParams.logoStayDuration-0.002);
                                _logoState = 'visible';
                            }
                            if(_logoState === 'startHiding'){
                                _logoVisibility = Math.min(0.998, _logoVisibility);
                                _tweenLogo(0, 'hidden',(_logoVisibility) * logoParams.logoFadeOutDuration);
                                _logoState = 'hiding';
                            }
                        }
                    }

                    function beforeApplyColor(api, presetData, layer, e, target = null){
                        if(!presetData.includes("googleapis"))
                        {
                            let presetDataParsed = JSON.parse(presetData);
                            applyColor(api, presetDataParsed, layer, e, target);
                        }
                        else
                        {
                            let isMobile = window.innerWidth < window.innerHeight;
                            let isIphone = navigator.platform == 'iPhone' || navigator.platform == 'iPad';
                            if(isMobile && !isIphone){
                                document.getElementById("myLoader").style.top = "50%";
                            }
                            if(window.innerWidth < window.innerHeight && window.innerWidth == 1080){
                                document.getElementById("myLoader").style.top = "50%";
                            }
                            if(isFullScreen){
                                document.getElementById("myLoader").style.top = "25%";
                            }
                            document.getElementById("myLoader").style.display = "block";
                            // document.getElementById("canvas").style.display = "none";
                            let id = presetData.replace('googleapis:','');
                            //TODO use API to get Preset Data
                            axios.post('preset/get', {id: id}).then(response => {
                                let {data} = response
                                if (!data.success) {
                                    console.log(data)
                                }
                                if (data.success) {
                                    let preset = data.data.preset;
                                    setTimeout(() => {
                                        let presetDataParsed = preset.data; //JSON.parse(e.target.value);
                                        console.log("googleapis");
                                        applyColor(api, presetDataParsed, layer, e, target);
                                        setTimeout(() => {
                                            document.getElementById("myLoader").style.display = "none";
                                            document.getElementById("canvas").style.display = "block";
                                        }, 1000);
                                    }, 1000);
                                }
                            });
                        }
                    }
    
                    function applyColor(api, presetData, layer, e, target = null)
                    {
                        if(typeof presetData !== 'object'){
                            presetData = JSON.parse(presetData);
                        }
                        presetData.id = layer.params.id;
                        presetData.name = layer.params.name;
                        layer.params = presetData;
                        layer.mesh.forEach(object => {
                            object.material.map = null;
                            object.material.lightMap = null;
                            object.material.aoMap = null;
                            object.material.emissiveMap = null;
                            object.material.bumpMap = null;
                            object.material.normalMap = null;
                            object.material.roughnessMap = null;
                            object.material.metalnessMap = null;
                            object.material.alphaMap = null;
                            api.importMaterialParams(layer.params).then((m) => {
                                try{
                                    //m.visible = layer.visible;
                                    api.setMaterialParams(object, m)
                                }
                                catch (error) {
                                        console.log(error);
                                }
                                
                            })
                        })

                        for(let presetLayer of materialsLibraryLocal)
                        {
                            if(presetLayer.layer == layer.params.id)
                            {
                                if(e != null){
                                    presetLayer.preset = e.target.parentNode.getAttribute("data-value");
                                    presetLayer.presetId = e.target.parentNode.getAttribute("id");
                                    
                                }else{
                                    presetLayer.preset = target.getAttribute("data-value");
                                    presetLayer.presetId = target.getAttribute("id");
                                }
                            }
                        }

                        for(let presetLayer of materialsForQuotation)
                        {
                            if(presetLayer.layer == layer.params.id)
                            {
                                if(e != null){
                                    presetLayer.preset = e.target.parentNode.getAttribute("data-value");
                                    presetLayer.presetId = e.target.parentNode.getAttribute("id");
                                    presetLayer.presetName = e.target.parentNode.getAttribute("data-name");
                                    
                                }else{
                                    presetLayer.preset = target.getAttribute("data-value");
                                    presetLayer.presetId = target.getAttribute("id");
                                    presetLayer.presetName = target.getAttribute("data-name");
                                }
                            }
                        }

                        
                    }
    
                    function reCalculateTotalPrice(){
                        totalPrice = 0;
                        for (const [key, value] of Object.entries(layersVisiblePrice)) {
                            totalPrice = Number(totalPrice) + Number(value);
                        }
                        document.getElementById('combinationPrice').value = commonFunction.convertPriceToString(totalPrice, currencyLocal);
                        variationPrice = commonFunction.convertPriceToString(totalPrice, currencyLocal);
                        variationPriceOriginal = totalPrice;
                    }
    
                    function initial(path, user, comp)
                    {
                        console.log(user);
                        console.log(path);
                        if(user.cdnUrl){
                            let arrPath = path.split("/");
                            path = "https://" + user.cdnUrl + "/" + arrPath[arrPath.length - 1];
                        }
                        new FileLoader().load(path, async (response) => {
                            materialsLibraryLocal = [];
                            let chk = document.getElementById('layerVisible');
                            chk.checked = false;
                            await api.importScene(JSON.parse(response)).then(() => {
                                setTimeout(() => {
                                    commonFunction.waitAudioContext(api);
                                    // api.play();
                                    if(window.innerWidth < window.innerHeight){
                                        api.setCameraParams({ orbitParams: { zoomSpeed: 1 } });
                                        if (document.getElementsByClassName("quotation-div-mobile-mode")[0]) {
                                            document.getElementsByClassName("quotation-div-mobile-mode")[0].style.display = 'block';
                                        }
                                        if (document.getElementsByClassName("quotation-div-pc-mode")[0]) {
                                        document.getElementsByClassName("quotation-div-pc-mode")[0].style.display = 'none';
                                        }
                                        document.getElementById("layerList").style.height = 74+'px';
                                        document.getElementById("layerList").style.setProperty("max-height", "145px", "important");
                                        document.getElementById("layerList").style.setProperty("width", "100%", "important");
                                    } else {
                                        if (document.getElementById("panel-close-mobile")) {
                                            document.getElementById("panel-close-mobile").style.display = 'none';
                                        }
                                        if (document.getElementsByClassName("quotation-div-mobile-mode")[0]) {
                                            document.getElementsByClassName("quotation-div-mobile-mode")[0].style.display = 'none';
                                        }
                                        if (document.getElementsByClassName("quotation-div-pc-mode")[0]) {
                                            document.getElementsByClassName("quotation-div-pc-mode")[0].style.display = 'block';
                                        }
                                        document.getElementById("layerList").style.height = 100+'px';
                                    }
        
                                    let obj = JSON.parse(response);
        
                                    let p = api.getCameraParams();
                                    p.position = [obj.config.camera.position[0], obj.config.camera.position[1], obj.config.camera.position[2]];
                                    api.setCameraParams(p);
                                    let animationSet = api.getModelAnimation();
                                    if(animationSet.size > 0 && isIgnoreAnimation == "false"){
                                        //SHOW PLAY ANIMATION BUTTON
                                        document.getElementById("animationPlay").style.display = "block";
                                    }
                                    
                                    setTimeout(() => {
                                        commonFunction.hideLayersAfterLoad();
                                        setState({
                                            ...value,
                                            canvasHeight: canvas.current.clientHeight,
                                        });
                                        originalCanvasHeight = canvas.current.clientHeight;
                                    }, 3000);
                                    
        
                                    
                                    if(api.getAmbientAudio()  != undefined){
                                        document.getElementById("audioPlay").style.display = "block";
                                    }
        
                                    //Auto Rotate
                                    let cameraParams = api.getCameraParams();
                                    let orbitP = cameraParams.orbitParams;
                                    if(orbitP.autoRotate == true){
                                        document.getElementById("autoRotateIcon").style.display = "block";
                                        document.getElementById("btnRotate").checked = true;
                                        
                                    }
        
                                    //Annotation
                                    commonFunction.renderAnnotationIcon(api);
        
                                    
                                    if(new Date(comp.createDate).getFullYear() <= new Date(2021,10,26).getFullYear()
                                    && 
                                    new Date(comp.createDate).getMonth() <= new Date(2021,10,26).getMonth()
                                    ){
                                        api.setCameraParams({orbitParams: {zoomSpeed: 1, minDistance: 0.2}})
                                    }


                                    setTimeout(() => {
                                        let per = 90;
                                        var percentageInterval = setInterval(() => {
                                            per++;
                                            document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                                            document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                                            if(per >= 100){
                                                commonFunction.hideLayersAfterLoad();
                                                clearInterval(percentageInterval);
                                            }
                                        }, 100);
                                    }, 1);



        
                                    // 
                                    let isInIFrame = window.self !== window.top;
                                    if(isInIFrame){
                                        xrParams = api.getXRParams();
                                        xrParams.enabled = false;
                                        
                                        if(navigator.platform == 'iPhone' || navigator.platform == 'iPad' || navigator.platform == 'Win32'){
                                            api.setXRParams(xrParams);
                                        }
                                    }else{
                                        var xrInterval = setInterval(() => {
                                                xrParams = api.getXRParams();
                                                
                                                if (navigator.platform == 'iPhone' || navigator.platform == 'iPad') { // <-- Use the one here above
                                                        
                                                    if ('xr' in navigator)
                                                    {
                                                        if(!user.isAllowAR)
                                                        {
                                                            xrParams.enabled = false;
                                                        }
                                                        api.setXRParams(xrParams);
                                                    }
                                                    else
                                                    {
                                                        var mySpans = document.getElementsByTagName('a');
                                                        var arBut;
                                                        for(var i=0;i<mySpans.length;i++){
                                    
                                                            if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                    
                                                            arBut = mySpans[i];
                                                            break;
                                    
                                                        }
                                    
                                                        }
                                                        if(arBut)
                                                        {
                                                            //Hide Button for iOS for now
                                                            arBut.style.display = "none";
                                                            if(user.isAllowAR)
                                                            {
                                                                if(!xrParams.enabled)
                                                                {
                                                                    arBut.style.display = "none";
                                                                }
                                                            }
                                                            else{
                                                                arBut.style.display = "none";
                                                            }
                                                            arBut.innerText = 'START AR';
                                                            arBut.removeAttribute("href");
                                        
                                                            // Get the modal
                                                            var modal = document.getElementById("myModal");
                                        
                                                            // Get the button that opens the modal
                                                            var btn = arBut;
                                        
                                                            // Get the <span> element that closes the modal
                                                            var span = document.getElementsByClassName("closeAR")[0];
                                        
                                                            // When the user clicks on the button, open the modal
                                                            var p = document.getElementsByClassName("modal-bodyAR-p")[0];
                                                            //p.innerHTML = "Please download or open <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> to view " + "<a href='"+document.location+"'>"+document.location+"</a> and experience the AR ";
                                                            p.innerHTML = "To view in AR <br/> <a id='urlClicking' href='#'>"+currentCombinationName+"</a> (Copy link) <br/> Use or install <a target='_blank' href='https://apps.apple.com/us/app/webxr-viewer/id1295998056'>XR Viewer</a> <br/> <i>* AR will be fully available on IOS very soon</i> ";
                                                            
                                                            document.getElementById('urlClicking').addEventListener('click', function(){
                                                                    const textField = document.createElement('textarea');
                                                                    textField.innerText = currentCombinationUrl;
                                                                    document.body.appendChild(textField);
                                                                    textField.focus();
                                                                    textField.select();
                                                                    textField.setSelectionRange(0, 99999); /* For mobile devices */
                                                                    try {
                                                                    // Now that we've selected the anchor text, execute the copy command
                                                                        const successful = document.execCommand('copy');
                                                                    } catch(err) {
                                                                        alert(err);
                                                                    }
                                                                    document.body.removeChild(textField);
                                                                    alert("Copied to the clipboard");
                                                            });
        
                                                            btn.onclick = function() {
                                                                modal.style.display = "block";
                                                            }
                                        
                                                            // When the user clicks on <span> (x), close the modal
                                                            span.onclick = function() {
                                                            modal.style.display = "none";
                                                            }
                                        
                                                            // When the user clicks anywhere outside of the modal, close it
                                                            window.onclick = function(event) {
                                                                if (event.target == modal) {
                                                                    modal.style.display = "none";
                                                                }
                                                            }
                                                        }
                                    
                                                    }
                                                }
                                                else
                                                {
                                                    var element =  document.getElementById('ARButton');
                                                    if(element != null)
                                                    {
                                                        var txt = element.textContent || element.innerText;
                                                        if(txt == 'AR NOT SUPPORTED')
                                                        {
                                                            element.style.display = "none";
                                                        }
                                                    }
                                                    var mySpans = document.getElementsByTagName('a');
                                                    var arBut;
                                                    for(var i=0;i<mySpans.length;i++){
                                
                                                        if(mySpans[i].innerHTML == 'WEBXR NOT AVAILABLE'){
                                                            arBut = mySpans[i];
                                                            break;
                                                        }
                                                    }
                                                    if(arBut){
                                                        arBut.style.display = "none";
                                                    }
                                                }
                                                clearInterval(xrInterval);
                                        }, 1);
                                    }
        
                                    materialsLibrary = api.getMaterialLibrary();
                                    materialsLibrary = materialsLibrary.sort(commonFunction.sortLayerName);
                                    setMaterialsReact(materialsLibrary);
                                    let layerList = document.getElementById("layerList");
                                    layerList.innerHTML = "";
                                    layerList.style.overflow = 'auto';
    
    
                                    let index = 0;
                                    let totalMaterialLayer = 0;
                                    let firstMaterialLayerId = "";
                                    let isMobile = window.innerWidth < window.innerHeight;
                                    layersVisiblePrice = {};


                                    let uuids = [];
                                    for(const layer of materialsLibrary)
                                    {
                                        uuids.push(layer.params.id);
                                    }

                                    //Get Layers Info
                                    axios.post('layer/getList', {
                                        uuids: uuids,
                                        userId: user.userId
                                    }).then(response => {
                                        let countPresetFolderLayer = 0;
                                        const layers = response.data.data.layers;
                                        const presetIdArr = presetIds != undefined ? presetIds.split(",") : [];
                                        const meshesArr = meshes.split(";");
                                        
                                        for(let layer of layers){
                                            layersPrice[layer.layerId] = layer.price;
                                            layersLabel[layer.layerId] = layer.label;
                                            layersTag[layer.layerId] = layer.tag;
                                            layersDisplayName[layer.layerId] = layer.displayName;
                                        }

                                        for(let i = 0 ; i < presetIdArr.length; i ++){
                                            layersMaterial[meshesArr[i]] = presetIdArr[i];//layer.materialId;
                                        }

                                        let countMaterials = 0;
                                        for(const layer of materialsLibrary)
                                        {
                                            countMaterials++;
                                            if(layer.material.visible)
                                            {
                                                layersVisiblePrice[layer.params.id] = layersPrice[layer.params.id] ? layersPrice[layer.params.id] : 0;
                                                totalMaterialLayer ++;
                                                if(totalMaterialLayer == 1){
                                                    firstMaterialLayerId = layer.params.id;
                                                }
                                                for(let mesh of layer.mesh){
                                                    if(api.getAudio(mesh)){
                                                        document.getElementById("audioPlay").style.display = "block";
                                                    }
                                                }
                                                
                                                chosenLayer = layer.params.id;
                                                document.getElementById("chosenLayer").value = chosenLayer;
                                                api.highlightObjects.apply(api, layer.mesh)
            
                                                let selectList = document.createElement("div");
                                                selectList.style.display = "none";
                                                selectList.setAttribute("id", layer.params.id);
                                                selectList.setAttribute("configurator-select-layer-id", layer.params.id);
                                                selectList.className = "configurator-select-layer";
                                                // selectList.style.setProperty("padding", "20px", "important");
                                                let layerName = layersDisplayName[layer.params.id] ? layersDisplayName[layer.params.id] : layer.params.name.replace(/_/g, ' ').replace(/-/g, ' ');
                                                layerName = (isMobile ? commonFunction.truncate(layerName, 15) : layerName);// + " - $" + (layersPrice[layer.params.id] ? parseFloat(Number(layersPrice[layer.params.id]), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() : 0);
                                                if(layersLabel != undefined && layersLabel[layer.params.id]){
                                                    selectList.innerHTML  = "<img class='layer-label' src='"+layersLabel[layer.params.id]+"' width='20px' width='20px' />" + layerName;
                                                }else
                                                {
                                                    selectList.innerHTML  = layerName;
                                                }
                                                
                                                layerList.appendChild(selectList);
                                                materialsLibraryLocal.push({
                                                    'visible': true,
                                                    'data': layer,
                                                    'preset': null,
                                                    'layer': layer.params.id,
                                                    'name': layer.params.name.replace(/_/g, ' ').replace(/-/g, ' ')
                                                });
                                                
                                            }else{
                                                hiddenMaterialsLibraryLocal.push(layer);
                                            }

                                            materialsForQuotation.push({
                                                'visible': layer.material.visible,
                                                'data': layer,
                                                'preset': null,
                                                'layer': layer.params.id,
                                                'name': layer.params.name.replace(/_/g, ' ').replace(/-/g, ' ')
                                            });
            
                                            
                                            axios.post('preset/get-preset-folder-layer-from-conf', {
                                                layerUuid: layer.params.id,
                                                userId: user.userId}).then(response2 =>{
                                                    if(response2.data.data.presetFolder != null)
                                                    {
                                                        countPresetFolderLayer ++;
                                                        //Create Dropdown Presets
                                                        let presetFolder = response2.data.data.presetFolder;


                                                        const newObject = {
                                                            layerId: layer.params.id,
                                                            presetFolder: presetFolder
                                                          };
                                                          
                                                          setLayerPresetFoldersReact(prevState => {
                                                            // Check if the layerId already exists
                                                            if (prevState.some(item => item.layerId === newObject.layerId)) {
                                                              return prevState; // Return the unchanged array if it exists
                                                            } else {
                                                              return [...prevState, newObject]; // Add the newObject if it doesn't exist
                                                            }
                                                          });


                                                        if (!document.getElementById(presetFolder.uuid)) {
                                                            let presetPanelId = "panelPresets";
                                                            if(isMobile){
                                                                presetPanelId = "panelPresetsMobile";
                                                                //Hide Colors panel
                                                                let panels = document.getElementsByClassName("panel-combination-list");
                                                                panels[2].style.setProperty("max-width", "100%", "important");
                                                                panels[2].style.setProperty("margin-top", "0px", "important");
                                                                panels[2].style.setProperty("width", "100%", "important");
                                                                panels[2].firstElementChild.style.setProperty("display", "none", "important");
    
    
                                                                panels[3].style.display = "none";
    
                                                                panels[0].style.maxWidth = "50%";
                                                                // panels[0].style.setProperty("padding-right", "0px", "important");
    
    
                                                                panels[1].style.maxWidth = "50%";
                                                                panels[1].style.setProperty("padding-right", "0px", "important");
                                                                document.getElementsByClassName("quotation-div-mobile-mode")[0].style.width = "100%";
                                                                document.getElementById("quotation-btn-mobile").style.setProperty("margin-left", "0px", "important");
                                                                document.getElementById("quotation-btn-mobile").style.setProperty("width", "100%", "important");
                                                                document.getElementById("layerList").style.setProperty("margin-left", "0px", "important");
                                                                
    
                                                                document.getElementById(presetPanelId).style.width = "100%";
                                                            }
                                                            let presetPanel = document.getElementById(presetPanelId);
                                                            // let selectList = document.createElement("select");
                                                            let selectList = document.createElement("div");
    
                                                            if(isMobile) selectList.style.width = "100%";
                                                            if(isMobile) selectList.style.setProperty("margin-left", "0px", "important");
                                                            if(isMobile) selectList.style.setProperty("margin-top", "0px", "important");
                                                            if(isMobile) selectList.style.setProperty("border-top", "1px solid #cccccc", "important");
                                                            if(isMobile) selectList.style.setProperty("border-bottom", "1px solid #cccccc", "important");
    
                                                            if(isMobile) selectList.style.setProperty("white-space", "nowrap", "important");
                                                            if(isMobile) selectList.style.setProperty("overflow-x", "auto", "important");
                                                            if(isMobile) selectList.style.setProperty("overflow-y", "hidden", "important");
                                                            
                                                            selectList.style.height = 85+'px';
                                                            selectList.style.overflow = 'auto';
                                                            selectList.style.display = "none";
                                                            selectList.setAttribute("id", presetFolder.uuid);
                                                            selectList.className = "panel-combination-select form-control  configurator-preset-list";
                                                            presetPanel.appendChild(selectList);
                                                            let index = 0;
                                                            
                                                            //selectList[index] = new Option("- Select -", "choose");
                                                            index++;
                                                            let subDiv = '';
                                                            for(const preset of presetFolder.data)
                                                            {
                                                                if(preset.name)
                                                                {
                                                                    let priceText = " - Price(multiply with): " + (preset.price ? parseFloat(Number(preset.price), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString(): 0);
                                                                    let text = preset.name;
                                                                    let val = !JSON.stringify(preset.data).includes("googleapis") ? JSON.stringify(preset.data) : ("googleapis:" + preset.uuid)
                                                                    let price = preset.price ? preset.price : 0;
                                                                    let uuid = preset.uuid;
                                                                    let display = "inline-block";
                                                                    if(isMobile) display = "inline-block";
                                                                    let textDisplay = "inline-block";
                                                                    if(isMobile) textDisplay = "none";
                                                                    if(preset.label || preset.labelColor){
                                                                        if(preset.label){
                                                                            if(isMobile) selectList.style.width = "100%";
                                                                            subDiv += `<div data-name="${text}" id=${uuid} data-price=${price} style="display: ${display}" class='preset-list' data-value='${val}' > 
                                                                            <div title="${text}" class="label-image" style="cursor: pointer; 
                                                                            
                                                                            width: 45px; height: 42px;
                                                                            border-radius: 10px; 
                                                                            display: inline-block; background-image: url('${preset.label}');
                                                                            background-position: top center!important;
                                                                            background-size: cover!important; 
                                                                            height: 40px;
                                                                            width: 40px;
                                                                            "></div>
                                                                            <div class="label-image-text" style="cursor: pointer; display: none;">${text}</div>
                                                                            </div>`;
                                                                        }
                                                                        else if(preset.labelColor){
                                                                            subDiv += `<div data-name="${text}" id=${uuid} data-price=${price}  style="width: 45px; height: 42px;  display: ${display}" class='preset-list ' data-value='${val}'> 
                                                                            <div class="label-color" style="cursor: pointer; border-radius: 10px; width: 20px; height: 20px; display: inline-block; background-color: ${preset.labelColor}"></div>
                                                                            <div class="label-color-text" style="cursor: pointer; display: none;">${text}</div>
                                                                            </div>`;
                                                                        }
                                                                    }else{
                                                                        subDiv += `<div data-name="${text}" id=${uuid} data-price=${price} style="width: 45px; height: 42px;  display: ${display}" class='preset-list ' data-value='${val}'> 
                                                                        <div style="cursor: pointer; border-radius: 10px; width: 20px; height: 20px; display: inline-block; "></div>
                                                                        <div style="cursor: pointer; display: ${textDisplay};">${text}</div>
                                                                        </div>`;
                                                                    }
                                                                }
                                                            }
                                                            selectList.innerHTML = subDiv;
        
                                                            document.addEventListener('click',function(e){
                                                                if(
                                                                    (e.target.parentNode.parentNode && e.target.parentNode.parentNode.id == presetFolder.uuid) 
                                                                    &&
                                                                    e.target && e.target.parentNode.classList.contains('preset-list')){
                                                                    if(e.target.value == "choose") return false;
                                                                    let chosenLayer = document.getElementById("chosenLayer").value;
                                                                    for(const layer of materialsLibrary)
                                                                    {
                                                                        if(layer.params.id == chosenLayer)
                                                                        {
                                                                            let listPresetDom = document.getElementsByClassName("preset-list ");
                                                                            for(let presetDom of listPresetDom){
                                                                                presetDom.classList.remove("choosen-preset-css");
                                                                            }
                                                                            e.target.parentNode.classList.add("choosen-preset-css");
        
                                                                            beforeApplyColor(api, e.target.parentNode.getAttribute("data-value"), layer, e);

                                                                            
    
                                                                            let a = layersPrice[layer.params.id];
                                                                            let b = e.target.parentNode.getAttribute("data-price");
                                                                            let c = Number(a) * Number(b);
                                                                            layersVisiblePrice[layer.params.id] = c;//layersPrice[layer.params.id] ? Number(layersPrice[layer.params.id]) : 0 * Number(e.target.parentNode.getAttribute("data-price"));
                                                                            
                                                                        }
                                                                    }
                                                                    reCalculateTotalPrice();
                                                                }
                                                            });
                                                        } 
        
        
                                                        let presetFolderUuid = presetFolder.uuid;
                                                        presetFolderLayer.push({
                                                            'layerUuid': layer.params.id,
                                                            'presetFolderUuid': presetFolderUuid,
                                                            'presetFolderName': presetFolder.name,
                                                            'presets': presetFolder.data
                                                        });
                                                        document.getElementById("presetFolderLayer").value = JSON.stringify(presetFolderLayer);
        
                                                        if(index == materialsLibrary.filter(m => m.material.visible == true).length)
                                                        {
                                                            //Open relational Preset Folder\
                                                            let chosenLayer = document.getElementById("chosenLayer").value;
                                                            if(layer.params.id == chosenLayer)
                                                            {
                                                                if(document.getElementById(presetFolderUuid) != null)
                                                                {
                                                                    document.getElementById(presetFolderUuid).style.display = "block";
                                                                    document.getElementById(presetFolderUuid).setAttribute("size",document.getElementById(presetFolderUuid).length);
                                                                }
                                                            }
                                                        }
        
                                                        document.getElementById(firstMaterialLayerId).click();
                                                        if(document.getElementById(layer.params.id) !=null){
                                                            document.getElementById(layer.params.id).style.display = "block";
                                                        }
                                                        layerHavePresetFolder.push(layer.params.id);
                                                    }else{
                                                        document.getElementById(layer.params.id).style.display = "none";
                                                        materialsLibraryLocal = materialsLibraryLocal.filter(function(material) {
                                                            return material.layer != layer.params.id;
                                                        });
                                                        materialsForQuotation = materialsForQuotation.filter(function(material) {
                                                            return material.layer != layer.params.id;
                                                        });
                                                    }
                                            });
                                        }
                                        reCalculateTotalPrice();

                                        let intervalPresetFolderLayer = setInterval(() => {
                                            if((countPresetFolderLayer == layerHavePresetFolder.length) && layerHavePresetFolder.length != 0 && countMaterials == materialsLibrary.length){
                                                clearInterval(intervalPresetFolderLayer);
                                                setTimeout(() => {
                                                    for(const layer of materialsLibrary)
                                                    {
                                                        if(layer.params.visible && document.getElementById(layersMaterial[layer.params.id]) != undefined){
                                                            let target = document.getElementById(layersMaterial[layer.params.id]);
                                                            for(let presetLayer of materialsLibraryLocal)
                                                            {
                                                                for(let presetFolder of presetFolderLayer){
                                                                    if(presetFolder.layerUuid == presetLayer.layer && presetLayer.layer == layer.params.id){
                                                                        presetLayer.preset = target.getAttribute("data-value");
                                                                        presetLayer.presetId = target.getAttribute("id");
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        
                                                        if (document.getElementById(layersMaterial[layer.params.id]) != undefined) {
                                                            let target = document.getElementById(layersMaterial[layer.params.id]);
                                                            for(let presetLayer of materialsForQuotation)
                                                            {
                                                                for(let presetFolder of presetFolderLayer){
                                                                    if(presetFolder.layerUuid == presetLayer.layer && presetLayer.layer == layer.params.id){
                                                                        presetLayer.preset = target.getAttribute("data-value");
                                                                        presetLayer.presetId = target.getAttribute("id");
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    document.getElementById(firstMaterialLayerId).click();
                                                }, 1000);
                                            }
                                        }, 500);
                                    });
                                }, 500);
                            });
    
                            commonFunction.uploadGLBToLeo(api, viewerUrl, 'configurators');
                        }
                        ,function ( xhr ) {
                            if(xhr.loaded >= xhr.total)
                            {
                                // document.getElementById("myLoader2").style.display = "none";
                                // document.getElementById("static-img").style.display = "none";
                                // document.getElementById("canvas").style.display = "block";
                                // setState({
                                //     ...value,
                                //     canvasHeight: canvas.current.clientHeight,
                                // });
                                
                            }else{
    
                            }
                            let xhrTotal = 0;
                            if(comp.originalFileSize){
                                xhrTotal = comp.originalFileSize;
                            }else{
                                xhrTotal = xhr.total;
                            }

                            var per = xhr.loaded / xhrTotal * 100;
                            if(per > 90){
                                per = 90;
                            }
                            

                            document.getElementById("myBar").className = "c100 p" + Math.ceil(per) + " big";
                            document.getElementById("myBar-text").innerHTML = Math.ceil(per) + '%' ;
                            // var fadeTarget = document.getElementById("static-img-overlay");
                            var fadeTarget = document.getElementById("static-img");
                            var opa = (100 - xhr.loaded / xhrTotal * 100)/10;
                            // fadeTarget.style.filter  = 'blur('+opa+'px)';
                            // fadeTarget.style.filter  = 'blur(40px)';
                        },
                    
                        // onError callback
                        function ( err ) {
                            console.error( 'An error happened' );
                        } 
                        )
    
                        
                    }
    
                    if(window.innerWidth < window.innerHeight){
                        document.getElementById('fakeLoadBackground').innerText = "Background";
                        document.getElementById('btnScreenshot').innerText = "Screenshot";
                        document.getElementById('lblHideLayer').innerText = "Hide";
                        document.getElementById('lblRotate').innerText = "Rotate";
                    }
    
                    //Setting mouse and touch events on canvas
                    // document.getElementById('combinationList').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
    
                    document.getElementById('canvas').addEventListener('mousemove', (e) => onPointerMove(e, api, false, userInfo));
                    document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerMove(e, api, true, userInfo));
                    document.getElementById('tooltip-span').addEventListener('mouseup', (e) => onPointerUp2(e, api, props, false, userInfo));
                    document.getElementById('canvas').addEventListener('mouseup', (e) => onPointerUp(e, api, props, materialsLibraryLocal, false, userInfo));
                    document.getElementById('canvas').addEventListener('touchend', (e) => onPointerUp(e, api, props, materialsLibraryLocal, true, userInfo));
                    document.getElementById('canvas').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('canvas').addEventListener('touchstart', (e) => onPointerDown(e, api, props));
                    document.getElementById('canvas').addEventListener('wheel', (e) => onPointerWheel(e, api, props));
                    document.getElementById('canvas').addEventListener('touchmove', (e) => onPointerWheel(e, api, true, userInfo));
                    window.addEventListener("resize", ev => {
                        // api.setViewerSize(document.getElementById('canvas').clientWidth, document.getElementById('canvas').clientHeight);
                        api.setViewerSize(window.innerWidth, window.innerHeight);
    
                        commonFunction.setDefaultRatio(api);
                    }, false);
    
    
                    document.getElementById('changeBackgroundIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('changeBackgroundIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('screenshotIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('screenshotIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
    
                    document.getElementById('autoRotateIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('autoRotateIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('helperIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('helperIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('fullScreenIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('fullScreenIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('animationPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('animationPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('audioPlay').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('audioPlay').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
                    document.getElementById('annotationIcon').addEventListener('mouseup', (e) => onPointerUp(e, api, props, false, userInfo));
                    document.getElementById('annotationIcon').addEventListener('mousedown', (e) => onPointerDown(e, api, props));
    
                    document.getElementById('canvas').addEventListener('dblclick', (e) => onPointerDownDouble(e, api, props));
    

                    function selectLayer(e){
                        let layerType = "standard";
                        for(const layer of materialsLibrary)
                        {
                            if(layer.params.id == chosenLayer)
                            {
                                currentTag = (layersTag != undefined && layersTag[layer.params.id]) ? layersTag[layer.params.id] : '';
                                if(layer.params.isDiamond){
                                    layerType = "diamond";
                                }
                                api.highlightObjects.apply(api, layer.mesh)
                                let p = {edgeStrength: 10};
                                api.getTweener(p)
                                        .to({edgeStrength: 0.0}, 0*1000)
                                        .onUpdate(()=> api.setOutlineParams(p))
                                        .start();
                                document.getElementById("chosenLayer").value = chosenLayer;
                                let chk = document.getElementById('layerVisible');
                                chk.checked = !layer.params.visible;
            
                                //Open relational Preset Folder
                                let presetFolderUuid = '';
                                for(const fl of presetFolderLayer)
                                {
                                    if(document.getElementById(fl.presetFolderUuid) != null)
                                    {
                                        document.getElementById(fl.presetFolderUuid).style.display = "none";
                                        if(fl.layerUuid == chosenLayer)
                                        {
                                            presetFolderUuid = fl.presetFolderUuid;
                                        }
                                    }
                                }
                                if(document.getElementById(presetFolderUuid) != null)
                                {
                                    document.getElementById(presetFolderUuid).style.display = "block";
                                }

                                let presetsDom = document.getElementsByClassName("preset-list")
                                for(let preset of presetsDom){
                                    preset.classList.remove("choosen-preset-css");
                                }
                                for(let a of materialsLibraryLocal){
                                    if(a.layer == layer.params.id && a.preset != null){
                                        document.getElementById(a.presetId).classList.add("choosen-preset-css");
                                    }
                                }

                            }
                        }


                        //Load Hidden Lyaer that have same type (Diamond, Material)
                        let hiddenLayerList = document.getElementById("hiddenLayerList");
                        if(hiddenLayerList){
                            hiddenLayerList.innerHTML = "";
                            hiddenLayerList.style.overflow = 'auto';
                            for(let layer of hiddenMaterialsLibraryLocal){
                                // if((layerType == "diamond" && layer.params.isDiamond) || (layerType == "standard" && !layer.params.isDiamond)){
                                    if(layersTag != undefined && layersTag[layer.params.id] && currentTag == layersTag[layer.params.id]){
                                        let selectList = document.createElement("div");
                                        selectList.setAttribute("id", layer.params.id);
                                        selectList.style.cursor = "pointer"
                                        selectList.style.width = "30px"
                                        selectList.style.height = "30px"
                                        selectList.style.float = "left"
                                        selectList.style.margin = "10px 10px 10px 10px"
                                        selectList.style.display = "inline-block"

                                        
                                        let labelDiv = document.createElement("div");
                                        labelDiv.className = "configurator-select-hidden-layer";
                                        labelDiv.setAttribute("configurator-select-layer-id", layer.params.id);
                                        labelDiv.setAttribute("title", layersDisplayName[layer.params.id] ? layersDisplayName[layer.params.id] : layer.params.name);
                                        labelDiv.style.width = "30px"
                                        labelDiv.style.height = "30px"
                                        if(layersLabel != undefined && layersLabel.hasOwnProperty(layer.params.id)){
                                            if(layersLabel[layer.params.id]){
                                                labelDiv.style.backgroundImage = "url('"+layersLabel[layer.params.id]+"')";
                                            }
                                        }
                                        selectList.appendChild(labelDiv);
    
                                        hiddenLayerList.appendChild(selectList);
                                    }
                                // }
                            }
                            if(hiddenLayerList.innerHTML == ""){
                                document.getElementById("swapLayerTitle").style.display = "none";
                            }else{
                                let isMobile = window.innerWidth < window.innerHeight;
                                if(!isMobile){
                                    document.getElementById("swapLayerTitle").style.display = "block";
                                }
                            }
                        }
                    }

                    document.addEventListener('click',function(e){
                        if (e.target && e.target.classList.contains('configurator-select-hidden-layer')) {
                            // document.getElementById("myLoader").style.display = "block";
                            
                            layerHavePresetFolder.push(e.target.getAttribute("configurator-select-layer-id"));
                            setTimeout(() => {
                                for(const layer of materialsLibrary)
                                {
                                    //Hide current layer
                                    if(layer.params.id == chosenLayer)
                                    {
                                        // layer.visible = !layer.visible;
                                        layer.params.visible = false;
                                        api.importMaterialParams(layer.params).then(() => {
                                            try{
                                                api.setMaterialParams(layer.mesh[0], layer.params)
                                            }
                                            catch (error) {
                                                    console.log(error);
                                            }
                                        })
                                    }
                                }

                                for (const layer of materialsForQuotation) {
                                    if (layer.layer == chosenLayer) {
                                        layer.visible = false;
                                    }
                                    if (layer.layer == e.target.getAttribute("configurator-select-layer-id")) {
                                        layer.visible = true;
                                    }
                                }
                                
                                for(const layer of materialsLibrary)
                                {
                                    //Show selected layer
                                    if(layer.params.id == e.target.getAttribute("configurator-select-layer-id"))
                                    {
                                        // layer.visible = !layer.visible;
                                        layer.params.visible = true;
                                        api.importMaterialParams(layer.params).then(() => {
                                            try{
                                                api.setMaterialParams(layer.mesh[0], layer.params)
                                            }
                                            catch (error) {
                                                    console.log(error);
                                            }
                                        })

                                    }
                                }
                                
                                setTimeout(() => {
                                    hiddenMaterialsLibraryLocal = [];
                                    materialsLibraryLocal = [];
                                    materialsLibrary = api.getMaterialLibrary();
                                    materialsLibrary = materialsLibrary.sort(commonFunction.sortLayerName);
                                    let layerList = document.getElementById("layerList");
                                    layerList.innerHTML = "";
                                    layersVisiblePrice = {};
                                    for(let layer of materialsLibrary){
        
                                        if(layer.material.visible)
                                        {
                                            if(layerHavePresetFolder.includes(layer.params.id)){
                                                layersVisiblePrice[layer.params.id] = layersPrice[layer.params.id];
                                                let selectList = document.createElement("div");
                                                selectList.setAttribute("id", layer.params.id);
                                                selectList.setAttribute("configurator-select-layer-id", layer.params.id);
                                                selectList.className = "configurator-select-layer";
                                                let layerName = layersDisplayName[layer.params.id] ? layersDisplayName[layer.params.id] :  layer.params.name.replace(/_/g, ' ').replace(/-/g, ' '); // + " - $" + (layersPrice[layer.params.id] ? parseFloat(Number(layersPrice[layer.params.id]), 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() : 0);
                                                if(layersLabel != undefined && layersLabel[layer.params.id]){
                                                    selectList.innerHTML  = "<img class='layer-label' src='"+layersLabel[layer.params.id]+"' width='20px' width='20px' />" + layerName;
                                                }else{
                                                    selectList.innerHTML  =  layerName;
                                                }
                                                
                                                layerList.appendChild(selectList);
                                                materialsLibraryLocal.push({
                                                    'visible': true,
                                                    'data': layer,
                                                    'preset': null,
                                                    'presetId': null,
                                                    'layer': layer.params.id,
                                                    'name': layerName //layer.params.name.replace(/_/g, ' ').replace(/-/g, ' ')
                                                });
                                            }
                                        }else{
                                            hiddenMaterialsLibraryLocal.push(layer);
                                        }
                                    }
                                    
                                    
                                    reCalculateTotalPrice();
                                    chosenLayer = e.target.getAttribute("configurator-select-layer-id");
                                    selectLayer(e);
                                    // document.getElementById("myLoader").style.display = "none";
                                }, 0);
                            }, 500);
                            
                        }
                    });

                    document.addEventListener('click',function(e){
                        if(e.target && e.target.classList.contains('layer-label')){
                            let listLayerDom = document.getElementsByClassName("configurator-select-layer");
                            for(let layerDom of listLayerDom){
                                layerDom.classList.remove("choosen-preset-css");
                            }
                            e.target.parentNode.classList.add("choosen-preset-css");
                            
                            chosenLayer = e.target.parentNode.getAttribute("configurator-select-layer-id");
                            selectLayer(e.target.parentNode);
                        }
                    });

                    document.addEventListener('click',function(e){
                        if(e.target && e.target.classList.contains('configurator-select-layer')){
                            let listLayerDom = document.getElementsByClassName("configurator-select-layer");
                            for(let layerDom of listLayerDom){
                                layerDom.classList.remove("choosen-preset-css");
                            }
                            e.target.classList.add("choosen-preset-css");
                            
                            chosenLayer = e.target.getAttribute("configurator-select-layer-id");
                            selectLayer(e);
                        }
                    });
                    
                    document.getElementById('screenshotIcon').addEventListener('click', function(e) {
                        let img = api.getSnapshotData("image/jpg")
                        let link = document.createElement("a");
                        link.download = "screenshot.jpg";
                        link.href = img;
                        const body = document.getElementsByTagName("body")[0];
                        body.appendChild(link);
                        link.click();
                        body.removeChild(link);
                    });
    
                    
                    function exitHandler()
                    {
                        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
                        if(fullscreenElement == null){
                            let a = originalCanvasHeight + "px";
                            document.getElementById("configurators-panel-area").style.height = a;
                            isFullScreen = false;
                        }
                    }
    
                    document.addEventListener('fullscreenchange', exitHandler, false);
                    document.addEventListener('mozfullscreenchange', exitHandler, false);
                    document.addEventListener('MSFullscreenChange', exitHandler, false);
                    document.addEventListener('webkitfullscreenchange', exitHandler, false);
                    document.getElementById('fullScreenIcon').addEventListener('click', function(e) {
                        if(!isFullScreen){
                            var elem = document.documentElement;
                            if (elem.requestFullscreen) {
                            elem.requestFullscreen();
                            } else if (elem.webkitRequestFullscreen) { /* Safari */
                            elem.webkitRequestFullscreen();
                            } else if (elem.msRequestFullscreen) { /* IE11 */
                            elem.msRequestFullscreen();
                            }
                            document.getElementById("configurators-panel-area").style.height = "2000px";
                            isFullScreen = true;
                        }else{
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.webkitExitFullscreen) { /* Safari */
                                document.webkitExitFullscreen();
                            } else if (document.msExitFullscreen) { /* IE11 */
                                document.msExitFullscreen();
                            }
                            isFullScreen = false;
                        }
                    });
    
                    document.getElementById('animationPlay').addEventListener('click', function(e) {
                        if(document.getElementById('animationPlay').childNodes[0].src == play){
                            api.playAllAnimations();
                            api.playAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_on;
                            document.getElementById('animationPlay').childNodes[0].src = stop;
                            document.getElementById('animationPlay').title = "Stop Animation";
                            document.getElementById('animationPlay').setAttribute('data-tooltip', 'Stop Animation');
                        }
                        else{
                            api.stopAllAnimations();
                            api.stopAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_off;
                            document.getElementById('animationPlay').childNodes[0].src = play;
                            document.getElementById('animationPlay').title = "Play Animation";
                            document.getElementById('animationPlay').setAttribute('data-tooltip', 'Play Animation');
                        }
                    });
    
                    document.getElementById('audioPlay').addEventListener('click', function(e) {
                        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
                            try {
                                api.stopAllSounds();
                            } catch (error) {
                            }
                            document.getElementById('audioPlay').childNodes[0].src = sound_off;
                            document.getElementById('audioPlay').title = "UnMute";
                            document.getElementById('audioPlay').setAttribute('data-tooltip', 'UnMute');
                        }
                        else{
                            api.playAllSounds();
                            document.getElementById('audioPlay').childNodes[0].src = sound_on;
                            document.getElementById('audioPlay').title = "Mute";
                            document.getElementById('audioPlay').setAttribute('data-tooltip', 'Mute');
                        }
                    });
    
                    document.getElementById('autoRotateIcon').addEventListener('click', function(e) {
                        let cameraParams = api.getCameraParams();
                        let orbitP = cameraParams.orbitParams;
                        api.setCameraParams({orbitParams: {autoRotate: !orbitP.autoRotate}})
                        
                        document.getElementById("btnRotate").checked = !orbitP.autoRotate;
                        if(document.getElementById('autoRotateIcon').childNodes[0].src == auto_rotate){
                            document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate_stop;
                            document.getElementById('autoRotateIcon').setAttribute('data-tooltip', 'Auto Rotate');
                        }else{
                            document.getElementById('autoRotateIcon').childNodes[0].src = auto_rotate;
                            document.getElementById('autoRotateIcon').setAttribute('data-tooltip', 'Stop Auto Rotate');
                        }
                    });
    
                    document.getElementById('annotationIcon').addEventListener('click', function(e) {
                        if(document.getElementById('annotationIcon').childNodes[0].src == annotation_on){
                            document.getElementById('annotationIcon').childNodes[0].src = annotation_off;
                            api.annotationsEnabled = false;
                        }else{
                            document.getElementById('annotationIcon').childNodes[0].src = annotation_on;
                            api.annotationsEnabled = true;
                        }
                    });

                    document.getElementById('close3DViewer').addEventListener('click', function(e) {
                        var modal3DViewer = document.getElementById("modal3DViewer");
                        modal3DViewer.style.display = "none";
                        document.getElementById('configurator-main-content').style.opacity = "100%";
                    });

                    document.getElementById('measurementIcon').addEventListener('click', function(e) {
                        document.getElementById('modal3DViewer').style.display = "block";
                        document.getElementById('configurator-main-content').style.opacity = "20%";
                    })
    
                    
    
                    document.getElementById('btnRotate').addEventListener('click', function(e) {
                        let cameraParams = api.getCameraParams();
                        let orbitP = cameraParams.orbitParams;
                        api.setCameraParams({orbitParams: {autoRotate: !orbitP.autoRotate}})
                    });
    
                    document.getElementById('btnScreenshot').addEventListener('mouseover', function(e) {
                        document.getElementById("btnScreenshot").src = cameraOn
                    });
    
                    document.getElementById('btnScreenshot').addEventListener('mouseout', function(e) {
                        document.getElementById("btnScreenshot").src = cameraOn
                    });
    
                    document.getElementById('btnRotate').addEventListener('mouseover', function(e) {
                        document.getElementById("btnRotate").src = rotateOn
                    });
    
                    document.getElementById('btnRotate').addEventListener('mouseout', function(e) {
                        document.getElementById("btnRotate").src = rotateOn
                    });
    
                    // document.getElementById('btnAR').addEventListener('mouseover', function(e) {
                    //     document.getElementById("btnAR").src = arOff
                    // });
    
                    // document.getElementById('btnAR').addEventListener('mouseout', function(e) {
                    //     document.getElementById("btnAR").src = arOn
                    // });
    
                    document.getElementById('layerVisible').addEventListener('click', function(e) {
                        let chosenLayer = document.getElementById("chosenLayer").value;
                        for(let layer of materialsLibraryLocal)
                        {
                            if(layer.data.params.id == chosenLayer)
                            {
                                layer.visible = !layer.visible;
                                layer.data.params.visible = layer.visible;
                                api.importMaterialParams(layer.data.params).then(() => {
                                    try{
                                        api.setMaterialParams(layer.data.mesh[0], layer.data.params)
                                    }
                                    catch (error) {
                                            console.log(error);
                                    }
                                })
                            }
                        }
                    });
    
                    document.getElementById('changeBackgroundIcon').addEventListener('click', () => {
                        document.getElementById('loadBackground').click()
                    })
    
                    // document.getElementById('fakeLoadBackground2').addEventListener('click', () => {
                    //     document.getElementById('loadBackground').click()
                    // })
    
                    document.getElementById('hambuger-configurator').addEventListener('click', () => {
                        document.getElementById('configurators-panel-area').style.display = "block";
                        document.getElementById('configurators-panel-area').style.position = "absolute";
                        document.getElementById('configurators-panel-area').style.top = 0;
                    })
    
                    document.getElementById('loadBackground').addEventListener('change', function(e) {
                        const files = Array.from(e.target.files)
                        let file = Array.isArray(files) ? files[0] : files;
                        if (file) {
                            api.setBackgroundFile(file, {
                                setEnvironment: false
                            })
                        }
                    })

                    document.getElementById('panel-close-mobile-button').addEventListener('click', function(e) {
                        var x = document.getElementById("hambuger-configurator");
                        if (window.getComputedStyle(x).display === "block") {
                            document.getElementById('configurators-panel-area').style.display = "none";
                        }
                    })
    
                    

                    document.addEventListener('click',function(e){
                        if(e.target && e.target.classList.contains('configurator-select-variation')){
                            let urlArr = window.location.href.split("/");
                            urlArr[urlArr.length - 1] = e.target.getAttribute("uuid");
                            let newUrl = urlArr.join('/');
                            window.location.href = newUrl;
                            // let listVariationDom = document.getElementsByClassName("configurator-select-variation");
                            // for(let variationDom of listVariationDom){
                            //     variationDom.classList.remove("choosen-preset-css");
                            // }

                            // e.target.classList.add("choosen-preset-css");
                            // document.getElementById("myLoader2").style.display = "block";
                            // document.getElementById("static-img").style.display = "block";
                            // document.getElementById("canvas").style.display = "none";
        
                            // document.querySelectorAll('.configurator-preset-list').forEach(e => e.remove());
        
                            // api.stopAllSounds();
                            // initial(e.target.getAttribute("data-path"), userOwned);
        
                            // combinations.map(combination => {
                            //     if(combination.path == e.target.getAttribute("data-path"))
                            //     {
                            //         document.getElementById('combinationPrice').value = combination.price != undefined ? commonFunction.convertPriceToString(combination.price, currencyLocal) : 0;
                            //         document.getElementById('combinationDesc').value = combination.description != undefined ? combination.description : '';
                                    
                            //         variationPrice = combination.price != undefined ? commonFunction.convertPriceToString(combination.price, currencyLocal) : 0;
                            //         variationDesc = combination.description != undefined ? combination.description : '';
                            //         variationName = combination.name;
                            //         variationPriceOriginal = combination.price;
        
                            //         currentCombinationUrl = window.location.protocol+'//'+window.location.hostname + '/configurators/' + combination.uuid;
                            //         currentCombinationName = combination.name;
                            //         // commonFunction.currentCombinationName = currentCombinationName;
                            //         if(document.getElementById('urlClicking') != null){
                            //             document.getElementById('urlClicking').innerHTML = currentCombinationName;
                            //         }
                            //     }
                            // });
                        }
                    });
                
            }, timeTimeout);
            
        }
        
    }

    useEffect(() => {
        // let isMobile = false;
        // let isAppleGPU = false;
        // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform) ) {
        //     isMobile = true;
        // }
        // const iPad = (navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ || 
        //     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);
        // if(iPad) isMobile = true;
        // if(firstLoad){
        //     testGPU();
        //     if(gpu && firstLoad){
        //         setFirstLoad(false);
        //         if(gpu.gpu == "apple gpu (Apple GPU)") isAppleGPU = true;
        //         if(gpu.tier >= 2 || isMobile || isAppleGPU){
        //             coreFeature();
        //         }else{
        //             setShowHardwareModal(true);
        //         }
        //     }
        // }
        coreFeature();
        
    }, [gpu])

    useEffect(() => {
            switchCases(props, API)
    })

    const openCheckout = () => {
        setState({
            ...value,
            checkoutShow: true,
            variationName: variationName,
            variationDesc: variationDesc,
            variationPrice: variationPrice,
            variationPriceOriginal: variationPriceOriginal
        });
    }
    const handleCheckoutClose = () => {
        setState({
            ...value,
            checkoutShow: false
        });
    }
    const handleCheckoutSuccessClose = () => {
        setState({
            ...value,
            checkoutSuccessShow: false
        });
    }

    const openQuotation = () => {

        materialsLibraryLocal.map(function(material, index) {
            presetFolderLayer.map(function(presetFolder, index) {
                presetFolder.presets.map(function(preset, index) {
                    if(material.preset){
                        if(material.preset == JSON.stringify(preset.data)){
                            material.presetName = preset.name;
                        }else{
                            //Check if Texture
                            if(material.preset.includes("googleapis")){
                                //This is Texture
                                let jsonFile = material.preset.split(":")[1];
                                if(JSON.stringify(preset.data).includes(jsonFile)){
                                    material.presetName = preset.name;
                                }
                            }
                        }
                    }
                });
            });
        });
        let layersShowOnQuotation = [];
        materialsForQuotation.map(function(material, index) {
            presetFolderLayer.map(function(presetFolder, index) {
                presetFolder.presets.map(function(preset, index) {
                    if(material.preset){
                        if(material.preset == JSON.stringify(preset.data)){
                            material.presetName = preset.name;
                        }else{
                            //Check if Texture
                            if(material.preset.includes("googleapis")){
                                //This is Texture
                                let jsonFile = material.preset.split(":")[1];
                                if(JSON.stringify(preset.data).includes(jsonFile)){
                                    material.presetName = preset.name;
                                }
                            }
                        }
                    }
                });
            });
        });

        layersShowOnQuotation = materialsForQuotation.filter(function(material) {
            return material.preset != null;
        });

        let isMobile = window.innerWidth < window.innerHeight;
        
        setState({
            ...value,
            quotationPCShow: !isMobile,
            quotationMobileShow: isMobile,
            variationName: variationName,
            variationDesc: variationDesc,
            variationPrice: variationPrice,
            variationPriceOriginal: variationPriceOriginal,
            // materialsLibrary: materialsLibraryLocal,
            materialsLibrary: layersShowOnQuotation, //materialsForQuotation,
            presetFolderLayer: presetFolderLayer
        });
    }


    const handleQuotationClose = () => {
        setState({
            ...value,
            quotationPCShow: false,
            quotationMobileShow: false,
        });
    }

    const onChangeQuotationEmail = e => {
        setState({
            ...value,
            quotationEmail: e.target.value,
        });
    }
    const onChangeQuotationQuestion = e => {
        setState({
            ...value,
            quotationQuestion: e.target.value,
        });
    }

    const submitQuotation = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let checkEmail = re.test(String(value.quotationEmail).toLowerCase());

        if(value.quotationEmail == '' || !checkEmail){
            alert('Email address is not valid');
        }else{
            setState({
                ...value,
                quotationSubmitting: true,
            });
            let materialLayers = [];
            value.materialsLibrary.map(function (material, index) {
                if (material.visible) {
                    materialLayers.push({
                        'material': layersDisplayName[material.layer] ? layersDisplayName[material.layer] : material.name,
                        'preset': material.presetName
                    });
                }
            });
            axios.post('combination/quotation', {
                variationName: value.variationName,
                variationDesc: value.variationDesc,
                variationPrice: value.variationPrice,
                materialLayers: materialLayers,
                email: value.quotationEmail,
                question: value.quotationQuestion,
                uuid: props.match.params.id
            }).then(response => {
                console.log(response);
                if(response.data.success){
                    alert("Send Quotation successfully!");
                    setState({
                        ...value,
                        quotationPCShow: false,
                        quotationMobileShow: false,
                        quotationSubmitting: false,
                    });
                }
            });
        }
    }

    
    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        alert("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }
    const onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        console.log(payment);
        setState({
            ...value,
            checkoutSuccessShow: true,
            checkoutShow: false,
            paymentID: payment.paymentID
        });
        axios.post('transaction/create', {
                combinationUuid: props.match.params.id,
                price: variationPriceOriginal,
                transactionId: payment.paymentID,
                type: 'configurators',

                address: payment.address,
                email: payment.email,
                payerID: payment.payerID,
                paymentID: payment.paymentID,
                paymentToken: payment.paymentToken
        }).then(response => {
        });
                // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }












    //Template React style

    useEffect(() => {
    }, [layerPresetFoldersReact])

    useEffect(() => {
        if(materialsReact.length !== 0 && userReact != null){
            let uuids = [];
            let layersTmp = [];
            for(const layer of materialsReact)
            {
                uuids.push(layer.params.id);
                layersTmp.push({
                    'layerId': layer.params.id,
                    'visible': layer.params.visible,
                    'displayName': layer.params.name,
                    'label': '',
                    'materialId': layer.params.materialId,
                    'materialFolderId': layer.params.materialFolderId,
                    'tag': ''
                });
            }
            //Get Layers Info
            axios.post('layer/getList', {
                uuids: uuids,
                userId: userReact.userId
            }).then(response => {
                const layers = response.data.data.layers;
                for(let layer of layers){
                    for(let layerTmp of layersTmp){
                        if(layerTmp.layerId == layer.layerId){
                            layerTmp.displayName = layer.displayName ? layer.displayName : layerTmp.displayName.replace(/_/g, ' ').replace(/-/g, ' ');
                            layerTmp.label = layer.label;
                            layerTmp.materialId = layer.materialId;
                            layerTmp.materialFolderId = layer.materialFolderId;
                            layerTmp.tag = layer.tag;
                        }
                    }
                }
                setLayersReact(layersTmp);
            });
        }
    }, [materialsReact, userReact])

    const groupedData = layersReact.reduce((acc, item) => {
        const { materialId, displayName, visible, label, materialFolderId, tag, layerId } = item;
      
        // Check if there is already an entry for the tag in the accumulator
        if (!acc[tag]) {
          // If not, create an array for the tag
          acc[tag] = [];
        }
      
        // Push the item into the array for the corresponding tag
        acc[tag].push({ tag, displayName, visible, label, materialFolderId, layerId });
      
        return acc;
      }, {});
    
    useEffect(() => {
        // Check if collection.collection is an empty array
        if (collections.length === 0) {
          axios.get('collection/get-collections-with-combinations')
            .then(response => {
              setCollections(response.data.data);
            })
            .catch(error => {
              console.error("Error fetching data:", error);
            });
        }
        if (collections && collections.length > 0) {
          setSelectedCollection(collections[0]);
        }
    }, [collections]); // Make sure to specify collection as a dependency

    useEffect(() => {
        if(layersReact.length > 0){
            if(selectedTag == null){
                setSelectedTag(layersReact[0].tag);
                setPanelVisible(true); // Show the panel when a layer is clicked
                setSelectedLayer(layersReact[0].layerId);
                setActiveLayerId(layersReact[0].layerId); // Set the active layer ID
            }
        }
    }, [layersReact]);

    const handleCombinationLayerClick = (tag, layerId) => {
        setSelectedTag(tag);
        setPanelVisible(true); // Show the panel when a layer is clicked
        setSelectedLayer(layerId);
        setActiveLayerId(layerId); // Set the active layer ID
        for(const layer of materialsReact)
        {
            if(layer.params.id == layerId)
            {
                apiReact.highlightObjects.apply(apiReact, layer.mesh)
                let p = {edgeStrength: 10};
                apiReact.getTweener(p)
                        .to({edgeStrength: 0.0}, 0*1000)
                        .onUpdate(()=> apiReact.setOutlineParams(p))
                        .start();

                if(layer.params.visible){
                    const existingIndex = selectedLayerWithTag.findIndex(item => item.tag === tag);
                    if (existingIndex !== -1) {
                    // If the tag exists, update the layer
                        setSelectedLayerWithTag(prevState => {
                            const newState = [...prevState];
                            newState[existingIndex].layer = layerId;
                            return newState;
                        });
                    } else {
                    // If the tag doesn't exist, add a new object
                        setSelectedLayerWithTag(prevState => [...prevState, { tag, layer: layerId }]);
                    }
                }
            }
        }
    };

    const handleCombinationHiddenLayerClick = (tag, layerId) => {
        for(const layer of materialsReact)
        {
            const existingLayerWithTag = selectedLayerWithTag.findIndex(item => item.tag === tag);
            
            if(layer.params.id == selectedLayer || (existingLayerWithTag !== -1 && layer.params.id == selectedLayerWithTag[existingLayerWithTag].layer)){
                layer.params.visible = false;
                apiReact.importMaterialParams(layer.params).then(() => {
                    try{
                        apiReact.setMaterialParams(layer.mesh[0], layer.params)
                    }
                    catch (error) {
                            console.log(error);
                    }
                })
            }
                
            //Show selected layer
            if(layer.params.id == layerId)
            {
                layer.params.visible = true;
                apiReact.importMaterialParams(layer.params).then(() => {
                    try{
                        apiReact.setMaterialParams(layer.mesh[0], layer.params)
                    }
                    catch (error) {
                            console.log(error);
                    }
                })
                setSelectedLayer(layerId);


                const existingIndex = selectedLayerWithTag.findIndex(item => item.tag === tag);
                if (existingIndex !== -1) {
                // If the tag exists, update the layer
                    setSelectedLayerWithTag(prevState => {
                        const newState = [...prevState];
                        newState[existingIndex].layer = layerId;
                        return newState;
                    });
                } else {
                // If the tag doesn't exist, add a new object
                    setSelectedLayerWithTag(prevState => [...prevState, { tag, layer: layerId }]);
                }


            }
        }

    };

    const applyColor = (layer, presetData) => {
        presetData.id = layer.params.id;
        presetData.name = layer.params.name;
        layer.params = presetData;
        layer.mesh.forEach(object => {
            object.material.map = null;
            object.material.lightMap = null;
            object.material.aoMap = null;
            object.material.emissiveMap = null;
            object.material.bumpMap = null;
            object.material.normalMap = null;
            object.material.roughnessMap = null;
            object.material.metalnessMap = null;
            object.material.alphaMap = null;
            apiReact.importMaterialParams(layer.params).then((m) => {
                try{
                    //m.visible = layer.visible;
                    console.log("importMaterialParams");
                    apiReact.setMaterialParams(object, m)
                }
                catch (error) {
                        console.log(error);
                }
                
            })
        })
    }

    const handlePresetClick = (presetData) => {
        for(const layer of materialsReact)
        {
            if(layer.params.id == selectedLayer){
                if(typeof presetData !== 'object'){
                    let isMobile = window.innerWidth < window.innerHeight;
                    let isIphone = navigator.platform == 'iPhone' || navigator.platform == 'iPad';
                    if(isMobile && !isIphone){
                        document.getElementById("myLoader").style.top = "50%";
                    }
                    if(window.innerWidth < window.innerHeight && window.innerWidth == 1080){
                        document.getElementById("myLoader").style.top = "50%";
                    }
                    if(isFullScreen){
                        document.getElementById("myLoader").style.top = "25%";
                    }
                    document.getElementById("myLoader").style.display = "block";
                    const jsonFile = presetData.split('/').pop().replace('.json', '');
                    axios.post('preset/get', {id: jsonFile}).then(response => {
                        let {data} = response
                        if (!data.success) {
                            console.log(data)
                        }
                        if (data.success) {
                            let preset = data.data.preset;
                            setTimeout(() => {
                                let presetDataParsed = preset.data; //JSON.parse(e.target.value);
                                console.log("googleapis");
                                applyColor(layer, presetDataParsed);
                                setTimeout(() => {
                                    document.getElementById("myLoader").style.display = "none";
                                    document.getElementById("canvas").style.display = "block";
                                }, 1000);
                            }, 1000);
                        }
                    });
                    
                }else{
                    applyColor(layer, presetData);
                }

                break;
            }
        }
    }
    
    const closePanel = () => {
        setPanelVisible(false); // Close the panel
        setActiveLayerId(null); // Reset the active layer ID
    };
	  
    const navigateConfigurators = (path, uuid) => {
        const newUrl = window.location.href.replace(/\/[^/]+(?=\?)/, `/${uuid}`);
        window.open(newUrl, '_self');
    }

    const isActiveLayer = (tag, layer) => {
        const existingLayerWithTag = selectedLayerWithTag.findIndex(item => item.tag === tag);
        if(selectedLayerWithTag && selectedLayerWithTag[existingLayerWithTag]){
            return selectedLayerWithTag[existingLayerWithTag].layer == layer ? "active-layer" : "";
        }
        return "";
    }

    const isActiveCollection = (uuid) => {
        if(selectedCollection && selectedCollection.collection.uuid == uuid){
            return "active-collection";
        }
        return "";
    }



    return (
        <>
            <HardwareAccelerationModal show={showHardwareModal}></HardwareAccelerationModal>
            <div className="cadgl-template">
                <div id="tooltip-span" style={{width: 100, height: 100}} className="tooltip" title="CadGL"></div>
                <div id="configurator-main-content" className="main-content configurator-main-content" style={{padding: 0}}>
                    <Col style={{paddingLeft: 0, paddingRight: 0, marginLeft: 0}} md={{ span: 12}} className="canvas-area" id="configurator-canvas-area">
                        <div id="hambuger-configurator" style={{display: 'none', position: 'absolute', top: 0, right: 20}}>
                            <img src={hamburger} style={{width: 25}} />
                        </div>
                        <div style={{display: 'none'}} className="animate-bottom" id="myLoader">
                        </div>
                        <div style={{display: 'none', position: 'absolute'}}  id="myLoader2">
                            <div id="myBar" className="c100 p0 big">
                                <span id="myBar-text">0%</span>
                                <div className="slice">
                                    <div className="bar"></div>
                                    <div className="fill"></div>
                                </div>
                            </div>
                        </div>
                        
                        <div id="static-img">
                            <div id="static-img-overlay">
                            </div>
                        </div>
                        {/* <img id="static-img" style={{width: 100+'%'}} /> */}
                        <canvas id="canvas"  ref={canvas}/>
                        <div id="xrOverlay"></div>
                        <img id="swipeIcon" src={'https://cadgl.net/editor/images/swipe_360.gif'} style={{position: 'absolute', bottom: 20}} />

                        <div id="configurators-canvas-buttons" className="" style={{position: 'absolute', top: 10, right: 70, height: 20}}>
                            
                            <CombinationIcons></CombinationIcons>
                        </div>
                    </Col>

                    <div style={{width: '100%', position: 'fixed', 
                    //left: 100, 
                    bottom: 20, 
                    //display: 'flex', flexDirection: 'row'
                    textAlign: 'center'
                    }}>

                        <div id="collection-combination-layers" style={{display: 'inline-block'}}>
                            {
                                layersReact && layersReact.map((layer, j) => (
                                    layerPresetFoldersReact && layerPresetFoldersReact.map((presetFolder, j) => (
                                        layer.visible && presetFolder.layerId == layer.layerId ? 
                                        // <div key={j} className={`combination-layer${layer.layerId === activeLayerId ? ' active' : ''}`}
                                        // style={{padding: 10, borderBottom: '2px solid white'}}
                                        // onClick={() => handleCombinationLayerClick(layer.tag, layer.layerId)}>
                                        //         <img 
                                        //         src={layer.label} style={{ width: '100%', cursor: 'pointer' }}  />
                                        //         <p style={{marginTop: 10, marginBottom: 0}}>{layer.tag}</p>
                                        // </div>
                                        <label key={j} >
                                            {layer.displayName}
                                            <input
                                            style={{marginRight: 30}}
                                            type="radio"
                                            value={layer.layerId}
                                            checked={layer.layerId === activeLayerId}
                                            onClick={() => handleCombinationLayerClick(layer.tag, layer.layerId)}
                                            
                                            />
                                        </label>
                                    : ''
                                    ))
                                ))
                            }
                        </div>
                        <div 
                            className={`collection-combination-layers-panel${panelVisible ? ' visible' : ''}`}
                            style={{ 
                                display: 'inline-block',
                                textAlign: 'left',
                                width: 'auto',
                                // position: 'fixed',
                                right: panelVisible ? '95px' : '-200px', // Slide out of view when not visible
                                top: 'calc(40% - 25vh)',
                                // background: 'white',
                                transition: 'left 0.3s ease-in-out',
                                paddingBottom: 0,
                                overflowX: 'hidden',
                                height: 'auto'
                            }}
                            >
                            {/* {
                                selectedTag && (
                                <>
                                <h3 className='collection-combination-layers-panel-tag-title' style={{color: 'black', marginBottom: 0}}>{selectedTag}</h3>
                                <hr style={{display: 'block', width: 30+'%', marginTop: 10, marginBottom: 0, borderWidth: 1, borderColor: 'black', borderStyle: 'solid'}}></hr>
                                </>
                                )
                            } */}
                            {/* <div className='row'>
                                {selectedTag &&
                                groupedData[selectedTag].map((item, index) => (
                                    <div key={index} className="col-md-6" style={{ padding: 10, borderBottom: '2px solid white' }}
                                    onClick={() => handleCombinationHiddenLayerClick(item.tag, item.layerId)}>
                                    <img 
                                    className={isActiveLayer(selectedTag, item.layerId)}
                                    src={item.label} style={{ width: '100%', cursor: 'pointer' }} />
                                    <p style={{ marginTop: 10, marginBottom: 0 }}>{item.displayName}</p>
                                    </div>
                                ))}
                            </div> */}
                            <div >
                                {selectedLayer &&
                                layerPresetFoldersReact.map((item, index) => (
                                    item.layerId == selectedLayer ?
                                        item.presetFolder.data.map((preset, index2) => (
                                            <div title={preset.name} key={index2}  style={{display: 'inline-block'}}>
                                            <img 
                                                onClick={() => handlePresetClick(preset.data)}
                                                src={preset.label} style={{ margin: '0px 5px 0px 5px', width: 50, cursor: 'pointer', border: '2px solid #476984', 
                                                borderRadius: 30 }} alt='' />
                                            </div>
                                        ))
                                    : ''
                                ))}
                            </div>
                            <button style={{display: 'none'}} class="close-button" onClick={closePanel}>x</button>
                        </div>

                    </div>

                    <Col style={{display: 'none', overflowY: 'scroll', overflowX: 'hidden', height: value.canvasHeight}} md={{ span: 2}} id="configurators-panel-area" className="panel-area">
                        
                        <Row className="annotation-title" style={{color: 'black', fontSize: 25, fontWeight: 'bold', marginTop: 25}}>
                            <Col md={{ span: 12}} className="panel-column">
                                {lang.configurator_3d}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 12}} className="panel-column">
                                <div className="panel-logo">
                                    <img
                                        style={{width: 100+'%'}}
                                        alt=""
                                        src={logo}
                                        className="d-inline-block align-top panel-logo-img"
                                    />
                                </div>
                                <Row>
                                    <div className="panel-combination-list">
                                        <Form.Group className="panel-combination-list-title">
                                            <div id="combinationList" className="panel-combination-select">

                                            </div>
                                        </Form.Group>
                                        {/* <Form.Group controlId="combinationList">
                                            <Form.Control  as="select" className="panel-combination-select">
                                                
                                            </Form.Control>
                                        </Form.Group> */}
                                        <Form.Group controlId="combinationDesc" className="panel-combination-info-desc">
                                            <Form.Control as="textarea" rows={3} readOnly value={value.description} />
                                        </Form.Group>
                                        <Form.Group>
                                            <div className="price">
                                                <div id="" className="panel-combination-info-price">
                                                    <Form.Control id="combinationPrice" type="text" readOnly value={value.price} />
                                                </div>
                                            </div>
                                        
                                        </Form.Group>
                                                <div>
                                                    <div
                                                        style={{paddingRight: 5+'px', maxWidth: 100+'%', width: 100+'%'}}
                                                        className="quotation-div-mobile-mode">
                                                        <React.Fragment>
                                                        <Button id="quotation-btn-mobile" onClick={openQuotation} className="panel-combination-info-add-to-cart " style={{marginBottom: 5+'px', width: 100+'%', display: 'none', marginLeft: '0px !improtant'}} res-type="Mobile" variant="primary">{lang.quotation}</Button>{' '}
                                                        <Modal size="xl" show={value.quotationMobileShow} onHide={handleQuotationClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                                <Modal.Title>{lang.configuration}</Modal.Title>
                                                                <div>
                                                                    <img className="close-icon" src={closeIcon} alt="" onClick={handleQuotationClose}/>
                                                                </div>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form.Group controlId="checkoutVariationName" style={{display: 'none'}}>
                                                                    <Form.Label style={{color: 'white'}}>{lang.variationName}</Form.Label>
                                                                    <Form.Control type="text" value={value.variationName} disabled={true} />
                                                                </Form.Group>
                                                                <Form.Group controlId="checkoutVariationDesc" style={{display: 'none'}}>
                                                                    <Form.Label style={{color: 'white'}}>{lang.variationDesc}</Form.Label>
                                                                    <Form.Control as="textarea" rows={3}  value={value.variationDesc} disabled={true} />
                                                                </Form.Group>
                                                                <Form.Group controlId="checkoutVariationPrice" style={{display: 'none'}}>
                                                                    <Form.Label style={{color: 'white'}}>{lang.variationPrice}</Form.Label>
                                                                    <Form.Control type="text" value={value.variationPrice} disabled={true} />
                                                                </Form.Group>
                                                                <Form.Group controlId="">
                                                                    <Form.Label style={{color: 'white'}}>{lang.material_layers_2}</Form.Label>
                                                                </Form.Group>
                                                                {
                                                                value.materialsLibrary.map(function (material, index) {
                                                                    if (material.visible == true) {
                                                                        return <React.Fragment key={index}>
                                                                            <Row>
                                                                                <Col md={{span: 6}}>
                                                                                    <Form.Control className="quotation-material-layer"  type="text" value={layersDisplayName[material.layer] ? layersDisplayName[material.layer] : material.name} disabled={true} />
                                                                                </Col>
                                                                                <Col md={{span: 6}}>
                                                                                    <Form.Control className="quotation-material" type="text" value={material.presetName} disabled={true} />
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    }
                                                                    })
                                                                }
                                                                <Form.Group controlId="checkoutVariationQuestion" style={{marginTop: 10 + 'px'}}>
                                                                    <Form.Label style={{color: 'white'}}>{lang.variationQuestion}</Form.Label>
                                                                    <Form.Control as="textarea" rows={3}  onChange={onChangeQuotationQuestion} />
                                                                </Form.Group>
                                                                <Form.Group controlId="quotationEmail">
                                                                    <Form.Label style={{color: 'white'}}>Email</Form.Label>
                                                                    <Form.Control onChange={onChangeQuotationEmail} type="email" value={value.quotationEmail} />
                                                                </Form.Group>
                                                            </Modal.Body>
                                                            <Modal.Footer style={{paddingLeft: 0, paddingRight: 5+'px'}}>
                                                                <Form.Group style={{width: 100+'%'}} controlId="quotationSumbitMobile">
                                                                    <Button id="btn-submit-quotation-mobile" disabled={value.quotationSubmitting} onClick={submitQuotation} className="panel-combination-info-add-to-cart" style={{width: 100+'%', marginLeft: 10, marginRight: 10}} variant="primary">{lang.submit}</Button>{' '}
                                                                </Form.Group>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </React.Fragment>
                                                    </div>
                                                    <div
                                                        style={{paddingRight: 5+'px', maxWidth: 100+'%', width: 100+'%'}}>
                                                        <React.Fragment>
                                                            <Button id="btnBuyNow" onClick={openCheckout} className="panel-combination-info-add-to-cart" style={{display: 'none', width: 100+'%', marginTop: 5}} variant="primary">{lang.buy_now}</Button>{' '}
                                                            <Modal size="xl" show={value.checkoutShow} onHide={handleCheckoutClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                                <Modal.Header closeButton className="rename-material-modal-title">
                                                                    <Modal.Title>{lang.checkout}</Modal.Title>
                                                                    <div>
                                                                        <img className="close-icon" src={closeIcon} alt="" onClick={handleCheckoutClose}/>
                                                                    </div>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <Form.Group controlId="checkoutVariationName">
                                                                        <Form.Label style={{color: 'white'}}>{lang.variationName}</Form.Label>
                                                                        <Form.Control type="text" value={value.variationName} disabled={true} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="checkoutVariationDesc">
                                                                        <Form.Label style={{color: 'white'}}>{lang.variationDesc}</Form.Label>
                                                                        <Form.Control as="textarea" rows={3}  value={value.variationDesc} disabled={true} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="checkoutVariationPrice">
                                                                        <Form.Label style={{color: 'white'}}>{lang.variationPrice}</Form.Label>
                                                                        <Form.Control type="text" value={value.variationPrice} disabled={true} />
                                                                    </Form.Group>

                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                <PaypalExpressBtn env={paypal.client.production ? 'production' : 'sandbox'}
                                                                onError={onError}
                                                                onSuccess={onSuccess}
                                                                client={paypal.client}
                                                                currency={currency.currency}
                                                                total={value.variationPriceOriginal} />
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </React.Fragment>
                                                    </div>

                                                    
                                                    <React.Fragment>
                                                        <Modal size="xl" show={value.checkoutSuccessShow} onHide={handleCheckoutSuccessClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                            <Modal.Header closeButton className="rename-material-modal-title">
                                                                <Modal.Title>CadGL - 3D Configurator</Modal.Title>
                                                                <div>
                                                                    <img className="close-icon" src={closeIcon} alt="" onClick={handleCheckoutSuccessClose}/>
                                                                </div>
                                                            </Modal.Header>
                                                            <Modal.Body style={{color: 'white'}}>
                                                                Thank you! Your Payment ID is: {value.paymentID}
                                                            </Modal.Body>
                                                        </Modal>
                                                    </React.Fragment>

                                                </div>
                                    </div>
                                    <div className="panel-combination-list">
                                        <Form.Group className="panel-combination-list-title">
                                            {lang.pick_a_layer}
                                        </Form.Group>
                                        <input type="hidden" id="presetFolderLayer" />
                                        <input type="hidden" id="chosenLayer" />
                                        {/* <Form.Group controlId="layerList">
                                            <Form.Control  as="select" className="panel-combination-select">
                                                
                                            </Form.Control>
                                        </Form.Group> */}
                                        <div id="layerList">
                                            
                                        </div>
                                    </div>
                                    <div className="panel-combination-list">
                                        <Form.Group style={{display: 'none'}} className="panel-combination-list-title" id="swapLayerTitle">
                                            {lang.swap_layer}
                                        </Form.Group>
                                        <div id="hiddenLayerList">
                                            
                                        </div>
                                    </div>
                                    <div className="panel-combination-list">
                                        <Form.Group className="panel-combination-list-title">
                                            {lang.colors}
                                        </Form.Group>
                                        <div id="panelPresets">
                                        </div>
                                    </div>
                                    

                                    <div className="panel-combination-list quotation-div-pc-mode">
                                        <React.Fragment>
                                            <Button onClick={openQuotation} className="panel-combination-info-add-to-cart" style={{width: 100+'%', display: 'none'}} res-type="Mobile" variant="primary">{lang.quotation}</Button>{' '}
                                            <Modal size="xl" show={value.quotationPCShow} onHide={handleQuotationClose} aria-labelledby="contained-modal-title-vcenter" centered>
                                                <Modal.Header closeButton className="rename-material-modal-title">
                                                    <Modal.Title>{lang.configuration}</Modal.Title>
                                                    <div>
                                                        <img className="close-icon" src={closeIcon} alt="" onClick={handleQuotationClose}/>
                                                    </div>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group controlId="checkoutVariationName" style={{display: 'none'}}>
                                                        <Form.Label style={{color: 'white'}}>{lang.variationName}</Form.Label>
                                                        <Form.Control type="text" value={value.variationName} disabled={true} />
                                                    </Form.Group>
                                                    <Form.Group controlId="checkoutVariationDesc" style={{display: 'none'}}>
                                                        <Form.Label style={{color: 'white'}}>{lang.variationDesc}</Form.Label>
                                                        <Form.Control as="textarea" rows={3}  value={value.variationDesc} disabled={true} />
                                                    </Form.Group>
                                                    <Form.Group controlId="checkoutVariationPrice" style={{display: 'none'}}>
                                                        <Form.Label style={{color: 'white'}}>{lang.variationPrice}</Form.Label>
                                                        <Form.Control type="text" value={value.variationPrice} disabled={true} />
                                                    </Form.Group>
                                                    <Form.Group controlId="">
                                                        <Form.Label style={{color: 'white'}}>{lang.material_layers_2}</Form.Label>
                                                    </Form.Group>
                                                    {
                                                        value.materialsLibrary.map(function(material, index) {
                                                            if (material.visible == true) {
                                                                return <React.Fragment>
                                                                    <Row>
                                                                        <Col md={{ span: 6 }}>
                                                                            <Form.Control className="quotation-material-layer" type="text" value={layersDisplayName[material.layer] ? layersDisplayName[material.layer] : material.name} disabled={true} />
                                                                        </Col>
                                                                        <Col md={{ span: 6 }}>
                                                                            <Form.Control className="quotation-material" type="text" value={material.presetName} disabled={true} />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            }
                                                        })
                                                    }
                                                    <Form.Group controlId="checkoutVariationQuestion" style={{marginTop: 10 + 'px'}}>
                                                        <Form.Label style={{color: 'white'}}>{lang.variationQuestion}</Form.Label>
                                                        <Form.Control as="textarea" rows={3} onChange={onChangeQuotationQuestion} />
                                                    </Form.Group>
                                                    <Form.Group controlId="quotationEmail">
                                                        <Form.Label style={{color: 'white'}}>Email</Form.Label>
                                                        <Form.Control onChange={onChangeQuotationEmail} type="email" value={value.quotationEmail} />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button id="btn-submit-quotation-pc" disabled={value.quotationSubmitting} onClick={submitQuotation} className="panel-combination-info-add-to-cart" style={{width: 100+'%', marginLeft: 10, marginRight: 10}} variant="primary">{lang.submit}</Button>{' '}
                                                </Modal.Footer>
                                            </Modal>
                                        </React.Fragment>
                                    </div>

                                    
                                    <React.Fragment>
                                        <Button onClick={openQuotation} className="panel-combination-info-add-to-cart panel-combination-info-add-to-cart-desktop" style={{width: 100+'%', marginLeft: 10, marginRight: 10}} res-type="Desk" variant="primary">{lang.quotation}</Button>{' '}
                                    </React.Fragment>
                                    <div className="panel-color-list-mobile" id="panelPresetsMobile"></div>
                                    <div className="panel-combination-list" style={{display: 'none'}}>
                                        <Form.Group className="panel-combination-list-title">
                                            {lang.tools}
                                        </Form.Group>
                                        <Row className="">
                                            <Col id="lblHideLayer" md={{span: 9}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                {lang.hide_selected_layer}
                                            </Col>
                                            <Col id="lblHideLayerInput" md={{span: 3}}>
                                                <input value="true" id="layerVisible" className="form-check-input position-static" type="checkbox" />
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col id="lblRotate" md={{span: 9}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                {lang.auto_rotate_2}
                                            </Col>
                                            <Col id="lblRotateInput" md={{span: 3}}>
                                                <input value="true" id="btnRotate" className="form-check-input position-static" type="checkbox" />
                                            </Col>
                                        </Row>
                                        <Row className="first-load-bg">
                                            <Col md={{span: 12}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                {/* <a href="#" className="fakeLoadBackground" id="fakeLoadBackground">Add Background</a> */}
                                                <input type="file"
                                                style={{display: 'none'}}
                                                id="loadBackground" name="avatar"
                                                accept="image/png, image/jpeg"></input>
                                            </Col>
                                        </Row>
                                        <Row className="panel-button-group">
                                            {/* <Col md={{ span: 4}} className="panel-button">
                                                <img className="iframe-circle-icon  panel-button-ar"  id="btnAR" src={arOn}  />
                                            </Col> */}
                                            {/* <Col md={{ span: 6}} className="panel-button">
                                                <img className="iframe-circle-icon panel-button-screenshot"  id="btnScreenshot" src={cameraOn}  />
                                            </Col> */}
                                            {/* <Col md={{ span: 6}} className="panel-button">
                                                <img className="iframe-circle-icon panel-button-rotate" id="btnRotate" src={rotateOn}  />
                                            </Col> */}
                                        </Row>
                                        {/* <Row className="second-load-bg" style={{display: 'none'}}>
                                            <Col md={{span: 12}} style={{lineHeight: 20+'px', textAlign: 'left'}}>
                                                <a href="#" className="fakeLoadBackground" id="fakeLoadBackground2">Add Background</a>
                                                <input type="file"
                                                style={{display: 'none'}}
                                                id="loadBackground" name="avatar"
                                                accept="image/png, image/jpeg"></input>
                                            </Col>
                                        </Row> */}
                                        <Row style={{padding: 8}}>
                                            <React.Fragment>
                                                <Button  id="fakeLoadBackground" className="fakeLoadBackground panel-combination-info-add-to-cart " style={{width: 100+'%', marginLeft: 10, marginRight: 10, marginTop: 30, marginBottom: 5}} res-type="Desk" variant="primary">{lang.change_background}</Button>{' '}
                                            </React.Fragment>
                                            <React.Fragment>
                                                <Button  id="btnScreenshot" className="fakeLoadBackground panel-combination-info-add-to-cart " style={{width: 100+'%', marginLeft: 10, marginRight: 10}} res-type="Desk" variant="primary">{lang.take_screenshot_label}</Button>{' '}
                                            </React.Fragment>
                                        </Row>
                                    </div>

                                    <div style={{padding: '5px', height: '35px', borderTop: '1px solid #c5c5c5', textAlign: 'right', marginTop: '-3px', background: '#f6f6f6 ', width: '100%', maxWidth: '100%'}} id="panel-close-mobile" className="">
                                        <button id="panel-close-mobile-button" style={{width: '20%', float: 'right', display: 'none'}} type="button" className="panel-combination-info-add-to-cart btn btn-primary"><i id="x-close" className="fas fa-times"></i></button>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                </div>
                <ZoomAlertModal></ZoomAlertModal>
                <div id="myModal" className="modalAR">
                    <div className="modal-contentAR">
                        <span className="closeAR">&times;</span>
                        <div className="modal-bodyAR">
                            <p className="modal-bodyAR-p" style={{color: 'black'}}></p>
                        </div>
                    </div>
                </div>
                <div id="modal3DViewer" className="modal3DViewer"  style={{width: 90 + '%', margin: 'auto', display: 'none'}}>
                    <div className="" style={{width: 100 + '%', height: 95 + '%', margin: 'auto'}}>
                        <span id="close3DViewer" style={{fontSize: 30, float: 'left', cursor: 'pointer'}}>&times;</span>
                        <div className="" id="modal-3d-viewer" style={{height: 95 + '%'}}>
                            <iframe width={100+'%'} height={100+'%'}/>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

const calculateMousePosition = (e, isMobile) =>
{
    // if(e.changedTouches != undefined){
    //     let x = isMobile ? e.changedTouches[0].clientX : e.clientX;
    //     let y = isMobile ? e.changedTouches[0].clientY : e.clientY;
    //     mousePosition.x = (x / document.getElementById('canvas').clientWidth) * 2 - 1;
    //     mousePosition.y = -(y / document.getElementById('canvas').clientHeight) * 2 + 1;
    // }
    let x, y;
    if(isMobile){
        if(e.changedTouches != undefined){
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        }
    }else{
        x = e.clientX;
        y = e.clientY;
    }
    mousePosition.x = (x / document.getElementById('canvas').clientWidth) * 2 - 1;
    mousePosition.y = -(y / document.getElementById('canvas').clientHeight) * 2 + 1;
}

const onPointerWheel = (e, api, props) => {
    mouseWheelTime = Date.now();
    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            // api.stopAllSounds();
            api.playAllSounds();
        }
    }
}
/**
 * Set mouse position
 * @param e
 */
const onPointerMove = (e, api, isMobile) => {
    calculateMousePosition(e, isMobile);
    const hitLogo = api.hitLogo(mousePosition);
    if(hitLogo) 
    {
        document.body.style.cursor = "pointer";

        if(userInfo.isShowLinkToolTip)
        {
            var tooltipSpan = document.getElementById('tooltip-span');
            if(tooltipSpan)
            {
                tooltipSpan.style.display = "block";
                tooltipSpan.style.top = (mousePosition.y + 20) + 'px';
                tooltipSpan.style.left = (mousePosition.x + 20) + 'px';
            }
        }
    }
    else
    {
        document.body.style.cursor = "default";
        var tooltipSpan = document.getElementById('tooltip-span');
        if(tooltipSpan)
        {
            tooltipSpan.style.display = "none";
            tooltipSpan.style.top = 0 + 'px';
            tooltipSpan.style.left = 0 + 'px';
        }
    }

}

/**
 * On mouse up event, will highlight on UI objects that use the material of the clicked object
 * @param e
 * @param api
 * @param props
 */
const onPointerUp = (e, api, props, materialsLibraryLocal, isMobile) => {
    document.getElementById("annotationIcon").classList.remove("canvasActive");
    document.getElementById("autoRotateIcon").classList.remove("canvasActive");
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    document.getElementById("changeBackgroundIcon").classList.remove("canvasActive");
    document.getElementById("screenshotIcon").classList.remove("canvasActive");


    document.getElementById("canvas").classList.remove("canvasActive");
    calculateMousePosition(e, isMobile);
    mouseWheelTime = Date.now();
    lastMouseDownTime = mouseDownTime;
    let delta = Date.now() - mouseDownTime;
    mouseDownTime = 0;
    if (delta > 200) return;

    const intersection = api.rayCast(mousePosition);
    let pickedObject = intersection?.object ?? null;
    let materialLibrary = api.getMaterialLibrary();
    let objs = materialLibrary.find(value => !!value.mesh.find(value1 => value1 === pickedObject))?.mesh ?? [pickedObject];
    let selectedObject = new Map()

    if (!e.ctrlKey && pickedObject) {
        api.highlightObjects();
        selectedObject.clear();

        let p = {edgeStrength: 10};
        api.getTweener(p)
                .to({edgeStrength: 0.0}, 0*1000)
                // .delay(4*1000)
                .onUpdate(()=> api.setOutlineParams(p))
                .start();

    }

    if(pickedObject != null)
    {
        let id = pickedObject.material.uid ? pickedObject.material.uid : pickedObject.material.uuid;
        
        document.getElementById(id).click();

    }

    objs.forEach(value => {
        if (value) {
            selectedObject.set(value.uuid, value);
            api.highlightObjects.apply(api, Array.from(selectedObject.values()));
        } else {
            api.highlightObjects();
            selectedObject.clear();
        }
    });

    props.selectObject(selectedObject, pickedObject, objs) //Fire action to display material and object properties in sidebar

    if(userInfo.isShowLinkToolTip)
    {
        const hitLogo = api.hitLogo(mousePosition);
        if(hitLogo) window.open('https://www.cadgl.com/subscription-packages', '_blank');
    }

    
    //ANNOTATIONS
    let annotation = api.hitAnnotations(mousePosition, true);
    if(annotation == draggingAnnotation) {
        draggingAnnotation = null;
        api.setCameraParams({mode: "orbit"});
    }
    if(annotation)
    {
        api.animateCamera(annotation.cameraState);
    }
    setTimeout(() => {
        var modal3DViewer = document.getElementById("modal3DViewer");
        modal3DViewer.style.display = "none";
        document.getElementById('configurator-main-content').style.opacity = "100%";
    }, 1);
}

const onPointerUp2 = (e, api, props, isMobile) => {
    document.getElementById("helperIcon").classList.remove("canvasActive");
    document.getElementById("audioPlay").classList.remove("canvasActive");
    document.getElementById("animationPlay").classList.remove("canvasActive");
    document.getElementById("fullScreenIcon").classList.remove("canvasActive");
    
    document.getElementById("changeBackgroundIcon").classList.remove("canvasActive");
    document.getElementById("screenshotIcon").classList.remove("canvasActive");
    document.getElementById("canvas").classList.remove("canvasActive");
    
    if(userInfo.isShowLinkToolTip)
    {
        window.open('https://www.cadgl.com/subscription-packages', '_blank');
    }

}

const onPointerDown = (e, api, props) => {

    if(!isFirstClick){
        isFirstClick = true;
        if(document.getElementById('audioPlay').childNodes[0].src == sound_on){
            api.stopAllSounds();
            api.playAllSounds();
        }
    }
    document.getElementById("annotationIcon").classList.add("canvasActive");
    document.getElementById("autoRotateIcon").classList.add("canvasActive");
    document.getElementById("helperIcon").classList.add("canvasActive");
    document.getElementById("audioPlay").classList.add("canvasActive");
    document.getElementById("animationPlay").classList.add("canvasActive");
    document.getElementById("fullScreenIcon").classList.add("canvasActive");

    document.getElementById("changeBackgroundIcon").classList.add("canvasActive");
    document.getElementById("screenshotIcon").classList.add("canvasActive");

    document.getElementById("canvas").classList.add("canvasActive");
    document.getElementById("swipeIcon").style.display = "none";
    mouseDownTime = Date.now();

    var x = document.getElementById("hambuger-configurator");
    let isMobile = window.innerWidth < window.innerHeight;
    if(!isMobile){
        if (window.getComputedStyle(x).display === "block") {
            document.getElementById('configurators-panel-area').style.display = "none";
        }
        
    }else{
        if (window.getComputedStyle(x).display === "block") {
            document.getElementById('configurators-panel-area').style.display = "none";
        }
    }

    
    const now = Date.now();
    if(now - lastMouseDownTime < 300){
        lastMouseDownTime = 0;
        //double click
        if(enableCameraDoubleClick === true || enableCameraDoubleClick === "true"){
            api.animateCameraTarget(mousePosition, 1.2, 6, 1.7);
        }
        return
    }

    
    draggingAnnotation = api.hitAnnotations(mousePosition, true);
    if (draggingAnnotation){
        api.setCameraParams({mode: "fixed"});
    }
}



const onPointerDownDouble = (e, api, props) => {
    // api.animateCameraTarget(mousePosition, 1.2, 6, 1.1);
}

const getFileBlob = (url, cb) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
        cb(xhr.response);
    });
    xhr.send();
};

const blobToFile = (blob, name) => {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};

const getFileObject = (filePathOrUrl, cb) => {
    getFileBlob(filePathOrUrl, function (blob) {
        cb(blobToFile(blob, 'test.jpg'));
    });
};


const mapStateToProps = (state) => {
    return {
        iframe: state.iframe,
        combinations: state.combinations,
        selectObject: selectObject
    }
}

export default withRouter(connect(mapStateToProps, null)(ProductRenderer))