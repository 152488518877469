import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import {
    selectMeshes,
    refractionMaterialToggleVisibility,
} from "../../../../actions/actionsCreators";
import axios from '../../../../axios';

//HIGHLIGHT BUTTON
class LayerInfo  extends Component {
    /**
     * Filter all button types and fire its specific action
     */
    state = {
        isSelected: false,
        labelUrl: '',
        tag: '',
        displayName: '',
        price: 0,
        isUpdatingPrice: false,
        isUpdatingTag: false,
        visible: false
    }
    dispatchAction = (type, value) => {
        switch (type) {
            case 'selectMeshes':
                //localStorage.setItem('current-part', value);
                this.props.selectMeshes(value)
                break;
            default:
                return null;
        }
    }


    handleVisible = (value) => {
        this.setState({visible: !this.state.visible});
        this.props.selectMeshes(this.props.value);
        this.props.refractionMaterialToggleVisibility(!this.state.visible);
    }

    componentDidMount(){
        let url = '';
        let tag = '';
        let display_name = '';
        let price = 0;
        let layers_label = document.getElementById("layers_label").value;
        if(layers_label != ""){
            layers_label = JSON.parse(layers_label);
            if(layers_label[this.props.uuid]){
                url = layers_label[this.props.uuid]
            }
        }else{
            layers_label = {};
        }
        
        let layers_tag = document.getElementById("layers_tag").value;
        if(layers_tag != ""){
            layers_tag = JSON.parse(layers_tag);
            if(layers_tag[this.props.uuid]){
                tag = layers_tag[this.props.uuid]
            }
        }else{
            layers_tag = {};
        }
        
        

        let layers_price = document.getElementById("layers_price").value;
        if(layers_price != ""){
            layers_price = JSON.parse(layers_price);
            if(layers_price[this.props.uuid]){
                price = layers_price[this.props.uuid]
            }
        }else{
            layers_price = {};
        }

        let layers_display_name = document.getElementById("layers_display_name").value;
        if(layers_display_name != ""){
            layers_display_name = JSON.parse(layers_display_name);
            if(layers_display_name[this.props.uuid]){
                display_name = layers_display_name[this.props.uuid]
            }
        }else{
            layers_display_name = {};
        }

        


        this.setState({
            ...this.state,
            labelUrl: layers_label[this.props.uuid],
            tag: layers_tag[this.props.uuid],
            price: layers_price[this.props.uuid],
            displayName: layers_display_name[this.props.uuid] ?? this.props.name,
            visible: this.props.visible
        })
    }

    uploadLayerLabel = (e) => {
        let layer_name = this.props.uuid; 
        let layers_label = document.getElementById("layers_label").value;
        if(layers_label != ""){
            layers_label = JSON.parse(layers_label);
        }else{
            layers_label = {};
        }
        const files = Array.from(e.target.files);
        
        let layerLabelData = new FormData();
        layerLabelData.append('label', files[0]);
        
        axios.post('combination/upload-layer-label', layerLabelData
        ).then(response => {
            let labelPath = response.data.data;
            layers_label[layer_name] = labelPath;
            document.getElementById("layers_label").value = JSON.stringify(layers_label);
            console.log(document.getElementById("layers_label").value);
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    labelUrl: labelPath
                })
        
                axios.post('layer/create', {layerId: layer_name, label: labelPath}
                ).then(response => {
                    
                });
            }, 3000);
        });
    }

    updateDisplayName = (e) => {
        let layer_name = this.props.uuid;
        let layers_display_name = document.getElementById("layers_display_name").value;
        if(layers_display_name != ""){
            layers_display_name = JSON.parse(layers_display_name);
        }else{
            layers_display_name = null;
        }
        layers_display_name[layer_name] = e.target.value;
        document.getElementById("layers_display_name").value = JSON.stringify(layers_display_name);
        this.setState({
            ...this.state,
            displayName: e.target.value
        })
    }

    updateTag = (e) => {
        let layer_name = this.props.uuid;
        let layers_tag = document.getElementById("layers_tag").value;
        if(layers_tag != ""){
            layers_tag = JSON.parse(layers_tag);
        }else{
            layers_tag = {};
        }
        layers_tag[layer_name] = e.target.value;
        document.getElementById("layers_tag").value = JSON.stringify(layers_tag);
        this.setState({
            ...this.state,
            tag: e.target.value
        })
    }

    updatePrice = (e) => {
        let layer_name = this.props.uuid;
        let layers_price = document.getElementById("layers_price").value;
        if(layers_price != ""){
            layers_price = JSON.parse(layers_price);
        }else{
            layers_price = {};
        }
        layers_price[layer_name] = e.target.value;
        document.getElementById("layers_price").value = JSON.stringify(layers_price);
        this.setState({
            ...this.state,
            price: e.target.value
        })
        
        // axios.post('layer/create', {layerId: layer_name, price: e.target.value}
        // ).then(response => {
            
        // });
    }

    updateLayer = () => {
        let layer_name = this.props.uuid;
        axios.post('layer/create', {layerId: layer_name, price: this.state.price, tag: this.state.tag, displayName: this.state.displayName}
        ).then(response => {
            // alert("Success!");
        });
    }

    //Files upload handling only for menu Upload Files
    

    render() {
        console.log(this.props.name + "---------------" + this.props.uuid);
    return (
        <React.Fragment>
            <Row>{
                (this.props.pickedObject != undefined && this.props.pickedObject === this.props.orgName) ? 
                <Col md={12} className="setting-col-selected layer-info-div" onClick={() => this.dispatchAction(this.props.type, this.props.value)}>
                        <span title={this.props.title} className="setting-name">{this.props.name}</span>
                        <Form.Check
                        type="checkbox"
                        className="float-right"
                        checked={this.state.visible}
                        value={this.state.visible}
                        onChange={this.handleVisible}/>
                </Col>
                : 
                <Col md={12} className="setting-col layer-info-div" onClick={() => this.dispatchAction(this.props.type, this.props.value)}>
                        <span title={this.props.title} className="setting-name">{this.props.name}</span>
                        <Form.Check
                        type="checkbox"
                        className="float-right"
                        checked={this.state.visible}
                        value={this.state.visible}
                        onChange={this.handleVisible}/>
                </Col>
                }
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">{
                (this.props.pickedObject != undefined && this.props.pickedObject === this.props.orgName) ? 
                <Col md={12} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                        <span className="setting-name">{this.props.presetFolderName != null ? this.props.presetFolderName : 'N/F'}</span>
                        <span className="setting-name">{this.props.presetName != null ? this.props.presetName : 'N/M'}</span>
                </Col>
                : 
                <Col md={12} style={{backgroundColor: 'gray'}} className="setting-col" onClick={() => this.dispatchAction(this.props.type, this.props.value)}>
                        <span className="setting-name">{this.props.presetFolderName != null ? this.props.presetFolderName : 'N/F'}</span>
                        <span className="setting-name">{this.props.presetName != null ? this.props.presetName : 'N/M'}</span>
                </Col>
                }
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={12} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <img style={{float: 'left'}} src={this.state.labelUrl} width="45px" />
                </Col>
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={12} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <input onChange={this.uploadLayerLabel} type="file"  />
                </Col>
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={7} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <input type="text" value={this.state.tag} onChange={this.updateTag}   />
                </Col>
                <Col md={5} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    Swap Tag
                </Col>
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={7} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <input type="number" value={this.state.price} onChange={this.updatePrice}   />
                </Col>
                <Col md={5} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    Layer Price
                </Col>
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={7} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <input type="text" value={this.state.displayName} onChange={this.updateDisplayName}   />
                </Col>
                <Col md={5} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    Display Name
                </Col>
            </Row>
            <Row style={{backgroundColor: 'gray'}} className="layer-info-content">
                <Col md={12} style={{backgroundColor: 'gray'}} className="setting-col-selected">
                    <button style={{marginRight: 0+'px'}} onClick={this.updateLayer} type="button" className="add-combination-btn btn btn-primary">APPLY</button>
                </Col>
            </Row>
        </React.Fragment>
    )
}
}

const mapDispatchToProps = {
    selectMeshes: selectMeshes,
    refractionMaterialToggleVisibility: refractionMaterialToggleVisibility,
}

export default connect(null, mapDispatchToProps)(LayerInfo)