import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {
    changeCameraZoom,
    changeFovSetting,
    changeEdgeGlowSetting,
    changeEdgeThicknessSetting,
    changeEdgeStrengthSetting,
    changePulsePeriodSetting,
    changeCameraPositionXSetting,
    changeCameraPositionYSetting,
    changeCameraPositionZSetting,
    changeSharpnessSetting,
    changeSsaoIntensitySetting,
    changeSsaoRadiusSetting,
    changeBloomStrengthSetting,
    changeBloomRadiuSetting,
    changeBloomThresholdSetting,
    changeNumSamplesSetting,
    changeNumSamplesPerFrameSetting,
    changeGroundBrightnessSetting,
    changeBlurRadiusSetting,
    changeGroundSharpnessSetting,
    changeFalloffSetting,
    changeReflectionRoughnessSetting,
    changeReflectionMetalnessSetting,
    changeReflectionSizeSetting,
    changeCrossRadiusSetting,
    changeNearBlurScaleSetting,
    changeFarBlurScaleSetting,
    changeDepthRangeSetting,
    changeAutoRotateSpeedSetting,
    changeBoostFactorXSetting,
    changeBoostFactorYSetting,
    changeBoostFactorZSetting,
    changeEnvMapIntensityDiamond,
    changeRefractiveIndexDiamond,
    changeGeometryFactorDiamond,
    changeSquashFactorDiamond,
    changeDispersionDiamond,
    changeReflectivityDiamond,
    changeAbsorptionFactorDiamond,
    changeGammaFactorDiamond,
    changeStandardMaterialOpacity,
    changeStandardMaterialRoughness,
    changeStandardMaterialMetalness,
    changeStandardMaterialEnvMapIntensity,
    refractionMaterialRefractionIndex,
    refractionMaterialRefractionRoughness,
    refractionMaterialRoughness,
    refractionMaterialMetalness,
    refractionMaterialTransparency,
    changeObjectScaleSetting,
    changeReticleSizeInSetting,
    changeReticleSizeOutSetting,
    changeReticleFadeThreshSetting,
    changeReticleFadeTimeSetting,
    changeToneMappingExposureSetting,
    changeToneMappingSaturationSetting,
    changeToneMappingContrastSetting,
    changeEnvMapRotationSetting,
    changeBevelRadius,
    changePixelRatio,
    changeMapRepeatU,
    changeMapRepeatV,
    logoStayDuration,
    logoFadeInDuration,
    logoFadeOutDuration,
    logoScale,
    logoPositionX,
    logoPositionY,
    intensitySSR,
    changeStandardMaterialBumpScale,
    changeMinimumDistance,
    changeMaximumDistance,
    changeMaxPolarAngle,
    changeMinPolarAngle,
    changeMaxAzimuthAngle,
    changeMinAzimuthAngle,
    changeAnimationTimeScale,
    changeAudioVolume,
    changeAudioMaterialVolume,
    
    changeStandardMaterialAlphaMapIntensity,
    changeStandardMaterialLightMapIntensity,
    changeStandardMaterialAoMapIntensity,
    changeStandardMaterialEmissiveIntensity,
    feedBackMin,
    feedBackMax,
    changeDampingFactor,
    changeNearFarMultiplier,
    changeMinCameraNearClip,
    changeNormalScaleX,
    changeNormalScaleY
} from "../../../../actions/actionsCreators";

class MenuItemInputRange extends Component {
    state = {
        value: this.props.value ? this.props.value : 0
    };

    /**
     * Filter all input range types and fire its specific action
     */
    dispatchAction = (type, value, additional, obj) => {
        switch (type) {
            case 'minCameraNearClip':
                this.props.changeMinCameraNearClip(value);
                break;
            case 'nearFarMultiplier':
                this.props.changeNearFarMultiplier(value);
                break;
            case 'dampingFactor':
                this.props.changeDampingFactor(value);
                break;
            case 'feedBackMin':
                this.props.feedBackMin(value);
                break;
            case 'feedBackMax':
                this.props.feedBackMax(value);
                break;
            case 'standardMaterialAlphaMapIntensity':
                this.props.changeStandardMaterialAlphaMapIntensity(value)
                break;
                case 'standardMaterialAlphaMapIntensity':
                    this.props.changeStandardMaterialAlphaMapIntensity(value)
                    break;
            case 'standardMaterialAlphaMapIntensity':
                this.props.changeStandardMaterialAlphaMapIntensity(value)
                break;
            case 'standardMaterialLightMapIntensity':
                this.props.changeStandardMaterialLightMapIntensity(value)
                break;
            case 'standardMaterialAoMapIntensity':
                this.props.changeStandardMaterialAoMapIntensity(value)
                break;
            case 'standardMaterialEmissiveIntensity':
                this.props.changeStandardMaterialEmissiveIntensity(value)
                break;

            case 'changeAudioMaterialVolume':
                this.props.changeAudioMaterialVolume(obj, value)
                break;

            case 'changeAudioVolume':
                this.props.changeAudioVolume(value)
                break;
            case 'animationTimeScale':
                this.props.changeAnimationTimeScale(value)
                break;
            
            case 'standardMaterialBumpScale':
                this.props.changeStandardMaterialBumpScale(value)
                break;
            case 'logoPositionY':
                this.props.logoPositionY(value);
                break;
            case 'logoPositionX':
                this.props.logoPositionX(value);
                break;
            case 'logoScale':
                this.props.logoScale(value);
                break;
            case 'logoFadeOutDuration':
                this.props.logoFadeOutDuration(value);
                break;
            case 'logoFadeInDuration':
                this.props.logoFadeInDuration(value);
                break;
            case 'logoStayDuration':
                this.props.logoStayDuration(value);
                break;
            case 'cameraZoom':
                this.props.changeCameraZoom(parseFloat(value).toFixed(2))
                break;
            case 'fov':
                this.props.changeFovSetting(parseFloat(value).toFixed(2))
                break;
            case 'edgeGlow':
                this.props.changeEdgeGlowSetting(value)
                break;
            case 'edgeThickness':
                this.props.changeEdgeThicknessSetting(value)
                break;
            case 'edgeStrength':
                this.props.changeEdgeStrengthSetting(value)
                break;
            case 'pulsePeriod':
                this.props.changePulsePeriodSetting(value)
                break;
            case 'cameraX':
                this.props.changeCameraPositionXSetting(value)
                break;
            case 'cameraY':
                this.props.changeCameraPositionYSetting(value)
                break;
            case 'cameraZ':
                this.props.changeCameraPositionZSetting(value)
                break;
            case 'sharpness':
                this.props.changeSharpnessSetting(value)
                break;
            case 'ssaoIntensity':
                this.props.changeSsaoIntensitySetting(value)
                break;
            case 'ssaoRadius':
                this.props.changeSsaoRadiusSetting(value)
                break;
            case 'bloomStrength':
                this.props.changeBloomStrengthSetting(value)
                break;
            case 'bloomRadius':
                this.props.changeBloomRadiuSetting(value)
                break;
            case 'bloomThreshold':
                this.props.changeBloomThresholdSetting(value)
                break;
            case 'numSamples':
                this.props.changeNumSamplesSetting(value)
                break;
            case 'numSamplesPerFrame':
                this.props.changeNumSamplesPerFrameSetting(value)
                break;
            case 'groundBrightness':
                this.props.changeGroundBrightnessSetting(value)
                break;
            case 'blurRadius':
                this.props.changeBlurRadiusSetting(value)
                break;
            case 'groundSharpness':
                this.props.changeGroundSharpnessSetting(value)
                break;
            case 'falloff':
                this.props.changeFalloffSetting(value)
                break;
            case 'reflectionRoughness':
                this.props.changeReflectionRoughnessSetting(value)
                break;
            case 'reflectionMetalness':
                this.props.changeReflectionMetalnessSetting(value)
                break;
            case 'reflectionSize':
                this.props.changeReflectionSizeSetting(value)
                break;
            case 'crossRadius':
                this.props.changeCrossRadiusSetting(value)
                break;
            case 'nearBlurScale':
                this.props.changeNearBlurScaleSetting(value)
                break;
            case 'farBlurScale':
                this.props.changeFarBlurScaleSetting(value)
                break;
            case 'depthRange':
                this.props.changeDepthRangeSetting(value)
                break;
            case 'autoRotateSpeed':
                this.props.changeAutoRotateSpeedSetting(value)
                break;
            case 'boostFactorX':
                this.props.changeBoostFactorXSetting(value)
                break;
            case 'boostFactorY':
                this.props.changeBoostFactorYSetting(value)
                break;
            case 'boostFactorZ':
                this.props.changeBoostFactorZSetting(value)
                break;
            case 'envMapIntensityDiamond':
                this.props.changeEnvMapIntensityDiamond(value)
                break;
            case 'refractiveIndexDiamond':
                this.props.changeRefractiveIndexDiamond(value)
                break;
            case 'geometryFactorDiamond':
                this.props.changeGeometryFactorDiamond(value)
                break;
            case 'squashFactorDiamond':
                this.props.changeSquashFactorDiamond(value)
                break;
            case 'dispersionDiamond':
                this.props.changeDispersionDiamond(value)
                break;
            case 'reflectivityDiamond':
                this.props.changeReflectivityDiamond(value)
                break;
            case 'absorptionFactorDiamond':
                this.props.changeAbsorptionFactorDiamond(value)
                break;
            case 'gammaFactorDiamond':
                this.props.changeGammaFactorDiamond(value)
                break;
            case 'standardMaterialOpacity':
                this.props.changeStandardMaterialOpacity(value)
                break;
            case 'standardMaterialRoughness':
                this.props.changeStandardMaterialRoughness(value)
                break;
            case 'standardMaterialMetalness':
                this.props.changeStandardMaterialMetalness(value)
                break;
            case 'standardMaterialEnvMapIntensity':
                this.props.changeStandardMaterialEnvMapIntensity(value)
                break;
            case 'refractionMaterialRefractionIndex':
                this.props.refractionMaterialRefractionIndex(value)
                break;
            case 'refractionMaterialRefractionRoughness':
                this.props.refractionMaterialRefractionRoughness(value)
                break;
            case 'refractionMaterialRoughness':
                this.props.refractionMaterialRoughness(value)
                break;
            case 'refractionMaterialMetalness':
                this.props.refractionMaterialMetalness(value)
                break;
            case 'refractionMaterialTransparency':
                this.props.refractionMaterialTransparency(value)
                break;
            case 'changeObjectScaleSetting':
                this.props.changeObjectScaleSetting(value)
                break;
            case 'changeReticleSizeInSetting':
                this.props.changeReticleSizeInSetting(value)
                break;
            case 'changeReticleSizeOutSetting':
                this.props.changeReticleSizeOutSetting(value)
                break;
            case 'changeReticleFadeThreshSetting':
                this.props.changeReticleFadeThreshSetting(value)
                break;
            case 'changeReticleFadeTimeSetting':
                this.props.changeReticleFadeTimeSetting(value)
                break;
            case 'changeToneMappingExposureSetting':
                this.props.changeToneMappingExposureSetting(value)
                break;
            case 'changeToneMappingSaturationSetting':
                this.props.changeToneMappingSaturationSetting(value)
                break;
            case 'changeToneMappingContrastSetting':
                this.props.changeToneMappingContrastSetting(value)
                break;
            case 'changeEnvMapRotationSetting':
                this.props.changeEnvMapRotationSetting(value)
                break;
            case 'bevelRadius':
                this.props.changeBevelRadius(value)
                break;
            case 'pixelRatio':
                this.props.changePixelRatio(value)
                break;
            case 'mapRepeatU':
                this.props.changeMapRepeatU('map', value)
                break;
            case 'mapRepeatV':
                this.props.changeMapRepeatV('map', value)
                break;
            case 'lightMapRepeatU':
                this.props.changeMapRepeatU('lightMap', value)
                break;
            case 'lightMapRepeatV':
                this.props.changeMapRepeatV('lightMap', value)
                break;
            case 'emissiveMapRepeatU':
                this.props.changeMapRepeatU('emissiveMap', value)
                break;
            case 'emissiveMapRepeatV':
                this.props.changeMapRepeatV('emissiveMap', value)
                break;
            case 'bumpMapRepeatU':
                this.props.changeMapRepeatU('bumpMap', value)
                break;
            case 'bumpMapRepeatV':
                this.props.changeMapRepeatV('bumpMap', value)
                break;
            case 'normalMapRepeatU':
                this.props.changeMapRepeatU('normalMap', value)
                break;
            case 'normalMapRepeatV':
                this.props.changeMapRepeatV('normalMap', value)
                break;
            case 'roughnessMapRepeatU':
                this.props.changeMapRepeatU('roughnessMap', value)
                break;
            case 'roughnessMapRepeatV':
                this.props.changeMapRepeatV('roughnessMap', value)
                break;
            case 'metalnessMapRepeatU':
                this.props.changeMapRepeatU('metalnessMap', value)
                break;
            case 'metalnessMapRepeatV':
                this.props.changeMapRepeatV('metalnessMap', value)
                break;
            case 'alphaMapRepeatU':
                this.props.changeMapRepeatU('alphaMap', value)
                break;
            case 'alphaMapRepeatV':
                this.props.changeMapRepeatV('alphaMap', value)
                break;
            case 'intensitySSR':
                this.props.intensitySSR(value)
                break;

            case 'minimumDistance':
                this.props.changeMinimumDistance(value)
                break;
            case 'maximumDistance':
                this.props.changeMaximumDistance(value)
                break;
            case 'maxPolarAngle':
                this.props.changeMaxPolarAngle(value)
                break;
            case 'minPolarAngle':
                this.props.changeMinPolarAngle(value)
                break;
            case 'maxAzimuthAngle':
                this.props.changeMaxAzimuthAngle(value)
                break;
            case 'minAzimuthAngle':
                this.props.changeMinAzimuthAngle(value)
                break;

            
            case 'aoMapRepeatU':
                this.props.changeMapRepeatU('aoMap', value)
                break;
            case 'aoMapRepeatV':
                this.props.changeMapRepeatV('aoMap', value)
                break;
            
            
            case 'changeNormalScaleX':
                this.props.changeNormalScaleX(value)
                break;
            case 'changeNormalScaleY':
                this.props.changeNormalScaleY(value)
                break;

            default:
                return null;
        }
    }

    /**
     * Checks if Value entered is a number
     * Then updates state when input text box is used for changing this field
     */
    handleTextBoxChange = event => {
        let inputVal = event.target.value
        //if (!isNaN(inputVal) && inputVal <= this.props.max && inputVal >= this.props.min) {
        if (!isNaN(inputVal)) {
            this.setState({[event.target.name]: event.target.value});
            this.dispatchAction(this.props.type, Number(event.target.value), null, this.props.obj)
        }
    }

    /**
     * Update state when slider is used for changing this field
     */
    handleSliderChange = (value) => {
        this.setState({
            value: parseFloat(value.toFixed(2))
        })
        this.dispatchAction(this.props.type, value, null, this.props.obj)
    }

    render() {
        return (
            <Row  /*{...this.props}*/>
                <Col md={12} className="setting-col-input">
                    <span title={this.props.title} className="setting-name">{this.props.name}</span>
                </Col>
                <Col md={8} className="input-range-slider">
                    <InputRange
                        step={this.props.step ? this.props.step : 0.01}
                        maxValue={this.props.max}
                        minValue={this.props.min}
                        value={this.state.value}
                        onChange={this.handleSliderChange}/>
                </Col>
                <Col md={4} className="setting-col-input" style={{marginTop: -5}}>
                    <Form.Control
                        autoComplete="off"
                        size="sm"
                        type="text"
                        className="menu-item-text-box"
                        name="value"
                        id={this.props.id}
                        value={this.state.value}
                        onChange={this.handleTextBoxChange}
                        // disabled={this.props.disableInput ? this.props.disableInput : false}
                        />
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    changeCameraZoom: changeCameraZoom,
    changeFovSetting: changeFovSetting,
    changeEdgeGlowSetting: changeEdgeGlowSetting,
    changeEdgeThicknessSetting: changeEdgeThicknessSetting,
    changeEdgeStrengthSetting: changeEdgeStrengthSetting,
    changePulsePeriodSetting: changePulsePeriodSetting,
    changeCameraPositionXSetting: changeCameraPositionXSetting,
    changeCameraPositionYSetting: changeCameraPositionYSetting,
    changeCameraPositionZSetting: changeCameraPositionZSetting,
    changeSharpnessSetting: changeSharpnessSetting,
    changeSsaoIntensitySetting: changeSsaoIntensitySetting,
    changeSsaoRadiusSetting: changeSsaoRadiusSetting,
    changeBloomStrengthSetting: changeBloomStrengthSetting,
    changeBloomRadiuSetting: changeBloomRadiuSetting,
    changeBloomThresholdSetting: changeBloomThresholdSetting,
    changeNumSamplesSetting: changeNumSamplesSetting,
    changeNumSamplesPerFrameSetting: changeNumSamplesPerFrameSetting,
    changeGroundBrightnessSetting: changeGroundBrightnessSetting,
    changeBlurRadiusSetting: changeBlurRadiusSetting,
    changeGroundSharpnessSetting: changeGroundSharpnessSetting,
    changeFalloffSetting: changeFalloffSetting,
    changeReflectionRoughnessSetting: changeReflectionRoughnessSetting,
    changeReflectionMetalnessSetting: changeReflectionMetalnessSetting,
    changeReflectionSizeSetting: changeReflectionSizeSetting,
    changeCrossRadiusSetting: changeCrossRadiusSetting,
    changeNearBlurScaleSetting: changeNearBlurScaleSetting,
    changeFarBlurScaleSetting: changeFarBlurScaleSetting,
    changeDepthRangeSetting: changeDepthRangeSetting,
    changeAutoRotateSpeedSetting: changeAutoRotateSpeedSetting,
    changeBoostFactorXSetting: changeBoostFactorXSetting,
    changeBoostFactorYSetting: changeBoostFactorYSetting,
    changeBoostFactorZSetting: changeBoostFactorZSetting,
    changeEnvMapIntensityDiamond: changeEnvMapIntensityDiamond,
    changeRefractiveIndexDiamond: changeRefractiveIndexDiamond,
    changeGeometryFactorDiamond: changeGeometryFactorDiamond,
    changeSquashFactorDiamond: changeSquashFactorDiamond,
    changeDispersionDiamond: changeDispersionDiamond,
    changeReflectivityDiamond: changeReflectivityDiamond,
    changeAbsorptionFactorDiamond: changeAbsorptionFactorDiamond,
    changeGammaFactorDiamond: changeGammaFactorDiamond,
    changeStandardMaterialOpacity: changeStandardMaterialOpacity,
    changeStandardMaterialRoughness: changeStandardMaterialRoughness,
    changeStandardMaterialMetalness: changeStandardMaterialMetalness,
    changeStandardMaterialEnvMapIntensity: changeStandardMaterialEnvMapIntensity,
    refractionMaterialRefractionIndex: refractionMaterialRefractionIndex,
    refractionMaterialRefractionRoughness: refractionMaterialRefractionRoughness,
    refractionMaterialRoughness: refractionMaterialRoughness,
    refractionMaterialMetalness: refractionMaterialMetalness,
    refractionMaterialTransparency: refractionMaterialTransparency,
    changeObjectScaleSetting: changeObjectScaleSetting,
    changeReticleSizeInSetting: changeReticleSizeInSetting,
    changeReticleSizeOutSetting: changeReticleSizeOutSetting,
    changeReticleFadeThreshSetting: changeReticleFadeThreshSetting,
    changeReticleFadeTimeSetting: changeReticleFadeTimeSetting,
    changeToneMappingExposureSetting: changeToneMappingExposureSetting,
    changeToneMappingSaturationSetting: changeToneMappingSaturationSetting,
    changeEnvMapRotationSetting: changeEnvMapRotationSetting,
    changeToneMappingContrastSetting: changeToneMappingContrastSetting,
    changeBevelRadius: changeBevelRadius,
    changePixelRatio: changePixelRatio,
    changeMapRepeatU: changeMapRepeatU,
    changeMapRepeatV: changeMapRepeatV,
    logoStayDuration: logoStayDuration,
    logoFadeInDuration: logoFadeInDuration,
    logoFadeOutDuration: logoFadeOutDuration,
    logoScale: logoScale,
    logoPositionX: logoPositionX,
    logoPositionY: logoPositionY,
    intensitySSR: intensitySSR,
    changeStandardMaterialBumpScale: changeStandardMaterialBumpScale,

    changeMinimumDistance:changeMinimumDistance,
    changeMaximumDistance:changeMaximumDistance,
    changeMaxPolarAngle:changeMaxPolarAngle,
    changeMinPolarAngle:changeMinPolarAngle,
    changeMaxAzimuthAngle:changeMaxAzimuthAngle,
    changeMinAzimuthAngle:changeMinAzimuthAngle,

    changeAnimationTimeScale: changeAnimationTimeScale,
    changeAudioVolume: changeAudioVolume,
    changeAudioMaterialVolume: changeAudioMaterialVolume,


    changeStandardMaterialLightMapIntensity: changeStandardMaterialLightMapIntensity,
    changeStandardMaterialAlphaMapIntensity: changeStandardMaterialAlphaMapIntensity,
    changeStandardMaterialAoMapIntensity: changeStandardMaterialAoMapIntensity,
    changeStandardMaterialEmissiveIntensity: changeStandardMaterialEmissiveIntensity,
    feedBackMin: feedBackMin,
    feedBackMax: feedBackMax,
    changeDampingFactor:changeDampingFactor,
    changeNearFarMultiplier: changeNearFarMultiplier,
    changeMinCameraNearClip: changeMinCameraNearClip,

    changeNormalScaleX: changeNormalScaleX,
    changeNormalScaleY: changeNormalScaleY
}

export default connect(null, mapDispatchToProps)(MenuItemInputRange)