import {
    REQUEST_COMBINATIONS_LIST,
    REQUEST_COMBINATIONS_SUCCESS,
    REQUEST_COMBINATIONS_FAILED,
    REQUEST_COMBINATIONS_ERROR,
    REQUEST_CREATE_COMBINATIONS_FAILED,
    REQUEST_CREATE_COMBINATIONS_ERROR,
    REQUEST_CREATE_COMBINATIONS_SUCCESS,
    CLONE_COMBINATION,
    DELETE_COMBINATION,
    UPDATE_COMBINATION,
    RESET_PRESET,
    ADD_PRESET_TO_LIST,
    REQUEST_COMBINATIONS_SIZE_SUCCESS,
    REQUEST_ACTIVITY_SUCCESS,
    REQUEST_IMAGES_SIZE_SUCCESS,
    REQUEST_TEXTURES_SIZE_SUCCESS,
    REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS,
    REQUEST_TRANSACTION_SUCCESS,
    GET_STORAGE_SUMMARY,

    SCENE_INFO_ALERT
} from "../actions/actionTypes";

const initialState = {
    combinationsList: [],
    error: '',
    hasError: false,
    loading: false,
    presetList: new Map(),
    createdCombination: {},
    totalSize: 0,
    storage: 0,
    storageExpired: '',

    imageList: [],
    imageTotalSize: 0,

    textureList: [],
    publicUploadedFilesList: [],
    textureTotalSize: 0,

    summary: {}

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SCENE_INFO_ALERT:
            return {
                ...state,
                loading: action.loading,
                infos: action.infos,
                sceneInfos: action.sceneInfos,
            }
        case GET_STORAGE_SUMMARY:
            console.log("GET_STORAGE_SUMMARY");
            console.log(action.summary);
            return {
                ...state,
                summary: action.summary
            }
        case REQUEST_COMBINATIONS_LIST:
            return {
                ...state,
                loading: true
            }
        case REQUEST_COMBINATIONS_SUCCESS:
        case REQUEST_CREATE_COMBINATIONS_SUCCESS:
        case CLONE_COMBINATION:
        case DELETE_COMBINATION:
            return {
                ...state,
                loading: action.loading,
                combinationsList: action.combinations,
                createdCombination: action.createdCombination
            }
        case REQUEST_COMBINATIONS_SIZE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                combinationsList: action.combinations,
                createdCombination: action.createdCombination,
                totalSize: action.totalSize,
                storage: action.storage,
                storageExpired: action.storageExpired
            }
        case REQUEST_IMAGES_SIZE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                imageList: action.imageList,
                imageTotalSize: action.imageTotalSize,
                storage: action.storage,
                storageExpired: action.storageExpired
            }
        case REQUEST_TEXTURES_SIZE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                textureList: action.textureList,
                textureTotalSize: action.textureTotalSize,
                storage: action.storage,
                storageExpired: action.storageExpired
            }
        case REQUEST_PUBLIC_UPLOADED_FILES_SIZE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                publicUploadedFilesList: action.publicUploadedFilesList,
                publicUploadedFilesTotalSize: action.publicUploadedFilesTotalSize,
                storage: action.storage,
                storageExpired: action.storageExpired
            }
        case REQUEST_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                remain: action.remain,
                default: action.default,
                extra: action.extra,
                expired: action.expired,
            }
        case REQUEST_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                transactions: action.transactions,
            }
        case REQUEST_COMBINATIONS_FAILED:
        case REQUEST_COMBINATIONS_ERROR:
        case REQUEST_CREATE_COMBINATIONS_FAILED:
        case REQUEST_CREATE_COMBINATIONS_ERROR:
            return {
                ...state,
                loading: action.loading,
                hasError: action.hasError,
                error: action.error
            }
        case UPDATE_COMBINATION:
            return {
                ...state,
                combinationsList: action.combinations
            }
        case RESET_PRESET:
            return{
                ...state,
                presetList: new Map()
            }
        case ADD_PRESET_TO_LIST:
            return{
                ...state,
                presetList: action.presetList
            }
        default:
            return state;
    }
}

export default reducer;