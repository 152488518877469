import {Spinner} from "react-bootstrap";
import React from "react";

function SpinnerSmall() {
    return (
        <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
        </Spinner>
    )
}

export {SpinnerSmall}