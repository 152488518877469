import React, {Component, Fragment} from "react";
import {Row, Col, Button} from "react-bootstrap";
import "../Sidebar/Sidebar.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import {SpinnerSmall} from '../../../helpers/spinners';


class Sidebar extends Component {
    state = {
        dateRange: [{
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }],
        showDateRangePicker: false
    }

    componentDidMount() {
        this.props.loadFiltersRequest()
    }

    handleSubmit = e => {
        e.preventDefault();
        let state = {...this.state}
        if (this.state.option2 === 'customDate') {
            let firstDay = state.dateRange[0].startDate
            let lastDay = state.dateRange[0].endDate
            state.option2 = {
                firstDay: firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate(),
                lastDay: lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()
            }
        }

        let {dateRange, showDateRangePicker, ...filters} = state

        this.props.loadReportRequest(filters)
    }

    handleChange = e => {
        e.preventDefault()
        if (e.target.name === 'option2' && e.target.value === 'customDate') {
            this.setState({
                showDateRangePicker: true
            })
        }

        if (e.target.name === 'option2' && e.target.value !== 'customDate') {
            this.setState({
                showDateRangePicker: false
            })
        }

        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleDatePicker = item => {
        this.setState({
            dateRange: [
                item.selection
            ]
        })
    }

    render() {
        let calendar = <DateRange
            className="date-range-picker"
            ranges={this.state.dateRange}
            onChange={item => this.handleDatePicker(item)}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
        />

        return (
            <div>
                <Row>
                    <Col md={{ span: 12 }} className="filter-title">
                        FILTER OPTIONS
                    </Col>
                </Row>
                {
                    this.props.tracker.loading ?
                        <Row>
                            <Col md={{ span: 12 }} className="filter-form">
                                <SpinnerSmall />
                            </Col>
                        </Row>
                        : ''
                }

                {
                    this.props.tracker.hasError ?
                        <Row>
                            <Col md={{ span: 12 }} className="filter-form">
                                {this.props.tracker.error}
                            </Col>
                        </Row> : ''
                }

                {
                    !this.props.tracker.isEmpty ?
                        <Row>
                            <Col md={{ span: 12 }}>
                                <form className="filter-form" onSubmit={this.handleSubmit}>
                                    {
                                        Object.keys(this.props.availableFilters).map((option, i) => {
                                            let defaultOption = ''
                                            if (option === 'type') {
                                                defaultOption = <option disabled={true} selected={true}>Select Type</option>
                                            }
                                            if (option === 'option1') {
                                                defaultOption = <option disabled={true} selected={true}>Select Report Type</option>
                                            }
                                            if (option === 'option2') {
                                                defaultOption = <option disabled={true} selected={true}>Select Date Range</option>
                                            }
                                            if (option === 'listUser') {
                                                defaultOption = <option disabled={true} selected={true}>Select User</option>
                                            }
                                            return (
                                                <Fragment key={i}>
                                                    <select key={i}
                                                            name={option === 'listUser' ? 'userId' : option}
                                                            id={option}
                                                            className="filter"
                                                            onChange={this.handleChange}>
                                                        {defaultOption}
                                                        {
                                                            this.props.availableFilters[option].map((option, i) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        value={option.userId ? option.userId : option.value }
                                                                    >{option.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {
                                                        option === 'option2' ? (this.state.showDateRangePicker ? calendar : '') : ''
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                    <button onClick={e => this.handleSubmit(e)} type="submit" className="btn filter-button">GENERATE</button>
                                </form>
                            </Col>
                        </Row> : ''
                }
            </div>
        )
    }
}

export default Sidebar;