import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../axios';
import {
    deleteCombination,
} from "../../../actions/actionsCreators";
import deleteBtn from '../../../assets/delete@2x.png';
import closeIcon from "../../../assets/close-icon@2x.png";
import {SpinnerSmall} from "../../../helpers/spinners";
import helpIcon from '../../../assets/help_icon.png';

class CombinationVideoHelpModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        // this.setState({
        //     show: true
        // })
        
        window.open("https://www.cadgl.com/combination-setup", "_blank");
    }

    render() {
        return (
            <React.Fragment>
                <img className="helpIconVideo"  id="" src={helpIcon} onClick={this.handleShow} />
                <Modal className="combinationVideoHelperModal" show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title></Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/M6LoRZsHMSs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationVideoHelpModal)