import React, {Component} from "react";
import {connect} from "react-redux";
import {Table, Col, Row, Spinner, Button, Tabs, Tab, Form} from "react-bootstrap";
import axios from '../../../axios';

class CombinationsSizeFileDownload extends Component {
    state = {
        selectedFiles: null,
        combination: this.props.combination,
        fileUrl: this.props.downloadFile,
        fileUrls: this.props.downloadFiles,
        uploading: false
    }
    onFileChange = event => {
      // Update the state
      this.setState({ selectedFiles: event.target.files });
    };
    /**
     * Update combination with new price and description
     */
    onFileUpload = () => {
        this.setState({ uploading: true });
        if (this.state.selectedFiles) {
            let count = 0;
            let i = 1;
            console.log("this.state.selectedFiles");
            console.log(this.state.selectedFiles['length']);
            for(let selectedFile of this.state.selectedFiles){
                i++;
                setTimeout(() => {
                    // Create an object of formData
                    const formData = new FormData();
                    
                    // Update the formData object
                    formData.append(
                        "myFile",
                        selectedFile,
                        selectedFile.name
                    );
                    
                    // Request made to the backend api
                    // Send formData object
                    axios.post('combination/upload-file-download', formData).then(response => {
                        console.log(response.data.data);
                        const path = response.data.data;
                        let comb = {
                            id: this.props.combination.uuid,
                            downloadFile: path
                        };
                        axios.post('combination/update-file-download', comb).then(response => {
                            console.log(response.data);
                            const paths = response.data.data.combination;
                            this.setState({ fileUrls: paths });
                            count ++;
                            
                        });
                    });
                }, i * 1000);
            }
            setInterval(() => {
                if(count == this.state.selectedFiles['length']){
                    this.setState({ uploading: false });
                }
            }, 1000);
        }
    }

    onClearFiles = () => {
        let comb = {
            id: this.props.combination.uuid,
        };
        axios.post('combination/clear-file-download', comb).then(response => {
            alert("Success");
            const paths = response.data.data.combination;
            this.setState({ fileUrls: paths });
        });
    }
    render() {
        return(
            <React.Fragment>
                {
                    this.state.fileUrls && this.state.fileUrls.length > 0 && this.state.fileUrls.map((file, key) => {
                        return(
                            <input key={key} value={file} readOnly type="text" className="form-control" />
                        )
                    })
                }
                {/* <input value={this.state.fileUrl} readOnly type="text" className="form-control" /> */}
                <input accept=".json, .3dm, .glb, .gltf, .blend, .stl, .3ds, .jcd, .fbx, .obj, .fbx, .mtl, .max, .stp, .collada, .iges, .vrml, .x3d, .dae, .jpeg, .jpg, .png, .bmp, .mp4, .mp3 " type="file" multiple className="form-control" onChange={this.onFileChange}  />
                <textarea rows="4" className="form-control" readOnly>
                Allowed file types: .json, .3dm, .glb, .gltf, .blend, .stl, .3ds, .jcd, .fbx, .obj, .fbx, .mtl, .max, .stp, .collada, .iges, .vrml, .x3d, .dae, .jpeg, .jpg, .png, .bmp, .mp4, .mp3
                </textarea>
                <Button onClick={this.onFileUpload} className="btn btn-danger">Upload</Button>
                {
                    this.state.uploading ? 
                        <p style={{marginTop: 5}}>Uploading....</p>
                    : <></>
                }
                <Button onClick={this.onClearFiles} style={{marginTop: 5, backgroundColor: '#bd2130'}} className="btn btn-danger">Clear All</Button>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(CombinationsSizeFileDownload);