import React, {Component} from "react";
import {Col, Row, Form} from "react-bootstrap";
import MenuGroup from "../MenuItems/MenuGroup";
import MenuItemTextBox from "../MenuItems/MenuItemTextBox";
import MenuItemTextBox2 from "../MenuItems/MenuItemTextBox2";
import RichTextEditor from "../MenuItems/RichTextEditor";
import MenuItemButton from "../MenuItems/MenuItemButton";
import MenuItemCheckbox from "../MenuItems/MenuItemCheckbox";
import MenuItemInputRange from "../MenuItems/MenuItemInputRange";
import MenuItemDropdown from "../MenuItems/MenuItemDropdown";
import {downscaleLevels} from "../../../../helpers/dropDownOptions";
import MenuItemColorPicker from "../MenuItems/MenuItemColorPicker";
import AddCombinationModal from "../../Combinations/AddCombinationModal";
import AddCombinationSceneInfoAlertModal from "../../Combinations/AddCombinationSceneInfoAlertModal";
import ShowCombinationsModal from "../../Combinations/ShowCombinationsModal";
import UploadFileModal from "../../../Configurator/Combinations/UploadFileModal";
import LoginModal from "../../Combinations/LoginModal";
import LogoutForm from "../../Combinations/LogoutForm";
import {FileLoader} from "three";
import jwt_decode from "jwt-decode";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';

let lang = {};
class PostProcessSettings extends Component{
    state = {
        diamondEnvironment: false,
        bevel: false,
        ssao: false,
        bloom: false,
        arSettings: false,
        liveFocus: false,
        tooltip: null,
        loggedIn: false,
        userInfo: '',
        logo: false,
        annotations: false,
        taa: false,
        ssr: false,
        animation: false,
        audio: false,
        ignoreAnimation: false,
        annotationUrl: ''
    }

    componentDidMount() {
        new FileLoader().load('https://cadgl.net/tooltip_cadgl/tooltip.json', (response) => {
            this.setState({
                tooltip: JSON.parse(response)
            })
        });
        let token = localStorage.getItem('user');
        if(token)
        {
            console.log(jwt_decode(localStorage.getItem('user')));
            this.setState({
                loggedIn: true,
                userInfo: jwt_decode(localStorage.getItem('user'))
            })
        }

        
        if (window.getComputedStyle(document.getElementById("ignoreAnimation")).display === "none") {
            this.setState({
                ...this.state,
                ignoreAnimation: false
            })
        }else{
            this.setState({
                ...this.state,
                ignoreAnimation: true
            })
        }

        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }

        if(document.getElementById("annotation_url").value != ''){
            this.setState({
                ...this.state,
                annotationUrl: document.getElementById("annotation_url").value
            })
        }
    }

    toggleIgnoreAnimation = (value) =>{
        document.getElementById("ignoreAnimation").style.display = !this.state.ignoreAnimation ? "block" : "none";
        this.setState({
            ...this.state,
            ignoreAnimation: !this.state.ignoreAnimation
        })
    }

    toggleMenuVisibility = (type) => {
        let newState = {}
        Object.keys({...this.state}).forEach(key => {
            if(key != 'tooltipTab' && key != 'tooltip' && key != 'loggedIn' && key != 'userInfo' && key != 'ignoreAnimation' && key != 'annotationUrl')
            {
                newState = {
                    ...newState,
                    [key]: false
                }
            }
        })

        this.setState({
            ...newState,
            [type]: !this.state[type]
        })
        
    }

    toggleAnnotationUrlChange = (value) => {
        this.setState({
            ...this.state,
            annotationUrl: value.target.value
        })
        document.getElementById("annotation_url").value = value.target.value;
    }

    render() {
        return (
            <React.Fragment>

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowSharpness) ?
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.sharpness : ''}
                        name={lang.sharpness_tab}
                        onClick={() => this.toggleMenuVisibility('diamondEnvironment')}
                        collapsed={this.state.diamondEnvironment ? 1 : 0}>
                        {
                            this.state.diamondEnvironment ?
                                <span>
                                    {/* <MenuItemCheckbox name={lang.enable} value={this.props.sharpnessStatus} type="enableSharpness"/> */}
                                    <MenuItemCheckbox name={lang.enable} value={this.props.config.sharpness} type="enableSharpness"/>
                                    <MenuItemInputRange name={lang.intensity} min={0} max={2} value={this.props.config.sharpness} type="sharpness"/>
                                </span> : ''
                        }
                    </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowSSAO) ?
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.ssao : ''}
                            name={lang.ssao}
                            onClick={() => this.toggleMenuVisibility('ssao')}
                            collapsed={this.state.ssao ? 1 : 0}>
                            {
                                this.state.ssao ?
                                    <span>
                                        <MenuItemCheckbox name={lang.enable} value={this.props.config.ssao.enabled} type="enableSsao" />
                                        <MenuItemCheckbox name={lang.enable_debug} value={this.props.config.ssao.enableDebug} type="enableDebugSsao" />
                                        <MenuItemCheckbox name={lang.blur} value={this.props.config.ssao.blur} type="enableSsaoBlur" />
                                        <MenuItemInputRange name={lang.intensity} min={0} max={1} value={this.props.config.ssao.intensity} type="ssaoIntensity" />
                                        <MenuItemInputRange name={lang.radius} min={0} max={10} value={this.props.config.ssao.radius} type="ssaoRadius" />
                                        <MenuItemDropdown name="Downscale Level" options={downscaleLevels} value={this.props.config.ssao.downscaleLevel} type="ssaoDownscaleLevel" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }
                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowSSR) ?
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.ssr : ''}
                            name={lang.ssr}
                            onClick={() => this.toggleMenuVisibility('ssr')}
                            collapsed={this.state.ssr ? 1 : 0}>
                            {
                                this.state.ssr ?
                                    
                                    <span>
                                        <MenuItemCheckbox name={lang.enable_taa} value={
                                            this.props.config.taa != undefined ? this.props.config.taa.enabled : this.props.api.getTAAParams().enabled
                                            } type="enableTAA"/>
                                        <MenuItemInputRange name={lang.feedback_min} min={0} max={1} 
                                        value={
                                            this.props.config.taa != undefined ? this.props.config.taa.feedBackMin : this.props.api.getTAAParams().feedBackMin
                                            } type="feedBackMin"/>
                                        <MenuItemInputRange name={lang.feedback_max} min={0} max={1} 
                                        value={
                                            this.props.config.taa != undefined ? this.props.config.taa.feedBackMax : this.props.api.getTAAParams().feedBackMax
                                            } type="feedBackMax"/>
                                        <MenuItemCheckbox name={lang.enable_ssr} value={
                                            this.props.api.getSSRParams().enabled
                                            } type="enableSSR"/>
                                        <MenuItemInputRange name={lang.intensity}  min={0} max={1} value={this.props.api.getSSRParams().intensity} type="intensitySSR"/>
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }
                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowBloom) ?
                <MenuGroup
                    title={this.state.tooltip != null ? this.state.tooltip.bloom : ''}
                    name={lang.bloom}
                    onClick={() => this.toggleMenuVisibility('bloom')}
                    collapsed={this.state.bloom ? 1 : 0}>
                    {
                        this.state.bloom ?
                            <span>
                                <MenuItemCheckbox name={lang.enable} value={this.props.config.unrealBloom.enabled} type="enableBloom" />
                                <MenuItemInputRange name={lang.strenth} min={0} max={5} value={this.props.config.unrealBloom.strength} type="bloomStrength" />
                                <MenuItemInputRange name={lang.radius} min={0} max={2} value={this.props.config.unrealBloom.radius} type="bloomRadius" />
                                <MenuItemInputRange name={lang.threshold} min={0} max={1.5} value={this.props.config.unrealBloom.threshold} type="bloomThreshold" />
                            </span> : ''
                    }
                </MenuGroup>
                    : ''
                }
                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowLiveFocus) ?
                        <MenuGroup
                            title={this.state.tooltip != null ? this.state.tooltip.live_focus : ''}
                            name={lang.live_focus}
                            onClick={() => this.toggleMenuVisibility('liveFocus')}
                            collapsed={this.state.liveFocus ? 1 : 0}>
                            {
                                this.state.liveFocus ?
                                    <span>
                                        <MenuItemCheckbox name={lang.enable} value={this.props.config.dof.enabled} type="enableDof" />
                                        <MenuItemCheckbox name={lang.enable_edit} value={this.props.config.dof.enableEdit} type="enableEditDof" />
                                        <MenuItemColorPicker name={lang.cross_color} value={this.props.config.dof.crossColor} type="crossColor" />
                                        <MenuItemInputRange name={lang.cross_radius} step={0.001} min={0} max={0.3} value={this.props.config.dof.crossRadius} type="crossRadius" />
                                        <MenuItemInputRange name={lang.near_blur_scale} min={0} max={1} value={this.props.config.dof.nearBlurScale} type="nearBlurScale" />
                                        <MenuItemInputRange name={lang.far_blur_scale} min={0} max={1} value={this.props.config.dof.farBlurScale} type="farBlurScale" />
                                        <MenuItemInputRange name={lang.depth_range} step={0.01} min={0.5} max={3} value={this.props.config.dof.depthRange} type="depthRange" />
                                    </span> : ''
                            }
                        </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isAllowAR) ?
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.ar_settings : ''}
                        name={lang.ar_settings}
                        onClick={() => this.toggleMenuVisibility('arSettings')}
                        collapsed={this.state.arSettings ? 1 : 0}>
                        {
                            this.state.arSettings ?
                                <span>
                                    <MenuItemCheckbox name={lang.enable} value={this.props.arSettings.enabled} type="enableAr" />
                                    <MenuItemInputRange name={lang.scale} min={0} max={2} value={this.props.arSettings.objectScale} type="changeObjectScaleSetting" />
                                    <MenuItemCheckbox name={lang.ground_shadows} value={this.props.arSettings.groundShadows} type="enableArGroundShadows" />
                                    <MenuItemColorPicker name={lang.reticle_color} value={this.props.arSettings.reticleColor} type="changeReticleColorSetting" />
                                    <MenuItemInputRange name={lang.reticle_size_in} min={0} max={1} value={this.props.arSettings.reticleSizeIn} type="changeReticleSizeInSetting" />
                                    <MenuItemInputRange name={lang.reticle_size_out} min={0} max={2} value={this.props.arSettings.reticleSizeOut} type="changeReticleSizeOutSetting" />
                                    <MenuItemInputRange name={lang.reticle_fade_thresh} min={0} max={1} value={this.props.arSettings.reticleFadeThresh} type="changeReticleFadeThreshSetting" />
                                    <MenuItemInputRange name={lang.reticle_fade_time} step={0.1} min={0} max={2} value={this.props.arSettings.reticleFadeTime} type="changeReticleFadeTimeSetting" />
                                </span> : ''
                        }
                    </MenuGroup>
                    : ''
                }
                {console.log("this.props.enableAnnotations: " + this.props.enableAnnotations)}
                {
                    (this.props.userPermission != undefined && this.props.userPermission.isAllowAnnotation) ?
                    <>
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.annotations : ''}
                        name={lang.annotations}
                        onClick={() => this.toggleMenuVisibility('annotations')}
                        collapsed={this.state.annotations ? 1 : 0}>
                        {
                            this.state.annotations ?
                            ''
                            // <Row>
                            //     <Col md={6} className="setting-col-input">
                            //         <span title={lang.custom_annotation_icons_url} className="setting-name">{lang.custom_annotation_icons_url}</span>
                            //     </Col>
                            //     <Col md={6} className="setting-col-input">
                            //         <Form.Control
                            //             size="sm"
                            //             type="text"
                            //             className="menu-item-text-box"
                            //             value={this.state.annotationUrl}
                            //             onChange={this.toggleAnnotationUrlChange}/>
                            //     </Col>
                            // </Row>

                            : ''
                        }
                        {
                            this.state.annotations ?
                                <span>
                                    <MenuItemCheckbox name={lang.enable} value={this.props.enableAnnotations != undefined ? this.props.enableAnnotations : false} type="enableAnnotations"/>
                                </span> : ''
                        }
                        {
                            this.state.annotations && this.props.enableAnnotations && this.props.annotation ?
                                (
                                    <MenuItemButton 
                                    title={this.props.annotation != null ? "Annotation " + (this.props.annotation.index + 1) : ''}
                                    name={"Annotation " + (this.props.annotation.index + 1)} type="" value="" />
                                )
                            :''
                        }
                        {
                            this.state.annotations && this.props.enableAnnotations && this.props.annotation ?
                                (
                                    <MenuItemTextBox2 name={lang.title}
                                    value={this.props.annotation.title}
                                    type="changeAnnotationTitle"
                                    />
                                )
                            :''
                        }
                        {
                            this.state.annotations && this.props.enableAnnotations && this.props.annotation ?
                                (
                                    <RichTextEditor name={lang.text}
                                    value={this.props.annotation.text}
                                    type="changeAnnotationText"/>
                                )
                            :''
                        }
                        {
                            this.state.annotations && this.props.enableAnnotations && this.props.annotation ?
                                (
                                    <MenuItemButton 
                                    name={lang.remove}
                                    title="Remove" type="removeAnnotation" value="" />
                                )
                            :''
                        }
                        {
                            this.state.annotations && this.props.enableAnnotations && this.props.annotation ?
                                (
                                    <UploadFileModal />
                                )
                            :''
                        }
                    </MenuGroup>
                    </>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isAllowWatermark) ?
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.logo_watermark : ''}
                        name={lang.logo_watermark}
                        onClick={() => this.toggleMenuVisibility('logo')}
                        collapsed={this.state.logo ? 1 : 0}>
                        {
                            this.state.logo ?
                                <span>
                                    <MenuItemCheckbox name={lang.enable} value={this.props.config.logoParams.enabled} type="logoToggle"/>
                                    <MenuItemInputRange name={lang.stay_duration} step={0.1} min={0} max={60} value={this.props.config.logoParams.logoStayDuration} type="logoStayDuration" />
                                    <MenuItemInputRange name={lang.fade_in_duration} step={0.1} min={0} max={3} value={this.props.config.logoParams.logoFadeInDuration} type="logoFadeInDuration" />
                                    <MenuItemInputRange name={lang.fade_out_duration} step={0.1} min={0} max={3} value={this.props.config.logoParams.logoFadeOutDuration} type="logoFadeOutDuration" />
                                    <MenuItemInputRange name={lang.scale} step={0.1} min={0} max={4} value={this.props.config.logoParams.scale} type="logoScale" />
                                    <MenuItemInputRange name={lang.position_x} step={0.1} min={0} max={1} value={this.props.config.logoParams.positionX} type="logoPositionX" />
                                    <MenuItemInputRange name={lang.position_y} step={0.1} min={0} max={1} value={this.props.config.logoParams.positionY} type="logoPositionY" />
                                </span> : ''
                        }
                    </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowAnimation) ?
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.animation : ''}
                        name={lang.animation} 
                        onClick={() => this.toggleMenuVisibility('animation')}
                        collapsed={this.state.animation ? 1 : 0}>
                        {
                            this.state.animation ?
                                <span>
                                    <MenuItemInputRange name={lang.time_scale}  min={0} max={3} step={0.1} value={this.props.config.animationParams != undefined ? this.props.config.animationParams.timescale : 0} type="animationTimeScale" />
                                    <MenuItemCheckbox name={lang.loop}  value={this.props.config.animationParams != undefined ? this.props.config.animationParams.loop : false} type="toggleAnimationLoop"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.play}  type="toggleAnimationPlay"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.stop}  type="toggleAnimationStop"/>
                                    

                                    <Row /*{...this.props}*/>
                                        <Col md={7} className="setting-col-input">
                                            <span title="Ignore Animation" className="setting-name">{lang.ignore_animation}</span>
                                        </Col>
                                        <Col md={5} className="setting-col-input">
                                            <Form.Check
                                                checked={this.state.ignoreAnimation}
                                                onChange={this.toggleIgnoreAnimation}
                                                id="btnIgnoreAnimation"
                                                type="checkbox"
                                                className="float-right"/>
                                        </Col>
                                    </Row>
                                    <MenuItemInputRange name={lang.near_far_multiplier}  min={1} max={1000} step={0.1} value={this.props.api.getCameraNearFarMultiplier() ?? 0} type="nearFarMultiplier" />
                                    
                                </span> : ''
                        }
                    </MenuGroup>
                    : ''
                }

                {
                    (this.props.userPermission != undefined && this.props.userPermission.isShowAudio) ?
                    <MenuGroup
                        title={this.state.tooltip != null ? this.state.tooltip.audio : ''}
                        name={lang.audio}
                        onClick={() => this.toggleMenuVisibility('audio')}
                        collapsed={this.state.audio ? 1 : 0}>
                        {
                            this.state.audio ?
                                <span>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.add_ambient} type="addAmbient"/>
                                    <MenuItemInputRange name={lang.volume} min={0} max={1} step={0.1} 
                                    value={this.props.api.getAmbientAudio() ? this.props.api.getAmbientAudio().getVolume() : 1} 
                                    type="changeAudioVolume" />
                                    <MenuItemCheckbox name={lang.loop} value={this.props.api.getAmbientAudio() ? this.props.api.getAmbientAudio().getLoop() :true} type="toggleAudioLoop"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.play} type="toggleAudioPlay"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.stop} type="toggleAudioStop"/>
                                    <MenuItemButton
                                    title={this.state.tooltip != null ? this.state.tooltip.open_files : ''}
                                    name={lang.remove} type="removeAudio"/>
                                </span> : ''
                        }
                    </MenuGroup>
                    : ''
                }
                
                <br/>

                <Row>
                    <Col md={12}>
                        <h5 className="app-name-sidebar" style={{fontSize:11+'px'}}>{lang.create_your_combinations}</h5>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '2.5%', paddingLeft: '0'}}>
                        {/* <AddCombinationModal /> */}
                        <AddCombinationSceneInfoAlertModal/>
                    </Col>
                    <Col md={6} className="btn-save-as-preset" style={{paddingRight: '0', paddingLeft: '0'}}>
                        <ShowCombinationsModal />
                    </Col>
                </Row>

            </React.Fragment>
        )
    }
}

export default PostProcessSettings