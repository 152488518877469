import React, {Component} from "react";
import {connect} from "react-redux";
import DeleteCombinationsModal from "./DeleteCombinationsModal";
import CloneCombinationModal from "./CloneCombinationModal";
import axios from '../../../axios';
import {SpinnerSmall} from "../../../helpers/spinners";
import {login} from '../../../actions/actionsCreators';
import {Table, Col, Row, Spinner} from "react-bootstrap";

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        loading: false,
        error: '',
        hasError: false,
        checkedWidget: this.props.checkedWidget,
        checkedEmbedded: this.props.checkedEmbedded
    }

    /**
     * Handles price and description change
     * @param e
     */
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: '',
            hasError: false
        })
    }

    /**
     * Change selected status of widget
     */
    handleWidgetCheckboxChange = () => {
        this.setState({
            checkedWidget: !this.state.checkedWidget
        })
        this.props.handleWidgetCheckboxChange(this.props.combination.widget, this.props.combination.uuid)
    }

    /**
     * Change selected status of embedded
     */
    handleEmbeddedCheckboxChange = () => {
        this.setState({
            checkedEmbedded: !this.state.checkedEmbedded
        })
        this.props.handleEmbeddedCheckboxChange(this.props.combination.embedded, this.props.combination.uuid)
    }

    /**
     * Update combination with new price and description
     */
    login = () => {
        this.setState({
            loading: true,
            hasError: false,
            error: ''
        })

        let payload = {
            email: this.state.email,
            password: this.state.password,
        }

        axios.post('auth/login', payload).then(result => {
            if (result.data.success) {
                alert('Success');
                this.setState({
                    loading: false,
                    hasError: false,
                    error: ''
                })
                localStorage.setItem('user', JSON.stringify(result.data.data.token));
            }
        }).catch((err, result) => {
            alert(err.response.data.error.message);
            this.setState({
                loading: false,
                hasError: true,
                error: err.response.data.error.message
            })
        })
    }

    render() {

        return(
            <React.Fragment>
                <Table className="combinations-table table-borderless" size="lg">
                    <tr>
                        <td><input style={{paddingRight: 9}} placeholder="email" className="name-input" name="email" type="text" value={this.state.email} onChange={this.handleChange} /> </td>
                        <td>
                            <input placeholder="password" className="name-input" name="password" type="password" value={this.state.password} onChange={this.handleChange} />
                        </td>
                        <td>
                            {
                                this.state.loading ?
                                    <SpinnerSmall /> :
                                    <button style={{marginBottom: '0'}} className="add-combination-btn btn btn-primary" onClick={this.login} disabled={this.state.loading} >Login</button>
                            }
                        </td>
                    </tr>
                </Table>
            </React.Fragment>
        )
        
    }
}

const mapDispatchToProps = {
    login: login,
}

export default connect(null, mapDispatchToProps)(LoginForm);