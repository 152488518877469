import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import axios from '../../../../axios';
import {
    deletePresetFolder,
} from "../../../../actions/actionsCreators";
import trashIcon from "../../../../assets/trash.png";
import closeIcon from "../../../../assets/close-icon@2x.png";

class DeletePresetFolderModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        loading: false
    };

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true
        })
    }

    /**
     * Send request to delete preset folder also remove it from state
     */
    deletePresetFolder = () => {
        this.setState({loading: true})
        axios.post('preset/delete-folder', {"id": this.props.uuid}).then(response => {
            let {data} = response
            this.setState({loading: false})
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.props.deletePresetFolder(this.props.uuid)
            }
        }).catch(err => {
            this.setState({
                hasError: true,
                error: 'Error occurred, please contact support!',
                loading: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <img className="trash-icon material-gallery-icon" src={trashIcon} alt="" onClick={this.handleShow}/>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="rename-material-modal-title">
                        <Modal.Title>Delete Preset Folder</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="rename-material-modal-body">
                            <Col md={12}>
                                <span>Are you sure you want to delete this folder with all its presets?</span>
                            </Col>
                            {
                                this.state.hasError ?
                                    <Col md={12}>
                                        <span className="rename-material-modal-error">{this.state.error}</span>
                                    </Col> : ''
                            }
                        </Row>
                        {
                            this.state.loading ?
                                <Row>
                                    <Col md={12} className="spinner">
                                        <Spinner animation="border" role="status" variant="primary">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col md={12} className="text-center">
                                <Button variant="primary" className="btn-delete-preset" onClick={this.deletePresetFolder} disabled={this.state.loading}>
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    deletePresetFolder: deletePresetFolder,
}

export default connect(null, mapDispatchToProps)(DeletePresetFolderModal)