import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row, Modal, Button, Form, Spinner} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import {
    savePreset,
    applyPreset,
} from "../../../../actions/actionsCreators";
import axios from "../../../../axios";
import closeIcon from "../../../../assets/close-icon@2x.png";

import lang_it from '../../../../lang/lang_it.json';
import lang_en from '../../../../lang/lang_en.json';
import lang_fr from '../../../../lang/lang_fr.json';
import lang_vn from '../../../../lang/lang_vn.json';
let lang = {};
class SaveAsPresetModal extends Component {
    state = {
        show: false,
        hasError: false,
        error: '',
        presetName: '',
        presetPrice: 0,
        folderUuid: null,
        loading: false
    };



    componentDidMount() {
        if(localStorage.getItem('lang')){
            switch(localStorage.getItem('lang')){
                case "en":
                    lang = lang_en;
                break;
                case "vn":
                    lang = lang_vn;
                break;
                case "fr":
                    lang = lang_fr;
                break;
                case "it":
                    lang = lang_it;
                break;
            }
        }else{
            lang = lang_en;
        }
    }


    /**
     * Update materials array on state and close modal
     */

    savingPreset(materialData, label = ''){
        let payload = {
            "presetFolder": {
                "id": this.state.folderUuid
            },
            "preset": {
                "name": this.state.presetName,
                "price": this.state.presetPrice,
                //"data": this.props.material,
                "labelColor": document.getElementById("colorValue").value,
                "label": label,
                "data": materialData,
                "mesh": this.props.material.id,
                "createdAt": new Date()
            }
        }

        axios.post('preset/create', payload).then(response => {
            this.setState({loading: false})
            let {data} = response
            if (!data.success) {
                this.setState({
                    hasError: true,
                    error: data.error.message
                })
            }

            if (data.success) {
                this.props.savePreset(this.state.presetName, this.state.folderUuid, data.data.preset, this.state.presetPrice)
                this.props.applyPreset(data.data.preset, this.state.folderUuid);
                this.handleClose()
            }
        }).catch(err => {
            console.log(err)
            this.setState({
                hasError: true,
                error: err.response.data.message,
                loading: false
            })
        })
    }

    dispatchAction = () => {
        if (!this.state.presetName) {
            this.setState({
                hasError: true,
                error: 'Preset name is required!'
            })
        }

        // if (!this.state.presetPrice) {
        //     this.setState({
        //         hasError: true,
        //         error: 'Preset price is required!'
        //     })
        // }

        if (!this.state.folderUuid) {
            this.setState({
                hasError: true,
                error: 'Folder is required!'
            })
        }

        if (!this.state.hasError && this.state.presetName && this.state.folderUuid) {

            this.setState({loading: true})

            var materialData = null;
            
            let materialTmp = this.props.material;
            materialTmp.map = this.props.selectedObjects.pickedObject.material.map;
            this.props.api.exportMaterialParams(materialTmp).then((value) => {
                materialData = (value);
                delete value['id']; //Note: if this is not removed, same material instance will be used when this is applied to some other mesh.
                
                let label = '';

                if(document.getElementById("label").files[0] == undefined){
                    this.savingPreset(materialData);
                }else{
                    let materialLabelData = new FormData();
                    materialLabelData.append('label', document.getElementById("label").files[0]);
                    
                    axios.post('preset/upload-material-label-img', materialLabelData
                    ).then(response => {
                        label = response.data.data;
                        console.log(label);
                        this.savingPreset(materialData, label);
                    });
                }

            });
        }
    }

    /**
     * Close modal
     */
    handleClose = () => {
        this.setState({
            show: false,
            hasError: false
        })
    }

    /**
     * Open modal
     */
    handleShow = () => {
        this.setState({
            show: true,
            hasError: false
        })
    }

    /**
     * Updates field value on change
     * @param e
     */
    updateField = (e) => {
        this.setState({
            presetName: e.target.value,
            hasError: !e.target.value,
            error: 'Name can not be empty'
        });
    }

    updatePrice = (e) => {
        this.setState({
            presetPrice: e.target.value,
            hasError: !e.target.value,
            error: 'Price can not be empty'
        });
    }

    handleFolderChange = (e) => {
        this.setState({
            folderUuid: e.target.value,
            hasError: !e.target.value,
            error: 'Please select a folder!'
        });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md={12} className="setting-col" >
                        <span title={this.props.title} onClick={this.handleShow} className="setting-name">{this.props.name}</span>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="save-preset-modal-title">
                        <Modal.Title>{lang.save_material}</Modal.Title>
                        <div>
                            <img className="close-icon" src={closeIcon} alt="" onClick={this.handleClose}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.props.presets.length === 0 ?
                                <Row className="save-preset-modal-body" style={{textAlign: 'center'}}>
                                    <Col md={12}>
                                        <span>Create a preset folder first !</span>
                                    </Col>
                                </Row> :
                                <React.Fragment>
                                    <Row className="save-preset-modal-body">
                                        <Col md={5}>
                                            <span id="save_as_material_name_label">{lang.material_name}</span>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Control
                                                id="save_as_material_name"
                                                placeholder="Enter material name"
                                                size="sm"
                                                type="text"
                                                value={this.state.presetName}
                                                className="save-preset-text-box"
                                                onChange={this.updateField}/>
                                        </Col>
                                        <Col md={5}>
                                            <span id="save_as_material_price_label">{lang.material_price}</span>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Control
                                                id="save_as_material_price"
                                                placeholder="Enter material price"
                                                size="sm"
                                                type="number"
                                                value={this.state.presetPrice}
                                                className="save-preset-text-box"
                                                onChange={this.updatePrice}/>
                                        </Col>

                                        <Col md={5}>
                                            <span id="save_as_material_folder_label">{lang.select_material_folder}</span>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Control 
                                            id="save_as_material_folder"
                                            as="select" size="sm" className="menu-item-text-box" onChange={this.handleFolderChange}>
                                                <option value={null} disabled={true} selected={true}>{lang.select_material_folder}</option>
                                                {
                                                    this.props.presets.map(presetFolder => {
                                                        return (
                                                            <option key={presetFolder.id} value={presetFolder.uuid}>{presetFolder.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Col>

                                        <Col md={5}>
                                            <span id="select_material_label">{lang.select_material_label}</span>
                                            <span style={{display: 'block', fontSize:12, marginTop: -10}} id="select_material_label_sub">{lang.use_generate_color}</span>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Control
                                                size="sm"
                                                type="file"
                                                id="label"/>
                                        </Col>
                                        {
                                            this.state.hasError ?
                                                <React.Fragment>
                                                    <Col md={5} />
                                                    <Col md={7}>
                                                        <span className="save-preset-modal-error">{this.state.error}</span>
                                                    </Col>
                                                </React.Fragment> : ''
                                        }
                                    </Row>
                                    {
                                        this.state.loading ?
                                            <Row>
                                                <Col md={12} className="spinner">
                                                    <Spinner animation="border" role="status" variant="primary">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </Col>
                                            </Row> : ''
                                    }
                                    <Row>
                                        <Col md={5}/>
                                        <Col md={7}>
                                            <Button 
                                                id="save_as_material_button"
                                                variant="primary" onClick={this.dispatchAction} disabled={this.state.loading}>
                                                {lang.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    savePreset: savePreset,
    applyPreset: applyPreset
}

const mapStateToProps = (state) => {
    return {
        selectedObjects: state.selectedObjects,
        material: state.selectedObjects.material,
        presets: state.presetLibrary.presets,
        api: state.sceneConfig.api
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveAsPresetModal)